import React             from 'react';
import { ISectionProps } from '../../../api/com/ewing/social/interface';
import { BaseSection }   from '../../atom/BaseSection';
import { Description }   from '../../atom/Description';
import { Title }         from '../../atom/Title';

/**
 * @class Layout11
 * @extends BaseSection
 * @author Isaac Ewing
 * @version 1.0.0 01/30/21 11:30 am - documented
 */
export class Layout11 extends BaseSection {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am - documented
      */
     protected static readonly COMPONENT_CLASS: string = 'layout-11';

     /**
      *
      * @param props {ISectionProps}
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am - documented
      */
     public constructor( props: ISectionProps ) {
          super( props );

          this.state = {
               className: new Set<string>( [ 'grid-x', Layout11.COMPONENT_CLASS ] ),
          };

          this.registerProps( props.module ?? null );
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am
      * documented
      */
     public render(): JSX.Element {
          return (
               <section { ...this.registerSectionProps( this.props.module ) }>
                    <div className="grid-container content">
                         <div className="grid-x align-center">
                              <div className="cell small-12">
                                   <Title module={ this.props?.module?.title } />
                              </div>
                              <div className="cell small-11 medium-8">
                                   <Description module={ this.props?.module?.description } />
                              </div>
                         </div>
                    </div>
               </section>
          );
     }
}
