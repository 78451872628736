import React                               from 'react';
import { PageObserver }                    from '../../api/com/ewing/social/observer';
import { SiteModule }                      from '../../api/com/ewing/social/module';
import { Util }                            from '../../api/com/ewing/social/tool';
import { Infolinks, PopAds, PropellerAds } from '../atom/ad';

/**
 * @class Ad
 * @extends React.PureComponent
 * @author Isaac Ewing
 * @version 1.0.0 05/03/21 01:35 pm
 */
export class Ad extends React.PureComponent {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/03/21 01:35 pm
      */
     protected static readonly COMPONENT_CLASS: string = 'molecule-ad';
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/03/21 01:35 pm
      */
     protected static readonly DEFAULT_HASH: string    = Util.registerHash();
     /**
      *
      * @type {number}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/03/21 01:35 pm
      */
     protected static DEFAULT_UPDATES: number          = 0;
     /**
      *
      * @type {Set<JSX.Element>}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/03/21 01:35 pm
      */
     protected static children: Set<JSX.Element>       = new Set<JSX.Element>();
     /**
      *
      * @type {any}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/03/21 01:35 pm
      */
     public state: any;

     /**
      *
      * @param {string} type
      * @return {string}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/03/21 01:35 pm
      */
     protected static buildKey( type: string ): string {
          return `ad-${ type }-${ this.DEFAULT_HASH }`;
     }

     /**
      *
      * @protected
      * @return {void}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/03/21 03:13 pm
      */
     protected static registerAds(): void {
          console.log( ' [[ AD ]] CLEARING ads then adding them...', { children: Ad.children.size } );
          Ad.DEFAULT_UPDATES++;
          this.children.clear();
          this.children
              .add( <Infolinks key={ this.buildKey( 'Infolinks' ) } /> )
              .add( <PopAds key={ this.buildKey( 'PopAds' ) } /> )
              .add( <PropellerAds key={ this.buildKey( 'PropellerAds' ) } /> );
          console.log( ' [[ AD ]] FINISHED ads, check count for each...', { updates: Ad.DEFAULT_UPDATES, children: Ad.children.size } );
     }

     /**
      *
      * @param module {SiteModule}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/03/21 01:35 pm
      */
     protected onPageObserver( module: SiteModule ): void {
          Ad.registerAds();
          this.setState( {
                              updates : Ad.DEFAULT_UPDATES,
                              children: Ad.children,
                         } );
     }

     /**
      *
      * @param props {any}
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/03/21 01:35 pm
      */
     public constructor( props: any ) {
          super( props );

          Ad.registerAds();
          this.state = {
               updates : Ad.DEFAULT_UPDATES,
               children: Ad.children,
          };

          this.onPageObserver = this.onPageObserver.bind( this );
          PageObserver.subscribe( Ad.COMPONENT_CLASS, this.onPageObserver );
          console.log( ' [[ AD ]] SUBSCRIBED to the page observer...' );
     }

     /**
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/03/21 01:35 pm
      */
     public componentWillUnmount(): void {
          PageObserver.unsubscribe( Ad.COMPONENT_CLASS );
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/03/21 01:35 pm
      */
     public render(): JSX.Element {
          return (
               <div id={ `ad-${ Ad.DEFAULT_HASH }` } data-ad={ true } data-updates={ this.state.updates }>
                    { [ ...this.state.children ] }
               </div>
          );
     }
}

export default Ad;