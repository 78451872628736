import React                            from 'react';
import jQuery                           from 'jquery';
import { TweenMax }                     from 'gsap';
import { IBaseProps, IModule }          from '../../../api/com/ewing/social/interface';
import { TitleModule }                  from '../../../api/com/ewing/social/module';
import { ActionEnum, RegisterStepEnum } from '../../../api/com/ewing/social/enum';
import { OAuthObserver }                from '../../../api/com/ewing/social/observer/oauth.observer';
import { ProfileModule }                from '../../../api/com/ewing/social/module/api/profile.module';
import { Description }                  from '../../atom/Description';
import { InviteBase }                   from '../../atom/invite/InviteBase';

/**
 * @class Expand
 * @extends InviteBase
 * @author Isaac Ewing
 * @version 1.0.0 01/16/21 11:22 am
 */
export class Expand extends InviteBase<unknown, unknown> {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/16/21 11:22 am
      */
     protected static readonly COMPONENT_CLASS: string = 'expand';

     /**
      *
      * @type {Object}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/24/21 04:06 pm
      */

     /**
      *
      * @param {IModule} template
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/19/21 01:05 pm
      */
     protected onOAuthObserver( template: IModule ): void;
     protected onOAuthObserver( module: ProfileModule ): void;
     protected onOAuthObserver( data: ProfileModule | IModule ): void {
          const component: JQuery     = this.state.component;
          const module: ProfileModule = data as ProfileModule;
          const updateSocials         = ():void => {
               const tempSocials: Set<string> = this.state.socials;
               const hasPlatform: boolean     = tempSocials.has( module.platform );
               const properties               = {
                    'data-state': InviteBase.STATE_OPEN,
                    // 'data-status': Expand.STATUS_DONE,
               };

               this.updateAttributeData( component, properties ?? {} );

               if( hasPlatform ) {
                    tempSocials.delete( module.platform );
                    this.setState( {
                                        socials           : tempSocials,
                                        socialButtonsState: module.state,
                                   } );
               }
          };

          if( module.step === RegisterStepEnum.Platform ) {
               updateSocials();
          } else if( module.step === RegisterStepEnum.Expand ) {
               const button: JQuery   = jQuery( this.state.socialButtons ).find( `.${ module.platform }` );
               const updateSocialData = () => {
                    updateSocials();

                    if( this.state.socials.size === 0 ) {
                         const properties = {
                              'data-state' : InviteBase.STATE_CLOSE,
                              'data-status': InviteBase.STATUS_DONE,
                         };

                         module.step = RegisterStepEnum.Start;
                         this.updateAttributeData( component, properties ?? {} );
                         OAuthObserver.unsubscribe( Expand.COMPONENT_CLASS );
                         OAuthObserver.notify( module );
                    }
               };
               const vars             = {
                    duration: InviteBase.ANIMATION_TIME,
                    ease    : 'circ.out',
                    width   : 0,
                    height  : 0,
               };

               TweenMax.to( button, vars ?? {} );
               setTimeout( updateSocialData, 500 );
          }
     }

     /**
      *
      * @param props {any}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/16/21 11:22 am
      */
     public constructor( props: IBaseProps ) {
          super( props );

          this.state = {
               hash              : this.registerHash(),
               id                : props.id ?? '',
               initialized       : false,
               className         : new Set<string>( [ Expand.COMPONENT_CLASS ] ),
               component         : null,
               children          : new Set<JSX.Element>(),
               title             : null,
               titleHash         : this.registerHash(),
               socials           : InviteBase.getSocials(),
               socialButtons     : null,
               socialButtonsHash : this.registerHash(),
               socialButtonsState: null,
               nextButton        : null,
               nextButtonHash    : this.registerHash(),
          };

          this.registerProps( props );
          this.onOAuthObserver       = this.onOAuthObserver.bind( this );
          this.onAttributeDataChange = this.onAttributeDataChange.bind( this );
          OAuthObserver.subscribe( Expand.COMPONENT_CLASS, this.onOAuthObserver );
     }

     /**
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/19/21 01:05 pm
      */
     public componentDidMount(): void {
          const component: JQuery     = jQuery( `[data-hash="${ this.state.hash }"]` );
          const socialButtons: JQuery = jQuery( `[data-hash="${ this.state.socialButtonsHash }"]` );
          const nextButton: JQuery    = jQuery( `[data-hash="${ this.state.nextButtonHash }"]` );
          const title: JQuery         = jQuery( `[data-hash="${ this.state.titleHash }"]` );
          const module: ProfileModule = ProfileModule.Build( { step: RegisterStepEnum.Start } );
          const onClick               = ( event: JQuery.ClickEvent<HTMLElement> ): void => {
               event.preventDefault();
               this.updateAttributeData( component, initialData );
               OAuthObserver.unsubscribe( Expand.COMPONENT_CLASS );
               OAuthObserver.notify( module );
          };
          const initialData           = {
               'data-state' : InviteBase.STATE_CLOSE,
               'data-status': InviteBase.STATUS_INCOMPLETE,
          };

          // @ts-ignore
          this.state.title = title;
          component.on( InviteBase.ATTRIBUTE_DATA_CHANGED, this.onAttributeDataChange );
          nextButton.on( 'click', onClick ?? null );
          InviteBase.REGISTER_MODULE.componentExpand = component;
          this.setState( { component, socialButtons, title, nextButton } );
          this.updateAttributeData( component, initialData );
     }

     /**
      *
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 03/26/21 05:23 pm
      */
     public componentWillUnmount(): void {
          OAuthObserver.unsubscribe( Expand.COMPONENT_CLASS );
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/16/21 11:22 am
      */
     public render(): JSX.Element {
          const data    = {
               'data-state' : InviteBase.STATE_CLOSE,
               'data-status': InviteBase.STATUS_INCOMPLETE,
               'data-hash'  : this.state.hash,
          };
          const options = {
               color : InviteBase.COLOR_GREY,
               action: ActionEnum.Invite,
               code  : this.state.page?.invite?.code,
               step  : RegisterStepEnum.Expand,
               state : this.state?.socialButtonsState ?? null,
          };
          const button  = {
               id         : 'next-button-link',
               className  : 'button-link',
               href       : '#',
               'data-hash': this.state.nextButtonHash,
          };

          return (
               <div className={ [ ...this.state.className ].join( ' ' ) }  { ...data }>
                    <h5 className={ 'subtitle' } data-hash={ this.state.titleHash }>{ InviteBase.TEXT_MAP.get( 'expand-heading' ) }</h5>
                    <Description module={ TitleModule.Build( { text: InviteBase.TEXT_MAP.get( 'expand-description' ) } ) } />
                    <div className={ InviteBase.CLASS_SOCIALS } data-hash={ this.state.socialButtonsHash }>
                         { this.buildClaimSocial( this.state.socials ?? null, null, options ?? {} ) }
                    </div>
                    <div className={ 'next-button' }>
                         <a { ...button }>next</a>
                    </div>
                    { this.state.children }
               </div>
          );
     }
}

export default Expand;