import { AObserver }                   from '../abstract';
import { IObserver, IShortcodeModule } from '../interface';

/**
 * @class ShortcodeObserver
 * @extends AObserver
 * @implements IObserver
 * @author Isaac Ewing
 * @version 1.0.0 12/20/20 03:14 pm
 */
export class ShortcodeObserver extends AObserver implements IObserver {
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 05/07/21 06:39 pm
     */
    protected static readonly CONSOLE_PREFIX: string                            = `${ process.env.REACT_APP_CONSOLE_PREFIX_OBSERVER } [O/] ${ process.env.REACT_APP_CONSOLE_SUFFIX_OBSERVER }`;
    /**
     *
     * @type {Map<string, Function>}
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 03:14 pm
     * @version 1.1.0 03/24/21 12:17 pm - changed array to set
     * @version 1.2.0 03/26/21 04:54 pm - changed set to map
     */
    protected static observers: Map<string, ( data: IShortcodeModule ) => void> = new Map<string, ( data: IShortcodeModule ) => void>();

    /**
     *
     * @param hash {string}
     * @param fn {Function}
     * @return void
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 03/26/21 06:50 pm
     */
    public static subscribe( hash: string | null, fn: ( data: IShortcodeModule ) => void ): void {
        if( hash ) {
            this.observers.set( hash, fn );
            console.log( `${ this.CONSOLE_PREFIX } ADDED shortcode subscriber`, hash, 'all shortcode subscribers', { observers: this.observers } );
        }
    }
}