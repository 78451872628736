import { AModule }                      from '../../../abstract/module.abstract';
import { IAmountModule, IDonateModule } from '../../../interface';
import { AmountModule }                 from '../../../module/donate/amount.module';

/**
 * @class DonateModule
 * @extends AModule
 * @implements IDonateModule
 * @author Isaac Ewing
 * @version 1.0.0 12/11/20 03:38 pm
 * @version 1.1.0 02/23/21 08:23 pm - updated to extend AComponent
 * @classdesc This class will handle the storage for price amount data. This is the fifth class to
 * implement the interfaces in a similar way to laravel/lumen
 */
export class DonateModule extends AModule implements IDonateModule {
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.1.0 02/23/21 08:23 pm
      */
     protected static readonly CONSOLE_ENABLED: boolean = true;

     /**
      *
      * @param module {DonateModule} The object containing the data
      * @return {DonateModule|null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @version 1.1.0 02/23/21 08:23 pm
      * @see Build
      * @see amounts
      * @see setAmounts
      * @see addAmount
      * @see amountsToArray
      */
     public static Build( module: DonateModule ): DonateModule;
     public static Build( obj: Partial<IDonateModule> ): DonateModule;
     public static Build( json: string ): DonateModule;
     public static Build( amount?: AmountModule, id?: number | string ): DonateModule;
     public static Build( amounts?: AmountModule[], id?: number | string ): DonateModule;
     public static Build( amounts?: Set<AmountModule>, id?: number | string ): DonateModule;
     public static Build( dataOrAmounts?: unknown, id?: number | string ): DonateModule {
          if( dataOrAmounts ) {
               if( dataOrAmounts instanceof DonateModule ) {
                    return dataOrAmounts;
               }
               if( dataOrAmounts instanceof Set || Array.isArray( dataOrAmounts ) ) {
                    const amounts: Set<AmountModule> = new Set<AmountModule>();

                    dataOrAmounts.forEach( ( link: unknown ): void => {
                         amounts.add( AmountModule.Build( link ) );
                    } );

                    return new DonateModule( amounts ?? null, id ?? null );
               }
               if( typeof dataOrAmounts === 'object' ) {
                    const localData: Partial<IDonateModule> = dataOrAmounts;
                    const localAmounts: any                 = localData?.amounts ?? localData;
                    const localModules: Set<AmountModule>   = new Set<AmountModule>();

                    localAmounts.forEach( ( amount: unknown ): void => {
                         localModules.add( AmountModule.Build( amount ) );
                    } );

                    return new DonateModule( localModules ?? null, localData?.id ?? null );
               }
               if( typeof dataOrAmounts === 'number' ) {
                    return new DonateModule( new AmountModule( dataOrAmounts ?? null, id ?? null ));
               }
               if( typeof dataOrAmounts === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrAmounts ) );
                    } catch( exception ) {
                         // not a valid json string
                         return new DonateModule( new AmountModule( +dataOrAmounts ?? null, id ?? null) );
                    }
               }
          }

          return null;
     }

     /**
      *
      * @param amount {AmountModule}
      * @param id {number | string}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/11/20 03:38 pm
      * @version 1.1.0 02/23/21 08:23 pm
      * @see amounts
      * @see setAmounts
      * @see addAmount
      * @see amountsToArray
      */
     public constructor( amount?: AmountModule, id?: number | string );
     public constructor( amounts?: AmountModule[], id?: number | string );
     public constructor( amounts?: Set<AmountModule>, id?: number | string );
     public constructor( amounts?: unknown, id?: number | string ) {
          super( id );

          this._data.set( 'links', new Set<AmountModule>() );
          this._data.set( 'amounts', new Set<AmountModule>() );

          if( amounts ) {
               if( DonateModule.CONSOLE_ENABLED ) {
                    console.info( '[[ ]]DONATE MODULE[[ ]]', { amounts, id } );
               }

               this.addAmount( amounts );
          }
     }

     /**
      *
      * @return {Set<AmountModule>}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/23/21 08:23 pm
      * @see amounts
      * @see setAmounts
      * @see addAmount
      * @see amountsToArray
      */
     public get amounts(): Set<AmountModule> {
          return this._data.get( 'amounts' );
     }

     /**
      *
      * @param {Set<AmountModule>} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/23/21 08:23 pm
      * @see amounts
      * @see setAmounts
      * @see addAmount
      * @see amountsToArray
      */
     public set amounts( value: Set<AmountModule> ) {
          this._data.set( 'amounts', value );
     }

     /**
      *
      * @param {Set<AmountModule>} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/23/21 08:23 pm
      * @see id
      * @see amounts
      * @see setAmounts
      * @see addAmount
      * @see amountsToArray
      */
     public setAmounts( value: Set<AmountModule> ): this {
          this.amounts = value;

          return this;
     }

     /**
      *
      * @param id {AmountModule[]|Object[]|AmountModule|Object|number} An array or object or id with the data
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/23/21 08:23 pm
      * @see id
      * @see amounts
      * @see setAmounts
      * @see addAmount
      * @see amountsToArray
      */
     public addAmount( id: AmountModule ): this;
     public addAmount( id: AmountModule[] ): this;
     public addAmount( id: Set<AmountModule> ): this;
     public addAmount( id: Partial<IAmountModule> ): this;
     public addAmount( id: Partial<IAmountModule>[] ): this;
     public addAmount( id: Set<Partial<IAmountModule>> ): this;
     public addAmount( id: unknown, amount?: number ): this {
          if( id instanceof AmountModule ) {
               this.amounts.add( id );
          } else if( Array.isArray( id ) || id instanceof Set ) {
               id.forEach( ( element: unknown ): void => {
                    this.addAmount( element );
               } );
          } else if( typeof id === 'object' ) {
               const local: Partial<IAmountModule> = id;

               if( DonateModule.CONSOLE_ENABLED ) {
                    console.info( '[[O]]DONATE MODULE[[ ]]', { local } );
               }

               this.amounts.add( new AmountModule( local?.amount ?? null, local?.id ?? null ) );
          } else if( id || amount ) {
               this.amounts.add( new AmountModule( amount ?? null, `${ id ?? null }` ) );
          } else {
               console.log( 'the link was invalid', { id, amount } );
          }

          return this;
     }

     /**
      *
      * @return {object[]}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/23/21 08:23 pm
      * @see id
      * @see amounts
      * @see setAmounts
      * @see addAmount
      * @see amountsToArray
      */
     public amountsToArray(): Record<string, unknown>[] {
          const amounts: Record<string, unknown>[] = [];

          this.amounts.forEach( ( amount: AmountModule ): number => amounts.push( amount.toObject() ) );

          return amounts;
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/23/21 08:23 pm
      * @see id
      * @see amounts
      * @see setAmounts
      * @see addAmount
      * @see amountsToArray
      */
     public toObject(): Record<string, unknown> {
          return {
               id     : this.id,
               amounts: this.amountsToArray(),
          };
     }
}