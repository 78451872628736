import React                                       from 'react';
import PropTypes                                   from 'prop-types';
import { Img, ImgProps }                           from 'react-image';
import { GatsbyImage, GatsbyImageProps, getImage } from 'gatsby-plugin-image';
import { FileNode }                                from 'gatsby-plugin-image/dist/src/components/hooks';
import { FavorImageEnum }                          from '../../api/com/ewing/social/enum';
import { ImageModule }                             from '../../api/com/ewing/social/component';
import { IImageProps }                             from '../../api/com/ewing/social/interface';
import { Util }                                    from '../../api/com/ewing/social/tool';
import { Base }                                    from './Base';

/**
 * @class Image
 * @extends Base
 * @author Isaac Ewing
 * @version 1.0.0 01/18/21 06:24 pm - documented
 */
export class Image extends Base<IImageProps> {
     protected static readonly COMPONENT_CLASS: string = 'image';
     protected static readonly CONFIG_LAZY_LOAD_ON     = 'lazy';
     protected static readonly CONFIG_LAZY_LOAD_OFF    = 'eager';
     public static propTypes                           = {
          ...Base.propTypes,
          module : PropTypes.instanceOf( ImageModule ).isRequired,
          top    : PropTypes.bool,
          bottom : PropTypes.bool,
          rounded: PropTypes.bool,
          greyed : PropTypes.bool,
          shadow : PropTypes.bool,
          svg    : PropTypes.bool,
          png    : PropTypes.bool,
          jpg    : PropTypes.bool,
          jpeg   : PropTypes.bool,
          gif    : PropTypes.bool,
          jxr    : PropTypes.bool,
          webp   : PropTypes.bool,
     };
     protected hasMounted: boolean;
     /**
      *
      * @type {Partial<IImageProps>}
      * @readonly
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/29/21 06:31 pm
      */
     public readonly props: Partial<IImageProps>;

     /**
      *
      * @param url {string}
      * @param type {string}
      * @param count {number}
      * @return {JSX.Element}
      * @author Isaac Ewing
      * @version 1.0.0 - 10/04/20 01:33 pm
      */
     protected addSource( url: string, type: string, count: number ): JSX.Element {
          const key = `image-${ this.state.hash }-${ count + 1 }`;
          let ext: string;

          switch( type ) {
               case 'gif':
               case 'jpg':
               case 'jpeg':
               case 'png':
               case 'webp':
                    ext = type;
                    break;
               case 'svg':
                    ext = 'svg+xml';
                    break;
               case 'jxr':
                    ext = 'vnd.ms-photo';
                    break;
          }

          return <source key={ key } srcSet={ `${ url }.${ type }` } type={ 'image/' + ext } />;
     }

     /**
      *
      * @param props {ImageModule} The module with all the data
      * @author Isaac Ewing
      * @version 1.0.0 - 10/04/20 12:51 pm
      */
     protected registerTypes( props: ImageModule ): void {
          /*
           let count = 0;

           const url: string                = Util.buildImageURL( props.source, props?.domain?.id ?? null );
           const types: Set<string>         = props.ordered;
           const urls: Set<string>          = new Set<string>();
           const children: Set<JSX.Element> = new Set<JSX.Element>();
           const extensions: Set<string>    = new Set<string>(
           [
           ...props.extensions,
           '',
           ] );

           types.forEach( ( extension: string ): void => {
           if( extensions.has( extension ) ) {
           count++;
           urls.add( `${ url }.${ extension }` );
           //children.add( this.addSource( url, tempProperty, count ) );
           }
           } );

           if( this.state.children.size === 0 ) {
           extensions.forEach( ( extension: string ): void => {
           count++;
           urls.add( `${ url }${ extension !== '' ? '.' : '' }${ extension }` );
           //children.add( this.addSource( url, types[ i ], count ) );
           } );
           }

           count++;

           */

          //console.log( '[[ I ]] IMAGE SOURCES', [ ...urls ] );
          //const file: FileNode               = props.imageFile;
          const children: Set<JSX.Element>   = new Set<JSX.Element>();
          const component: FileNode | string = props.forComponent();
          console.log( '[[ I ]] IMAGE FAVOR', { favor: props.favor, svg: props.favor === FavorImageEnum.Svg, url: props.favor === FavorImageEnum.URL } );

          try {
               const params = {
                    key         : Util.registerHash(),
                    src         : props.path,
                    alt         : props.alt,
                    unselectable: 'on',
                    loading     : Util.asBoolean( process.env.REACT_APP_CONFIG_LAZY_LOAD_IMAGE ) ? Image.CONFIG_LAZY_LOAD_ON : Image.CONFIG_LAZY_LOAD_OFF,
                    ...this.state.other as Record<string, never>,
                    image: null,
               };

               if( typeof component === 'string' ) {
                    params.src = props.forComponent() as string;
                    console.log( '[[ I ]] IMAGE trying to build react image component...', { params, props, image: params.src } );

                    children.add( <Img { ...params as ImgProps } /> );
               } else if( component ) {
                    params.image = getImage( props.imageFile );
                    console.log( '[[ I ]] IMAGE trying to build gatsby image component from file node...', { params, props, image: params.image } );

                    children.add( <GatsbyImage { ...params as GatsbyImageProps } /> );
               } else {
                    console.log( '[[ I ]] IMAGE trying to build gatsby image component from file node...', { params, props, image: params.image } );
                    children.add( <Img { ...params as ImgProps } /> );
               }
          } catch( exception ) {
               const isString: boolean = typeof props.forComponent() === 'string';

               console.warn( `[[ I ]] IMAGE FAILED to create ${ isString ? 'static' : 'gatsby' } image, try to add additional testing or support...`,
                             { props: this.props, module: props } );
          }

          if( this.hasMounted ) {
               try {
                    this.setState( { module: props, children } );
               } catch( exception ) {
                    console.error( 'IMAGE MOUNTED STATE UPDATE', exception );
               }
          } else {
               try {
                    children.forEach( ( child: JSX.Element ): void => {
                         this.state.children.add( child );
                    } );
               } catch( exception ) {
                    console.error( 'IMAGE NOT MOUNTED STATE UPDATE', exception );
               }
          }
     }

     /**
      *
      * @param props {ImageModule} The module with all the data
      * @author Isaac Ewing
      * @version 1.0.0 - 10/04/20 01:13 pm
      */
     protected registerRounded( props: ImageModule ): void {
          if( this.state.rounded ?? props.rounded ) {
               this.state.className.add( 'rounded' );
          }
     }

     /**
      *
      * @param props {ImageModule} The module with all the data
      * @author Isaac Ewing
      * @version 1.0.0 - 10/04/20 01:14 pm
      */
     protected registerGreyed( props: ImageModule ): void {
          if( this.state.greyed ?? props.greyed ) {
               this.state.className.add( 'greyed' );
          }
     }

     /**
      *
      * @param props {ImageModule} The module with all the data
      * @author Isaac Ewing
      * @version 1.0.0 - 10/04/20 01:14 pm
      */
     protected registerShadow( props: ImageModule ): void {
          if( this.state.shadow ?? props.shadow ) {
               this.state.className.add( 'shadow' );
          }
     }

     /**
      *
      * @param props {ImageModule}
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/18/21 06:28 pm
      */
     protected registerPropsCall( props: ImageModule ): void {
          this.registerTypes( props );
          this.registerPosition( props );
          this.registerAlign( props );
          this.registerRounded( props );
          this.registerGreyed( props );
          this.registerShadow( props );
     }

     /**
      *
      * @param props {any}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/18/21 06:24 pm - documented
      */
     public constructor( props: IImageProps ) {
          super( props );

          this.state = {
               module   : props.module,
               hash     : Util.registerHash(),
               top      : props.top ?? false,
               bottom   : props.bottom ?? false,
               rounded  : props.rounded ?? false,
               greyed   : props.greyed ?? false,
               shadow   : props.shadow ?? false,
               svg      : props.svg ?? false,
               png      : props.png ?? false,
               jpg      : props.jpg ?? false,
               jpeg     : props.jpeg ?? false,
               gif      : props.gif ?? false,
               jxr      : props.jxr ?? false,
               webp     : props.webp ?? false,
               className: new Set<string>( [ Image.COMPONENT_CLASS, props?.className ?? '' ] ),
               children : new Set<JSX.Element>(),
          };

          this.registerProps( props.module as unknown as Record<string, unknown> );
     }

     /**
      *
      * @return {void}
      * @author Isaac Ewing
      * @version 1.0.0 01/18/21 02:00 pm
      */
     public componentDidMount(): void {
          this.hasMounted = true;
     }

     /**
      *
      * @param {Readonly<any>} prevProps
      * @param {Readonly<any>} prevState
      * @param snapshot
      * @return {void}
      * @author Isaac Ewing
      * @version 1.0.0 01/17/21 04:26 pm
      */
     public componentDidUpdate( prevProps: Readonly<Record<string, never>>, prevState: Readonly<Record<string, never>>, snapshot?: unknown ): void {
          if( prevProps.module !== this.props.module ) {
               console.log( 'UPDATING THE STATE FROM PROPS IN IMAGE', { previousModule: prevProps.module, currentModule: this.props.module, prevState, snapshot } );

               this.state.children.clear();
               this.registerTypes( this.props.module as ImageModule );
               // this.setState( { module: this.props.module } );
          }
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 01/18/21 06:21 pm
      * documented
      */
     public render(): JSX.Element {
          return (
               <picture className={ [ ...this.state.className ].join( ' ' ) }>
                    { this.state.children }
               </picture>
          );
     }
}
