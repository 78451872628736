import React, { RefObject }         from 'react';
import PropTypes                    from 'prop-types';
import JsxParser                    from 'react-jsx-parser';
import ReactVisibilitySensor        from 'react-visibility-sensor';
import { ITitleProps, ITitleState } from '../../api/com/ewing/social/interface';
import { TitleModule }              from '../../api/com/ewing/social/module/api/title.module';
import { ShortcodeManager }         from '../../api/com/ewing/social/manager/shortcode.manager';
import { Shortcode }                from '../atom/Shortcode';
import { Base }                     from './Base';

/**
 * @class Title
 * @extends Base
 * @author Isaac Ewing
 * @version 1.0.0 - 12/24/20 01:00 pm
 * @classdesc This class will handle all the title component rendering throughout the app
 */
export class Title extends Base<ITitleProps, ITitleState> {
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 - 12/24/20 01:00 pm
     */
    protected static readonly COMPONENT_CLASS: string = 'title';
    public static propTypes                           = {
        ...Base.propTypes,
        module: PropTypes.instanceOf( TitleModule ).isRequired,
    };
    protected wrapper: RefObject<HTMLDivElement>;
    /**
     *
     * @type {Partial<ITitleProps>}
     * @readonly
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 05/29/21 07:12 pm
     */
    public readonly props: Partial<ITitleProps>;
    /**
     *
     * @type {Partial<ITitleState>}
     * @readonly
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 05/29/21 07:12 pm
     */
    public state: Partial<ITitleState>;

    /**
     *
     * @param props {any}
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 - 12/24/20 01:00 pm
     */
    public constructor( props: ITitleProps ) {
        super( props );

        this.state = {
            module          : props.module,
            hash            : this.registerHash(),
            text            : null,
            initialized     : false,
            className       : new Set<string>( [ Title.COMPONENT_CLASS ] ),
            children        : new Set<JSX.Element>( props?.children ?? [] ),
            visibilitySensor: {
                active  : true,
                onChange: ( visible: boolean ): void => { this.registerOnChange( visible ); },
            },
            components      : {
                Shortcode,
            },
        };

        this.wrapper = React.createRef();
        this.registerClasses( props );
    }

    /**
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 - 12/24/20 01:00 pm
     */
    public componentDidMount(): void {
        this.setState( { text: ShortcodeManager.parse( this.state.module.text ) } );
    }

    /**
     *
     * @param {Error} error
     * @param {React.ErrorInfo} errorInfo
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 06/01/21 07:28 pm
     */
    public componentDidCatch( error: Error, errorInfo: React.ErrorInfo ): void {
        console.error( 'TITLE JSX', { error, errorInfo } );
    }

    /**
     *
     * @return {JSX.Element}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 11:57 am documented
     * @version 1.1.0 12/20/20 11:58 am added support for jsx parser
     */
    public render(): JSX.Element {
        return (
            <div ref={ this.wrapper }>
                <ReactVisibilitySensor { ...this.state.visibilitySensor }>
                    <div className={ [ ...this.state.className ].join( ' ' ) }>
                        <h1>
                            <JsxParser renderInWrapper={ false } components={ this.state.components } jsx={ this.state.text } onError={ this.registerOnError } autoCloseVoidElements />
                        </h1>
                    </div>
                </ReactVisibilitySensor>
            </div>
        );
    }
}