import isNode                    from 'detect-node';
import axios, { AxiosResponse }  from 'axios';
import { QueryClient, useQuery } from 'react-query';
import { UserGraphql }           from '../graphql';
import { UserModule }            from '../module/api/user.module';
import { UserObserver }          from '../observer';
import { URLManager }            from './url.manager';
import { StorageManager }        from './storage.manager';

/**
 * @class UserManager
 * @author Isaac Ewing
 * @version 1.0.0 05/07/21 07:04 pm
 */
export class UserManager {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/09/21 01:31 pm
      */
     protected static readonly CONSOLE_PREFIX: string   = `${ process.env.REACT_APP_CONSOLE_PREFIX_MANAGER } USER ${ process.env.REACT_APP_CONSOLE_SUFFIX_MANAGER }`;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/09/21 01:31 pm
      */
     protected static readonly CONSOLE_ENABLED: boolean = true;
     /**
      *
      * @type {Map<string, any>}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/07/21 07:04 pm
      */
     protected static _data: Map<string, any>           = new Map<string, any>();
     /**
      *
      * @type {QueryClient}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 10:48 pm
      */
     protected static readonly client: QueryClient      = new QueryClient();

     protected static isBusy: boolean;

     /**
      *
      * @return {UserModule}
      * @constructor
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/07/21 07:04 pm
      */
     protected static get user(): UserModule {
          const user: UserModule = this._data.get( 'user' );

          if( !user ) {
               const storage: UserModule = UserModule.FromStorage();

               if( storage ) {
                    this.user = storage;
               } else {
                    if( !this.isBusy ) {
                         this.isBusy = true;
                         this.queryForUser().then( ( data: UserModule ): void => {
                              this.isBusy = false;

                              if( data ) {
                                   data.store();
                                   this.user = data;

                                   if( this.CONSOLE_ENABLED ) {
                                        console.log( `${ this.CONSOLE_PREFIX } DATA from graphql...`, { data } );
                                   }
                              }
                         } );
                    }
               }
          }

          return user;
     }

     /**
      *
      * @param {UserModule} value
      * @return {void}
      * @constructor
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/07/21 07:04 pm
      */
     protected static set user( value: UserModule ) {
          this._data.set( 'user', value );
     }

     /**
      *
      * @return {string | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 06:44 pm
      */
     public static get id(): number | null {
          return +this.user?.id ?? StorageManager.userId ?? null;
     }

     /**
      *
      * @return {string | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 06:44 pm
      */
     public static get username(): string | null {
          return this.user?.username ?? StorageManager.userUsername ?? null;
     }

     /**
      *
      * @return {string | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 06:44 pm
      */
     public static get email(): string | null {
          return this.user?.email ?? StorageManager.userEmail ?? null;
     }

     /**
      *
      * @return {string | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 06:44 pm
      */
     public static get token(): string | null {
          return this.user?.token ?? StorageManager.userToken ?? null;
     }

     protected static UseQuery( props: any ) {
          return props.children( useQuery( props.key, props.fn, props.options ) );
     }

     protected static async queryForUser( user?: number ): Promise<any> {
          //axios.defaults.headers.common['Authorization'] = `Bearer ${authenticationToken}`;
          if( !isNode ) {
               const query: string                = UserGraphql.Query( user );
               const headers: any                 = {
                    Authorization: this.token,
               };
               const response: AxiosResponse<any> = await axios.post( URLManager.GraphQL, { query }, { headers } );

               if( response?.status === 200 && response?.data?.data?.getUser ) {
                    const status: number     = response?.status;
                    const module: UserModule = UserModule.Build( response?.data?.data?.getUser );

                    module.token ??= this.token;
                    console.log( `${ this.CONSOLE_PREFIX } QUERY from axios...`, { query, headers, response, module, status } );

                    return module;
               }
          }

          return null;
     }

     /**
      *
      * @param {UserModule} user
      * @return {UserManager}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/07/21 07:04 pm
      */
     public static Login( user: UserModule ): UserManager {
          this.user                = user;
          StorageManager.userToken = user.token;
          UserObserver.notify( user );

          return UserManager;
     }

     /**
      *
      * @return {UserManager}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/07/21 07:04 pm
      */
     public static Logout(): UserManager {
          this.user = null;
          StorageManager.userDestroy();
          UserObserver.notify( null );

          return UserManager;
     }

     /**
      *
      * @return {UserModule}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/07/21 07:04 pm
      */
     public static User(): UserModule {
          return this.user ?? null;
     }
}