import { IDomain, ISetup } from '../../interface';
import { TitleModule }     from './title.module';
import { DomainModule }    from './domain.module';
import { StorageManager }  from '../../manager';

/**
 * @class SetupModule
 * @extends TitleModule
 * @implements ISetup
 * @author Isaac Ewing
 * @version 1.0.0 10/03/20 01:06 pm
 * @version 1.1.0 02/18/21 02:56 pm - updated to extend AComponent
 * @version 1.1.1 05/24/21 06:50 pm - updated to implement ISetup
 * @version 2.0.0 05/27/21 09:37 pm - converted from AComponent to TitleModule
 */
export class SetupModule extends TitleModule implements ISetup {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 03:54 pm
      */
     protected static readonly CONSOLE_PREFIX: string   = `${ process.env.REACT_APP_CONSOLE_PREFIX_MODULE } STUP ${ process.env.REACT_APP_CONSOLE_SUFFIX_MODULE }`;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 03:44 pm
      */
     protected static readonly CONSOLE_ENABLED: boolean = false;
     /**
      *
      * @type {SetupModule}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 03:44 pm
      */
     protected static fromStorage: SetupModule;

     /**
      *
      * @param module {SetupModule} The object containing the data
      * @return {SetupModule|null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 04:23 pm
      * @see id
      * @see login
      * @see intro
      * @see domain
      * @see setId
      * @see setLogin
      * @see setIntro
      * @see setDomain
      * @see store
      * @see toObject
      * @see FromStorage
      */
     public static Build( module: SetupModule ): SetupModule;
     public static Build( obj: Partial<ISetup> ): SetupModule;
     public static Build( json: string ): SetupModule;
     public static Build( id: number, login?: boolean, intro?: boolean, domain?: DomainModule ): SetupModule;
     public static Build( id: number, login?: unknown, intro?: boolean, domain?: Partial<IDomain> ): SetupModule;
     public static Build( dataOrId?: unknown, login?: boolean, intro?: boolean, domain?: DomainModule ): SetupModule {
          if( dataOrId ) {
               if( dataOrId instanceof SetupModule ) {
                    return dataOrId;
               }
               if( typeof dataOrId === 'object' ) {
                    const localData: Partial<ISetup> = dataOrId as Partial<ISetup>;

                    return new SetupModule( localData?.id ?? null, localData?.login ?? null, localData?.intro ?? null, DomainModule.Build( localData?.domain ) ?? null );
               }
               if( typeof dataOrId === 'number' ) {
                    return new SetupModule( dataOrId ?? null, login ?? null, intro ?? null, DomainModule.Build( domain ) ?? null );
               }
               if( typeof dataOrId === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrId ) );
                    } catch( exception ) {
                         // not a valid json string
                         return new SetupModule( +dataOrId ?? null, login ?? null, intro ?? null, DomainModule.Build( domain ) ?? null );
                    }
               }
          }

          return null;
     }

     /**
      *
      * @return {SetupModule | null}
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 03:37 pm
      * @see id
      * @see login
      * @see intro
      * @see domain
      * @see setId
      * @see setLogin
      * @see setIntro
      * @see setDomain
      * @see store
      * @see toObject
      */
     public static FromStorage(): SetupModule | null {
          if( this.fromStorage ) {
               return this.fromStorage;
          }
          if( StorageManager.setupModel ) {
               this.fromStorage = SetupModule.Build( StorageManager.setupModel );
          }
          if( StorageManager.setupId && StorageManager.setupLogin && StorageManager.setupIntro ) {
               this.fromStorage = SetupModule.Build( StorageManager.setupId ?? null, StorageManager.setupLogin ?? null, StorageManager.setupIntro ?? null,
                                                     StorageManager.setupDomain ?? null );
          }

          return this.fromStorage ?? null;
     }

     /**
      *
      * @param {number} id
      * @param {boolean} login
      * @param {boolean} intro
      * @param {DomainModule} domain
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 04:23 pm
      * @see id
      * @see login
      * @see intro
      * @see domain
      * @see setId
      * @see setLogin
      * @see setIntro
      * @see setDomain
      * @see store
      * @see toObject
      */
     public constructor( id?: number, login?: boolean, intro?: boolean, domain?: DomainModule ) {
          super( id ?? null );

          this.login  = login;
          this.intro  = intro;
          this.domain = domain;
     }

     /**
      *
      * @return {boolean} Returns the string for the property
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 04:23 pm
      * @see id
      * @see login
      * @see intro
      * @see domain
      * @see setId
      * @see setLogin
      * @see setIntro
      * @see setDomain
      * @see store
      * @see toObject
      */
     public get login(): boolean {
          return this._data.get( 'login' );
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 04:23 pm
      * @see id
      * @see login
      * @see intro
      * @see domain
      * @see setId
      * @see setLogin
      * @see setIntro
      * @see setDomain
      * @see store
      * @see toObject
      */
     public set login( value: boolean ) {
          this._data.set( 'login', value );
     }

     /**
      *
      * @return {boolean} Returns the string for the property
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 04:23 pm
      * @see id
      * @see login
      * @see intro
      * @see domain
      * @see setId
      * @see setLogin
      * @see setIntro
      * @see setDomain
      * @see store
      * @see toObject
      */
     public get intro(): boolean {
          return this._data.get( 'intro' );
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 04:23 pm
      * @see id
      * @see login
      * @see intro
      * @see domain
      * @see setId
      * @see setLogin
      * @see setIntro
      * @see setDomain
      * @see store
      * @see toObject
      */
     public set intro( value: boolean ) {
          this._data.set( 'intro', value );
     }

     /**
      *
      * @return {DomainModule} Returns the domain for the source
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 04:23 pm
      * @see id
      * @see login
      * @see intro
      * @see domain
      * @see setId
      * @see setLogin
      * @see setIntro
      * @see setDomain
      * @see store
      * @see toObject
      */
     public get domain(): DomainModule | null {
          return this._data.get( 'domain' );
     }

     /**
      *
      * @param {DomainModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 04:23 pm
      * @see id
      * @see login
      * @see intro
      * @see domain
      * @see setId
      * @see setLogin
      * @see setIntro
      * @see setDomain
      * @see store
      * @see toObject
      */
     public set domain( value: DomainModule ) {
          this._data.set( 'domain', value );
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 04:23 pm
      * @see id
      * @see login
      * @see intro
      * @see domain
      * @see setId
      * @see setLogin
      * @see setIntro
      * @see setDomain
      * @see store
      * @see toObject
      */
     public setLogin( value: boolean ): this {
          this.login = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 04:23 pm
      * @see id
      * @see login
      * @see intro
      * @see domain
      * @see setId
      * @see setLogin
      * @see setIntro
      * @see setDomain
      * @see store
      * @see toObject
      */
     public setIntro( value: boolean ): this {
          this.intro = value;

          return this;
     }

     /**
      *
      * @param {DomainModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 04:23 pm
      * @see id
      * @see login
      * @see intro
      * @see domain
      * @see setId
      * @see setLogin
      * @see setIntro
      * @see setDomain
      * @see store
      * @see toObject
      */
     public setDomain( value: DomainModule ): this {
          this.domain = value;

          return this;
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 06:40 pm
      * @see id
      * @see login
      * @see intro
      * @see domain
      * @see setId
      * @see setLogin
      * @see setIntro
      * @see setDomain
      * @see store
      * @see toObject
      */
     public store(): this {
          StorageManager.setupModel  = this;
          StorageManager.setupId     = this.id;
          StorageManager.setupLogin  = this.login;
          StorageManager.setupIntro  = this.intro;
          StorageManager.setupDomain = this.domain;

          return this;
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 06:40 pm
      * @see id
      * @see login
      * @see intro
      * @see domain
      * @see setId
      * @see setLogin
      * @see setIntro
      * @see setDomain
      * @see store
      * @see toObject
      */
     public toObject(): Record<string, unknown> {
          return {
               id    : this.id,
               login : this.login,
               intro : this.intro,
               domain: this.domain?.toObject(),
          };
     }
}