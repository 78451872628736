import isNode            from 'detect-node';
import fetch             from 'isomorphic-unfetch';
import { ComponentEnum } from '../enum';
import {
     ApolloClient, ApolloClientOptions, HttpLink,
     InMemoryCache,
}                        from 'apollo-boost';

/**
 * @class AManager
 * @abstract
 * @author Isaac Ewing
 * @version 1.0.0 05/09/21 11:08 am
 */
export abstract class AManager {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/09/21 11:08 am
      */
     protected static readonly CONSOLE_PREFIX: string                           = `${ process.env.REACT_APP_CONSOLE_PREFIX_MANAGER } AMNG ${ process.env.REACT_APP_CONSOLE_SUFFIX_MANAGER }`;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/09/21 11:08 am
      */
     protected static readonly CONSOLE_ENABLED: boolean                         = false;
     /**
      *
      * @type {Map<ComponentEnum, Set<number>>}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/09/21 11:08 am
      */
     protected static readonly _queue: Map<ComponentEnum, Set<number>>          = new Map<ComponentEnum, Set<number>>();
     /**
      *
      * @type {Map<ComponentEnum, Map<number, unknown | any>>}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/09/21 11:08 am
      */
     protected static readonly _cache: Map<ComponentEnum, Map<number, unknown>> = new Map<ComponentEnum, Map<number, unknown>>();
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/09/21 11:08 am
      */
     protected static readonly processBrowser: boolean                          = !!process[ 'browser' ];
     /**
      *
      * @type {HttpLink}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/09/21 11:08 am
      */
     protected static readonly link: HttpLink                                   = new HttpLink( {
                                                                                                     uri  : process.env.REACT_APP_API_GRAPHQL_URL,
                                                                                                     fetch: !isNode && fetch,
                                                                                                     //fetch: !isBrowser && fetch,
                                                                                                } );
     /**
      *
      * @type {InMemoryCache}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/09/21 11:08 am
      */
     protected static readonly cache: InMemoryCache                             = new InMemoryCache();
     /**
      *
      * @type {ApolloClientOptions<any>}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/09/21 11:08 am
      */
     protected static readonly apolloOptions: ApolloClientOptions<any>          = {
          connectToDevTools: AManager.processBrowser,
          ssrMode          : !AManager.processBrowser,
          link             : AManager.link,
          cache            : AManager.cache,
     };
     /**
      *
      * @type {ApolloClient<any>}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/09/21 11:08 am
      */
     protected static readonly client: ApolloClient<any>                        = new ApolloClient( AManager.apolloOptions );
}