import React               from 'react';
import PropTypes           from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Util }            from '../../../api/com/ewing/social/tool/util';

/**
 * @class Input
 * @extends Component
 * @author Isaac Ewing
 * @version 1.0.0 02/05/21 12:18 pm
 * @classdesc This class handles the default contact functionality for the site
 */
export class Input extends React.Component<any, any> {
     protected static readonly COMPONENT_CLASS: string  = 'contact-13';
     protected static readonly CLASS_SHOW_ERROR: string = 'show-error';
     public static propTypes                            = {
          className         : PropTypes.object,
          'data-id'         : PropTypes.string.isRequired,
          'data-prefix'     : PropTypes.string,
          'data-placeholder': PropTypes.string,
          'data-error'      : PropTypes.string,
          'data-required'   : PropTypes.bool,
          'data-isBlank'    : PropTypes.bool,
          'data-isError'    : PropTypes.bool,
     };

     /**
      *
      * @param {string} value
      * @param {boolean} addBrackets
      * @return {string}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/05/21 12:29 pm
      */
     protected autoPrefix( value: string, addBrackets?: boolean ): string {
          return Util.getHashId( this.state.data.prefix, value, addBrackets );
     }

     /**
      *
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/15/21 02:31 pm
      */
     protected onIsChange(): void {
          const data = {
               ...this.state.data,
               isBlankClassName: this.props[ 'data-isBlank' ] ? Input.CLASS_SHOW_ERROR : null,
               isErrorClassName: this.props[ 'data-isError' ] ? Input.CLASS_SHOW_ERROR : null,
          };

          this.setState( { data } );
     }

     /**
      *
      * @param props {any}
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/05/21 12:18 pm
      */
     public constructor( props: any ) {
          super( props );

          this.state = {
               module   : props.module,
               page     : props.page,
               className: props.className ?? null,
               children : null,
               data     : {
                    id              : this.props[ 'data-id' ] ?? Util.registerHash(),
                    prefix          : this.props[ 'data-prefix' ] ?? process.env.REACT_APP_CONTACT_ID_PREFIX,
                    placeholder     : this.props[ 'data-placeholder' ] ?? null,
                    error           : this.props[ 'data-error' ] ?? `The ${ this.props[ 'data-id' ] } field in invalid.`,
                    isBlankClassName: this.props[ 'data-isBlank' ] ? Input.CLASS_SHOW_ERROR : null,
                    isErrorClassName: this.props[ 'data-isError' ] ? Input.CLASS_SHOW_ERROR : null,
               },
          };
     }

     /**
      *
      * @param {Readonly<any>} prevProps
      * @param {Readonly<any>} prevState
      * @param snapshot
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/05/21 01:22 pm
      */
     public componentDidUpdate( prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any ): void {
          const changeBlank: boolean = prevProps[ 'data-isBlank' ] !== this.props[ 'data-isBlank' ];
          const changeError: boolean = prevProps[ 'data-isError' ] !== this.props[ 'data-isError' ];

          if( changeBlank || changeError ) {
               this.onIsChange();
          }
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/05/21 12:18 pm
      */
     public render(): JSX.Element {
          const propsInput = {
               type       : 'text',
               name       : `${ this.autoPrefix( this.state.data.id, true ) }`,
               id         : `${ this.autoPrefix( this.state.data.id ) }`,
               className  : [ ...this.state?.className ].join( ' ' ),
               placeholder: this.state.data.placeholder,
          };
          const propsBlank = {
               id                  : `${ this.autoPrefix( this.state.data.id ) }Blank`,
               className           : [ 'form-error', this.state.data.isBlankClassName ].join( ' ' ),
               'data-form-error-on': 'required',
          };
          const propsError = {
               id                  : `${ this.autoPrefix( this.state.data.id ) }Error`,
               className           : [ 'form-error', this.state.data.isErrorClassName ].join( ' ' ),
               'data-form-error-on': 'pattern',
          };

          return (
               <>
                    <label htmlFor={ `${ this.autoPrefix( this.state.data.id ) }` }>{ this.state.data.id }</label>
                    <input { ...propsInput } />
                    <span { ...propsBlank }><FontAwesomeIcon icon={ [ 'far', 'times-circle' ] } />{ `The ${ this.state.data.id } field is required.` }</span>
                    <span { ...propsError }><FontAwesomeIcon icon={ [ 'far', 'times-circle' ] } />{ `Check your ${ this.state.data.id }, it's not valid.` }</span>
               </>
          );
     }
}
