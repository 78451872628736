import React            from 'react';
import PropTypes        from 'prop-types';
import { AmountModule } from '../../../api/com/ewing/social/module/donate/amount.module';
import { Util }         from '../../../api/com/ewing/social/tool/util';

export class Amount extends React.Component<any, any> {
     protected static COMPONENT_CLASS: string = 'amount-1';
     protected static TYPE_BUTTON: string     = 'button';
     protected static TYPE_INPUT: string      = 'input';
     protected static CLASS_BUTTON: string    = 'is-button';
     protected static CLASS_INPUT: string     = 'is-input';
     public static propTypes                  = {
          module: PropTypes.instanceOf( AmountModule ).isRequired,
     };

     /**
      *
      * @param {AmountModule} module
      * @return {JSX.Element}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/11/20 04:23 pm
      * @see registerButton
      * @see registerInput
      */
     protected registerButton( module: AmountModule ): JSX.Element {
          return (
               <button key={ Util.registerHash() } className={ [ 'button-link' ].join( ' ' ) }>
                    <div className="amount">{ `$${ module.amount }.00` }</div>
                    <div className="currency">{ process.env.REACT_APP_CURRENCY_AMERICA }</div>
               </button>
          );
     }

     /**
      *
      * @param {AmountModule} module
      * @return {JSX.Element}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/11/20 04:23 pm
      * @see registerButton
      * @see registerInput
      */
     protected registerInput( module: AmountModule ): JSX.Element {
          return (
               <div key={ Util.registerHash() } className={ Amount.TYPE_INPUT }>
                    <input type="text" />
               </div>
          );
     }

     /**
      *
      * @param props {any}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/11/20 04:23 pm
      */
     public constructor( props: any ) {
          super( props );

          this.state = {
               module     : props.module,
               initialized: false,
               type       : props.module.amount ? Amount.TYPE_BUTTON : Amount.TYPE_INPUT,
               className  : new Set<string>( [ Amount.COMPONENT_CLASS ] ),
               children   : new Set<JSX.Element>( [] ),
          };

          //this.registerStyle(props);
          // this.registerClasses( this.state?.module );
     }

     /**
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/11/20 04:23 pm
      */
     public componentDidMount(): void {
          switch( this.state.type ) {
               case Amount.TYPE_INPUT:
                    this.setState( {
                                        className: new Set<string>( [ ...this.state.className, Amount.CLASS_INPUT ] ),
                                        children : new Set<JSX.Element>( [ this.registerInput( this.state?.module ) ] ),
                                   } );
                    break;
               default:
                    this.setState( {
                                        className: new Set<string>( [ ...this.state.className, Amount.CLASS_BUTTON ] ),
                                        children : new Set<JSX.Element>( [ this.registerButton( this.state?.module ) ] ),
                                   } );
                    break;
          }
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/11/20 04:23 pm
      */
     public render(): JSX.Element {
          const props = {
               id           : this?.props?.id ?? Util.registerHash(),
               className    : [ ...this.state.className, this?.props?.className ].join( ' ' ),
               'data-amount': this.state?.module?.amount,
          };

          return (
               <div { ...props }>
                    { this.state.children }
               </div>
          );
     }
}