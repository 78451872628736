import { AComponent }            from '../../abstract';
import { ILink, ISubmenuModule } from '../../interface';
import { Util }                  from '../../tool';
import { LinkModule }            from '../../module/api/link.module';

/**
 * @class SubmenuModule
 * @extends AComponent
 * @implements ISubmenuModule
 * @author Isaac Ewing
 * @version 1.0.0 11/28/20 03:54 pm
 * @version 1.0.0 02/21/21 02:09 pm - updated to extend AComponent (through link module through title module)
 */
export class SubmenuModule extends AComponent implements ISubmenuModule {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 03:54 pm
      */
     protected static readonly CONSOLE_PREFIX: string     = `${ process.env.REACT_APP_CONSOLE_PREFIX_MODULE } SUBM ${ process.env.REACT_APP_CONSOLE_SUFFIX_MODULE }`;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/22/21 08:36 pm
      */
     protected static readonly CONSOLE_ENABLED: boolean   = false;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 02:09 pm
      */
     protected static readonly DEFAULT_JQUERY: string     = 'submenu';
     /**
      *
      * @return {LinkModule[]}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/02/20 02:10 pm
      */
     protected static readonly MENU_ABOUT: LinkModule[]   = [
          new LinkModule( null, 'about', 'summary' ),
          new LinkModule( null, 'about/fanmail', 'fanmail' ),
          new LinkModule( null, 'about/wishlist', 'wishlist' ),
          new LinkModule( null, 'about/donate', 'donate' ),
     ];
     /**
      *
      * @return {LinkModule[]}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/02/20 02:10 pm
      */
     protected static readonly MENU_PARTNER: LinkModule[] = [
          new LinkModule( null, 'partner', 'summary' ),
          new LinkModule( null, 'partner/affiliates', 'affiliates' ),
          new LinkModule( null, 'partner/sponsors', 'sponsors' ),
          new LinkModule( null, 'partner/apply', 'apply' ),
     ];
     /**
      *
      * @type {LinkModule[]}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 04/17/21 08:41 pm
      */
     protected static readonly MENU_TOOLS: LinkModule[]   = [
          new LinkModule( null, 'tools', 'summary' ),
          new LinkModule( null, 'tools/pencil', 'pencil' ),
          new LinkModule( null, 'tools/paintbrush', 'paintbrush' ),
          new LinkModule( null, 'tools/calculator', 'calculator' ),
          new LinkModule( null, 'tools/idea', 'idea' ),
     ];

     /**
      *
      * @param module {SubmenuModule} The object containing the data
      * @return {SubmenuModule|null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @version 1.0.0 02/21/21 02:46 pm
      * @see Build
      * @see BuildForAbout
      * @see BuildForPartner
      * @see Build
      * @see BuildForShortcode
      * @see id
      * @see text
      */
     public static Build( module: SubmenuModule ): SubmenuModule;
     public static Build( obj: Partial<ISubmenuModule> ): SubmenuModule;
     public static Build( json: string ): SubmenuModule;
     public static Build( link?: LinkModule, id?: number | string, hash?: string, className?: string, jquery?: string, shortcode?: boolean ): SubmenuModule;
     public static Build( links?: Iterable<LinkModule>, id?: number | string, hash?: string, className?: string, jquery?: string, shortcode?: boolean ): SubmenuModule;
     public static Build( links?: Iterable<LinkModule>, id?: number | string, hash?: string, className?: string, jquery?: string, shortcode?: boolean ): SubmenuModule;
     public static Build( link?: LinkModule, id?: number | string, hash?: string, classNames?: Iterable<string>, jquery?: string, shortcode?: boolean ): SubmenuModule;
     public static Build( links?: Iterable<LinkModule>, id?: number | string, hash?: string, classNames?: Iterable<string>, jquery?: string, shortcode?: boolean ): SubmenuModule;
     public static Build( links?: Iterable<LinkModule>, id?: number | string, hash?: string, classNames?: Iterable<string>, jquery?: string, shortcode?: boolean ): SubmenuModule;
     public static Build( dataOrLinks?: unknown, id?: number | string, hash?: string, classNames?: never, jquery?: string, shortcode?: boolean ): SubmenuModule {
          if( dataOrLinks ) {
               if( dataOrLinks instanceof SubmenuModule ) {
                    return dataOrLinks;
               }
               if( dataOrLinks instanceof LinkModule ) {
                    return new SubmenuModule( dataOrLinks ?? null, id ?? null, hash ?? null, classNames ?? null, jquery ?? null, shortcode ?? null );
               }
               if( dataOrLinks instanceof Set || Array.isArray( dataOrLinks ) ) {
                    const links: Set<LinkModule> = new Set<LinkModule>();

                    dataOrLinks.forEach( ( link: unknown ): void => {
                         links.add( LinkModule.Build( link ?? null ) );
                    } );

                    return new SubmenuModule( links ?? null, id ?? null, hash ?? null, classNames ?? null, jquery ?? null, shortcode ?? null );
               }
               if( typeof dataOrLinks === 'object' ) {
                    const localData: Partial<ISubmenuModule> = dataOrLinks;
                    const localLinks: Set<ILink> | ILink[]   = localData?.links ?? null;
                    const localModules: Set<LinkModule>      = new Set<LinkModule>();

                    localLinks.forEach( ( link: unknown ): Set<LinkModule> => localModules.add( LinkModule.Build( link ) ) );

                    return new SubmenuModule( localModules ?? null, localData?.id ?? null, localData?.hash ?? null, localData?.className ?? null,
                                              localData?.jquery ?? null, localData?.shortcode ?? null );
               }
               if( typeof dataOrLinks === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrLinks ) );
                    } catch( exception ) {
                         // not a valid json string
                         return new SubmenuModule( LinkModule.Build( dataOrLinks ) ?? null, id ?? null, hash ?? null, classNames ?? null, jquery ?? null,
                                                   shortcode ?? null );
                    }
               }
          }

          return null;
     }

     /**
      *
      * @param {string} page
      * @return {SubmenuModule}
      * @constructor
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/02/21 12:53 pm
      * @see Build
      * @see BuildForAbout
      * @see BuildForPartner
      * @see BuildForDetect
      */
     public static BuildForDetect( page: string ): SubmenuModule {
          const path: string[] = Util.cleanPath( page, false ).split( '/' );

          switch( path[ 0 ] ) {
               case'about':
                    return SubmenuModule.BuildForAbout();
               case'partner':
                    return SubmenuModule.BuildForPartner();
               case'tools':
                    return SubmenuModule.BuildForTools();
               default:
                    return null;
          }
     }

     /**
      *
      * @return {SubmenuModule}
      * @constructor
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 02:46 pm
      * @see Build
      * @see BuildForAbout
      * @see BuildForPartner
      * @see BuildForDetect
      */
     public static BuildForAbout(): SubmenuModule {
          return new SubmenuModule( this.MENU_ABOUT ?? null );
     }

     /**
      *
      * @return {SubmenuModule}
      * @constructor
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 02:46 pm
      * @see Build
      * @see BuildForAbout
      * @see BuildForPartner
      * @see BuildForDetect
      */
     public static BuildForPartner(): SubmenuModule {
          return new SubmenuModule( this.MENU_PARTNER ?? null );
     }

     /**
      *
      * @return {SubmenuModule}
      * @constructor
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 04/17/21 08:42 pm
      * @see Build
      * @see BuildForAbout
      * @see BuildForPartner
      * @see BuildForDetect
      */
     public static BuildForTools(): SubmenuModule {
          return new SubmenuModule( this.MENU_TOOLS ?? null );
     }

     /**
      *
      * @param link {LinkModule|LinkModule[]}
      * @param id {number | string} The object containing the data
      * @param hash {string}
      * @param className {string | string[]}
      * @param jquery {string}
      * @param shortcode {boolean} Indicates if this is being created for a shortcode or not
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 11/28/20 03:54 pm
      * @version 1.0.0 02/21/21 02:09 pm
      * TODO 02/21/21 02:26 pm
      *  check to make sure that the menu_partner and menu_about are not building array of modules each time they are called
      */
     public constructor( link?: LinkModule, id?: number | string, hash?: string, className?: string, jquery?: string, shortcode?: boolean );
     public constructor( links?: LinkModule[], id?: number | string, hash?: string, className?: string, jquery?: string, shortcode?: boolean );
     public constructor( links?: Set<LinkModule>, id?: number | string, hash?: string, className?: string, jquery?: string, shortcode?: boolean );
     public constructor( link?: LinkModule, id?: number | string, hash?: string, classNames?: Iterable<string>, jquery?: string, shortcode?: boolean );
     public constructor( links?: LinkModule[], id?: number | string, hash?: string, classNames?: Iterable<string>, jquery?: string, shortcode?: boolean );
     public constructor( links?: Set<LinkModule>, id?: number | string, hash?: string, classNames?: Iterable<string>, jquery?: string, shortcode?: boolean );
     public constructor( links?: never, id?: number | string, hash?: string, classNames?: never, jquery?: string, shortcode?: boolean ) {
          super( id, hash, classNames, jquery ?? SubmenuModule.DEFAULT_JQUERY, shortcode );

          this.links = new Set<LinkModule>();

          if( links ) {
               if( SubmenuModule.CONSOLE_ENABLED ) {
                    console.info( `${ SubmenuModule.CONSOLE_PREFIX } SUBMENU MODULE`, { links, id, hash, classNames, jquery, shortcode } );
               }

               this.addLink( links );
          }
     }

     /**
      *
      * @return {Set<LinkModule>} Returns the value for the property
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 10/03/20 02:34 pm
      * @see id
      * @see submenu
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setColumns
      * @see setLinks
      * @see addLink
      * @see linksToArray
      */
     public get links(): Set<LinkModule> {
          return this._data.get( 'links' );
     }

     /**
      *
      * @param value {Set<LinkModule>} The string to set for the property
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 10/03/20 01:44 pm
      * @see id
      * @see submenu
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setColumns
      * @see setLinks
      * @see addLink
      * @see linksToArray
      */
     public set links( value: Set<LinkModule> ) {
          this._data.set( 'links', value );
     }

     /**
      *
      * @param value {LinkModule|Set<LinkModule>}
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 02:38 pm
      * @see id
      * @see links
      * @see setLinks
      * @see addLink
      * @see linksToArray
      */
     public setLinks( value: LinkModule ): this;
     public setLinks( values: LinkModule[] ): this;
     public setLinks( values: Set<LinkModule> ): this;
     public setLinks( values: unknown ): this {
          if( values ) {
               this._data.delete( 'links' );
               this._data.set( 'links', new Set<LinkModule>() );
               this.addLink( values );
          }

          return this;
     }

     /**
      *
      * @param module {LinkModule} An array or object or id with the data
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 02:38 pm
      * @see id
      * @see links
      * @see setLinks
      * @see addLink
      * @see linksToArray
      */
     public addLink( module: LinkModule ): this;
     public addLink( modules: LinkModule[] ): this;
     public addLink( modules: Set<LinkModule> ): this;
     public addLink( obj: Partial<ILink> ): this;
     public addLink( objects: Partial<ILink>[] ): this;
     public addLink( objects: Set<Partial<ILink>> ): this;
     public addLink( data: unknown, path?: string, text?: string, icon?: string, submit?: boolean, shortcode?: boolean ): this {
          if( data instanceof LinkModule ) {
               this.links.add( data );
          } else if( data instanceof Set || Array.isArray( data ) ) {
               data.forEach( ( element: unknown ): void => {
                    this.addLink( element );
               } );
          } else if( typeof data === 'object' ) {
               const local: Partial<ILink> = data;
               const module: LinkModule    = new LinkModule( local?.id ?? null, local?.path ?? null, local?.text ?? null, local?.icon ?? null, local?.submit ?? null,
                                                             local?.shortcode ?? null );

               this.links.add( module ?? null );

               if( SubmenuModule.CONSOLE_ENABLED ) {
                    console.info( `${ SubmenuModule.CONSOLE_PREFIX } SUBMENU MODULE`, { local, module } );
               }
          } else if( data && path ) {
               this.links.add( LinkModule.Build( { id: +data, path, text, icon, submit, shortcode } ) );
          } else {
               console.log( 'the link was invalid', { id: data, path, text, icon, submit, shortcode } );
          }

          return this;
     }

     /**
      *
      * @param {boolean} [primitive=false] Indicates if the values should be transformed into their basic types
      * @return {LinkModule[] | object[]}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 02:38 pm
      * @version 1.1.0 02/24/21 05:13 pm - added support for primitive transformation vs normal conversion to array
      * @see id
      * @see links
      * @see setLinks
      * @see addLink
      * @see linksToArray
      */
     public linksToArray( primitive?: boolean ): LinkModule[] | Record<string, unknown>[] {
          if( this.links ) {
               if( primitive ) {
                    let test: Record<string, unknown>;
                    const links: Record<string, unknown>[] = [];

                    this.links.forEach( ( link: LinkModule ): void => {
                         test = link.toObject();

                         if( test ) {
                              links.push( test );
                         }
                    } );

                    return links;
               } else {
                    return [ ...this.links ];
               }
          }

          return null;
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/03/20 12:12 pm
      * @see id
      * @see text
      * @see path
      * @see icon
      * @see jqueryId
      * @see submit
      * @see enableSubmit
      * @see disableSubmit
      */
     public toObject(): Record<string, unknown> {
          return {
               ...super.toObject(),
               links: this.linksToArray( true ),
          };
     }
}
