import React             from 'react';
import { TypeEnum }      from '../../api/com/ewing/social/enum';
import { SectionModule } from '../../api/com/ewing/social/component';
import { ISectionProps } from '../../api/com/ewing/social/interface';
import { SiteModule }    from '../../api/com/ewing/social/module';
import { BaseSection }   from '../atom/BaseSection';
import {
     Contact1, Contact12, Contact13,
}                        from './contact';
import {
     Donate1,
}                        from './donate';
import {
     Event1,
}                        from './event';
import {
     Hero1, Hero2,
}                        from './hero';
import {
     Invite1,
}                        from './invite';
import {
     Legal1,
}                        from './legal';
import {
     Map1,
}                        from './map';
import {
     Newsletter1,
}                        from './newsletter';
import {
     Viewer1,
}                        from './viewer';
import {
     Layout1, Layout2,
     Layout3, Layout4,
     Layout6, Layout7,
     Layout8, Layout9,
     Layout10, Layout11,
     Layout15,
}                        from './layout';
import { Util }          from '../../api/com/ewing/social/tool';

/**
 *
 * @class Section
 * @extends BaseSection
 * @author Isaac Ewing
 * @version 1.0.0 12/07/20 01:39 pm
 */
export class Section extends BaseSection {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/07/20 01:39 pm
      */
     protected static readonly COMPONENT_CLASS: string = 'section';

     /**
      *
      * @param data {SectionModule} The module with all the data
      * @param page {SiteModule} The module with all the data
      * @return {JSX.Element}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 11/04/20 03:31 pm
      * @see registerContact
      * @see registerDonate
      * @see registerEvent
      * @see registerHero
      * @see registerInvite
      * @see registerLayout
      * @see registerLegal
      * @see registerMap
      * @see registerNewsletter
      * @see registerViewer
      * @see registerSection
      */
     protected registerContact( data: SectionModule, page: SiteModule ): JSX.Element {
          switch( data.layout.id ) {
               case 1:
                    return <Contact1 key={ Util.registerHash() } module={ data } page={ page } />;
               case 12:
                    return <Contact12 key={ Util.registerHash() } module={ data } page={ page } />;
               case 13:
                    return <Contact13 key={ Util.registerHash() } module={ data } page={ page } />;
               default:
                    return <div key={ Util.registerHash() }>this is a default CONTACT layout</div>;
          }
     }

     /**
      *
      * @param data {SectionModule} The module with all the data
      * @param page {SiteModule} The module with all the data
      * @return {JSX.Element}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/10/20 01:02 pm
      * @see registerContact
      * @see registerDonate
      * @see registerEvent
      * @see registerHero
      * @see registerInvite
      * @see registerLayout
      * @see registerLegal
      * @see registerMap
      * @see registerNewsletter
      * @see registerViewer
      * @see registerSection
      */
     protected registerDonate( data: SectionModule, page: SiteModule ): JSX.Element {
          switch( data.layout.id ) {
               case 1:
                    return <Donate1 key={ Util.registerHash() } module={ data } page={ page } />;
               default:
                    return <div key={ Util.registerHash() }>this is a default DONATE layout</div>;
          }
     }

     /**
      *
      * @param data {SectionModule} The module with all the data
      * @param page {SiteModule} The module with all the data
      * @return {JSX.Element}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/29/21 12:25 pm
      * @see registerContact
      * @see registerDonate
      * @see registerEvent
      * @see registerHero
      * @see registerInvite
      * @see registerLayout
      * @see registerLegal
      * @see registerMap
      * @see registerNewsletter
      * @see registerViewer
      * @see registerSection
      */
     protected registerEvent( data: SectionModule, page: SiteModule ): JSX.Element {
          switch( data.layout.id ) {
               case 1:
                    return <Event1 key={ Util.registerHash() } module={ data } page={ page } />;
               default:
                    return <div key={ Util.registerHash() }>this is a default EVENT layout</div>;
          }
     }

     /**
      *
      * @param data {SectionModule} The module with all the data
      * @param page {SiteModule} The module with all the data
      * @return {JSX.Element}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 11/04/20 03:31 pm
      * @see registerContact
      * @see registerDonate
      * @see registerEvent
      * @see registerHero
      * @see registerInvite
      * @see registerLayout
      * @see registerLegal
      * @see registerMap
      * @see registerNewsletter
      * @see registerViewer
      * @see registerSection
      */
     protected registerHero( data: SectionModule, page: SiteModule ): JSX.Element {
          switch( data.layout.id ) {
               case 1:
                    return <Hero1 key={ Util.registerHash() } module={ data } page={ page } />;
               case 2:
                    return <Hero2 key={ Util.registerHash() } module={ data } page={ page } />;
               default:
                    return <div key={ Util.registerHash() }>this is a default HERO layout</div>;
          }
     }

     /**
      *
      * @param data {SectionModule} The module with all the data
      * @param page {SiteModule} The module with all the data
      * @return {JSX.Element}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 11/04/20 03:31 pm
      * @see registerContact
      * @see registerDonate
      * @see registerEvent
      * @see registerHero
      * @see registerInvite
      * @see registerLayout
      * @see registerLegal
      * @see registerMap
      * @see registerNewsletter
      * @see registerViewer
      * @see registerSection
      */
     protected registerInvite( data: SectionModule, page: SiteModule ): JSX.Element {
          switch( data.layout.id ) {
               case 1:
                    return <Invite1 key={ Util.registerHash() } module={ data } page={ page } />;
               default:
                    return <div key={ Util.registerHash() }>this is a default INVITE layout</div>;
          }
     }

     /**
      *
      * @param data {SectionModule} The module with all the data
      * @param page {SiteModule} The module with all the data
      * @return {JSX.Element}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 11/04/20 03:31 pm
      * @see registerContact
      * @see registerDonate
      * @see registerEvent
      * @see registerHero
      * @see registerInvite
      * @see registerLayout
      * @see registerLegal
      * @see registerMap
      * @see registerNewsletter
      * @see registerViewer
      * @see registerSection
      */
     protected registerLayout( data: SectionModule, page: SiteModule ): JSX.Element {
          switch( data.layout.id ) {
               case 1:
                    return <Layout1 key={ Util.registerHash() } module={ data } page={ page } />;
               case 2:
                    return <Layout2 key={ Util.registerHash() } module={ data } page={ page } />;
               case 3:
                    return <Layout3 key={ Util.registerHash() } module={ data } page={ page } />;
               case 4:
                    return <Layout4 key={ Util.registerHash() } module={ data } page={ page } />;
               case 5:
                    return <div key={ Util.registerHash() }>this is a default LAYOUT 5</div>;
               case 6:
                    return <Layout6 key={ Util.registerHash() } module={ data } page={ page } />;
               case 7:
                    return <Layout7 key={ Util.registerHash() } module={ data } page={ page } />;
               case 8:
                    return <Layout8 key={ Util.registerHash() } module={ data } page={ page } />;
               case 9:
                    return <Layout9 key={ Util.registerHash() } module={ data } page={ page } />;
               case 10:
                    return <Layout10 key={ Util.registerHash() } module={ data } page={ page } />;
               case 11:
                    return <Layout11 key={ Util.registerHash() } module={ data } page={ page } />;
               case 15:
                    return <Layout15 key={ Util.registerHash() } module={ data } page={ page } />;
               default:
                    return <div key={ Util.registerHash() }>this is a default LAYOUT layout</div>;
          }
     }

     /**
      *
      * @param data {SectionModule} The module with all the data
      * @param page {SiteModule} The module with all the data
      * @return {JSX.Element}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 11/26/20 04:04 pm
      * @see registerContact
      * @see registerDonate
      * @see registerEvent
      * @see registerHero
      * @see registerInvite
      * @see registerLayout
      * @see registerLegal
      * @see registerMap
      * @see registerNewsletter
      * @see registerViewer
      * @see registerSection
      */
     protected registerLegal( data: SectionModule, page: SiteModule ): JSX.Element {
          switch( data.layout.id ) {
               case 1:
                    return <Legal1 key={ Util.registerHash() } module={ data } page={ page } />;
               default:
                    return <div key={ Util.registerHash() }>this is a default LEGAL layout</div>;
          }
     }

     /**
      *
      * @param data {SectionModule} The module with all the data
      * @param page {SiteModule} The module with all the data
      * @return {JSX.Element}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/07/20 01:36 pm
      * @see registerContact
      * @see registerDonate
      * @see registerEvent
      * @see registerHero
      * @see registerInvite
      * @see registerLayout
      * @see registerLegal
      * @see registerMap
      * @see registerNewsletter
      * @see registerViewer
      * @see registerSection
      */
     protected registerMap( data: SectionModule, page: SiteModule ): JSX.Element {
          switch( data.layout.id ) {
               case 1:
                    return <Map1 key={ Util.registerHash() } module={ data } page={ page } />;
               default:
                    return <div key={ Util.registerHash() }>this is a default MAP layout</div>;
          }
     }

     /**
      *
      * @param data {SectionModule} The module with all the data
      * @param page {SiteModule} The module with all the data
      * @return {JSX.Element}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/05/20 04:50 pm
      * @see registerContact
      * @see registerDonate
      * @see registerEvent
      * @see registerHero
      * @see registerInvite
      * @see registerLayout
      * @see registerLegal
      * @see registerMap
      * @see registerNewsletter
      * @see registerViewer
      * @see registerSection
      */
     protected registerNewsletter( data: SectionModule, page: SiteModule ): JSX.Element {
          switch( data.layout.id ) {
               case 1:
                    return <Newsletter1 key={ Util.registerHash() } module={ data } page={ page } />;
               default:
                    return <div key={ Util.registerHash() }>this is a default NEWSLETTER layout</div>;
          }
     }

     /**
      *
      * @param data {SectionModule} The module with all the data
      * @param page {SiteModule} The module with all the data
      * @return {JSX.Element}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/05/20 04:50 pm
      * @see registerContact
      * @see registerDonate
      * @see registerEvent
      * @see registerHero
      * @see registerInvite
      * @see registerLayout
      * @see registerLegal
      * @see registerMap
      * @see registerNewsletter
      * @see registerViewer
      * @see registerSection
      */
     protected registerViewer( data: SectionModule, page: SiteModule ): JSX.Element {
          switch( data.layout.id ) {
               case 1:
                    return <Viewer1 key={ Util.registerHash() } module={ data } page={ page } />;
               default:
                    return <div key={ Util.registerHash() }>this is a default VIEWER layout</div>;
          }
     }

     /**
      *
      * @param data {SectionModule} The module with all the data
      * @param page {SiteModule} The module with all the data
      * @return {JSX.Element}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 11/04/20 03:31 pm
      * @see registerContact
      * @see registerDonate
      * @see registerEvent
      * @see registerHero
      * @see registerInvite
      * @see registerLayout
      * @see registerLegal
      * @see registerMap
      * @see registerNewsletter
      * @see registerViewer
      * @see registerSection
      */
     protected registerSection( data: SectionModule, page: SiteModule ): JSX.Element {
          console.log( '+++ adding', { type: data?.type ?? null, data, page } );

          switch( data?.type ) {
               case TypeEnum.Contact:
                    return this.registerContact( data, page );
               case TypeEnum.Donate:
                    return this.registerDonate( data, page );
               case TypeEnum.Event:
                    return this.registerEvent( data, page );
               case TypeEnum.Hero:
                    return this.registerHero( data, page );
               case TypeEnum.Invite:
                    return this.registerInvite( data, page );
               case TypeEnum.Layout:
                    return this.registerLayout( data, page );
               case TypeEnum.Legal:
                    return this.registerLegal( data, page );
               case TypeEnum.Map:
                    return this.registerMap( data, page );
               case TypeEnum.Newsletter:
                    return this.registerNewsletter( data, page );
               case TypeEnum.Viewer:
                    return this.registerViewer( data, page );
               default:
                    return <div key={ Util.registerHash() }>this is a DEFAULT layout</div>;
          }
     }

     /**
      *
      * @param props {unknown}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/07/20 01:39 pm
      */
     public constructor( props?: ISectionProps ) {
          super( props );

          this.state = {
               data     : props.data ?? null,
               module   : props.module,
               page     : props.page,
               className: new Set<string>( [ 'grid-x', Section.COMPONENT_CLASS ] ),
          };
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 11/04/20 03:31 pm
      * @see registerContact
      * @see registerDonate
      * @see registerEvent
      * @see registerHero
      * @see registerInvite
      * @see registerLayout
      * @see registerLegal
      * @see registerMap
      * @see registerNewsletter
      * @see registerViewer
      * @see registerSection
      */
     public render(): JSX.Element {
          return this.registerSection( this.props.module as SectionModule, this.props.page as SiteModule );
     }
}