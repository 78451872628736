import PropTypes         from 'prop-types';
import { SectionModule } from '../../../api/com/ewing/social/component';
import { IEventState }   from '../../../api/com/ewing/social/interface';
import { ISectionProps } from '../../../api/com/ewing/social/interface/component';
import { SiteModule }    from '../../../api/com/ewing/social/module';
import { Base }          from '../Base';

/**
 *
 * @class EventBase
 * @extends Base
 * @author Isaac Ewing
 * @version 1.0.0 01/29/21 12:06 pm
 */
export abstract class EventBase extends Base<ISectionProps, IEventState> {
     protected static COMPONENT_CLASS = 'event';
     /**
      *
      * @type {Object}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/29/21 12:06 pm
      */
     public static propTypes          = {
          ...Base.propTypes,
          module: PropTypes.instanceOf( SectionModule ).isRequired,
          page  : PropTypes.instanceOf( SiteModule ).isRequired,
     };

     /**
      *
      * @param props {any}
      * @return {void}
      * @throws Throws an exception if method is called without having an override
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/29/21 12:06 pm
      */
     protected registerPropsCall( props: ISectionProps ): void {
          // this.registerStyle( props );
          this.registerClasses( props );
          // this.registerCaps( props );
          // this.registerPassword( props );
     }

     /**
      *
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/29/21 12:06 pm
      */
     public abstract componentWillUnmount(): void;
}