import PropTypes                  from 'prop-types';
import React                      from 'react';
import jQuery                     from 'jquery';
import { IBaseState, ITextProps } from '../../../../api/com/ewing/social/interface';
import { ConsoleManager }         from '../../../../api/com/ewing/social/manager';
import { TitleModule }            from '../../../../api/com/ewing/social/module';
import { Util }                   from '../../../../api/com/ewing/social/tool';
import { Subtitle }               from '../../Subtitle';
import { Character }              from './Character';

/**
 *
 * @class Text
 * @extends React.Component
 * @author Isaac Ewing
 * @version 1.0.0 12/24/20 01:35 pm
 */
export class Text extends React.Component {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 06:23 pm
      */
     protected static readonly CONSOLE_PREFIX: string                    = `${ process.env.REACT_APP_CONSOLE_PREFIX_COMPONENT } TEXT ${ process.env.REACT_APP_CONSOLE_SUFFIX_COMPONENT }`;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 06:23 pm
      */
     protected static readonly CONSOLE_ENABLED: boolean                  = true;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/24/20 01:35 pm
      */
     protected static readonly COMPONENT_CLASS: string                   = 'input-text';
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/24/20 01:35 pm
      */
     protected static readonly CLASS_INPUT_LABEL: string                 = 'input-label';
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/24/20 01:35 pm
      */
     protected static readonly CLASS_MIDDLE_SPACER: string               = 'middle-spacer';
     /**
      *
      * @type {Record<string, number | string>}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/24/20 01:35 pm
      */
     protected static readonly DEFAULTS: Record<string, number | string> = {
          text  : '',
          length: 10,
     };
     public static propTypes: unknown                                    = {
          caps    : PropTypes.bool,
          password: PropTypes.bool,
          disabled: PropTypes.bool,
          animate : PropTypes.bool,
          fadeIn  : PropTypes.bool,
          length  : PropTypes.number,
          text    : PropTypes.string,
          label   : PropTypes.string,
     };
     public readonly props: Partial<ITextProps>;
     public state: Partial<IBaseState>;

     /**
      *
      * @param props {any}
      * @return {void}
      * @throws Throws an exception if method is called without having an override
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/24/20 01:24 pm
      */
     protected registerPropsCall( props: ITextProps ): void {
          //this.registerStyle( props );
          //this.registerClasses( props );
          this.registerCharacters( props );
     }

     /**
      *
      * @param props {any}
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/25/20 02:26 pm
      */
     protected registerCharacters( props: ITextProps ): void {
          let hasNoChars: boolean;
          let characters: string[];
          const children: Set<JSX.Element> = new Set<JSX.Element>();
          const text: string               = props?.text ?? Util.registerHash( props.length );
          const properties                 = {
               caps    : props.caps ?? null,
               password: props.password ?? null,
               disabled: props.disabled ?? null,
               animate : props.animate ?? null,
               fadeIn  : props.fadeIn ?? null,
          };

          if( props.length ) {
               if( Text.CONSOLE_PREFIX ) {
                    ConsoleManager.Log( Text.CONSOLE_PREFIX, 'TEXT', 'Text is registering characters...', { length: props.length, text: props?.text ?? 'NO TEXT' } );
               }
               if( !props.text ) {
                    hasNoChars = true;
               }

               characters = text.slice( 0, props.length ).split( '' );
          } else {
               characters = text.split( '' );
          }
          if( Text.CONSOLE_PREFIX ) {
               ConsoleManager.Log( Text.CONSOLE_PREFIX, 'TEXT', 'Text is building characters...', { characters, hasNoChars } );
          }
          if( characters.length % 2 === 0 ) {
               const middle: number = characters.length >> 1;

               for( let i = 0, total: number = characters.length; i < total; i++ ) {
                    const key = `${ this.state.hash }-char${ i }`;

                    console.log( 'character being added', !hasNoChars ? characters[ i ] : null, !hasNoChars );
                    if( i !== middle ) {
                         children.add( <Character key={ key } id={ key } { ...properties } text={ !hasNoChars ? characters[ i ] : null } tabIndex={ i + 1 } /> );
                    } else {
                         const props = {
                              key      : key,
                              id       : key,
                              className: Text.CLASS_MIDDLE_SPACER,
                         };

                         children.add( <Character { ...props } { ...properties } text={ !hasNoChars ? characters[ i ] : null } tabIndex={ i + 1 } /> );
                    }
               }
          } else {
               for( let i = 0, total: number = characters.length; i < total; i++ ) {
                    const key: string = `${ this.state.hash }-char${ i }`;

                    children.add( <Character key={ key } id={ key } { ...properties } text={ !hasNoChars ? characters[ i ] : null } tabIndex={ i + 1 } /> );
               }
          }
          if( children.size ) {
               children.forEach( ( child: JSX.Element ): void => {
                    this.state.children.add( child );
               } );
          }
     }

     /**
      *
      * @param props {any}
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/24/20 01:35 pm
      */
     public constructor( props: ITextProps ) {
          super( props );

          this.state = {
               hash     : Util.registerHash(),
               className: new Set<string>( [ Text.COMPONENT_CLASS ] ),
               children : new Set<JSX.Element>(),
          };

          //this.registerProps( props );
          this.registerCharacters( props );
     }

     /**
      *
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 07:10 pm
      */
     public componentDidMount(): void {
          jQuery( `#${ this.state.hash }-char0` ).focus();
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/24/20 01:24 pm
      */
     public render(): JSX.Element {
          let subtitle: JSX.Element;

          if( this.props.label ) {
               const codeText: TitleModule = TitleModule.Build( { text: this.props.label } );

               subtitle = <Subtitle key={ Util.registerHash() } module={ codeText } className="manual string" />;
          }

          return (
               <>
                    <div className={ [ ...this.state.className ].join( ' ' ) }>
                         { this.state.children }
                    </div>
                    <div className={ [ ...this.state.className, Text.CLASS_INPUT_LABEL ].join( ' ' ) }>
                         { subtitle }
                    </div>
               </>
          );
     }
}