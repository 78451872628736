import React                                from 'react';
import PropTypes                            from 'prop-types';
import JsxParser                            from 'react-jsx-parser';
import { IBaseState, IDescriptionProps }    from '../../api/com/ewing/social/interface';
import { TitleModule }                      from '../../api/com/ewing/social/module';
import { ConsoleManager, ShortcodeManager } from '../../api/com/ewing/social/manager';
import { RegisterTool }                     from '../../api/com/ewing/social/tool';
import { Base }                             from './Base';
import { Shortcode }                        from './Shortcode';

/**
 * @class Description
 * @extends Base
 * @author Isaac Ewing
 * @version 1.0.0 12/22/20 06:25 pm
 */
export class Description extends Base<Partial<IDescriptionProps>, Partial<IBaseState>> {
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/22/20 06:25 pm
     */
    protected static readonly COMPONENT_CLASS: string  = 'description';
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 08/01/21 09:32 am
     */
    protected static readonly CONSOLE_PREFIX: string   = `${ process.env.REACT_APP_CONSOLE_PREFIX_COMPONENT } DESC ${ process.env.REACT_APP_CONSOLE_SUFFIX_COMPONENT }`;
    /**
     *
     * @type {boolean}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 08/01/21 09:32 am
     */
    protected static readonly CONSOLE_ENABLED: boolean = true;
    public static propTypes                            = {
        ...Base.propTypes,
        module: PropTypes.instanceOf( TitleModule ).isRequired,
    };
    /**
     *
     * @type {Partial<IDescriptionProps>}
     * @readonly
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 05/29/21 07:18 pm
     */
    public readonly props: Partial<IDescriptionProps>;
    /**
     *
     * @type {Partial<IBaseState>}
     * @readonly
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 08/01/21 09:29 am
     */
    public readonly state: Partial<IBaseState>;

    /**
     *
     * @param exception
     * @return {void}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/22/20 06:25 pm
     */
    protected onError( exception: Error ): void {
        console.trace( '==X== DESCRIPTION EXCEPTION', exception );
    }

    /**
     *
     * @param props
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/22/20 06:25 pm
     */
    public constructor( props: IDescriptionProps ) {
        super( props );

        try {
            this.state = {
                text      : ShortcodeManager.parse( props.module.text ),
                className : new Set<string>( [ Description.COMPONENT_CLASS ] ),
                children  : new Set<JSX.Element>( props?.children ?? [] ),
                components: {
                    Shortcode,
                },
            };
        } catch( exception: unknown ) {
            ConsoleManager.Warn( Description.CONSOLE_ENABLED ?? null, Description.CONSOLE_PREFIX ?? null, 'Constructor', 'try catch, see exception', { props, exception } );
        }

        RegisterTool.classes( props ?? null, this.state ?? null );
    }

    /**
     *
     * @param {Error} error
     * @param {React.ErrorInfo} errorInfo
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 06/01/21 07:28 pm
     */
    public componentDidCatch( error: Error, errorInfo: React.ErrorInfo ): void {
        console.error( 'DESCRIPTION JSX', { error, errorInfo } );
    }

    /**
     *
     * @return {JSX.Element}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/22/20 06:23 pm documented
     * @version 1.1.0 12/22/20 06:24 pm added react-jsx-parser to replace dangerously set inner html
     * @version 1.1.0 12/22/20 06:25 pm simplified and added state binding
     */
    public render(): JSX.Element {
        return (
            <>
                <div className={ [ ...this.state.className ].join( ' ' ) }>
                    <JsxParser renderInWrapper={ false } components={ this.state.components } jsx={ this.state.text } onError={ this.onError } autoCloseVoidElements />
                </div>
                { this.state.children }
            </>
        );
    }
}
