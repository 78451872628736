import React            from 'react';
import { ParticleBase } from './ParticleBase';
import { Util }         from '../../../api/com/ewing/social/tool';

export class Particle1 extends ParticleBase<any, any> {
     public constructor( props: any ) {
          super( props );

          this.state = {
               type            : Particle1.PARTICLE.type1,
               hash            : Util.registerHash(),
               className       : [],
               action          : true,
               canvas          : null,
               dimensions      : props.dimensions,
               particles       : {
                    max      : props.particleMax,
                    frequency: props.particleFrequency,
                    children : [],
               },
               visibilitySensor: {
                    active  : true,
                    onChange: ( visible: boolean ) => { this.registerOnChange( visible ); },
               },
          };

          // this.registerClasses( props );
     }
}

export default Particle1;