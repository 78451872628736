import PropTypes                                    from 'prop-types';
import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import { ComponentEnum, ShortcodeActionEnum }       from '../../api/com/ewing/social/enum';
import { ShortcodeModule }                          from '../../api/com/ewing/social/component/atom/shortcode.module';
import { IBaseState, IModule, IShortcodeProps }     from '../../api/com/ewing/social/interface';
import { ShortcodeObserver }                        from '../../api/com/ewing/social/observer';
import { ConsoleManager, CacheManager }             from '../../api/com/ewing/social/manager';
import { FactoryAtom }                              from './FactoryAtom';

/**
 * @class Shortcode
 * @extends Component
 * @author Isaac Ewing
 * @version 1.0.0 12/24/20 12:33 pm
 */
export class Shortcode extends React.Component<Partial<IShortcodeProps>, Partial<IBaseState>> {
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/24/20 12:33 pm
     */
    protected static readonly CONSOLE_PREFIX: string   = `${ process.env.REACT_APP_CONSOLE_PREFIX_COMPONENT } \\SC/ ${ process.env.REACT_APP_CONSOLE_SUFFIX_COMPONENT }`;
    /**
     *
     * @type {boolean}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/27/21 11:23 pm
     */
    protected static readonly CONSOLE_ENABLED: boolean = true;
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/24/20 12:33 pm
     */
    protected static readonly LOADING_TEXT: string     = process.env.REACT_APP_SHORTCODE_LOADING_TEXT ?? 'loading-now...';
    public static propTypes                            = {
        module       : PropTypes.instanceOf( ShortcodeModule ),
        dataId       : PropTypes.string,
        dataType     : PropTypes.oneOf( Object.values( ComponentEnum ) as ComponentEnum[] ),
        dataHash     : PropTypes.string,
        dataCode     : PropTypes.string,
        dataShortcode: PropTypes.bool,
    };
    /**
     *
     * @type {boolean}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 03/22/21 03:36 pm
     */
    protected isMounted: boolean                       = false;
    /**
     *
     * @type {any}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 03/22/21 03:04 pm
     */
    protected queueState: any;
    /**
     *
     * @type {IShortcodeProps}
     * @readonly
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 06/01/21 07:04 pm
     */
    public readonly props: Partial<IShortcodeProps>    = {};
    /**
     *
     * @type {Partial<IBaseState>}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 06/01/21 07:04 pm
     */
    public state: Partial<IBaseState>                  = {};

    /**
     *
     * @param {Partial<IShortcodeProps>} props
     * @return {string}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 03/27/21 01:56 pm
     * @version 1.1.0 06/02/21 10:59 am - added support for passing in props (just in case the component props are not ready... typecasting issue)
     */
    protected buildShortcodeHash( props?: Partial<IShortcodeProps> ): string | null {
        if( props ) {
            return `${ props?.dataType }-${ props?.dataId }-${ props?.dataHash }`;
        }
        if( this.props ) {
            return `${ this.props.dataType }-${ this.props.dataId }-${ this.props.dataHash }`;
        } else {
            console.warn( 'SHORTCODE HASH ERROR, NO PROPS', { thisProps: this.props ?? null, props: props ?? null } );
        }

        return null;
    }

    /**
     *
     * @param {IModule} template
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/24/20 12:33 pm
     */
    protected onShortcodeObserver( template: IModule ): void;
    protected onShortcodeObserver( module: ShortcodeModule ): void;
    protected onShortcodeObserver( data: ShortcodeModule ): void {
        if( data ) {
            const module: ShortcodeModule = data as ShortcodeModule;
            const consoleTitle: string    = `${ this.props.dataType }--${ this.props.dataId }[ ${ this.props.dataHash } ]`;

            try {
                `${ module.type }--${ module.id } `;
            } catch( exception: unknown ) {
                if( Shortcode.CONSOLE_ENABLED ) {
                    ConsoleManager.BrightYellow( Shortcode.CONSOLE_PREFIX, consoleTitle, `on shortcode observer called`, data );
                }
            }

            const moduleTitle: string = `${ module.type }--${ module.id } `;

            ConsoleManager.LightGrey( Shortcode.CONSOLE_PREFIX, consoleTitle, `observer called... ${ moduleTitle }complete: ${ module.complete }` );

            if( module.action === ShortcodeActionEnum.Build ) {
                ConsoleManager.TealBlue( Shortcode.CONSOLE_PREFIX, consoleTitle, `SKIP ${ moduleTitle } [BUILD] existing query element` );
            } else if( module.action === ShortcodeActionEnum.Add ) {
                ConsoleManager.TealBlue( Shortcode.CONSOLE_PREFIX, consoleTitle, `SKIP ${ moduleTitle } [ADD] existing query element` );
            } else if( module.action === ShortcodeActionEnum.Query ) {
                ConsoleManager.TealBlue( Shortcode.CONSOLE_PREFIX, consoleTitle, `SKIP ${ moduleTitle } [QUERY] existing query element` );
            } else if( module.action === ShortcodeActionEnum.Cache ) {
                ConsoleManager.TealBlue( Shortcode.CONSOLE_PREFIX, consoleTitle, `CACHE ${ moduleTitle } existing module element` );

                if( module.isMatch( this?.props.dataType ?? null, +( this?.props?.dataId ?? 0 ) ) ) {
                    const shortcode: JSX.Element | null = FactoryAtom.FromShortcode( module );

                    if( this.isMounted ) {
                        if( shortcode ) {
                            this.setState( { children: new Set( [ shortcode ] ) } );
                        }

                        ShortcodeObserver.unsubscribe( this.buildShortcodeHash() ?? null );
                        ConsoleManager.BrightGreen( Shortcode.CONSOLE_PREFIX, consoleTitle, `UNSUBSCRIBED ${ moduleTitle }...` );
                        ConsoleManager.TealBlue( Shortcode.CONSOLE_PREFIX, consoleTitle, `CACHE ${ moduleTitle } existing module element`, { module } );
                    } else {
                        this.queueState = { children: new Set( [ shortcode ] ) };
                        ConsoleManager.DarkBlue( Shortcode.CONSOLE_PREFIX, consoleTitle, `SAVING future state to queue` );
                    }
                } else {
                    ConsoleManager.BrightRed( Shortcode.CONSOLE_PREFIX, consoleTitle, `DOES NOT MATCH, ${ moduleTitle }` );
                }
            }
        } else {
            console.warn( 'onShortcodeObserver data BLANK', { data } );
        }
    }

    /**
     *
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 12:40 pm
     */
    public constructor( props: Partial<IShortcodeProps> ) {
        super( props );

        const params: DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> = {
            key             : props?.dataKey,
            id              : props?.dataKey,
            'data-id'       : props?.dataId ?? null,
            'data-type'     : props?.dataType ?? null,
            'data-hash'     : props?.dataHash ?? null,
            'data-code'     : props?.dataCode ?? null,
            'data-shortcode': props?.dataShortcode ?? null,
        };
        const queue: Set<number> | null                                                   = CacheManager.GetQueue( props.dataType ?? null );
        const consoleTitle: string                                                        = `${ props.dataType }--${ props.dataId }[ ${ props.dataHash } ]`;

        try {
            this.state = {
                children: new Set<JSX.Element>( [ <span key={ props?.dataKey } { ...params } >{ Shortcode.LOADING_TEXT }</span> ] ),
            };
        } catch( exception: unknown ) {
            console.warn( 'SHORTCODE ISSUE', { state: this?.state ?? null, props: this?.props ?? null, params } );
        }

        if( props?.dataType && props.dataId && props?.dataHash ) {
            const shortcodeHash: string | null = this.buildShortcodeHash( props ) ?? null;

            this.onShortcodeObserver = this.onShortcodeObserver.bind( this );
            ConsoleManager.LightGrey( Shortcode.CONSOLE_PREFIX, consoleTitle, `hash: ${ props?.dataHash } has been initialized` );
            ConsoleManager.DarkGrey( Shortcode.CONSOLE_PREFIX, consoleTitle, `shortcode hash: ${ shortcodeHash } has been initialized` );

            if( shortcodeHash ) {
                ShortcodeObserver.subscribe( shortcodeHash ?? null, this.onShortcodeObserver );
            }

            if( queue?.size ) {
                console.info( '[[ S ]] SHORTCODE QUEUE DATA', { queue } );
                const cache: Map<number, ShortcodeModule> | null = CacheManager.GetCaches( props?.dataType );
                ConsoleManager.LightGrey( Shortcode.CONSOLE_PREFIX, consoleTitle, `hash: ${ '[ ' + queue.size + ' ]' } QUEUE` );

                for( const linkId of queue.values() ) {
                    ConsoleManager.LightGrey( Shortcode.CONSOLE_PREFIX, consoleTitle, `QUEUE ${ props?.dataType }-${ linkId }[  ${ cache.get( linkId ).hash } ]` );
                }
            }
        } else {
            console.error( '&&& SHORTCODE CONSTRUCTOR EMPTY PROPS', props );
        }
    }

    /**
     *
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/24/20 12:31 pm
     */
    public componentDidMount(): void {
        this.isMounted             = true;
        const consoleTitle: string = `${ this.props.dataType }--${ this.props.dataId }[ ${ this.props.dataHash } ]`;

        if( CacheManager.isComplete( this.props?.dataType ?? null ) ) {
            const cache: ShortcodeModule | null = CacheManager.GetCache( this.props?.dataType ?? null, this.props?.dataId ?? null );

            if( Shortcode.CONSOLE_ENABLED ) {
                const output: Record<string, string | null> = {
                    id  : this.props?.dataId ?? null,
                    type: this.props.dataType ?? null,
                };

                ConsoleManager.BrightYellow( Shortcode.CONSOLE_PREFIX, consoleTitle, `on component did mount called`, output ?? {} );
                ConsoleManager.BrightYellow( Shortcode.CONSOLE_PREFIX, consoleTitle, `on component did mount GET CACHE called`, { cache } );
            }
            ConsoleManager.LightGrey( Shortcode.CONSOLE_ENABLED, Shortcode.CONSOLE_PREFIX, consoleTitle, `hash: ${ this.props.dataHash } is MANUALLY setting from cache` );

            if( cache ) {
                this.onShortcodeObserver( cache );
            }
        }
        if( this.queueState ) {
            ConsoleManager.TealBlue( Shortcode.CONSOLE_PREFIX, consoleTitle, `SETTING future state from queue` );
            this.setState( this.queueState );
            this.queueState = null;
        }
    }

    /**
     *
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/24/20 12:31 pm
     */
    public componentWillUnmount(): void {
        ShortcodeObserver.unsubscribe( this.buildShortcodeHash() ?? null );
    }

    /**
     *
     * @return {JSX.Element}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 12:40 pm
     */
    public render(): JSX.Element {
        return (
            <>
                { this.state.children }
            </>
        );
    }
}