import { RequestInit } from 'node-fetch';
import {
    IModule, ISupportAPI, ISupportChain,
    ISupportComponent, ISupportShortcode,
    ISupportStorage,
}                      from '../interface';

/**
 * @class AModule
 * @implements IModule, ISupportAPI, ISupportChain, ISupportComponent, ISupportTo
 * @abstract
 * @author Isaac Ewing
 * @version 1.0.0 02/18/21 10:06 am
 */
export abstract class AModule implements IModule, ISupportAPI, ISupportChain, ISupportComponent, ISupportShortcode, ISupportStorage {
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 10:06 am
     */
    protected static readonly DEFAULT_VALUE: string                    = 'n/a';
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 04/23/21 03:50 pm
     */
    protected static readonly DEFAULT_QUERY_API_SOCIAL: string         = process.env.REACT_APP_CONFIG_QUERY_NAMESPACE ?? 'socialAPI';
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 04/23/21 03:50 pm
     */
    protected static readonly DEFAULT_QUERY_GET_SITE_PAGE: string      = process.env.REACT_APP_GRAPHQL_GET_SITE_PAGE ?? 'getSitePage';
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 04/23/21 03:50 pm
     */
    protected static readonly DEFAULT_QUERY_GET_ALL_SITE_PAGES: string = process.env.REACT_APP_GRAPHQL_GET_ALL_SITE_PAGES ?? 'getAllSitePages';
    /**
     *
     * @type {Map<string, unknown>}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 10:06 am
     */
    protected _data: Map<string, any>                                  = new Map<string, any>();

    /**
     *
     * @param data {any} The returned data from the graphql endpoint
     * @param root {string} The key top level object property from the graphql endpoint
     * @return {any} Returns the child node(s) from the graphql endpoint if the data was received from there
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/19/21 09:29 am
     */
    protected static mapAPIRootNode( data: Record<string, unknown>, root?: string ): unknown {
        if( data ) {
            if( data?.[ AModule.DEFAULT_QUERY_API_SOCIAL ] ) {
                data = data[ AModule.DEFAULT_QUERY_API_SOCIAL ] as Record<string, unknown>;
            }
            if( data?.[ root ?? AModule.DEFAULT_QUERY_GET_SITE_PAGE ] ) {
                return data[ root ?? AModule.DEFAULT_QUERY_GET_SITE_PAGE ] as Record<string, unknown>;
            } else if( data?.[ AModule.DEFAULT_QUERY_GET_ALL_SITE_PAGES ] ) {
                return data[ AModule.DEFAULT_QUERY_GET_ALL_SITE_PAGES ] as Record<string, unknown>;
            }
        }

        return data;
    }

    /**
     *
     * @param data {any}
     * @return {any}
     * @constructor
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 10:06 am
     * TODO: 02/18/21 10:34 am
     *  add support for class error - override method
     */
    public static Build( data: unknown ): unknown {
        return data ??= null;
    }

    /**
     *
     * @param {ISupportAPI} module
     * @return {RequestInit}
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 10:06 am
     */
    public static ToRequestBody( module: ISupportAPI ): RequestInit | Record<string, unknown> {
        return {
            method : 'post',
            headers: {
                'user-agent': process.env.REACT_APP_CONFIG_HEADER_USER_AGENT,
            },
            body   : new URLSearchParams( module.toRequestParams() ),
        };
    }

    /**
     *
     * @param {number | string} id
     * @constructor
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 10:06 am
     */
    protected constructor( id?: number | string | null ) {
        this.id = id ?? null;
    }

    /**
     *
     * @return {object}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 10:06 am
     * @see toObject
     * @see toMap
     * @see toArray
     * @see toString
     */
    public abstract toObject(): Record<string, unknown>;

    /**
     *
     * @param data {any}
     * @return {this}
     * @version 1.0.0 02/18/21 10:06 am
     */
    public build( data: unknown ): unknown {
        return AModule.Build( data );
    }

    /**
     *
     * @return {number | string} Returns the number for the id
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 10:06 am
     * @see @see isShortcode
     * @see id
     */
    public get id(): number | string {
        return this._data.get( 'id' ) as number | string;
    }

    /**
     *
     * @param value {number | string} The number to set for the id
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 10:06 am
     * @see @see isShortcode
     * @see id
     */
    public set id( value: number | string | null ) {
        this._data.set( 'id', value );
    }

    /**
     *
     * @return {string} Returns the string for the source
     * @public
     * @author Isaac Ewing
     * @version 1.1.0 05/27/21 11:41 am
     * @see id
     * @see domain
     * @see source
     * @see path
     * @see alt
     * @see favor
     * @see valign
     * @see halign
     * @see rounded
     * @see greyed
     * @see shadow
     * @see webp
     * @see png
     * @see jpg
     * @see jpeg
     * @see gif
     * @see svg
     * @see jxr
     * @see favorWebP
     * @see favorPNG
     * @see favorJPG
     * @see favorJPEG
     * @see favorGIF
     * @see favorSVG
     * @see favorJXR
     * @see types
     * @see extensions
     * @see ordered
     * @see setDomain
     * @see setSource
     * @see setAlt
     * @see setFavor
     * @see setVAlign
     * @see setHAlign
     * @see setRounded
     * @see setGreyed
     * @see setShadow
     * @see setWEBP
     * @see setPNG
     * @see setJPG
     * @see setJPEG
     * @see setGIF
     * @see setSVG
     * @see setJXR
     * @see enableRounded
     * @see enableGreyed
     * @see enableShadow
     */
    public get text(): string | null {
        return this._data.get( 'text' ) ?? null;
    }

    /**
     *
     * @param value {string} The string to public set for the source
     * @public
     * @author Isaac Ewing
     * @version 1.1.0 05/27/21 11:41 am
     * @see id
     * @see domain
     * @see source
     * @see path
     * @see alt
     * @see favor
     * @see valign
     * @see halign
     * @see rounded
     * @see greyed
     * @see shadow
     * @see webp
     * @see png
     * @see jpg
     * @see jpeg
     * @see gif
     * @see svg
     * @see jxr
     * @see favorWebP
     * @see favorPNG
     * @see favorJPG
     * @see favorJPEG
     * @see favorGIF
     * @see favorSVG
     * @see favorJXR
     * @see types
     * @see extensions
     * @see ordered
     * @see setDomain
     * @see setSource
     * @see setAlt
     * @see setFavor
     * @see setVAlign
     * @see setHAlign
     * @see setRounded
     * @see setGreyed
     * @see setShadow
     * @see setWEBP
     * @see setPNG
     * @see setJPG
     * @see setJPEG
     * @see setGIF
     * @see setSVG
     * @see setJXR
     * @see enableRounded
     * @see enableGreyed
     * @see enableShadow
     */
    public set text( value: string | null ) {
        this._data.set( 'text', value );
    }

    /**
     *
     * @return {string} Returns the string for the source
     * @public
     * @author Isaac Ewing
     * @version 1.1.0 05/01/21 01:51 pm
     * @see id
     * @see domain
     * @see source
     * @see path
     * @see alt
     * @see favor
     * @see valign
     * @see halign
     * @see rounded
     * @see greyed
     * @see shadow
     * @see webp
     * @see png
     * @see jpg
     * @see jpeg
     * @see gif
     * @see svg
     * @see jxr
     * @see favorWebP
     * @see favorPNG
     * @see favorJPG
     * @see favorJPEG
     * @see favorGIF
     * @see favorSVG
     * @see favorJXR
     * @see types
     * @see extensions
     * @see ordered
     * @see setDomain
     * @see setSource
     * @see setAlt
     * @see setFavor
     * @see setVAlign
     * @see setHAlign
     * @see setRounded
     * @see setGreyed
     * @see setShadow
     * @see setWEBP
     * @see setPNG
     * @see setJPG
     * @see setJPEG
     * @see setGIF
     * @see setSVG
     * @see setJXR
     * @see enableRounded
     * @see enableGreyed
     * @see enableShadow
     */
    public get url(): string {
        return this._data.get( 'url' ) as string;
    }

    /**
     *
     * @param value {string} The string to public set for the source
     * @public
     * @author Isaac Ewing
     * @version 1.1.0 05/01/21 01:51 pm
     * @see id
     * @see domain
     * @see source
     * @see path
     * @see alt
     * @see favor
     * @see valign
     * @see halign
     * @see rounded
     * @see greyed
     * @see shadow
     * @see webp
     * @see png
     * @see jpg
     * @see jpeg
     * @see gif
     * @see svg
     * @see jxr
     * @see favorWebP
     * @see favorPNG
     * @see favorJPG
     * @see favorJPEG
     * @see favorGIF
     * @see favorSVG
     * @see favorJXR
     * @see types
     * @see extensions
     * @see ordered
     * @see setDomain
     * @see setSource
     * @see setAlt
     * @see setFavor
     * @see setVAlign
     * @see setHAlign
     * @see setRounded
     * @see setGreyed
     * @see setShadow
     * @see setWEBP
     * @see setPNG
     * @see setJPG
     * @see setJPEG
     * @see setGIF
     * @see setSVG
     * @see setJXR
     * @see enableRounded
     * @see enableGreyed
     * @see enableShadow
     */
    public set url( value: string ) {
        this._data.set( 'url', value );
    }

    /**
     *
     * @param {number | string} value
     * @return {any}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 10:06 am
     */
    public setId( value: number | string ): unknown {
        this.id = value;

        return this;
    }

    /**
     *
     * @param {string} value
     * @return {any}
     * @public
     * @author Isaac Ewing
     * @version 1.1.0 05/27/21 11:41 am
     */
    public setText( value: string ): unknown {
        this.text = value;

        return this;
    }

    /**
     *
     * @param {number | string} value
     * @return {any}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 10:06 am
     */
    public setURL( value: string ): unknown {
        this.url = value;

        return this;
    }

    /**
     *
     * @return {any}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 05/10/21 09:58 am
     */
    public store(): unknown {
        throw new Error( 'You need to override this method in order to use it...' );
    }

    /**
     *
     * @return {Object}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 10:06 am
     * @see id
     * @see forComponent
     * @see forStorage
     * @see toObject
     * @see toMap
     * @see toArray
     * @see toString
     */
    public forComponent(): unknown {
        return this.toObject();
    }

    /**
     *
     * @return {Object}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 05/08/21 03:11 pm
     * @see id
     * @see forComponent
     * @see forStorage
     * @see toObject
     * @see toMap
     * @see toArray
     * @see toString
     */
    public forStorage(): unknown {
        return this.toObject();
    }

    /**
     *
     * @return {Map<string, any>}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 10:06 am
     * @see toObject
     * @see toMap
     * @see toArray
     * @see toString
     */
    public toMap(): Map<string, unknown> {
        return new Map<string, unknown>( Object.entries( this.toObject() ) );
    }

    /**
     *
     * @return {[string, any][]}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 10:06 am
     * @see toObject
     * @see toMap
     * @see toArray
     * @see toArray
     * @see toString
     */
    public toArray(): [ string, unknown ][] {
        return [ ...this.toMap() ];
    }

    /**
     *
     * @return {string}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 10:06 am
     * @see toObject
     * @see toMap
     * @see toArray
     * @see toString
     */
    public toString(): string {
        return JSON.stringify( this.toObject() );
    }

    /**
     *
     * @return {string}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 10:06 am
     * @see id
     * @see action
     * @see code
     * @see provider
     * @see domain
     * @see socket
     * @see hash
     * @see toObject
     * @see toMap
     * @see toString
     * @see toRequestParams
     */
    public toRequestParams(): string {
        const parts: string[]           = [];
        const map: Map<string, unknown> = this.toMap();

        for( const [ key, value ] of map ) {
            parts.push( `${ key }=${ value ?? '' }` );
        }

        return parts.join( '&' );
    }

    /**
     *
     * @return {RequestInit}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 10:06 am
     * @version 1.1.0 05/07/21 04:56 pm - removed parameter so method calls itself to convert
     */
    public toRequestBody(): RequestInit {
        return AModule.ToRequestBody( this );
    }
}