import { AModule } from '../../abstract';
import { ITitle }  from '../../interface';

/**
 * @class TitleModule
 * @extends AModule
 * @implements ITitle
 * @author Isaac Ewing
 * @version 1.0.0 05/27/21 11:36 am
 */
export class TitleModule extends AModule implements ITitle {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/27/21 11:36 am
      */
     protected static readonly CONSOLE_PREFIX: string   = `${ process.env.REACT_APP_CONSOLE_PREFIX_MODULE } TITL ${ process.env.REACT_APP_CONSOLE_SUFFIX_MODULE }`;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/27/21 11:36 am
      */
     protected static readonly CONSOLE_ENABLED: boolean = false;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/27/21 11:36 am
      */
     protected static readonly API_ROOT_NODE: string    = process.env?.REACT_APP_GRAPHQL_GET_TITLE??'';
     /**
      *
      * @type {TitleModule}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/27/21 11:36 am
      */
     protected static fromStorage: TitleModule;

     /**
      *
      * @param module {TitleModule} The object containing the data
      * @return {TitleModule | null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/27/21 11:36 am
      * @see Build
      * @see id
      * @see text
      * @see setText
      * @see store
      * @see toObject
      */
     public static Build( module: TitleModule ): TitleModule;
     public static Build( obj: Partial<ITitle> ): TitleModule;
     public static Build( json: string ): TitleModule;
     public static Build( id: number, text?: string ): TitleModule;
     public static Build( dataOrId?: unknown, text?: string ): TitleModule|null {
          if( dataOrId ) {
               dataOrId = this.mapAPIRootNode( dataOrId as Record<string, string>, this.API_ROOT_NODE );

               if( dataOrId instanceof TitleModule ) {
                    return dataOrId;
               }
               if( typeof dataOrId === 'object' ) {
                    const localData: Partial<ITitle> = dataOrId as Partial<ITitle>;

                    return new TitleModule( +(localData?.id ?? 0), localData?.text ?? null );
               }
               if( typeof dataOrId === 'number' ) {
                    return new TitleModule( dataOrId ?? null, text ?? null );
               }
               if( typeof dataOrId === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrId ) );
                    } catch( exception: unknown ) {
                         // not a valid json string
                         return new TitleModule( +dataOrId ?? null, text ?? null );
                    }
               }
          }

          return null;
     }

     /**
      *
      * @return {TitleModule | null}
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/27/21 11:36 am
      * @see id
      * @see text
      * @see setText
      * @see store
      * @see toObject
      */
     public static FromStorage(): TitleModule | null {
          return null;
     }

     /**
      *
      * @param {number} id
      * @param {string} text
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/27/21 11:36 am
      * @see id
      * @see text
      * @see setText
      * @see store
      * @see toObject
      */
     public constructor( id?: number|null, text?: string|null ) {
          super( id ?? null );

          this.text = text ?? null;
     }

     /**
      *
      * @return {number} Returns the number for the id
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/27/21 11:36 am
      * @see id
      * @see text
      * @see setText
      * @see store
      * @see toObject
      */
     public get id(): number {
          return this._data.get( 'id' );
     }

     /**
      *
      * @param value {number} The number to set for the id
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/27/21 11:36 am
      * @see id
      * @see text
      * @see setText
      * @see store
      * @see toObject
      */
     public set id( value: number ) {
          this._data.set( 'id', value );
     }

     /**
      *
      * @param {number} value
      * @return {any}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/27/21 11:36 am
      * @see id
      * @see text
      * @see setText
      * @see store
      * @see toObject
      */
     public setId( value: number ): this {
          this.id = value;

          return this;
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/27/21 11:36 am
      * @see id
      * @see text
      * @see setText
      * @see store
      * @see toObject
      */
     public store(): this {
          //

          return this;
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/27/21 11:36 am
      * @see id
      * @see text
      * @see setText
      * @see store
      * @see toObject
      */
     public toObject(): Record<string, unknown> {
          return {
               id  : this.id,
               text: this.text,
          };
     }
}