import { PathEnum }                             from '../../../api/com/ewing/social/enum';
import { ISectionModule, ISectionProps, IPage } from '../../../api/com/ewing/social/interface';
import { ConsoleManager }                       from '../../../api/com/ewing/social/manager';
import { RegisterTool }                         from '../../../api/com/ewing/social/tool';
import { BaseSection }                          from '../../atom/BaseSection';
import { Title }                                from '../../atom/Title';
import { Gallery }                              from '../../molecule/Gallery';

/**
 * @class Layout9
 * @extends BaseSection
 * @author Isaac Ewing
 * @version 1.0.0 01/30/21 11:30 am - documented
 */
export class Layout9 extends BaseSection {
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 01/30/21 11:30 am - documented
     */
    protected static readonly COMPONENT_CLASS: string  = 'layout-9';
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 07/31/21 06:37 pm
     */
    protected static readonly CONSOLE_PREFIX: string   = `${ process.env.REACT_APP_CONSOLE_PREFIX_COMPONENT } LY09 ${ process.env.REACT_APP_CONSOLE_SUFFIX_COMPONENT }`;
    /**
     *
     * @type {boolean}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/23/21 08:52 pm
     */
    protected static readonly CONSOLE_ENABLED: boolean = true;
    /**
     *
     * @type {IPage}
     * @readonly
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 08/01/21 11:14 am
     */
    protected readonly page: IPage;

    /**
     *
     * @param {ISectionProps} props
     * @return {void}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 01/30/21 11:30 am - documented
     */
    protected registerGallery( props: ISectionModule ): void {
        switch( this.props?.page?.page?.path ?? this.page?.path ) {
            case `/${ PathEnum.About }`:
                this.state.gallery = <Gallery module={ props?.gallery } social youtube />;
                break;
            case `/${ PathEnum.AboutWishlist }`:
                this.state.gallery = <Gallery module={ props?.gallery } social wishlist />;
                break;
            case `/${ PathEnum.Store }`:
                this.state.gallery = <Gallery module={ props?.gallery } social equalize />;
                break;
            case `/${ PathEnum.Download }`:
            case `/${ PathEnum.PartnerAffiliates }`:
            case `/${ PathEnum.PartnerSponsors }`:
                this.state.gallery = <Gallery module={ props?.gallery } social twoColumns />;
                break;
            default:
                if( props?.gallery ) {
                    this.state.gallery = <Gallery module={ props?.gallery } social />;
                }

                ConsoleManager.Log( Layout9.CONSOLE_ENABLED ?? null, Layout9.CONSOLE_PREFIX ?? null, 'Gallery', 'register gallery called', { props, page: this.page } );
                break;
        }
    }

    /**
     *
     * @param props {ISectionProps}
     * @return {void}
     * @throws Throws an exception if method is called without having an override
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 01/30/21 12:09 pm - documented
     */
    protected registerPropsCall( props: ISectionModule ): void {
        RegisterTool.style( props, this.state );
        RegisterTool.curve( props, this.state );
        RegisterTool.submenus( props, this.state );
        RegisterTool.classes( props, this.state );
        this.registerGallery( props );
    }

    /**
     *
     * @param props {ISectionProps}
     * @return {void}
     * @constructor
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 01/30/21 11:30 am - documented
     */
    public constructor( props: ISectionProps ) {
        super( props );

        this.state = {
            className: new Set<string>( [ 'grid-x', Layout9.COMPONENT_CLASS ] ),
            gallery  : null,
        };
        this.page  = props?.page?.page ?? null;

        this.registerProps( props ?? null );
    }

    /**
     *
     * @return {JSX.Element}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 01/30/21 11:30 am
     * documented
     */
    public render(): JSX.Element {
        return (
            <section { ...this.registerSectionProps( this.props.module ) }>
                <div className="grid-container content">
                    <div className="grid-x align-left">
                        <div className="cell small-12 medium-12">
                            <Title module={ this.props.module?.title } />
                        </div>
                        <div className="cell small-12">
                            { this.state.gallery }
                            { this.props.children }
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
