import React             from 'react';
import { ISectionProps } from '../../../api/com/ewing/social/interface';
import { BaseSection }   from '../../atom/BaseSection';
import { Button }        from '../../atom/Button';
import { Description }   from '../../atom/Description';
import { Image }         from '../../atom/Image';
import { Subtitle }      from '../../atom/Subtitle';
import { Title }         from '../../atom/Title';

/**
 * @class Layout8
 * @extends BaseSection
 * @author Isaac Ewing
 * @version 1.0.0 01/30/21 11:30 am - documented
 */
export class Layout8 extends BaseSection {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am - documented
      */
     protected static readonly COMPONENT_CLASS: string = 'layout-8';

     /**
      *
      * @param props {ISectionProps}
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am - documented
      */
     public constructor( props: ISectionProps ) {
          super( props );

          this.state = {
               className: new Set<string>( [ 'grid-x', Layout8.COMPONENT_CLASS ] ),
          };

          this.registerProps( props.module ?? null );
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am
      * documented
      */
     public render(): JSX.Element {
          return (
               <section { ...this.registerSectionProps(this.props.module ) }>
                    <div className="grid-container content">
                         <div className="grid-x align-left">
                              <div className="cell small-12 medium-7 image-right">
                                   <Title module={ this.props?.module?.title } />
                                   <Subtitle module={ this.props?.module?.subtitle } />
                                   <Description module={ this.props?.module?.description } />
                                   <Button module={ this.props?.module?.button } />
                              </div>
                              <div className="cell small-12 medium-5">
                                   <Image module={ this.props?.module?.image } rounded greyed shadow />
                                   { this.props.children }
                              </div>
                         </div>
                    </div>
               </section>
          );
     }
}

export default Layout8;
