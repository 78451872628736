import { FileNode, IGatsbyImageDataParent }       from 'gatsby-plugin-image/dist/src/components/hooks';
import { AComponent }                             from '../../abstract';
import { IImageModule, IImageStatic, IImageNode } from '../../interface';
import { DomainModule }                           from '../../module/api/domain.module';
import { UserModule }                             from '../../module/api/user.module';
import { FavorImageEnum, HAlignEnum, VAlignEnum } from '../../enum';
import { Util }                                   from '../../tool';

/**
 * @class ImageModule
 * @extends AComponent
 * @implements IImageModule
 * @author Isaac Ewing
 * @version 1.0.0 10/03/20 01:45 pm
 * @version 1.1.0 02/22/21 02:28 pm - updated to extend AComponent
 */
export class ImageModule extends AComponent implements IImageModule {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      */
     protected static readonly API_ROOT_NODE: string       = process.env.REACT_APP_GRAPHQL_GET_IMAGE;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      */
     protected static readonly DEFAULT_EXTENSIONS: string  = process.env.REACT_APP_CONFIG_IMG_EXT;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 06:51 pm
      */
     protected static readonly DEFAULT_IMAGE_SRC: string   = process.env.REACT_APP_DEFAULT_IMAGE_SRC ?? 'bg-grey';
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 06:51 pm
      */
     protected static readonly DEFAULT_IMAGE_EXT: string   = process.env.REACT_APP_DEFAULT_IMAGE_EXT ?? 'png';
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 06:51 pm
      */
     protected static readonly DEFAULT_IMAGE_ALT: string   = 'default grey image placeholder';
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      */
     protected static readonly DEFAULT_JQUERY: string      = 'image';
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      */
     protected static readonly DEFAULT_FAVOR: string       = 'webp';
     /**
      *
      * @type {VAlignEnum}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      */
     protected static readonly DEFAULT_VALIGN: VAlignEnum  = VAlignEnum.Auto;
     /**
      *
      * @type {HAlignEnum}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      */
     protected static readonly DEFAULT_HALIGN: HAlignEnum  = HAlignEnum.Auto;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 04/25/21 06:47 pm
      */
     protected static readonly DEFAULT_NODE_IMAGE: string  = 'image';
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 04/25/21 06:47 pm
      */
     protected static readonly DEFAULT_NODE_LOGO: string   = 'logo';
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 04/25/21 06:47 pm
      */
     protected static readonly DEFAULT_NODE_SOCIAL: string = 'social';
     /**
      *
      * @type {number}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 04/25/21 04:32 pm
      */
     protected static STATIC_DOMAIN: number;
     /**
      *
      * @type {IImageStatic}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 04/25/21 04:32 pm
      */
     protected static STATIC_IMAGES: IImageStatic;

     /**
      *
      * @param module {ImageModule} The object containing the data
      * @return {ImageModule|null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see Build
      * @see BuildForShortcode
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public static Build( module: ImageModule ): ImageModule;
     public static Build( obj: Partial<IImageModule> ): ImageModule;
     public static Build( json: string ): ImageModule;
     public static Build( domain?: DomainModule, source?: string, path?: string, imageFile?: FileNode, alt?: string, favor?: string, valign?: VAlignEnum | string, halign?: HAlignEnum | string,
                          logo?: boolean, rounded?: boolean, greyed?: boolean, shadow?: boolean, webp?: boolean, png?: boolean, jpg?: boolean, jpeg?: boolean, gif?: boolean, svg?: boolean,
                          jxr?: boolean,
                          id?: number | string, hash?: string, className?: string, jquery?: string, shortcode?: boolean ): ImageModule;
     public static Build( domain?: DomainModule, source?: string, path?: string, imageFile?: FileNode, alt?: string, favor?: string, valign?: VAlignEnum | string, halign?: HAlignEnum | string,
                          logo?: boolean, rounded?: boolean, greyed?: boolean, shadow?: boolean, webp?: boolean, png?: boolean, jpg?: boolean, jpeg?: boolean, gif?: boolean, svg?: boolean,
                          jxr?: boolean,
                          id?: number | string, hash?: string, classNames?: string[], jquery?: string, shortcode?: boolean ): ImageModule;
     public static Build( domain?: DomainModule, source?: string, path?: string, imageFile?: FileNode, alt?: string, favor?: string, valign?: VAlignEnum | string, halign?: HAlignEnum | string,
                          logo?: boolean, rounded?: boolean, greyed?: boolean, shadow?: boolean, webp?: boolean, png?: boolean, jpg?: boolean, jpeg?: boolean, gif?: boolean, svg?: boolean,
                          jxr?: boolean,
                          id?: number | string, hash?: string, classNames?: Set<string>, jquery?: string, shortcode?: boolean ): ImageModule;
     public static Build( dataOrDomain?: unknown, source?: string, path?: string, imageFile?: FileNode, alt?: string, favor?: string, valign?: VAlignEnum | string, halign?: HAlignEnum | string,
                          logo?: boolean, rounded?: boolean, greyed?: boolean, shadow?: boolean, webp?: boolean, png?: boolean, jpg?: boolean, jpeg?: boolean, gif?: boolean, svg?: boolean,
                          jxr?: boolean,
                          id?: number | string, hash?: string, classNames?: never, jquery?: string, shortcode?: boolean ): ImageModule {
          if( dataOrDomain ) {
               dataOrDomain = this.mapAPIRootNode( dataOrDomain, this.API_ROOT_NODE );

               if( dataOrDomain instanceof ImageModule ) {
                    return dataOrDomain;
               }
               if( typeof dataOrDomain === 'object' ) {
                    let localDomain: DomainModule;
                    const localData: Record<string, never> = dataOrDomain as Record<string, never>;

                    if( localData?.domain ) {
                         if( typeof localData.domain === 'number' ) {
                              localDomain = DomainModule.Build( { id: localData.domain } );
                         } else if( typeof localData.domain === 'object' ) {
                              localDomain = DomainModule.Build( localData.domain );
                         }
                    }

                    return new ImageModule( localDomain ?? null, localData?.source ?? null, localData?.path ?? null, localData?.imageFile ?? null,
                                            localData?.alt ?? null, localData?.favor ?? null, localData?.valign ?? null, localData?.halign ?? null,
                                            localData?.logo ?? null, localData?.rounded ?? null, localData?.greyed ?? null, localData?.shadow ?? null,
                                            localData?.webp ?? null, localData?.png ?? null, localData?.jpg ?? null, localData?.jpeg ?? null, localData?.gif ?? null,
                                            localData?.svg ?? null, localData?.jxr ?? null, localData?.id ?? null, localData?.hash ?? null, localData?.className ?? null,
                                            localData?.jquery ?? null, localData?.shortcode ?? null );
               }
               if( typeof dataOrDomain === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrDomain ) );
                    } catch( exception: unknown ) {
                         // not a valid json string
                         const localDomain: DomainModule = DomainModule.Build( dataOrDomain );

                         return new ImageModule( localDomain ?? null, source ?? null, path ?? null, imageFile ?? null, alt ?? null, favor ?? null,
                                                 valign ?? null, halign ?? null, logo ?? null, rounded ?? null, greyed ?? null, shadow ?? null,
                                                 webp ?? null, png ?? null, jpg ?? null, jpeg ?? null, gif ?? null, svg ?? null, jxr ?? null, id ?? null,
                                                 hash ?? null, classNames ?? null, jquery ?? null, shortcode ?? null );
                    }
               }
          }

          return null;
     }

     /**
      *
      * @return {ImageModule} Returns a new instance of the module
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 06:51 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public static BuildDefaultGrey( alt?: string ): ImageModule {
          const props: Partial<IImageModule> = {
               imageFile: this.ForGetImageSocial( `${ this.DEFAULT_IMAGE_SRC }.${ this.DEFAULT_IMAGE_EXT }` ) as FileNode,
               alt      : alt ?? this.DEFAULT_IMAGE_ALT,
          };

          return this.Build( props ?? null );
     }

     /**
      *
      * @param domain {number}
      * @param images {any}
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 04/25/21 04:43 pm
      */
     public static SetStatic( domain: number, images: unknown ): void;
     public static SetStatic( domain: string, images: unknown ): void;
     public static SetStatic( domain: DomainModule, images: unknown ): void;
     public static SetStatic( domain: unknown, images: unknown ): void {
          if( typeof domain === 'number' || typeof domain === 'string' ) {
               this.STATIC_DOMAIN = +domain;
          } else if( typeof domain === 'object' ) {
               this.STATIC_DOMAIN = +DomainModule.Build( domain ).id;
          }

          this.STATIC_IMAGES = {
               image : new Map<string, IImageNode>(),
               logo  : new Map<string, IImageNode>(),
               social: new Map<string, IImageNode>(),
          };

          if( images[ this.DEFAULT_NODE_IMAGE ] ) {
               images[ this.DEFAULT_NODE_IMAGE ].nodes.forEach( node => {
                    this.STATIC_IMAGES.image.set( node.base, node );
               } );
          }
          if( images[ this.DEFAULT_NODE_LOGO ] ) {
               images[ this.DEFAULT_NODE_LOGO ].nodes.forEach( node => {
                    this.STATIC_IMAGES.logo.set( node.base, node );
               } );
          }
          if( images[ this.DEFAULT_NODE_SOCIAL ] ) {
               images[ this.DEFAULT_NODE_SOCIAL ].nodes.forEach( node => {
                    this.STATIC_IMAGES.social.set( node.base, node );
               } );
          }

          console.log( 'STATIC IMAGE DATA', this.STATIC_IMAGES );
     }

     /**
      *
      * @param {string} source
      * @return {FileNode | string}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 04/25/21 09:46 pm
      * @see ForGetImageLogo
      * @see ForGetImageSocial
      */
     public static ForGetImageLogo( source: string ): FileNode | string {
          if( ImageModule.STATIC_IMAGES.logo.has( source ) ) {
               const result: IImageNode = ImageModule.STATIC_IMAGES.logo.get( source );

               if( result.extension !== FavorImageEnum.Svg ) {
                    return result;
               }

               return result.publicURL;
          }

          console.warn( 'OHHH BOY, image module tried ForGetImageLogo using logo tables but could not find the hash', { hash: source } );

          return null;
     }

     /**
      *
      * @param {string} source
      * @param {boolean} onlyGetURL
      * @return {FileNode | string}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 04/25/21 09:46 pm
      * @version 1.0.0 05/02/21 01:27 pm- added support for onlyGetURL
      * @see ForGetImageLogo
      * @see ForGetImageSocial
      */
     public static ForGetImageSocial( source: string, onlyGetURL?: boolean ): FileNode | string {
          if( ImageModule.STATIC_IMAGES.social.has( source ) ) {
               const result: IImageNode = ImageModule.STATIC_IMAGES.social.get( source );

               if( result.extension !== FavorImageEnum.Svg && !onlyGetURL ) {
                    return result;
               }

               return result.publicURL;
          }

          console.warn( 'OHHH BOY, image module tried ForGetImageSocial using social tables but could not find the hash', { hash: source } );

          return null;
     }

     /**
      *
      * @param {DomainModule} domain
      * @param {string} source
      * @param {string} path
      * @param {FileNode} imageFile
      * @param {string} alt
      * @param {string} favor
      * @param {VAlignEnum | string} valign
      * @param {HAlignEnum | string} halign
      * @param {boolean} logo
      * @param {boolean} rounded
      * @param {boolean} greyed
      * @param {boolean} shadow
      * @param {boolean} webp
      * @param {boolean} png
      * @param {boolean} jpg
      * @param {boolean} jpeg
      * @param {boolean} gif
      * @param {boolean} svg
      * @param {boolean} jxr
      * @param {number | string} id
      * @param {string} hash
      * @param {string} className
      * @param {string} jquery
      * @param {boolean} shortcode
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @version 1.1.1 04/24/21 04:18 pm - added support for path
      * @version 1.1.2 04/30/21 02:05 pm - added support for imageFile
      * @version 1.1.3 05/04/21 02:06 pm - added support for logo
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public constructor( domain?: DomainModule, source?: string, path?: string, imageFile?: FileNode, alt?: string, favor?: string, valign?: VAlignEnum | string, halign?: HAlignEnum | string,
                         logo?: boolean, rounded?: boolean, greyed?: boolean, shadow?: boolean, webp?: boolean, png?: boolean, jpg?: boolean, jpeg?: boolean, gif?: boolean, svg?: boolean,
                         jxr?: boolean,
                         id?: number | string, hash?: string, className?: string, jquery?: string, shortcode?: boolean );
     public constructor( domain?: DomainModule, source?: string, path?: string, imageFile?: FileNode, alt?: string, favor?: string, valign?: VAlignEnum | string, halign?: HAlignEnum | string,
                         logo?: boolean, rounded?: boolean, greyed?: boolean, shadow?: boolean, webp?: boolean, png?: boolean, jpg?: boolean, jpeg?: boolean, gif?: boolean, svg?: boolean,
                         jxr?: boolean,
                         id?: number | string, hash?: string, classNames?: string[], jquery?: string, shortcode?: boolean );
     public constructor( domain?: DomainModule, source?: string, path?: string, imageFile?: FileNode, alt?: string, favor?: string, valign?: VAlignEnum | string, halign?: HAlignEnum | string,
                         logo?: boolean, rounded?: boolean, greyed?: boolean, shadow?: boolean, webp?: boolean, png?: boolean, jpg?: boolean, jpeg?: boolean, gif?: boolean, svg?: boolean,
                         jxr?: boolean,
                         id?: number | string, hash?: string, classNames?: Set<string>, jquery?: string, shortcode?: boolean );
     public constructor( domain?: DomainModule, source?: string, path?: string, imageFile?: FileNode, alt?: string, favor?: string, valign?: VAlignEnum | string, halign?: HAlignEnum | string,
                         logo?: boolean, rounded?: boolean, greyed?: boolean, shadow?: boolean, webp?: boolean, png?: boolean, jpg?: boolean, jpeg?: boolean, gif?: boolean, svg?: boolean,
                         jxr?: boolean,
                         id?: number | string, hash?: string, classNames?: never, jquery?: string, shortcode?: boolean ) {
          super( id, hash, classNames, jquery ?? ImageModule.DEFAULT_JQUERY, shortcode );

          this.domain    = domain ?? null;
          this.source    = source ?? null;
          this.imageFile = imageFile ?? null;
          this.url       = imageFile?.publicURL ? imageFile?.publicURL as string : null;
          this.path      = path ?? null;
          this.alt       = alt ?? null;
          this.favor     = favor ?? ImageModule.DEFAULT_FAVOR;
          this.valign    = valign ?? ImageModule.DEFAULT_VALIGN;
          this.halign    = halign ?? ImageModule.DEFAULT_HALIGN;
          this.logo      = Util.asBoolean( logo );
          this.rounded   = Util.asBoolean( rounded );
          this.greyed    = Util.asBoolean( greyed );
          this.shadow    = Util.asBoolean( shadow );
          this.webp      = Util.asBoolean( webp );
          this.png       = Util.asBoolean( png );
          this.jpg       = Util.asBoolean( jpg );
          this.jpeg      = Util.asBoolean( jpeg );
          this.gif       = Util.asBoolean( gif );
          this.svg       = Util.asBoolean( svg );
          this.jxr       = Util.asBoolean( jxr );

     }

     /**
      *
      * @return {DomainModule} Returns the module for the domain
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 03/31/21 12:39 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public get domain(): DomainModule {
          return this._data.get( 'domain' );
     }

     /**
      *
      * @param value {DomainModule} The module to public set for the domain
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 03/31/21 12:39 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public set domain( value: DomainModule ) {
          this._data.set( 'domain', value );
     }

     /**
      *
      * @return {UserModule} Returns the module for the domain
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 05/24/21 07:21 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public get user(): UserModule {
          return this._data.get( 'user' );
     }

     /**
      *
      * @param value {UserModule} The module to public set for the domain
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 05/24/21 07:21 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public set user( value: UserModule ) {
          this._data.set( 'user', value );
     }

     /**
      *
      * @return {string} Returns the string for the source
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public get source(): string {
          return this._data.get( 'source' );
     }

     /**
      *
      * @param value {string} The string to public set for the source
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public set source( value: string ) {
          this._data.set( 'source', value );
     }

     /**
      *
      * @return {FileNode} Returns the string for the source
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 04/30/21 02:06 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public get imageFile(): FileNode {
          return this._data.get( 'imageFile' );
     }

     /**
      *
      * @param value {FileNode} The string to public set for the source
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 04/30/21 02:06 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public set imageFile( value: FileNode ) {
          this._data.set( 'imageFile', value );
     }

     /**
      *
      * @return {string} Returns the string for the source
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public get alt(): string {
          return this._data.get( 'alt' );
     }

     /**
      *
      * @param value {string} The string to public set for the source
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public set alt( value: string ) {
          this._data.set( 'alt', value );
     }

     /**
      *
      * @return {string} Returns the string for the source
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public get favor(): string {
          return this._data.get( 'favor' );
     }

     /**
      *
      * @param value {string} The string to public set for the source
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public set favor( value: string ) {
          this._data.set( 'favor', value );
     }

     /**
      *
      * @return {boolean} Returns the string for the property
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 05/04/21 02:07 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public get logo(): boolean {
          return this._data.get( 'logo' );
     }

     /**
      *
      * @param value {boolean} The string to public set for the property
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 05/04/21 02:07 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public set logo( value: boolean ) {
          this._data.set( 'logo', value );
     }

     /**
      *
      * @return {boolean} Returns the string for the property
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public get rounded(): boolean {
          return this._data.get( 'rounded' );
     }

     /**
      *
      * @param value {boolean} The string to public set for the property
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public set rounded( value: boolean ) {
          this._data.set( 'rounded', value );
     }

     /**
      *
      * @return {boolean} Returns the string for the property
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public get greyed(): boolean {
          return this._data.get( 'greyed' );
     }

     /**
      *
      * @param value {boolean} The string to public set for the property
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public set greyed( value: boolean ) {
          this._data.set( 'greyed', value );
     }

     /**
      *
      * @return {boolean} Returns the string for the property
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public get shadow(): boolean {
          return this._data.get( 'shadow' );
     }

     /**
      *
      * @param value {boolean} The string to public set for the property
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public set shadow( value: boolean ) {
          this._data.set( 'shadow', value );
     }

     /**
      *
      * @return {boolean} Returns the string for the property
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public get avif(): boolean {
          return this._data.get( 'avif' );
     }

     /**
      *
      * @param value {boolean} The string to public set for the property
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public set avif( value: boolean ) {
          this._data.set( 'avif', value );
     }

     /**
      *
      * @return {boolean} Returns the string for the property
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public get webp(): boolean {
          return this._data.get( 'webp' );
     }

     /**
      *
      * @param value {boolean} The string to public set for the property
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public set webp( value: boolean ) {
          this._data.set( 'webp', value );
     }

     /**
      *
      * @return {boolean} Returns the string for the property
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public get png(): boolean {
          return this._data.get( 'png' );
     }

     /**
      *
      * @param value {boolean} The string to public set for the property
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public set png( value: boolean ) {
          this._data.set( 'png', value );
     }

     /**
      *
      * @return {boolean} Returns the string for the property
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public get jpg(): boolean {
          return this._data.get( 'jpg' );
     }

     /**
      *
      * @param value {boolean} The string to public set for the property
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public set jpg( value: boolean ) {
          this._data.set( 'jpg', value );
     }

     /**
      *
      * @return {boolean} Returns the string for the property
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public get jpeg(): boolean {
          return this._data.get( 'jpeg' );
     }

     /**
      *
      * @param value {boolean} The string to public set for the property
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public set jpeg( value: boolean ) {
          this._data.set( 'jpeg', value );
     }

     /**
      *
      * @return {boolean} Returns the string for the property
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public get gif(): boolean {
          return this._data.get( 'gif' );
     }

     /**
      *
      * @param value {boolean} The string to public set for the property
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public set gif( value: boolean ) {
          this._data.set( 'gif', value );
     }

     /**
      *
      * @return {boolean} Returns the string for the property
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public get svg(): boolean {
          return this._data.get( 'svg' );
     }

     /**
      *
      * @param value {boolean} The string to public set for the property
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public set svg( value: boolean ) {
          this._data.set( 'svg', value );
     }

     /**
      *
      * @return {boolean} Returns the string for the property
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public get jxr(): boolean {
          return this._data.get( 'jxr' );
     }

     /**
      *
      * @param value {boolean} The string to public set for the property
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public set jxr( value: boolean ) {
          this._data.set( 'jxr', value );
     }

     /**
      *
      * @return {Set<string>}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public get types(): Set<string> {
          const template: Set<string> = new Set<string>();

          if( this.avif ) {
               template.add( FavorImageEnum.Avif );
          }
          if( this.webp ) {
               template.add( FavorImageEnum.Webp );
          }
          if( this.png ) {
               template.add( FavorImageEnum.Png );
          }
          if( this.jpg ) {
               template.add( FavorImageEnum.Jpg );
          }
          if( this.jpeg ) {
               template.add( FavorImageEnum.Jpeg );
          }
          if( this.gif ) {
               template.add( FavorImageEnum.Gif );
          }
          if( this.svg ) {
               template.add( FavorImageEnum.Svg );
          }
          if( this.jxr ) {
               template.add( FavorImageEnum.Jxr );
          }

          return template;
     }

     /**
      *
      * @return {Set<string>}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public get extensions(): Set<string> {
          return new Set<string>( ImageModule.DEFAULT_EXTENSIONS.split( ',' ) ?? null );
     }

     /**
      *
      * @return {Set<string>}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 04/15/21 07:49 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public get ordered(): Set<string> {
          const ordered: string[] = [ ...this.types ].filter( ( item: string ): boolean => item !== this.favor );

          ordered.unshift( this.favor );

          return new Set<string>( ordered );
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public favorAVIF(): this {
          this.favor = FavorImageEnum.Avif;

          return this;
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public favorWEBP(): this {
          this.favor = FavorImageEnum.Webp;

          return this;
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public favorPNG(): this {
          this.favor = FavorImageEnum.Png;

          return this;
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public favorJPG(): this {
          this.favor = FavorImageEnum.Jpg;

          return this;
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public favorJPEG(): this {
          this.favor = FavorImageEnum.Jpeg;

          return this;
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public favorGIF(): this {
          this.favor = FavorImageEnum.Gif;

          return this;
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public favorSVG(): this {
          this.favor = FavorImageEnum.Svg;

          return this;
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public favorJXR(): this {
          this.favor = FavorImageEnum.Jxr;

          return this;
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public favorURL(): this {
          this.favor = FavorImageEnum.URL;

          return this;
     }

     /**
      *
      * @param {DomainModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 03/31/21 12:39 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public setDomain( value: DomainModule ): this {
          this.domain = value;

          return this;
     }

     /**
      *
      * @param {UserModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 03/31/21 12:39 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public setUser( value: UserModule ): this {
          this.user = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public setSource( value: string ): this {
          this.source = value;

          return this;
     }

     /**
      *
      * @param {FileNode} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public setImageFile( value: FileNode ): this {
          this.imageFile = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public setAlt( value: string ): this {
          this.alt = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public setFavor( value: string ): this {
          this.favor = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 05/04/21 02:08 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public setLogo( value: boolean ): this {
          this.logo = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public setRounded( value: boolean ): this {
          this.rounded = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public setGreyed( value: boolean ): this {
          this.greyed = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public setShadow( value: boolean ): this {
          this.shadow = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public setWEBP( value: boolean ): this {
          this.webp = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public setPNG( value: boolean ): this {
          this.png = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public setJPG( value: boolean ): this {
          this.jpg = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public setJPEG( value: boolean ): this {
          this.jpeg = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public setGIF( value: boolean ): this {
          this.gif = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public setSVG( value: boolean ): this {
          this.svg = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public setJXR( value: boolean ): this {
          this.jxr = value;

          return this;
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public enableRounded(): this {
          this.rounded = true;

          return this;
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public enableGreyed(): this {
          this.greyed = true;

          return this;
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public enableShadow(): this {
          this.shadow = true;

          return this;
     }

     /**
      *
      * @return {FileNode | string} Returns the string for the image
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 05/01/21 01:51 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public forComponent(): FileNode | string {
          if( this.imageFile?.childImageSharp ) {
               return this.imageFile;
          }

          return this.forReactImage();
     }

     /**
      *
      * @return {FileNode | string} Returns the string for the image
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 05/01/21 01:51 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public forReactImage(): FileNode | string {
          if( this.favor === FavorImageEnum.URL ) {
               return this.path ?? this.url ?? null;
          }
          if( this.logo ) {
               return ImageModule.ForGetImageLogo( Util.cleanPath( `${ this.source }.${ this.favor }`, false, true ) );
          }

          console.log( '[[ IM ]] IMAGE MODULE', { data: this._data } );

          const key: string = Util.cleanPath( `${ this.source }.${ this.favor }`, false, true );

          if( this.domain ) {
               if( ImageModule.STATIC_IMAGES.image.has( key ) ) {
                    const result: IImageNode = ImageModule.STATIC_IMAGES.image.get( key );

                    if( result.relativePath !== `${ this.domain.id }/${ key }` ) {
                         console.warn( 'UHHH OHHH, image module mapped to the domain static images but relative path did not match', { domain: this.domain, hash: key, path: result.relativePath } );
                    }
                    if( result.extension !== FavorImageEnum.Svg ) {
                         return result;
                    }

                    return result.publicURL;
               }
               if( !( this.path ?? this.url ?? null ) ) {
                    console.warn( 'OHHH BOY, image module tried forReactImage using domain but could not find the hash', { domain: this.domain, hash: key, path: this.path, url: this.url } );
               }

               return this.path ?? this.url ?? null;
          }

          return ImageModule.ForGetImageSocial( key ) ?? ImageModule.ForGetImageLogo( key );
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 02:28 pm
      * @see id
      * @see domain
      * @see source
      * @see path
      * @see alt
      * @see favor
      * @see valign
      * @see halign
      * @see rounded
      * @see greyed
      * @see shadow
      * @see webp
      * @see png
      * @see jpg
      * @see jpeg
      * @see gif
      * @see svg
      * @see jxr
      * @see favorWebP
      * @see favorPNG
      * @see favorJPG
      * @see favorJPEG
      * @see favorGIF
      * @see favorSVG
      * @see favorJXR
      * @see types
      * @see extensions
      * @see ordered
      * @see setDomain
      * @see setSource
      * @see setAlt
      * @see setFavor
      * @see setVAlign
      * @see setHAlign
      * @see setRounded
      * @see setGreyed
      * @see setShadow
      * @see setWEBP
      * @see setPNG
      * @see setJPG
      * @see setJPEG
      * @see setGIF
      * @see setSVG
      * @see setJXR
      * @see enableRounded
      * @see enableGreyed
      * @see enableShadow
      */
     public toObject(): Record<string, unknown> {
          return {
               ...super.toObject(),
               domain    : this.domain.toObject(),
               source    : this.source,
               url       : this.url,
               path      : this.path,
               imageFile : this.imageFile,
               alt       : this.alt,
               favor     : this.favor,
               valign    : this.valign,
               halign    : this.halign,
               logo      : this.logo,
               rounded   : this.rounded,
               greyed    : this.greyed,
               shadow    : this.shadow,
               webp      : this.webp,
               png       : this.png,
               jpg       : this.jpg,
               jpeg      : this.jpeg,
               gif       : this.gif,
               svg       : this.svg,
               jxr       : this.jxr,
               types     : [ ...this.types ],
               extensions: [ ...this.extensions ],
               ordered   : [ ...this.ordered ],
          };
     }

     /**
      *
      * @return {IGatsbyImageDataParent}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 04/30/21 02:09 pm
      */
     public toChildImageSharp(): IGatsbyImageDataParent {
          return {
               childImageSharp: {
                    gatsbyImageData: {
                         layout     : 'constrained',
                         placeholder: null,
                         images     : {
                              fallback: null,
                              sources : [],
                         },
                         width      : 512,
                         height     : 512,
                    },
               },
          } as never;
     }
}