import React                  from 'react';
import PropTypes              from 'prop-types';
import { Link as Hyperlink }  from 'gatsby';
import { OutboundLink }       from 'gatsby-plugin-google-gtag';
import { FontAwesomeIcon }    from '@fortawesome/react-fontawesome';
import { ILinkProps }         from '../../api/com/ewing/social/interface';
import { LinkModule }         from '../../api/com/ewing/social/module/api/link.module';
import { RegisterTool, Util } from '../../api/com/ewing/social/tool';
import { Base }               from './Base';
import {
    faDiscord, faFacebook, faFacebookF,
    faGithub, faGoogle, faLinkedin, faLinkedinIn,
    faPatreon, faTiktok, faTwitch, faTwitter,
    faYoutube,
}                             from '@fortawesome/free-brands-svg-icons';
import {
    faEnvelope, faTimesCircle,
}                             from '@fortawesome/free-regular-svg-icons';
import {
    faPlus,
}                             from '@fortawesome/free-solid-svg-icons';
import {
    library, IconLookup,
    IconDefinition,
    findIconDefinition,
    IconPrefix, IconName,
}                             from '@fortawesome/fontawesome-svg-core';

/**
 * @class Link
 * @extends Base
 * @author Isaac Ewing
 * @version 1.0.0 12/24/20 12:31 pm
 */
export class Link extends Base<React.HTMLProps<HTMLAnchorElement>> {
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/24/20 12:31 pm
     */
    protected static readonly COMPONENT_CLASS: string      = 'text-link';
    /**
     *
     * @type {IconDefinition[]}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 04/17/21 01:29 pm
     */
    protected static readonly FA_BRANDS: IconDefinition[]  = [
        faDiscord,
        faFacebook,
        faFacebookF,
        faGithub,
        faGoogle,
        faLinkedin,
        faLinkedinIn,
        faPatreon,
        faTiktok,
        faTwitch,
        faTwitter,
        faYoutube,
    ];
    protected static readonly FA_REGULAR: IconDefinition[] = [
        faEnvelope,
        faTimesCircle,
    ];
    /**
     *
     * @type {IconDefinition[]}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 04/17/21 01:29 pm
     */
    protected static readonly FA_SOLID: IconDefinition[]   = [
        faPlus,
    ];
    /**
     *
     * @type {IconDefinition[]}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 04/17/21 01:29 pm
     */
    protected static FA_INIT: boolean                      = false;
    /**
     *
     * @type {any}
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/24/20 12:31 pm
     */
    public static propTypes                                = {
        ...Base.propTypes,
        module     : PropTypes.instanceOf( LinkModule ).isRequired,
        style      : PropTypes.string,
        target     : PropTypes.string,
        rel        : PropTypes.string,
        'data-id'  : PropTypes.number,
        'data-type': PropTypes.string,
        'data-hash': PropTypes.string,
    };
    /**
     *
     * @type {Partial<ILinkProps>}
     * @readonly
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 05/30/21 06:28 pm
     */
    public readonly props: Partial<ILinkProps & React.HTMLProps<HTMLAnchorElement>>;

    /**
     *
     * @param {string} key
     * @param {string} id
     * @param {LinkModule} module
     * @param {Record<string, unknown>} other
     * @return {JSX.Element}
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 07/25/21 02:39 pm
     */
    public static CreateFactory( key: string, id: string, module: LinkModule, other: Record<string, unknown> ): JSX.Element {
        return <Link key={ key } id={ id } module={ module } { ...other } />;
    }

    /**
     *
     * @param props {LinkModule}
     * @return {JSX.Element|null}
     * @author Isaac Ewing
     * @version 1.0.0 10/04/20 04:50 pm
     */
    protected registerIcon( props?: LinkModule ): JSX.Element | null {
        if( props?.icon ) {
            try {
                const parts: string[] = props.icon.split( ' ' );

                if( parts.length === 2 ) {
                    const threeLetters: boolean = parts[ 0 ].length === 3;
                    const startsFA: boolean     = parts[ 0 ].startsWith( 'fa' );

                    if( threeLetters && startsFA ) {
                        const prefix: IconPrefix   = parts[ 0 ] as IconPrefix;
                        const iconName: IconName   = parts[ 1 ] as IconName;
                        const lookup: IconLookup   = { prefix, iconName };
                        const icon: IconDefinition = findIconDefinition( lookup || null );

                        return <FontAwesomeIcon key={ Util.registerHash() } icon={ icon } />;
                    } else {
                        return <i key={ Util.registerHash() } className={ props.icon } />;
                    }
                }
            } catch( exception: unknown ) {
                return <i key={ Util.registerHash() } className={ props.icon } />;
            }
        }

        return null;
    }

    /**
     *
     * @param props {ILinkProps}
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/24/20 12:31 pm
     */
    public constructor( props: ILinkProps ) {
        super( props );

        let { module = null, ...other } = { ...props };

        module ??= null;
        other ??= {} as never;

        if( !Link.FA_INIT ) {
            library.add( ...Link.FA_BRANDS, ...Link.FA_REGULAR, ...Link.FA_SOLID );
            Link.FA_INIT = true;
        }
        if( module?.shortcode ) {
            other[ 'data-type' ] = props[ 'data-type' ] ?? module[ 'data-type' ] ?? null;
            other[ 'data-id' ]   = props[ 'data-id' ] ?? module[ 'data-id' ] ?? null;
            other[ 'data-hash' ] = props[ 'data-hash' ] ?? module[ 'data-hash' ] ?? null;
        }

        this.props = props;
        this.state = {
            module,
            other,
            className: new Set<string>( [ Link.COMPONENT_CLASS ] ),
            children : props?.children ?? new Set<JSX.Element>(),
        };

        RegisterTool.style( props ?? null, this.state );
        RegisterTool.classes( props ?? null, this.state );
    }

    /**
     *
     * @param props {LinkModule}
     * @return {void}
     * @author Isaac Ewing
     * @version 1.0.0 10/04/20 04:50 pm
     * @version 1.0.1 11/01/20 10:51 am modified to void, updates state instead of returning element
     */
    public registerRender( props?: LinkModule ): JSX.Element | null {
        if( props ) {
            // TODO: 02/18/21 12:03 pm
            //  add test to determine if hash is needed or it can pull it from a shortcode module
            // TODO: 05/30/21 07:11 pm
            //  removed key      : props.key ?? Util.registerHash(),
            //  trying to use the passed in properties from the data attributes to build key and id
            try {
                new URL( props?.path??'' );
                const hash: string = Util.registerHash();
                const params       = {
                    key      : this.state.other[ 'data-hash' ] ?? hash ?? Util.registerHash(),
                    id       : this.state.other[ 'data-id' ] ?? hash ?? Util.registerHash(),
                    className: [ ...this.state.className ].join( ' ' ),
                    href     : props?.path??'',
                    target   : '_blank',
                    ...this.state.other as Record<string, string>,
                };

                return <OutboundLink { ...params }>{ this.registerIcon( props ) }{ props.text }{ this.state.children }</OutboundLink>;
            } catch( exception: unknown ) {
                const hash: string = Util.registerHash();
                const params       = {
                    key      : this.state.other[ 'data-hash' ] ?? hash ?? Util.registerHash(),
                    id       : this.state.other[ 'data-id' ] ?? hash ?? Util.registerHash(),
                    className: [ ...this.state.className ].join( ' ' ),
                    to       : `/${ props.path }`,
                    ...this.state.other as Record<string, unknown>,
                };

                return <Hyperlink { ...params }>{ this.registerIcon( props ) }{ props.text }{ this.state.children }</Hyperlink>;
            }
        } else {
            console.log( 'skipping link, no props', props, this.state );
        }

        return null;
    }

    /**
     *
     * @return {JSX.Element}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/24/20 12:31 pm
     */
    public render(): JSX.Element | null {
        return this.registerRender( this.props.module as LinkModule );
    }
}
