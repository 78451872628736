import { AModule }      from '../../../../abstract/module.abstract';
import { IParseModule } from '../../../../interface';

/**
 * @class ParseModule
 * @extends AModule
 * @implements IParseModule
 * @author Isaac Ewing
 * @version 1.0.0 12/16/20 05:05 pm
 * @version 1.1.0 02/25/21 01:38 pm - updated to extend AComponent
 */
export class ParseModule extends AModule implements IParseModule {
     /**
      *
      * @param module {ParseModule} The object containing the data
      * @return {ParseModule|null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/25/21 01:38 pm
      * @see id
      * @see toObject
      */
     public static Build( module: ParseModule ): ParseModule;
     public static Build( obj: Partial<IParseModule> ): ParseModule;
     public static Build( json: string ): ParseModule;
     public static Build( id?: number | string ): ParseModule;
     public static Build( id?: number | string ): ParseModule;
     public static Build( id?: number | string ): ParseModule;
     public static Build( dataOrId?: unknown ): ParseModule {
          if( dataOrId ) {
               if( dataOrId instanceof ParseModule ) {
                    return dataOrId;
               }
               if( typeof dataOrId === 'object' ) {
                    const localData: Partial<IParseModule> = dataOrId;

                    return new ParseModule( localData?.id ?? null );
               }
               if( typeof dataOrId === 'number' ) {
                    return new ParseModule( +dataOrId ?? null );
               }
               if( typeof dataOrId === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrId ) );
                    } catch( exception ) {
                         // not a valid json string
                         return new ParseModule( dataOrId ?? null );
                    }
               }
          }

          return null;
     }

     /**
      *
      * @param {number | string} id
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/25/21 01:38 pm
      * @see id
      * @see toObject
      */
     public constructor( id?: number | string );
     public constructor( id?: number | string );
     public constructor( id?: number | string );
     public constructor( id?: number | string ) {
          super( id ?? null );
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/25/21 01:38 pm
      * @see id
      * @see toObject
      */
     public toObject(): Record<string, unknown> {
          return {
               id: this.id,
          };
     }
}