import { AModule } from '../abstract/module.abstract';
import { IModule } from '../interface';

/**
 * @class AddressUtil
 * @extends AModule
 * @author Isaac Ewing
 * @version 1.0.0 02/07/21 12:32 pm
 * @version 1.1.0 05/07/21 02:11 pm - changed base class to AModule
 * @classdesc This class will handle the storage for address data. This is the first class to
 * implement the interfaces in a similar way to laravel/lumen
 */
export class AddressUtil extends AModule implements IModule {
     /**
      * @param data {Object|null} The object containing the data
      * @return {AddressUtil|null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 01:28 pm
      * @see street
      * @see suite
      * @see state
      * @see setStreet
      * @see setSuite
      * @see setState
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public static Build( data: Record<string, string> ): AddressUtil {
          if( data ) {
               return new AddressUtil( data );
          }

          return null;
     }

     /**
      *
      * @param data {any}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 01:28 pm
      * @see street
      * @see suite
      * @see state
      * @see setStreet
      * @see setSuite
      * @see setState
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public constructor( data: Record<string, string> ) {
          super( data?.id ?? null );

          this.street = data?.street ?? process.env.REACT_APP_MAP_ADDRESS_STREET;
          this.suite  = data?.suite ?? process.env.REACT_APP_MAP_ADDRESS_SUITE;
          this.state  = data?.state ?? process.env.REACT_APP_MAP_ADDRESS_STATE;
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 01:28 pm
      * @see street
      * @see suite
      * @see state
      * @see setStreet
      * @see setSuite
      * @see setState
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public get street(): string {
          return this._data.get( 'street' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 11:43 am
      * @see street
      * @see suite
      * @see state
      * @see setStreet
      * @see setSuite
      * @see setState
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public set street( value: string ) {
          this._data.set( 'street', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 01:28 pm
      * @see street
      * @see suite
      * @see state
      * @see setStreet
      * @see setSuite
      * @see setState
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public get suite(): string {
          return this._data.get( 'suite' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 11:43 am
      * @see street
      * @see suite
      * @see state
      * @see setStreet
      * @see setSuite
      * @see setState
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public set suite( value: string ) {
          this._data.set( 'suite', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 01:28 pm
      * @see street
      * @see suite
      * @see state
      * @see setStreet
      * @see setSuite
      * @see setState
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public get state(): string {
          return this._data.get( 'state' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 11:43 am
      * @see street
      * @see suite
      * @see state
      * @see setStreet
      * @see setSuite
      * @see setState
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public set state( value: string ) {
          this._data.set( 'state', value );
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 11:43 am
      * @see street
      * @see suite
      * @see state
      * @see setStreet
      * @see setSuite
      * @see setState
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public setStreet( value: string ): this {
          this.street = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 11:43 am
      * @see street
      * @see suite
      * @see state
      * @see setStreet
      * @see setSuite
      * @see setState
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public setSuite( value: string ): this {
          this.suite = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 11:43 am
      * @see street
      * @see suite
      * @see state
      * @see setStreet
      * @see setSuite
      * @see setState
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public setState( value: string ): this {
          this.state = value;

          return this;
     }

     /**
      *
      * @return {Object}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 11:24 am
      * @see street
      * @see suite
      * @see state
      * @see setStreet
      * @see setSuite
      * @see setState
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toArray
      * @see toString
      */
     public toObject(): Record<string, string> {
          return {
               street: this.street,
               suite : this.suite,
               state : this.state,
          };
     }
}