import React                from 'react';
import jQuery               from 'jquery';
import DatePicker           from 'react-datepicker';
import { RegisterStepEnum } from '../../../api/com/ewing/social/enum';
import { TitleModule }      from '../../../api/com/ewing/social/module/api/title.module';
import { OAuthObserver }    from '../../../api/com/ewing/social/observer/oauth.observer';
import { ProfileModule }    from '../../../api/com/ewing/social/module/api/profile.module';
import { Description }      from '../../atom/Description';
import { InviteBase }       from '../../atom/invite/InviteBase';

export class Start extends InviteBase<unknown, unknown> {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/24/21 10:16 am
      * documented
      */
     protected static readonly COMPONENT_CLASS: string = 'start';

     /**
      *
      * @param {ProfileModule} module
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/21/21 09:24 pm
      */
     protected onOAuthObserver( module: ProfileModule ): void {
          const component: JQuery = this.state.component;

          if( module.step === RegisterStepEnum.Start ) {
               console.info( '%%%% LOOKS LIKE THE START ELEMENT SHOULD OPEN...' );
               const properties = {
                    'data-state': InviteBase.STATE_OPEN,
                    // 'data-status': Start.STATUS_DONE,
               };

               this.updateAttributeData( component, properties ?? {} );
          } else if( module.step === RegisterStepEnum.Finish ) {
               const properties = {
                    'data-state' : InviteBase.STATE_CLOSE,
                    'data-status': InviteBase.STATUS_DONE,
               };

               this.updateAttributeData( component, properties ?? {} );
          }
     }

     /**
      *
      * @param props {any}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/16/21 11:22 am
      */
     public constructor( props: any ) {
          super( props );

          this.state = {
               page          : props.page,
               hash          : this.registerHash(),
               id            : props.id ?? '',
               initialized   : false,
               className     : new Set<string>( [ Start.COMPONENT_CLASS ] ),
               component     : null,
               children      : new Set<JSX.Element>(),
               started       : new Date(),
               title         : null,
               titleHash     : this.registerHash(),
               nextButton    : null,
               nextButtonHash: this.registerHash(),
          };

          this.registerProps( props );
          this.onDateChanged         = this.onDateChanged.bind( this );
          this.onDateSelected        = this.onDateSelected.bind( this );
          this.onOAuthObserver       = this.onOAuthObserver.bind( this );
          this.onAttributeDataChange = this.onAttributeDataChange.bind( this );
          OAuthObserver.subscribe( Start.COMPONENT_CLASS, this.onOAuthObserver );
     }

     /**
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/16/21 11:22 am
      */
     public componentDidMount(): void {
          const component: JQuery     = jQuery( `[data-hash="${ this.state.hash }"]` );
          const nextButton: JQuery    = jQuery( `[data-hash="${ this.state.nextButtonHash }"]` );
          const title: JQuery         = jQuery( `[data-hash="${ this.state.titleHash }"]` );
          const module: ProfileModule = ProfileModule.Build( { step: RegisterStepEnum.Finish } );
          const onClick               = ( event: JQuery.ClickEvent<HTMLElement> ): void => {
               event.preventDefault();

               if( this.isDateChanged ) {
                    const properties = {
                         'data-state' : InviteBase.STATE_CLOSE,
                         'data-status': InviteBase.STATUS_DONE,
                    };

                    InviteBase.REGISTER_MODULE.componentStart.attr( properties ?? {} );
                    this.updateAttributeData( component, properties ?? {} );
                    OAuthObserver.unsubscribe( Start.COMPONENT_CLASS );
                    OAuthObserver.notify( module );
               }
          };
          const initialData           = {
               'data-state' : InviteBase.STATE_CLOSE,
               'data-status': InviteBase.STATUS_INCOMPLETE,
          };

          // @ts-ignore
          this.state.title = title;
          // @ts-ignore
          component.on( InviteBase.ATTRIBUTE_DATA_CHANGED, this.onAttributeDataChange );
          nextButton.on( 'click', onClick ?? null );
          InviteBase.REGISTER_MODULE.componentStart = component;
          this.setState( { component, title, nextButton } );
          this.updateAttributeData( component, initialData );
     }

     /**
      *
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 03/26/21 05:23 pm
      */
     public componentWillUnmount(): void {
          OAuthObserver.unsubscribe( Start.COMPONENT_CLASS );
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/16/21 11:22 am
      */
     public render(): JSX.Element {
          const data   = {
               'data-state' : '',
               'data-status': '',
               'data-hash'  : this.state.hash,
          };
          const button = {
               id         : 'done-button-link',
               className  : 'button-link disabled',
               href       : '#',
               'data-hash': this.state.nextButtonHash,
          };
          const date   = {
               selected: this.state.started,
               onSelect: this.onDateSelected,
               onChange: this.onDateChanged,
          };

          return (
               <div className={ [ ...this.state.className ].join( ' ' ) }  { ...data }>
                    <h5 className={ 'subtitle' } data-hash={ this.state.titleHash }>{ InviteBase.TEXT_MAP.get( 'start-heading' ) }</h5>
                    <Description module={ TitleModule.Build( { text: InviteBase.TEXT_MAP.get( 'start-description' ) } ) } />
                    <div className={ InviteBase.CLASS_DATE_PICKER }>
                         <DatePicker { ...date } />
                    </div>
                    <div className={ 'next-button' }>
                         <a { ...button }>done</a>
                    </div>
                    { this.state.children }
               </div>
          );
     }
}

export default Start;