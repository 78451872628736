import { AObserver } from '../abstract';
import { IObserver } from '../interface';

/**
 * @class UserObserver
 * @extends AObserver
 * @implements IObserver
 * @author Isaac Ewing
 * @version 1.0.0 05/07/21 06:42 pm
 */
export class UserObserver extends AObserver implements IObserver {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/07/21 06:42 pm
      */
     protected static readonly CONSOLE_PREFIX: string                   = `${ process.env.REACT_APP_CONSOLE_PREFIX_OBSERVER } USER ${ process.env.REACT_APP_CONSOLE_SUFFIX_OBSERVER }`;
     /**
      *
      * @type {Map<string, Function>}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/07/21 06:42 pm
      */
     protected static observers: Map<string, ( data: unknown ) => void> = new Map<string, ( data: unknown ) => void>();

     /**
      *
      * @param hash {string}
      * @param fn {Function}
      * @return void
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/07/21 06:42 pm
      */
     public static subscribe( hash: string, fn: ( data: unknown ) => void ): void {
          this.observers.set( hash, fn );
          console.log( `${ this.CONSOLE_PREFIX } ADDED user subscriber`, hash, 'all user subscribers', { observers: this.observers } );
     }
}