import axios, { AxiosResponse }               from 'axios';
import { AManager }                           from '../../abstract';
import { ILink, IModule, IShortcodeModule }   from '../../interface';
import { ComponentEnum, ShortcodeActionEnum } from '../../enum';
import { ShortcodeModule }                    from '../../component';
import { ShortcodeGraphql }                   from '../../graphql';
import { LinkModule }                         from '../../module/api/link.module';
import { ShortcodeObserver }                  from '../../observer';
import { ConsoleManager }                     from '../console.manager';

/**
 * @class CacheManager
 * @extends AManager
 * @author Isaac Ewing
 * @version 1.0.0 12/21/20 07:06 pm
 * @version 1.1.0 05/09/21 11:15 am - added extends AManager
 * @classdesc This class handles the uses for caching the shortcode queries into memory for reuse
 */
export class CacheManager extends AManager {
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 05/09/21 11:08 am
     */
    protected static readonly CONSOLE_PREFIX: string                                   = `${ process.env.REACT_APP_CONSOLE_PREFIX_MANAGER } Cach ${ process.env.REACT_APP_CONSOLE_SUFFIX_MANAGER }`;
    /**
     *
     * @type {boolean}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 06/01/21 04:27 pm
     */
    protected static readonly CONSOLE_ENABLED: boolean                                 = true;
    /**
     *
     * @type {Map<ComponentEnum, Map<number, ShortcodeModule | any>>}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 05/09/21 11:08 am
     */
    protected static readonly _cache: Map<ComponentEnum, Map<number, ShortcodeModule>> = new Map<ComponentEnum, Map<number, ShortcodeModule>>();

    /**
     *
     * @param type {string}
     * @param data {Object}
     * @return {IComponent}
     * @throws {Error} Throws an exception when if type is not yet implemented
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/21/20 07:06 pm
     */
    protected static buildModule( type: ComponentEnum, data: IModule ): IModule {
        switch( type ) {
            case ComponentEnum.Link:
                return LinkModule.BuildForShortcode( data as ILink ?? null );
            default:
                throw new Error( `${ this.CONSOLE_PREFIX } XXX BUILD MODULE TYPE NOT SUPPORTED YET...` );
        }
    }

    /**
     *
     * @param {ComponentEnum} type
     * @param {number} id
     * @param data
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/25/20 02:43 pm
     */
    protected static onQueryPass( type: ComponentEnum, id: number, data: IModule ): void {
        try {
            const module: IModule               = this.buildModule( type ?? ComponentEnum.Link, data ?? null );
            const cache: ShortcodeModule | null = this.GetCache( type, id );

            cache?.setModule( module ).markComplete();

            if( this.CONSOLE_ENABLED ) {
                console.info( `${ this.CONSOLE_PREFIX } Query completed...`, { type, id, data, module, cache }, {
                    'has-id'     : this._queue.get( type )?.has( id ),
                    'is-complete': this.isComplete( type ),
                    'size'       : this._queue.get( type )?.size ?? null,
                    'queue'      : this._queue.get( type ),
                } );
            }

            if( this._queue.get( type )?.has( id ) ) {
                this._queue.get( type )?.delete( id );
            }
            if( this.isComplete( type ) ) {
                console.log( `${ this.CONSOLE_PREFIX } RUNNING through all cache for type: ${ type }` );

                for( const element of this.GetCaches( type ).values() ) {
                    console.log( `${ this.CONSOLE_PREFIX } SENDING NOTIFICATION for the following shortcode...`, { type, element } );
                    ShortcodeObserver.notify( element );
                }
            } else {
                console.log( `${ this.CONSOLE_PREFIX } SENDING NOTIFICATION for the following shortcode...`, { type, cache, queue: this._queue } );
                ShortcodeObserver.notify( cache );
            }
        } catch( exception: unknown ) {
            console.log( `${ this.CONSOLE_PREFIX } xxx 555 update link data`, data, this.buildModule( type ?? ComponentEnum.Link, data ?? null ) );
            console.log( `${ this.CONSOLE_PREFIX } xxx 555 update link exception`, exception );
        }
    }

    /**
     *
     * @param {ComponentEnum} type
     * @param {number} id
     * @param exception
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/25/20 02:43 pm
     */
    protected static onQueryFail( type: ComponentEnum, id: number, exception: Error ): void {
        console.error( `${ this.CONSOLE_PREFIX } EXCEPTION FOR QUERY CACHE SHORTCODE`, { type, id, exception } );
    }

    /**
     *
     * @return {JSX.Element}
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/21/20 06:07 pm
     */
    protected static onQueryChildren(): JSX.Element | null {
        return null;
    }

    /**
     *
     * @param {ComponentEnum} type
     * @param {number} id
     * @return {CacheManager}
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/21/20 06:19 pm
     */
    public static Add( type: ComponentEnum, id: number ): CacheManager {
        id = +id;

        if( !this._queue.has( type ) ) {
            this._queue.set( type, new Set<number>() );
        }
        if( !this._cache.has( type ) ) {
            this._cache.set( type, new Map<number, ShortcodeModule>() );
        }
        if( this._cache.get( type )?.has( id ) ) {
            const module: ShortcodeModule | null = this._cache.get( type )?.get( id ) ?? null;

            if( module ) {
                module.action = ShortcodeActionEnum.Add;

                if( module.complete ) {
                    ShortcodeObserver.notify( module );

                    if( this.CONSOLE_ENABLED ) {
                        ConsoleManager.Log( this.CONSOLE_PREFIX, 'SENDING NOTIFICATION', `for module complete ${ module.type }--${ module.id }` );
                    }
                }
            }
        } else {
            const headers: Record<string, string>       = {
                'Content-Type': 'application/json',
            };
            const query: Promise<void>                  = axios.post( process.env.REACT_APP_API_GRAPHQL_URL ?? '',
                                                                      { ...ShortcodeGraphql.getShortcode( type, id ) },
                                                                      { headers } )
                                                               .then( ( data: AxiosResponse ): void => { this.onQueryPass( type ?? null, id ?? null, data?.data?.data ?? null ); } )
                                                               .catch( ( data ): void => { this.onQueryFail( type ?? null, id ?? null, data ?? null ); } );
            const properties: Partial<IShortcodeModule> = {
                type,
                id,
                query,
                action: ShortcodeActionEnum.Build,
            };
            const module: ShortcodeModule               = ShortcodeModule.Build( properties ?? null ) as ShortcodeModule;

            this._queue.get( type )?.add( id );
            this._cache.get( type )?.set( id, module );

            console.log( `${ this.CONSOLE_PREFIX } _-_-_- ADD-${ type }[${ id }]`, { queue: this._queue.get( type ), module: null } );
            console.log( `${ this.CONSOLE_PREFIX } _-_-_- ADD-${ type }[${ id }]`, { cache: this._cache.get( type ), module: this._cache.get( type )?.get( id ) } );
        }
        if( this.CONSOLE_ENABLED ) {
            console.log( `${ this.CONSOLE_PREFIX } _-_-_- ADD-${ type }[${ id }]`, { isInt: Number.isInteger( id ), cache: this._cache.get( type ), module: this._cache.get( type )?.get( id ) } );
        }

        return CacheManager;
    }

    /**
     *
     * @param {ComponentEnum} type
     * @param {number | string} id
     * @return {ShortcodeModule|null}
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/25/20 02:43 pm
     * @version 2.0.0 06/01/21 02:38 pm - added overload methods
     */
    public static GetCache( type: ComponentEnum | null, id?: number ): ShortcodeModule | null;
    public static GetCache( type: ComponentEnum | null, hash?: string ): ShortcodeModule | null;
    public static GetCache( type: ComponentEnum | null, id?: number | string | null ): ShortcodeModule | null {
        if( this.CONSOLE_ENABLED ) {
            console.log( `${ this.CONSOLE_PREFIX } _-_-_- GET CACHE-${ type }[${ id }]`,
                         { isInt: id as number, cache: this._cache.get( type ), module: this._cache.get( type )?.get( id as number ) } );
        }
        if( type && id ) {
            if( +id ) {
                return this._cache.get( type )?.get( +id ) ?? null;
            }
            if( typeof id === 'string' ) {
                const modules: Map<number, ShortcodeModule> | null = this._cache.get( type ) ?? null;

                if( modules ) {
                    for( const module of modules.values() ) {
                        if( module.hash === id ) {
                            return module;
                        }
                    }
                }
            }
        }

        return null;
    }

    /**
     *
     * @param {ComponentEnum} type
     * @return {Map<number, ShortcodeModule>}
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/25/20 02:43 pm
     */
    public static GetCaches( type: ComponentEnum ): Map<number, ShortcodeModule> | null {
        return this._cache.get( type ) ?? null;
    }

    /**
     *
     * @param {ComponentEnum} type
     * @return {object[]}
     * @constructor
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/27/21 11:46 am
     */
    public static GetCachesToArray( type: ComponentEnum ): Record<string, unknown>[] {
        const temp: Record<string, unknown>[]            = [];
        const cache: Map<number, ShortcodeModule> | null = this.GetCaches( type );

        if( cache ) {
            cache.forEach( ( module: ShortcodeModule ): number => temp.push( module.toObject() ) );
        }

        return temp;
    }

    /**
     *
     * @param {ComponentEnum | null} type
     * @return {Set<number>}
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/25/20 02:43 pm
     */
    public static GetQueue( type?: ComponentEnum | null ): Set<number> | null {
        if( type ) {
            return this._queue.get( type ) ?? null;
        }

        return null;
    }

    /**
     *
     * @param {ComponentEnum} type
     * @return {boolean}
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/25/20 02:43 pm
     */
    public static isComplete( type: ComponentEnum | null ): boolean {
        if( type ) {
            const isDone: boolean = this?._queue?.get( type )?.size === 0;

            console.log( `${ this.CONSOLE_PREFIX } CACHE MANAGER ${ isDone ? 'HAS' : 'HAS NOT' } finished with all the queued shortcodes...`,
                         { queue: this._queue.get( type ), keys: [ ...this._queue.get( type )?.keys() ] } );

            return isDone;
        }

        return false;
    }

    /**
     *
     * @param {ComponentEnum} type
     * @param {number} id
     * @return {CacheManager}
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/19/21 07:30 pm
     * @see Add
     * @see GetCache
     */
    public Add( type: ComponentEnum, id: number ): CacheManager {
        return CacheManager.Add( type, id );
    }

    /**
     *
     * @param {ComponentEnum} type
     * @param {number} id
     * @return {ShortcodeModule}
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/19/21 07:30 pm
     * @see Add
     * @see GetCache
     */
    public GetCache( type: ComponentEnum, id: number ): ShortcodeModule | null {
        return CacheManager.GetCache( type, id );
    }
}
