import { AModule }                 from '../../../abstract/module.abstract';
import {
     IParseModule, IViewerOptionsModule,
     IViewModule,
}                                  from '../../../interface';
import { ParseModule, ViewModule } from './options';
import { Util }                    from '../../../tool';

/**
 * @class ViewerOptionsModule
 * @extends AModule
 * @implements IViewerOptionsModule
 * @author Isaac Ewing
 * @version 1.0.0 12/16/20 05:05 pm
 * @version 1.1.0 02/25/21 01:54 pm - updated to extend AComponent
 */
export class ViewerOptionsModule extends AModule implements IViewerOptionsModule {
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.1.0 02/25/21 01:54 pm
      */
     protected static readonly CONSOLE_ENABLED: boolean  = false;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.1.0 02/25/21 01:54 pm
      */
     protected static readonly DEFAULT_DOWNLOAD: boolean = Util.asBoolean( process.env.REACT_APP_VIEWER_DOWNLOAD );

     /**
      *
      * @param module {ViewerOptionsModule} The object containing the data
      * @return {ViewerOptionsModule|null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/25/21 01:54 pm
      * @see id
      * @see download
      * @see parse
      * @see view
      * @see setId
      * @see setDownload
      * @see setParse
      * @see setView
      * @see toObject
      */
     public static Build( module: ViewerOptionsModule ): ViewerOptionsModule;
     public static Build( obj: Partial<IViewerOptionsModule> ): ViewerOptionsModule;
     public static Build( json: string ): ViewerOptionsModule;
     public static Build( id?: number | string, download?: boolean, parse?: ParseModule, view?: ViewModule ): ViewerOptionsModule;
     public static Build( id?: number | string, download?: boolean, parse?: Partial<IParseModule>, view?: ViewModule ): ViewerOptionsModule;
     public static Build( id?: number | string, download?: boolean, parse?: ParseModule, view?: Partial<IViewModule> ): ViewerOptionsModule;
     public static Build( id?: number | string, download?: boolean, parse?: Partial<IParseModule>, view?: Partial<IViewModule> ): ViewerOptionsModule;
     public static Build( dataOrId?: unknown, download?: boolean, parse?: unknown, view?: unknown ): ViewerOptionsModule {
          if( dataOrId ) {
               if( dataOrId instanceof ViewerOptionsModule ) {
                    return dataOrId;
               }
               if( typeof dataOrId === 'object' ) {
                    const local: Partial<IViewerOptionsModule> = dataOrId;

                    return new ViewerOptionsModule( local?.id ?? null, local?.download ?? null, ParseModule.Build( local?.parse ) ?? null,
                                                    ViewModule.Build( local?.view ) ?? null );
               }
               if( typeof dataOrId === 'number' ) {
                    return new ViewerOptionsModule( +dataOrId ?? null, download ?? null, ParseModule.Build( parse ?? null ) ?? null,
                                                    ViewModule.Build( view ?? null ) ?? null );
               }
               if( typeof dataOrId === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrId ) );
                    } catch( exception ) {
                         // not a valid json string
                         return new ViewerOptionsModule( dataOrId ?? null, download ?? null, ParseModule.Build( parse ?? null ) ?? null,
                                                         ViewModule.Build( view ?? null ) ?? null );
                    }
               }
          }

          return null;
     }

     /**
      *
      * @param {number | string} [id=null]
      * @param {boolean} [download=false]
      * @param {ParseModule} [parse=null]
      * @param {ViewModule} [view=null]
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/25/21 01:54 pm
      * @see id
      * @see download
      * @see parse
      * @see view
      * @see setId
      * @see setDownload
      * @see setParse
      * @see setView
      * @see toObject
      */
     public constructor( id?: number | string, download?: boolean, parse?: ParseModule, view?: ViewModule ) {
          super( id ?? null );

          this.download = Util.asBoolean( download ?? ViewerOptionsModule.DEFAULT_DOWNLOAD );
          this.parse    = parse ?? null;
          this.view     = view ?? null;
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/25/21 01:54 pm
      * @see id
      * @see download
      * @see parse
      * @see view
      * @see setId
      * @see setDownload
      * @see setParse
      * @see setView
      * @see toObject
      */
     public get download(): boolean {
          return this._data.get( 'download' );
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/25/21 01:54 pm
      * @see id
      * @see download
      * @see parse
      * @see view
      * @see setId
      * @see setDownload
      * @see setParse
      * @see setView
      * @see toObject
      */
     public set download( value: boolean ) {
          this._data.set( 'download', value );
     }

     /**
      *
      * @return {ParseModule}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/25/21 01:54 pm
      * @see id
      * @see download
      * @see parse
      * @see view
      * @see setId
      * @see setDownload
      * @see setParse
      * @see setView
      * @see toObject
      */
     public get parse(): ParseModule {
          return this._data.get( 'parse' );
     }

     /**
      *
      * @param {ParseModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/25/21 01:54 pm
      * @see id
      * @see download
      * @see parse
      * @see view
      * @see setId
      * @see setDownload
      * @see setParse
      * @see setView
      * @see toObject
      */
     public set parse( value: ParseModule ) {
          this._data.set( 'parse', value );
     }

     /**
      *
      * @return {ViewModule}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/25/21 01:54 pm
      * @see id
      * @see download
      * @see parse
      * @see view
      * @see setId
      * @see setDownload
      * @see setParse
      * @see setView
      * @see toObject
      */
     public get view(): ViewModule {
          return this._data.get( 'view' );
     }

     /**
      *
      * @param {ViewModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/25/21 01:54 pm
      * @see id
      * @see download
      * @see parse
      * @see view
      * @see setId
      * @see setDownload
      * @see setParse
      * @see setView
      * @see toObject
      */
     public set view( value: ViewModule ) {
          this._data.set( 'view', value );
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/25/21 01:54 pm
      * @see id
      * @see download
      * @see parse
      * @see view
      * @see setId
      * @see setDownload
      * @see setParse
      * @see setView
      * @see toObject
      */
     public setDownload( value: boolean ): this {
          this.download = value;

          return this;
     }

     /**
      *
      * @param {ParseModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/25/21 01:54 pm
      * @see id
      * @see download
      * @see parse
      * @see view
      * @see setId
      * @see setDownload
      * @see setParse
      * @see setView
      * @see toObject
      */
     public setParse( value: ParseModule ): this {
          this.parse = value;

          return this;
     }

     /**
      *
      * @param {ViewModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/25/21 01:54 pm
      * @see id
      * @see download
      * @see parse
      * @see view
      * @see setId
      * @see setDownload
      * @see setParse
      * @see setView
      * @see toObject
      */
     public setView( value: ViewModule ): this {
          this.view = value;

          return this;
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/25/21 01:54 pm
      * @see id
      * @see download
      * @see parse
      * @see view
      * @see setId
      * @see setDownload
      * @see setParse
      * @see setView
      * @see toObject
      */
     public toObject(): Record<string, unknown> {
          return {
               id      : this.id,
               download: this.download,
               parse   : this.parse.toObject(),
               view    : this.view.toObject(),
          };
     }
}