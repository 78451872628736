import { AObserver }              from '../abstract';
import { IObserver, ISiteModule } from '../interface';

/**
 * @class PageObserver
 * @extends AObserver
 * @implements IObserver
 * @author Isaac Ewing
 * @version 1.0.0 11/03/20 08:12 pm
 */
export class PageObserver extends AObserver implements IObserver {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/07/21 06:39 pm
      */
     protected static readonly CONSOLE_PREFIX: string                   = `${ process.env.REACT_APP_CONSOLE_PREFIX_OBSERVER } +PgO ${ process.env.REACT_APP_CONSOLE_SUFFIX_OBSERVER }`;
     /**
      *
      * @type {Map<string,Function>}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 11/03/20 08:12 pm
      * @version 1.1.0 03/24/21 12:17 pm - changed array to set
      * @version 1.2.0 03/26/21 02:28 pm - changed set to map
      */
     protected static observers: Map<string, ( data: ISiteModule ) => void> = new Map<string, ( data: ISiteModule ) => void>();

     /**
      *
      * @param hash {string}
      * @param fn {Function}
      * @return void
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 03/26/21 06:51 pm
      */
     public static subscribe( hash: string, fn: ( data: ISiteModule ) => void ): void {
          this.observers.set( hash, fn );
          console.log( `${ this.CONSOLE_PREFIX } ADDED page subscriber`, hash, 'all page subscribers', { observers: this.observers } );
     }
}