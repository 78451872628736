import React    from 'react';
import jQuery   from 'jquery';
import { Util } from '../../../api/com/ewing/social/tool';

/**
 * @class Infolinks
 * @extends React.PureComponent
 * @author Isaac Ewing
 * @version 1.0.0 05/03/21 12:07 pm
 */
export class PropellerAds extends React.PureComponent {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/03/21 04:33 pm
      */
     protected static readonly DEFAULT_HASH: string = Util.registerHash();
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/03/21 04:33 pm
      */
     protected static readonly DEFAULT_ID: string   = `ad-${ PropellerAds.DEFAULT_HASH }`;
     /**
      *
      * @type {number}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/03/21 12:52 pm
      */
     protected static DEFAULT_UPDATES: number       = 0;
     public state: any;

     /**
      *
      * @return {void}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/03/21 12:52 pm
      */
     protected static registerScript(): void {
          const script: HTMLScriptElement = document.createElement( 'script' );

          script.src = process.env.REACT_APP_ADS_PROPELLERADS_SOURCE;
          script.setAttribute( process.env.REACT_APP_ADS_PROPELLERADS_ZONE, process.env.REACT_APP_ADS_PROPELLERADS_ID );
          script.async = true;

          jQuery( `#${ PropellerAds.DEFAULT_ID }` ).empty().append( script );
     }

     /**
      *
      * @param props
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/03/21 12:07 pm
      */
     public constructor( props ) {
          super( props );

          this.state = {
               updates: PropellerAds.DEFAULT_UPDATES,
          };
     }

     /**
      *
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/03/21 04:33 pm
      */
     public componentDidMount(): void {
          PropellerAds.DEFAULT_UPDATES++;
          PropellerAds.registerScript();
          this.setState( { updates: PropellerAds.DEFAULT_UPDATES } );
          console.log( '[[ IL ]] CALLED component did mount for the PropellerAds ad...' );
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/03/21 12:07 pm
      */
     public render(): JSX.Element {
          return <div id={ PropellerAds.DEFAULT_ID } data-ad={ true } data-updates={ this.state.updates } />;
     }
}
