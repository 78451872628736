import isNode from 'detect-node';

/**
 * @class URLManager
 * @author Isaac Ewing
 * @version 1.0.0 12/04/20 05:34 pm
 * @classdesc This class handles the simplification of determining the dev/prod url for each part automatically using env vars
 */
export class URLManager {
     /**
      *
      * @return {string}
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/04/20 05:35 pm
      * @see Contact
      * @see GraphQL
      * @see Newsletter
      * @see OAuth
      * @see Report
      * @see Root
      */
     public static get Contact(): string {
          return [
               process.env.REACT_APP_API_CONTACT_HTTP,
               process.env.REACT_APP_API_CONTACT_DOMAIN,
               ':',
               process.env.REACT_APP_API_CONTACT_PORT,
               process.env.REACT_APP_API_CONTACT_PATH,
          ].join( '' );
     }

     /**
      *
      * @return {string}
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/04/20 05:35 pm
      * @see Contact
      * @see GraphQL
      * @see Newsletter
      * @see OAuth
      * @see Report
      * @see Root
      */
     public static get GraphQL(): string {
          return [
               process.env.REACT_APP_API_GRAPHQL_HTTP,
               process.env.REACT_APP_API_GRAPHQL_DOMAIN,
               ':',
               process.env.REACT_APP_API_GRAPHQL_PORT,
               process.env.REACT_APP_API_GRAPHQL_PATH,
          ].join( '' );
     }

     /**
      *
      * @return {string}
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 06:25 pm
      * @see Contact
      * @see GraphQL
      * @see Newsletter
      * @see OAuth
      * @see Report
      * @see Root
      */
     public static get Newsletter(): string {
          return [
               process.env.REACT_APP_API_NEWSLETTER_HTTP,
               process.env.REACT_APP_API_NEWSLETTER_DOMAIN,
               ':',
               process.env.REACT_APP_API_NEWSLETTER_PORT,
               process.env.REACT_APP_API_NEWSLETTER_PATH,
          ].join( '' );
     }

     /**
      *
      * @return {string}
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/04/20 05:35 pm
      * @see Contact
      * @see GraphQL
      * @see Newsletter
      * @see OAuth
      * @see Report
      * @see Root
      */
     public static get OAuth(): string {
          return [
               process.env.REACT_APP_API_OAUTH_HTTP,
               process.env.REACT_APP_API_OAUTH_DOMAIN,
               ':',
               process.env.REACT_APP_API_OAUTH_PORT,
               process.env.REACT_APP_API_OAUTH_PATH,
          ].join( '' );
     }

     /**
      *
      * @return {string}
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 01:31 pm
      * @see Contact
      * @see GraphQL
      * @see Newsletter
      * @see OAuth
      * @see Report
      * @see Root
      */
     public static get Report(): string {
          return [
               process.env.REACT_APP_API_REPORT_HTTP,
               process.env.REACT_APP_API_REPORT_DOMAIN,
               ':',
               process.env.REACT_APP_API_REPORT_PORT,
               process.env.REACT_APP_API_REPORT_PATH,
          ].join( '' );
     }

     /**
      *
      * @return {string}
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/04/20 05:35 pm
      * @see Contact
      * @see GraphQL
      * @see Newsletter
      * @see OAuth
      * @see Report
      * @see Root
      */
     public static get Root(): string {
          return [
               process.env.REACT_APP_API_ROOT_HTTP,
               process.env.REACT_APP_API_ROOT_DOMAIN,
               ':',
               process.env.REACT_APP_API_ROOT_PORT,
               process.env.REACT_APP_API_ROOT_PATH,
          ].join( '' );
     }

     /**
      *
      * @return {string}
      * @static
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/15/21 02:46 pm
      * @see Domain
      * @see Page
      */
     public static get Domain(): string {
          return !isNode ? window.location.hostname : process.env.REACT_APP_API_ROOT_DOMAIN;
     }

     /**
      *
      * @return {string}
      * @static
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/15/21 02:46 pm
      * @see Domain
      * @see Page
      */
     public static get Page(): string {
          return !isNode ? window.location.pathname : '/';
     }

     /**
      *
      * @return {boolean}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/10/20 04:23 pm
      */
     public static get isLive(): boolean {
          return process.env.NODE_ENV !== 'development';
     }

     /**
      *
      * @return {boolean}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 04/09/21 12:26 pm
      * @see isHomePage
      * @see isNotHomePage
      */
     public static get isHomePage(): boolean {
          return URLManager.Page === '/';
     }

     /**
      *
      * @return {boolean}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 04/09/21 12:33 pm
      * @see isHomePage
      * @see isNotHomePage
      */
     public static get isNotHomePage(): boolean {
          return URLManager.Page !== '/';
     }
}