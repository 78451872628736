import React      from 'react';
import PropTypes  from 'prop-types';
import { Helmet } from 'react-helmet';

/**
 *
 * @param {any} description
 * @param {any} lang
 * @param {any} meta
 * @param {any} title
 * @return {JSX.Element}
 * @constructor
 */
export function SEO( { description, lang, meta, title } ) {
     return (
          <Helmet
               htmlAttributes={ {
                    lang,
               } }
               title={ title }
               meta={ [
                    {
                         name   : `description`,
                         content: description,
                    },
                    {
                         property: `og:title`,
                         content : title,
                    },
                    {
                         property: `og:description`,
                         content : description,
                    },
                    {
                         property: `og:type`,
                         content : `website`,
                    },
                    {
                         name   : `twitter:card`,
                         content: `summary`,
                    },
                    /*
                     {
                     name   : `twitter:creator`,
                     content: site.siteMetadata?.author || ``,
                     },

                     */
                    {
                         name   : `twitter:title`,
                         content: title,
                    },
                    {
                         name   : `twitter:description`,
                         content: description,
                    },
               ].concat( meta ) }
          />
     );
}

SEO.defaultProps = {
     lang       : `en`,
     meta       : [],
     description: ``,
};

SEO.propTypes = {
     description: PropTypes.string,
     lang       : PropTypes.string,
     meta       : PropTypes.arrayOf( PropTypes.object ),
     title      : PropTypes.string.isRequired,
};

export default SEO;
