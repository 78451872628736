import React           from 'react';
import { FormEvent }   from 'react';
import { InputModule } from '../../../api/com/ewing/social/module';
import { BaseContact } from '../../atom/contact/BaseContact';
import { Description } from '../../atom/Description';
import { Image }       from '../../atom/Image';
import { Title }       from '../../atom/Title';

/**
 * @class Contact13
 * @extends BaseContact
 * @author Isaac Ewing
 * @version 1.0.0 02/04/21 02:36 pm
 * @classdesc This class handles the default contact functionality for the site
 */
export class Contact13 extends BaseContact<any, any> {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/04/21 02:36 pm
      */
     protected static readonly COMPONENT_CLASS: string = 'contact-13';

     /**
      *
      * @param event {FormEvent}
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/17/21 05:04 pm
      */
     protected onSubmit( event: FormEvent<HTMLFormElement> ): void {
          this.onSubmitPartner( event );
     }

     /**
      *
      * @param props {any}
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am - documented
      */
     public constructor( props: any ) {
          super( props );

          this.state = {
               module   : props.module,
               page     : props.page,
               hash     : this.registerHash(),
               className: new Set<string>( [ 'grid-x', Contact13.COMPONENT_CLASS ] ),
               data     : {
                    name     : InputModule.BuildForName(),
                    email    : InputModule.BuildForEmail(),
                    message  : InputModule.BuildForMessage( { placeholder: 'What partner terms do you have?' } ),
                    affiliate: InputModule.BuildForAffiliate(),
                    sponsor  : InputModule.BuildForSponsor(),
               },
          };

          this.state.module?.button?.enableSubmit();
          this.onSubmit        = this.onSubmit.bind( this );
          this.onResponseEmail = this.onResponseEmail.bind( this );
          this.registerProps( this.state.module ?? null );
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am - documented
      */
     public render(): JSX.Element {
          return (
               <section { ...this.registerSectionProps() }>
                    <div className="grid-container content">
                         <div className="grid-x align-left">
                              <div className="cell small-12 medium-12">
                                   <Title module={ this.state?.module?.title } />
                                   <Description module={ this.state?.module?.description } />
                              </div>
                         </div>
                         <div className={ `grid-x align-left ${ Contact13.CLASS_FORM_SECTION }` }>
                              {
                                   this.state?.module?.image &&
                                   <div className="cell small-12 medium-5">
                                        <Image module={ this.state?.module?.image } rounded greyed shadow />
                                   </div>
                              }
                              <div className={ `cell small-12 medium-${ this.state?.module?.image ? '7' : '12' }  contact-form` }>
                                   <form id={ Contact13.getHashId( this.state.hash, 'form' ) } className={ Contact13.CLASS_FORM } onSubmit={ this.onSubmit } data-abide={ true } noValidate>
                                        <div className="grid-x">
                                             { this.buildInput( this.state.data.name ?? null ) }
                                             { this.buildInput( this.state.data.email ?? null ) }
                                             { this.buildCheckbox( this.state.data.affiliate ?? null ) }
                                             { this.buildCheckbox( this.state.data.sponsor ?? null ) }
                                             { this.buildInput( this.state.data.message ?? null ) }
                                             { this.buildButton() }
                                             { this.buildFeedback() }
                                        </div>
                                   </form>
                              </div>
                         </div>
                    </div>
               </section>
          );
     }
}