import { TitleModule } from './title.module';
import { IInvite }     from '../../interface';
import { Util }        from '../../tool';

/**
 * @class InviteModule
 * @extends TitleModule
 * @implements IInvite
 * @author Isaac Ewing
 * @version 1.0.0 12/19/20 10:47 pm
 * @version 1.1.0 02/18/21 05:16 pm - updated to extend AComponent
 * @version 2.0.0 05/28/21 05:47 pm - converted from AComponent to TitleModule
 */
export class InviteModule extends TitleModule implements IInvite {
     /**
      *
      * @param {InviteModule} module
      * @param module {SetupModule} The object containing the data
      * @return {InviteModule|null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 04:23 pm
      * @see Build
      * @see id
      * @see default
      * @see active
      * @see nonce
      * @see used
      * @see code
      * @see setId
      * @see setDefault
      * @see setActive
      * @see setNonce
      * @see setUsed
      * @see setCode
      * @see toObject
      */
     public static Build( module: InviteModule ): InviteModule;
     public static Build( obj: Partial<IInvite> ): InviteModule;
     public static Build( json: string ): InviteModule;
     public static Build( id?: number, active?: boolean, nonce?: boolean, used?: number, code?: string ): InviteModule;
     public static Build( id?: number, active?: unknown, nonce?: boolean, used?: number, code?: string ): InviteModule;
     public static Build( dataOrId?: unknown, active?: boolean, nonce?: boolean, used?: number, code?: string ): InviteModule {
          if( dataOrId ) {
               if( dataOrId instanceof InviteModule ) {
                    return dataOrId;
               }
               if( typeof dataOrId === 'object' ) {
                    const localData: Partial<IInvite> = dataOrId;

                    return new InviteModule( localData?.id ?? null, localData?.active ?? null, localData?.nonce ?? null, localData?.used ?? null, localData?.code ?? null );
               }
               if( typeof dataOrId === 'number' ) {
                    return new InviteModule( dataOrId ?? null, active ?? null, nonce ?? null, used ?? null, code ?? null );
               }
               if( typeof dataOrId === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrId ) );
                    } catch( exception: unknown ) {
                         // not a valid json string
                         return new InviteModule( +dataOrId ?? null, active ?? null, nonce ?? null, used ?? null, code ?? null );
                    }
               }
          }

          return null;
     }

     /**
      *
      * @param {number} id
      * @param {boolean} active
      * @param {boolean} nonce
      * @param {number} used
      * @param {string} code
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/18/21 05:16 pm
      * @see Build
      * @see id
      * @see default
      * @see active
      * @see nonce
      * @see used
      * @see code
      * @see setId
      * @see setDefault
      * @see setActive
      * @see setNonce
      * @see setUsed
      * @see setCode
      * @see toObject
      */
     public constructor( id?: number, active?: boolean, nonce?: boolean, used?: number, code?: string ) {
          super( id ?? null );

          this.default = Util.asBoolean( process.env.REACT_APP_INVITE_DEFAULT );
          this.active  = Util.asBoolean( active ?? process.env.REACT_APP_INVITE_ACTIVE );
          this.nonce   = Util.asBoolean( nonce ?? process.env.REACT_APP_INVITE_NONCE );
          this.used    = +( nonce ?? process.env.REACT_APP_INVITE_USED );
          this.code    = code ?? process.env.REACT_APP_INVITE_CODE;
     }

     /**
      *
      * @return {boolean}
      * @deprecated since 1.0.0, this is being removed in future versions
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/18/21 05:16 pm
      * @see Build
      * @see id
      * @see default
      * @see active
      * @see nonce
      * @see used
      * @see code
      * @see setId
      * @see setDefault
      * @see setActive
      * @see setNonce
      * @see setUsed
      * @see setCode
      * @see toObject
      */
     public get default(): boolean {
          return this._data.get( 'default' );
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @deprecated since 1.0.0, this is being removed in future versions
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/18/21 05:16 pm
      * @see Build
      * @see id
      * @see default
      * @see active
      * @see nonce
      * @see used
      * @see code
      * @see setId
      * @see setDefault
      * @see setActive
      * @see setNonce
      * @see setUsed
      * @see setCode
      * @see toObject
      */
     public set default( value: boolean ) {
          this._data.set( 'default', value );
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/18/21 05:16 pm
      * @see Build
      * @see id
      * @see default
      * @see active
      * @see nonce
      * @see used
      * @see code
      * @see setId
      * @see setDefault
      * @see setActive
      * @see setNonce
      * @see setUsed
      * @see setCode
      * @see toObject
      */
     public get active(): boolean {
          return this._data.get( 'active' );
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/18/21 05:16 pm
      * @see Build
      * @see id
      * @see default
      * @see active
      * @see nonce
      * @see used
      * @see code
      * @see setId
      * @see setDefault
      * @see setActive
      * @see setNonce
      * @see setUsed
      * @see setCode
      * @see toObject
      */
     public set active( value: boolean ) {
          this._data.set( 'active', value );
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/18/21 05:16 pm
      * @see Build
      * @see id
      * @see default
      * @see active
      * @see nonce
      * @see used
      * @see code
      * @see setId
      * @see setDefault
      * @see setActive
      * @see setNonce
      * @see setUsed
      * @see setCode
      * @see toObject
      */
     public get nonce(): boolean {
          return this._data.get( 'nonce' );
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/18/21 05:16 pm
      * @see Build
      * @see id
      * @see default
      * @see active
      * @see nonce
      * @see used
      * @see code
      * @see setId
      * @see setDefault
      * @see setActive
      * @see setNonce
      * @see setUsed
      * @see setCode
      * @see toObject
      */
     public set nonce( value: boolean ) {
          this._data.set( 'nonce', value );
     }

     /**
      *
      * @return {number}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/18/21 05:16 pm
      * @see Build
      * @see id
      * @see default
      * @see active
      * @see nonce
      * @see used
      * @see code
      * @see setId
      * @see setDefault
      * @see setActive
      * @see setNonce
      * @see setUsed
      * @see setCode
      * @see toObject
      */
     public get used(): number {
          return this._data.get( 'used' );
     }

     /**
      *
      * @param {number} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/18/21 05:16 pm
      * @see Build
      * @see id
      * @see default
      * @see active
      * @see nonce
      * @see used
      * @see code
      * @see setId
      * @see setDefault
      * @see setActive
      * @see setNonce
      * @see setUsed
      * @see setCode
      * @see toObject
      */
     public set used( value: number ) {
          this._data.set( 'used', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/18/21 05:16 pm
      * @see Build
      * @see id
      * @see default
      * @see active
      * @see nonce
      * @see used
      * @see code
      * @see setId
      * @see setDefault
      * @see setActive
      * @see setNonce
      * @see setUsed
      * @see setCode
      * @see toObject
      */
     public get code(): string {
          return this._data.get( 'code' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/18/21 05:16 pm
      * @see Build
      * @see id
      * @see default
      * @see active
      * @see nonce
      * @see used
      * @see code
      * @see setId
      * @see setDefault
      * @see setActive
      * @see setNonce
      * @see setUsed
      * @see setCode
      * @see toObject
      */
     public set code( value: string ) {
          this._data.set( 'code', value );
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @deprecated since 1.0.0, this is being removed in future versions
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/18/21 05:16 pm
      * @see Build
      * @see id
      * @see default
      * @see active
      * @see nonce
      * @see used
      * @see code
      * @see setId
      * @see setDefault
      * @see setActive
      * @see setNonce
      * @see setUsed
      * @see setCode
      * @see toObject
      */
     public setDefault( value: boolean ): this {
          this._data.set( 'default', value );

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/18/21 05:16 pm
      * @see Build
      * @see id
      * @see default
      * @see active
      * @see nonce
      * @see used
      * @see code
      * @see setId
      * @see setDefault
      * @see setActive
      * @see setNonce
      * @see setUsed
      * @see setCode
      * @see toObject
      */
     public setActive( value: boolean ): this {
          this.active = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/18/21 05:16 pm
      * @see Build
      * @see id
      * @see default
      * @see active
      * @see nonce
      * @see used
      * @see code
      * @see setId
      * @see setDefault
      * @see setActive
      * @see setNonce
      * @see setUsed
      * @see setCode
      * @see toObject
      */
     public setNonce( value: boolean ): this {
          this.nonce = value;

          return this;
     }

     /**
      *
      * @param {number} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/18/21 05:16 pm
      * @see Build
      * @see id
      * @see default
      * @see active
      * @see nonce
      * @see used
      * @see code
      * @see setId
      * @see setDefault
      * @see setActive
      * @see setNonce
      * @see setUsed
      * @see setCode
      * @see toObject
      */
     public setUsed( value: number ): this {
          this.used = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/18/21 05:16 pm
      * @see Build
      * @see id
      * @see default
      * @see active
      * @see nonce
      * @see used
      * @see code
      * @see setId
      * @see setDefault
      * @see setActive
      * @see setNonce
      * @see setUsed
      * @see setCode
      * @see toObject
      */
     public setCode( value: string ): this {
          this.code = value;

          return this;
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/18/21 05:16 pm
      * @see Build
      * @see id
      * @see default
      * @see active
      * @see nonce
      * @see used
      * @see code
      * @see setId
      * @see setDefault
      * @see setActive
      * @see setNonce
      * @see setUsed
      * @see setCode
      * @see toObject
      */
     public toObject(): Record<string, unknown> {
          return {
               id    : this.id,
               active: this.active,
               nonce : this.nonce,
               used  : this.used,
               code  : this.code,
          };
     }
}