import { AModule }         from '../../abstract/module.abstract';
import { CoordinatesUtil } from '../../tool';

/**
 * @class MapModule
 * @extends AModule
 * @author Isaac Ewing
 * @version 1.0.0 12/09/20 01:41 pm
 * @version 2.0.0 05/30/21 08:11 pm - updated to extend AModule (was BaseModule)
 */
export class MapModule extends AModule {
     /**
      * @param data {Object|null} The object containing the data
      * @return {MapModule|null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 12:34 pm
      */
     public static Build( data: Record<string, unknown> ): MapModule | null {
          if( data ) {
               return new MapModule( data );
          }

          return null;
     }

     /**
      *
      * @param data
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 01:41 pm
      */
     public constructor( data: Record<string, unknown> ) {
          super( null );

          this.center = CoordinatesUtil.Build( data.center ?? null );
          this.focus  = CoordinatesUtil.Build( data.focus ?? null );
          this.zoom   = +( data.zoom ?? process.env.REACT_APP_MAP_ZOOM );
     }

     /**
      *
      * @return {CoordinatesUtil}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 01:41 pm
      * @see center
      * @see focus
      * @see zoom
      */
     public get center(): CoordinatesUtil {
          return this._data.get( 'center' );
     }

     /**
      *
      * @param {CoordinatesUtil} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/30/21 08:11 pm
      * @see center
      * @see focus
      * @see zoom
      */
     public set center( value: CoordinatesUtil ) {
          this._data.set( 'center', value );
     }

     /**
      *
      * @return {CoordinatesUtil}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 01:41 pm
      * @see center
      * @see focus
      * @see zoom
      */
     public get focus(): CoordinatesUtil {
          return this._data.get( 'focus' );
     }

     /**
      *
      * @param {CoordinatesUtil} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/30/21 08:11 pm
      * @see center
      * @see focus
      * @see zoom
      */
     public set focus( value: CoordinatesUtil ) {
          this._data.set( 'focus', value );
     }

     /**
      *
      * @return {number}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 01:41 pm
      * @see center
      * @see focus
      * @see zoom
      */
     public get zoom(): number {
          return this._data.get( 'zoom' );
     }

     /**
      *
      * @param {number} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/30/21 08:11 pm
      * @see center
      * @see focus
      * @see zoom
      */
     public set zoom( value: number ) {
          this._data.set( 'zoom', value );
     }

     public toObject(): Record<string, unknown> {
          return {
               id    : null,
               center: this.center.toObject(),
               focus : this.focus.toObject(),
               zoom  : this.zoom,
          };
     }
}