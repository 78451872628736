import * as ShortcodeLib     from '../../../../../../node_modules/meta-shortcodes/lib/parser';
import { ComponentEnum }     from '../enum';
import { ShortcodeModule }   from '../component';
import { ShortcodeObserver } from '../observer';
import { CacheManager }      from './shortcode';

//const ShortcodeLib = require( '../../../../../../node_modules/meta-shortcodes/lib/parser' );

/**
 * @class ShortcodeManager
 * @author Isaac Ewing
 * @version 1.0.0 11/01/20 11:52 am
 */
export class ShortcodeManager {
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 05/09/21 11:08 am
     */
    protected static readonly CONSOLE_PREFIX: string      = `${ process.env.REACT_APP_CONSOLE_PREFIX_MANAGER } Shor ${ process.env.REACT_APP_CONSOLE_SUFFIX_MANAGER }`;
    /**
     *
     * @type {boolean}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/22/21 08:36 pm
     */
    protected static readonly CONSOLE_ENABLED: boolean    = true;
    /**
     *
     * @type {ShortcodeLib}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 11/01/20 11:52 am
     */
    protected static readonly ShortcodeLibrary            = ShortcodeLib();
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/19/21 04:32 pm
     */
    protected static readonly DEFAULT_PLACEHOLDER: string = process.env.REACT_APP_SHORTCODE_PLACEHOLDER_TEXT;
    /**
     *
     * @type {boolean}
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/22/21 08:36 pm
     */
    protected static initialized: boolean                 = false;

    /**
     *
     * @param type {ComponentEnum}
     * @param id {number}
     * @return {ShortcodeModule}
     * @author Isaac Ewing
     * @version 1.0.0 10/09/20 07:29 pm
     */
    protected static buildShortcode( type: ComponentEnum, id: number ): ShortcodeModule {
        // CacheManager.add( type, id );

        const module: ShortcodeModule = CacheManager.Add( type, id ).GetCache( type, id );

        //console.log( '********** BUILD SHORTCODE MODULE', module );
        ShortcodeObserver.notify( module );

        return module;
    }

    /**
     *
     * @return {void}
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 10/09/20 07:29 pm
     */
    protected static registerTypes(): void {
        const types: ComponentEnum[] = [
            ComponentEnum.Link,
            //Component.TEST,
        ];

        types.map( ( type: ComponentEnum ): void => {
            if( this.CONSOLE_ENABLED ) {
                console.log( `${ this.CONSOLE_PREFIX } SHORTCODE MANAGER TYPE`, type, `${ type }`, '===', type === `${ type }` );
            }

            ShortcodeManager.ShortcodeLibrary.add( type, ( options: Record<string, number>, text: string ): string => {
                if( this.CONSOLE_ENABLED ) {
                    console.log( `${ this.CONSOLE_PREFIX } SHORTCODE MANAGER PARSE`, { type, options, text } );
                }

                if( options?.id ) {
                    const module: ShortcodeModule = this.buildShortcode( type ?? null, +options.id ?? null );
                    const placeholder: string     = module.placeholder;
                    const fixed: string           = placeholder.replaceAll( this.DEFAULT_PLACEHOLDER, 'Shortcode' );

                    if( this.CONSOLE_ENABLED ) {
                        console.log( `${ this.CONSOLE_PREFIX } MAP SHORTCODE PLACEHOLDER`, { placeholder, fixed } );
                    }

                    return fixed;
                }

                return text;
            } );
        } );
    }

    /**
     *
     * @return {void}
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 10/09/20 07:29 pm
     */
    public static register(): void {
        if( !this.initialized ) {
            this.registerTypes();
            this.initialized = true;
        }
    }

    /**
     *
     * @param text {string}
     * @return {string}
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 10/09/20 07:30 pm
     * @version 1.0.0 12/22/20 04:41 pm removed that, no more dynamic state binding
     */
    public static parse( text: string ): string {
        const temp: string = ShortcodeManager.ShortcodeLibrary.parse( text );

        if( this.CONSOLE_ENABLED ) {
            console.log( `${ this.CONSOLE_PREFIX } \\/\\/\\/\\/\\/ SHORTCODE MANAGER PARSE`, { text, temp } );
        }

        return temp;
    }
}