import { AModule }    from './module.abstract';
import { IComponent } from '../interface';
import {
    ComponentEnum, HAlignEnum,
    ShortcodeActionEnum, TypeEnum, VAlignEnum,
}                     from '../enum';
import { Util }       from '../tool';

/**
 * @class AComponent
 * @extends AModule
 * @implements IComponent
 * @abstract
 * @author Isaac Ewing
 * @version 1.0.0 02/18/21 10:49 am
 */
export abstract class AComponent extends AModule implements IComponent {
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 10:49 am
     */
    protected static readonly DEFAULT_JQUERY: string     = 'com';
    /**
     *
     * @type {boolean}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 11:15 am
     */
    protected static readonly DEFAULT_SHORTCODE: boolean = false;

    /**
     *
     * @param {string} className
     * @return {Set<string>}
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 06:57 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    protected static addClassNames( className: string | null ): Set<string>;
    protected static addClassNames( classNames: string[] | null ): Set<string>;
    protected static addClassNames( classNames: Set<string> | null ): Set<string>;
    protected static addClassNames( classNames: unknown ): Set<string> {
        if( typeof classNames === 'string' ) {
            const testArray: string[]       = classNames.split( ' ' );
            const resultString: Set<string> = new Set<string>();

            testArray.forEach( ( value: string ): void => {
                resultString.add( value );
            } );

            return resultString;
        } else if( Array.isArray( classNames ) ) {
            const resultArray: Set<string> = new Set<string>();

            classNames.forEach( value => resultArray.add( value ) );

            return resultArray;
        } else if( classNames instanceof Set ) {
            return classNames;
        }

        return new Set<string>();
    }

    /**
     * @static
     * @param data {Object} The object containing the data
     * @return {any|null} Returns a new instance of the module or null
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 06:57 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     * TODO: 02/18/21 10:34 am
     *  add support for class error - override method
     */
    public static BuildForShortcode( data?: unknown ): unknown {
        return data ?? null;
    }

    /**
     *
     * @param id {number | string} The object containing the data
     * @param hash {string}
     * @param className {string | string[]}
     * @param jquery {string}
     * @param shortcode {boolean} Indicates if this is being created for a shortcode or not
     * @constructor
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 06:57 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    protected constructor( id?: number | string | null, hash?: string | null, className?: string, jquery?: string, shortcode?: boolean );
    protected constructor( id?: number | string | null, hash?: string | null, classNames?: string[], jquery?: string, shortcode?: boolean );
    protected constructor( id?: number | string | null, hash?: string | null, classNames?: Set<string>, jquery?: string, shortcode?: boolean );
    protected constructor( id?: number | string | null, hash?: string | null, classNames?: never, jquery?: string, shortcode?: boolean ) {
        super( id ?? null );

        hash ??= Util.registerHash();
        this.key       = hash ?? null;
        this.hash      = hash ?? null;
        this.className = AComponent.addClassNames( classNames ?? null );
        this.jquery    = jquery ?? null;
        this.shortcode = shortcode ?? AComponent.DEFAULT_SHORTCODE;
    }

    /**
     *
     * @return {string|null} Returns the string for the text
     * @author Isaac Ewing
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 06:57 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    public get key(): string | null {
        if( !this._data.get( 'key' ) ) {
            this.hash = Util.registerHash();
        }

        return this._data.get( 'key' );
    }

    /**
     *
     * @param {string|null} value
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 06:57 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    public set key( value: string | null ) {
        this._data.set( 'key', value );
    }

    /**
     *
     * @return {string} Returns the string for the text
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 06:57 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    public get hash(): string {
        if( !this._data.get( 'hash' ) ) {
            this.hash = Util.registerHash();
        }

        return this._data.get( 'hash' );
    }

    /**
     *
     * @param {string} value
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 06:57 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    public set hash( value: string ) {
        this._data.set( 'hash', value );
    }

    /**
     *
     * @return {string}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 06:57 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    public get className(): Set<string> {
        return this._data.get( 'className' );
    }

    /**
     *
     * @param {Set<string>} values
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/26/21 03:03 pm
     * @see setId
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setData
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    public set className( values: Set<string> ) {
        this.setClassName( values );
    }

    /**
     *
     * @return {string}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 06:57 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    public get state(): string {
        return `${ this.jquery }${ this.id }`;
    }

    /**
     *
     * @return {any}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 06:57 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    public get data(): unknown {
        return this._data.get( 'data' );
    }

    /**
     *
     * @param value {any}
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 06:57 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    public set data( value: unknown ) {
        this._data.set( 'data', value );
    }

    /**
     *
     * @return {ComponentEnum | ShortcodeActionEnum | TypeEnum} Returns the string for the text
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 06:57 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    public get type(): ComponentEnum | ShortcodeActionEnum | TypeEnum {
        return this._data.get( 'type' );
    }

    /**
     *
     * @param value {ComponentEnum | ShortcodeActionEnum | TypeEnum} The string to set for the text
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 06:57 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    public set type( value: ComponentEnum | ShortcodeActionEnum | TypeEnum ) {
        this._data.set( 'type', value );
    }

    /**
     *
     * @return {string}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 06:57 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    public get jquery(): string | null {
        return this._data.get( 'jquery' );
    }

    /**
     *
     * @param {string} value
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 06:57 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    public set jquery( value: string | null ) {
        this._data.set( 'jquery', value );
    }

    /**
     *
     * @return {string} Returns the string for the path
     * @deprecated since 1.0.0, use <code>jquery</code> instead
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 06:57 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    public get jqueryId(): string {
        return `#${ this.jquery }${ this.id }`;
    }

    /**
     *
     * @return {string}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 06:57 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    public get placeholder(): string {
        return `#${ this.jquery }${ Util.capitalizeFirstLetter( this.id ) }`;
    }

    /**
     *
     * @return {boolean}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 06:57 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see placeholder
     * @see shortcode
     * @see shortcodeId
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    public get shortcode(): boolean {
        return this._data.get( 'shortcode' );
    }

    /**
     *
     * @param {boolean} value
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 06:57 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see placeholder
     * @see shortcode
     * @see shortcodeId
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    public set shortcode( value: boolean ) {
        this._data.set( 'shortcode', value );
    }

    /**
     *
     * @return {string} Returns the string for the path
     * @deprecated since 1.0.0, Use <code>shortcode</code> instead
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 06:57 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see placeholder
     * @see shortcode
     * @see shortcodeId
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    public get shortcodeId(): string {
        return `${ this.jquery }${ this.id }com`;
    }

    /**
     *
     * @return {string} Returns the string for the text
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 12:58 pm
     * @see text
     */
    public get text(): string {
        return this._data.get( 'text' );
    }

    /**
     *
     * @param value {string} The string to set for the text
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 12:58 pm
     * @see text
     */
    public set text( value: string ) {
        this._data.set( 'text', value ?? null );
    }

    /**
     *
     * @return {string} Returns the string for the path
     * @author Isaac Ewing
     * @version 1.0.0 02/21/21 04:03 pm
     * @see id
     * @see text
     * @see path
     * @see icon
     * @see jqueryId
     * @see submit
     * @see enableSubmit
     * @see disableSubmit
     */
    public get path(): string {
        return this._data.get( 'path' );
    }

    /**
     *
     * @param value {string} The string to set for the path
     * @author Isaac Ewing
     * @version 1.0.0 02/21/21 04:03 pm
     * @see id
     * @see text
     * @see path
     * @see icon
     * @see jqueryId
     * @see submit
     * @see enableSubmit
     * @see disableSubmit
     */
    public set path( value: string ) {
        this._data.set( 'path', value ?? null );
    }

    /**
     *
     * @return {boolean}
     * @public
     * @author Isaac Ewing
     * @version 1.1.0 02/22/21 05:34 pm
     * @see id
     * @see gallery
     * @see valign
     * @see halign
     * @see images
     * @see setGallery
     * @see setVAlign
     * @see setHAlign
     * @see setImages
     * @see addImage
     * @see enableGallery
     * @see disableGallery
     */
    public get gallery(): boolean {
        return this._data.get( 'gallery' );
    }

    /**
     *
     * @param {boolean} value
     * @public
     * @author Isaac Ewing
     * @version 1.1.0 02/22/21 05:34 pm
     * @see id
     * @see gallery
     * @see valign
     * @see halign
     * @see images
     * @see setGallery
     * @see setVAlign
     * @see setHAlign
     * @see setImages
     * @see addImage
     * @see enableGallery
     * @see disableGallery
     */
    public set gallery( value: boolean ) {
        this._data.set( 'gallery', value );
    }

    /**
     *
     * @return {VAlignEnum | string} Returns the string for the valign
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/21/21 10:49 pm
     * @see id
     * @see valign
     * @see halign
     * @see setVAlign
     * @see setHAlign
     */
    public get valign(): VAlignEnum | string {
        return this._data.get( 'valign' );
    }

    /**
     *
     * @param value {VAlignEnum | string} The string to set for the valign
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/21/21 10:49 pm
     * @see id
     * @see valign
     * @see halign
     * @see setVAlign
     * @see setHAlign
     */
    public set valign( value: VAlignEnum | string ) {
        this._data.set( 'valign', value );
    }

    /**
     *
     * @return {HAlignEnum | string} Returns the string for the halign
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/21/21 10:49 pm
     * @see id
     * @see valign
     * @see halign
     * @see setVAlign
     * @see setHAlign
     */
    public get halign(): HAlignEnum | string {
        return this._data.get( 'halign' );
    }

    /**
     *
     * @param value {HAlignEnum | string} The string to set for the halign
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/21/21 10:49 pm
     * @see id
     * @see valign
     * @see halign
     * @see setVAlign
     * @see setHAlign
     */
    public set halign( value: HAlignEnum | string ) {
        this._data.set( 'halign', value );
    }

    /**
     *
     * @param {string} value
     * @return {this}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 06:57 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    public setHash( value: string ): this {
        this._data.set( 'hash', value );

        return this;
    }

    /**
     *
     * @param {any} value
     * @return {this}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/26/21 03:03 pm
     * @see setId
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setData
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    public setClassName( value: string ): this;
    public setClassName( values: string[] ): this
    public setClassName( values: Set<string> ): this
    public setClassName( values: never ): this {
        this._data.delete( 'className' );
        this._data.set( 'className', AComponent.addClassNames( values ?? null ) );

        return this;
    }

    /**
     *
     * @param {string} value
     * @return {this}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 06:57 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    public setState( value: string ): this {
        this._data.set( 'state', value );

        return this;
    }

    /**
     *
     * @param value {unknown}
     * @return {this}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 06:57 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    public setData( value: unknown ): this {
        this._data.set( 'data', value );

        return this;
    }

    /**
     *
     * @param {ComponentEnum | ShortcodeActionEnum | TypeEnum} value
     * @return {this}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 06:57 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    public setType( value: ComponentEnum | ShortcodeActionEnum | TypeEnum ): this {
        this._data.set( 'type', value );

        return this;
    }

    /**
     *
     * @param {string} value
     * @return {this}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 06:57 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    public setJquery( value: string ): this {
        this._data.set( 'jquery', value );

        return this;
    }

    /**
     *
     * @param {boolean} value
     * @return {this}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 06:57 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    public setShortcode( value: boolean ): this {
        this._data.set( 'shortcode', value );

        return this;
    }

    /**
     *
     * @return {this}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 06:57 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    public setShortcodeComplete(): this {
        this._data.set( 'shortcode', false );

        return this;
    }

    /**
     *
     * @param value {string} The string to set for the text
     * @return {this}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 12:58 pm
     */
    public setText( value: string ): this {
        this.text = value;

        return this;
    }

    /**
     *
     * @param {string} value
     * @return {this}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/21/21 04:03 pm
     * @class id
     * @class text
     * @class path
     * @class icon
     * @class submit
     * @class setText
     * @class setPath
     * @class setIcon
     * @class setSubmit
     * @class enableSubmit
     * @class disableSubmit
     */
    public setPath( value: string ): this {
        this.path = value;

        return this;
    }

    /**
     *
     * @param {VAlignEnum | string} value
     * @return {this}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/21/21 10:49 pm
     * @see id
     * @see valign
     * @see halign
     * @see setVAlign
     * @see setHAlign
     */
    public setVAlign( value: VAlignEnum | string ): this {
        this.valign = value;

        return this;
    }

    /**
     *
     * @param {HAlignEnum | string} value
     * @return {this}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/21/21 10:49 pm
     * @see id
     * @see valign
     * @see halign
     * @see setVAlign
     * @see setHAlign
     */
    public setHAlign( value: HAlignEnum | string ): this {
        this.halign = value;

        return this;
    }

    /**
     *
     * @param {boolean} value
     * @return {this}
     * @public
     * @author Isaac Ewing
     * @version 1.1.0 02/22/21 05:34 pm
     * @see id
     * @see gallery
     * @see valign
     * @see halign
     * @see images
     * @see setGallery
     * @see setVAlign
     * @see setHAlign
     * @see setImages
     * @see addImage
     * @see enableGallery
     * @see disableGallery
     */
    public setGallery( value: boolean ): this {
        this.gallery = value;

        return this;
    }

    /**
     *
     * @param {string} value
     * @return {this} Returns an instance of itself for method chaining
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 06:57 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    public addClass( value: string ): this {
        //this._data.set( 'className', value );
        this.className.add( value );

        return this;
    }

    /**
     *
     * @param {string} value
     * @return {this}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 03/24/21 02:05 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    public removeClass( value: string ): this {
        this.className.delete( value );

        return this;
    }

    /**
     *
     * @param data {any}
     * @return {this}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 06:57 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    public buildForShortcode( data: unknown ): unknown {
        return AComponent.BuildForShortcode( data );
    }

    /**
     *
     * @return {this} Returns an instance of itself for method chaining
     * @deprecated since 1.0.0, Use <code>setShortcodeComplete</code> instead
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 06:57 pm
     * @see key
     * @see id
     * @see type
     * @see code
     * @see action
     * @see type
     * @see data
     * @see module
     * @see component
     * @see query
     * @see setClassName
     * @see setHash
     * @see setState
     * @see setType
     * @see setJquery
     * @see setShortcode
     * @see setShortcodeComplete
     * @see buildForShortcode
     * @see shortcodeComplete
     * @see addClass
     * @see removeClass
     */
    public shortcodeComplete(): this {
        this.setShortcodeComplete();

        return this;
    }

    /**
     *
     * @return {object}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/21/21 11:43 am
     */
    public toObject(): Record<string, unknown> {
        return {
            id       : this.id,
            hash     : this.hash,
            className: [ ...this.className ],
        };
    }
}