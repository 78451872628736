import { AComponent }                                 from '../../abstract';
import { ComponentEnum, ShortcodeActionEnum }         from '../../enum';
import { IModule, IShortcodeModule, IShortcodeProps } from '../../interface';
import { Util }                                       from '../../tool';

/**
 * @class ShortcodeModule
 * @extends AComponent
 * @implements IShortcodeModule
 * @author Isaac Ewing
 * @version 1.0.0 02/18/21 07:08 pm
 */
export class ShortcodeModule extends AComponent implements IShortcodeModule {
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/19/21 11:53 am
     */
    protected static readonly API_ROOT_NODE: string               = process.env.REACT_APP_GRAPHQL_GET_LINK ?? '';
    /**
     *
     * @type {boolean}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/22/21 08:36 pm
     */
    protected static readonly CONSOLE_ENABLED: boolean            = false;
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/19/21 04:32 pm
     */
    protected static readonly DEFAULT_PLACEHOLDER: string         = process.env.REACT_APP_SHORTCODE_PLACEHOLDER_TEXT ?? '';
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 05:42 pm
     */
    protected static readonly DEFAULT_JQUERY: string              = 'shortcode';
    /**
     *
     * @type {ShortcodeActionEnum}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 05:42 pm
     */
    protected static readonly DEFAULT_ACTION: ShortcodeActionEnum = ShortcodeActionEnum.Build;
    /**
     *
     * @type {ComponentEnum}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 05:42 pm
     */
    protected static readonly DEFAULT_TYPE: ComponentEnum         = ComponentEnum.Link;
    /**
     *
     * @type {boolean}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 05:42 pm
     */
    protected static readonly DEFAULT_COMPLETE: boolean           = false;
    /**
     *
     * @type {Map<ComponentEnum, Set<string>>}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/21/20 03:58 pm
     */
    protected static hashes: Map<ComponentEnum, Set<string>>      = new Map();

    /**
     *
     * @param module {ShortcodeModule} The object containing the data
     * @return {ShortcodeModule|null} Returns a new instance of the module or null
     * @constructor
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 07:08 pm
     * @see key
     * @see id
     * @see hash
     * @see action
     * @see type
     * @see data
     * @see complete
     * @see module
     * @see component
     * @see query
     * @see code
     * @see placeholder
     * @see setId
     * @see setHash
     * @see setAction
     * @see setType
     * @see setData
     * @see setComplete
     * @see setModule
     * @see setComponent
     * @see setQuery
     * @see isMatch
     * @see markComplete
     */
    public static Build( module: ShortcodeModule | null ): ShortcodeModule | null;
    public static Build( obj: Partial<IShortcodeModule> ): ShortcodeModule | null;
    public static Build( json: string | null ): ShortcodeModule | null;
    public static Build( key?: string | null, action?: ShortcodeActionEnum | null, type?: ComponentEnum | null, data?: unknown, complete?: boolean, module?: IModule | null,
                         component?: JSX.Element | null,
                         query?: Promise<void> | null,
                         id?: number | string | null, hash?: string | null, className?: string, jquery?: string, shortcode?: boolean ): ShortcodeModule | null;
    public static Build( key?: string | null, action?: ShortcodeActionEnum | null, type?: ComponentEnum | null, data?: unknown, complete?: boolean, module?: IModule | null,
                         component?: JSX.Element | null,
                         query?: Promise<void> | null,
                         id?: number | string | null, hash?: string | null, classNames?: string[], jquery?: string, shortcode?: boolean ): ShortcodeModule | null;
    public static Build( dataOrKey?: unknown, action?: ShortcodeActionEnum | null, type?: ComponentEnum | null, data?: unknown, complete?: boolean, module?: IModule | null,
                         component?: JSX.Element | null,
                         query?: Promise<void> | null,
                         id?: number | string | null, hash?: string | null, classNames?: string | string[] | Set<string> | null, jquery?: string, shortcode?: boolean ): ShortcodeModule | null {
        if( dataOrKey ) {
            dataOrKey = this.mapAPIRootNode( dataOrKey, this.API_ROOT_NODE );

            if( this.CONSOLE_ENABLED ) {
                console.log( '@@@@@ SHORTCODE MODULE - BUILD', 'data', dataOrKey );
            }

            if( dataOrKey instanceof ShortcodeModule ) {
                return dataOrKey;
            }
            if( typeof dataOrKey === 'object' ) {
                const localData: Partial<IShortcodeModule> = dataOrKey as Partial<IShortcodeModule>;

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return new ShortcodeModule( localData?.key ?? null, localData?.action ?? null, localData?.type ?? null, localData?.data ?? null,
                                            localData?.complete ?? false, localData?.module ?? null, localData?.component ?? null, localData?.query ?? null,
                                            localData?.id ?? null, localData?.hash ?? null, localData?.className ?? null, localData?.jquery ?? null,
                                            localData?.shortcode ?? null );
            }
            if( typeof dataOrKey === 'string' ) {
                try {
                    return this.Build( JSON.parse( dataOrKey ) );
                } catch( exception: unknown ) {
                    // not a valid json string
                    return new ShortcodeModule( dataOrKey ?? null, action ?? null, type ?? null, data ?? null, complete ?? false, module ?? null,
                                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                // @ts-ignore
                                                component ?? null, query ?? null, id ?? null, hash ?? null, classNames ?? null, jquery ?? null,
                                                shortcode ?? null );
                }
            }
        }

        return null;
    }

    /**
     *
     * @param module {ShortcodeModule} The object containing the data
     * @return {ShortcodeModule | null} Returns a new instance of the module or null
     * @constructor
     * @static
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 07:08 pm
     * @see key
     * @see id
     * @see hash
     * @see action
     * @see type
     * @see data
     * @see complete
     * @see module
     * @see component
     * @see query
     * @see code
     * @see placeholder
     * @see setId
     * @see setHash
     * @see setAction
     * @see setType
     * @see setData
     * @see setComplete
     * @see setModule
     * @see setComponent
     * @see setQuery
     * @see isMatch
     * @see markComplete
     */
    public static BuildForShortcode( module?: ShortcodeModule ): ShortcodeModule | null;
    public static BuildForShortcode( obj?: Partial<IShortcodeModule> ): ShortcodeModule | null;
    public static BuildForShortcode( json?: string ): ShortcodeModule | null;
    public static BuildForShortcode( key?: string, action?: ShortcodeActionEnum, type?: ComponentEnum, data?: unknown, complete?: boolean, module?: IModule, component?: JSX.Element,
                                     query?: Promise<void> | null, id?: number | string, hash?: string, className?: string, jquery?: string ): ShortcodeModule | null;
    public static BuildForShortcode( key?: string, action?: ShortcodeActionEnum, type?: ComponentEnum, data?: unknown, complete?: boolean, module?: IModule, component?: JSX.Element,
                                     query?: Promise<void> | null, id?: number | string, hash?: string, classNames?: string[], jquery?: string ): ShortcodeModule | null;
    public static BuildForShortcode( key?: string, action?: ShortcodeActionEnum, type?: ComponentEnum, data?: unknown, complete?: boolean, module?: IModule, component?: JSX.Element,
                                     query?: Promise<void> | null, id?: number | string, hash?: string, classNames?: Set<string>, jquery?: string ): ShortcodeModule | null;
    public static BuildForShortcode( dataOrKey?: never, action?: ShortcodeActionEnum | null, type?: ComponentEnum, data?: unknown, complete?: boolean, module?: IModule, component?: JSX.Element,
                                     query?: Promise<void> | null, id?: number | string, hash?: string, classNames?: never, jquery?: string ): ShortcodeModule | null {
        return ShortcodeModule.Build( dataOrKey, action ?? null, type ?? null, data ?? null, complete ?? false, module ?? null,
                                      component ?? null, query ?? null, id ?? null, hash ?? null, classNames, jquery, true );
    }

    /**
     *
     * @param {string | number} id
     * @param {string} type
     * @param {string} hash
     * @param {string} code
     * @param {string} key
     * @return {string}
     * @constructor
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/19/21 05:04 pm
     */
    public static BuildPlaceholder( id: string | number, type: ComponentEnum, hash: string, code: string, key: string | null ): string {
        const props: IShortcodeProps = {
            dataKey      : key ?? Util.registerHash(),
            dataId       : id as string ?? 'n/a',
            dataType     : type ?? ComponentEnum.Error,
            dataHash     : hash ?? 'n/a',
            dataCode     : code ?? 'n/a',
            dataShortcode: true,
        };

        return `<${ ShortcodeModule.DEFAULT_PLACEHOLDER } key="${ props.dataKey }" id="${ props.dataKey }" dataId="${ props.dataId }" dataType="${ props.dataType }" dataHash="${ props.dataHash }" dataCode="${ props.dataCode }" dataShortcode />`;
    }

    /**
     *
     * @param {string} key
     * @param {ShortcodeActionEnum} action
     * @param {ComponentEnum} type
     * @param data {any}
     * @param {boolean} complete
     * @param {IComponent} module
     * @param {JSX.Element} component
     * @param {Promise<void>} query
     * @param {number | string} id
     * @param {string} hash
     * @param {string} className
     * @param {string} jquery
     * @param {boolean} shortcode
     * @return {void}
     * @constructor
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 07:08 pm
     * @see key
     * @see id
     * @see hash
     * @see action
     * @see type
     * @see data
     * @see complete
     * @see module
     * @see component
     * @see query
     * @see code
     * @see placeholder
     * @see setId
     * @see setHash
     * @see setAction
     * @see setType
     * @see setData
     * @see setComplete
     * @see setModule
     * @see setComponent
     * @see setQuery
     * @see isMatch
     * @see markComplete
     */
    public constructor( key?: string | null, action?: ShortcodeActionEnum | null, type?: ComponentEnum | null, data?: unknown, complete?: boolean, module?: IModule | null,
                        component?: JSX.Element | null, query?: Promise<void> | null,
                        id?: number | string | null, hash?: string | null, className?: string | null, jquery?: string | null, shortcode?: boolean )
    public constructor( key?: string | null, action?: ShortcodeActionEnum | null, type?: ComponentEnum | null, data?: unknown, complete?: boolean, module?: IModule | null,
                        component?: JSX.Element | null, query?: Promise<void> | null,
                        id?: number | string | null, hash?: string | null, classNames?: string[] | null, jquery?: string | null, shortcode?: boolean );
    public constructor( key?: string | null, action?: ShortcodeActionEnum | null, type?: ComponentEnum | null, data?: unknown, complete?: boolean, module?: IModule | null,
                        component?: JSX.Element | null, query?: Promise<void> | null,
                        id?: number | string | null, hash?: string | null, classNames?: Set<string> | null, jquery?: string, shortcode?: boolean );
    public constructor( key?: string | null, action?: ShortcodeActionEnum | null, type?: ComponentEnum | null, data?: unknown, complete?: boolean, module?: IModule | null,
                        component?: JSX.Element | null, query?: Promise<void> | null,
                        id?: number | string | null, hash?: string | null, classNames?: never, jquery?: string | null, shortcode?: boolean ) {
        super( id ?? null, hash, classNames, jquery ?? ShortcodeModule.DEFAULT_JQUERY, shortcode );

        this.key       = key ?? Util.registerHash();
        this.action    = action ?? ShortcodeModule.DEFAULT_ACTION;
        this.type      = type ?? ShortcodeModule.DEFAULT_TYPE;
        this.data      = data ?? null;
        this.complete  = complete ?? ShortcodeModule.DEFAULT_COMPLETE;
        this.module    = module ?? null;
        this.component = component ?? null;
        this.query     = query ?? null;
    }

    /**
     *
     * @return {ShortcodeActionEnum}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 07:08 pm
     * @see key
     * @see id
     * @see hash
     * @see action
     * @see type
     * @see data
     * @see complete
     * @see module
     * @see component
     * @see query
     * @see code
     * @see placeholder
     * @see setId
     * @see setHash
     * @see setAction
     * @see setType
     * @see setData
     * @see setComplete
     * @see setModule
     * @see setComponent
     * @see setQuery
     * @see isMatch
     * @see markComplete
     */
    public get action(): ShortcodeActionEnum {
        return this._data.get( 'action' );
    }

    /**
     *
     * @param {ShortcodeActionEnum} value
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 07:08 pm
     * @see key
     * @see id
     * @see hash
     * @see action
     * @see type
     * @see data
     * @see complete
     * @see module
     * @see component
     * @see query
     * @see code
     * @see placeholder
     * @see setId
     * @see setHash
     * @see setAction
     * @see setType
     * @see setData
     * @see setComplete
     * @see setModule
     * @see setComponent
     * @see setQuery
     * @see isMatch
     * @see markComplete
     */
    public set action( value: ShortcodeActionEnum ) {
        if( !this.complete ) {
            this._data.set( 'action', value );
        }
    }

    /**
     *
     * @return {ComponentEnum}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 07:08 pm
     * @see key
     * @see id
     * @see hash
     * @see action
     * @see type
     * @see data
     * @see complete
     * @see module
     * @see component
     * @see query
     * @see code
     * @see placeholder
     * @see setId
     * @see setHash
     * @see setAction
     * @see setType
     * @see setData
     * @see setComplete
     * @see setModule
     * @see setComponent
     * @see setQuery
     * @see isMatch
     * @see markComplete
     */
    public get type(): ComponentEnum {
        return this._data.get( 'type' );
    }

    /**
     *
     * @param value {ComponentEnum} The string to set for the text
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 07:08 pm
     * @see key
     * @see id
     * @see hash
     * @see action
     * @see type
     * @see data
     * @see complete
     * @see module
     * @see component
     * @see query
     * @see code
     * @see placeholder
     * @see setId
     * @see setHash
     * @see setAction
     * @see setType
     * @see setData
     * @see setComplete
     * @see setModule
     * @see setComponent
     * @see setQuery
     * @see isMatch
     * @see markComplete
     */
    public set type( value: ComponentEnum ) {
        this._data.set( 'type', value );
    }

    /**
     *
     * @return {boolean}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 07:08 pm
     * @see key
     * @see id
     * @see hash
     * @see action
     * @see type
     * @see data
     * @see complete
     * @see module
     * @see component
     * @see query
     * @see code
     * @see placeholder
     * @see setId
     * @see setHash
     * @see setAction
     * @see setType
     * @see setData
     * @see setComplete
     * @see setModule
     * @see setComponent
     * @see setQuery
     * @see isMatch
     * @see markComplete
     */
    public get complete(): boolean {
        return this._data.get( 'complete' );
    }

    /**
     *
     * @param {boolean} value
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 07:08 pm
     * @see key
     * @see id
     * @see hash
     * @see action
     * @see type
     * @see data
     * @see complete
     * @see module
     * @see component
     * @see query
     * @see code
     * @see placeholder
     * @see setId
     * @see setHash
     * @see setAction
     * @see setType
     * @see setData
     * @see setComplete
     * @see setModule
     * @see setComponent
     * @see setQuery
     * @see isMatch
     * @see markComplete
     */
    public set complete( value: boolean ) {
        this._data.set( 'complete', value );
    }

    /**
     *
     * @return {IModule}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 07:08 pm
     * @see key
     * @see id
     * @see hash
     * @see action
     * @see type
     * @see data
     * @see complete
     * @see module
     * @see component
     * @see query
     * @see code
     * @see placeholder
     * @see setId
     * @see setHash
     * @see setAction
     * @see setType
     * @see setData
     * @see setComplete
     * @see setModule
     * @see setComponent
     * @see setQuery
     * @see isMatch
     * @see markComplete
     */
    public get module(): IModule | null {
        return this._data.get( 'module' );
    }

    /**
     *
     * @param {IModule} value
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 07:08 pm
     * @see key
     * @see id
     * @see hash
     * @see action
     * @see type
     * @see data
     * @see complete
     * @see module
     * @see component
     * @see query
     * @see code
     * @see placeholder
     * @see setId
     * @see setHash
     * @see setAction
     * @see setType
     * @see setData
     * @see setComplete
     * @see setModule
     * @see setComponent
     * @see setQuery
     * @see isMatch
     * @see markComplete
     */
    public set module( value: IModule | null ) {
        this._data.set( 'module', value );
    }

    /**
     *
     * @return {JSX.Element}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 07:08 pm
     * @see key
     * @see id
     * @see hash
     * @see action
     * @see type
     * @see data
     * @see complete
     * @see module
     * @see component
     * @see query
     * @see code
     * @see placeholder
     * @see setId
     * @see setHash
     * @see setAction
     * @see setType
     * @see setData
     * @see setComplete
     * @see setModule
     * @see setComponent
     * @see setQuery
     * @see isMatch
     * @see markComplete
     */
    public get component(): JSX.Element | null {
        return this._data.get( 'component' );
    }

    /**
     *
     * @param {JSX.Element} value
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 07:08 pm
     * @see key
     * @see id
     * @see hash
     * @see action
     * @see type
     * @see data
     * @see complete
     * @see module
     * @see component
     * @see query
     * @see code
     * @see placeholder
     * @see setId
     * @see setHash
     * @see setAction
     * @see setType
     * @see setData
     * @see setComplete
     * @see setModule
     * @see setComponent
     * @see setQuery
     * @see isMatch
     * @see markComplete
     */
    public set component( value: JSX.Element | null ) {
        this._data.set( 'component', value );
    }

    /**
     *
     * @return {Promise<void>}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 07:08 pm
     * @see key
     * @see id
     * @see hash
     * @see action
     * @see type
     * @see data
     * @see complete
     * @see module
     * @see component
     * @see query
     * @see code
     * @see placeholder
     * @see setId
     * @see setHash
     * @see setAction
     * @see setType
     * @see setData
     * @see setComplete
     * @see setModule
     * @see setComponent
     * @see setQuery
     * @see isMatch
     * @see markComplete
     */
    public get query(): Promise<void> | null {
        return this._data.get( 'query' );
    }

    /**
     *
     * @param {Promise<void>} value
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 07:08 pm
     * @see key
     * @see id
     * @see hash
     * @see action
     * @see type
     * @see data
     * @see complete
     * @see module
     * @see component
     * @see query
     * @see code
     * @see placeholder
     * @see setId
     * @see setHash
     * @see setAction
     * @see setType
     * @see setData
     * @see setComplete
     * @see setModule
     * @see setComponent
     * @see setQuery
     * @see isMatch
     * @see markComplete
     */
    public set query( value: Promise<void> | null ) {
        this._data.set( 'query', value );
    }

    /**
     *
     * @return {string}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 07:08 pm
     * @see key
     * @see id
     * @see hash
     * @see action
     * @see type
     * @see data
     * @see complete
     * @see module
     * @see component
     * @see query
     * @see code
     * @see placeholder
     * @see setId
     * @see setHash
     * @see setAction
     * @see setType
     * @see setData
     * @see setComplete
     * @see setModule
     * @see setComponent
     * @see setQuery
     * @see isMatch
     * @see markComplete
     */
    public get code(): string {
        return this.type + this.id;
    }

    /**
     *
     * @return {string} Returns the string for the text
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 07:08 pm
     * @see key
     * @see id
     * @see hash
     * @see action
     * @see type
     * @see data
     * @see complete
     * @see module
     * @see component
     * @see query
     * @see code
     * @see placeholder
     * @see setId
     * @see setHash
     * @see setAction
     * @see setType
     * @see setData
     * @see setComplete
     * @see setModule
     * @see setComponent
     * @see setQuery
     * @see isMatch
     * @see markComplete
     */
    public get placeholder(): string {
        if( !ShortcodeModule.hashes.has( this.type ) ) {
            ShortcodeModule.hashes.set( this.type, new Set() );
        }

        ShortcodeModule.hashes.get( this.type )?.add( this.key as string );

        return ShortcodeModule.BuildPlaceholder( this.id ?? null, this.type ?? null, this.hash ?? null, this.code ?? null, this.key ?? null );
    }

    /**
     *
     * @param {ShortcodeActionEnum} value
     * @return {this}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 07:08 pm
     * @see key
     * @see id
     * @see hash
     * @see action
     * @see type
     * @see data
     * @see complete
     * @see module
     * @see component
     * @see query
     * @see code
     * @see placeholder
     * @see setId
     * @see setHash
     * @see setAction
     * @see setType
     * @see setData
     * @see setComplete
     * @see setModule
     * @see setComponent
     * @see setQuery
     * @see isMatch
     * @see markComplete
     */
    public setAction( value: ShortcodeActionEnum ): this {
        this.action = value;

        return this;
    }

    /**
     *
     * @param {ComponentEnum} value
     * @return {this}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 07:08 pm
     * @see key
     * @see id
     * @see hash
     * @see action
     * @see type
     * @see data
     * @see complete
     * @see module
     * @see component
     * @see query
     * @see code
     * @see placeholder
     * @see setId
     * @see setHash
     * @see setAction
     * @see setType
     * @see setData
     * @see setComplete
     * @see setModule
     * @see setComponent
     * @see setQuery
     * @see isMatch
     * @see markComplete
     */
    public setType( value: ComponentEnum ): this {
        this._data.set( 'type', value );

        return this;
    }

    /**
     *
     * @param {boolean} value
     * @return {this}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 07:08 pm
     * @see key
     * @see id
     * @see hash
     * @see action
     * @see type
     * @see data
     * @see complete
     * @see module
     * @see component
     * @see query
     * @see code
     * @see placeholder
     * @see setId
     * @see setHash
     * @see setAction
     * @see setType
     * @see setData
     * @see setComplete
     * @see setModule
     * @see setComponent
     * @see setQuery
     * @see isMatch
     * @see markComplete
     */
    public setComplete( value: boolean ): this {
        this.complete = value;

        return this;
    }

    /**
     *
     * @param {IModule} value
     * @return {this}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 07:08 pm
     * @see key
     * @see id
     * @see hash
     * @see action
     * @see type
     * @see data
     * @see complete
     * @see module
     * @see component
     * @see query
     * @see code
     * @see placeholder
     * @see setId
     * @see setHash
     * @see setAction
     * @see setType
     * @see setData
     * @see setComplete
     * @see setModule
     * @see setComponent
     * @see setQuery
     * @see isMatch
     * @see markComplete
     */
    public setModule( value: IModule ): this {
        this.module = value;

        return this;
    }

    /**
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 07:08 pm
     * @see key
     * @see id
     * @see hash
     * @see action
     * @see type
     * @see data
     * @see complete
     * @see module
     * @see component
     * @see query
     * @see code
     * @see placeholder
     * @see setId
     * @see setHash
     * @see setAction
     * @see setType
     * @see setData
     * @see setComplete
     * @see setModule
     * @see setComponent
     * @see setQuery
     * @see isMatch
     * @see markComplete
     * @param {JSX.Element} value
     * @return {this}
     */
    public setComponent( value: JSX.Element | null ): this {
        this.component = value;

        return this;
    }

    /**
     *
     * @param {Promise<void>} value
     * @return {this}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 07:08 pm
     * @see key
     * @see id
     * @see hash
     * @see action
     * @see type
     * @see data
     * @see complete
     * @see module
     * @see component
     * @see query
     * @see code
     * @see placeholder
     * @see setId
     * @see setHash
     * @see setAction
     * @see setType
     * @see setData
     * @see setComplete
     * @see setModule
     * @see setComponent
     * @see setQuery
     * @see isMatch
     * @see markComplete
     */
    public setQuery( value: Promise<void> | null ): this {
        this.query = value;

        return this;
    }

    /**
     *
     * @param {ComponentEnum} type
     * @param {number} id
     * @return {boolean}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 07:08 pm
     * @see key
     * @see id
     * @see hash
     * @see action
     * @see type
     * @see data
     * @see complete
     * @see module
     * @see component
     * @see query
     * @see code
     * @see placeholder
     * @see setId
     * @see setHash
     * @see setAction
     * @see setType
     * @see setData
     * @see setComplete
     * @see setModule
     * @see setComponent
     * @see setQuery
     * @see isMatch
     * @see markComplete
     */
    public isMatch( type: ComponentEnum | null, id: number | null ): boolean {
        if( type && id ) {
            return type === this.type && id === this.id;
        }

        return false;
    }

    /**
     *
     * @return {this}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 07:08 pm
     * @see key
     * @see id
     * @see hash
     * @see action
     * @see type
     * @see data
     * @see complete
     * @see module
     * @see component
     * @see query
     * @see code
     * @see placeholder
     * @see setId
     * @see setHash
     * @see setAction
     * @see setType
     * @see setData
     * @see setComplete
     * @see setModule
     * @see setComponent
     * @see setQuery
     * @see isMatch
     * @see markComplete
     */
    public markComplete(): this {
        return this.setAction( ShortcodeActionEnum.Cache ).setComplete( true );
    }

    /**
     *
     * @return {string}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 07:08 pm
     * @see key
     * @see id
     * @see hash
     * @see action
     * @see type
     * @see data
     * @see complete
     * @see module
     * @see component
     * @see query
     * @see code
     * @see placeholder
     * @see setId
     * @see setHash
     * @see setAction
     * @see setType
     * @see setData
     * @see setComplete
     * @see setModule
     * @see setComponent
     * @see setQuery
     * @see isMatch
     * @see markComplete
     */
    public toString(): string {
        return this.placeholder;
    }

    /**
     *
     * @return {Record<string, unknown>}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 06/01/21 02:45 pm
     */
    public toComponentSpread(): Record<string, number | string> {
        return {
            'data-id'  : this.id ?? null,
            'data-type': this.type ?? null,
            'data-hash': this.hash ?? null,
            'data-code': this.code ?? null,
        };
    }

    /**
     *
     * @return {object}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 07:08 pm
     * @see key
     * @see id
     * @see hash
     * @see action
     * @see type
     * @see data
     * @see complete
     * @see module
     * @see component
     * @see query
     * @see code
     * @see placeholder
     * @see setId
     * @see setHash
     * @see setAction
     * @see setType
     * @see setData
     * @see setComplete
     * @see setModule
     * @see setComponent
     * @see setQuery
     * @see isMatch
     * @see markComplete
     */
    public toObject(): Record<string, unknown> {
        return {
            id       : this.id,
            hash     : this.hash,
            action   : this.action,
            type     : this.type,
            data     : this.data,
            complete : this.complete,
            module   : this.module,
            component: this.component,
            query    : this.query,
            code     : this.code,
        };
    }
}