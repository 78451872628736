import store from 'store2';
import {
     AboutEnum, AccountEnum, CompanyEnum,
     DomainEnum, PageEnum, PlatformEnum,
     ProfileEnum, SettingEnum, SetupEnum,
     UserEnum,
}            from '../enum';
import {
     IAbout, IAccount, ICompany, IDomain, IPage,
     IPlatform, IProfile, ISetting, ISetup, IUser,
}            from '../interface';

/**
 * @class StorageManager
 * @author Isaac Ewing
 * @version 1.0.0 02/22/21 08:36 pm
 */
export class StorageManager {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 12:20 pm
      */
     protected static readonly CONSOLE_PREFIX: string   = `${ process.env.REACT_APP_CONSOLE_PREFIX_MANAGER } STOR ${ process.env.REACT_APP_CONSOLE_SUFFIX_MANAGER }`;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/22/21 08:36 pm
      */
     protected static readonly CONSOLE_ENABLED: boolean = false;

     /**
      *
      * @return {object | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/23/21 06:35 pm
      * @see aboutModel
      * @see aboutId
      * @see aboutFirst
      * @see aboutLast
      */
     public static get aboutModel(): IAbout | null {
          return store.session.get( AboutEnum.Model ) ?? null;
     }

     /**
      *
      * @param {object} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/23/21 06:35 pm
      * @see aboutModel
      * @see aboutId
      * @see aboutFirst
      * @see aboutLast
      */
     public static set aboutModel( value: IAbout ) {
          store.session.set( AboutEnum.Model, value?.toObject() ?? null );
     }

     /**
      *
      * @return {number | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/23/21 06:35 pm
      * @see aboutId
      * @see aboutFirst
      * @see aboutLast
      */
     public static get aboutId(): number | null {
          return store.session.get( AboutEnum.Id ) ?? null;
     }

     /**
      *
      * @param {number} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/23/21 06:35 pm
      * @see aboutModel
      * @see aboutId
      * @see aboutFirst
      * @see aboutLast
      */
     public static set aboutId( value: number ) {
          store.session.set( AboutEnum.Id, value ?? null );
     }

     /**
      *
      * @return {string | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/23/21 06:35 pm
      * @see aboutModel
      * @see aboutId
      * @see aboutFirst
      * @see aboutLast
      */
     public static get aboutFirst(): string | null {
          return store.session.get( AboutEnum.First ) ?? null;
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/23/21 06:35 pm
      * @see aboutModel
      * @see aboutId
      * @see aboutFirst
      * @see aboutLast
      */
     public static set aboutFirst( value: string ) {
          store.session.set( AboutEnum.First, value ?? null );
     }

     /**
      *
      * @return {string | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/23/21 06:35 pm
      * @see aboutModel
      * @see aboutId
      * @see aboutFirst
      * @see aboutLast
      */
     public static get aboutLast(): string | null {
          return store.session.get( AboutEnum.Last ) ?? null;
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/23/21 06:35 pm
      * @see aboutModel
      * @see aboutId
      * @see aboutFirst
      * @see aboutLast
      */
     public static set aboutLast( value: string ) {
          store.session.set( AboutEnum.Last, value ?? null );
     }

     /**
      *
      * @return {IAccount | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 04:35 pm
      * @see accountModel
      * @see accountId
      * @see accountUser
      * @see accountPlatform
      * @see accountProfile
      */
     public static get accountModel(): IAccount | null {
          return store.session.get( AccountEnum.Model ) ?? null;
     }

     /**
      *
      * @param {IAccount} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 04:35 pm
      * @see accountModel
      * @see accountId
      * @see accountUser
      * @see accountPlatform
      * @see accountProfile
      */
     public static set accountModel( value: IAccount ) {
          store.session.set( AccountEnum.Model, value?.toObject() ?? null );
     }

     /**
      *
      * @return {number | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 04:48 pm
      * @see accountModel
      * @see accountId
      * @see accountUser
      * @see accountPlatform
      * @see accountProfile
      */
     public static get accountId(): number | null {
          return store.session.get( AccountEnum.Id ) ?? null;
     }

     /**
      *
      * @param {number} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 04:48 pm
      * @see accountModel
      * @see accountId
      * @see accountUser
      * @see accountPlatform
      * @see accountProfile
      */
     public static set accountId( value: number ) {
          store.session.set( AccountEnum.Id, value ?? null );
     }

     /**
      *
      * @return {IUser | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 04:48 pm
      * @see accountModel
      * @see accountId
      * @see accountUser
      * @see accountPlatform
      * @see accountProfile
      */
     public static get accountUser(): IUser | null {
          return store.session.get( AccountEnum.User ) ?? null;
     }

     /**
      *
      * @param {IUser} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 04:48 pm
      * @see accountModel
      * @see accountId
      * @see accountUser
      * @see accountPlatform
      * @see accountProfile
      */
     public static set accountUser( value: IUser ) {
          store.session.set( AccountEnum.User, value?.toObject() ?? null );
     }

     /**
      *
      * @return {IPlatform | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 04:48 pm
      * @see accountModel
      * @see accountId
      * @see accountUser
      * @see accountPlatform
      * @see accountProfile
      */
     public static get accountPlatform(): IPlatform | null {
          return store.session.get( AccountEnum.Platform ) ?? null;
     }

     /**
      *
      * @param {IPlatform} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 04:48 pm
      * @see accountModel
      * @see accountId
      * @see accountUser
      * @see accountPlatform
      * @see accountProfile
      */
     public static set accountPlatform( value: IPlatform ) {
          store.session.set( AccountEnum.Platform, value?.toObject() ?? null );
     }

     /**
      *
      * @return {IProfile | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 04:48 pm
      * @see accountModel
      * @see accountId
      * @see accountUser
      * @see accountPlatform
      * @see accountProfile
      */
     public static get accountProfile(): IProfile | null {
          return store.session.get( AccountEnum.Profile ) ?? null;
     }

     /**
      *
      * @param {IProfile} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 04:48 pm
      * @see accountModel
      * @see accountId
      * @see accountUser
      * @see accountPlatform
      * @see accountProfile
      */
     public static set accountProfile( value: IProfile ) {
          store.session.set( AccountEnum.Profile, value?.toObject() ?? null );
     }

     /**
      *
      * @return {ICompany | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 03:29 pm
      * @see companyModel
      * @see companyId
      * @see companySlug
      * @see companyText
      */
     public static get companyModel(): ICompany | null {
          return store.session.get( CompanyEnum.Model ) ?? null;
     }

     /**
      *
      * @param {ICompany} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 03:29 pm
      * @see companyModel
      * @see companyId
      * @see companySlug
      * @see companyText
      */
     public static set companyModel( value: ICompany ) {
          store.session.set( CompanyEnum.Model, value?.toObject() ?? null );
     }

     /**
      *
      * @return {number | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 03:29 pm
      * @see companyId
      * @see companySlug
      * @see companyText
      */
     public static get companyId(): number | null {
          return store.session.get( CompanyEnum.Id ) ?? null;
     }

     /**
      *
      * @param {number} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 03:29 pm
      * @see companyModel
      * @see companyId
      * @see companySlug
      * @see companyText
      */
     public static set companyId( value: number ) {
          store.session.set( CompanyEnum.Id, value ?? null );
     }

     /**
      *
      * @return {string | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 03:29 pm
      * @see companyModel
      * @see companyId
      * @see companySlug
      * @see companyText
      */
     public static get companySlug(): string | null {
          return store.session.get( CompanyEnum.Slug ) ?? null;
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 03:29 pm
      * @see companyModel
      * @see companyId
      * @see companySlug
      * @see companyText
      */
     public static set companySlug( value: string ) {
          store.session.set( CompanyEnum.Slug, value ?? null );
     }

     /**
      *
      * @return {string | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 03:29 pm
      * @see companyModel
      * @see companyId
      * @see companySlug
      * @see companyText
      */
     public static get companyText(): string | null {
          return store.session.get( CompanyEnum.Text ) ?? null;
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 03:29 pm
      * @see companyModel
      * @see companyId
      * @see companySlug
      * @see companyText
      */
     public static set companyText( value: string ) {
          store.session.set( CompanyEnum.Text, value ?? null );
     }

     /**
      *
      * @return {number | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/26/21 04:51 pm
      * @see domainModel
      * @see domainId
      * @see domainPath
      * @see domainAlias
      * @see domainPrefix
      * @see domainSuffix
      * @see domainText
      * @see domainClaimed
      */
     public static get domainModel(): IDomain | null {
          return store.local.get( DomainEnum.Model ) ?? null;
     }

     /**
      *
      * @param {number} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/26/21 04:51 pm
      * @see domainModel
      * @see domainId
      * @see domainPath
      * @see domainAlias
      * @see domainPrefix
      * @see domainSuffix
      * @see domainText
      * @see domainClaimed
      */
     public static set domainModel( value: IDomain ) {
          store.local.set( DomainEnum.Model, value.toObject() ?? null );
     }

     /**
      *
      * @return {number | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 03:31 pm
      * @see domainModel
      * @see domainId
      * @see domainPath
      * @see domainAlias
      * @see domainPrefix
      * @see domainSuffix
      * @see domainText
      * @see domainClaimed
      */
     public static get domainId(): number | null {
          return store.local.get( DomainEnum.Id ) ?? null;
     }

     /**
      *
      * @param {number} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 03:31 pm
      * @see domainModel
      * @see domainId
      * @see domainPath
      * @see domainAlias
      * @see domainPrefix
      * @see domainSuffix
      * @see domainText
      * @see domainClaimed
      */
     public static set domainId( value: number ) {
          store.local.set( DomainEnum.Id, value ?? null );
     }

     /**
      *
      * @return {string | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 03:31 pm
      * @see domainModel
      * @see domainId
      * @see domainPath
      * @see domainAlias
      * @see domainPrefix
      * @see domainSuffix
      * @see domainText
      * @see domainClaimed
      */
     public static get domainPath(): string | null {
          return store.local.get( DomainEnum.Path ) ?? null;
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 03:31 pm
      * @see domainModel
      * @see domainId
      * @see domainPath
      * @see domainAlias
      * @see domainPrefix
      * @see domainSuffix
      * @see domainText
      * @see domainClaimed
      */
     public static set domainPath( value: string ) {
          store.local.set( DomainEnum.Path, value ?? null );
     }

     /**
      *
      * @return {string | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 03:31 pm
      * @see domainModel
      * @see domainId
      * @see domainPath
      * @see domainAlias
      * @see domainPrefix
      * @see domainSuffix
      * @see domainText
      * @see domainClaimed
      */
     public static get domainAlias(): string | null {
          return store.local.get( DomainEnum.Alias ) ?? null;
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 03:31 pm
      * @see domainModel
      * @see domainId
      * @see domainPath
      * @see domainAlias
      * @see domainPrefix
      * @see domainSuffix
      * @see domainText
      * @see domainClaimed
      */
     public static set domainAlias( value: string ) {
          store.local.set( DomainEnum.Alias, value ?? null );
     }

     /**
      *
      * @return {string | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 03:31 pm
      * @see domainModel
      * @see domainId
      * @see domainPath
      * @see domainAlias
      * @see domainPrefix
      * @see domainSuffix
      * @see domainText
      * @see domainClaimed
      */
     public static get domainPrefix(): string | null {
          return store.local.get( DomainEnum.Prefix ) ?? null;
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 03:31 pm
      * @see domainModel
      * @see domainId
      * @see domainPath
      * @see domainAlias
      * @see domainPrefix
      * @see domainSuffix
      * @see domainText
      * @see domainClaimed
      */
     public static set domainPrefix( value: string ) {
          store.local.set( DomainEnum.Prefix, value ?? null );
     }

     /**
      *
      * @return {string | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 03:31 pm
      * @see domainModel
      * @see domainId
      * @see domainPath
      * @see domainAlias
      * @see domainPrefix
      * @see domainSuffix
      * @see domainText
      * @see domainClaimed
      */
     public static get domainSuffix(): string | null {
          return store.local.get( DomainEnum.Suffix ) ?? null;
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 03:31 pm
      * @see domainModel
      * @see domainId
      * @see domainPath
      * @see domainAlias
      * @see domainPrefix
      * @see domainSuffix
      * @see domainText
      * @see domainClaimed
      */
     public static set domainSuffix( value: string ) {
          store.local.set( DomainEnum.Suffix, value ?? null );
     }

     /**
      *
      * @return {string | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 03:31 pm
      * @see domainModel
      * @see domainId
      * @see domainPath
      * @see domainAlias
      * @see domainPrefix
      * @see domainSuffix
      * @see domainText
      * @see domainClaimed
      */
     public static get domainText(): string | null {
          return store.local.get( DomainEnum.Text ) ?? null;
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 03:31 pm
      * @see domainModel
      * @see domainId
      * @see domainPath
      * @see domainAlias
      * @see domainPrefix
      * @see domainSuffix
      * @see domainText
      * @see domainClaimed
      */
     public static set domainText( value: string ) {
          store.local.set( DomainEnum.Text, value ?? null );
     }

     /**
      *
      * @return {boolean | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 03:31 pm
      * @see domainModel
      * @see domainId
      * @see domainPath
      * @see domainAlias
      * @see domainPrefix
      * @see domainSuffix
      * @see domainText
      * @see domainClaimed
      */
     public static get domainClaimed(): boolean | null {
          return store.local.get( DomainEnum.Claimed ) ?? null;
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 03:31 pm
      * @see domainModel
      * @see domainId
      * @see domainPath
      * @see domainAlias
      * @see domainPrefix
      * @see domainSuffix
      * @see domainText
      * @see domainClaimed
      */
     public static set domainClaimed( value: boolean ) {
          store.local.set( DomainEnum.Claimed, value ?? null );
     }

     /**
      *
      * @return {number | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/26/21 04:55 pm
      * @see pageModel
      * @see pageId
      * @see pagePath
      * @see pageAlias
      * @see pagePrefix
      * @see pageSuffix
      * @see pageText
      */
     public static get pageModel(): IPage | null {
          return store.session.get( PageEnum.Model ) ?? null;
     }

     /**
      *
      * @param {number} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/26/21 04:55 pm
      * @see pageModel
      * @see pageId
      * @see pagePath
      * @see pageAlias
      * @see pagePrefix
      * @see pageSuffix
      * @see pageText
      */
     public static set pageModel( value: IPage ) {
          store.session.set( PageEnum.Model, value.toObject() ?? null );
     }

     /**
      *
      * @return {number | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 04:36 pm
      * @see pageModel
      * @see pageId
      * @see pagePath
      * @see pageAlias
      * @see pagePrefix
      * @see pageSuffix
      * @see pageText
      */
     public static get pageId(): number | null {
          return store.session.get( PageEnum.Id ) ?? null;
     }

     /**
      *
      * @param {number} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 04:36 pm
      * @see pageModel
      * @see pageId
      * @see pagePath
      * @see pageAlias
      * @see pagePrefix
      * @see pageSuffix
      * @see pageText
      */
     public static set pageId( value: number ) {
          store.session.set( PageEnum.Id, value ?? null );
     }

     /**
      *
      * @return {string | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 04:36 pm
      * @see pageModel
      * @see pageId
      * @see pagePath
      * @see pageAlias
      * @see pagePrefix
      * @see pageSuffix
      * @see pageText
      */
     public static get pagePath(): string | null {
          return store.session.get( PageEnum.Path ) ?? null;
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 04:36 pm
      * @see pageModel
      * @see pageId
      * @see pagePath
      * @see pageAlias
      * @see pagePrefix
      * @see pageSuffix
      * @see pageText
      */
     public static set pagePath( value: string ) {
          store.session.set( PageEnum.Path, value ?? null );
     }

     /**
      *
      * @return {string | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 04:36 pm
      * @see pageModel
      * @see pageId
      * @see pagePath
      * @see pageAlias
      * @see pagePrefix
      * @see pageSuffix
      * @see pageText
      */
     public static get pageAlias(): string | null {
          return store.session.get( PageEnum.Alias ) ?? null;
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 04:36 pm
      * @see pageModel
      * @see pageId
      * @see pagePath
      * @see pageAlias
      * @see pagePrefix
      * @see pageSuffix
      * @see pageText
      */
     public static set pageAlias( value: string ) {
          store.session.set( PageEnum.Alias, value ?? null );
     }

     /**
      *
      * @return {string | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 04:36 pm
      * @see pageModel
      * @see pageId
      * @see pagePath
      * @see pageAlias
      * @see pagePrefix
      * @see pageSuffix
      * @see pageText
      */
     public static get pagePrefix(): string | null {
          return store.session.get( PageEnum.Prefix ) ?? null;
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 04:36 pm
      * @see pageModel
      * @see pageId
      * @see pagePath
      * @see pageAlias
      * @see pagePrefix
      * @see pageSuffix
      * @see pageText
      */
     public static set pagePrefix( value: string ) {
          store.session.set( PageEnum.Prefix, value ?? null );
     }

     /**
      *
      * @return {string | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 04:36 pm
      * @see pageModel
      * @see pageId
      * @see pagePath
      * @see pageAlias
      * @see pagePrefix
      * @see pageSuffix
      * @see pageText
      */
     public static get pageSuffix(): string | null {
          return store.session.get( PageEnum.Suffix ) ?? null;
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 04:36 pm
      * @see pageModel
      * @see pageId
      * @see pagePath
      * @see pageAlias
      * @see pagePrefix
      * @see pageSuffix
      * @see pageText
      */
     public static set pageSuffix( value: string ) {
          store.session.set( PageEnum.Suffix, value ?? null );
     }

     /**
      *
      * @return {string | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 04:36 pm
      * @see pageModel
      * @see pageId
      * @see pagePath
      * @see pageAlias
      * @see pagePrefix
      * @see pageSuffix
      * @see pageText
      */
     public static get pageText(): string | null {
          return store.session.get( PageEnum.Text ) ?? null;
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 04:36 pm
      * @see pageModel
      * @see pageId
      * @see pagePath
      * @see pageAlias
      * @see pagePrefix
      * @see pageSuffix
      * @see pageText
      */
     public static set pageText( value: string ) {
          store.session.set( PageEnum.Text, value ?? null );
     }

     /**
      *
      * @return {number | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static get platformId(): number | null {
          return store.session.get( PlatformEnum.Id ) ?? null;
     }

     /**
      *
      * @param {number} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static set platformId( value: number ) {
          store.session.set( PlatformEnum.Id, value ?? null );
     }

     /**
      *
      * @return {string | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static get platformName(): string | null {
          return store.session.get( PlatformEnum.Name ) ?? null;
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static set platformName( value: string ) {
          store.session.set( PlatformEnum.Name, value ?? null );
     }

     /**
      *
      * @return {string | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static get platformAlias(): string | null {
          return store.session.get( PlatformEnum.Alias ) ?? null;
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static set platformAlias( value: string ) {
          store.session.set( PlatformEnum.Alias, value ?? null );
     }

     /**
      *
      * @return {string | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static get platformText(): string | null {
          return store.session.get( PlatformEnum.Text ) ?? null;
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static set platformText( value: string ) {
          store.session.set( PlatformEnum.Text, value ?? null );
     }

     /**
      *
      * @return {string | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static get platformURL(): string | null {
          return store.session.get( PlatformEnum.URL ) ?? null;
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static set platformURL( value: string ) {
          store.session.set( PlatformEnum.URL, value ?? null );
     }

     /**
      *
      * @return {boolean | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static get platformEnabled(): boolean | null {
          return store.session.get( PlatformEnum.Enabled ) ?? null;
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static set platformEnabled( value: boolean ) {
          store.session.set( PlatformEnum.Enabled, value ?? null );
     }

     /**
      *
      * @return {boolean | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static get platformStandard(): boolean | null {
          return store.session.get( PlatformEnum.Standard ) ?? null;
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static set platformStandard( value: boolean ) {
          store.session.set( PlatformEnum.Standard, value ?? null );
     }

     /**
      *
      * @return {unknown | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static get platformOptions(): unknown | null {
          return store.session.get( PlatformEnum.Options ) ?? null;
     }

     /**
      *
      * @param {unknown} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static set platformOptions( value: unknown ) {
          store.session.set( PlatformEnum.Options, value ?? null );
     }

     /**
      *
      * @return {number | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/26/21 04:55 pm
      * @see pageModel
      * @see pageId
      * @see pagePath
      * @see pageAlias
      * @see pagePrefix
      * @see pageSuffix
      * @see pageText
      */
     public static get profileModel(): IProfile | null {
          return store.session.get( ProfileEnum.Model ) ?? null;
     }

     /**
      *
      * @param {number} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/26/21 04:55 pm
      * @see pageModel
      * @see pageId
      * @see pagePath
      * @see pageAlias
      * @see pagePrefix
      * @see pageSuffix
      * @see pageText
      */
     public static set profileModel( value: IProfile ) {
          store.session.set( ProfileEnum.Model, value.toObject() ?? null );
     }

     /**
      *
      * @return {number | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static get profileId(): number | null {
          return store.session.get( ProfileEnum.Id ) ?? null;
     }

     /**
      *
      * @param {number} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static set profileId( value: number ) {
          store.session.set( ProfileEnum.Id, value ?? null );
     }

     /**
      *
      * @return {string | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static get profileIdentity(): string | null {
          return store.session.get( ProfileEnum.Identity ) ?? null;
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static set profileIdentity( value: string ) {
          store.session.set( ProfileEnum.Identity, value ?? null );
     }

     /**
      *
      * @return {string | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static get profileUsername(): string | null {
          return store.session.get( ProfileEnum.Username ) ?? null;
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static set profileUsername( value: string ) {
          store.session.set( ProfileEnum.Username, value ?? null );
     }

     /**
      *
      * @return {string | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static get profileEmail(): string | null {
          return store.session.get( ProfileEnum.Email ) ?? null;
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static set profileEmail( value: string ) {
          store.session.set( ProfileEnum.Email, value ?? null );
     }

     /**
      *
      * @return {Partial<IAbout> | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static get profileName(): Partial<IAbout> | null {
          return {
               id   : store.session.get( `${ ProfileEnum.Name }.${ AboutEnum.Id }` ) ?? null,
               first: store.session.get( `${ ProfileEnum.Name }.${ AboutEnum.First }` ) ?? null,
               last : store.session.get( `${ ProfileEnum.Name }.${ AboutEnum.Last }` ) ?? null,
          };
     }

     /**
      *
      * @param {Partial<IAbout>} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static set profileName( value: Partial<IAbout> ) {
          store.session.set( `${ ProfileEnum.Name }.${ AboutEnum.Id }`, value.id ?? null );
          store.session.set( `${ ProfileEnum.Name }.${ AboutEnum.First }`, value.first ?? null );
          store.session.set( `${ ProfileEnum.Name }.${ AboutEnum.Last }`, value.last ?? null );
     }

     /**
      *
      * @return {boolean | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static get profileLocation(): string | null {
          return store.session.get( ProfileEnum.Location ) ?? null;
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static set profileLocation( value: string ) {
          store.session.set( ProfileEnum.Location, value ?? null );
     }

     /**
      *
      * @return {boolean | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static get profileImage(): string | null {
          return store.session.get( ProfileEnum.Image ) ?? null;
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static set profileImage( value: string ) {
          store.session.set( ProfileEnum.Image, value ?? null );
     }

     /**
      *
      * @return {boolean | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static get profileURL(): string | null {
          return store.session.get( ProfileEnum.URL ) ?? null;
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static set profileURL( value: string ) {
          store.session.set( ProfileEnum.URL, value ?? null );
     }

     /**
      *
      * @return {boolean | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static get profileFollowers(): number | null {
          return store.session.get( ProfileEnum.Followers ) ?? null;
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static set profileFollowers( value: number ) {
          store.session.set( ProfileEnum.Followers, value ?? null );
     }

     /**
      *
      * @return {boolean | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static get profileVerified(): boolean | null {
          return store.session.get( ProfileEnum.Verified ) ?? null;
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static set profileVerified( value: boolean ) {
          store.session.set( ProfileEnum.Verified, value ?? null );
     }

     /**
      *
      * @return {boolean | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static get profileStarted(): number | null {
          return store.session.get( ProfileEnum.Started ) ?? null;
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see platformId
      * @see platformName
      * @see platformAlias
      * @see platformText
      * @see platformURL
      * @see platformEnabled
      * @see platformStandard
      * @see platformOptions
      */
     public static set profileStarted( value: number ) {
          store.session.set( ProfileEnum.Started, value ?? null );
     }

     /**
      *
      * @return {ISetting | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:51 pm
      * @see settingModel
      * @see settingId
      * @see settingPronouns
      */
     public static get settingModel(): Partial<ISetting> | null {
          return store.session.get( SettingEnum.Model ) ?? null;
     }

     /**
      *
      * @param {ISetting} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:51 pm
      * @see settingModel
      * @see settingId
      * @see settingPronouns
      */
     public static set settingModel( value: Partial<ISetting> ) {
          store.session.set( SettingEnum.Model, value?.toObject() ?? null );
     }

     /**
      *
      * @return {number | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:51 pm
      * @see settingModel
      * @see settingId
      * @see settingPronouns
      */
     public static get settingId(): number | null {
          return store.session.get( SettingEnum.Id ) ?? null;
     }

     /**
      *
      * @param {number} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:51 pm
      * @see settingModel
      * @see settingId
      * @see settingPronouns
      */
     public static set settingId( value: number ) {
          store.session.set( SettingEnum.Id, value ?? null );
     }

     /**
      *
      * @return {string | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:51 pm
      * @see settingModel
      * @see settingId
      * @see settingPronouns
      */
     public static get settingPronouns(): string | null {
          return store.session.get( SettingEnum.Pronouns ) ?? null;
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:51 pm
      * @see settingModel
      * @see settingId
      * @see settingPronouns
      */
     public static set settingPronouns( value: string ) {
          store.session.set( SettingEnum.Pronouns, value ?? null );
     }

     /**
      *
      * @return {number | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 02:03 pm
      * @see setupModel
      * @see setupId
      * @see setupClaim
      * @see setupLogin
      * @see setupIntro
      * @see setupDomain
      */
     public static get setupModel(): Partial<ISetup> | null {
          return store.session.get( SetupEnum.Model ) ?? null;
     }

     /**
      *
      * @param {number} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 03:31 pm
      * @see setupModel
      * @see setupId
      * @see setupClaim
      * @see setupLogin
      * @see setupIntro
      * @see setupDomain
      */
     public static set setupModel( value: Partial<ISetup> ) {
          store.session.set( SetupEnum.Model, value?.toObject() ?? null );
     }

     /**
      *
      * @return {number | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 02:03 pm
      * @see setupModel
      * @see setupId
      * @see setupClaim
      * @see setupLogin
      * @see setupIntro
      * @see setupDomain
      */
     public static get setupId(): number | null {
          return store.session.get( SetupEnum.Id ) ?? null;
     }

     /**
      *
      * @param {number} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 03:31 pm
      * @see setupModel
      * @see setupId
      * @see setupClaim
      * @see setupLogin
      * @see setupIntro
      * @see setupDomain
      */
     public static set setupId( value: number ) {
          store.session.set( SetupEnum.Id, value ?? null );
     }

     /**
      *
      * @return {boolean | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 02:03 pm
      * @see setupModel
      * @see setupId
      * @see setupClaim
      * @see setupLogin
      * @see setupIntro
      * @see setupDomain
      */
     public static get setupLogin(): boolean | null {
          return store.session.get( SetupEnum.Login ) ?? null;
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 02:03 pm
      * @see setupModel
      * @see setupId
      * @see setupClaim
      * @see setupLogin
      * @see setupIntro
      * @see setupDomain
      */
     public static set setupLogin( value: boolean ) {
          store.session.set( SetupEnum.Login, value ?? null );
     }

     /**
      *
      * @return {boolean | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 02:03 pm
      * @see setupModel
      * @see setupId
      * @see setupClaim
      * @see setupLogin
      * @see setupIntro
      * @see setupDomain
      */
     public static get setupIntro(): boolean | null {
          return store.session.get( SetupEnum.Intro ) ?? null;
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 02:03 pm
      * @see setupModel
      * @see setupId
      * @see setupClaim
      * @see setupLogin
      * @see setupIntro
      * @see setupDomain
      */
     public static set setupIntro( value: boolean ) {
          store.session.set( SetupEnum.Intro, value ?? null );
     }

     /**
      *
      * @return {IDomain | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 02:03 pm
      * @see setupModel
      * @see setupId
      * @see setupClaim
      * @see setupLogin
      * @see setupIntro
      * @see setupDomain
      */
     public static get setupDomain(): IDomain | null {
          return store.session.get( SetupEnum.Domain ) ?? null;
     }

     /**
      *
      * @param {IDomain} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 02:03 pm
      * @see setupModel
      * @see setupId
      * @see setupClaim
      * @see setupLogin
      * @see setupIntro
      * @see setupDomain
      */
     public static set setupDomain( value: IDomain ) {
          store.session.set( SetupEnum.Domain, value?.toObject() ?? null );
     }

     /**
      *
      * @return {IUser | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 06:11 pm
      * @see userModel
      * @see userId
      * @see userUsername
      * @see userEmail
      * @see userToken
      * @see userDestroy
      */
     public static get userModel(): Partial<IUser> | null {
          return store.session.get( UserEnum.Model ) ?? null;
     }

     /**
      *
      * @param {IUser} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 06:11 pm
      * @see userModel
      * @see userId
      * @see userUsername
      * @see userEmail
      * @see userToken
      * @see userDestroy
      */
     public static set userModel( value: Partial<IUser> ) {
          store.session.set( UserEnum.Model, value?.toObject() ?? null );
     }

     /**
      *
      * @return {number | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 06:11 pm
      * @see userId
      * @see userUsername
      * @see userEmail
      * @see userToken
      * @see userDestroy
      */
     public static get userId(): number | null {
          return store.session.get( UserEnum.Id ) ?? null;
     }

     /**
      *
      * @param {number} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 06:11 pm
      * @see userId
      * @see userUsername
      * @see userEmail
      * @see userToken
      * @see userDestroy
      */
     public static set userId( value: number ) {
          store.session.set( UserEnum.Id, value ?? null );
     }

     /**
      *
      * @return {string | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 06:11 pm
      * @see userId
      * @see userUsername
      * @see userEmail
      * @see userToken
      * @see userDestroy
      */
     public static get userUsername(): string | null {
          return store.session.get( UserEnum.Username ) ?? null;
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 06:11 pm
      * @see userId
      * @see userUsername
      * @see userEmail
      * @see userToken
      * @see userDestroy
      */
     public static set userUsername( value: string ) {
          store.session.set( UserEnum.Username, value ?? null );
     }

     /**
      *
      * @return {string | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 06:11 pm
      * @see userId
      * @see userUsername
      * @see userEmail
      * @see userAbout
      * @see userAccounts
      * @see userDomain
      * @see userSetup
      * @see userSetting
      * @see userCompany
      * @see userToken
      * @see userDestroy
      */
     public static get userEmail(): string | null {
          return store.session.get( UserEnum.Email ) ?? null;
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 06:11 pm
      * @see userId
      * @see userUsername
      * @see userEmail
      * @see userAbout
      * @see userAccounts
      * @see userDomain
      * @see userSetup
      * @see userSetting
      * @see userCompany
      * @see userToken
      * @see userDestroy
      */
     public static set userEmail( value: string ) {
          store.session.set( UserEnum.Email, value ?? null );
     }

     /**
      *
      * @return {Partial<IAbout> | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see userId
      * @see userUsername
      * @see userEmail
      * @see userAbout
      * @see userAccounts
      * @see userDomain
      * @see userSetup
      * @see userSetting
      * @see userCompany
      * @see userToken
      * @see userDestroy
      */
     public static get userAbout(): Partial<IAbout> | null {
          return {
               id   : store.session.get( `${ UserEnum.About }.${ AboutEnum.Id }` ) ?? null,
               first: store.session.get( `${ UserEnum.About }.${ AboutEnum.First }` ) ?? null,
               last : store.session.get( `${ UserEnum.About }.${ AboutEnum.Last }` ) ?? null,
          };
     }

     /**
      *
      * @param {Partial<IAbout>} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see userId
      * @see userUsername
      * @see userEmail
      * @see userAbout
      * @see userAccounts
      * @see userDomain
      * @see userSetup
      * @see userSetting
      * @see userCompany
      * @see userToken
      * @see userDestroy
      */
     public static set userAbout( value: Partial<IAbout> ) {
          store.session.set( `${ UserEnum.About }.${ AboutEnum.Id }`, value.id ?? null );
          store.session.set( `${ UserEnum.About }.${ AboutEnum.First }`, value.first ?? null );
          store.session.set( `${ UserEnum.About }.${ AboutEnum.Last }`, value.last ?? null );
     }

     /**
      *
      * @return {Set<Partial<IAccount>> | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/28/21 04:31 pm
      * @see userId
      * @see userUsername
      * @see userEmail
      * @see userAbout
      * @see userAccounts
      * @see userDomain
      * @see userSetup
      * @see userSetting
      * @see userCompany
      * @see userToken
      * @see userDestroy
      */
     public static get userAccounts(): Set<Partial<IAccount>> | null {
          return new Set<Partial<IAccount>>( store.session.get( `${ UserEnum.Accounts }.${ AccountEnum.Model }` ) ?? null );
     }

     /**
      *
      * @param {Set<Partial<IAccount>} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/28/21 04:31 pm
      * @see userId
      * @see userUsername
      * @see userEmail
      * @see userAbout
      * @see userAccounts
      * @see userDomain
      * @see userSetup
      * @see userSetting
      * @see userCompany
      * @see userToken
      * @see userDestroy
      */
     public static set userAccounts( value: Set<Partial<IAccount>> ) {
          const accounts: Record<string, unknown>[] = [];

          value.forEach( ( account: Partial<IAccount> ): void => { accounts.push( account.toObject( true ) ); } );
          store.session.set( `${ UserEnum.Accounts }.${ AccountEnum.Model }`, accounts ?? null );
     }

     /**
      *
      * @return {Partial<IDomain> | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see userId
      * @see userUsername
      * @see userEmail
      * @see userAbout
      * @see userAccounts
      * @see userDomain
      * @see userSetup
      * @see userSetting
      * @see userCompany
      * @see userToken
      * @see userDestroy
      */
     public static get userDomain(): Partial<IDomain> | null {
          return {
               id     : store.session.get( `${ UserEnum.About }.${ DomainEnum.Id }` ) ?? null,
               path   : store.session.get( `${ UserEnum.About }.${ DomainEnum.Path }` ) ?? null,
               alias  : store.session.get( `${ UserEnum.About }.${ DomainEnum.Alias }` ) ?? null,
               prefix : store.session.get( `${ UserEnum.About }.${ DomainEnum.Prefix }` ) ?? null,
               suffix : store.session.get( `${ UserEnum.About }.${ DomainEnum.Suffix }` ) ?? null,
               text   : store.session.get( `${ UserEnum.About }.${ DomainEnum.Text }` ) ?? null,
               claimed: store.session.get( `${ UserEnum.About }.${ DomainEnum.Claimed }` ) ?? null,
          };
     }

     /**
      *
      * @param {Partial<IDomain>} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see userId
      * @see userUsername
      * @see userEmail
      * @see userAbout
      * @see userAccounts
      * @see userDomain
      * @see userSetup
      * @see userSetting
      * @see userCompany
      * @see userToken
      * @see userDestroy
      */
     public static set userDomain( value: Partial<IDomain> ) {
          store.session.set( `${ UserEnum.About }.${ DomainEnum.Id }`, value.id ?? null );
          store.session.set( `${ UserEnum.About }.${ DomainEnum.Path }`, value.path ?? null );
          store.session.set( `${ UserEnum.About }.${ DomainEnum.Alias }`, value.alias ?? null );
          store.session.set( `${ UserEnum.About }.${ DomainEnum.Prefix }`, value.prefix ?? null );
          store.session.set( `${ UserEnum.About }.${ DomainEnum.Suffix }`, value.suffix ?? null );
          store.session.set( `${ UserEnum.About }.${ DomainEnum.Text }`, value.text ?? null );
          store.session.set( `${ UserEnum.About }.${ DomainEnum.Claimed }`, value.claimed ?? null );
     }

     /**
      *
      * @return {Partial<ISetup> | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see userId
      * @see userUsername
      * @see userEmail
      * @see userAbout
      * @see userAccounts
      * @see userDomain
      * @see userSetup
      * @see userSetting
      * @see userCompany
      * @see userToken
      * @see userDestroy
      */
     public static get userSetup(): Partial<ISetup> | null {
          return {
               id    : store.session.get( `${ UserEnum.Setup }.${ SetupEnum.Id }` ) ?? null,
               login : store.session.get( `${ UserEnum.Setup }.${ SetupEnum.Login }` ) ?? null,
               intro : store.session.get( `${ UserEnum.Setup }.${ SetupEnum.Intro }` ) ?? null,
               domain: {
                    id     : store.session.get( `${ UserEnum.Setup }.${ SetupEnum.Domain }.${ DomainEnum.Id }` ) ?? null,
                    path   : store.session.get( `${ UserEnum.Setup }.${ SetupEnum.Domain }.${ DomainEnum.Path }` ) ?? null,
                    alias  : store.session.get( `${ UserEnum.Setup }.${ SetupEnum.Domain }.${ DomainEnum.Alias }` ) ?? null,
                    prefix : store.session.get( `${ UserEnum.Setup }.${ SetupEnum.Domain }.${ DomainEnum.Prefix }` ) ?? null,
                    suffix : store.session.get( `${ UserEnum.Setup }.${ SetupEnum.Domain }.${ DomainEnum.Suffix }` ) ?? null,
                    text   : store.session.get( `${ UserEnum.Setup }.${ SetupEnum.Domain }.${ DomainEnum.Text }` ) ?? null,
                    claimed: store.session.get( `${ UserEnum.Setup }.${ SetupEnum.Domain }.${ DomainEnum.Claimed }` ) ?? null,
               },
          };
     }

     /**
      *
      * @param {Partial<ISetup>} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see userId
      * @see userUsername
      * @see userEmail
      * @see userAbout
      * @see userAccounts
      * @see userDomain
      * @see userSetup
      * @see userSetting
      * @see userCompany
      * @see userToken
      * @see userDestroy
      */
     public static set userSetup( value: Partial<ISetup> ) {
          store.session.set( `${ UserEnum.Setup }.${ SetupEnum.Id }`, value.id ?? null );
          store.session.set( `${ UserEnum.Setup }.${ SetupEnum.Login }`, value.login ?? null );
          store.session.set( `${ UserEnum.Setup }.${ SetupEnum.Intro }`, value.intro ?? null );
          store.session.set( `${ UserEnum.Setup }.${ SetupEnum.Domain }.${ DomainEnum.Id }`, value.domain.id ?? null );
          store.session.set( `${ UserEnum.Setup }.${ SetupEnum.Domain }.${ DomainEnum.Path }`, value.domain.path ?? null );
          store.session.set( `${ UserEnum.Setup }.${ SetupEnum.Domain }.${ DomainEnum.Alias }`, value.domain.alias ?? null );
          store.session.set( `${ UserEnum.Setup }.${ SetupEnum.Domain }.${ DomainEnum.Prefix }`, value.domain.prefix ?? null );
          store.session.set( `${ UserEnum.Setup }.${ SetupEnum.Domain }.${ DomainEnum.Suffix }`, value.domain.suffix ?? null );
          store.session.set( `${ UserEnum.Setup }.${ SetupEnum.Domain }.${ DomainEnum.Text }`, value.domain.text ?? null );
          store.session.set( `${ UserEnum.Setup }.${ SetupEnum.Domain }.${ DomainEnum.Claimed }`, value.domain.claimed ?? null );
     }

     /**
      *
      * @return {Partial<IDomain> | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see userId
      * @see userUsername
      * @see userEmail
      * @see userAbout
      * @see userAccounts
      * @see userDomain
      * @see userSetup
      * @see userSetting
      * @see userCompany
      * @see userToken
      * @see userDestroy
      */
     public static get userSetting(): Partial<ISetting> | null {
          return {
               id      : store.session.get( `${ UserEnum.Setting }.${ SettingEnum.Id }` ) ?? null,
               pronouns: store.session.get( `${ UserEnum.Setting }.${ SettingEnum.Pronouns }` ) ?? null,
          };
     }

     /**
      *
      * @param {Partial<IDomain>} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:27 pm
      * @see userId
      * @see userUsername
      * @see userEmail
      * @see userAbout
      * @see userAccounts
      * @see userDomain
      * @see userSetup
      * @see userSetting
      * @see userCompany
      * @see userToken
      * @see userDestroy
      */
     public static set userSetting( value: Partial<ISetting> ) {
          store.session.set( `${ UserEnum.Setting }.${ SettingEnum.Id }`, value.id ?? null );
          store.session.set( `${ UserEnum.Setting }.${ SettingEnum.Pronouns }`, value.pronouns ?? null );
     }

     /**
      *
      * @return {Partial<ICompany> | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/28/21 01:34 pm
      * @see userId
      * @see userUsername
      * @see userEmail
      * @see userAbout
      * @see userAccounts
      * @see userDomain
      * @see userSetup
      * @see userSetting
      * @see userCompany
      * @see userToken
      * @see userDestroy
      */
     public static get userCompany(): Partial<ICompany> | null {
          return {
               id  : store.session.get( `${ UserEnum.Company }.${ CompanyEnum.Id }` ) ?? null,
               slug: store.session.get( `${ UserEnum.Company }.${ CompanyEnum.Slug }` ) ?? null,
               text: store.session.get( `${ UserEnum.Company }.${ CompanyEnum.Text }` ) ?? null,
          };
     }

     /**
      *
      * @param {Partial<ICompany>} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/28/21 01:34 pm
      * @see userId
      * @see userUsername
      * @see userEmail
      * @see userAbout
      * @see userAccounts
      * @see userDomain
      * @see userSetup
      * @see userSetting
      * @see userCompany
      * @see userToken
      * @see userDestroy
      */
     public static set userCompany( value: Partial<ICompany> ) {
          store.session.set( `${ UserEnum.Company }.${ CompanyEnum.Id }`, value.id ?? null );
          store.session.set( `${ UserEnum.Company }.${ CompanyEnum.Slug }`, value.slug ?? null );
          store.session.set( `${ UserEnum.Company }.${ CompanyEnum.Text }`, value.text ?? null );
     }

     /**
      *
      * @return {string | null}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 06:11 pm
      * @see userId
      * @see userUsername
      * @see userEmail
      * @see userAbout
      * @see userAccounts
      * @see userDomain
      * @see userSetup
      * @see userSetting
      * @see userCompany
      * @see userToken
      * @see userDestroy
      */
     public static get userToken(): string | null {
          return store.session.get( UserEnum.Token ) ?? null;
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 06:11 pm
      * @see userId
      * @see userUsername
      * @see userEmail
      * @see userAbout
      * @see userAccounts
      * @see userDomain
      * @see userSetup
      * @see userSetting
      * @see userCompany
      * @see userToken
      * @see userDestroy
      */
     public static set userToken( value: string ) {
          store.session.set( UserEnum.Token, value ?? null );
     }

     /**
      *
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 06:11 pm
      * @see userId
      * @see userUsername
      * @see userEmail
      * @see userAbout
      * @see userAccounts
      * @see userDomain
      * @see userSetup
      * @see userSetting
      * @see userCompany
      * @see userToken
      * @see userDestroy
      */
     public static userDestroy(): void {
          store.session.set( UserEnum.Token, null );
     }
}