/**
 * @enum AboutEnum
 * @author Isaac Ewing
 * @version 1.0.0 05/23/21 06:33 pm
 */
export enum AboutEnum {
     Model = 'about.model',
     Id    = 'about.id',
     First = 'about.first',
     Last  = 'about.last',
}

/**
 * @enum AccountEnum
 * @author Isaac Ewing
 * @version 1.0.0 05/24/21 04:30 pm
 */
export enum AccountEnum {
     Model    = 'account.model',
     Id       = 'account.id',
     User     = 'account.user',
     Platform = 'account.platform',
     Profile  = 'account.profile',
}

/**
 * @enum CompanyEnum
 * @author Isaac Ewing
 * @version 1.0.0 05/25/21 03:25 pm
 */
export enum CompanyEnum {
     Model = 'company.model',
     Id    = 'company.id',
     Slug  = 'company.slug',
     Text  = 'company.text',
}

/**
 * @enum DomainEnum
 * @author Isaac Ewing
 * @version 1.0.0 05/08/21 03:26 pm
 */
export enum DomainEnum {
     Model   = 'domain.model',
     Id      = 'domain.id',
     Path    = 'domain.path',
     Alias   = 'domain.alias',
     Prefix  = 'domain.prefix',
     Suffix  = 'domain.suffix',
     Text    = 'domain.text',
     Claimed = 'domain.claimed',
}

/**
 * @enum PageEnum
 * @author Isaac Ewing
 * @version 1.0.0 05/08/21 03:58 pm
 */
export enum PageEnum {
     Model  = 'page.model',
     Id     = 'page.id',
     Path   = 'page.path',
     Alias  = 'page.alias',
     Prefix = 'page.prefix',
     Suffix = 'page.suffix',
     Text   = 'page.text',
}

/**
 * @enum PlatformEnum
 * @author Isaac Ewing
 * @version 1.0.0 05/24/21 02:20 pm
 */
export enum PlatformEnum {
     Model    = 'platform.model',
     Id       = 'platform.id',
     Name     = 'platform.name',
     Alias    = 'platform.alias',
     Text     = 'platform.text',
     URL      = 'platform.url',
     Enabled  = 'platform.enabled',
     Standard = 'platform.standard',
     Options  = 'platform.options',
}

/**
 * @enum ProfileEnum
 * @author Isaac Ewing
 * @version 1.0.0 05/24/21 11:54 am
 */
export enum ProfileEnum {
     Model     = 'profile.model',
     Id        = 'profile.id',
     Identity  = 'profile.identity',
     Username  = 'profile.username',
     Email     = 'profile.email',
     Name      = 'profile.name',
     Location  = 'profile.location',
     Image     = 'profile.image',
     URL       = 'profile.url',
     Followers = 'profile.followers',
     Verified  = 'profile.verified',
     Started   = 'profile.started',
}

/**
 * @enum SettingEnum
 * @author Isaac Ewing
 * @version 1.0.0 05/25/21 02:46 pm
 */
export enum SettingEnum {
     Model    = 'setting.model',
     Id       = 'setting.id',
     Pronouns = 'setting.pronouns',
}

/**
 * @enum SetupEnum
 * @author Isaac Ewing
 * @version 1.0.0 05/08/21 02:02 pm
 */
export enum SetupEnum {
     Model  = 'setup.model',
     Id     = 'setup.id',
     Login  = 'setup.login',
     Intro  = 'setup.intro',
     Domain = 'setup.domain',
}

/**
 * @enum SiteEnum
 * @author Isaac Ewing
 * @version 1.0.0 05/08/21 12:54 pm
 */
export enum SiteEnum {
     Model   = 'site.model',
     Domain  = 'site.domain',
     Page    = 'site.page',
     Setup   = 'site.setup',
     Social  = 'site.social',
     Company = 'site.company',
}

/**
 *
 * @enum UserEnum
 * @author Isaac Ewing
 * @version 1.0.0 10/24/20 07:01 pm
 * @version 2.0.0 11/04/20 12:55 pm - converted to typescript object from class
 * @version 3.0.0 05/08/21 12:47 pm - converted to enum from class
 */
export enum UserEnum {
     Model    = 'user.model',
     Id       = 'user.id',
     Username = 'user.username',
     Email    = 'user.email',
     About    = 'user.about',
     Accounts = 'user.accounts',
     Domain   = 'user.domain',
     Setup    = 'user.setup',
     Setting  = 'user.setting',
     Company  = 'user.company',
     Token    = 'user.token',
}