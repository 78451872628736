import React             from 'react';
import { BaseSection }   from '../../atom/BaseSection';
import { Gallery }       from '../../molecule/Gallery';
import { ISectionProps } from '../../../api/com/ewing/social/interface';

/**
 * @class Layout15
 * @extends BaseSection
 * @author Isaac Ewing
 * @version 1.0.0 01/30/21 11:30 am - documented
 */
export class Layout15 extends BaseSection {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am - documented
      */
     protected static readonly COMPONENT_CLASS: string = 'layout-15';

     /**
      *
      * @param props {ISectionProps}
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am - documented
      */
     public constructor( props: ISectionProps ) {
          super( props );

          this.state = {
               data     : props.data,
               className: new Set<string>( [ 'grid-x', Layout15.COMPONENT_CLASS ] ),
          };

          this.registerProps( props.module ?? null );
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am - documented
      */
     public render(): JSX.Element {
          return (
               <section { ...this.registerSectionProps( this.props.module ) }>
                    <div className="grid-container full content">
                         <Gallery module={ this.props?.module?.gallery } fullWidth />
                    </div>
               </section>
          );
     }
}