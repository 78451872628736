/**
 * @class StorageObserver
 * @abstract
 * @author Isaac Ewing
 * @version 1.0.0 10/26/20 06:33 pm
 * @version 1.1.0 06/01/21 04:15 pm - changed type from &quot;Function&quot; to &quot;( data: unknown ) => void&quot;
 */
export abstract class AObserver {
    /**
     *
     * @type {Map<string, Function>}
     * @static
     * @private
     * @author Isaac Ewing
     * @version 1.0.0 10/26/20 06:33 pm
     * @version 1.1.0 03/24/21 12:17 pm - changed array to set
     * @version 1.2.0 03/26/21 02:28 pm - changed set to map
     */
    protected static observers: Map<string, ( data: any ) => void>;

    /**
     *
     * @param hash {string}
     * @param fn {Function}
     * @return void
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 10/26/20 06:33 pm
     */
    public static subscribe( hash: string, fn: ( data: any ) => void ): void {
        this.observers.set( hash, fn );
        console.log( '{{ +O }} ADDED subscriber', hash, 'all subscribers', { observers: this.observers } );
    }

    /**
     *
     * @param hash {string}
     * @return void
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 10/26/20 06:33 pm
     */
    public static unsubscribe( hash: string | null ): void {
        if( hash ) {
            if( this.observers.has( hash ) ) {
                this.observers.delete( hash );
                console.log( '{{ -O }} REMOVED subscriber', hash );
            } else {
                console.log( '{{ xO }} ERROR removing subscriber, could not find...', hash );
            }
        }
    }

    /**
     *
     * @param data {any}
     * @return void
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 10/26/20 06:33 pm
     */
    public static notify( data: any ): void {
        try {
            console.log( '{{ >O }} >>> com name', data.constructor.name, data );
        } catch( exception ) {
            console.warn( '{{ >O }} >>> com name', data?.constructor?.name, data );
        }

        this.observers.forEach( ( fn: ( data: any ) => void, key: string ): void => {
            console.log( '{{ >O }} >>> CALLING hash', key, 'function...' );
            fn( data );
        } );
    }

    /**
     * @return {void}
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 03/26/21 02:19 pm
     */
    public static clear(): void {
        this.observers.clear();
    }

    /**
     *
     * @return {Function[]}
     * @static
     * @public
     * @author Isaac Ewing
     * @version 01/18/21 12:59 pm
     */
    public static getAllObservers(): ( ( data: any ) => void )[] {
        return [ ...this.observers.values() ];
    }
}