import React           from 'react';
import jQuery          from 'jquery';
import {
     IGalleryImageModule, ISectionProps,
     ISectionStateDimensions,
}                      from '../../../api/com/ewing/social/interface';
import { BaseSection } from '../../atom/BaseSection';
import { Button }      from '../../atom/Button';
import { Description } from '../../atom/Description';
import { Divider }     from '../../atom/Divider';
import { Image }       from '../../atom/Image';
import { Particle1 }   from '../../atom/particle/Particle1';
import { Subtitle }    from '../../atom/Subtitle';
import { Title }       from '../../atom/Title';
import { Menu }        from '../../molecule/Menu';

// TODO 05/30/21 11:47 am
//  This class component can be changed into a functional component
/**
 * @class Layout1
 * @extends BaseSection
 * @author Isaac Ewing
 * @version 1.0.0 01/30/21 11:30 am - documented
 */
export class Layout1 extends BaseSection {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am - documented
      */
     protected static readonly COMPONENT_CLASS: string = 'layout-1';
     /**
      *
      * @type {ISectionProps}
      * @readonly
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/06/21 02:24 pm
      */
     public readonly props:ISectionProps;

     /**
      *
      * @param props {ISectionProps}
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am - documented
      */
     public constructor( props: ISectionProps ) {
          super( props );

          this.state = {
               className : new Set<string>( [ 'grid-x', Layout1.COMPONENT_CLASS ] ),
               dimensions: {
                    width : 0,
                    height: 0,
               },
          };

          this.registerProps( props.module ?? null );
     }

     /**
      *
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am
      * documented
      */
     public componentDidMount(): void {
          const section: JQuery<HTMLElement>        = jQuery( `.${ Layout1.COMPONENT_CLASS }` );
          const cell: Element                       = section[ 0 ].firstElementChild.firstElementChild.lastElementChild;
          const dimensions: ISectionStateDimensions = {
               width : cell.clientWidth,
               height: cell.clientHeight,
          };
          this.setState( { dimensions } );

          console.log( ' OK!!!!!! LAYOUT 1 DIMENSIONS', dimensions );
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am
      * documented
      */
     public render(): JSX.Element {
          const localImages: IGalleryImageModule[] = [ ...this.props.module?.gallery?.images ];

          return (
               <section { ...this.registerSectionProps( this.props.module ) }>
                    <div className="grid-container content">
                         <div className="grid-x">
                              <div className="cell small-12 medium-7 main-content">
                                   <Image className="show-for-small-only" module={ localImages?.[ 0 ].image } />
                                   <Image className="show-for-small-only" module={ localImages?.[ 1 ].image } />
                                   <Subtitle module={ this.props?.module?.subtitle }  />
                                   <Divider />
                                   <Title module={ this.props?.module?.title } />
                                   <Description module={ this.props?.module?.description } />
                                   <Menu module={ this.props?.module?.menu } />
                                   <Button module={ this.props?.module?.button } />
                              </div>
                              <div className="cell hide-for-small-only medium-5">
                                   <Particle1 dimensions={ this.state.dimensions } />
                                   <Image module={ localImages?.[ 0 ].image } />
                                   <Image module={ localImages?.[ 1 ].image } />
                              </div>
                         </div>
                    </div>
               </section>
          );
     }
}