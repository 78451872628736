import React               from 'react';
import { AllHtmlEntities } from 'html-entities';
import { LinkModule }      from '../../api/com/ewing/social/module/api/link.module';
import { Util }            from '../../api/com/ewing/social/tool/util';
import { Link }            from '../atom/Link';

/**
 * @class Footer
 * @extends PureComponent
 * @author Isaac Ewing
 * @version 1.0.0 02/20/21 04:50 pm - documented
 * @version 1.1.0 02/20/21 05:01 pm - converted component to pure component
 */
export class Footer extends React.PureComponent<any, any> {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 12:58 pm
      */
     public static readonly COMPONENT_CLASS: string                = 'root-footer';
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 12:58 pm
      */
     protected static readonly DEFAULT_HASH: string                = Util.registerHash();
     /**
      *
      * @type {number}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 12:58 pm
      */
     protected static readonly DEFAULT_INC_YEAR: number            = +process.env.REACT_APP_INCORPORATE_YEAR;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 12:58 pm
      */
     protected static readonly DEFAULT_EMAIL: string               = `mailto:${ Util.buildDomainEmail() }`;
     /**
      *
      * @type {Set<JSX.Element>}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 12:58 pm
      */
     protected static readonly DEFAULT_COPYRIGHT: Set<JSX.Element> = new Set<JSX.Element>();
     /**
      *
      * @type {Set<string>}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 12:58 pm
      */
     protected static readonly className: Set<string>              = new Set<string>( [ Footer.COMPONENT_CLASS ] );
     /**
      *
      * @type {boolean}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 03/22/21 01:31 pm
      */
     protected static isSetup: boolean                             = false;

     /**
      *
      * @protected
      * @return {void}
      * @author Isaac Ewing
      * @version 1.0.0 02/20/21 04:50 pm
      */
     protected registerCopyright(): void {
          if( !Footer.isSetup ) {
               const website: string        = `https://${ process.env.REACT_APP_SITE_DOMAIN }`;
               const module: LinkModule     = LinkModule.Build( { path: website, text: `${ process.env.REACT_APP_NAME }` } );
               const link: JSX.Element      = <Link key={ `${ Footer.DEFAULT_HASH }-link` } module={ module } />;
               const span: JSX.Element      = <span key={ `${ Footer.DEFAULT_HASH }-span` }>, { process.env.REACT_APP_INCORPORATE_ENTITY }</span>;
               const parts: string[]        = [
                    `&copy;`,
                    `${ Footer.DEFAULT_INC_YEAR }-${ new Date().getFullYear() }`,
                    '',
               ];
               const paragraph: JSX.Element = <p key={ `${ Footer.DEFAULT_HASH }-paragraph1` }>{ AllHtmlEntities.decode( parts.join( ' ' ) ) }</p>;

               Footer.DEFAULT_COPYRIGHT.add( paragraph ).add( link ).add( span );
               Footer.isSetup = true;
          }
     }

     /**
      *
      * @param props {any}
      * @return {void}
      * @author Isaac Ewing
      * @version 1.0.0 02/20/21 04:50 pm - documented
      * @version 1.1.0 02/20/21 05:01 pm - converted component to pure component
      */
     public constructor( props: any ) {
          super( props );

          this.registerCopyright();
     }

     /**
      *
      * @return {JSX.Element}
      * @author Isaac Ewing
      * @version 1.0.0 02/20/21 04:50 pm - documented
      * @version 1.1.0 02/20/21 05:01 pm - converted component to pure component
      */
     public render(): JSX.Element {
          return (
               <footer className={ [ ...Footer.className ].join( ' ' ) }>
                    <div className="grid-x grid-padding-x dark">
                         <div className="cell small-12 medium-2 left">{ [ ...Footer.DEFAULT_COPYRIGHT ] }</div>
                         <div className="cell small-12 medium-8 center">
                              <ul className="menu align-center">
                                   <li><Link module={ LinkModule.Build( { path: '/events', text: 'events' } ) } /></li>
                                   <li><Link module={ LinkModule.Build( { path: '/cookies', text: 'cookies' } ) } /></li>
                                   <li><Link module={ LinkModule.Build( { path: '/terms', text: 'terms' } ) } /></li>
                                   <li><Link module={ LinkModule.Build( { path: '/privacy', text: 'privacy' } ) } /></li>
                                   <li><Link module={ LinkModule.Build( { path: '/disclaimer', text: 'disclaimer' } ) } /></li>
                                   <li><Link module={ LinkModule.Build( { path: '/sitemap', text: 'sitemap' } ) } /></li>
                              </ul>
                         </div>
                         <div className="cell small-12 medium-2 right">
                              <ul className="menu align-right">
                                   <li><Link module={ LinkModule.Build( { path: Footer.DEFAULT_EMAIL, icon: 'far envelope' } ) } /></li>
                                   <li><Link module={ LinkModule.Build( { path: '/facebook', icon: 'fab facebook-f' } ) } /></li>
                                   <li><Link module={ LinkModule.Build( { path: '/twitter', icon: 'fab twitter' } ) } /></li>
                                   <li><Link module={ LinkModule.Build( { path: '/youtube', icon: 'fab youtube' } ) } /></li>
                              </ul>
                         </div>
                    </div>
               </footer>
          );
     }
}