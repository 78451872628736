import { ILink, IMenuModule } from '../../interface';
import { ColumnsEnum }        from '../../enum';
import { LinkModule }         from '../../module/api/link.module';
import { TitleModule }        from '../../module/api/title.module';
import { Util }               from '../../tool';

/**
 * @class MenuModule
 * @extends TitleModule
 * @implements IMenuModule
 * @author Isaac Ewing
 * @version 1.0.0 02/20/21 10:05 pm - updated to extend AComponent (through submenu module)
 * @version 2.0.0 05/30/21 04:34 pm - updated to extend TitleModule (was AComponent)
 */
export class MenuModule extends TitleModule implements IMenuModule {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/30/21 05:48 pm
      */
     protected static readonly CONSOLE_PREFIX: string       = `${ process.env.REACT_APP_CONSOLE_PREFIX_MODULE } MENU ${ process.env.REACT_APP_CONSOLE_SUFFIX_MODULE }`;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/30/21 05:48 pm
      */
     protected static readonly CONSOLE_ENABLED: boolean     = false;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/20/21 09:36 pm
      */
     protected static readonly API_ROOT_NODE: string        = process.env.REACT_APP_GRAPHQL_GET_LINK;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/20/21 09:36 pm
      */
     protected static readonly DEFAULT_SUBMENU: boolean     = false;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:30 pm
      */
     protected static readonly DEFAULT_DISCORD: boolean     = false;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:30 pm
      */
     protected static readonly DEFAULT_FACEBOOK: boolean    = false;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:30 pm
      */
     protected static readonly DEFAULT_INSTAGRAM: boolean   = false;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:30 pm
      */
     protected static readonly DEFAULT_ONLYFANS: boolean    = false;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:30 pm
      */
     protected static readonly DEFAULT_PATREON: boolean     = false;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:30 pm
      */
     protected static readonly DEFAULT_TIKTOK: boolean      = false;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:30 pm
      */
     protected static readonly DEFAULT_TWITCH: boolean      = false;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:30 pm
      */
     protected static readonly DEFAULT_TWITTER: boolean     = false;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:30 pm
      */
     protected static readonly DEFAULT_YOUTUBE: boolean     = false;
     /**
      *
      * @type {ColumnsEnum}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/20/21 09:36 pm
      */
     protected static readonly DEFAULT_COLUMNS: ColumnsEnum = ColumnsEnum.Auto;

     /**
      *
      * @param {Partial<ILink>} obj
      * @return {LinkModule | Set<LinkModule>}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/30/21 06:16 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      * @see linksToArray
      * @see parseLink
      */
     //protected static parseLink( module: LinkModule ): LinkModule | null;
     //protected static parseLink( modules: LinkModule[] ): LinkModule | Set<LinkModule> | null;
     //protected static parseLink( modules: Set<LinkModule> ): LinkModule | Set<LinkModule> | null;
     protected static parseLink( obj: Partial<ILink> ): LinkModule | null;
     //protected static parseLink( objects: Partial<ILink>[] ): LinkModule | Set<LinkModule> | null;
     //protected static parseLink( objects: Set<Partial<ILink>> ): LinkModule | Set<LinkModule> | null;
     protected static parseLink( data: unknown, path?: string, text?: string, icon?: string, submit?: boolean, shortcode?: boolean ): LinkModule | Set<LinkModule> | null {
          if( data instanceof LinkModule ) {
               return data;
          } else if( data instanceof Set || Array.isArray( data ) ) {
               const result: Set<LinkModule> = new Set<LinkModule>();

               data.forEach( ( element: LinkModule | Partial<ILink> ): void => {
                    const temp: LinkModule | null = this.parseLink( element ?? null );

                    if( temp ) {
                         result.add( temp );
                    }
               } );

               return result;
          } else if( typeof data === 'object' ) {
               const local: Partial<ILink> = data;

               return new LinkModule( local?.id ?? null, local?.path ?? null, local?.text ?? null, local?.icon ?? null, local?.submit ?? null,
                                      local?.shortcode ?? null );
          } else if( data && path && text ) {
               return LinkModule.Build( { id: +data, path, text, icon, submit, shortcode } );
          } else {
               console.log( 'the link was invalid', { id: data, path, text, icon, submit, shortcode } );
          }

          return null;
     }

     /**
      *
      * @param module {MenuModule} The object containing the data
      * @return {MenuModule|null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 12:58 pm
      * @see Build
      * @see BuildForShortcode
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public static Build( module: MenuModule ): MenuModule;
     public static Build( obj: Partial<IMenuModule> ): MenuModule;
     public static Build( json: string ): MenuModule;
     public static Build( id?: number, submenu?: boolean, discord?: boolean, facebook?: boolean, instagram?: boolean, onlyfans?: boolean, patreon?: boolean, tiktok?: boolean, twitch?: boolean,
                          twitter?: boolean, youtube?: boolean, columns?: string, link?: LinkModule ): MenuModule;
     public static Build( id?: number, submenu?: boolean, discord?: boolean, facebook?: boolean, instagram?: boolean, onlyfans?: boolean, patreon?: boolean, tiktok?: boolean, twitch?: boolean,
                          twitter?: boolean, youtube?: boolean, columns?: string, links?: LinkModule[] ): MenuModule;
     public static Build( id?: number, submenu?: boolean, discord?: boolean, facebook?: boolean, instagram?: boolean, onlyfans?: boolean, patreon?: boolean, tiktok?: boolean, twitch?: boolean,
                          twitter?: boolean, youtube?: boolean, columns?: string, links?: Set<LinkModule> ): MenuModule;
     public static Build( id?: number, submenu?: boolean, discord?: boolean, facebook?: boolean, instagram?: boolean, onlyfans?: boolean, patreon?: boolean, tiktok?: boolean, twitch?: boolean,
                          twitter?: boolean, youtube?: boolean, columns?: string, link?: Partial<ILink> ): MenuModule;
     public static Build( id?: number, submenu?: boolean, discord?: boolean, facebook?: boolean, instagram?: boolean, onlyfans?: boolean, patreon?: boolean, tiktok?: boolean, twitch?: boolean,
                          twitter?: boolean, youtube?: boolean, columns?: string, links?: Partial<ILink>[] ): MenuModule;
     public static Build( id?: number, submenu?: boolean, discord?: boolean, facebook?: boolean, instagram?: boolean, onlyfans?: boolean, patreon?: boolean, tiktok?: boolean, twitch?: boolean,
                          twitter?: boolean, youtube?: boolean, columns?: string, links?: Set<Partial<ILink>> ): MenuModule;
     public static Build( id?: unknown, submenu?: unknown, discord?: boolean, facebook?: boolean, instagram?: boolean, onlyfans?: boolean, patreon?: boolean, tiktok?: boolean, twitch?: boolean,
                          twitter?: boolean, youtube?: boolean, columns?: string, links?: Set<Partial<ILink>> ): MenuModule;
     public static Build( dataOrId?: unknown, submenu?: boolean, discord?: boolean, facebook?: boolean, instagram?: boolean, onlyfans?: boolean, patreon?: boolean, tiktok?: boolean, twitch?: boolean,
                          twitter?: boolean, youtube?: boolean, columns?: string, links?: unknown ): MenuModule {
          if( dataOrId ) {
               dataOrId = this.mapAPIRootNode( dataOrId, this.API_ROOT_NODE );

               if( dataOrId instanceof MenuModule ) {
                    return dataOrId;
               }
               if( typeof dataOrId === 'object' ) {
                    const local: Partial<IMenuModule> = dataOrId;

                    return new MenuModule( local?.id ?? null, local?.submenu ?? null, local?.discord ?? null, local?.facebook ?? null, local?.instagram ?? null,
                                           local?.onlyfans ?? null, local?.patreon ?? null, local?.tiktok ?? null, local?.twitch ?? null, local?.twitter ?? null,
                                           local?.youtube ?? null, local?.columns ?? null, local.links ?? null );
               }
               if( typeof dataOrId === 'number' ) {
                    return new MenuModule( dataOrId ?? null, submenu ?? null, discord ?? null, facebook ?? null, instagram ?? null, onlyfans ?? null,
                                           patreon ?? null, tiktok ?? null, twitch ?? null, twitter ?? null, youtube ?? null, columns ?? null,
                                           links ?? null );
               }
               if( typeof dataOrId === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrId ) );
                    } catch( exception ) {
                         // not a valid json string
                         return new MenuModule( +dataOrId ?? null ?? null, submenu ?? null, discord ?? null, facebook ?? null, instagram ?? null,
                                                onlyfans ?? null, patreon ?? null, tiktok ?? null, twitch ?? null, twitter ?? null, youtube ?? null,
                                                columns ?? null, links ?? null );
                    }
               }
          }

          return null;
     }

     /**
      *
      * @param {number} id
      * @param {boolean} submenu
      * @param {boolean} discord
      * @param {boolean} facebook
      * @param {boolean} instagram
      * @param {boolean} onlyfans
      * @param {boolean} patreon
      * @param {boolean} tiktok
      * @param {boolean} twitch
      * @param {boolean} twitter
      * @param {boolean} youtube
      * @param {string} columns
      * @param {LinkModule|LinkModule[]} link
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 10/03/20 02:38 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public constructor( id?: number, submenu?: boolean, discord?: boolean, facebook?: boolean, instagram?: boolean, onlyfans?: boolean, patreon?: boolean, tiktok?: boolean, twitch?: boolean,
                         twitter?: boolean, youtube?: boolean, columns?: string, link?: LinkModule )
     public constructor( id?: number, submenu?: boolean, discord?: boolean, facebook?: boolean, instagram?: boolean, onlyfans?: boolean, patreon?: boolean, tiktok?: boolean, twitch?: boolean,
                         twitter?: boolean, youtube?: boolean, columns?: string, links?: LinkModule[] )
     public constructor( id?: number, submenu?: boolean, discord?: boolean, facebook?: boolean, instagram?: boolean, onlyfans?: boolean, patreon?: boolean, tiktok?: boolean, twitch?: boolean,
                         twitter?: boolean, youtube?: boolean, columns?: string, links?: Set<LinkModule> )
     public constructor( id?: number, submenu?: boolean, discord?: boolean, facebook?: boolean, instagram?: boolean, onlyfans?: boolean, patreon?: boolean, tiktok?: boolean, twitch?: boolean,
                         twitter?: boolean, youtube?: boolean, columns?: string, link?: Partial<ILink> )
     public constructor( id?: number, submenu?: boolean, discord?: boolean, facebook?: boolean, instagram?: boolean, onlyfans?: boolean, patreon?: boolean, tiktok?: boolean, twitch?: boolean,
                         twitter?: boolean, youtube?: boolean, columns?: string, links?: Partial<ILink>[] )
     public constructor( id?: number, submenu?: boolean, discord?: boolean, facebook?: boolean, instagram?: boolean, onlyfans?: boolean, patreon?: boolean, tiktok?: boolean, twitch?: boolean,
                         twitter?: boolean, youtube?: boolean, columns?: string, links?: Set<ILink> )
     public constructor( id?: number, submenu?: boolean, discord?: boolean, facebook?: boolean, instagram?: boolean, onlyfans?: boolean, patreon?: boolean, tiktok?: boolean, twitch?: boolean,
                         twitter?: boolean, youtube?: boolean, columns?: string, links?: unknown ) {
          super( id ?? null );

          this.submenu   = Util.asBoolean( submenu ?? MenuModule.DEFAULT_SUBMENU );
          this.discord   = Util.asBoolean( discord ?? MenuModule.DEFAULT_DISCORD );
          this.facebook  = Util.asBoolean( facebook ?? MenuModule.DEFAULT_FACEBOOK );
          this.instagram = Util.asBoolean( instagram ?? MenuModule.DEFAULT_INSTAGRAM );
          this.onlyfans  = Util.asBoolean( onlyfans ?? MenuModule.DEFAULT_ONLYFANS );
          this.patreon   = Util.asBoolean( patreon ?? MenuModule.DEFAULT_PATREON );
          this.tiktok    = Util.asBoolean( tiktok ?? MenuModule.DEFAULT_TIKTOK );
          this.twitch    = Util.asBoolean( twitch ?? MenuModule.DEFAULT_TWITCH );
          this.twitter   = Util.asBoolean( twitter ?? MenuModule.DEFAULT_TWITTER );
          this.youtube   = Util.asBoolean( youtube ?? MenuModule.DEFAULT_YOUTUBE );
          this.columns   = columns ?? MenuModule.DEFAULT_COLUMNS;
          this.links     = new Set<LinkModule>();
          this.addLink( links ?? null );
     }

     /**
      *
      * @return {boolean} Returns the value for the property
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 10/03/20 01:44 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public get submenu(): boolean {
          return this._data.get( 'submenu' );
     }

     /**
      *
      * @param value {boolean} The string to set for the property
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 10/03/20 01:44 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public set submenu( value: boolean ) {
          this._data.set( 'submenu', value );
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:47 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public get discord(): boolean {
          return this._data.get( 'discord' );
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:47 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public set discord( value: boolean ) {
          this._data.set( 'discord', value );
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:47 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public get facebook(): boolean {
          return this._data.get( 'facebook' );
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:47 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public set facebook( value: boolean ) {
          this._data.set( 'facebook', value );
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:47 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public get instagram(): boolean {
          return this._data.get( 'instagram' );
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:47 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public set instagram( value: boolean ) {
          this._data.set( 'instagram', value );
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:47 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public get onlyfans(): boolean {
          return this._data.get( 'onlyfans' );
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:47 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public set onlyfans( value: boolean ) {
          this._data.set( 'onlyfans', value );
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:47 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public get patreon(): boolean {
          return this._data.get( 'patreon' );
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:47 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public set patreon( value: boolean ) {
          this._data.set( 'patreon', value );
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:47 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public get tiktok(): boolean {
          return this._data.get( 'tiktok' );
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:47 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public set tiktok( value: boolean ) {
          this._data.set( 'tiktok', value );
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:47 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public get twitch(): boolean {
          return this._data.get( 'twitch' );
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:47 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public set twitch( value: boolean ) {
          this._data.set( 'twitch', value );
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:47 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public get twitter(): boolean {
          return this._data.get( 'twitter' );
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:47 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public set twitter( value: boolean ) {
          this._data.set( 'twitter', value );
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:47 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public get youtube(): boolean {
          return this._data.get( 'youtube' );
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:47 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public set youtube( value: boolean ) {
          this._data.set( 'youtube', value );
     }

     /**
      *
      * @return {string} Returns the value for the property
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 10/03/20 01:44 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public get columns(): string {
          return this._data.get( 'columns' );
     }

     /**
      *
      * @param value {string} The string to set for the property
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 10/03/20 01:44 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public set columns( value: string ) {
          this._data.set( 'columns', value );
     }

     /**
      *
      * @return {Set<LinkModule>} Returns the value for the property
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 10/03/20 02:34 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      * @see linksToArray
      */
     public get links(): Set<LinkModule> {
          return this._data.get( 'links' );
     }

     /**
      *
      * @param value {Set<LinkModule>} The string to set for the property
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 10/03/20 01:44 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      * @see linksToArray
      */
     public set links( value: Set<LinkModule> ) {
          this._data.set( 'links', value );
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 10/03/20 02:38 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public setSubmenu( value: boolean ): this {
          this.submenu = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:47 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public setDiscord( value: boolean ): this {
          this.discord = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:47 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public setFacebook( value: boolean ): this {
          this.facebook = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:47 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public setInstagram( value: boolean ): this {
          this.instagram = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:47 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public setOnlyFans( value: boolean ): this {
          this.onlyfans = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:47 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public setPatreon( value: boolean ): this {
          this.patreon = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:47 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public setTikTok( value: boolean ): this {
          this.tiktok = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:47 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public setTwitch( value: boolean ): this {
          this.twitch = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:47 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public setTwitter( value: boolean ): this {
          this.twitter = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 08:47 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public setYouTube( value: boolean ): this {
          this.youtube = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/30/21 05:48 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      * @see linksToArray
      */
     public setColumns( value: string ): this {
          this.columns = value;

          return this;
     }

     /**
      *
      * @param value {LinkModule|Set<LinkModule>}
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/30/21 05:48 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      * @see linksToArray
      */
     public setLinks( value: LinkModule ): this;
     public setLinks( values: LinkModule[] ): this;
     public setLinks( values: Set<LinkModule> ): this;
     public setLinks( values: unknown ): this {
          if( values ) {
               this._data.delete( 'links' );
               this._data.set( 'links', new Set<LinkModule>() );
               this.addLink( values );
          }

          return this;
     }

     /**
      *
      * @param module {LinkModule} An array or object or id with the data
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/30/21 05:48 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      * @see linksToArray
      */
     public addLink( module: LinkModule ): this;
     public addLink( modules: LinkModule[] ): this;
     public addLink( modules: Set<LinkModule> ): this;
     public addLink( obj: Partial<ILink> ): this;
     public addLink( objects: Partial<ILink>[] ): this;
     public addLink( objects: Set<Partial<ILink>> ): this;
     public addLink( data: unknown, path?: string, text?: string, icon?: string, submit?: boolean, shortcode?: boolean ): this {
          if( data instanceof LinkModule ) {
               this.links.add( data );
          } else if( data instanceof Set || Array.isArray( data ) ) {
               data.forEach( ( element: unknown ): void => {
                    this.addLink( element );
               } );
          } else if( typeof data === 'object' ) {
               const local: Partial<ILink> = data;
               const module: LinkModule    = new LinkModule( local?.id ?? null, local?.path ?? null, local?.text ?? null, local?.icon ?? null, local?.submit ?? null,
                                                             local?.shortcode ?? null );

               this.links.add( module ?? null );

               if( MenuModule.CONSOLE_ENABLED ) {
                    console.info( `${ MenuModule.CONSOLE_PREFIX } MENU MODULE`, { local, module } );
               }
          } else if( data && path ) {
               this.links.add( LinkModule.Build( { id: +data, path, text, icon, submit, shortcode } ) );
          } else {
               console.log( 'the link was invalid', { id: data, path, text, icon, submit, shortcode } );
          }

          return this;
     }

     /**
      *
      * @param {boolean} [primitive=false] Indicates if the values should be transformed into their basic types
      * @return {LinkModule[] | object[]}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 02:38 pm
      * @version 1.1.0 02/24/21 05:13 pm - added support for primitive transformation vs normal conversion to array
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      * @see linksToArray
      */
     public linksToArray( primitive?: boolean ): LinkModule[] | Record<string, unknown>[] {
          if( this.links ) {
               if( primitive ) {
                    let test: Record<string, unknown>;
                    const links: Record<string, unknown>[] = [];

                    this.links.forEach( ( link: LinkModule ): void => {
                         test = link.toObject();

                         if( test ) {
                              links.push( test );
                         }
                    } );

                    return links;
               } else {
                    return [ ...this.links ];
               }
          }

          return null;
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/20/21 09:44 pm
      * @see id
      * @see submenu
      * @see discord
      * @see facebook
      * @see instagram
      * @see onlyfans
      * @see patreon
      * @see tiktok
      * @see twitch
      * @see twitter
      * @see youtube
      * @see columns
      * @see links
      * @see setSubmenu
      * @see setDiscord
      * @see setFacebook
      * @see setInstagram
      * @see setOnlyFans
      * @see setPatreon
      * @see setTikTok
      * @see setTwitch
      * @see setTwitter
      * @see setYouTube
      * @see setColumns
      * @see setLinks
      * @see addLink
      */
     public toObject(): Record<string, unknown> {
          return {
               ...super.toObject(),
               submenu  : this.submenu,
               discord  : this.discord,
               facebook : this.facebook,
               instagram: this.instagram,
               onlyfans : this.onlyfans,
               patreon  : this.patreon,
               tiktok   : this.tiktok,
               twitch   : this.twitch,
               twitter  : this.twitter,
               youtube  : this.youtube,
               columns  : this.columns,
               links    : this.linksToArray( true ),
          };
     }
}
