import {
     ISectionModule, ISiteModule, ISitePage,
}                        from '../../interface';
import { TitleModule }   from './title.module';
import { DomainModule }  from './domain.module';
import { PageModule }    from './page.module';
import { SetupModule }   from './setup.module';
import { InviteModule }  from './invite.module';
import { SectionModule } from '../../component';

/**
 * @class SiteModule
 * @extends TitleModule
 * @implements ISiteModule
 * @author Isaac Ewing
 * @version 1.1.0 02/18/21 02:56 pm - updated to extend AModule (through link module through title module)
 * @version 2.0.0 05/28/21 08:03 pm - converted from AModule to TitleModule
 */
export class SiteModule extends TitleModule implements ISiteModule {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 05:42 pm
      */
     protected static readonly CONSOLE_PREFIX: string   = `${ process.env.REACT_APP_CONSOLE_PREFIX_MODULE } SITE ${ process.env.REACT_APP_CONSOLE_SUFFIX_MODULE }`;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 05:42 pm
      */
     protected static readonly CONSOLE_ENABLED: boolean = true;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/19/21 09:29 am
      */
     protected static readonly API_ROOT_NODE: string    = process.env.REACT_APP_GRAPHQL_GET_SITE_PAGE;

     /**
      *
      * @param module {SiteModule} The object containing the data
      * @return {SiteModule|null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/18/21 02:56 pm
      * @see Build
      * @see domain
      * @see page
      * @see pages
      * @see setup
      * @see invite
      * @see platforms
      * @see sections
      * @see setDomain
      * @see setPage
      * @see setPages
      * @see setSetup
      * @see setInvite
      * @see setPlatforms
      * @see setSections
      * @see addPage
      * @see pagesToArray
      * @see addSection
      * @see sectionsToArray
      * @see toObject
      */
     public static Build( module: SiteModule ): SiteModule;
     public static Build( obj: Partial<ISiteModule> ): SiteModule;
     public static Build( json: string ): SiteModule;
     public static Build( id?: number, domain?: unknown, page?: PageModule, pages?: ISiteModule[], setup?: SetupModule, invite?: InviteModule, platforms?: string[],
                          section?: SectionModule ): SiteModule;
     public static Build( id?: number, domain?: DomainModule, page?: PageModule, pages?: ISiteModule[], setup?: SetupModule, invite?: InviteModule, platforms?: string[],
                          section?: SectionModule ): SiteModule;
     public static Build( id?: number, domain?: DomainModule, page?: PageModule, pages?: ISiteModule[], setup?: SetupModule, invite?: InviteModule, platforms?: string[],
                          sections?: SectionModule[] ): SiteModule;
     public static Build( id?: number, domain?: DomainModule, page?: PageModule, pages?: ISiteModule[], setup?: SetupModule, invite?: InviteModule, platforms?: string[],
                          sections?: Set<SectionModule> ): SiteModule;
     public static Build( id?: number, domain?: DomainModule, page?: PageModule, pages?: ISitePage[], setup?: SetupModule, invite?: InviteModule, platforms?: string[],
                          section?: SectionModule ): SiteModule;
     public static Build( id?: number, domain?: DomainModule, page?: PageModule, pages?: ISitePage[], setup?: SetupModule, invite?: InviteModule, platforms?: string[],
                          sections?: SectionModule[] ): SiteModule;
     public static Build( id?: number, domain?: DomainModule, page?: PageModule, pages?: ISitePage[], setup?: SetupModule, invite?: InviteModule, platforms?: string[],
                          sections?: Set<SectionModule> ): SiteModule;
     public static Build( dataOrId?: unknown, domain?: DomainModule, page?: never, pages?: never, setup?: SetupModule, invite?: InviteModule, platforms?: string[], sections?: never ): SiteModule {
          if( dataOrId ) {
               if( dataOrId instanceof SiteModule ) {
                    return dataOrId;
               }

               dataOrId = this.mapAPIRootNode( dataOrId, this.API_ROOT_NODE );

               if( this.CONSOLE_ENABLED ) {
                    console.log( `${ this.CONSOLE_PREFIX } SITE MODULE`, { dataOrId, domain, page, pages, setup, invite, sections } );
               }
               if( typeof dataOrId === 'object' ) {
                    const localData: Partial<ISiteModule> = dataOrId;

                    return new SiteModule( localData?.id ?? null, DomainModule.Build( localData?.domain ) ?? null, PageModule.Build( localData?.page ) ?? null, localData?.pages as never,
                                           SetupModule.Build( localData?.setup ) ?? null, InviteModule.Build( localData?.invite ) ?? null, localData?.platforms ?? null,
                                           localData?.sections ?? null );
               }
               if( typeof dataOrId === 'number' ) {
                    return new SiteModule( dataOrId ?? null, DomainModule.Build( domain ) ?? null, PageModule.Build( page ) ?? null, pages ?? null, SetupModule.Build( setup ) ?? null,
                                           InviteModule.Build( invite ) ?? null, platforms ?? null, sections ?? null );
               }
               if( typeof dataOrId === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrId ) );
                    } catch( exception ) {
                         // not a valid json string
                         return new SiteModule( +dataOrId ?? null, DomainModule.Build( dataOrId ) ?? null, PageModule.Build( page ) ?? null, pages ?? null,
                                                SetupModule.Build( setup ) ?? null, InviteModule.Build( invite ) ?? null, platforms ?? null, sections ?? null );
                    }
               }
          }

          return null;
     }

     /**
      *
      * @param domain {DomainModule}
      * @param page {PageModule}
      * @param pages {ISiteModule[]}
      * @param setup {SetupModule}
      * @param invite {InviteModule}
      * @param platforms {string[]}
      * @param section {SectionModule | SectionModule[]}
      * @param id {number | string} The object containing the data
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 02:50 pm
      * @version 1.1.0 04/23/21 03:59 pm - added support for correctly storing pages (when queried for all site pages)
      * @see domain
      * @see page
      * @see pages
      * @see setup
      * @see invite
      * @see sections
      * @see setDomain
      * @see setPage
      * @see setPages
      * @see setSetup
      * @see setInvite
      * @see setPlatforms
      * @see setSections
      * @see addPage
      * @see pagesToArray
      * @see addSection
      * @see sectionsToArray
      * @see toObject
      */
     public constructor( id?: number, domain?: DomainModule, page?: PageModule, pages?: ISitePage[], setup?: SetupModule, invite?: InviteModule, platforms?: string[], section?: ISectionModule );
     public constructor( id?: number, domain?: DomainModule, page?: PageModule, pages?: ISitePage[], setup?: SetupModule, invite?: InviteModule, platforms?: string[], sections?: ISectionModule[] );
     public constructor( id?: number, domain?: DomainModule, page?: PageModule, pages?: ISitePage[], setup?: SetupModule, invite?: InviteModule, platforms?: string[], sections?: Set<ISectionModule> );
     public constructor( id?: number, domain?: DomainModule, page?: PageModule, pages?: ISitePage[], setup?: SetupModule, invite?: InviteModule, platforms?: Set<string>, section?: ISectionModule );
     public constructor( id?: number, domain?: DomainModule, page?: PageModule, pages?: ISitePage[], setup?: SetupModule, invite?: InviteModule, platforms?: Set<string>, sections?: ISectionModule[] );
     public constructor( id?: number, domain?: DomainModule, page?: PageModule, pages?: ISitePage[], setup?: SetupModule, invite?: InviteModule, platforms?: Set<string>,
                         sections?: Set<ISectionModule> );
     public constructor( id?: number, domain?: DomainModule, page?: PageModule, pages?: unknown, setup?: SetupModule, invite?: InviteModule, platforms?: unknown, sections?: unknown ) {
          super( id ?? null );

          if( SiteModule.CONSOLE_ENABLED ) {
               console.log( `${ SiteModule.CONSOLE_PREFIX } $$$ 1 SITE MODULE`,
                            {
                                 domain, page, pageObj: page.toObject(),
                                 pages                : 'omitted from log',
                                 setup,
                                 invite,
                                 sections             : 'omitted from log',
                                 id,
                            } );
          }

          this.domain    = DomainModule.Build( domain ?? null );
          this.page      = PageModule.Build( page ?? null );
          this.pages     = new Set<ISitePage>();
          this.setup     = SetupModule.Build( setup ?? null );
          this.invite    = InviteModule.Build( invite ?? null );
          this.platforms = new Set<string>();
          this.sections  = new Set<SectionModule>();

          if( SiteModule.CONSOLE_ENABLED ) {
               console.log( `${ SiteModule.CONSOLE_PREFIX } $$$ 2 SITE MODULE`,
                            {
                                 domain  : this.domain,
                                 page    : this.page,
                                 pageObj : this.page.toObject(),
                                 pages   : 'omitted from trace',
                                 setup   : this.setup,
                                 invite  : this.invite,
                                 sections: 'omitted from trace',
                                 id,
                            } );
          }

          if( pages ) {
               if( pages instanceof Set || Array.isArray( pages ) ) {
                    pages.forEach( ( page: Partial<ISitePage> ): void => {
                         if( page?.page && page?.sections ) {
                              const tempPage: PageModule             = PageModule.Build( page.page );
                              const tempSections: Set<SectionModule> = new Set<SectionModule>();

                              page.sections.forEach( ( section: ISectionModule ): void => {
                                   tempSections.add( SectionModule.Build( section ?? null ) );
                              } );

                              this.addPage( tempPage, tempSections );
                         }
                    } );
               } else if( typeof pages === 'object' ) {
                    const localData: Partial<ISitePage> = pages;

                    if( localData?.page && localData?.sections ) {
                         const tempPage: DomainModule           = DomainModule.Build( localData.page );
                         const tempSections: Set<SectionModule> = new Set<SectionModule>();

                         localData.sections?.forEach( ( section: ISectionModule ): void => {
                              tempSections.add( SectionModule.Build( section ?? null ) );
                         } );

                         this.addPage( tempPage, tempSections );
                    }
               }
          }
          if( sections ) {
               if( Array.isArray( sections ) || sections instanceof Set ) {
                    if( SiteModule.CONSOLE_ENABLED ) {
                         console.log( `${ SiteModule.CONSOLE_PREFIX } SITE MODULE`, 'SECTIONS IS ARRAY', { sections } );
                    }
                    sections.forEach( ( section: SectionModule ) => this.addSection( SectionModule.Build( section ) ) );
               } else {
                    if( SiteModule.CONSOLE_ENABLED ) {
                         console.log( `${ SiteModule.CONSOLE_PREFIX } SITE MODULE`, 'SECTIONS IS NOT ARRAY', { sections } );
                    }

                    this.addSection( SectionModule.Build( sections as ISectionModule ) );
               }
          }
          if( platforms ) {
               if( Array.isArray( platforms ) || platforms instanceof Set ) {
                    platforms.forEach( ( platform: string ): void => { this.addPlatform( platform ); } );
               } else if( typeof platforms === 'string' ) {
                    this.addPlatform( platforms );
               }
          }
          if( SiteModule.CONSOLE_ENABLED ) {
               console.log( `${ SiteModule.CONSOLE_PREFIX } SITE MODULE`,
                            {
                                 domain   : this.domain.toObject(),
                                 page     : this.page.toObject(),
                                 pages    : this.pagesToArray(),
                                 setup    : this?.setup?.toObject() ?? null,
                                 invite   : this?.invite?.toObject() ?? null,
                                 platforms: this.platforms ?? null,
                                 sections : this.sectionsToArray(),
                                 id,
                            } );
          }
     }

     /**
      *
      * @return {DomainModule|null}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 02:23 pm
      * @see domain
      * @see page
      * @see pages
      * @see setup
      * @see invite
      * @see sections
      * @see setDomain
      * @see setPage
      * @see setPages
      * @see setSetup
      * @see setInvite
      * @see setPlatforms
      * @see setSections
      * @see addPage
      * @see pagesToArray
      * @see addSection
      * @see sectionsToArray
      * @see toObject
      */
     public get domain(): DomainModule | null {
          return this._data.get( 'domain' );
     }

     /**
      *
      * @param {DomainModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 02:23 pm
      * @see domain
      * @see page
      * @see pages
      * @see setup
      * @see invite
      * @see sections
      * @see setDomain
      * @see setPage
      * @see setPages
      * @see setSetup
      * @see setInvite
      * @see setPlatforms
      * @see setSections
      * @see addPage
      * @see pagesToArray
      * @see addSection
      * @see sectionsToArray
      * @see toObject
      */
     public set domain( value: DomainModule ) {
          this._data.set( 'domain', value );
     }

     /**
      *
      * @return {PageModule | null}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 02:23 pm
      * @see domain
      * @see page
      * @see pages
      * @see setup
      * @see invite
      * @see sections
      * @see setDomain
      * @see setPage
      * @see setPages
      * @see setSetup
      * @see setInvite
      * @see setPlatforms
      * @see setSections
      * @see addPage
      * @see pagesToArray
      * @see addSection
      * @see sectionsToArray
      * @see toObject
      */
     public get page(): PageModule {
          return this._data.get( 'page' );
     }

     /**
      *
      * @param {PageModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 02:23 pm
      * @see domain
      * @see page
      * @see pages
      * @see setup
      * @see invite
      * @see sections
      * @see setDomain
      * @see setPage
      * @see setPages
      * @see setSetup
      * @see setInvite
      * @see setPlatforms
      * @see setSections
      * @see addPage
      * @see pagesToArray
      * @see addSection
      * @see sectionsToArray
      * @see toObject
      */
     public set page( value: PageModule ) {
          this._data.set( 'page', value );
     }

     /**
      *
      * @return {Set<ISitePage>}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 02:23 pm
      * @version 1.1.0 04/23/21 04:26 pm - changed type to correctly store object with page and sections
      * @see domain
      * @see page
      * @see pages
      * @see setup
      * @see invite
      * @see sections
      * @see setDomain
      * @see setPage
      * @see setPages
      * @see setSetup
      * @see setInvite
      * @see setPlatforms
      * @see setSections
      * @see addPage
      * @see pagesToArray
      * @see addSection
      * @see sectionsToArray
      * @see toObject
      */
     public get pages(): Set<ISitePage> {
          return this._data.get( 'pages' );
     }

     /**
      *
      * @param {Set<ISitePage>} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 02:23 pm
      * @version 1.1.0 04/23/21 04:26 pm - changed type to correctly store object with page and sections
      * @see domain
      * @see page
      * @see pages
      * @see setup
      * @see invite
      * @see sections
      * @see setDomain
      * @see setPage
      * @see setPages
      * @see setSetup
      * @see setInvite
      * @see setPlatforms
      * @see setSections
      * @see addPage
      * @see pagesToArray
      * @see addSection
      * @see sectionsToArray
      * @see toObject
      */
     public set pages( value: Set<ISitePage> ) {
          this._data.set( 'pages', value );
     }

     /**
      *
      * @return {SetupModule|null}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 02:23 pm
      * @see domain
      * @see page
      * @see pages
      * @see setup
      * @see invite
      * @see sections
      * @see setDomain
      * @see setPage
      * @see setPages
      * @see setSetup
      * @see setInvite
      * @see setPlatforms
      * @see setSections
      * @see addPage
      * @see pagesToArray
      * @see addSection
      * @see sectionsToArray
      * @see toObject
      */
     public get setup(): SetupModule {
          return this._data.get( 'setup' );
     }

     /**
      *
      * @param {SetupModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 02:23 pm
      * @see domain
      * @see page
      * @see pages
      * @see setup
      * @see invite
      * @see sections
      * @see setDomain
      * @see setPage
      * @see setPages
      * @see setSetup
      * @see setInvite
      * @see setPlatforms
      * @see setSections
      * @see addPage
      * @see pagesToArray
      * @see addSection
      * @see sectionsToArray
      * @see toObject
      */
     public set setup( value: SetupModule ) {
          this._data.set( 'setup', value );
     }

     /**
      *
      * @return {SetupModule|null}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 02:23 pm
      * @see domain
      * @see page
      * @see pages
      * @see setup
      * @see invite
      * @see sections
      * @see setDomain
      * @see setPage
      * @see setPages
      * @see setSetup
      * @see setInvite
      * @see setPlatforms
      * @see setSections
      * @see addPage
      * @see pagesToArray
      * @see addSection
      * @see sectionsToArray
      * @see toObject
      */
     public get invite(): InviteModule {
          return this._data.get( 'invite' );
     }

     /**
      *
      * @param {InviteModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 02:23 pm
      * @see domain
      * @see page
      * @see pages
      * @see setup
      * @see invite
      * @see sections
      * @see setDomain
      * @see setPage
      * @see setPages
      * @see setSetup
      * @see setInvite
      * @see setPlatforms
      * @see setSections
      * @see addPage
      * @see pagesToArray
      * @see addSection
      * @see sectionsToArray
      * @see toObject
      */
     public set invite( value: InviteModule ) {
          this._data.set( 'invite', value );
     }

     /**
      *
      * @return {string[]}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/16/21 07:36 pm
      * @see domain
      * @see page
      * @see pages
      * @see setup
      * @see invite
      * @see platforms
      * @see sections
      * @see setDomain
      * @see setPage
      * @see setPages
      * @see setSetup
      * @see setInvite
      * @see setPlatforms
      * @see setSections
      * @see addPage
      * @see pagesToArray
      * @see addSection
      * @see sectionsToArray
      * @see toObject
      */
     public get platforms(): Set<string> {
          return this._data.get( 'platforms' );
     }

     /**
      *
      * @param {string[]} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/16/21 07:36 pm
      * @see domain
      * @see page
      * @see pages
      * @see setup
      * @see invite
      * @see platforms
      * @see sections
      * @see setDomain
      * @see setPage
      * @see setPages
      * @see setSetup
      * @see setInvite
      * @see setPlatforms
      * @see setSections
      * @see addPage
      * @see pagesToArray
      * @see addSection
      * @see sectionsToArray
      * @see toObject
      */
     public set platforms( value: Set<string> ) {
          this._data.set( 'platforms', value );
     }

     /**
      *
      * @return {Set<SectionModule>} Returns the values for the property
      * @author Isaac Ewing
      * @version 1.0.0 10/03/20 05:01 pm
      * @see domain
      * @see page
      * @see pages
      * @see setup
      * @see invite
      * @see platforms
      * @see sections
      * @see setDomain
      * @see setPage
      * @see setPages
      * @see setSetup
      * @see setInvite
      * @see setPlatforms
      * @see setSections
      * @see addPage
      * @see pagesToArray
      * @see addSection
      * @see sectionsToArray
      * @see toObject
      */
     public get sections(): Set<SectionModule> {
          return this._data.get( 'sections' );
     }

     /**
      *
      * @param {Set<SectionModule>} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 02:23 pm
      * @see domain
      * @see page
      * @see pages
      * @see setup
      * @see invite
      * @see platforms
      * @see sections
      * @see setDomain
      * @see setPage
      * @see setPages
      * @see setSetup
      * @see setInvite
      * @see setPlatforms
      * @see setSections
      * @see addPage
      * @see pagesToArray
      * @see addSection
      * @see sectionsToArray
      * @see toObject
      */
     public set sections( value: Set<SectionModule> ) {
          this._data.set( 'sections', value );
     }

     /**
      *
      * @param {DomainModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 02:23 pm
      * @see domain
      * @see page
      * @see pages
      * @see setup
      * @see invite
      * @see platforms
      * @see sections
      * @see setDomain
      * @see setPage
      * @see setPages
      * @see setSetup
      * @see setInvite
      * @see setPlatforms
      * @see setSections
      * @see addPage
      * @see pagesToArray
      * @see addSection
      * @see sectionsToArray
      * @see toObject
      */
     public setDomain( value: DomainModule ): this {
          this.domain = value;

          return this;
     }

     /**
      *
      * @param {PageModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 02:23 pm
      * @see domain
      * @see page
      * @see pages
      * @see setup
      * @see invite
      * @see platforms
      * @see sections
      * @see setDomain
      * @see setPage
      * @see setPages
      * @see setSetup
      * @see setInvite
      * @see setPlatforms
      * @see setSections
      * @see addPage
      * @see pagesToArray
      * @see addSection
      * @see sectionsToArray
      * @see toObject
      */
     public setPage( value: PageModule ): this {
          this.page = value;

          return this;
     }

     /**
      *
      * @param {Set<ISitePage>} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 02:23 pm
      * @version 1.1.0 04/23/21 04:22 pm - added support for correctly storing pages (page with sections; for get all site pages query)
      * @see domain
      * @see page
      * @see pages
      * @see setup
      * @see invite
      * @see platforms
      * @see sections
      * @see setDomain
      * @see setPage
      * @see setPages
      * @see setSetup
      * @see setInvite
      * @see setPlatforms
      * @see setSections
      * @see addPage
      * @see pagesToArray
      * @see addSection
      * @see sectionsToArray
      * @see toObject
      */
     public setPages( value: Set<ISitePage> ): this {
          this.pages = value;

          return this;
     }

     /**
      *
      * @param {SetupModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 02:23 pm
      * @see domain
      * @see page
      * @see pages
      * @see setup
      * @see invite
      * @see platforms
      * @see sections
      * @see setDomain
      * @see setPage
      * @see setPages
      * @see setSetup
      * @see setInvite
      * @see setPlatforms
      * @see setSections
      * @see addPage
      * @see pagesToArray
      * @see addSection
      * @see sectionsToArray
      * @see toObject
      */
     public setSetup( value: SetupModule ): this {
          this.setup = value;

          return this;
     }

     /**
      *
      * @param {InviteModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 02:23 pm
      * @see domain
      * @see page
      * @see pages
      * @see setup
      * @see invite
      * @see platforms
      * @see sections
      * @see setDomain
      * @see setPage
      * @see setPages
      * @see setSetup
      * @see setInvite
      * @see setPlatforms
      * @see setSections
      * @see addPage
      * @see pagesToArray
      * @see addSection
      * @see sectionsToArray
      * @see toObject
      */
     public setInvite( value: InviteModule ): this {
          this.invite = value;

          return this;
     }

     /**
      *
      * @param {string[]} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/16/21 07:36 pm
      * @see domain
      * @see page
      * @see pages
      * @see setup
      * @see invite
      * @see platforms
      * @see sections
      * @see setDomain
      * @see setPage
      * @see setPages
      * @see setSetup
      * @see setInvite
      * @see setPlatforms
      * @see setSections
      * @see addPage
      * @see pagesToArray
      * @see addSection
      * @see sectionsToArray
      * @see toObject
      */
     public setPlatforms( value: Set<string> ): this {
          this.platforms = value;

          return this;
     }

     /**
      *
      * @param {Set<SectionModule>} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 02:23 pm
      * @see domain
      * @see page
      * @see pages
      * @see setup
      * @see invite
      * @see platforms
      * @see sections
      * @see setDomain
      * @see setPage
      * @see setPages
      * @see setSetup
      * @see setInvite
      * @see setPlatforms
      * @see setSections
      * @see addPage
      * @see pagesToArray
      * @see addSection
      * @see sectionsToArray
      * @see toObject
      */
     public setSections( value: Set<SectionModule> ): this {
          this.sections = value;

          return this;
     }

     /**
      *
      * @param {PageModule} page
      * @param {Set<SectionModule>} sections
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 02:23 pm
      * @version 1.1.0 04/23/21 04:22 pm - added support for correctly storing pages (page with sections; for get all site pages query)
      * @see domain
      * @see page
      * @see pages
      * @see setup
      * @see invite
      * @see platforms
      * @see sections
      * @see setDomain
      * @see setPage
      * @see setPages
      * @see setSetup
      * @see setInvite
      * @see setPlatforms
      * @see setSections
      * @see addPage
      * @see pagesToArray
      * @see addSection
      * @see sectionsToArray
      * @see toObject
      */
     public addPage( page: PageModule, sections: Set<SectionModule> ): this {
          this.pages.add( { page, sections } );

          return this;
     }

     /**
      *
      * @param {boolean} [primitive=false] Indicates if the values should be transformed into their basic types
      * @return {PageModule[] | object[]}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/23/21 07:48 pm
      * @version 1.1.0 02/24/21 05:13 pm - added support for primitive transformation vs normal conversion to array
      * @see domain
      * @see page
      * @see pages
      * @see setup
      * @see invite
      * @see platforms
      * @see sections
      * @see setDomain
      * @see setPage
      * @see setPages
      * @see setSetup
      * @see setInvite
      * @see setPlatforms
      * @see setSections
      * @see addPage
      * @see pagesToArray
      * @see addSection
      * @see sectionsToArray
      */
     public pagesToArray( primitive?: boolean ): ISitePage[] | Record<string, unknown>[] {
          if( this.pages ) {
               if( primitive ) {
                    let localPage;
                    let localSection;
                    let localSections: SectionModule[];
                    const pages = [];

                    this.pages.forEach( ( page: ISitePage ): void => {
                         localPage = page.page.toObject();

                         if( localPage ) {
                              localSections = [];
                              page.sections.forEach( ( section ): void => {
                                   localSection = section.toObject();

                                   if( localSection ) {
                                        localSections.push( localSection );
                                   }
                              } );

                              pages.push( { page: localPage, sections: localSections } );
                         }
                    } );

                    return pages;
               } else {
                    return [ ...this.pages ];
               }
          }

          return null;
     }

     /**
      *
      * @param {SectionModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 02:23 pm
      * @see domain
      * @see page
      * @see pages
      * @see setup
      * @see invite
      * @see platforms
      * @see sections
      * @see setDomain
      * @see setPage
      * @see setPages
      * @see setSetup
      * @see setInvite
      * @see setPlatforms
      * @see setSections
      * @see addPage
      * @see pagesToArray
      * @see addSection
      * @see sectionsToArray
      * @see toObject
      */
     public addSection( value: SectionModule ): this {
          this.sections.add( value );

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/16/21 09:01 pm
      * @see domain
      * @see page
      * @see pages
      * @see setup
      * @see invite
      * @see platforms
      * @see sections
      * @see setDomain
      * @see setPage
      * @see setPages
      * @see setSetup
      * @see setInvite
      * @see setPlatforms
      * @see setSections
      * @see addPage
      * @see pagesToArray
      * @see addSection
      * @see sectionsToArray
      * @see toObject
      */
     public addPlatform( value: string ): this {
          this.platforms.add( value );

          return this;
     }

     /**
      *
      * @param {boolean} [primitive=false] Indicates if the values should be transformed into their basic types
      * @return {SectionModule[] | object[]}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/23/21 07:48 pm
      * @version 1.1.0 02/24/21 05:13 pm - added support for primitive transformation vs normal conversion to array
      * @see domain
      * @see page
      * @see pages
      * @see setup
      * @see invite
      * @see platforms
      * @see sections
      * @see setDomain
      * @see setPage
      * @see setPages
      * @see setSetup
      * @see setInvite
      * @see setPlatforms
      * @see setSections
      * @see addPage
      * @see pagesToArray
      * @see addSection
      * @see sectionsToArray
      */
     public sectionsToArray( primitive?: boolean ): SectionModule[] | Record<string, unknown>[] {
          if( this.sections ) {
               if( primitive ) {
                    let test: Record<string, unknown>;
                    const sections: Record<string, unknown>[] = [];

                    this.sections.forEach( ( section: SectionModule ): void => {
                         test = section.toObject();

                         if( test ) {
                              sections.push( test );
                         }
                    } );

                    return sections;
               } else {
                    return [ ...this.sections ];
               }
          }

          return null;
     }

     /**
      *
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/09/21 03:55 pm
      */
     public store(): this {
          this.domain?.store();
          this.page?.store();
          this.setup?.store();
          this.invite?.store();

          return this;
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 02:23 pm
      * @see domain
      * @see page
      * @see pages
      * @see setup
      * @see invite
      * @see platforms
      * @see sections
      * @see setDomain
      * @see setPage
      * @see setPages
      * @see setSetup
      * @see setInvite
      * @see setPlatforms
      * @see setSections
      * @see addPage
      * @see pagesToArray
      * @see addSection
      * @see sectionsToArray
      * @see toObject
      */
     public toObject(): Record<string, unknown> {
          return {
               id: this.id ?? null,

               domain   : this.domain?.toObject() ?? null,
               page     : this.page?.toObject() ?? null,
               pages    : this.pagesToArray() ?? null,
               setup    : this.setup?.toObject() ?? null,
               invite   : this.invite?.toObject() ?? null,
               platforms: this.platforms ?? null,
               sections : this.sectionsToArray( true ),
          };
     }
}