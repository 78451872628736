import { StyleEnum, TypeEnum } from '../../enum';
import { ISectionModule }      from '../../interface';
import { TitleModule }         from '../../module/api/title.module';
import { DescriptionModule }   from '../../module/api/description.module';
import { DomainModule }        from '../../module/api/domain.module';
import { PageModule }          from '../../module/api/page.module';
import { LinkModule }          from '../../module/api/link.module';
import { EventModule }         from '../../module/api/event.module';
import { LayoutModule }        from '../../module/api/layout.module';
import { GalleryModule }       from '../molecule/gallery.module';
import { ImageModule }         from '../molecule/image.module';
import { MenuModule }          from '../molecule/menu.module';

/**
 * @class SectionModule
 * @extends TitleModule
 * @implements ISectionModule
 * @author Isaac Ewing
 * @version 1.0.0 10/03/20 01:56 pm
 * @version 1.0.0 02/21/21 12:27 pm - updated to extend AComponent (through link module through title module)
 * @version 2.0.0 05/28/21 06:29 pm - converted from AComponent to TitleModule
 */
export class SectionModule extends TitleModule implements ISectionModule {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/22/21 08:36 pm
      */
     protected static readonly CONSOLE_PREFIX: string   = `${ process.env.REACT_APP_CONSOLE_PREFIX_MODULE } SECT ${ process.env.REACT_APP_CONSOLE_SUFFIX_MODULE }`;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/22/21 08:36 pm
      */
     protected static readonly CONSOLE_ENABLED: boolean = true;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 12:27 pm
      */
     protected static readonly API_ROOT_NODE: string    = process.env.REACT_APP_GRAPHQL_GET_SECTION;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 12:27 pm
      */
     protected static readonly DEFAULT_JQUERY: string   = 'section';
     /**
      *
      * @type {TypeEnum}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 12:27 pm
      */
     protected static readonly DEFAULT_TYPE: TypeEnum   = TypeEnum.Layout;
     /**
      *
      * @type {StyleEnum}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 12:27 pm
      */
     protected static readonly DEFAULT_STYLE: StyleEnum = StyleEnum.Light;
     /**
      *
      * @type {number}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 12:27 pm
      */
     protected static readonly DEFAULT_ORDER: number    = 0;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 12:27 pm
      */
     protected static readonly DEFAULT_CURVE: boolean   = false;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 12:27 pm
      */
     protected static readonly DEFAULT_SUBMENU: boolean = false;

     /**
      *
      * @param module {LinkModule} The object containing the data
      * @return {LinkModule|null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 12:58 pm
      * @see Build
      * @see BuildForShortcode
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public static Build( module: SectionModule ): SectionModule;
     public static Build( obj: Partial<ISectionModule> ): SectionModule;
     public static Build( json: string ): SectionModule;
     public static Build( id?: number, type?: TypeEnum, style?: StyleEnum, order?: number, curve?: boolean, layout?: LayoutModule, data?: TitleModule,
                          title?: TitleModule, subtitle?: TitleModule, description?: DescriptionModule, menu?: MenuModule, gallery?: GalleryModule, image?: ImageModule, button?: LinkModule,
                          submenu?: boolean, domain?: DomainModule, page?: DomainModule ): SectionModule;
     public static Build( dataOrId?: unknown, type?: TypeEnum, style?: StyleEnum, order?: number, curve?: boolean, layout?: LayoutModule, data?: TitleModule,
                          title?: TitleModule, subtitle?: TitleModule, description?: DescriptionModule, menu?: MenuModule, gallery?: GalleryModule, image?: ImageModule, button?: LinkModule,
                          submenu?: boolean, domain?: DomainModule, page?: DomainModule ): SectionModule {
          if( dataOrId ) {
               dataOrId = this.mapAPIRootNode( dataOrId, this.API_ROOT_NODE );

               if( dataOrId instanceof SectionModule ) {
                    return dataOrId;
               }
               if( typeof dataOrId === 'object' ) {
                    const localData: Partial<ISectionModule> = dataOrId;

                    if( this.CONSOLE_ENABLED ) {
                         console.log( `${ this.CONSOLE_PREFIX } SECTION MODULE`, { domain: DomainModule.Build( localData?.domain ) ?? null } );
                    }

                    const temp: SectionModule = new SectionModule(
                         localData?.id ?? null, localData?.type ?? null, localData?.style ?? null, localData?.order ?? null, localData?.curve ?? null,
                         LayoutModule.Build( localData?.layout ) ?? null, TitleModule.Build( localData?.data ) ?? null,
                         TitleModule.Build( localData?.title ) ?? null, TitleModule.Build( localData?.subtitle ) ?? null,
                         DescriptionModule.Build( localData?.description ) ?? null, MenuModule.Build( localData?.menu ) ?? null,
                         GalleryModule.Build( localData?.gallery ) ?? null, ImageModule.Build( localData?.image ) ?? null,
                         LinkModule.Build( localData?.button ) ?? null, localData?.submenu ?? null, DomainModule.Build( localData?.domain ) ?? null,
                         PageModule.Build( localData?.page ) ?? null );

                    if( this.CONSOLE_ENABLED ) {
                         console.log( `${ this.CONSOLE_PREFIX } SECTION MODULE`, { localData, temp, type, style, order, curve, data } );
                    }

                    return temp;
               }
               if( typeof dataOrId === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrId ) );
                    } catch( exception ) {
                         if( this.CONSOLE_ENABLED ) {
                              console.log( `${ this.CONSOLE_PREFIX } SECTION MODULE`, 'EXCEPTION, was string - could not convert to json', exception, { dataOrDomain: dataOrId } );
                         }

                         // not a valid json string
                         return new SectionModule( +dataOrId ?? null, type ?? null, style ?? null,
                                                   order ?? null, curve ?? null, LayoutModule.Build( layout ) ?? null, TitleModule.Build( data ) ?? null,
                                                   TitleModule.Build( title ) ?? null, TitleModule.Build( subtitle ) ?? null, DescriptionModule.Build( description ) ?? null,
                                                   MenuModule.Build( menu ) ?? null, GalleryModule.Build( gallery ) ?? null, ImageModule.Build( image ) ?? null,
                                                   LinkModule.Build( button ) ?? null, submenu ?? null, DomainModule.Build( dataOrId ) ?? null,
                                                   DomainModule.Build( page ) ?? null );
                    }
               }
          }

          return null;
     }

     /**
      *
      * @param {number} id
      * @param {TypeEnum} type
      * @param {StyleEnum} style
      * @param {number} order
      * @param {boolean} curve
      * @param {LayoutModule} layout
      * @param {boolean} data
      * @param {TitleModule} title
      * @param {TitleModule} subtitle
      * @param {TitleModule} description
      * @param {MenuModule} menu
      * @param {GalleryModule} gallery
      * @param {ImageModule} image
      * @param {LinkModule} button
      * @param {boolean} submenu
      * @param {DomainModule} domain
      * @param {PageModule} page
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 10/03/20 01:56 pm
      * @version 1.1.0 02/21/21 12:27 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public constructor( id?: number, type?: TypeEnum, style?: string, order?: number, curve?: boolean, layout?: LayoutModule, data?: TitleModule, title?: TitleModule, subtitle?: TitleModule,
                         description?: DescriptionModule, menu?: MenuModule, gallery?: GalleryModule, image?: ImageModule, button?: LinkModule, submenu?: boolean, domain?: DomainModule,
                         page?: PageModule ) {
          super( id ?? null );

          this.type        = type ?? SectionModule.DEFAULT_TYPE;
          this.style       = style ?? SectionModule.DEFAULT_STYLE;
          this.order       = order ?? SectionModule.DEFAULT_ORDER;
          this.curve       = curve ?? SectionModule.DEFAULT_CURVE;
          this.layout      = layout ?? null;
          this.data        = data ?? null;
          this.title       = title ?? null;
          this.subtitle    = subtitle ?? null;
          this.description = description ?? null;
          this.menu        = menu ?? null;
          this.gallery     = gallery ?? null;
          this.image       = image ?? null;
          this.button      = button ?? null;
          this.submenu     = submenu ?? SectionModule.DEFAULT_SUBMENU;
          this.domain      = domain ?? null;
          this.page        = page ?? null;
     }

     /**
      *
      * @return {TypeEnum}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public get type(): TypeEnum {
          return this._data.get( 'type' );
     }

     /**
      *
      * @param {TypeEnum} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public set type( value: TypeEnum ) {
          this._data.set( 'type', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public get style(): string {
          return this._data.get( 'style' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public set style( value: string ) {
          this._data.set( 'style', value );
     }

     /**
      *
      * @return {number}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public get order(): number {
          return this._data.get( 'order' );
     }

     /**
      *
      * @param {number} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public set order( value: number ) {
          this._data.set( 'order', value );
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public get curve(): boolean {
          return this._data.get( 'curve' );
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public set curve( value: boolean ) {
          this._data.set( 'curve', value );
     }

     /**
      *
      * @return {LayoutModule}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public get layout(): LayoutModule {
          return this._data.get( 'layout' );
     }

     /**
      *
      * @param {LayoutModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public set layout( value: LayoutModule ) {
          this._data.set( 'layout', value );
     }

     /**
      *
      * @return {TitleModule}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public get data(): TitleModule {
          return this._data.get( 'data' );
     }

     /**
      *
      * @param {TitleModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public set data( value: TitleModule ) {
          this._data.set( 'data', value );
     }

     /**
      *
      * @return {TitleModule}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public get title(): TitleModule {
          return this._data.get( 'title' );
     }

     /**
      *
      * @param {TitleModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public set title( value: TitleModule ) {
          this._data.set( 'title', value );
     }

     /**
      *
      * @return {TitleModule}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public get subtitle(): TitleModule {
          return this._data.get( 'subtitle' );
     }

     /**
      *
      * @param {TitleModule} value@return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      *
      */
     public set subtitle( value: TitleModule ) {
          this._data.set( 'subtitle', value );
     }

     /**
      *
      * @return {DescriptionModule}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public get description(): DescriptionModule {
          return this._data.get( 'description' );
     }

     /**
      *
      * @param {DescriptionModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public set description( value: DescriptionModule ) {
          this._data.set( 'description', value );
     }

     /**
      *
      * @return {MenuModule}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public get menu(): MenuModule {
          return this._data.get( 'menu' );
     }

     /**
      *
      * @param {MenuModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public set menu( value: MenuModule ) {
          this._data.set( 'menu', value );
     }

     /**
      *
      * @return {GalleryModule}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public get gallery(): GalleryModule {
          return this._data.get( 'gallery' );
     }

     /**
      *
      * @param {GalleryModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public set gallery( value: GalleryModule ) {
          this._data.set( 'gallery', value );
     }

     /**
      *
      * @return {ImageModule}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public get image(): ImageModule {
          return this._data.get( 'image' );
     }

     /**
      *
      * @param {ImageModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public set image( value: ImageModule ) {
          this._data.set( 'image', value );
     }

     /**
      *
      * @return {LinkModule}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public get button(): LinkModule {
          return this._data.get( 'button' );
     }

     /**
      *
      * @param {LinkModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public set button( value: LinkModule ) {
          this._data.set( 'button', value );
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public get submenu(): boolean {
          return this._data.get( 'submenu' );
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public set submenu( value: boolean ) {
          this._data.set( 'submenu', value );
     }

     /**
      *
      * @return {Set<EventModule>}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/29/21 12:28 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public get events(): Set<EventModule> {
          return this._data.get( 'events' );
     }

     /**
      *
      * @param {Set<EventModule>} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/29/21 12:28 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public set events( value: Set<EventModule> ) {
          this._data.set( 'events', value );
     }

     /**
      *
      * @return {DomainModule}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public get domain(): DomainModule {
          return this._data.get( 'domain' );
     }

     /**
      *
      * @param {DomainModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public set domain( value: DomainModule ) {
          this._data.set( 'domain', value );
     }

     /**
      *
      * @return {PageModule}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public get page(): PageModule {
          return this._data.get( 'page' );
     }

     /**
      *
      * @param {PageModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public set page( value: PageModule ) {
          this._data.set( 'page', value );
     }

     /**
      *
      * @param {TypeEnum} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public setType( value: TypeEnum ): this {
          this.type = value;

          return this;
     }

     /**
      *
      * @param {StyleEnum} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public setStyle( value: StyleEnum ): this {
          this.style = value;

          return this;
     }

     /**
      *
      * @param {number} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public setOrder( value: number ): this {
          this.order = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public setCurve( value: boolean ): this {
          this.curve = value;

          return this;
     }

     /**
      *
      * @param {LayoutModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public setLayout( value: LayoutModule ): this {
          this.layout = value;

          return this;
     }

     /**
      *
      * @param {TitleModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public setData( value: TitleModule ): this {
          this.data = value;

          return this;
     }

     /**
      *
      * @param {TitleModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public setTitle( value: TitleModule ): this {
          this.title = value;

          return this;
     }

     /**
      *
      * @param {TitleModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public setSubtitle( value: TitleModule ): this {
          this.subtitle = value;

          return this;
     }

     /**
      *
      * @param {DescriptionModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public setDescription( value: DescriptionModule ): this {
          this.description = value;

          return this;
     }

     /**
      *
      * @param {MenuModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public setMenu( value: MenuModule ): this {
          this.menu = value;

          return this;
     }

     /**
      *
      * @param {GalleryModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public setGallery( value: GalleryModule ): this {
          this.gallery = value;

          return this;
     }

     /**
      *
      * @param {ImageModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public setImage( value: ImageModule ): this {
          this.image = value;

          return this;
     }

     /**
      *
      * @param {LinkModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public setButton( value: LinkModule ): this {
          this.button = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public setSubmenu( value: boolean ): this {
          this.submenu = value;

          return this;
     }

     /**
      *
      * @param {Set<EventModule>} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public setEvents( value: Set<EventModule> ): this {
          this.events = value;

          return this;
     }

     /**
      *
      * @param {DomainModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public setDomain( value: DomainModule ): this {
          this.domain = value;

          return this;
     }

     /**
      *
      * @param {DomainModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public setPage( value: DomainModule ): this {
          this.page = value;

          return this;
     }

     /**
      *
      * @return {SectionModule}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public enableSubmenu(): this {
          this.submenu = true;

          return this;
     }

     /**
      *
      * @return {SectionModule}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public disableSubmenu(): this {
          this.submenu = false;

          return this;
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 01:11 pm
      * @see domain
      * @see page
      * @see type
      * @see style
      * @see order
      * @see curve
      * @see layout
      * @see data
      * @see title
      * @see subtitle
      * @see description
      * @see menu
      * @see gallery
      * @see image
      * @see button
      * @see submenu
      * @see setDomain
      * @see setPage
      * @see setType
      * @see setStyle
      * @see setOrder
      * @see setCurve
      * @see setLayout
      * @see setData
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setMenu
      * @see setGallery
      * @see setImage
      * @see setButton
      * @see setSubmenu
      * @see enableSubmenu
      * @see disableSubmenu
      */
     public toObject(): Record<string, unknown> {
          return {
               id         : this.id ?? null,
               type       : this.type ?? SectionModule.DEFAULT_TYPE,
               style      : this.style ?? SectionModule.DEFAULT_STYLE,
               order      : this.order ?? SectionModule.DEFAULT_ORDER,
               curve      : this.curve ?? SectionModule.DEFAULT_CURVE,
               layout     : this.layout?.toObject() ?? null,
               data       : this.data?.toObject() ?? null,
               title      : this.title?.toObject() ?? null,
               subtitle   : this.subtitle?.toObject() ?? null,
               description: this.description?.toObject() ?? null,
               menu       : this.menu?.toObject() ?? null,
               gallery    : this.gallery?.toObject() ?? null,
               image      : this.image?.toObject() ?? null,
               button     : this.button?.toObject() ?? null,
               submenu    : this.submenu ?? SectionModule.DEFAULT_SUBMENU,
               events     : this.events ?? null,
               domain     : this.domain?.toObject() ?? null,
               page       : this.page?.toObject() ?? null,
          };
     }
}