import { AGraphql } from '../abstract/graphql/graphql.abstract';

/**
 * @class UserGraphql
 * @extends AGraphql
 * @author Isaac Ewing
 * @version 1.0.0 05/09/21 12:57 pm
 */
export class UserGraphql extends AGraphql {
     /**
      *
      * @param {number} user
      * @return {string}
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/09/21 12:57 pm
      */
     public static Query( user?: number ): string {
          return `
               query {
                    getUser( id: ${ user ?? null } ) {
                         id
                         username
                         email
                         ${ this.buildAbout() }
                         ${ this.buildSocial() }
                         ${ this.buildDomain() }
                         ${ this.buildSetup() }
                         ${ this.buildSetting() }
                         ${ this.buildCompany() }
                    }
               }
          `;
     }
}