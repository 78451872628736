import axios, { AxiosResponse } from 'axios';
import { IModule, IResponse }   from '../../interface';
import { ReportModule }         from '../../module';
import { ConsoleManager }       from '../console.manager';
import { URLManager }           from '../url.manager';

/**
 * @class EmailManager
 * @author Isaac Ewing
 * @version 1.0.0 02/15/21 03:34 pm
 * @version 1.1.0 05/07/21 02:11 pm - changed base class to AModule
 * @version 1.2.0 05/07/21 04:53 pm - removed the base class
 */
export class EmailManager {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 01:47 pm
      */
     protected static readonly CONSOLE_PREFIX: string   = `${ process.env.REACT_APP_CONSOLE_PREFIX_MANAGER } EMAI ${ process.env.REACT_APP_CONSOLE_SUFFIX_MANAGER }`;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 01:47 pm
      */
     protected static readonly CONSOLE_ENABLED: boolean = true;

     /**
      *
      * @param {ISupportAPI} module
      * @return {Promise<AxiosResponse<IResponse>>}
      * @constructor
      * @async
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/15/21 03:34 pm
      * @version 1.1.0 06/05/21 01:52 pm - changed response from node-fetch to axios
      * @see Email
      * @see Contact
      * @see Report
      */
     public static async Contact( module: IModule ): Promise<AxiosResponse<IResponse>> {
          const response: AxiosResponse<IResponse> = await axios.get( URLManager.Contact ?? null, { params: module.toObject() } ?? null );

          if( this.CONSOLE_ENABLED ) {
               ConsoleManager.Warn( this.CONSOLE_PREFIX ?? null, 'EMAIL MANAGER REPORT', 'the report has been sent to the api...', { response } );
          }

          return response;
     }

     /**
      *
      * @param {ISupportAPI} data
      * @return {Promise<AxiosResponse<IResponse>>}
      * @constructor
      * @async
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/04/20 07:10 pm
      * @version 1.1.0 06/05/21 01:52 pm - changed response from node-fetch to axios
      * @see Email
      * @see Contact
      * @see Report
      */
     public static async Email( data: IModule ): Promise<AxiosResponse<IResponse>> {
          const response: AxiosResponse<IResponse> = await axios.get( URLManager.Contact ?? null, { params: data.toObject() } ?? null );

          if( this.CONSOLE_ENABLED ) {
               ConsoleManager.Warn( this.CONSOLE_PREFIX ?? null, 'EMAIL MANAGER REPORT', 'the report has been sent to the api...', { response } );
          }

          return response;
     }

     /**
      *
      * @param {ReportModule} report
      * @return {Promise<AxiosResponse<IResponse>>}
      * @constructor
      * @async
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 01:52 pm
      * @see Email
      * @see Contact
      * @see Report
      */
     public static async Report( report: ReportModule ): Promise<AxiosResponse<IResponse>> {
          const response: AxiosResponse<IResponse> = await axios.post( URLManager.Report ?? null, report.toObject() ?? null );

          if( this.CONSOLE_ENABLED ) {
               ConsoleManager.Warn( this.CONSOLE_PREFIX ?? null, 'EMAIL MANAGER REPORT', 'the report has been sent to the api...', { response } );
          }

          return response;
     }
}