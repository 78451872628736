import React                                        from 'react';
import PropTypes                                    from 'prop-types';
import jQuery                                       from 'jquery';
import { IPageProps, IPageState, IShortcodeModule } from '../api/com/ewing/social/interface';
import { ShortcodeActionEnum }                      from '../api/com/ewing/social/enum';
import { ShortcodeModule }                          from '../api/com/ewing/social/component';
import { TitleModule, SiteModule }                  from '../api/com/ewing/social/module';
import { ShortcodeObserver }                        from '../api/com/ewing/social/observer';
import { Util }                                     from '../api/com/ewing/social/tool';
import {
     CacheManager, ConsoleManager,
     ShortcodeManager, URLManager,
}                                                   from '../api/com/ewing/social/manager';
import { SEO }                                      from './atom/SEO';
import { Ad }                                       from './molecule/Ad';
import { Header, Footer }                           from './organism';
import 'foundation-sites';
import '../scss/App.scss';

/**
 * @class Page
 * @extends React.Component
 * @author Isaac Ewing
 * @version 1.0.0 05/02/21 03:13 pm
 */
export class Page extends React.Component {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/04/21 05:07 pm
      */
     protected static readonly CONSOLE_PREFIX: string   = `${ process.env.REACT_APP_CONSOLE_PREFIX_COMPONENT } PAGE ${ process.env.REACT_APP_CONSOLE_SUFFIX_COMPONENT }`;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/04/21 05:07 pm
      */
     protected static readonly CONSOLE_ENABLED: boolean = true;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/02/21 03:13 pm
      */
     protected static readonly HASH: string             = `page-${ Util.registerHash() }`;
     /**
      *
      * @type {unknown}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 10:35 pm
      */
     //protected static readonly QUERY_CLIENT: QueryClient    = new QueryClient();
     /**
      *
      * @type {JSX.Element}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 10:35 pm
      */
     //protected static readonly QUERY_COMPONENT: JSX.Element = <QueryClientProvider client={ Page.QUERY_CLIENT } />;
     /**
      *
      * @type {Set<JSX.Element>}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/02/21 03:13 pm
      */
     protected static query: Set<JSX.Element> = new Set<JSX.Element>();
     /**
      *
      * @type {number}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/02/21 03:13 pm
      */
     protected static total                   = 0;
     /**
      *
      * @type {any}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/02/21 03:13 pm
      */
     public static propTypes: unknown;
     /**
      *
      * @type {any}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/02/21 03:13 pm
      */
     protected popup: unknown;
     /**
      *
      * @type {string}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/02/21 03:13 pm
      */
     protected componentClass: string;
     /**
      *
      * @type {Partial<IPageProps>}
      * @readonly
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/31/21 12:29 pm
      */
     public readonly props: Partial<IPageProps>;
     /**
      *
      * @type {Partial<IPageState>}
      * @readonly
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/31/21 12:29 pm
      */
     public readonly state: Partial<IPageState>;

     public readonly title: TitleModule;

     /**
      *
      * @param {Partial<IShortcodeModule>} template
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/02/21 03:13 pm
      * @version 1.1.0 06/02/21 03:19 pm - added support for method overload
      */
     protected onShortcodeObserver( template: Partial<IShortcodeModule> ): void;
     protected onShortcodeObserver( module: ShortcodeModule ): void;
     protected onShortcodeObserver( data: unknown ): void {
          const module: ShortcodeModule = data as ShortcodeModule;
          const shortcodeId             = `${ module.type }-${ module.id }-${ module.hash }`;
          const output: unknown         = {
               total : Page.total,
               module: module,
               cache : CacheManager.GetCachesToArray( module.type ),
               query : [ ...Page.query ],
          };

          switch( module.action ) {
               case ShortcodeActionEnum.Build:
                    Page.total++;
                    Page.query.add( module.query );
                    //this.state.queries = Page.query;
                    this.setState( { queries: Page.query } );

                    if( Page.CONSOLE_ENABLED ) {
                         ConsoleManager.Gold( Page.CONSOLE_PREFIX, 'BUILD', shortcodeId ?? null, output ?? null );
                    }
                    break;
               case ShortcodeActionEnum.Add:
                    if( Page.CONSOLE_ENABLED ) {
                         ConsoleManager.Gold( Page.CONSOLE_PREFIX, 'ADD', shortcodeId ?? null, output ?? null );
                    }
                    break;
               case ShortcodeActionEnum.Query:
                    if( Page.CONSOLE_ENABLED ) {
                         ConsoleManager.Gold( Page.CONSOLE_PREFIX, 'QUERY', shortcodeId ?? null, output ?? null );
                    }
                    break;
               case ShortcodeActionEnum.Cache:
                    if( Page.CONSOLE_ENABLED ) {
                         ConsoleManager.Gold( Page.CONSOLE_PREFIX, 'CACHE', shortcodeId ?? null, output ?? null );
                    }
                    break;
          }
     }

     /**
      *
      * @param props {any}
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/02/21 03:13 pm
      */
     public constructor( props: IPageProps ) {
          super( props );

          this.state               = {
               module : props?.module ?? null,
               user   : null,
               loading: true,
               site   : null,
               queries: null,
          };
          this.popup               = null;
          this.componentClass      = props?.className;
          this.onShortcodeObserver = this.onShortcodeObserver.bind( this );
          ShortcodeManager.register();
          ShortcodeObserver.subscribe( Page.HASH, this.onShortcodeObserver );

          if( Page.CONSOLE_ENABLED ) {
               ConsoleManager.Log( Page.CONSOLE_PREFIX, `+++>> Page`, `called constructor [ ${ this.componentClass } ]...`, { state: this.state } );
          }
     }

     /**
      *
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/02/21 03:13 pm
      */
     public componentDidMount(): void {
          if( Page.CONSOLE_ENABLED ) {
               ConsoleManager.Log( Page.CONSOLE_PREFIX, `--->> Page`, `called COMPONENT DID MOUNT [ ${ this.componentClass } ]...`, { props: this.props } );
          }

          try {
               fetch( `${ URLManager.OAuth }/wake-up` )
                    .then( res => {
                         if( res.ok ) {
                              this.setState( { loading: false } );
                         } else {
                              if( Page.CONSOLE_ENABLED ) {
                                   ConsoleManager.Warn( Page.CONSOLE_PREFIX, 'Page', 'SOCKET IO COULD NOT WAKE UP...', { props: this.props } );
                              }
                         }
                    } );
          } catch( exception: unknown ) {
               if( Page.CONSOLE_ENABLED ) {
                    ConsoleManager.Error( Page.CONSOLE_PREFIX, 'Page', 'SOCKET io could not wake up, url manager could not load on refresh...', { props: this.props } );
               }
          }
          try {
               ShortcodeManager.register();
          } catch( exception: unknown ) {
               if( Page.CONSOLE_ENABLED ) {
                    ConsoleManager.Error( Page.CONSOLE_PREFIX, 'Page', 'SHORTCODE manager could not call register, it could not load on refresh...', { props: this.props } );
               }
          }

          jQuery( document ).foundation();

          document.documentElement.classList.remove( 'no-js' );
     }

     /**
      *
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/02/21 03:13 pm
      */
     public componentWillUnmount(): void {
          if( Page.CONSOLE_ENABLED ) {
               ConsoleManager.Log( Page.CONSOLE_PREFIX, '<<--- Page', `called COMPONENT WILL UNMOUNT [ ${ this.componentClass } ]...`, { props: this.props } );
          }

          ShortcodeObserver.unsubscribe( Page.HASH );
     }

     /**
      *
      * @param {Readonly<any>} prevProps
      * @param {Readonly<any>} prevState
      * @param snapshot
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/02/21 03:13 pm
      */
     public componentDidUpdate( prevProps: Readonly<Record<string, unknown>>, prevState: Readonly<unknown>, snapshot?: unknown ): void {
          if( prevProps.children !== this.props.children ) {
               this.setState( { children: this.props.children } );

               if( Page.CONSOLE_ENABLED ) {
                    ConsoleManager.Log( Page.CONSOLE_PREFIX, 'Page', `component props change...`, { previous: prevProps, prevState, snapshot, current: this.props } );
               }
          } else {
               if( Page.CONSOLE_ENABLED ) {
                    ConsoleManager.Log( Page.CONSOLE_PREFIX, 'Page', `component props same...` );
               }
          }
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/02/21 03:13 pm
      */
     public render(): JSX.Element {
          if( Page.CONSOLE_ENABLED ) {
               ConsoleManager.Log( Page.CONSOLE_PREFIX, 'Page', `called render [ ${ this.componentClass } ]...` );
          }

          return (
               <>
                    <Header module={ this.props.module } />
                    <SEO title={ Util.buildMetaTitle( this.props.module?.page?.text ?? 'PAGE.TEXT not set... check page component state' ) } />

                    { this.props.children }
                    <Footer />
                    <Ad />
               </>
          );
     }
}

Page.propTypes = {
     module   : PropTypes.instanceOf( SiteModule ),
     className: PropTypes.string.isRequired,
     children : PropTypes.node.isRequired,
};

export default Page;
