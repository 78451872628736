import { TitleModule }    from './title.module';
import { ICompany }       from '../../interface';
import { StorageManager } from '../../manager';

/**
 * @class CompanyModule
 * @extends TitleModule
 * @implements ICompany
 * @author Isaac Ewing
 * @version 1.0.0 05/25/21 03:19 pm
 * @version 2.0.0 05/28/21 12:53 pm - converted from AModule to TitleModule
 */
export class CompanyModule extends TitleModule implements ICompany {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 03:19 pm
      */
     protected static readonly CONSOLE_PREFIX: string   = `${ process.env.REACT_APP_CONSOLE_PREFIX_MODULE } COMP ${ process.env.REACT_APP_CONSOLE_SUFFIX_MODULE }`;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 03:19 pm
      */
     protected static readonly CONSOLE_ENABLED: boolean = false;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 03:19 pm
      */
     protected static readonly API_ROOT_NODE: string    = process.env.REACT_APP_GRAPHQL_GET_COMPANY;
     /**
      *
      * @type {CompanyModule}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 03:19 pm
      */
     protected static fromStorage: CompanyModule;

     /**
      *
      * @param module {CompanyModule} The object containing the data
      * @return {CompanyModule | null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 03:19 pm
      * @see Build
      * @see id
      * @see first
      * @see last
      * @see FromStorage
      */
     public static Build( module: CompanyModule ): CompanyModule;
     public static Build( obj: Partial<ICompany> ): CompanyModule;
     public static Build( json: string ): CompanyModule;
     public static Build( id?: number, slug?: string, text?: string ): CompanyModule;
     public static Build( dataOrId?: unknown, slug?: string, text?: string ): CompanyModule {
          if( dataOrId ) {
               dataOrId = this.mapAPIRootNode( dataOrId, this.API_ROOT_NODE );

               if( dataOrId instanceof CompanyModule ) {
                    return dataOrId;
               }
               if( typeof dataOrId === 'object' ) {
                    const localData: Partial<ICompany> = dataOrId;

                    return new CompanyModule( +localData?.id ?? null, localData?.slug ?? null, localData?.text ?? null );
               }
               if( typeof dataOrId === 'number' ) {
                    return new CompanyModule( dataOrId ?? null, slug ?? null, text ?? null );
               }
               if( typeof dataOrId === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrId ) );
                    } catch( exception ) {
                         // not a valid json string
                         return new CompanyModule( +dataOrId ?? null, slug ?? null, text ?? null );
                    }
               }
          }

          return null;
     }

     /**
      *
      * @return {CompanyModule | null}
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 03:19 pm
      * @see id
      * @see slug
      * @see text
      * @see setSlug
      * @see setName
      */
     public static FromStorage(): CompanyModule | null {
          if( this.fromStorage ) {
               return this.fromStorage;
          }
          if( StorageManager.companyModel ) {
               this.fromStorage = CompanyModule.Build( StorageManager.companyModel ?? null );
          } else if( StorageManager.aboutId && StorageManager.aboutFirst && StorageManager.aboutLast ) {
               this.fromStorage = new CompanyModule( StorageManager.companyId ?? null, StorageManager.companySlug ?? null, StorageManager.companyText ?? null );
          }

          return this.fromStorage ?? null;
     }

     /**
      *
      * @param {number} id
      * @param {string} slug
      * @param {string} text
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 03:19 pm
      * @see id
      * @see slug
      * @see text
      * @see setSlug
      * @see setName
      */
     public constructor( id?: number, slug?: string, text?: string ) {
          super( id ?? null, text ?? null );

          this.slug = slug ?? null;
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 03:19 pm
      * @see id
      * @see slug
      * @see text
      * @see setSlug
      * @see setName
      */
     public get slug(): string {
          return this._data.get( 'short' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 03:19 pm
      * @see id
      * @see slug
      * @see text
      * @see setSlug
      * @see setName
      */
     public set slug( value: string ) {
          this._data.set( 'short', value );
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 03:19 pm
      * @see id
      * @see slug
      * @see text
      * @see setSlug
      * @see setName
      */
     public setSlug( value: string ): this {
          this.slug = value;

          return this;
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 03:19 pm
      * @see id
      * @see slug
      * @see text
      * @see setSlug
      * @see setName
      */
     public store(): this {
          StorageManager.companyModel = this;
          StorageManager.companyId    = this.id;
          StorageManager.companySlug  = this.slug;
          StorageManager.companyText  = this.text;

          return this;
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 03:19 pm
      * @see id
      * @see slug
      * @see text
      * @see setSlug
      * @see setName
      */
     public toObject(): Record<string, unknown> {
          return {
               id  : this.id,
               slug: this.slug,
               text: this.text,
          };
     }
}