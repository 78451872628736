import { FormEvent }     from 'react';
import { ISectionProps } from '../../../api/com/ewing/social/interface';
import { InputModule }   from '../../../api/com/ewing/social/module/';
import { Util }          from '../../../api/com/ewing/social/tool';
import { BaseContact }   from '../../atom/contact';
import { Description }   from '../../atom/Description';
import { Divider }       from '../../atom/Divider';
import { Subtitle }      from '../../atom/Subtitle';
import { Title }         from '../../atom/Title';

/**
 * @class Contact1
 * @extends BaseContact
 * @author Isaac Ewing
 * @version 1.0.0 02/04/21 02:36 pm
 * @classdesc This class handles the default contact functionality for the site
 */
export class Contact1 extends BaseContact {
    /**
     *
     * @param event {FormEvent}
     * @return {void}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/05/21 09:51 pm
     */
    protected onSubmit( event: FormEvent<HTMLFormElement> ): void {
        this.onSubmitContact( event );
    }

    /**
     *
     * @param props {any}
     * @return {void}
     * @constructor
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 01/30/21 11:30 am - documented
     */
    public constructor( props: ISectionProps ) {
        super( props );

        this.state = {
            hash     : Util.registerHash(),
            className: new Set<string>( [ 'grid-x', Contact1.COMPONENT_CLASS ] ),
            data     : {
                name   : InputModule.BuildForName(),
                email  : InputModule.BuildForEmail(),
                message: InputModule.BuildForMessage(),
            },
        };

        props.module?.button?.enableSubmit();
        this.onSubmit        = this.onSubmit.bind( this );
        this.onResponseEmail = this.onResponseEmail.bind( this );
        this.registerProps( props.module ?? null );
    }

    /**
     *
     * @return {JSX.Element}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 01/30/21 11:30 am - documented
     */
    public render(): JSX.Element {
        let title;
        let subtitle;
        let divider;

        if( this.props?.module?.title ) {
            title = <Title module={ this.props?.module?.title } />;
        }
        if( this.props?.module?.subtitle ) {
            subtitle = <Subtitle module={ this.props?.module?.subtitle } />;
        }
        if( title && subtitle ) {
            divider = <Divider />;
        }

        return (
            <section { ...this.registerSectionProps( this.props.module ) }>
                <div className="grid-container content">
                    <div className="grid-x align-center">
                        <div className="cell small-12 medium-12">
                            { subtitle }
                            { divider }
                            { title }
                        </div>
                        <div className="cell small-10 medium-8">
                            <Description module={ this.props?.module?.description } />
                        </div>
                        <div className="cell small-12 medium-6 large-5 form-section">
                            <form id={ Contact1.getHashId( this.state.hash, 'form' ) } className={ Contact1.CLASS_FORM } onSubmit={ this.onSubmit } data-abide={ true } noValidate>
                                <div className="grid-x">
                                    { this.buildInput( this.state.data.name ?? null ) }
                                    { this.buildInput( this.state.data.email ?? null ) }
                                    { this.buildInput( this.state.data.message ?? null ) }
                                    { this.buildButton() }
                                    { this.buildFeedback() }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}