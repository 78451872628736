import jQuery                                     from 'jquery';
import { ISectionProps, ISectionStateDimensions } from '../../../api/com/ewing/social/interface';
import { BaseSection }                            from '../../atom/BaseSection';
import { Button }                                 from '../../atom/Button';
import { Description }                            from '../../atom/Description';
import { Divider }                                from '../../atom/Divider';
import { Particle2 }                              from '../../atom/particle';
import { Subtitle }                               from '../../atom/Subtitle';
import { Title }                                  from '../../atom/Title';

/**
 * @class Layout3
 * @extends BaseSection
 * @author Isaac Ewing
 * @version 1.0.0 01/30/21 11:30 am - documented
 */
export class Layout3 extends BaseSection {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am
      */
     protected static readonly COMPONENT_CLASS: string     = 'layout-3';
     /**
      *
      * @type {number}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 04/09/21 08:10 pm
      */
     protected static readonly CANVAS_SMALL_HEIGHT: number = 200;
     /**
      *
      * @type {number}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 04/09/21 08:10 pm
      */
     protected static readonly CANVAS_LARGE_HEIGHT: number = 400;

     /**
      *
      * @param props {ISectionProps}
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am - documented
      */
     public constructor( props: ISectionProps ) {
          super( props );

          this.state = {
               className : new Set<string>( [ 'grid-x', Layout3.COMPONENT_CLASS ] ),
               dimensions: {
                    width : 0,
                    height: 0,
               },
          };

          this.registerStyle( props?.module );
          this.registerCurve( props?.module );
          this.registerSubmenus( props?.module );
          this.registerClasses( props?.module );
     }

     /**
      *
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am
      * documented
      */
     public componentDidMount(): void {
          const section: JQuery<HTMLElement>        = jQuery( `.${ Layout3.COMPONENT_CLASS }` );
          const dimensions: ISectionStateDimensions = {
               width : section[ 0 ].clientWidth,
               height: section[ 0 ].clientWidth >= 640 ? Layout3.CANVAS_LARGE_HEIGHT : Layout3.CANVAS_SMALL_HEIGHT,
          };

          this.setState( { dimensions: dimensions } );
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am
      * documented
      */
     public render(): JSX.Element {
          let particles: JSX.Element;
          let title: JSX.Element;
          let divider: JSX.Element;
          let subtitle: JSX.Element;
          let description: JSX.Element;
          let button: JSX.Element;

          if( this.props?.module?.title ) {
               title = <Title module={ this.props?.module?.title } />;
          }
          if( this.props?.module?.subtitle ) {
               particles = <Particle2 dimensions={ this.state.dimensions } />;
               divider   = <Divider />;
               subtitle  = <Subtitle module={ this.props?.module?.subtitle } />;
          }
          if( this.props?.module?.description ) {
               description = <Description module={ this.props?.module?.description } />;
          }
          if( this.props?.module?.button ) {
               button = <Button module={ this.props?.module?.button } />;
          }

          return (
               <section { ...this.registerSectionProps( this.props.module ) }>
                    { particles }
                    <div className="grid-container content">
                         <div className="grid-x align-center">
                              <div className="cell small-12">
                                   { subtitle }
                                   { divider }
                                   { title }
                              </div>
                              <div className="cell small-10 medium-8">
                                   { description }
                                   { button }
                              </div>
                         </div>
                    </div>
               </section>
          );
     }
}

export default Layout3;
