/**
 * @enum ActionEnum
 * @author Isaac Ewing
 * @version 1.0.0 02/07/21 04:41 pm
 */
export enum ActionEnum {
     Subscribe   = 'subscribe',
     Unsubscribe = 'unsubscribe',
     Pause       = 'pause'
}

/**
 * @enum CampaignEnum
 * @author Isaac Ewing
 * @version 1.0.0 02/07/21 04:41 pm
 */
export enum CampaignEnum {
     General  = 'general',
     Alert    = 'alert',
     Update   = 'update',
     Birthday = 'birthday',
     Sale     = 'sale'
}