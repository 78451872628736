import React    from 'react';
import jQuery   from 'jquery';
import { Util } from '../../../api/com/ewing/social/tool';

/**
 * @class Infolinks
 * @extends React.PureComponent
 * @author Isaac Ewing
 * @version 1.0.0 05/03/21 12:07 pm
 */
export class PopAds extends React.PureComponent {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/03/21 04:33 pm
      */
     protected static readonly DEFAULT_HASH: string = Util.registerHash();
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/03/21 04:33 pm
      */
     protected static readonly DEFAULT_ID: string   = `ad-${ PopAds.DEFAULT_HASH }`;
     /**
      *
      * @type {number}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/03/21 12:52 pm
      */
     protected static DEFAULT_UPDATES: number       = 0;
     public state: any;

     /**
      *
      * @return {void}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/03/21 12:52 pm
      */
     protected static registerScript(): void {
          const vars: HTMLScriptElement   = document.createElement( 'script' );
          const script: HTMLScriptElement = document.createElement( 'script' );
          const test: HTMLScriptElement   = document.createElement( 'script' );

          vars.innerHTML = `var infolinks_pid = ${ process.env.REACT_APP_API_PopAds_ID };`;
          vars.innerHTML += `var infolinks_wsid = ${ process.env.REACT_APP_API_PopAds_WEBSITE };`;
          script.src     = process.env.REACT_APP_API_PopAds_SOURCE;
          script.async   = true;
          test.innerHTML = `console.warn('ISAAC EWING');`;

          jQuery( `#${ PopAds.DEFAULT_ID }` ).empty().append( vars, script, test );
     }

     /**
      *
      * @param props
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/03/21 12:07 pm
      */
     public constructor( props ) {
          super( props );

          this.state = {
               updates: PopAds.DEFAULT_UPDATES,
          };
     }

     /**
      *
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/03/21 04:33 pm
      */
     public componentDidMount(): void {
          PopAds.DEFAULT_UPDATES++;
          //PopAds.registerScript();
          this.setState( { updates: PopAds.DEFAULT_UPDATES } );
          console.log( '[[ IL ]] CALLED component did mount for the PopAds ad...' );
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/03/21 12:07 pm
      */
     public render(): JSX.Element {
          return <div id={ PopAds.DEFAULT_ID } data-ad={ true } data-updates={ this.state.updates } />;
     }
}
