import {
    GoogleMap, InfoWindow, InfoWindowProps, LoadScript, Marker, MarkerProps,
}                        from '@react-google-maps/api';
import { MapModule }     from '../../../api/com/ewing/social/component';
import { ISectionProps } from '../../../api/com/ewing/social/interface';
import { Util }          from '../../../api/com/ewing/social/tool';
import { BaseSection }   from '../../atom/BaseSection';
import { Subtitle }      from '../../atom/Subtitle';
import { Title }         from '../../atom/Title';

/**
 * @class Map1
 * @extends BaseSection
 * @author Isaac Ewing
 * @version 1.0.0 01/30/21 11:30 am - documented
 * @classdesc This class handles rendering the google map data into interactive google maps
 */
export class Map1 extends BaseSection {
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 01/30/21 11:30 am - documented
     */
    protected static readonly COMPONENT_CLASS: string = 'map-1';

    /**
     *
     * @param window {InfoWindow}
     * @return {void}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 07/31/21 03:38 pm
     */
    protected onWindowLoad( window: google.maps.InfoWindow ): void {
        console.log( 'OVERLAY - LOAD:', window );
    }

    /**
     *
     * @param window {InfoWindow}
     * @return {void}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 07/31/21 03:38 pm
     */
    protected onWindowUnload( window: google.maps.InfoWindow ): void {
        console.log( 'OVERLAY - UNLOAD:', window );
    }

    /**
     *
     * @param marker {Marker}
     * @return {void}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 07/31/21 03:38 pm
     */
    protected onMarkerLoad( marker: google.maps.Marker ): void {
        console.log( 'OVERLAY - LOAD:', marker );
    }

    /**
     *
     * @param marker {Marker}
     * @return {void}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 07/31/21 03:38 pm
     */
    protected onMarkerUnload( marker: google.maps.Marker ): void {
        console.log( 'OVERLAY - UNLOAD:', marker );
    }

    /**
     *
     * @param overlay {any}
     * @return {void}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 01/30/21 11:30 am - documented
     */
    protected onOverlayClick( overlay: google.maps.MapMouseEvent ): void {
        console.log( 'OVERLAY - CLICK:', overlay );
    }

    /**
     *
     * @param props {any}
     * @return {void}
     * @constructor
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 01/30/21 11:30 am - documented
     */
    public constructor( props: ISectionProps ) {
        super( props );

        console.warn( 'MAP PROPS', { props, module: props.module, data: props.module.data, text: props.module.data.text, map: MapModule.Build( props.module?.data?.text ?? {} ) } );

        this.state = {
            map      : MapModule.Build( props.module?.data?.text ?? {} ),
            className: new Set<string>( [ 'grid-x', Map1.COMPONENT_CLASS ] ),
            children : new Set<JSX.Element>(),
        };

        this.registerProps( props.module ?? null );
    }

    /**
     *
     * @return {JSX.Element}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/09/20 12:15 pm
     * TODO: 12/09/20 add support for action buttons on the right side in a column format
     */
    public render(): JSX.Element {
        const map: MapModule             = this.state.map as MapModule;
        let title: JSX.Element | null    = null;
        let subtitle: JSX.Element | null = null;
        let window: JSX.Element | null   = null;
        let marker: JSX.Element | null   = null;

        if( this.props?.module?.title ) {
            title = <Title module={ this.props?.module?.title } />;
        }
        if( this.props?.module?.subtitle ) {
            subtitle = <Subtitle module={ this.props?.module?.subtitle } />;
        }
        if( map?.window?.enabled ) {
            const props: InfoWindowProps = {
                position : map?.map.focus.forComponent(),
                onLoad   : map?.window.onLoad ? this.onWindowLoad : undefined,
                onUnmount: map?.window.onUnload ? this.onWindowUnload : undefined,
                // onCloseClick         : map?.window.onClick ? this.onOverlayClick : null,
            };
            const child: JSX.Element     = (
                <div key={ `map-1-div` } className="info-div">
                    <h1 key={ `map-1-h1` }>{ map?.info.title }</h1>
                    <address key={ `map-1-address` }>
                        <p key={ `map-1-address-street` }>{ map?.info.address.street }</p>
                        <p key={ `map-1-address-suite` }>{ map?.info.address.suite }</p>
                        <p key={ `map-1-address-state` }>{ map?.info.address.state }</p>
                    </address>
                </div>
            );

            window = <InfoWindow key={ Util.registerHash() }  { ...props } >{ child }</InfoWindow>;
        }
        if( map?.marker.enabled ) {
            const props: MarkerProps = {
                position : map?.map.focus.forComponent(),
                onLoad   : map?.marker.onLoad ? this.onMarkerLoad : undefined,
                onUnmount: map?.marker.onUnload ? this.onMarkerUnload : undefined,
                onClick  : map?.marker.onClick ? this.onOverlayClick : undefined,
            };

            marker = <Marker key={ Util.registerHash() }  { ...props } />;
        }

        return (
            <>
                <section { ...this.registerSectionProps() }>
                    <div className="grid-container content">
                        <div className="grid-x align-left">
                            <div className="cell small-12 medium-12">
                                { title }
                                { subtitle }
                            </div>
                        </div>
                    </div>
                </section>
                <div id={ `div${ this.props.module?.id ?? this.state?.hash ?? Util.registerHash() }` } className={ [ ...this.state.className ?? '' ].slice( 0, 3 ).join( ' ' ) } data-section-map="">
                    <div className="grid-container content">
                        <div className="grid-x align-left">
                            <div className="cell small-12 medium-12">
                                <LoadScript googleMapsApiKey={ process.env?.REACT_APP_API_GOOGLE_ID ?? '' }>
                                    <GoogleMap mapContainerClassName="google-map" center={ map?.map.center.forComponent() } zoom={ map?.map.zoom }>
                                        { window }
                                        { marker }
                                    </GoogleMap>
                                </LoadScript>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
