import { IPage }          from '../../interface';
import { TitleModule }    from './title.module';
import { StorageManager } from '../../manager';

/**
 * @class PageModule
 * @extends TitleModule
 * @implements IPage
 * @author Isaac Ewing
 * @version 1.0.0 05/08/21 04:29 pm
 * @version 2.0.0 05/27/21 02:54 pm - converted from AComponent to AModule
 */
export class PageModule extends TitleModule implements IPage {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 04:29 pm
      */
     protected static readonly CONSOLE_PREFIX: string   = `${ process.env.REACT_APP_CONSOLE_PREFIX_MODULE } PAGE ${ process.env.REACT_APP_CONSOLE_SUFFIX_MODULE }`;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 04:29 pm
      */
     protected static readonly CONSOLE_ENABLED: boolean = false;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 04:29 pm
      */
     protected static readonly API_ROOT_NODE: string    = process.env.REACT_APP_GRAPHQL_GET_PAGE;
     /**
      *
      * @type {PageModule}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 04:29 pm
      */
     protected static fromStorage: PageModule;

     /**
      *
      * @param module {PageModule} The object containing the data
      * @return {PageModule|null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 12:58 pm
      * @see Build
      * @see id
      * @see path
      * @see alias
      * @see prefix
      * @see suffix
      * @see text
      * @see setId
      * @see setPath
      * @see setAlias
      * @see setPrefix
      * @see setSuffix
      * @see setText
      * @see store
      * @see toObject
      */
     public static Build( module: PageModule ): PageModule;
     public static Build( obj: Partial<IPage> ): PageModule;
     public static Build( json: string ): PageModule;
     public static Build( id?: number, path?: string, alias?: string, prefix?: string, suffix?: string, text?: string ): PageModule;
     public static Build( dataOrId?: unknown, path?: string, alias?: string, prefix?: string, suffix?: string, text?: string ): PageModule {
          if( dataOrId ) {
               dataOrId = this.mapAPIRootNode( dataOrId, this.API_ROOT_NODE );

               if( dataOrId instanceof PageModule ) {
                    return dataOrId;
               }
               if( typeof dataOrId === 'object' ) {
                    const localData: Partial<IPage> = dataOrId;

                    return new PageModule( localData?.id ?? null, localData?.path ?? null, localData?.alias ?? null, localData?.prefix ?? null,
                                           localData?.suffix ?? null, localData?.text ?? null );
               }
               if( typeof dataOrId === 'number' ) {
                    return new PageModule( dataOrId ?? null, path ?? null, alias ?? null, prefix ?? null, suffix ?? null, text ?? null );
               }
               if( typeof dataOrId === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrId ) );
                    } catch( exception ) {
                         // not a valid json string
                         return new PageModule( +dataOrId ?? null, path ?? null, alias ?? null, prefix ?? null, suffix ?? null, text ?? null );
                    }
               }
          }

          return null;
     }

     /**
      *
      * @return {PageModule | null}
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 04:29 pm
      * @see id
      * @see path
      * @see alias
      * @see prefix
      * @see suffix
      * @see text
      * @see setId
      * @see setPath
      * @see setAlias
      * @see setPrefix
      * @see setSuffix
      * @see setText
      * @see store
      * @see toObject
      */
     public static FromStorage(): PageModule | null {
          if( this.fromStorage ) {
               return this.fromStorage;
          }
          if( StorageManager.pageModel ) {
               this.fromStorage = PageModule.Build( StorageManager.pageModel ?? null );
          } else if( StorageManager.pageId && StorageManager.pagePath && StorageManager.pageText ) {
               this.fromStorage = new PageModule( StorageManager.pageId ?? null, StorageManager.pagePath ?? null, StorageManager.pageAlias ?? null,
                                                  StorageManager.pagePrefix ?? null, StorageManager.pageSuffix ?? null, StorageManager.pageText ?? null );
          }

          return this.fromStorage ?? null;
     }

     /**
      *
      * @param {number} id
      * @param {string} path
      * @param {string} alias
      * @param {string} prefix
      * @param {string} suffix
      * @param {string} text
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/27/21 11:36 am
      * @version 2.0.0 05/27/21 02:54 pm - converted from AComponent to AModule
      * @see id
      * @see path
      * @see alias
      * @see prefix
      * @see suffix
      * @see text
      * @see setId
      * @see setPath
      * @see setAlias
      * @see setPrefix
      * @see setSuffix
      * @see setText
      * @see store
      * @see toObject
      */
     public constructor( id?: number, path?: string, alias?: string, prefix?: string, suffix?: string, text?: string ) {
          super( id ?? null, text ?? null );

          this.path   = path ?? null;
          this.alias  = alias ?? null;
          this.prefix = prefix ?? null;
          this.suffix = suffix ?? null;
     }

     /**
      *
      * @return {string}
      * @public
      * @version 1.0.0 05/27/21 02:59 pm
      * @see id
      * @see path
      * @see alias
      * @see prefix
      * @see suffix
      * @see text
      * @see setId
      * @see setPath
      * @see setAlias
      * @see setPrefix
      * @see setSuffix
      * @see setText
      * @see store
      * @see toObject
      */
     public get path(): string {
          return this._data.get( 'path' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @version 1.0.0 05/27/21 02:59 pm
      * @see id
      * @see path
      * @see alias
      * @see prefix
      * @see suffix
      * @see text
      * @see setId
      * @see setPath
      * @see setAlias
      * @see setPrefix
      * @see setSuffix
      * @see setText
      * @see store
      * @see toObject
      */
     public set path( value: string ) {
          this._data.set( 'path', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @version 1.0.0 05/27/21 02:59 pm
      * @see id
      * @see path
      * @see alias
      * @see prefix
      * @see suffix
      * @see text
      * @see setId
      * @see setPath
      * @see setAlias
      * @see setPrefix
      * @see setSuffix
      * @see setText
      * @see store
      * @see toObject
      */
     public get alias(): string {
          return this._data.get( 'alias' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @version 1.0.0 05/27/21 02:59 pm
      * @see id
      * @see path
      * @see alias
      * @see prefix
      * @see suffix
      * @see text
      * @see setId
      * @see setPath
      * @see setAlias
      * @see setPrefix
      * @see setSuffix
      * @see setText
      * @see store
      * @see toObject
      */
     public set alias( value: string ) {
          this._data.set( 'alias', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @version 1.0.0 05/27/21 02:59 pm
      * @see id
      * @see path
      * @see alias
      * @see prefix
      * @see suffix
      * @see text
      * @see setId
      * @see setPath
      * @see setAlias
      * @see setPrefix
      * @see setSuffix
      * @see setText
      * @see store
      * @see toObject
      */
     public get prefix(): string {
          return this._data.get( 'prefix' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @version 1.0.0 05/27/21 02:59 pm
      * @see id
      * @see path
      * @see alias
      * @see prefix
      * @see suffix
      * @see text
      * @see setId
      * @see setPath
      * @see setAlias
      * @see setPrefix
      * @see setSuffix
      * @see setText
      * @see store
      * @see toObject
      */
     public set prefix( value: string ) {
          this._data.set( 'prefix', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @version 1.0.0 05/27/21 02:59 pm
      * @see id
      * @see path
      * @see alias
      * @see prefix
      * @see suffix
      * @see text
      * @see setId
      * @see setPath
      * @see setAlias
      * @see setPrefix
      * @see setSuffix
      * @see setText
      * @see store
      * @see toObject
      */
     public get suffix(): string {
          return this._data.get( 'suffix' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @version 1.0.0 05/27/21 02:59 pm
      * @see id
      * @see path
      * @see alias
      * @see prefix
      * @see suffix
      * @see text
      * @see setId
      * @see setPath
      * @see setAlias
      * @see setPrefix
      * @see setSuffix
      * @see setText
      * @see store
      * @see toObject
      */
     public set suffix( value: string ) {
          this._data.set( 'suffix', value );
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @version 1.0.0 05/27/21 02:59 pm
      * @see id
      * @see path
      * @see alias
      * @see prefix
      * @see suffix
      * @see text
      * @see setId
      * @see setPath
      * @see setAlias
      * @see setPrefix
      * @see setSuffix
      * @see setText
      * @see store
      * @see toObject
      */
     public setPath( value: string ): this {
          this.path = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @version 1.0.0 05/27/21 02:59 pm
      * @see id
      * @see path
      * @see alias
      * @see prefix
      * @see suffix
      * @see text
      * @see setId
      * @see setPath
      * @see setAlias
      * @see setPrefix
      * @see setSuffix
      * @see setText
      * @see store
      * @see toObject
      */
     public setAlias( value: string ): this {
          this.alias = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @version 1.0.0 05/27/21 02:59 pm
      * @see id
      * @see path
      * @see alias
      * @see prefix
      * @see suffix
      * @see text
      * @see setId
      * @see setPath
      * @see setAlias
      * @see setPrefix
      * @see setSuffix
      * @see setText
      * @see store
      * @see toObject
      */
     public setPrefix( value: string ): this {
          this.prefix = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @version 1.0.0 05/27/21 02:59 pm
      * @see id
      * @see path
      * @see alias
      * @see prefix
      * @see suffix
      * @see text
      * @see setId
      * @see setPath
      * @see setAlias
      * @see setPrefix
      * @see setSuffix
      * @see setText
      * @see store
      * @see toObject
      */
     public setSuffix( value: string ): this {
          this.suffix = value;

          return this;
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/27/21 02:59 pm
      * @see id
      * @see path
      * @see alias
      * @see prefix
      * @see suffix
      * @see text
      * @see setId
      * @see setPath
      * @see setAlias
      * @see setPrefix
      * @see setSuffix
      * @see setText
      * @see store
      * @see toObject
      */
     public store(): this {
          StorageManager.pageModel  = this;
          StorageManager.pageId     = this.id;
          StorageManager.pagePath   = this.path;
          StorageManager.pageAlias  = this.alias;
          StorageManager.pagePrefix = this.prefix;
          StorageManager.pageSuffix = this.suffix;
          StorageManager.pageText   = this.text;

          return this;
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/27/21 02:59 pm
      * @see id
      * @see path
      * @see alias
      * @see prefix
      * @see suffix
      * @see text
      * @see setId
      * @see setPath
      * @see setAlias
      * @see setPrefix
      * @see setSuffix
      * @see setText
      * @see store
      * @see toObject
      */
     public toObject(): Record<string, unknown> {
          return {
               id    : this.id,
               path  : this.path,
               alias : this.alias,
               prefix: this.prefix,
               suffix: this.suffix,
               text  : this.text,
          };
     }
}