import { IAbout }         from '../../interface';
import { TitleModule }    from './title.module';
import { StorageManager } from '../../manager';

/**
 * @class AboutModule
 * @extends TitleModule
 * @implements IAbout
 * @author Isaac Ewing
 * @version 1.0.0 05/23/21 05:42 pm
 * @version 2.0.0 05/27/21 04:31 pm - converted from AComponent to TitleModule
 */
export class AboutModule extends TitleModule implements IAbout {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 11:32 am
      */
     protected static readonly CONSOLE_PREFIX: string   = `${ process.env.REACT_APP_CONSOLE_PREFIX_MODULE } ABOU ${ process.env.REACT_APP_CONSOLE_SUFFIX_MODULE }`;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 11:32 am
      */
     protected static readonly CONSOLE_ENABLED: boolean = false;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 11:32 am
      */
     protected static readonly API_ROOT_NODE: string    = process.env.REACT_APP_GRAPHQL_GET_ABOUT;
     /**
      *
      * @type {AboutModule}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 11:32 am
      */
     protected static fromStorage: AboutModule;

     /**
      *
      * @param module {AboutModule} The object containing the data
      * @return {AboutModule | null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 11:40 am
      * @see Build
      * @see id
      * @see first
      * @see last
      * @see FromStorage
      */
     public static Build( module: AboutModule ): AboutModule;
     public static Build( obj: Partial<IAbout> ): AboutModule;
     public static Build( json: string ): AboutModule;
     public static Build( id?: number, first?: string, last?: string ): AboutModule;
     public static Build( dataOrId?: unknown, first?: string, last?: string ): AboutModule {
          if( dataOrId ) {
               dataOrId = this.mapAPIRootNode( dataOrId, this.API_ROOT_NODE );

               if( dataOrId instanceof AboutModule ) {
                    return dataOrId;
               }
               if( typeof dataOrId === 'object' ) {
                    const localData: Partial<IAbout> = dataOrId as Partial<IAbout>;

                    return new AboutModule( localData?.id ?? null, localData?.first ?? null, localData?.last ?? null );
               }
               if( typeof dataOrId === 'number' ) {
                    return new AboutModule( dataOrId ?? null, first ?? null, last ?? null );
               }
               if( typeof dataOrId === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrId ) );
                    } catch( exception ) {
                         // not a valid json string
                         return new AboutModule( +dataOrId ?? null, first ?? null, last ?? null );
                    }
               }
          }

          return null;
     }

     /**
      *
      * @return {AboutModule | null}
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 11:40 am
      * @see first
      * @see last
      * @see setFirst
      * @see setLast
      */
     public static FromStorage(): AboutModule | null {
          if( this.fromStorage ) {
               return this.fromStorage;
          }
          if( StorageManager.aboutModel ) {
               this.fromStorage = AboutModule.Build( StorageManager.aboutModel ?? null );
          } else if( StorageManager.aboutId && StorageManager.aboutFirst && StorageManager.aboutLast ) {
               this.fromStorage = new AboutModule( StorageManager.aboutId ?? null, StorageManager.aboutFirst ?? null, StorageManager.aboutLast ?? null );
          }

          return this.fromStorage ?? null;
     }

     /**
      *
      * @param {number} id
      * @param {string} first
      * @param {string} last
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 11:40 am
      * @see first
      * @see last
      * @see setFirst
      * @see setLast
      */
     public constructor( id?: number, first?: string, last?: string ) {
          super( id ?? null );

          this.first = first ?? null;
          this.last  = last ?? null;
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/23/21 05:42 pm
      * @see first
      * @see last
      * @see setFirst
      * @see setLast
      */
     public get first(): string {
          return this._data.get( 'first' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/23/21 05:42 pm
      * @see first
      * @see last
      * @see setFirst
      * @see setLast
      */
     public set first( value: string ) {
          this._data.set( 'first', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/23/21 05:42 pm
      * @see first
      * @see last
      * @see setFirst
      * @see setLast
      */
     public get last(): string {
          return this._data.get( 'last' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/23/21 05:42 pm
      * @see first
      * @see last
      * @see setFirst
      * @see setLast
      */
     public set last( value: string ) {
          this._data.set( 'last', value );
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/23/21 05:42 pm
      * @see first
      * @see last
      * @see setFirst
      * @see setLast
      */
     public setFirst( value: string ): this {
          this.first = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/23/21 05:42 pm
      * @see first
      * @see last
      * @see setFirst
      * @see setLast
      */
     public setLast( value: string ): this {
          this.last = value;

          return this;
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/23/21 06:39 pm
      */
     public store(): this {
          StorageManager.aboutModel = this;
          StorageManager.aboutId    = this.id;
          StorageManager.aboutFirst = this.first;
          StorageManager.aboutLast  = this.last;

          return this;
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/23/21 05:42 pm
      * @see first
      * @see last
      * @see setFirst
      * @see setLast
      */
     public toObject(): Record<string, unknown> {
          return {
               id   : this.id,
               first: this.first,
               last : this.last,
          };
     }
}