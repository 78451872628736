import { AModule } from '../abstract/module.abstract';

/**
 * @class CoordinatesUtil
 * @extends AModule
 * @author Isaac Ewing
 * @version 1.0.0 02/07/21 12:32 pm
 * @version 1.1.0 05/07/21 02:11 pm - changed base class to AModule
 * @classdesc This class will handle the storage for address data. This is the second class to
 * implement the interfaces in a similar way to laravel/lumen
 */
export class CoordinatesUtil extends AModule {
     /**
      * @param data {Object|null} The object containing the data
      * @return {MapModule|null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 12:51 pm
      * @see latitude
      * @see longitude
      * @see setLatitude
      * @see setLongitude
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public static Build( data: unknown ): CoordinatesUtil | null {
          if( data ) {
               return new CoordinatesUtil( data );
          }

          return null;
     }

     /**
      *
      * @param data {any}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 12:51 pm
      * @see latitude
      * @see longitude
      * @see setLatitude
      * @see setLongitude
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public constructor( data: any ) {
          super( data ?? null );

          this.latitude  = data?.latitude ?? data?.lat ?? process.env.REACT_APP_MAP_LATITUDE;
          this.longitude = data?.longitude ?? data?.lng ?? process.env.REACT_APP_MAP_LONGITUDE;
     }

     /**
      *
      * @return {number}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 12:51 pm
      * @see latitude
      * @see longitude
      * @see setLatitude
      * @see setLongitude
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public get latitude(): number {
          return this._data.get( 'latitude' );
     }

     /**
      *
      * @param {number} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 12:51 pm
      * @see latitude
      * @see longitude
      * @see setLatitude
      * @see setLongitude
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public set latitude( value: number ) {
          this._data.set( 'latitude', +value );
     }

     /**
      *
      * @return {number}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 12:51 pm
      * @see latitude
      * @see longitude
      * @see setLatitude
      * @see setLongitude
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public get longitude(): number {
          return this._data.get( 'longitude' );
     }

     /**
      *
      * @param {number} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 12:51 pm
      * @see latitude
      * @see longitude
      * @see setLatitude
      * @see setLongitude
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public set longitude( value: number ) {
          this._data.set( 'longitude', +value );
     }

     /**
      *
      * @param {number} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 12:51 pm
      * @see latitude
      * @see longitude
      * @see setLatitude
      * @see setLongitude
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public setLatitude( value: number ): this {
          this.latitude = value;

          return this;
     }

     /**
      *
      * @param {number} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 12:51 pm
      * @see latitude
      * @see longitude
      * @see setLatitude
      * @see setLongitude
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public setLongitude( value: number ): this {
          this.longitude = value;

          return this;
     }

     /**
      *
      * @return {{lng: number, lat: number}}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 12:51 pm
      * @see latitude
      * @see longitude
      * @see setLatitude
      * @see setLongitude
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public forComponent(): { lng: number, lat: number } {
          return {
               lat: this.latitude,
               lng: this.longitude,
          };
     }

     /**
      *
      * @return {Object}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 11:24 am
      * @see latitude
      * @see longitude
      * @see setLatitude
      * @see setLongitude
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public toObject(): Record<string, number> {
          return {
               latitude : this.latitude,
               longitude: this.longitude,
          };
     }
}