/**
 * @enum
 * @author Isaac Ewing
 * @version 1.0.0 01/25/21 12:50 pm
 */
export enum ActionEnum {
     Claim        = 'claim',
     Login        = 'login',
     Invite       = 'invite',
     InviteSubmit = 'invite-submit',
     Unknown      = 'unknown'
}