import { AModule } from '../../../../abstract/module.abstract';

/**
 * @class RegisterModule
 * @extends AModule
 * @author Isaac Ewing
 * @version 1.0.0 01/23/21 12:24 pm
 */
export class RegisterModule extends AModule {
     /**
      *
      * @param data {Object} The object containing the data
      * @return {RegisterModule|null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/23/21 12:23 pm
      * @see id
      */
     public static Build( data:unknown ): RegisterModule | null {
          if( data ) {
               if( typeof data === 'string' ) {
                    try {
                         data = JSON.parse( data );
                    } catch( exception ) {
                         return null;
                    }
               }
               if( typeof data === 'object' ) {
                    return new RegisterModule( data );
               }
          }

          return null;
     }

     /**
      *
      * @param data {any}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/23/21 12:23 pm
      * @see id
      *
      */
     public constructor( data?: any ) {
          super( data );
     }

     /**
      *
      * @return {JQuery<HTMLElement>} Returns the value stored in the data property
      * @type {JQuery<HTMLElement>}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/23/21 12:28 pm
      * @see id
      * @see componentProfile
      * @see componentPlatform
      * @see componentExpand
      * @see componentStart
      */
     public get componentProfile(): JQuery<HTMLElement> {
          return this._data.get( 'component-profile' );
     }

     /**
      *
      * @param {JQuery<HTMLElement>} value
      * @type {JQuery<HTMLElement>}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/23/21 12:28 pm
      * @see id
      * @see componentProfile
      * @see componentPlatform
      * @see componentExpand
      * @see componentStart
      */
     public set componentProfile( value: JQuery<HTMLElement> ) {
          this._data.set( 'component-profile', value );
     }

     /**
      *
      * @return {JQuery<HTMLElement>} Returns the value stored in the data property
      * @type {JQuery<HTMLElement>}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/23/21 12:28 pm
      * @see id
      * @see componentProfile
      * @see componentPlatform
      * @see componentExpand
      * @see componentStart
      */
     public get componentPlatform(): JQuery<HTMLElement> {
          return this._data.get( 'component-platform' );
     }

     /**
      *
      * @param {JQuery<HTMLElement>} value
      * @type {JQuery<HTMLElement>}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/23/21 12:28 pm
      * @see id
      * @see componentProfile
      * @see componentPlatform
      * @see componentExpand
      * @see componentStart
      */
     public set componentPlatform( value: JQuery<HTMLElement> ) {
          this._data.set( 'component-platform', value );
     }

     /**
      *
      * @return {JQuery<HTMLElement>} Returns the value stored in the data property
      * @type {JQuery<HTMLElement>}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/23/21 12:28 pm
      * @see id
      * @see componentProfile
      * @see componentPlatform
      * @see componentExpand
      * @see componentStart
      */
     public get componentExpand(): JQuery<HTMLElement> {
          return this._data.get( 'component-expand' );
     }

     /**
      *
      * @param {JQuery<HTMLElement>} value
      * @type {JQuery<HTMLElement>}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/23/21 12:28 pm
      * @see id
      * @see componentProfile
      * @see componentPlatform
      * @see componentExpand
      * @see componentStart
      */
     public set componentExpand( value: JQuery<HTMLElement> ) {
          this._data.set( 'component-expand', value );
     }

     /**
      *
      * @return {JQuery<HTMLElement>} Returns the value stored in the data property
      * @type {JQuery<HTMLElement>}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/23/21 12:28 pm
      * @see id
      * @see componentProfile
      * @see componentPlatform
      * @see componentExpand
      * @see componentStart
      */
     public get componentStart(): JQuery<HTMLElement> {
          return this._data.get( 'component-start' );
     }

     /**
      *
      * @param {JQuery<HTMLElement>} value
      * @type {JQuery<HTMLElement>}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/23/21 12:28 pm
      * @see id
      * @see componentProfile
      * @see componentPlatform
      * @see componentExpand
      * @see componentStart
      */
     public set componentStart( value: JQuery<HTMLElement> ) {
          this._data.set( 'component-start', value );
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/25/21 01:27 pm
      * @see id
      * @see action
      * @see code
      * @see provider
      * @see domain
      * @see socket
      * @see hash
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public toObject(): Record<string, unknown> {
          return {
               'component-profile' : this.componentProfile,
               'component-platform': this.componentPlatform,
               'component-expand'  : this.componentExpand,
               'component-start'   : this.componentStart,
          };
     }

     /**
      *
      * @return {Map<string, string>}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/25/21 01:27 pm
      * @see id
      * @see action
      * @see code
      * @see provider
      * @see domain
      * @see socket
      * @see hash
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public toMap(): Map<string, JQuery<HTMLElement>> {
          return new Map<string, JQuery<HTMLElement>>(
               [
                    [ 'component-profile', this.componentProfile ],
                    [ 'component-platform', this.componentPlatform ],
                    [ 'component-expand', this.componentExpand ],
                    [ 'component-start', this.componentStart ],
               ] );
     }
}