import { TitleModule }       from './title.module';
import { ILayoutModule }     from '../../interface';
import { DescriptionModule } from './description.module';

/**
 * @class SectionModule
 * @extends TitleModule
 * @implements ILayoutModule
 * @author Isaac Ewing
 * @version 1.0.0 10/03/20 01:56 pm
 * @version 1.1.0 02/21/21 11:55 am - updated to extend AComponent (through link module through title module)
 * @version 2.0.0 05/28/21 06:14 pm - converted from AComponent to TitleModule
 */
export class LayoutModule extends TitleModule implements ILayoutModule {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/26/21 05:15 pm
      */
     protected static readonly CONSOLE_PREFIX: string   = `${ process.env.REACT_APP_CONSOLE_PREFIX_MODULE } LAYO ${ process.env.REACT_APP_CONSOLE_SUFFIX_MODULE }`;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/26/21 05:15 pm
      */
     protected static readonly CONSOLE_ENABLED: boolean = false;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 11:56 am
      */
     protected static readonly API_ROOT_NODE: string    = process.env.REACT_APP_GRAPHQL_GET_LAYOUT;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 11:55 am
      */
     protected static readonly DEFAULT_JQUERY: string   = 'layout';

     /**
      *
      * @param module {LayoutModule} The object containing the data
      * @return {LayoutModule|null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 12:58 pm
      * @see Build
      * @see BuildForShortcode
      * @see id
      * @see text
      */
     public static Build( module: LayoutModule ): LayoutModule;
     public static Build( obj: Partial<ILayoutModule> ): LayoutModule;
     public static Build( json: string ): LayoutModule;
     public static Build( id?: number, title?: unknown, description?: DescriptionModule ): LayoutModule;
     public static Build( id?: number, title?: TitleModule, description?: DescriptionModule ): LayoutModule;
     public static Build( dataOrId?: unknown, title?: TitleModule, description?: DescriptionModule ): LayoutModule {
          if( dataOrId ) {
               dataOrId = this.mapAPIRootNode( dataOrId, this.API_ROOT_NODE );

               if( dataOrId instanceof LayoutModule ) {
                    return dataOrId;
               }
               if( typeof dataOrId === 'object' ) {
                    const localData: Partial<ILayoutModule> = dataOrId;

                    return new LayoutModule( localData?.id ?? null, TitleModule.Build( localData?.title ) ?? null, DescriptionModule.Build( localData?.description ) ?? null );
               }
               if( typeof dataOrId === 'number' ) {
                    return new LayoutModule( dataOrId ?? null, TitleModule.Build( dataOrId ) ?? null, DescriptionModule.Build( description ) ?? null );
               }
               if( typeof dataOrId === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrId ) );
                    } catch( exception ) {
                         // not a valid json string
                         return new LayoutModule( +dataOrId ?? null, TitleModule.Build( dataOrId ) ?? null, DescriptionModule.Build( description ) ?? null );
                    }
               }
          }

          return null;
     }

     /**
      *
      * @param {number} id
      * @param {TitleModule} title
      * @param {DescriptionModule} description
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/21/21 11:55 am
      * @see id
      * @see title
      * @see description
      * @see setId
      * @see setTitle
      * @see setDescription
      * @see store
      * @see toObject
      */
     public constructor( id?: number, title?: TitleModule, description?: DescriptionModule ) {
          super( id ?? null );

          this.title       = title ?? null;
          this.description = description ?? null;
     }

     /**
      *
      * @return {TitleModule} Returns the string for the title
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 10/03/20 12:06 pm
      * @see id
      * @see title
      * @see description
      * @see setId
      * @see setTitle
      * @see setDescription
      * @see store
      * @see toObject
      */
     public get title(): TitleModule {
          return this._data.get( 'title' );
     }

     /**
      *
      * @param {TitleModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/21/21 11:55 am
      * @see id
      * @see title
      * @see description
      * @see setId
      * @see setTitle
      * @see setDescription
      * @see store
      * @see toObject
      */
     public set title( value: TitleModule ) {
          this._data.set( 'title', value );
     }

     /**
      *
      * @return {DescriptionModule} Returns the string for the description
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 10/03/20 01:17 pm
      * @see id
      * @see title
      * @see description
      * @see setId
      * @see setTitle
      * @see setDescription
      * @see store
      * @see toObject
      */
     public get description(): DescriptionModule {
          return this._data.get( 'description' );
     }

     /**
      *
      * @param {DescriptionModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/21/21 11:55 am
      * @see id
      * @see title
      * @see description
      * @see setId
      * @see setTitle
      * @see setDescription
      * @see store
      * @see toObject
      */
     public set description( value: DescriptionModule ) {
          this._data.set( 'title', value );
     }

     /**
      *
      * @param {TitleModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/21/21 11:55 am
      * @see id
      * @see title
      * @see description
      * @see setId
      * @see setTitle
      * @see setDescription
      * @see store
      * @see toObject
      */
     public setTitle( value: TitleModule ): this {
          this.title = value;

          return this;
     }

     /**
      *
      * @param {DescriptionModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/21/21 11:55 am
      * @see id
      * @see title
      * @see description
      * @see setId
      * @see setTitle
      * @see setDescription
      * @see store
      * @see toObject
      */
     public setDescription( value: DescriptionModule ): this {
          this.description = value;

          return this;
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/21/21 11:52 am
      * @see id
      * @see title
      * @see description
      * @see setId
      * @see setTitle
      * @see setDescription
      * @see store
      * @see toObject
      */
     public toObject(): Record<string, unknown> {
          return {
               id         : this.id,
               title      : this.title?.toObject(),
               description: this.description?.toObject(),
          };
     }
}