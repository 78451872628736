import React             from 'react';
import { ISectionProps } from '../../../api/com/ewing/social/interface';
import { BaseSection }   from '../../atom/BaseSection';
import { Button }        from '../../atom/Button';
import { Description }   from '../../atom/Description';
import { Divider }       from '../../atom/Divider';
import { Subtitle }      from '../../atom/Subtitle';
import { Title }         from '../../atom/Title';
import { Gallery }       from '../../molecule/Gallery';

// TODO 05/29/21 08:19 pm
//  This class component can be changed into a functional component
/**
 * @class Layout2
 * @extends BaseSection
 * @author Isaac Ewing
 * @version 1.0.0 01/30/21 11:30 am - documented
 */
export class Layout2 extends BaseSection {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am - documented
      */
     protected static readonly COMPONENT_CLASS: string = 'layout-2';

     /**
      *
      * @param props {ISectionProps}
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am - documented
      */
     public constructor( props: ISectionProps ) {
          super( props );

          this.state = {
               data     : props.data,
               className: new Set<string>( [ 'grid-x', Layout2.COMPONENT_CLASS ] ),
          };

          this.registerProps( props.module ?? null );
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am
      * documented
      */
     public render(): JSX.Element {
          return (
               <section { ...this.registerSectionProps( this.props.module ) }>
                    <div className="grid-container content content-text" data-content-text="">
                         <div className="grid-x align-center">
                              <div className="cell small-12">
                                   <Subtitle module={ this.props?.module?.subtitle } />
                                   <Divider />
                                   <Title module={ this.props?.module?.title } />
                              </div>
                              <div className="cell small-10 medium-8" data-content-images="">
                                   <Description module={ this.props?.module?.description } />
                                   <Button module={ this.props?.module?.button } />
                              </div>
                         </div>
                    </div>
                    <div className="grid-container content content-images">
                         <Gallery module={ this.props?.module?.gallery } />
                    </div>
               </section>
          );
     }
}