import React                  from 'react';
import { GalleryImageModule } from '../../../api/com/ewing/social/component';
import { ISectionProps }      from '../../../api/com/ewing/social/interface';
import { BaseSection }        from '../../atom/BaseSection';
import { Button }             from '../../atom/Button';
import { Description }        from '../../atom/Description';
import { Divider }            from '../../atom/Divider';
import { Image }              from '../../atom/Image';
import { Subtitle }           from '../../atom/Subtitle';
import { Title }              from '../../atom/Title';

/**
 * @class Layout4
 * @extends BaseSection
 * @author Isaac Ewing
 * @version 1.0.0 01/30/21 11:30 am - documented
 */
export class Layout4 extends BaseSection {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am - documented
      */
     protected static readonly COMPONENT_CLASS: string = 'layout-4';

     /**
      *
      * @param props {ISectionProps}
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am - documented
      */
     public constructor( props: ISectionProps ) {
          super( props );

          this.state = {
               className: new Set<string>( [ 'grid-x', Layout4.COMPONENT_CLASS ] ),
          };

          this.registerStyle( props?.module );
          this.registerCurve( props?.module );
          this.registerSubmenus( props?.module );
          this.registerClasses( props?.module );
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am
      * documented
      */
     public render(): JSX.Element {
          const localImages: GalleryImageModule[] = this.props.module?.gallery?.imagesToArray() as GalleryImageModule[];

          return (
               <section { ...this.registerSectionProps( this.props.module ) }>
                    <div className="grid-container content">
                         <div className="grid-x align-center">
                              <div className="cell small-12">
                                   <Subtitle module={ this.props?.module?.subtitle } />
                                   <Divider />
                                   <Title module={ this.props?.module?.title } />
                              </div>
                              <div className="cell small-9 medium-8">
                                   <Description module={ this.props?.module?.description } />
                                   <Button module={ this.props?.module?.button } />
                              </div>
                              <Image module={ localImages?.[ 0 ].image } rounded greyed shadow />
                              <Image module={ localImages?.[ 1 ].image } rounded greyed shadow />
                              <Image module={ localImages?.[ 2 ].image } rounded greyed shadow />
                         </div>
                    </div>
               </section>
          );
     }
}

export default Layout4;
