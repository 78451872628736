import { IDomain }        from '../../interface';
import { PageModule }     from './page.module';
import { StorageManager } from '../../manager';

/**
 * @class DomainModule
 * @extends PageModule
 * @implements IDomain
 * @author Isaac Ewing
 * @version 1.1.0 02/18/21 02:23 pm - updated to extend AComponent (through link module through title module)
 * @version 2.0.0 05/27/21 03:50 pm - converted from AComponent to PageModule
 */
export class DomainModule extends PageModule implements IDomain {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 03:54 pm
      */
     protected static readonly CONSOLE_PREFIX: string   = `${ process.env.REACT_APP_CONSOLE_PREFIX_MODULE } DOMN ${ process.env.REACT_APP_CONSOLE_SUFFIX_MODULE }`;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 04:29 pm
      */
     protected static readonly CONSOLE_ENABLED: boolean = false;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/21/21 11:03 am
      */
     protected static readonly API_ROOT_NODE: string    = process.env.REACT_APP_GRAPHQL_GET_DOMAIN;
     /**
      *
      * @type {DomainModule}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 03:37 pm
      */
     protected static fromStorage: DomainModule;

     /**
      *
      * @param module {DomainModule} The object containing the data
      * @return {DomainModule|null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/18/21 12:58 pm
      * @see Build
      * @see BuildForShortcode
      * @see id
      * @see text
      */
     public static Build( module: DomainModule ): DomainModule;
     public static Build( obj: Partial<IDomain> ): DomainModule;
     public static Build( json: string ): DomainModule;
     public static Build( id?: number, path?: string, alias?: string, prefix?: string, suffix?: string, text?: string, claimed?: boolean ): DomainModule;
     public static Build( dataOrId?: unknown, path?: string, alias?: string, prefix?: string, suffix?: string, text?: string, claimed?: boolean ): DomainModule {
          if( dataOrId ) {
               dataOrId = this.mapAPIRootNode( dataOrId, this.API_ROOT_NODE );

               if( dataOrId instanceof DomainModule ) {
                    return dataOrId;
               }
               if( typeof dataOrId === 'object' ) {
                    const localData: Partial<IDomain> = dataOrId;

                    return new DomainModule( localData?.id ?? null, localData?.path ?? null, localData?.alias ?? null, localData?.prefix ?? null, localData?.suffix ?? null,
                                             localData?.text ?? null, localData?.claimed ?? null );
               }
               if( typeof dataOrId === 'number' ) {
                    return new DomainModule( dataOrId ?? null, path ?? null, alias ?? null, prefix ?? null, suffix ?? null, text ?? null, claimed ?? null );
               }
               if( typeof dataOrId === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrId ) );
                    } catch( exception ) {
                         // not a valid json string
                         return new DomainModule( +dataOrId ?? null, path ?? null, alias ?? null, prefix ?? null, suffix ?? null, text ?? null,
                                                  claimed ?? null );
                    }
               }
          }

          return null;
     }

     /**
      *
      * @return {DomainModule | null}
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 03:37 pm
      */
     public static FromStorage(): DomainModule | null {
          if( this.fromStorage ) {
               return this.fromStorage;
          }
          if( StorageManager.domainModel ) {
               this.fromStorage = DomainModule.Build( StorageManager.domainModel ?? null );
          } else if( StorageManager.domainId && StorageManager.domainPath && StorageManager.domainText ) {
               this.fromStorage = new DomainModule( StorageManager.domainId ?? null, StorageManager.domainPath ?? null, StorageManager.domainAlias ?? null,
                                                    StorageManager.domainPrefix ?? null, StorageManager.domainSuffix ?? null, StorageManager.domainText ?? null,
                                                    StorageManager.domainClaimed ?? null );
          }

          return this.fromStorage ?? null;
     }

     /**
      *
      * @param {number} id
      * @param {string} path
      * @param {string} alias
      * @param {string} prefix
      * @param {string} suffix
      * @param {string} text
      * @param {boolean} claimed
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/27/21 11:36 am
      * @version 2.0.0 05/27/21 03:50 pm - converted from AComponent to AModule
      * @see id
      * @see path
      * @see alias
      * @see prefix
      * @see suffix
      * @see text
      * @see claimed
      * @see setId
      * @see setPath
      * @see setAlias
      * @see setPrefix
      * @see setSuffix
      * @see setText
      * @see setClaimed
      * @see store
      * @see toObject
      */
     public constructor( id?: number, path?: string, alias?: string, prefix?: string, suffix?: string, text?: string, claimed?: boolean ) {
          super( id ?? null, path ?? null, alias ?? null, prefix ?? null, suffix ?? null, text ?? null );

          this.claimed = claimed ?? null;
     }

     /**
      *
      * @return {claimed}
      * @public
      * @version 1.0.0 05/27/21 03:50 pm
      * @see id
      * @see path
      * @see alias
      * @see prefix
      * @see suffix
      * @see text
      * @see claimed
      * @see setId
      * @see setPath
      * @see setAlias
      * @see setPrefix
      * @see setSuffix
      * @see setText
      * @see setClaimed
      * @see store
      * @see toObject
      */
     public get claimed(): boolean {
          return this._data.get( 'claimed' );
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @public
      * @version 1.0.0 05/27/21 03:50 pm
      * @see id
      * @see path
      * @see alias
      * @see prefix
      * @see suffix
      * @see text
      * @see claimed
      * @see setId
      * @see setPath
      * @see setAlias
      * @see setPrefix
      * @see setSuffix
      * @see setText
      * @see setClaimed
      * @see store
      * @see toObject
      */
     public set claimed( value: boolean ) {
          this._data.set( 'claimed', value );
     }

     /**
      *
      * @param value {boolean}
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/27/21 03:50 pm
      * @see id
      * @see path
      * @see alias
      * @see prefix
      * @see suffix
      * @see text
      * @see claimed
      * @see setId
      * @see setPath
      * @see setAlias
      * @see setPrefix
      * @see setSuffix
      * @see setText
      * @see store
      * @see toObject
      */
     public setClaimed( value: boolean ): this {
          this.claimed = value;

          return this;
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/27/21 03:50 pm
      * @see id
      * @see path
      * @see alias
      * @see prefix
      * @see suffix
      * @see text
      * @see claimed
      * @see setId
      * @see setPath
      * @see setAlias
      * @see setPrefix
      * @see setSuffix
      * @see setText
      * @see setClaimed
      * @see store
      * @see toObject
      */
     public store(): this {
          StorageManager.domainModel   = this;
          StorageManager.domainId      = this.id;
          StorageManager.domainPath    = this.path;
          StorageManager.domainAlias   = this.alias;
          StorageManager.domainPrefix  = this.prefix;
          StorageManager.domainSuffix  = this.suffix;
          StorageManager.domainText    = this.text;
          StorageManager.domainClaimed = this.claimed;

          return this;
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/27/21 03:50 pm
      * @see id
      * @see path
      * @see alias
      * @see prefix
      * @see suffix
      * @see text
      * @see claimed
      * @see setId
      * @see setPath
      * @see setAlias
      * @see setPrefix
      * @see setSuffix
      * @see setText
      * @see setClaimed
      * @see store
      * @see toObject
      */
     public toObject(): Record<string, unknown> {
          if( this.path ) {
               return {
                    ...super.toObject(),
                    claimed: this.claimed ?? null,
               };
          }

          return null;
     }
}