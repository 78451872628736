import React                                            from 'react';
import Particles, { IParticlesProps, RecursivePartial } from 'react-tsparticles';
import JsxParser                                        from 'react-jsx-parser';
import { ISectionModule, ISectionProps }                from '../../../api/com/ewing/social/interface/component';
import { ShortcodeManager, URLManager }                 from '../../../api/com/ewing/social/manager';
import { TitleModule }                                  from '../../../api/com/ewing/social/module';
import { BaseSection }                                  from '../../atom/BaseSection';
import { Shortcode }                                    from '../../atom/Shortcode';
import { Subtitle }                                     from '../../atom/Subtitle';

/**
 * @class Hero1
 * @extends BaseSection
 * @author Isaac Ewing
 * @version 1.0.0 01/30/21 11:30 am - documented
 */
export class Hero1 extends BaseSection {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am - documented
      */
     protected static readonly COMPONENT_CLASS: string = 'hero-1';
     /**
      *
      * @type {number}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 04/17/21 02:54 pm
      */
     protected static readonly PARTICLE_SPEED: number  = +( process.env.REACT_APP_HERO_PARTICLE_SPEED ?? 1 );
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 04/17/21 02:48 pm
      */
     protected static readonly PARTICLE_COLOR: string  = process.env.REACT_APP_HERO_PARTICLE_COLOR ?? '#ffffff';

     /**
      *
      * @param exception
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/22/20 06:25 pm
      */
     protected onError( exception: Error ): void {
          console.trace( '==X== HERO-1 EXCEPTION', exception );
     }

     /**
      *
      * @param {SectionModule} props
      * @return {JSX.Element|null}
      * @author Isaac Ewing
      * @version 1.0.0 10/04/20 05:55 pm
      */
     protected registerTitle( props: ISectionModule ): JSX.Element | null {
          if( props?.title ) {
               return (
                    <h3 key={ this.registerHash() } className="title">
                         <JsxParser renderInWrapper={ false } components={ this.state.components } jsx={ this.state.text } onError={ this.onError } autoCloseVoidElements />
                    </h3>
               );
          }
     }

     /**
      *
      * @param props {any}
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am - documented
      */
     public constructor( props: ISectionProps ) {
          super( props );

          this.state = {
               module    : props.module,
               page      : props.page,
               text      : ShortcodeManager.parse( props.module.title.text ),
               className : new Set<string>( [ 'grid-x', Hero1.COMPONENT_CLASS ] ),
               components: {
                    Shortcode,
               },
          };
     }

     /**
      *
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/04/21 04:13 pm
      * documented
      */
     public componentDidMount(): void {
          const options: RecursivePartial<unknown> = {
               'autoPlay'              : true,
               'background'            : {
                    'image'   : '',
                    'position': '50% 50%',
                    'repeat'  : 'no-repeat',
                    'size'    : 'cover',
                    'opacity' : 1,
               },
               'backgroundMask'        : {
                    'composite': 'destination-out',
                    'cover'    : {
                         'color'  : {
                              'value': Hero1.PARTICLE_COLOR,
                         },
                         'opacity': 1,
                    },
                    'enable'   : false,
               },
               'fullScreen'            : {
                    'enable': false,
                    'zIndex': 1,
               },
               'detectRetina'          : true,
               'fpsLimit'              : 60,
               'infection'             : {
                    'cure'      : false,
                    'delay'     : 0,
                    'enable'    : false,
                    'infections': 0,
                    'stages'    : [],
               },
               'interactivity'         : {
                    'detectsOn': 'canvas',
                    'events'   : {
                         'onClick': {
                              'enable': true,
                              'mode'  : 'repulse',
                         },
                         'onDiv'  : {
                              'selectors': [],
                              'enable'   : false,
                              'mode'     : [],
                              'type'     : 'circle',
                         },
                         'onHover': {
                              'enable'  : true,
                              'mode'    : 'attract',
                              'parallax': {
                                   'enable': false,
                                   'force' : 2,
                                   'smooth': 10,
                              },
                         },
                         'resize' : true,
                    },
                    'modes'    : {
                         'attract': {
                              'distance': 400,
                              'duration': 1.5,
                              'speed'   : 2.25,
                         },
                         'bounce' : {
                              'distance': 200,
                         },
                         'bubble' : {
                              'distance': 400,
                              'duration': 0.3,
                              'opacity' : 1,
                              'size'    : 4,
                         },
                         'connect': {
                              'distance': 80,
                              'links'   : {
                                   'opacity': 0.5,
                              },
                              'radius'  : 60,
                         },
                         'grab'   : {
                              'distance': 400,
                              'links'   : {
                                   'blink'  : false,
                                   'consent': false,
                                   'opacity': 0.5,
                              },
                         },
                         'light'  : {
                              'area'  : {
                                   'gradient': {
                                        'start': {
                                             'value': Hero1.PARTICLE_COLOR,
                                        },
                                        'stop' : {
                                             'value': '#000000',
                                        },
                                   },
                                   'radius'  : 1000,
                              },
                              'shadow': {
                                   'color' : {
                                        'value': '#000000',
                                   },
                                   'length': 2000,
                              },
                         },
                         'push'   : {
                              'quantity': 4,
                         },
                         'remove' : {
                              'quantity': 2,
                         },
                         'repulse': {
                              'distance': 150,
                              'duration': 1.25,
                              'speed'   : 3.75,
                         },
                         'slow'   : {
                              'factor': 3,
                              'radius': 200,
                         },
                         'trail'  : {
                              'delay'   : 1,
                              'quantity': 1,
                         },
                    },
               },
               'manualParticles'       : [],
               'motion'                : {
                    'disable': false,
                    'reduce' : {
                         'factor': 4,
                         'value' : true,
                    },
               },
               'particles'             : {
                    'bounce'          : {
                         'horizontal': {
                              'random': {
                                   'enable'      : false,
                                   'minimumValue': 0.1,
                              },
                              'value' : 1,
                         },
                         'vertical'  : {
                              'random': {
                                   'enable'      : false,
                                   'minimumValue': 0.1,
                              },
                              'value' : 1,
                         },
                    },
                    'collisions'      : {
                         'bounce' : {
                              'horizontal': {
                                   'random': {
                                        'enable'      : false,
                                        'minimumValue': 0.1,
                                   },
                                   'value' : 1,
                              },
                              'vertical'  : {
                                   'random': {
                                        'enable'      : false,
                                        'minimumValue': 0.1,
                                   },
                                   'value' : 1,
                              },
                         },
                         'enable' : false,
                         'mode'   : 'bounce',
                         'overlap': {
                              'enable' : true,
                              'retries': 0,
                         },
                    },
                    'color'           : {
                         'value'    : Hero1.PARTICLE_COLOR,
                         'animation': {
                              'h': {
                                   'count' : 0,
                                   'enable': false,
                                   'offset': 0,
                                   'speed' : 1,
                                   'sync'  : true,
                              },
                              's': {
                                   'count' : 0,
                                   'enable': false,
                                   'offset': 0,
                                   'speed' : 1,
                                   'sync'  : true,
                              },
                              'l': {
                                   'count' : 0,
                                   'enable': false,
                                   'offset': 0,
                                   'speed' : 1,
                                   'sync'  : true,
                              },
                         },
                    },
                    'destroy'         : {
                         'mode' : 'none',
                         'split': {
                              'count' : 1,
                              'factor': {
                                   'random': {
                                        'enable'      : false,
                                        'minimumValue': 0,
                                   },
                                   'value' : 3,
                              },
                              'rate'  : {
                                   'random': {
                                        'enable'      : false,
                                        'minimumValue': 0,
                                   },
                                   'value' : {
                                        'min': 4,
                                        'max': 9,
                                   },
                              },
                         },
                    },
                    'life'            : {
                         'count'   : 0,
                         'delay'   : {
                              'random': {
                                   'enable'      : false,
                                   'minimumValue': 0,
                              },
                              'value' : 0,
                              'sync'  : false,
                         },
                         'duration': {
                              'random': {
                                   'enable'      : false,
                                   'minimumValue': 0.0001,
                              },
                              'value' : 0,
                              'sync'  : false,
                         },
                    },
                    'links'           : {
                         'blink'    : false,
                         'color'    : {
                              'value': Hero1.PARTICLE_COLOR,
                         },
                         'consent'  : false,
                         'distance' : 500,
                         'enable'   : false,
                         'frequency': 1,
                         'opacity'  : 0.4,
                         'shadow'   : {
                              'blur'  : 5,
                              'color' : {
                                   'value': '#00ff00',
                              },
                              'enable': false,
                         },
                         'triangles': {
                              'enable'   : false,
                              'frequency': 1,
                         },
                         'width'    : 2,
                         'warp'     : false,
                    },
                    'move'            : {
                         'angle'    : {
                              'offset': 45,
                              'value' : 90,
                         },
                         'attract'  : {
                              'enable': false,
                              'rotate': {
                                   'x': 600,
                                   'y': 1200,
                              },
                         },
                         'decay'    : 0,
                         'distance' : 0,
                         'direction': 'bottom',
                         'drift'    : 0,
                         'enable'   : true,
                         'gravity'  : {
                              'acceleration': 9.81,
                              'enable'      : false,
                              'maxSpeed'    : 50,
                         },
                         'path'     : {
                              'clamp' : true,
                              'delay' : {
                                   'random': {
                                        'enable'      : false,
                                        'minimumValue': 0,
                                   },
                                   'value' : 0,
                              },
                              'enable': false,
                         },
                         'outModes' : {
                              'default': process.env.REACT_APP_HERO_PARTICLE_OUT_MODE,
                              'bottom' : process.env.REACT_APP_HERO_PARTICLE_OUT_MODE,
                              'left'   : process.env.REACT_APP_HERO_PARTICLE_MOVE_MODE,
                              'right'  : process.env.REACT_APP_HERO_PARTICLE_MOVE_MODE,
                              'top'    : process.env.REACT_APP_HERO_PARTICLE_OUT_MODE,
                         },
                         'random'   : true,
                         'size'     : false,
                         'speed'    : Hero1.PARTICLE_SPEED,
                         'straight' : false,
                         'trail'    : {
                              'enable'   : false,
                              'length'   : 10,
                              'fillColor': {
                                   'value': '#000000',
                              },
                         },
                         'vibrate'  : false,
                         'warp'     : false,
                    },
                    'number'          : {
                         'density': {
                              'enable': true,
                              'area'  : 800,
                              'factor': 1000,
                         },
                         'limit'  : 0,
                         'value'  : +process.env.REACT_APP_HERO_PARTICLE_COUNT,
                    },
                    'opacity'         : {
                         'random'   : {
                              'enable'      : true,
                              'minimumValue': 0.1,
                         },
                         'value'    : {
                              'min': 0.1,
                              'max': 0.5,
                         },
                         'animation': {
                              'count'       : 0,
                              'enable'      : false,
                              'speed'       : 1,
                              'sync'        : false,
                              'destroy'     : 'none',
                              'minimumValue': 0.1,
                              'startValue'  : 'random',
                         },
                    },
                    'reduceDuplicates': false,
                    'rotate'          : {
                         'random'   : {
                              'enable'      : false,
                              'minimumValue': 0,
                         },
                         'value'    : 0,
                         'animation': {
                              'enable': false,
                              'speed' : 0,
                              'sync'  : false,
                         },
                         'direction': 'clockwise',
                         'path'     : false,
                    },
                    'shadow'          : {
                         'blur'  : 0,
                         'color' : {
                              'value': '#000000',
                         },
                         'enable': false,
                         'offset': {
                              'x': 0,
                              'y': 0,
                         },
                    },
                    'shape'           : {
                         'options': {},
                         'type'   : 'circle',
                    },
                    'size'            : {
                         'random'   : {
                              'enable'      : true,
                              'minimumValue': 1,
                         },
                         'value'    : {
                              'min': 1,
                              'max': 10,
                         },
                         'animation': {
                              'count'       : 0,
                              'enable'      : false,
                              'speed'       : 40,
                              'sync'        : false,
                              'destroy'     : 'none',
                              'minimumValue': 0.1,
                              'startValue'  : 'random',
                         },
                    },
                    'stroke'          : {
                         'width': 0,
                    },
                    'twinkle'         : {
                         'lines'    : {
                              'enable'   : false,
                              'frequency': 0.05,
                              'opacity'  : 1,
                         },
                         'particles': {
                              'enable'   : false,
                              'frequency': 0.05,
                              'opacity'  : 1,
                         },
                    },
               },
               'pauseOnBlur'           : true,
               'pauseOnOutsideViewport': true,
               'responsive'            : [],
               'themes'                : [],
          };
          const hash: string                       = this.registerHash();
          const props: IParticlesProps             = {
               id       : `${ hash }`,
               className: 'particles',
               options,
          };

          console.info( '!@#$%^&* PARTICLES', { hash, options } );
          const particles: JSX.Element = <Particles key={ hash } { ...props } />;

          this.setState( { particles } );
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am
      * documented
      */
     public render(): JSX.Element {
          let titleClass = 'cell small-10 medium-8 small-offset-1 medium-offset-2 align-center';

          if( URLManager.isHomePage ) {
               titleClass = 'cell small-12';
          }

          return (
               <section { ...this.registerSectionProps() }>
                    <div className="grid-container content">
                         <div className="grid-x">
                              <div className={ titleClass }>
                                   { this.registerTitle( this.state.module ) }
                              </div>
                              <div className="cell small-10 medium-8 small-offset-1 medium-offset-2">
                                   <Subtitle module={ this.state.module.subtitle as TitleModule } />
                              </div>
                         </div>
                    </div>
                    { this.state.children }
                    { this.state.particles }
               </section>
          );
     }
}

export default Hero1;