import React     from 'react';
import PropTypes from 'prop-types';
import { Base }  from './Base';

export class Divider extends Base<any, any> {
     protected static readonly COMPONENT_CLASS: string = 'divider';
     public static readonly POSITION_TOP: string       = 'top';
     public static readonly POSITION_BOTTOM: string    = 'bottom';
     public static propTypes: any                      = {
          ...Base.propTypes,
          position: PropTypes.string,
          top     : PropTypes.bool,
          bottom  : PropTypes.bool,
     };

     public static defaultProps = {
          position: Divider.POSITION_TOP,
     };

     public constructor( props: any ) {
          super( props );

          this.state = {
               className: new Set<string>( [ Divider.COMPONENT_CLASS ] ),
          };

          this.registerStyle( props );
          this.registerClasses( props );
     }

     protected registerStyle( props ): void {
          if( props.top ) {
               this.state.className.add( Divider.POSITION_TOP );
          } else if( props.bottom ) {
               this.state.className.add( Divider.POSITION_BOTTOM );
          } else if( props.position ) {
               switch( props.position ) {
                    case Divider.POSITION_TOP:
                    case Divider.POSITION_BOTTOM:
                         this.state.className.add( props.position );
                         break;
                    default:
                         this.state.className.add( Divider.defaultProps.position );
               }
          }
     }

     public render(): JSX.Element {
          return (
               <div className={ [ ...this.state.className ].join( ' ' ) } />
          );
     }
}