import PropTypes                             from 'prop-types';
import ReactVisibilitySensor                 from 'react-visibility-sensor';
import { GalleryModule, GalleryImageModule } from '../../api/com/ewing/social/component';
import { IGalleryProps, IGalleryState }      from '../../api/com/ewing/social/interface';
import { RegisterTool, Util }                from '../../api/com/ewing/social/tool';
import { Base }                              from '../atom/Base';
import { Thumbnail }                         from './Thumbnail';

/**
 * @class Gallery
 * @extends Base
 * @author Isaac Ewing
 * @version 1.0.0 12/05/20 01:57 am
 */
export class Gallery extends Base<Partial<IGalleryProps>, Partial<IGalleryState>> {
    protected static readonly COMPONENT_CLASS: string   = 'gallery';
    protected static readonly CLASS_FULL_WIDTH: string  = 'full-width';
    protected static readonly CLASS_YOUTUBE: string     = 'youtube';
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 05/29/21 03:40 pm
     */
    protected static readonly SOCIAL_BLOCK: string      = 'platform-block';
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 04/11/21 06:56 pm
     */
    protected static readonly CLASS_TWO_COLUMNS: string = 'two-columns';
    public static propTypes                             = {
        ...Base.propTypes,
        module    : PropTypes.instanceOf( GalleryModule ).isRequired,
        social    : PropTypes.bool,
        wishlist  : PropTypes.bool,
        fullWidth : PropTypes.bool,
        youtube   : PropTypes.bool,
        equalize  : PropTypes.bool,
        twoColumns: PropTypes.bool,
    };
    /**
     *
     * @type {IGalleryState}
     * @readonly
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 05/29/21 03:46 pm
     */
    public readonly props: Partial<IGalleryProps>;
    /**
     *
     * @type {IGalleryState}
     * @readonly
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 05/29/21 03:46 pm
     */
    public readonly state: Partial<IGalleryState>;

    /**
     *
     * @param {boolean} visible
     * @protected
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/04/21 03:57 pm
     */
    protected onChange( visible: boolean ): void {
        if( visible && !this.state.initialized ) {
            this.setState( {
                               initialized     : true,
                               className       : new Set<string>( [ ...this.state.className, 'animation' ] ),
                               visibilitySensor: {
                                   active  : false,
                                   onChange: null,
                               },
                           } );
        }
    }

    /**
     *
     * @param {GalleryModule} props
     * @return {void}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/05/20 10:24 pm
     */
    protected registerFullWidthGallery( props: GalleryModule ): void {
        //const total: number           = props.images.size;
        const thumbs: JSX.Element[] = [];
        //const classForSmall: string[] = [ '', 'show-for-medium' ];
        const classes: string[]     = [ 'cell small-12 medium-4' ];
        //const isOneRow: boolean       = total % 3 === 0;
        const isYouTube: boolean    = this.state.youtube;
        const keys                  = {
            key      : Util.registerHash(),
            id       : `full-width-${ Util.registerHash() }`,
            className: [ 'grid-x' ].join( ' ' ),
        };
        let count                   = 0;

        props.images.forEach( ( child: GalleryImageModule ): void => {
            const keys = {
                key      : `gallery-image-${ count + 1 }`,
                className: [ ...classes ].join( ' ' ),
            };

            /*
             if( isOneRow && count === 2 ) {
             keys.className += classForSmall.join( ' ' );
             }

             */
            if( !isYouTube ) {
                thumbs.push( <div { ...keys }><Thumbnail module={ child } /></div> );
            } else {
                thumbs.push( <div { ...keys }><Thumbnail module={ child } youtube /></div> );
            }

            count++;
        } );

        this.state.children.add( <div { ...keys }>{ thumbs }</div> );
        this.state.className.add( Gallery.CLASS_FULL_WIDTH );
    }

    /**
     *
     * @param {GalleryModule} props
     * @return void
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 11/25/20 03:12 pm
     */
    protected registerSocialGallery( props: GalleryModule ): void {
        // const total: number         = props.images.length;
        const thumbs: Set<JSX.Element> = new Set( [] );
        const isYouTube: boolean       = this.state.youtube;
        const isEqualize: boolean      = this.state.equalize;
        const isTwoColumns: boolean    = this.state.twoColumns;
        const keys                     = {
            key      : Util.registerHash(),
            id       : `social-${ Util.registerHash() }`,
            className: 'grid-x',
        };
        let count                      = 0;
        let classes: string;

        if( isTwoColumns ) {
            this.state.className.add( Gallery.CLASS_TWO_COLUMNS );
            classes = [ 'cell small-6 medium-4', Gallery.SOCIAL_BLOCK ].join( ' ' );
        } else {
            classes = [ 'cell small-12 medium-4', Gallery.SOCIAL_BLOCK ].join( ' ' );
        }

        props.images.forEach( ( child: GalleryImageModule ): void => {
            const keys = {
                key      : `gallery-image-${ count + 1 }`,
                className: classes,
            };

            if( !isYouTube ) {
                if( isEqualize ) {
                    keys.className = [ 'cell', Gallery.SOCIAL_BLOCK ].join( ' ' );
                    thumbs.add( <div { ...keys } data-equalizer-watch={ true }><Thumbnail module={ child } social equalize /></div> );
                } else {
                    thumbs.add( <div { ...keys }><Thumbnail module={ child } social /></div> );
                }
            } else {
                thumbs.add( <div { ...keys }><Thumbnail module={ child } social youtube /></div> );
            }

            count++;
        } );

        if( this.state.youtube ) {
            this.state.className.add( Gallery.CLASS_YOUTUBE );
        }
        if( isEqualize ) {
            keys.className += ' grid-padding-x small-up-1 medium-up-2 large-up-3';
            this.state.children.add( <div { ...keys } data-equalizer={ true } data-equalize-by-row={ true }>{ thumbs }</div> );
        } else {
            this.state.children.add( <div { ...keys }>{ thumbs }</div> );
        }
    }

    /**
     *
     * @param {GalleryModule} props
     * @return void
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 11/25/20 01:51 pm
     */
    protected registerLargeGallery( props: GalleryModule ): void {
        // const total: number         = props.images.length;
        const thumbs: JSX.Element[] = [];
        const classes: string[]     = [ 'cell small-12 medium-4' ];
        const keys                  = {
            key      : Util.registerHash(),
            className: 'grid-x',
        };
        let count                   = 0;

        props.images.forEach( ( child: GalleryImageModule ): void => {
            const keys = {
                key      : `gallery-image-${ count + 1 }`,
                className: classes.join( ' ' ),
            };
            count++;
            thumbs.push( <div { ...keys }><Thumbnail module={ child } /></div> );
        } );

        this.state.children.add( <div { ...keys }>{ thumbs }</div> );
    }

    /**
     *
     * @param {GalleryModule} props
     * @return void
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 11/25/20 02:20 pm
     */
    protected registerNormalGallery( props: GalleryModule ): void {
        const total: number         = props.images.size;
        const thumbs: JSX.Element[] = [];
        const classes: string[]     = this.state.classes[ total ];
        const keys                  = {
            key      : total.toString(),
            className: 'grid-x',
        };
        let count                   = 0;

        props.images.forEach( ( child: GalleryImageModule ): void => {
            const keys = {
                key      : ( count + 1 ).toString(),
                className: classes[ count ],
            };
            thumbs.push( <div { ...keys }><Thumbnail module={ child } /></div> );
            count++;
            //console.log('*** trying serialize thumbnail',serializer)
        } );

        this.state.children.add( <div { ...keys }>{ thumbs }</div> );
    }

    /**
     *
     * @param props {GalleryModule}
     * @return void
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 11/25/20 01:50 pm
     */
    protected registerThumbnails( props: GalleryModule ): void {
        const total: number = props.images.size;

        if( total < 3 ) {
            this.registerLargeGallery( props );
        } else if( total === 3 || total === 4 ) {
            this.registerNormalGallery( props );
        } else if( total > 4 ) {
            this.registerLargeGallery( props );
        } else {
            throw new Error( 'The gallery does not have a valid anything... skipping...' );
        }
    }

    /**
     *
     * @param {IGalleryProps} props
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/05/20 01:57 am
     */
    public constructor( props: IGalleryProps ) {
        super( props );

        this.state = {
            initialized     : false,
            social          : !!props.social,
            wishlist        : !!props.wishlist,
            youtube         : !!props.youtube,
            equalize        : !!props.equalize,
            twoColumns      : !!props.twoColumns,
            className       : new Set<string>( [ Gallery.COMPONENT_CLASS ] ),
            classes         : {
                3: [
                    'cell small-4 medium-3',
                    'cell small-4 medium-6',
                    'cell small-4 medium-3',
                ],
                4: [
                    'cell small-3 medium-2',
                    'cell small-3 medium-4',
                    'cell small-3 medium-4',
                    'cell small-3 medium-2',
                ],
            },
            children        : new Set<JSX.Element>( [] ),
            visibilitySensor: {
                active           : true,
                partialVisibility: true,
                onChange         : ( visible: boolean ): void => { this.onChange( visible ); },
            },
        };

        //this.registerStyle(props);
        RegisterTool.align( props?.module ?? null, this.state ?? null );
        RegisterTool.position( props?.module ?? null, this.state ?? null );
        RegisterTool.classes( props ?? null, this.state ?? null );
        RegisterTool.children( props ?? null, this.state ?? null );

        if( props.fullWidth ) {
            this.registerFullWidthGallery( props.module as GalleryModule );
        } else if( !props.social ) {
            this.registerThumbnails( props.module as GalleryModule );
        } else {
            this.state.className.add( 'social' );
            this.registerSocialGallery( props.module as GalleryModule );
        }
        if( props.wishlist ) {
            this.state.className.add( 'wishlist' );
        }
    }

    /**
     *
     * @return {JSX.Element}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/05/20 01:57 am
     */
    public render(): JSX.Element {
        return (
            <ReactVisibilitySensor { ...this.state.visibilitySensor }>
                <div className={ [ ...this.state.className ].join( ' ' ) } data-component-gallery="">
                    { this.state.children }
                </div>
            </ReactVisibilitySensor>
        );
    }
}