import { AModule }       from '../../../abstract/module.abstract';
import { ISessionProps } from '../../../interface';

/**
 * @class SessionModule
 * @extends AModule
 * @implements ISessionProps
 * @author Isaac Ewing
 * @version 1.0.0 01/25/21 12:58 pm
 * @version 1.1.0 05/07/21 02:11 pm - changed base class to AModule
 */
export class SessionModule extends AModule implements ISessionProps {
     /**
      *
      * @param module {SessionModule} The object containing the data
      * @return {SessionModule | null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/25/21 12:58 pm
      * @see id
      * @see action
      * @see code
      * @see provider
      * @see domain
      * @see socket
      * @see hash
      * @see state
      */
     public static Build( module: SessionModule ): SessionModule;
     public static Build( obj: Partial<ISessionProps> ): SessionModule;
     public static Build( json: string ): SessionModule;
     public static Build( action?: string, code?: string, provider?: string, domain?: string, socket?: string, hash?: string, state?: string ): SessionModule;
     public static Build( dataOrAction?: unknown, code?: string, provider?: string, domain?: string, socket?: string, hash?: string, state?: string ): SessionModule {
          if( dataOrAction ) {
               if( dataOrAction instanceof SessionModule ) {
                    return dataOrAction;
               }
               if( typeof dataOrAction === 'object' ) {
                    const localData: Partial<ISessionProps> = dataOrAction;

                    return new SessionModule( localData?.action ?? null, localData?.code ?? null, localData?.provider ?? null, localData?.domain ?? null,
                                              localData?.socket ?? null, localData?.hash ?? null, localData?.state ?? null );
               }
               if( typeof dataOrAction === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrAction ) );
                    } catch( exception ) {
                         return new SessionModule( `${ dataOrAction }`, code ?? null, provider ?? null, domain ?? null, socket ?? null, hash ?? null,
                                                   state ?? null );
                    }
               }
          }

          return null;
     }

     /**
      *
      * @param {string} action
      * @param {string} code
      * @param {string} provider
      * @param {string} domain
      * @param {string} socket
      * @param {string} hash
      * @param {string} state
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/25/21 12:58 pm
      * @see id
      * @see action
      * @see code
      * @see provider
      * @see domain
      * @see socket
      * @see hash
      * @see state
      */
     public constructor( action: string, code?: string, provider?: string, domain?: string, socket?: string, hash?: string, state?: string ) {
          super( null );

          this.action   = action ?? null;
          this.code     = code ?? null;
          this.provider = provider ?? null;
          this.domain   = domain ?? null;
          this.socket   = socket ?? null;
          this.hash     = hash ?? null;
          this.state    = state ?? null;
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/25/21 01:27 pm
      * @see id
      * @see action
      * @see code
      * @see provider
      * @see domain
      * @see socket
      * @see hash
      * @see state
      */
     public get action(): string {
          return this._data.get( 'action' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/25/21 01:27 pm
      * @see id
      * @see action
      * @see code
      * @see provider
      * @see domain
      * @see socket
      * @see hash
      * @see state
      */
     public set action( value: string ) {
          this._data.set( 'action', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/25/21 01:27 pm
      * @see id
      * @see action
      * @see code
      * @see provider
      * @see domain
      * @see socket
      * @see hash
      * @see state
      */
     public get code(): string {
          return this._data.get( 'code' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/25/21 01:27 pm
      * @see id
      * @see action
      * @see code
      * @see provider
      * @see domain
      * @see socket
      * @see hash
      * @see state
      */
     public set code( value: string ) {
          this._data.set( 'code', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/25/21 01:27 pm
      * @see id
      * @see action
      * @see code
      * @see provider
      * @see domain
      * @see socket
      * @see hash
      * @see state
      */
     public get provider(): string {
          return this._data.get( 'provider' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/25/21 01:27 pm
      * @see id
      * @see action
      * @see code
      * @see provider
      * @see domain
      * @see socket
      * @see hash
      * @see state
      */
     public set provider( value: string ) {
          this._data.set( 'provider', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/25/21 01:27 pm
      * @see id
      * @see action
      * @see code
      * @see provider
      * @see domain
      * @see socket
      * @see hash
      * @see state
      */
     public get domain(): string {
          return this._data.get( 'domain' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/25/21 01:27 pm
      * @see id
      * @see action
      * @see code
      * @see provider
      * @see domain
      * @see socket
      * @see hash
      * @see state
      */
     public set domain( value: string ) {
          this._data.set( 'domain', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/25/21 01:27 pm
      * @see id
      * @see action
      * @see code
      * @see provider
      * @see domain
      * @see socket
      * @see hash
      * @see state
      */
     public get socket(): string {
          return this._data.get( 'socket' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/25/21 01:27 pm
      * @see id
      * @see action
      * @see code
      * @see provider
      * @see domain
      * @see socket
      * @see hash
      * @see state
      */
     public set socket( value: string ) {
          this._data.set( 'socket', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/25/21 01:27 pm
      * @see id
      * @see action
      * @see code
      * @see provider
      * @see domain
      * @see socket
      * @see hash
      * @see state
      */
     public get hash(): string {
          return this._data.get( 'hash' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/25/21 01:27 pm
      * @see id
      * @see action
      * @see code
      * @see provider
      * @see domain
      * @see socket
      * @see hash
      * @see state
      */
     public set hash( value: string ) {
          this._data.set( 'hash', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/25/21 01:27 pm
      * @see id
      * @see action
      * @see code
      * @see provider
      * @see domain
      * @see socket
      * @see hash
      * @see state
      */
     public get state(): string {
          return this._data.get( 'state' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/25/21 01:27 pm
      * @see id
      * @see action
      * @see code
      * @see provider
      * @see domain
      * @see socket
      * @see hash
      * @see state
      */
     public set state( value: string ) {
          this._data.set( 'state', value );
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/25/21 01:27 pm
      * @see id
      * @see action
      * @see code
      * @see provider
      * @see domain
      * @see socket
      * @see hash
      * @see state
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public toObject(): Record<string, unknown> {
          return {
               id      : this.id,
               action  : this.action,
               invite  : this.code,
               provider: this.provider,
               domain  : this.domain,
               socket  : this.socket,
               hash    : this.hash,
               state   : this.state,
          };
     }
}