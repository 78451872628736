import { AComponent } from '../../../abstract';
import {
    InfoModule, OverlayModule, ScriptModule,
    MapModule as MapperModule,
}                     from '../../../module/map';

export class MapModule extends AComponent {
    /**
     * @param data {Object|null} The object containing the data
     * @return {MapModule|null} Returns a new instance of the module or null
     * @constructor
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/09/20 12:34 pm
     */
    public static Build( data: unknown ): MapModule | null {
        if( data ) {
            if( typeof data === 'string' ) {
                data = JSON.parse( data );
            }

            return new MapModule( data as Record<string, never> );
        }

        return null;
    }

    public constructor( data: Record<string, never> ) {
        super( data?.id ?? null );

        this.script = ScriptModule.Build( data?.script ?? {} );
        this._data.set( 'map', MapperModule.Build( data?.map ?? {} ) );
        this._data.set( 'info', InfoModule.Build( data?.info ?? {} ) );
        this._data.set( 'window', OverlayModule.Build( data?.window ?? {} ) );
        this._data.set( 'marker', OverlayModule.Build( data?.marker ?? {} ) );

        if( !this.window.enabled && !this.marker.enabled ) {
            this.window.enable();
        }
    }

    /**
     *
     * @return {ScriptModule}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/09/20 02:03 pm
     * @see script
     * @see map
     * @see info
     * @see window
     * @see marker
     */
    public get script(): ScriptModule {
        return this._data.get( 'script' );
    }

    public set script( value: ScriptModule ) {
        this._data.set( 'script', value );
    }

    /**
     *
     * @return {MapModule}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/09/20 02:03 pm
     * @see script
     * @see map
     * @see info
     * @see window
     * @see marker
     */
    public get map(): MapperModule {
        return this._data.get( 'map' );
    }

    /**
     *
     * @return {InfoModule}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/09/20 02:03 pm
     * @see script
     * @see map
     * @see info
     * @see window
     * @see marker
     */
    public get info(): InfoModule {
        return this._data.get( 'info' );
    }

    /**
     *
     * @return {OverlayModule}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/09/20 02:03 pm
     * @see script
     * @see map
     * @see info
     * @see window
     * @see marker
     */
    public get window(): OverlayModule {
        return this._data.get( 'window' );
    }

    /**
     *
     * @return {OverlayModule}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/09/20 02:03 pm
     * @see script
     * @see map
     * @see info
     * @see window
     * @see marker
     */
    public get marker(): OverlayModule {
        return this._data.get( 'marker' );
    }
}