import { AModule }           from '../../abstract/module.abstract';
import { IContactModule }    from '../../interface';
import { ContactActionEnum } from '../../enum';
import { Util }              from '../../tool';
import { URLManager }        from '../../manager';

/**
 * @class ContactModule
 * @extends AModule
 * @implements IContactModule
 * @author Isaac Ewing
 * @version 1.0.0 12/06/20 03:50 pm
 * @version 1.1.0 02/13/21 06:52 pm - updated to support the correct overloading for build
 * @version 1.2.0 02/18/21 03:53 pm - updated to extend AComponent (through link module through title module)
 * @classdesc This generic class handles the storage of input data from contact api endpoint.
 */
export class ContactModule extends AModule implements IContactModule {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 03/18/21 02:37 pm
      */
     protected static readonly DEFAULT_ACTION: string = ContactActionEnum.Contact;

     /**
      *
      * @param {ContactModule} module
      * @return {ContactModule}
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/06/20 03:50 pm
      * @version 1.1.0 02/13/21 06:52 pm - updated to support the correct overloading for build
      * @see id
      * @see action
      * @see domain
      * @see page
      * @see name
      * @see email
      * @see message
      * @see setId
      * @see setAction
      * @see setDomain
      * @see setPage
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toArray
      * @see toString
      */
     public static Build( module: ContactModule ): ContactModule;
     public static Build( obj: Partial<IContactModule> ): ContactModule;
     public static Build( json: string ): ContactModule;
     public static Build( action?: string, domain?: string, page?: string, name?: string, email?: string, message?: string ): ContactModule;
     public static Build( dataOrAction?: unknown, domain?: string, page?: string, name?: string, email?: string, message?: string ): ContactModule {
          if( dataOrAction ) {
               if( dataOrAction instanceof ContactModule ) {
                    return dataOrAction;
               }
               if( typeof dataOrAction === 'object' ) {
                    const localData: Partial<IContactModule> = dataOrAction;

                    return new ContactModule( localData?.action ?? null, localData?.domain ?? null, localData?.page ?? null, localData?.name ?? null,
                                              localData?.email ?? null, localData?.message ?? null );
               }
               if( typeof dataOrAction === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrAction ) );
                    } catch( exception:unknown ) {
                         // not a valid json string
                         return new ContactModule( dataOrAction ?? null, domain ?? null, page ?? null, name ?? null, email ?? null, message ?? null );
                    }
               }
          }

          return null;
     }

     /**
      *
      * @param {string} action
      * @param {string} domain
      * @param {string} page
      * @param {string} name
      * @param {string} email
      * @param {string} message
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/06/20 03:50 pm
      * @version 1.1.0 02/13/21 06:52 pm - updated to support the correct overloading for build
      * @see id
      * @see action
      * @see domain
      * @see page
      * @see name
      * @see email
      * @see message
      * @see setId
      * @see setAction
      * @see setDomain
      * @see setPage
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toArray
      * @see toString
      */
     public constructor( action?: string, domain?: string, page?: string, name?: string, email?: string, message?: string ) {
          super( null );

          this.action  = action ?? ContactModule.DEFAULT_ACTION;
          this.domain  = Util.cleanPath( domain ?? URLManager.Domain, false );
          this.page    = Util.cleanPath( page ?? URLManager.Page, true );
          this.name    = name ?? ContactModule.DEFAULT_VALUE;
          this.email   = email ?? ContactModule.DEFAULT_VALUE;
          this.message = message ?? ContactModule.DEFAULT_VALUE;
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see action
      * @see domain
      * @see page
      * @see name
      * @see email
      * @see message
      * @see setId
      * @see setAction
      * @see setDomain
      * @see setPage
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toArray
      * @see toString
      */
     public get action(): string {
          return this._data.get( 'action' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see action
      * @see domain
      * @see page
      * @see name
      * @see email
      * @see message
      * @see setId
      * @see setAction
      * @see setDomain
      * @see setPage
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toArray
      * @see toString
      */
     public set action( value: string ) {
          this._data.set( 'action', Util.cleanPath( value ?? '', false ) );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see action
      * @see domain
      * @see page
      * @see name
      * @see email
      * @see message
      * @see setId
      * @see setAction
      * @see setDomain
      * @see setPage
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toArray
      * @see toString
      */
     public get domain(): string {
          return this._data.get( 'domain' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see action
      * @see domain
      * @see page
      * @see name
      * @see email
      * @see message
      * @see setId
      * @see setAction
      * @see setDomain
      * @see setPage
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toArray
      * @see toString
      */
     public set domain( value: string ) {
          this._data.set( 'domain', Util.cleanPath( value ?? URLManager.Domain, false ) );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see action
      * @see domain
      * @see page
      * @see name
      * @see email
      * @see message
      * @see setId
      * @see setAction
      * @see setDomain
      * @see setPage
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toArray
      * @see toString
      */
     public get page(): string {
          return this._data.get( 'page' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see action
      * @see domain
      * @see page
      * @see name
      * @see email
      * @see message
      * @see setId
      * @see setAction
      * @see setDomain
      * @see setPage
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toArray
      * @see toString
      */
     public set page( value: string ) {
          this._data.set( 'page', Util.cleanPath( value ?? URLManager.Page, true ) );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see action
      * @see domain
      * @see page
      * @see name
      * @see email
      * @see message
      * @see setId
      * @see setAction
      * @see setDomain
      * @see setPage
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toArray
      * @see toString
      */
     public get name(): string {
          return this._data.get( 'name' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see action
      * @see domain
      * @see page
      * @see name
      * @see email
      * @see message
      * @see setId
      * @see setAction
      * @see setDomain
      * @see setPage
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toArray
      * @see toString
      */
     public set name( value: string ) {
          this._data.set( 'name', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see action
      * @see domain
      * @see page
      * @see name
      * @see email
      * @see message
      * @see setId
      * @see setAction
      * @see setDomain
      * @see setPage
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toArray
      * @see toString
      */
     public get email(): string {
          return this._data.get( 'email' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see action
      * @see domain
      * @see page
      * @see name
      * @see email
      * @see message
      * @see setId
      * @see setAction
      * @see setDomain
      * @see setPage
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toArray
      * @see toString
      */
     public set email( value: string ) {
          this._data.set( 'email', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see action
      * @see domain
      * @see page
      * @see name
      * @see email
      * @see message
      * @see setId
      * @see setAction
      * @see setDomain
      * @see setPage
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toArray
      * @see toString
      */
     public get message(): string {
          return this._data.get( 'message' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see action
      * @see domain
      * @see page
      * @see name
      * @see email
      * @see message
      * @see setId
      * @see setAction
      * @see setDomain
      * @see setPage
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toArray
      * @see toString
      */
     public set message( value: string ) {
          this._data.set( 'message', value );
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see action
      * @see domain
      * @see page
      * @see name
      * @see email
      * @see message
      * @see setId
      * @see setAction
      * @see setDomain
      * @see setPage
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toArray
      * @see toString
      */
     public setAction( value: string ): this {
          this.action = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see action
      * @see domain
      * @see page
      * @see name
      * @see email
      * @see message
      * @see setId
      * @see setAction
      * @see setDomain
      * @see setPage
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toArray
      * @see toString
      */
     public setDomain( value: string ): this {
          this.domain = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see action
      * @see domain
      * @see page
      * @see name
      * @see email
      * @see message
      * @see setId
      * @see setAction
      * @see setDomain
      * @see setPage
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toArray
      * @see toString
      */
     public setPage( value: string ): this {
          this.page = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see action
      * @see domain
      * @see page
      * @see name
      * @see email
      * @see message
      * @see setId
      * @see setAction
      * @see setDomain
      * @see setPage
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toArray
      * @see toString
      */
     public setName( value: string ): this {
          this.name = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see action
      * @see domain
      * @see page
      * @see name
      * @see email
      * @see message
      * @see setId
      * @see setAction
      * @see setDomain
      * @see setPage
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toArray
      * @see toString
      */
     public setEmail( value: string ): this {
          this.email = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see action
      * @see domain
      * @see page
      * @see name
      * @see email
      * @see message
      * @see setId
      * @see setAction
      * @see setDomain
      * @see setPage
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toArray
      * @see toString
      */
     public setMessage( value: string ): this {
          this.message = value;

          return this;
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see action
      * @see domain
      * @see page
      * @see name
      * @see email
      * @see message
      * @see setId
      * @see setAction
      * @see setDomain
      * @see setPage
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toArray
      * @see toString
      */
     public toObject(): Record<string, unknown> {
          return {
               action : this.action,
               domain : this.domain,
               page   : this.page,
               name   : this.name,
               email  : this.email,
               message: this.message,
          };
     }
}