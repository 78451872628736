import fetch, { Response }        from 'node-fetch';
import { IResponse, ISupportAPI } from '../../interface';
import { URLManager }             from '../url.manager';

/**
 * @class NewsletterManager
 * @author Isaac Ewing
 * @version 1.0.0 02/07/21 06:10 pm
 * @version 1.1.0 05/07/21 02:11 pm - changed base class to AModule
 * @version 1.2.0 05/07/21 04:53 pm - removed the base class
 */
export class NewsletterManager {
     /**
      *
      * @param {ISupportAPI} module
      * @return {Promise<IResponse>}
      * @constructor
      * @async
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/16/21 03:34 pm
      */
     public static async Subscribe( module: ISupportAPI ): Promise<IResponse> {
          const url: string        = `${ URLManager.Newsletter }`;
          const response: Response = await fetch( `${ url }`, module.toRequestBody() ?? {} );
          const json: any          = await response.json();

          console.log( '{"":""} NEWSLETTER MANAGER JSON', json );

          return json;
     }
}