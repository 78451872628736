import { AError } from '../abstract';

/**
 * @class ClassError
 * @extends AError
 * @author Isaac Ewing
 * @version 1.0.0 02/08/21 03:01 pm
 */
export class ClassError extends AError {
     /**
      * @return {void}
      * @throws {Error} Throws the custom error message
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 11/05/20 01:29 pm
      * @see missingConditionIfElse
      * @see missingConditionSwitch
      * @see overrideProperty
      * @see overrideMethod
      * @see cannotInstantiate
      * @see noSingletonSupport
      */
     public static missingConditionIfElse(): void { throw new Error( this.buildMissing( [ 'if / else', 'condition' ] ) ); }

     /**
      * @return {void}
      * @throws {Error} Throws the custom error message
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 11/05/20 01:29 pm
      * @see missingConditionIfElse
      * @see missingConditionSwitch
      * @see overrideProperty
      * @see overrideMethod
      * @see cannotInstantiate
      * @see noSingletonSupport
      */
     public static missingConditionSwitch(): void { throw new Error( this.buildMissing( [ 'switch / case', 'condition' ] ) ); }

     /**
      * @return {void}
      * @throws {Error} Throws the custom error message
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 11/05/20 01:29 pm
      * @see missingConditionIfElse
      * @see missingConditionSwitch
      * @see overrideProperty
      * @see overrideMethod
      * @see cannotInstantiate
      * @see noSingletonSupport
      */
     public static overrideProperty(): void { throw new Error( this.buildOverride( 'method' ) ); }

     /**
      * @return {void}
      * @throws {Error} Throws the custom error message
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 11/05/20 01:29 pm
      * @see missingConditionIfElse
      * @see missingConditionSwitch
      * @see overrideProperty
      * @see overrideMethod
      * @see cannotInstantiate
      * @see noSingletonSupport
      */
     public static overrideMethod(): void { throw new Error( this.buildOverride( 'method', [ '...args' ] ) ); }

     /**
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/08/21 11:18 am
      * @see missingConditionIfElse
      * @see missingConditionSwitch
      * @see overrideProperty
      * @see overrideMethod
      * @see cannotInstantiate
      * @see noSingletonSupport
      */
     public static cannotInstantiate(): void {
          throw new Error( 'This class cannot be instantiated, instead, only use the static methods...' );
     }

     /**
      * @return {void}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/16/21 04:16 pm
      * @see missingConditionIfElse
      * @see missingConditionSwitch
      * @see overrideProperty
      * @see overrideMethod
      * @see cannotInstantiate
      * @see noSingletonSupport
      */
     public static noSingletonSupport(): void {
          throw new Error( 'This singleton class does not support this method, instead, only use the static methods...' );
     }
}