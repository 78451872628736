import { ILink }       from '../../interface';
import { TitleModule } from './title.module';
import { Util }        from '../../tool';

/**
 * @class LinkModule
 * @extends TitleModule
 * @implements ILink
 * @author Isaac Ewing
 * @version 1.0.0 10/03/20 01:05 pm
 * @version 1.0.0 02/18/21 12:58 pm - updated to extend AComponent (through link module through title module)
 * @version 2.0.0 05/27/21 05:20 pm - converted from AComponent to TitleModule
 */
export class LinkModule extends TitleModule implements ILink {
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/19/21 09:29 am
     */
    protected static readonly CONSOLE_PREFIX: string     = `${ process.env.REACT_APP_CONSOLE_PREFIX_MODULE } LINK ${ process.env.REACT_APP_CONSOLE_SUFFIX_MODULE }`;
    /**
     *
     * @type {boolean}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/19/21 09:29 am
     */
    protected static readonly CONSOLE_ENABLED: boolean   = false;
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/19/21 09:29 am
     */
    protected static readonly API_ROOT_NODE: string      = process.env.REACT_APP_GRAPHQL_GET_LINK ?? '';
    /**
     *
     * @type {boolean}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 12:58 pm
     */
    protected static readonly DEFAULT_SUBMIT: boolean    = false;
    /**
     *
     * @type {boolean}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 12:58 pm
     */
    protected static readonly DEFAULT_SHORTCODE: boolean = false;

    /**
     *
     * @param module {LinkModule} The object containing the data
     * @return {LinkModule|null} Returns a new instance of the module or null
     * @constructor
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 12:58 pm
     * @see Build
     * @see BuildForShortcode
     * @see id
     * @see path
     * @see text
     * @see icon
     * @see submit
     * @see shortcode
     * @see setId
     * @see setPath
     * @see setText
     * @see setIcon
     * @see setSubmit
     * @see setShortcode
     * @see enableSubmit
     * @see disableSubmit
     * @see enableShortcode
     * @see disableShortcode
     */
    public static Build( module: LinkModule ): LinkModule;
    public static Build( obj: Partial<ILink> ): LinkModule;
    public static Build( json: string ): LinkModule;
    public static Build( id?: number | null, path?: string | null, text?: string | null, icon?: string | null, submit?: boolean, shortcode?: boolean ): LinkModule;
    public static Build( dataOrId?: unknown, path?: string, text?: string, icon?: string, submit?: boolean, shortcode?: boolean ): LinkModule | null {
        if( dataOrId ) {
            dataOrId = this.mapAPIRootNode( dataOrId as Record<string, unknown>, this.API_ROOT_NODE );

            if( dataOrId instanceof LinkModule ) {
                return dataOrId;
            }
            if( typeof dataOrId === 'object' ) {
                const localData: Partial<ILink> = dataOrId as Partial<ILink>;

                return new LinkModule( localData?.id ?? null, localData?.path ?? null, localData?.text ?? null, localData?.icon ?? null, localData?.submit ?? false,
                                       localData?.shortcode ?? false );
            }
            if( typeof dataOrId === 'number' ) {
                return new LinkModule( dataOrId ?? null, path ?? null, text ?? null, icon ?? null, submit ?? false, shortcode ?? false );
            }
            if( typeof dataOrId === 'string' ) {
                try {
                    return this.Build( JSON.parse( dataOrId ) );
                } catch( exception: unknown ) {
                    // not a valid json string
                    return new LinkModule( +dataOrId ?? null, path ?? null, text ?? null, icon ?? null, submit ?? false, shortcode ?? false );
                }
            }
        }

        return null;
    }

    /**
     *
     * @param module {LinkModule} The object containing the data
     * @return {LinkModule | null} Returns a new instance of the module or null
     * @constructor
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 12:58 pm
     * @see Build
     * @see BuildForShortcode
     * @see id
     * @see path
     * @see text
     * @see icon
     * @see submit
     * @see shortcode
     * @see setId
     * @see setPath
     * @see setText
     * @see setIcon
     * @see setSubmit
     * @see setShortcode
     * @see enableSubmit
     * @see disableSubmit
     * @see enableShortcode
     * @see disableShortcode
     */
    public static BuildForShortcode( module?: LinkModule ): LinkModule;
    public static BuildForShortcode( link?: Partial<ILink> ): LinkModule;
    public static BuildForShortcode( json?: string ): LinkModule;
    public static BuildForShortcode( id?: number, path?: string, text?: string, icon?: string, submit?: boolean ): LinkModule;
    public static BuildForShortcode( dataOrId?: never, path?: string, text?: string, icon?: string, submit?: boolean ): LinkModule {
        return LinkModule.Build( dataOrId ?? null, path ?? null, text ?? null, icon ?? null, submit ?? false, true );
    }

    /**
     *
     * @param {number} id
     * @param {string} path
     * @param {string} text
     * @param {string} icon
     * @param {boolean} submit
     * @param {boolean} shortcode
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 12:58 pm
     * @version 2.0.0 05/27/21 05:20 pm - converted from AComponent to TitleModule
     * @see id
     * @see path
     * @see text
     * @see icon
     * @see submit
     * @see shortcode
     * @see setId
     * @see setPath
     * @see setText
     * @see setIcon
     * @see setSubmit
     * @see setShortcode
     * @see enableSubmit
     * @see disableSubmit
     * @see enableShortcode
     * @see disableShortcode
     */
    public constructor( id?: number | null, path?: string | null, text?: string | null, icon?: string | null, submit?: boolean, shortcode?: boolean ) {
        super( id ?? null, text ?? null );

        this.path      = Util.cleanPath( path ?? null, false, true, false );
        this.icon      = icon ?? null;
        this.submit    = submit ?? LinkModule.DEFAULT_SUBMIT;
        this.shortcode = shortcode ?? LinkModule.DEFAULT_SHORTCODE;
        this.className = new Set<string>();
    }

    /**
     *
     * @return {string} Returns the string for the icon
     * @author Isaac Ewing
     * @version 1.0.0 10/04/20 04:24 pm
     * @see id
     * @see path
     * @see text
     * @see icon
     * @see submit
     * @see shortcode
     * @see setId
     * @see setPath
     * @see setText
     * @see setIcon
     * @see setSubmit
     * @see setShortcode
     * @see enableSubmit
     * @see disableSubmit
     * @see enableShortcode
     * @see disableShortcode
     */
    public get path(): string | null {
        return this._data.get( 'path' ) as string;
    }

    /**
     *
     * @param value {string} The string to set for the icon
     * @author Isaac Ewing
     * @version 1.0.0 10/04/20 04:24 pm
     * @see id
     * @see path
     * @see text
     * @see icon
     * @see submit
     * @see shortcode
     * @see setId
     * @see setPath
     * @see setText
     * @see setIcon
     * @see setSubmit
     * @see setShortcode
     * @see enableSubmit
     * @see disableSubmit
     * @see enableShortcode
     * @see disableShortcode
     */
    public set path( value: string | null ) {
        this._data.set( 'path', value ?? null );
    }

    /**
     *
     * @return {string} Returns the string for the icon
     * @author Isaac Ewing
     * @version 1.0.0 10/04/20 04:24 pm
     * @see id
     * @see path
     * @see text
     * @see icon
     * @see submit
     * @see shortcode
     * @see setId
     * @see setPath
     * @see setText
     * @see setIcon
     * @see setSubmit
     * @see setShortcode
     * @see enableSubmit
     * @see disableSubmit
     * @see enableShortcode
     * @see disableShortcode
     */
    public get icon(): string | null {
        return this._data.get( 'icon' );
    }

    /**
     *
     * @param value {string} The string to set for the icon
     * @author Isaac Ewing
     * @version 1.0.0 10/04/20 04:24 pm
     * @see id
     * @see path
     * @see text
     * @see icon
     * @see submit
     * @see shortcode
     * @see setId
     * @see setPath
     * @see setText
     * @see setIcon
     * @see setSubmit
     * @see setShortcode
     * @see enableSubmit
     * @see disableSubmit
     * @see enableShortcode
     * @see disableShortcode
     */
    public set icon( value: string | null ) {
        this._data.set( 'icon', value ?? null );
    }

    /**
     *
     * @return {boolean}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/03/20 12:12 pm
     * @see id
     * @see path
     * @see text
     * @see icon
     * @see submit
     * @see shortcode
     * @see setId
     * @see setPath
     * @see setText
     * @see setIcon
     * @see setSubmit
     * @see setShortcode
     * @see enableSubmit
     * @see disableSubmit
     * @see enableShortcode
     * @see disableShortcode
     */
    public get submit(): boolean {
        return this._data.get( 'submit' );
    }

    /**
     *
     * @return {boolean}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/03/20 12:12 pm
     * @see id
     * @see path
     * @see text
     * @see icon
     * @see submit
     * @see shortcode
     * @see setId
     * @see setPath
     * @see setText
     * @see setIcon
     * @see setSubmit
     * @see setShortcode
     * @see enableSubmit
     * @see disableSubmit
     * @see enableShortcode
     * @see disableShortcode
     */
    public set submit( value: boolean ) {
        this._data.set( 'submit', value ?? null );
    }

    /**
     *
     * @return {boolean}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/03/20 12:12 pm
     * @see id
     * @see path
     * @see text
     * @see icon
     * @see submit
     * @see shortcode
     * @see setId
     * @see setPath
     * @see setText
     * @see setIcon
     * @see setSubmit
     * @see setShortcode
     * @see enableSubmit
     * @see disableSubmit
     * @see enableShortcode
     * @see disableShortcode
     */
    public get shortcode(): boolean {
        return this._data.get( 'shortcode' );
    }

    /**
     *
     * @return {boolean}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/03/20 12:12 pm
     * @see id
     * @see path
     * @see text
     * @see icon
     * @see submit
     * @see shortcode
     * @see setId
     * @see setPath
     * @see setText
     * @see setIcon
     * @see setSubmit
     * @see setShortcode
     * @see enableSubmit
     * @see disableSubmit
     * @see enableShortcode
     * @see disableShortcode
     */
    public set shortcode( value: boolean ) {
        this._data.set( 'shortcode', value ?? null );
    }

    /**
     *
     * @return {Set<string>}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 07/28/21 08:16 pm
     * @see id
     * @see path
     * @see text
     * @see icon
     * @see submit
     * @see shortcode
     * @see className
     * @see setId
     * @see setPath
     * @see setText
     * @see setIcon
     * @see setSubmit
     * @see setShortcode
     * @see enableSubmit
     * @see disableSubmit
     * @see enableShortcode
     * @see disableShortcode
     */
    public get className(): Set<string> {
        if( this._data.has( 'className' ) ) {
            return this._data.get( 'className' );
        }

        this._data.set( 'className', new Set<string>() );

        return this._data.get( 'className' );
    }

    /**
     *
     * @param {Set<string>} value
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 07/28/21 08:16 pm
     * @see id
     * @see path
     * @see text
     * @see icon
     * @see submit
     * @see shortcode
     * @see className
     * @see setId
     * @see setPath
     * @see setText
     * @see setIcon
     * @see setSubmit
     * @see setShortcode
     * @see enableSubmit
     * @see disableSubmit
     * @see enableShortcode
     * @see disableShortcode
     */
    public set className( value: Set<string> ) {
        this._data.set( 'className', value );
    }

    /**
     *
     * @param {string} value
     * @return {this}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 12:58 pm
     * @see id
     * @see path
     * @see text
     * @see icon
     * @see submit
     * @see shortcode
     * @see setId
     * @see setPath
     * @see setText
     * @see setIcon
     * @see setSubmit
     * @see setShortcode
     * @see enableSubmit
     * @see disableSubmit
     * @see enableShortcode
     * @see disableShortcode
     */
    public setPath( value: string ): this {
        this.icon = value;

        return this;
    }

    /**
     *
     * @param {string} value
     * @return {this}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 12:58 pm
     * @see id
     * @see path
     * @see text
     * @see icon
     * @see submit
     * @see shortcode
     * @see setId
     * @see setPath
     * @see setText
     * @see setIcon
     * @see setSubmit
     * @see setShortcode
     * @see enableSubmit
     * @see disableSubmit
     * @see enableShortcode
     * @see disableShortcode
     */
    public setIcon( value: string | null ): this {
        this.icon = value;

        return this;
    }

    /**
     *
     * @param {boolean} value
     * @return {this}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 12:58 pm
     * @see id
     * @see path
     * @see text
     * @see icon
     * @see submit
     * @see shortcode
     * @see setId
     * @see setPath
     * @see setText
     * @see setIcon
     * @see setSubmit
     * @see setShortcode
     * @see enableSubmit
     * @see disableSubmit
     * @see enableShortcode
     * @see disableShortcode
     */
    public setSubmit( value: boolean ): this {
        this.submit = value;

        return this;
    }

    /**
     *
     * @param {boolean} value
     * @return {this}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/18/21 12:58 pm
     * @see id
     * @see path
     * @see text
     * @see icon
     * @see submit
     * @see shortcode
     * @see setId
     * @see setPath
     * @see setText
     * @see setIcon
     * @see setSubmit
     * @see setShortcode
     * @see enableSubmit
     * @see disableSubmit
     * @see enableShortcode
     * @see disableShortcode
     */
    public setShortcode( value: boolean ): this {
        this.submit = value;

        return this;
    }

    /**
     *
     * @return {LinkModule}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/03/20 12:12 pm
     * @see id
     * @see path
     * @see text
     * @see icon
     * @see submit
     * @see shortcode
     * @see setId
     * @see setPath
     * @see setText
     * @see setIcon
     * @see setSubmit
     * @see setShortcode
     * @see enableSubmit
     * @see disableSubmit
     * @see enableShortcode
     * @see disableShortcode
     */
    public enableSubmit(): this {
        this.submit = true;

        return this;
    }

    /**
     *
     * @return {LinkModule}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/03/20 12:12 pm
     * @see id
     * @see path
     * @see text
     * @see icon
     * @see submit
     * @see shortcode
     * @see setId
     * @see setPath
     * @see setText
     * @see setIcon
     * @see setSubmit
     * @see setShortcode
     * @see enableSubmit
     * @see disableSubmit
     * @see enableShortcode
     * @see disableShortcode
     */
    public disableSubmit(): this {
        this.submit = false;

        return this;
    }

    /**
     *
     * @return {LinkModule}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/03/20 12:12 pm
     * @see id
     * @see path
     * @see text
     * @see icon
     * @see submit
     * @see shortcode
     * @see setId
     * @see setPath
     * @see setText
     * @see setIcon
     * @see setSubmit
     * @see setShortcode
     * @see enableSubmit
     * @see disableSubmit
     * @see enableShortcode
     * @see disableShortcode
     */
    public enableShortcode(): this {
        this.shortcode = true;

        return this;
    }

    /**
     *
     * @return {LinkModule}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/03/20 12:12 pm
     * @see id
     * @see path
     * @see text
     * @see icon
     * @see submit
     * @see shortcode
     * @see setId
     * @see setPath
     * @see setText
     * @see setIcon
     * @see setSubmit
     * @see setShortcode
     * @see enableSubmit
     * @see disableSubmit
     * @see enableShortcode
     * @see disableShortcode
     */
    public disableShortcode(): this {
        this.shortcode = false;

        return this;
    }

    /**
     *
     * @param {string | string[] | Set<string>} className
     * @return {this}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 07/28/21 08:23 pm
     * @see id
     * @see path
     * @see text
     * @see icon
     * @see submit
     * @see shortcode
     * @see className
     * @see setId
     * @see setPath
     * @see setText
     * @see setIcon
     * @see setSubmit
     * @see setShortcode
     * @see enableSubmit
     * @see disableSubmit
     * @see enableShortcode
     * @see disableShortcode
     */
    public addClass( className: string ): this;
    public addClass( classNames: string[] ): this;
    public addClass( classNames: Set<string> ): this;
    public addClass( value: unknown ): this {
        if( typeof value === 'string' ) {
            this.className.add( value );
        } else if( value instanceof Set || Array.isArray( value ) ) {
            value.forEach( temp => { this.addClass( temp ); } );
        }

        return this;
    }

    /**
     *
     * @param {string} className
     * @return {this}
     * @return {this}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 07/28/21 08:23 pm
     * @see id
     * @see path
     * @see text
     * @see icon
     * @see submit
     * @see shortcode
     * @see className
     * @see setId
     * @see setPath
     * @see setText
     * @see setIcon
     * @see setSubmit
     * @see setShortcode
     * @see enableSubmit
     * @see disableSubmit
     * @see enableShortcode
     * @see disableShortcode
     */
    public removeClass( className: string ): this;
    public removeClass( classNames: string[] ): this;
    public removeClass( classNames: Set<string> ): this;
    public removeClass( value: unknown ): this {
        if( typeof value === 'string' ) {
            this.className.delete( value );
        } else if( value instanceof Set || Array.isArray( value ) ) {
            value.forEach( temp => { this.removeClass( temp ); } );
        }

        return this;
    }

    /**
     *
     * @return {object}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/03/20 12:12 pm
     * @see id
     * @see path
     * @see text
     * @see icon
     * @see submit
     * @see shortcode
     * @see setId
     * @see setPath
     * @see setText
     * @see setIcon
     * @see setSubmit
     * @see setShortcode
     * @see enableSubmit
     * @see disableSubmit
     * @see enableShortcode
     * @see disableShortcode
     */
    public toObject(): Record<string, unknown> {
        return {
            ...super.toObject(),
            path     : this.path,
            icon     : this.icon,
            submit   : this.submit,
            shortcode: this.shortcode,
            className: [ ...this.className ],
        };
    }
}