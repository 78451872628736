import isNode                          from 'detect-node';
import objectHash                      from 'object-hash';
import { Md5 }                         from 'ts-md5';
import { randomNormal, randomUniform } from 'd3-random';
import { ConsoleManager }              from '../manager/console.manager';
import { URLManager }                  from '../manager/url.manager';

/**
 * @class Util
 * @author Isaac Ewing
 * @version 1.0.0 02/15/21 04:24 pm - documented
 * @classdesc This class handles storing all the random needed functions used throughout the application
 */
export class Util {
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 05/08/21 03:54 pm
     */
    protected static readonly CONSOLE_PREFIX: string     = `${ process.env.REACT_APP_CONSOLE_PREFIX_OTHER } UTIL ${ process.env.REACT_APP_CONSOLE_SUFFIX_OTHER }`;
    /**
     *
     * @type {boolean}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/22/21 08:36 pm
     */
    protected static readonly CONSOLE_ENABLED: boolean   = false;
    /**
     *
     * @type {number}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/26/21 01:19 pm
     */
    protected static readonly DEFAULT_RANDOM_MIN: number = 0;
    /**
     *
     * @type {number}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/26/21 01:19 pm
     */
    protected static readonly DEFAULT_RANDOM_MAX: number = 1;
    /**
     *
     * @type {number}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 04/09/21 08:34 pm
     */
    protected static readonly BREAKPOINT_SMALL: number   = 640;
    /**
     *
     * @type {number}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 04/09/21 08:34 pm
     */
    protected static readonly BREAKPOINT_MEDIUM: number  = 1024;
    /**
     *
     * @type {number}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 04/09/21 08:34 pm
     */
    protected static readonly BREAKPOINT_LARGE: number   = 1200;

    /**
     *
     * @param {number} max
     * @return {number}
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/26/21 01:19 pm
     */
    public static randomNumber( max?: number ): number;
    public static randomNumber( min?: number, max?: number ): number;
    public static randomNumber( testMin?: number, testMax?: number ): number {
        try {
            if( testMin || testMax ) {
                return randomUniform( testMin ?? this.DEFAULT_RANDOM_MIN, testMax ?? this.DEFAULT_RANDOM_MAX )();
            }

            return randomNormal( this.DEFAULT_RANDOM_MIN, this.DEFAULT_RANDOM_MAX )();
        } catch( exception: unknown ) {
            return Math.random() * ( ( testMax ?? this.DEFAULT_RANDOM_MAX ) - ( testMin ?? this.DEFAULT_RANDOM_MIN ) ) + ( testMin ?? this.DEFAULT_RANDOM_MIN );
        }
    }

    /**
     *
     * @param {number} length
     * @return {string}
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/21/20 12:14 pm
     */
    public static registerHash( length?: number ): string {
        try {
            const result: string = objectHash( { name: this.randomNumber() } );

            if( length ) {
                return result.slice( 0, length );
            }

            return result;
        } catch( exception: unknown ) {
            const random1: number = Math.random();
            const random2: number = Math.random();
            const string1: string = Md5.hashStr( String( Math.random() * random1 ) ) as string;
            const string2: string = Md5.hashStr( String( Math.random() * random2 ) ) as string;
            const string3: string = string1.concat( string2 ).slice( 0, length ?? +( process.env?.CONFIG_HASH_LENGTH ?? 0 ) );

            ConsoleManager.Log( this.CONSOLE_ENABLED ?? null, this.CONSOLE_PREFIX ?? null, Util.constructor.name ?? null, 'register hash exception', { exception, string1, string2, string3 } );

            return string3;
        }
    }

    /**
     *
     * @param value {any}
     * @return {boolean}
     * @author Isaac Ewing
     * @version 1.0.0 12/22/20 09:09 pm
     */
    public static isNumber( value: unknown ): boolean {
        const test: string = String( value );

        return !isNaN( +test ) && isFinite( +test ) && ( typeof value === 'number' || !/e/i.test( test ) );
    }

    /**
     *
     * @param {string} live
     * @param {string} dev
     * @return {string}
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/04/21 12:40 pm
     */
    public static isLive( live: string, dev: string ): string;
    public static isLive( live: unknown, dev: unknown ): unknown {
        if( URLManager.isLive ?? process.env.NODE_ENV !== 'development' ) {
            return live;
        }

        return dev;
    }

    /**
     *
     * @param value {any}
     * @return {boolean}
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/09/20 05:37 pm
     */
    public static asBoolean( value: unknown ): boolean {
        switch( value ) {
            case true:
            case 'true':
            case 1:
            case '1':
            case 'on':
            case 'yes':
                return true;
            default:
                return false;
        }
    }

    /**
     *
     * @param {string} path
     * @param {boolean} slash
     * @param {boolean} dash
     * @param {boolean} trail
     * @return {string}
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/07/21 05:37 pm
     * @version 1.1.0 02/21/21 06:53 pm - added support for replacing spaces with dashes and slash at the end
     */
    public static cleanPath( path: string | null = null, slash: boolean = true, dash: boolean = true, trail?: boolean ): string | null {
        try {
            if( path === '/' ) {
                return path;
            }

            const testPath: string  = path ?? URLManager?.Page ?? window.location.pathname;
            const hasSlash: boolean = testPath.startsWith( '/' );
            const endSlash: boolean = testPath.endsWith( '/' );
            let result: string | null;

            if( slash ) {
                result = hasSlash ? path : `/${ path }`;
            } else {
                result = hasSlash ? path?.slice( 1 ) ?? null : path;
            }
            if( dash ) {
                if( !isNode ) {
                    result = result?.replaceAll( ' ', '-' ) ?? null;
                } else {
                    result = result?.replace( / /g, '-' ) ?? null;
                }
            }
            if( trail ) {
                result = endSlash ? result : `${ result }/`;
            } else {
                result = endSlash ? result?.slice( 0, -1 ) ?? null : result;
            }

            return result;
        } catch( exception: unknown ) {
            ConsoleManager.Error( this.CONSOLE_ENABLED ?? null, this.CONSOLE_PREFIX ?? null, Util.constructor.name ?? null, 'clean path exception', { exception, path, slash, dash, trail } );

            return null;
        }
    }

    /**
     *
     * @param path {string}
     * @param domain {number | string}
     * @return {string}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/11/21 05:31 pm
     * @version 1.1.0 03/31/21 01:17 pm - added support for domain
     */
    public static buildURL( path: string, domain?: number | string ): string | null {
        try {
            const url: URL = new URL( path );

            if( url.protocol ) {
                return path;
            }
        } catch( exception: unknown ) {
            if( !isNode ) {
                const isLive: boolean   = process.env.NODE_ENV !== 'development';
                const subdomain: string = 'image';
                const hostname: string  = isLive ? process.env?.REACT_APP_SITE_TLD ?? '' : URLManager?.Domain ?? window.location.hostname ?? '';
                const tld: string       = isLive ? 'com' : '7443';

                if( isLive ) {
                    return `https://${ subdomain }.${ hostname }.${ tld }/${ domain ?? '' }${ Util.cleanPath( path, !!domain ) }`;
                }

                return `https://${ hostname }:8000/${ subdomain }s/${ domain ?? '' }${ Util.cleanPath( path, !!domain ) }`;
            }

            return path;
        }

        return null;
    }

    /**
     *
     * @param path {string}
     * @param domain {number | string}
     * @return {string}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 04/24/21 12:43 pm
     */
    public static buildImageURL( path: string, domain?: number | string ): string | null {
        try {
            const url: URL = new URL( path );

            if( url.protocol ) {
                return path;
            }
        } catch( exception: unknown ) {
            if( !isNode ) {
                const isLive: boolean   = process.env.NODE_ENV !== 'development';
                const subdomain: string = 'image';
                const tld: string       = isLive ? 'com' : '7443';

                if( isLive ) {
                    const hostname: string = process.env?.REACT_APP_SITE_TLD ?? '';

                    return `https://${ subdomain }.${ hostname }.${ tld }/${ domain ?? '' }${ Util.cleanPath( path, !!domain ) }`;
                }
                if( domain ) {
                    return `https://${ subdomain }.${ domain }:${ tld }${ Util.cleanPath( path, true ) }`;
                }

                return `https://${ subdomain }.${ window.location.hostname }:${ tld }${ Util.cleanPath( path, true ) }`;
            }

            return path;
        }

        return null;
    }

    /**
     *
     * @param {number | string} value
     * @param {boolean} allWords
     * @return {string}
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/05/21 12:24 pm
     * @version 1.1.0 02/18/21 12:11 pm - added support for number
     * @version 1.2.0 04/04/21 03:19 pm - added support for allWords
     */
    public static capitalizeFirstLetter( value: number | string, allWords?: boolean ): string {
        const temp: string = `${ value }`;

        if( allWords ) {
            const words: string[] = temp.split( ' ' );

            for( let i: number = 0, total: number = words.length; i < total; i++ ) {
                words[ i ] = this.capitalizeFirstLetter( words[ i ] );
            }

            return words.join( ' ' );
        }

        return `${ temp.charAt( 0 ).toUpperCase() }${ temp.slice( 1 ) }`;
    }

    /**
     *
     * @return {string}
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/20/21 04:38 pm
     */
    public static buildDomainEmail(): string | null {
        if( !isNode ) {
            return `${ process.env.REACT_APP_CONFIG_EMAIL_FROM }@${ window.location.hostname }`;
        }

        return null;
    }

    /**
     *
     * @param {string} title
     * @return {string}
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 04/22/21 11:24 am
     */
    public static buildMetaTitle( title: string ): string {
        const heading: string = title !== 'home' ? ` - ${ title }` : '';

        return Util.capitalizeFirstLetter( `${ process.env.REACT_APP_NAME }${ heading }`, true );
    }

    /**
     *
     * @param {string} prefix
     * @param {string} id
     * @param {boolean} addBrackets
     * @return {string}
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/17/21 05:56 pm
     */
    public static getHashId( prefix: string, id: string, addBrackets?: boolean ): string {
        if( addBrackets ) {
            return `[${ prefix }]${ Util.capitalizeFirstLetter( id ?? Util.registerHash() ) }`;
        }

        return `${ prefix }${ Util.capitalizeFirstLetter( id ?? Util.registerHash() ) }`;
    }

    /**
     *
     * @return {boolean}
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 04/09/21 08:53 pm
     * @see isBreakpointSmall
     * @see isBreakpointMedium
     * @see isBreakpointMediumOnly
     * @see isBreakpointLarge
     * @see isBreakpointLargeOnly
     */
    public static isBreakpointSmall(): boolean {
        if( !isNode ) {
            return window.innerWidth < this.BREAKPOINT_SMALL;
        }

        return false;
    }

    /**
     *
     * @return {boolean}
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 04/09/21 08:53 pm
     * @see isBreakpointSmall
     * @see isBreakpointMedium
     * @see isBreakpointMediumOnly
     * @see isBreakpointLarge
     * @see isBreakpointLargeOnly
     */
    public static isBreakpointMedium(): boolean {
        if( !isNode ) {
            return window.innerWidth >= Util.BREAKPOINT_SMALL;
        }

        return false;
    }

    /**
     *
     * @return {boolean}
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 04/09/21 08:53 pm
     * @see isBreakpointSmall
     * @see isBreakpointMedium
     * @see isBreakpointMediumOnly
     * @see isBreakpointLarge
     * @see isBreakpointLargeOnly
     */
    public static isBreakpointMediumOnly(): boolean {
        if( !isNode ) {
            return window.innerWidth >= Util.BREAKPOINT_SMALL && window.innerWidth < Util.BREAKPOINT_MEDIUM;
        }

        return false;
    }

    /**
     *
     * @return {boolean}
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 04/09/21 08:53 pm
     * @see isBreakpointSmall
     * @see isBreakpointMedium
     * @see isBreakpointMediumOnly
     * @see isBreakpointLarge
     * @see isBreakpointLargeOnly
     */
    public static isBreakpointLarge(): boolean {
        if( !isNode ) {
            return window.innerWidth >= Util.BREAKPOINT_MEDIUM;
        }

        return false;
    }

    /**
     *
     * @return {boolean}
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 04/09/21 08:53 pm
     * @see isBreakpointSmall
     * @see isBreakpointMedium
     * @see isBreakpointMediumOnly
     * @see isBreakpointLarge
     * @see isBreakpointLargeOnly
     */
    public static isBreakpointLargeOnly(): boolean {
        if( !isNode ) {
            return window.innerWidth >= Util.BREAKPOINT_MEDIUM && window.innerWidth < Util.BREAKPOINT_LARGE;
        }

        return false;
    }

    /**
     *
     * @param {string} domain
     * @return {boolean}
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 05/17/21 03:16 pm
     */
    public static isDefaultDomain( domain: string ): boolean;
    public static isDefaultDomain( model: Record<string, unknown> ): boolean;
    public static isDefaultDomain( data: unknown ): boolean {
        if( typeof data === 'string' ) {
            return data === process.env.REACT_APP_CLIENT_DOMAIN;
        }
        if( typeof data === 'object' ) {
            const localData: Record<string, string> = data as Record<string, string>;

            return ( localData?.domainName ?? localData.domain ?? localData.path ?? null ) === process.env.REACT_APP_CLIENT_DOMAIN;
        }

        return false;
    }
}