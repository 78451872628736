import React             from 'react';
import PropTypes         from 'prop-types';
import { SectionModule } from '../../../api/com/ewing/social/component';
import { SiteModule }    from '../../../api/com/ewing/social/module';
import { BaseSection }   from '../../atom/BaseSection';
import { Description }   from '../../atom/Description';
import { Subtitle }      from '../../atom/Subtitle';
import { Title }         from '../../atom/Title';

export class Legal1 extends BaseSection<any, any> {
     public static readonly COMPONENT_CLASS: string = 'legal';
     public static propTypes                        = {
          ...BaseSection.propTypes,
          module: PropTypes.instanceOf( SectionModule ).isRequired,
          page  : PropTypes.instanceOf( SiteModule ).isRequired,
     };

     /**
      *
      * @param props {any}
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am
      * documented
      */
     public constructor( props: any ) {
          super( props );

          this.state = {
               module   : props.module,
               page     : props.page,
               className: new Set<string>( [ 'grid-x', Legal1.COMPONENT_CLASS ] ),
               //children : [],
          };

          this.registerProps( this.state.module ?? null );
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am
      * documented
      */
     public render(): JSX.Element {
          let title: JSX.Element;
          let subtitle: JSX.Element;

          if( this.state?.module?.title ) {
               title = <Title module={ this.state?.module?.title } />;
          }
          if( this.state?.module?.subtitle ) {
               subtitle = <Subtitle module={ this.state?.module?.subtitle } />;
          }

          return (
               <section { ...this.registerSectionProps() }>
                    <div className="grid-container content">
                         <div className="grid-x align-left">
                              <div className="cell small-12">
                                   { title }
                                   { subtitle }
                                   <Description module={ this.state?.module?.description } />
                              </div>
                         </div>
                    </div>
               </section>
          );
     }
}

export default Legal1;
