import PropTypes                                  from 'prop-types';
import React                                      from 'react';
import jQuery                                     from 'jquery';
import { IBaseProps }                             from '../../../api/com/ewing/social/interface';
import { RegisterStepEnum }                       from '../../../api/com/ewing/social/enum';
import { OAuthObserver }                          from '../../../api/com/ewing/social/observer';
import { SectionModule }                          from '../../../api/com/ewing/social/component';
import { ProfileModule, SiteModule, TitleModule } from '../../../api/com/ewing/social/module';
import { Base }                                   from '../../atom/Base';
import { Description }                            from '../../atom/Description';
import { Subtitle }                               from '../../atom/Subtitle';
import { Platform, Profile, Expand, Start }       from '../../molecule/invite';

/**
 * @class Register
 * @extends Base
 * @author Isaac Ewing
 * @version 1.0.0 01/24/21 09:51 pm - documented
 */
export class Register extends Base {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/24/21 09:51 pm - documented
      */
     protected static readonly COMPONENT_CLASS: string = 'register';
     public static propTypes                           = {
          ...Base.propTypes,
          module: PropTypes.instanceOf( SectionModule ).isRequired,
          page  : PropTypes.instanceOf( SiteModule ).isRequired,
     };

     /**
      *
      * @param props {any}
      * @return {void}
      * @throws Throws an exception if method is called without having an override
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/24/20 01:24 pm
      */
     protected registerPropsCall( props: IBaseProps ): void {
          this.registerClasses( props );
     }

     /**
      *
      * @param {ProfileModule} module
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/24/21 09:50 pm
      */
     protected onOAuthObserver( module: ProfileModule ): void {
          const status: JQuery<HTMLElement> = this.state.status;

          if( module.step === RegisterStepEnum.Finish ) {
               //TweenMax.to( status, vars ?? {} );
               status.removeClass( 'status-hide' ).addClass( 'status-show' );
          }
     }

     /**
      *
      * @param props {any}
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/24/21 09:51 pm - documented
      */
     public constructor( props: Record<string, never> ) {
          super( props );

          this.state = {
               module    : props.module,
               page      : props.page,
               invite    : props.page?.[ 'invite' ] ?? null,
               className : new Set<string>( [ 'grid-x', Register.COMPONENT_CLASS ] ),
               component : null,
               status    : null,
               statusHash: this.registerHash(),
          };

          this.registerProps( this.state.module );
          this.onOAuthObserver = this.onOAuthObserver.bind( this );
          OAuthObserver.subscribe( Register.COMPONENT_CLASS, this.onOAuthObserver );
     }

     /**
      *
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/13/21 01:06 pm
      */
     public componentDidMount(): void {
          const component: JQuery<HTMLElement> = jQuery( `[data-hash="${ this.state.hash }"]` );
          const status: JQuery<HTMLElement>    = jQuery( `[data-hash="${ this.state.statusHash }"]` );

          this.setState( { component, status } );
     }

     /**
      *
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/24/21 09:53 pm
      */
     public componentWillUnmount(): void {
          OAuthObserver.unsubscribe( Register.COMPONENT_CLASS );
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/24/20 01:24 pm
      */
     public render(): JSX.Element {
          return (
               <div className={ [ ...this.state.className ].join( ' ' ) }>
                    <div className="cell small-12 medium-6">
                         <Profile page={ this.state.page as SiteModule } />
                    </div>
                    <div className="cell small-12 medium-6">
                         <Platform page={ this.state.page as SiteModule } />
                         <Expand page={ this.state.page as SiteModule } />
                         <Start page={ this.state.page as SiteModule } />
                    </div>
                    <div className="cell small-12 invite-status status-hide" data-hash={ this.state.statusHash }>
                         <Subtitle module={ TitleModule.Build( { text: 'thank you' } ) } />
                         <Description module={ TitleModule.Build( { text: 'we will be in touch' } ) } />
                    </div>
               </div>
          );
     }
}
