import { HAlignEnum, StyleEnum, VAlignEnum } from '../enum';
import {
    IBaseProps, IBaseState, ICharacterProps,
    IGalleryModule, IImageModule,
    ILinkProps, ISectionModule,
    ISubtitleProps,
}                                            from '../interface';
import { Util }                              from './util';

/**
 *
 * @interface IRegisterProps
 * @author Isaac Ewing
 * @version 1.0.0 06/06/21 04:01 pm
 */
interface IRegisterProps {
    style: string | StyleEnum;
    light: boolean;
    grey: boolean;
    dark: boolean;
    className: string | string[] | Set<string>;
}

/**
 *
 * @class RegisterTool
 * @author Isaac Ewing
 * @version 1.0.0 06/06/21 10:39 am
 */
export class RegisterTool {
    /**
     *
     * @param {IBaseProps} base
     * @param {Partial<IBaseState>} state
     * @return {void}
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 06/06/21 05:21 pm
     */
    public static style( base: Partial<IBaseProps>, state: Partial<IBaseState> ): void;
    public static style( link: ILinkProps, state: Partial<IBaseState> ): void;
    public static style( section: ISectionModule, state: Partial<IBaseState> ): void;
    public static style( obj: Record<string, never>, state: Partial<IBaseState> ): void;
    public static style( props: Partial<IRegisterProps>, state: Partial<IBaseState> ): void {
        let style: string;

        if( props?.style ) {
            switch( props.style ) {
                case StyleEnum.Light:
                case StyleEnum.Grey:
                case StyleEnum.Dark:
                    style = props.style;
                    break;
                default:
                    style = StyleEnum.Light;
            }
        } else if( props?.light ) {
            style = StyleEnum.Light;
        } else if( props?.grey ) {
            style = StyleEnum.Grey;
        } else if( props?.dark ) {
            style = StyleEnum.Dark;
        } else {
            style = StyleEnum.Light;
        }

        state.className.add( style );
    }

    /**
     *
     * @param {IBaseProps} subtitle
     * @param {Partial<IBaseState>} state
     * @return {void}
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 06/06/21 10:39 am
     */
    public static classes( subtitle: ISubtitleProps, state: Partial<IBaseState> ): void;
    public static classes( link: ILinkProps, state: Partial<IBaseState> ): void;
    public static classes( register: Partial<IRegisterProps>, state: Partial<IBaseState> ): void;
    public static classes( base: IBaseProps, state: Partial<IBaseState> ): void;
    public static classes( obj: Record<string, never>, state: Partial<IBaseState> ): void;
    public static classes( props: any, state: Partial<IBaseState> ): void {
        if( props?.className ) {
            if( props.className instanceof Set || Array.isArray( props.className ) ) {
                props.className.forEach( ( name: string ): void => {
                    state.className?.add( name );
                } );
            } else if( typeof props.className === 'string' ) {
                props.className.split( ' ' ).forEach( ( className: string ): void => {
                    state.className.add( className );
                } );
            } else {
                console.error( 'BASE TSX CLASSNAME IS UNKNOWN TYPE', { className: props.className, props } );
            }
        } else if( props?.module ) {
            this.classes( props.module, state );
        }
    }

    /**
     *
     * @param {IBaseProps} base
     * @param {Partial<IBaseState>} state
     * @return {void}
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 07/31/21 05:56 pm
     */
    public static curve( base: IBaseProps, state: Partial<IBaseState> ): void;
    public static curve( section: ISectionModule, state: Partial<IBaseState> ): void;
    public static curve( record: Record<string, never>, state: Partial<IBaseState> ): void;
    public static curve( data: any, state: Partial<IBaseState> ): void {
        if( data?.curve ) {
            state.className.add( process.env.REACT_APP_CSS_CLASSNAME_TOP_CURVE ?? 'ENV-VAR-NOT-FOUND' );
        }
    }

    /**
     *
     * @param {IBaseProps} base
     * @param {Partial<IBaseState>} state
     * @return {void}
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 07/31/21 06:29 pm
     */
    public static submenus( base: IBaseProps, state: Partial<IBaseState> ): void;
    public static submenus( section: ISectionModule, state: Partial<IBaseState> ): void;
    public static submenus( record: Record<string, never>, state: Partial<IBaseState> ): void;
    public static submenus( data: any, state: Partial<IBaseState> ): void {
        if( data?.submenu ) {
            state.className.add( process.env.REACT_APP_CSS_CLASSNAME_HAS_SUBMENU ?? 'ENV-VAR-NOT-FOUND' );
        }
    }

    /**
     *
     * @param {IBaseProps} base
     * @param {Partial<IBaseState>} state
     * @return {void}
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 07/31/21 08:18 pm
     */
    public static children( base: IBaseProps, state: Partial<IBaseState> ): void;
    public static children( section: ISectionModule, state: Partial<IBaseState> ): void;
    public static children( record: Record<string, never>, state: Partial<IBaseState> ): void;
    public static children( data: any, state: Partial<IBaseState> ): void {
        if( data?.children ) {
            if( data.children instanceof Map || Array.isArray( data.children ) ) {
                data.children.forEach( ( child: JSX.Element ): void => {
                    state.children.add( child );
                } );
            } else {
                state.children.add( data.children );
            }
        }
    }

    /**
     *
     * @param props {Partial<IGalleryModule> | Partial<IImageModule>}
     * @param {Partial<IBaseState>} state
     * @return {void}
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 07/31/21 07:52 pm
     */
    public static align( props: Partial<IGalleryModule>, state: Partial<IBaseState> ): void;
    public static align( props: Partial<IImageModule>, state: Partial<IBaseState> ): void;
    public static align( record: Record<string, never>, state: Partial<IBaseState> ): void;
    public static align( data: any, state: Partial<IBaseState> ): void {
        switch( data?.halign ) {
            case HAlignEnum.Auto:
            case HAlignEnum.Left:
            case HAlignEnum.Center:
            case HAlignEnum.Right:
                state.className.add( data.halign );
                break;
            default:
                state.className.add( HAlignEnum.Center );
                break;
        }
    }

    /**
     *
     * @param props {Partial<IGalleryModule> | Partial<IImageModule>}
     * @param {Partial<IBaseState>} state
     * @return {void}
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 07/31/21 07:52 pm
     */
    public static position( props: Partial<IGalleryModule>, state: Partial<IBaseState> ): void;
    public static position( props: Partial<IImageModule>, state: Partial<IBaseState> ): void;
    public static position( record: Record<string, never>, state: Partial<IBaseState> ): void;
    public static position( data: any, state: Partial<IBaseState> ): void {
        switch( data?.valign ) {
            case     VAlignEnum.Auto:
            case     VAlignEnum.Top:
            case     VAlignEnum.Middle:
            case     VAlignEnum.Bottom:
                state.className.add( data.valign );
                break;
            default:
                state.className.add( VAlignEnum.Top );
                break;
        }
    }

    /**
     *
     * @param {ICharacterProps} props
     * @param {Partial<IBaseState>} state
     * @return {void}
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 06/06/21 03:43 pm
     */
    public static password( props: ICharacterProps, state: Partial<IBaseState> ): void {
        if( props?.password && Util.asBoolean( props.password ) ) {
            state.className.add( process.env.REACT_APP_CSS_CLASSNAME_IS_CAPS );
        }
    }

    /**
     *
     * @param props {any}
     * @param {Partial<IBaseState>} state
     * @return {void}
     * @throws Throws an exception if method is called without having an override
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 06/06/21 03:43 pm
     */
    public static caps( props: ICharacterProps, state: Partial<IBaseState> ): void {
        if( props?.caps ) {
            state.className.add( process.env.REACT_APP_CSS_CLASSNAME_IS_PASSWORD );
        }
    }

    /**
     *
     * @param props {any}
     * @param {Partial<IBaseState>} state
     * @return {void}
     * @throws Throws an exception if method is called without having an override
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 06/06/21 03:43 pm
     */
    public static disabled( props: ICharacterProps, state: Partial<IBaseState> ): void {
        if( props?.disabled ) {
            state.className.add( process.env.REACT_APP_CSS_CLASSNAME_IS_DISABLED );
        }
    }

    /**
     *
     * @param props {any}
     * @param {Partial<IBaseState>} state
     * @return {void}
     * @throws Throws an exception if method is called without having an override
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 06/06/21 03:43 pm
     */
    public static animate( props: ICharacterProps, state: Partial<IBaseState> ): void {
        if( props?.animate ) {
            state.className.add( process.env.REACT_APP_CSS_CLASSNAME_ANIMATE );
        }
    }

    /**
     *
     * @param props {any}
     * @param {Partial<IBaseState>} state
     * @return {void}
     * @throws Throws an exception if method is called without having an override
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 06/06/21 03:43 pm
     */
    public static fadeIn( props: ICharacterProps, state: Partial<IBaseState> ): void {
        if( props?.fadeIn ) {
            state.className.add( process.env.REACT_APP_CSS_CLASSNAME_FADE_IN );
        }
    }
}