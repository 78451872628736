import { AObserver } from '../abstract';
import { IObserver } from '../interface';

/**
 * @class OAuthObserver
 * @extends AObserver
 * @implements IObserver
 * @author Isaac Ewing
 * @version 1.0.0 01/15/21 04:04 pm
 */
export class OAuthObserver extends AObserver implements IObserver {
     /**
      *
      * @type {Map<string, Function>}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/15/21 04:04 pm
      * @version 1.1.0 03/24/21 12:17 pm - changed array to set
      * @version 1.2.0 03/26/21 04:54 pm - changed set to map
      */
     protected static observers: Map<string, ( data: unknown ) => void> = new Map<string, ( data: unknown ) => void>();
}