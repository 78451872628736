import React                            from 'react';
import jQuery                           from 'jquery';
import { TweenMax }                     from 'gsap';
import { ActionEnum, RegisterStepEnum } from '../../../api/com/ewing/social/enum';
import { TitleModule, ProfileModule }   from '../../../api/com/ewing/social/module';
import { OAuthObserver }                from '../../../api/com/ewing/social/observer/oauth.observer';
import { Description }                  from '../../atom/Description';
import { InviteBase }                   from '../../atom/invite/InviteBase';

/**
 * @class Platform
 * @extends InviteBase
 * @author Isaac Ewing
 * @version 1.0.0 01/16/21 10:35 am
 */
export class Platform extends InviteBase<any, any> {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/16/21 10:35 am
      */
     protected static readonly COMPONENT_CLASS: string = 'platform';

     /**
      *
      * @param {ProfileModule} module
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/18/21 07:54 pm
      */
     protected onOAuthObserver( module: ProfileModule ): void {
          if( module.step === RegisterStepEnum.Platform ) {
               const component: JQuery = this.state.component;
               const button: JQuery    = jQuery( this.state.socialButtons ).find( `.${ module.platform }` );
               const properties        = {
                    'data-state' : InviteBase.STATE_CLOSE,
                    'data-status': InviteBase.STATUS_DONE,
               };
               const vars              = {
                    duration: InviteBase.ANIMATION_TIME,
                    ease    : 'circ.out',
                    width   : 0,
                    height  : 0,
               };

               this.updateAttributeData( component, properties ?? {} );
               TweenMax.to( button, vars ?? {} );
               OAuthObserver.unsubscribe( Platform.COMPONENT_CLASS );
          }
     }

     /**
      *
      * @param props {any}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/16/21 10:35 am
      */
     public constructor( props: any ) {
          super( props );

          this.state = {
               page             : props.page,
               hash             : this.registerHash(),
               id               : props.id ?? '',
               initialized      : false,
               className        : new Set<string>( [ Platform.COMPONENT_CLASS ] ),
               component        : null,
               children         : new Set<JSX.Element>(),
               title            : null,
               titleHash        : this.registerHash(),
               socials          : InviteBase.getSocials(),
               socialButtons    : null,
               socialButtonsHash: this.registerHash(),
          };

          this.registerProps( props );
          this.onOAuthObserver       = this.onOAuthObserver.bind( this );
          this.onAttributeDataChange = this.onAttributeDataChange.bind( this );
          OAuthObserver.subscribe( Platform.COMPONENT_CLASS, this.onOAuthObserver );
     }

     /**
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/14/21 01:30 pm
      */
     public componentDidMount(): void {
          const component: JQuery     = jQuery( `[data-hash="${ this.state.hash }"]` );
          const socialButtons: JQuery = jQuery( `[data-hash="${ this.state.socialButtonsHash }"]` );
          const title: JQuery         = jQuery( `[data-hash="${ this.state.titleHash }"]` );

          // @ts-ignore
          this.state.title = title;
          // @ts-ignore
          component.on( InviteBase.ATTRIBUTE_DATA_CHANGED, this.onAttributeDataChange );
          this.setState( { component, socialButtons, title } );
          InviteBase.REGISTER_MODULE.componentPlatform = component;
     }

     /**
      *
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 03/26/21 05:23 pm
      */
     public componentWillUnmount(): void {
          OAuthObserver.unsubscribe( Platform.COMPONENT_CLASS );
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/14/21 01:30 pm
      */
     public render(): JSX.Element {
          const data    = {
               'data-state' : InviteBase.STATE_OPEN,
               'data-status': InviteBase.STATUS_INCOMPLETE,
               'data-hash'  : this.state.hash,
          };
          const options = {
               color : InviteBase.COLOR_GREY,
               action: ActionEnum.Invite,
               code  : this.state.page?.invite?.code,
               step  : RegisterStepEnum.Platform,
          };

          return (
               <div className={ [ ...this.state.className ].join( ' ' ) }  { ...data }>
                    <h5 className={ 'subtitle' } data-hash={ this.state.titleHash }>{ InviteBase.TEXT_MAP.get( 'platform-heading' ) }</h5>
                    <Description module={ TitleModule.Build( { text: InviteBase.TEXT_MAP.get( 'platform-description' ) } ) } />
                    <div className={ InviteBase.CLASS_SOCIALS } data-hash={ this.state.socialButtonsHash }>
                         { this.buildClaimSocial( null, null, options ?? {} ) }
                    </div>
                    { this.state.children }
               </div>
          );
     }
}

export default Platform;