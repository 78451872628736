import { ComponentEnum }   from '../enum';
import { AShortcode }      from '../abstract';
import { ClassError }      from '../error';
import { IShortcodeQuery } from '../interface';

/**
 * @class ShortcodeGraphql
 * @extends AShortcode
 * @author Isaac Ewing
 * @version 1.0.0 02/12/21 12:53 pm
 */
export class ShortcodeGraphql extends AShortcode {
    /**
     *
     * @param {ComponentEnum} type
     * @param {number} id
     * @return {IShortcodeQuery}
     * @throws Throws an exception if the component type is not supported
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/12/21 01:07 pm
     * @version 2.0.0 07/25/21 05:33 pm - updated to reflect the variable support and removal of apollo-query
     */
    public static getShortcode( type: ComponentEnum, id: number ): IShortcodeQuery {
        switch( type ) {
            case ComponentEnum.Link:
                return ShortcodeGraphql.getLink( id );
            case ComponentEnum.Title:
                return this.getTitle( id );
            default:
                ClassError.missingConditionSwitch();
        }
    }
}