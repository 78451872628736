import React                                 from 'react';
import format                                from 'date-fns/format';
import parse                                 from 'date-fns/parse';
import startOfWeek                           from 'date-fns/startOfWeek';
import getDay                                from 'date-fns/getDay';
import { Calendar, Views, dateFnsLocalizer } from 'react-big-calendar';
import { IEventState }                       from '../../../api/com/ewing/social/interface';
import { ISectionProps }                     from '../../../api/com/ewing/social/interface/component';
import { DemoManager }                       from '../../../api/com/ewing/social/manager/demo.manager';
import { TitleModule }                       from '../../../api/com/ewing/social/module';
import { OAuthObserver }                     from '../../../api/com/ewing/social/observer';
import { Title }                             from '../../atom/Title';
import { EventBase }                         from '../../atom/event';

/**
 *
 * @class Event1
 * @extends EventBase
 * @author Isaac Ewing
 * @version 1.0.0 01/29/21 12:17 pm
 */
export class Event1 extends EventBase {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/29/21 12:17 pm
      */
     protected static readonly COMPONENT_CLASS: string = 'event-1';
     /**
      *
      * @type {ISectionProps}
      * @readonly
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/29/21 12:25 pm
      */
     public readonly props: Partial<ISectionProps>;
     /**
      *
      * @type {Partial<IEventState>}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/29/21 12:25 pm
      */
     public state: Partial<IEventState>;

     /**
      *
      * @param props {any}
      * @return {void}
      * @throws Throws an exception if method is called without having an override
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/29/21 12:17 pm
      */
     protected registerPropsCall( props: ISectionProps ): void {
          this.registerStyle( props );
          this.registerClasses( props );
     }

     /**
      *
      * @param props {any}
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/29/21 12:17 pm
      */
     public constructor( props: ISectionProps ) {
          super( props );

          this.state = {
               module   : props.module,
               page     : props.page,
               className: new Set<string>( [ 'grid-x', Event1.componentClass ] ),
               children : new Set<JSX.Element>(),
               event    : null,
               events   : !this.isSiteADemo() ? props.module?.events ?? DemoManager.getDemoEvents() : DemoManager.getDemoEvents(),
          };

          this.registerProps( this.state.module as unknown as Record<string, unknown> );
     }

     /**
      *
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/29/21 12:06 pm
      */
     public componentWillUnmount(): void {
          OAuthObserver.unsubscribe( Event1.componentClass );
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/29/21 12:17 pm
      */
     public render(): JSX.Element {
          let title: JSX.Element;
          const allViews: unknown[]              = Object.keys( Views ).map( ( k: string ): unknown => Views[ k ] );
          const locales: Record<string, unknown> = {
               'en-US': require( 'date-fns/locale/en-US' ),
          };
          const options: Record<string, unknown> = {
               format,
               parse,
               startOfWeek,
               getDay,
               locales,
          };
          const localize                         = dateFnsLocalizer( options );
          const localDate: Date                  = new Date();
          const ColoredDateCellWrapper           = ( { children } ) =>
               React.cloneElement( React.Children.only( children ), {
                    style: {
                         backgroundColor: 'lightblue',
                    },
               } );

          if( this.props.module?.title ) {
               title = <Title key={ this.registerHash() } module={ this.state.module.title as unknown as TitleModule } />;
          }

          return (
               <section className={ [ ...this.state.className ].join( ' ' ) }>
                    <div className="grid-container content">
                         <div className="grid-x align-left">
                              <div className="cell small-12 medium-12">
                                   { title }
                              </div>
                              <div className="cell small-12 medium-12">
                                   <Calendar
                                        events={ [ ...this.state.events ] }
                                        views={ allViews }
                                        step={ 60 }
                                        showMultiDayTimes
                                        defaultDate={ new Date( localDate.getFullYear(), localDate.getMonth(), localDate.getDate() ) }
                                        components={ {
                                             timeSlotWrapper: ColoredDateCellWrapper,
                                        } }
                                        localizer={ localize }
                                        ti
                                   />
                              </div>
                         </div>
                    </div>
               </section>
          );
     }
}
