import { AxiosResponse }            from 'axios';
import * as EmailValidator          from 'email-validator';
import jQuery                       from 'jquery';
import { FormEvent }                from 'react';
import { ContactActionEnum }        from '../../../api/com/ewing/social/enum';
import { ActionEnum, CampaignEnum } from '../../../api/com/ewing/social/enum/newsletter';
import { EmailManager }             from '../../../api/com/ewing/social/manager/api/email.manager';
import { NewsletterManager }        from '../../../api/com/ewing/social/manager/api/newsletter.manager';
import {
    IContactModule, INewsletterModule,
    IPartnerModule, IResponse, ISectionProps, ISectionState,
} from '../../../api/com/ewing/social/interface';
import {
    ContactModule, InputModule,
    NewsletterModule, PartnerModule,
}                                   from '../../../api/com/ewing/social/module';
import { Checkbox, Input }          from '../../molecule';
import { BaseSection }              from '../BaseSection';
import { Button }                   from '../Button';

/**
 * @class BaseContact
 * @extends BaseSection
 * @abstract
 * @author Isaac Ewing
 * @version 1.0.0 02/04/21 02:36 pm
 * @classdesc This class handles the default contact functionality for the site
 */
export abstract class BaseContact extends BaseSection {
    protected static readonly COMPONENT_CLASS: string           = 'contact';
    protected static readonly DEFAULT_CONTACT_RESULT: string    = 'sent';
    protected static readonly DEFAULT_CONTACT_STATUS: string    = 'working...';
    protected static readonly DEFAULT_NEWSLETTER_RESULT: string = 'subscribed';
    protected static readonly ID_BUTTON: string                 = 'button';
    protected static readonly ID_NAME: string                   = 'name';
    protected static readonly ID_EMAIL: string                  = 'email';
    protected static readonly ID_MESSAGE: string                = 'message';
    protected static readonly ID_AFFILIATE: string              = process.env.REACT_APP_INPUT_AFFILIATE_ID ?? 'affiliate';
    protected static readonly ID_SPONSOR: string                = process.env.REACT_APP_INPUT_SPONSOR_ID ?? 'sponsor';
    protected static readonly ID_FEEDBACK: string               = 'feedback';
    protected static readonly ID_FEEDBACK_TEXT: string          = 'feedbackText';
    protected static readonly CLASS_FORM: string                = 'contact-section';
    protected static readonly CLASS_FORM_SECTION: string        = 'form-section';
    protected static readonly CLASS_FEEDBACK: string            = 'feedback-container';
    protected static readonly CLASS_FEEDBACK_TEXT: string       = 'feedback-text';
    protected static readonly CLASS_FEEDBACK_ANIMATE: string    = 'feedback-animate';
    protected static readonly CLASS_FEEDBACK_SHOW: string       = 'feedback-show';
    protected static readonly CLASS_BUTTON_WORKING: string      = 'is-working';
    /**
     *
     * @type {boolean}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/05/21 08:04 pm
     */
    protected isChecking: boolean                               = false;
    /**
     *
     * @type {Partial<ISectionProps>}
     * @readonly
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 05/31/21 03:38 pm
     */
    public readonly props: Partial<ISectionProps>;
    /**
     *
     * @type {Partial<ISectionState>}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 05/31/21 03:38 pm
     */
    public state: Partial<ISectionState>;

    /**
     *
     * @param event {FormEvent}
     * @return {void}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/05/21 08:23 pm
     */
    protected abstract onSubmit( event: FormEvent<HTMLFormElement> ): void;

    /**
     *
     * @param {string} id
     * @return {jQuery<HTMLElement>}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/14/21 01:30 pm
     */
    protected registerJquerySelector( id: string ): JQuery<HTMLElement> {
        return this.state.jquery.get( id ) ?? jQuery( `#${ BaseContact.getHashId( this.state.hash, this.state.data[ id ].id ?? id ) }` );
    }

    /**
     *
     * @return {void}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/16/21 11:55 am
     * @see turnOnFeedback
     * @see turnOffFeedback
     * @see updateFeedback
     */
    protected turnOnFeedback(): void {
        this.registerJquerySelector( `${ BaseContact.ID_FEEDBACK }` ).addClass( [ BaseContact.CLASS_FEEDBACK_ANIMATE, BaseContact.CLASS_FEEDBACK_SHOW ] );
    }

    /**
     *
     * @return {void}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/16/21 11:55 am
     * @see turnOnFeedback
     * @see turnOffFeedback
     * @see updateFeedback
     */
    protected turnOffFeedback(): void {
        this.registerJquerySelector( `${ BaseContact.ID_FEEDBACK }` ).removeClass( [ BaseContact.CLASS_FEEDBACK_ANIMATE, BaseContact.CLASS_FEEDBACK_SHOW ] );
    }

    /**
     *
     * @param {string} value
     * @return {void}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/16/21 11:55 am
     * @see turnOnFeedback
     * @see turnOffFeedback
     * @see updateFeedback
     */
    protected updateFeedback( value?: string ): void {
        this.registerJquerySelector( `${ BaseContact.ID_FEEDBACK_TEXT }` ).html( value ?? BaseContact.DEFAULT_CONTACT_RESULT );
    }

    /**
     *
     * @param {IResponse} data
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/05/21 09:34 pm
     * @see onResponseEmail
     * @see onResponseNewsletter
     */
    protected onResponseEmail( data: AxiosResponse<IResponse> ): void {
        this.updateFeedback( data?.data?.message );
        this.isChecking = false;
    }

    /**
     *
     * @param {IResponse} data
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/16/21 04:04 pm
     * @see onResponseEmail
     * @see onResponseNewsletter
     */
    protected onResponseNewsletter( data: IResponse ): void {
        this.updateFeedback( data?.message ?? BaseContact.DEFAULT_NEWSLETTER_RESULT );
        this.isChecking = false;
    }

    /**
     *
     * @param event {FormEvent}
     * @return {void}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/05/21 09:51 pm
     */
    protected onSubmitContact( event: FormEvent<HTMLFormElement> ): void {
        const button: JQuery<HTMLElement>  = this.registerJquerySelector( `${ BaseContact.ID_BUTTON }` );
        const name: JQuery<HTMLElement>    = this.registerJquerySelector( `${ BaseContact.ID_NAME }` );
        const email: JQuery<HTMLElement>   = this.registerJquerySelector( `${ BaseContact.ID_EMAIL }` );
        const message: JQuery<HTMLElement> = this.registerJquerySelector( `${ BaseContact.ID_MESSAGE }` );

        event.preventDefault();

        if( !this.isChecking ) {
            this.isChecking = true;
            button.addClass( BaseContact.CLASS_BUTTON_WORKING ).html( 'checking' );

            const nameModule: InputModule  = this.checkInput( name, this.state.data.name );
            const emailModule: InputModule = this.checkEmail( email, this.state.data.email );
            const data                     = {
                ...this.state.data,
                name   : nameModule,
                email  : emailModule,
                message: this.state.data.message,
            };

            this.setState( { data } );

            if( nameModule.doesPass() && emailModule.doesPass() ) {
                const params: Partial<IContactModule> = {
                    action : ContactActionEnum.Contact,
                    name   : name.val() as string,
                    email  : email.val() as string,
                    message: message.val() as string,
                };

                EmailManager.Contact( ContactModule.Build( params ?? null ) ?? null ).then( this.onResponseEmail );
                this.turnOnFeedback();
            } else {
                button.removeClass( BaseContact.CLASS_BUTTON_WORKING ).html( `${ this.state?.module?.button?.text }` );
                this.isChecking = false;
            }
        }
    }

    /**
     *
     * @param event {FormEvent}
     * @return {void}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/17/21 05:05 pm
     */
    protected onSubmitPartner( event: FormEvent<HTMLFormElement> ): void {
        const button: JQuery<HTMLElement>    = this.registerJquerySelector( `${ BaseContact.ID_BUTTON }` );
        const name: JQuery<HTMLElement>      = this.registerJquerySelector( `${ BaseContact.ID_NAME }` );
        const email: JQuery<HTMLElement>     = this.registerJquerySelector( `${ BaseContact.ID_EMAIL }` );
        const affiliate: JQuery<HTMLElement> = this.registerJquerySelector( `${ BaseContact.ID_AFFILIATE }` );
        const sponsor: JQuery<HTMLElement>   = this.registerJquerySelector( `${ BaseContact.ID_SPONSOR }` );
        const message: JQuery<HTMLElement>   = this.registerJquerySelector( `${ BaseContact.ID_MESSAGE }` );

        event.preventDefault();

        if( !this.isChecking ) {
            this.isChecking = true;
            button.addClass( BaseContact.CLASS_BUTTON_WORKING ).html( 'checking' );

            const nameModule: InputModule  = this.checkInput( name, this.state.data.name );
            const emailModule: InputModule = this.checkEmail( email, this.state.data.email );
            const data                     = {
                ...this.state.data,
                name : nameModule,
                email: emailModule,
            };

            this.setState( { data } );

            if( nameModule.doesPass() && emailModule.doesPass() ) {
                const params: Partial<IPartnerModule> = {
                    action   : ContactActionEnum.Partner,
                    name     : name.val() as string,
                    email    : email.val() as string,
                    affiliate: affiliate.is( ':checked' ),
                    sponsor  : sponsor.is( ':checked' ),
                    message  : message.val() as string,
                };

                EmailManager.Contact( PartnerModule.Build( params ?? null ) ?? null ).then( this.onResponseEmail );
                this.turnOnFeedback();
            } else {
                button.removeClass( BaseContact.CLASS_BUTTON_WORKING ).html( `${ this.state?.module?.button?.text }` );
                this.isChecking = false;
            }
        }
    }

    /**
     *
     * @param event {FormEvent}
     * @return {void}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/05/21 09:51 pm
     */
    protected onSubmitNewsletter( event: FormEvent<HTMLFormElement> ): void {
        const button: JQuery<HTMLElement>  = this.registerJquerySelector( 'button' );
        const name: JQuery<HTMLElement>    = this.registerJquerySelector( 'name' );
        const email: JQuery<HTMLElement>   = this.registerJquerySelector( 'email' );
        const message: JQuery<HTMLElement> = this.registerJquerySelector( 'message' );

        event.preventDefault();

        if( !this.isChecking ) {
            this.isChecking = true;
            button.addClass( BaseContact.CLASS_BUTTON_WORKING ).html( 'checking' );

            const nameModule: InputModule  = this.checkInput( name, this.state.data.name );
            const emailModule: InputModule = this.checkEmail( email, this.state.data.email );
            const data                     = {
                name   : nameModule,
                email  : emailModule,
                message: this.state.data.message,
            };

            this.setState( { data } );

            if( nameModule.doesPass() && emailModule.doesPass() ) {
                const params: Partial<INewsletterModule> = {
                    action  : ActionEnum.Subscribe,
                    campaign: CampaignEnum.General,
                    name    : name.val() as string,
                    email   : email.val() as string,
                    message : message.val() as string,
                };

                NewsletterManager.Subscribe( NewsletterModule.Build( params ?? null ) ?? null ).then( this.onResponseNewsletter );
                this.turnOnFeedback();
            } else {
                button.removeClass( BaseContact.CLASS_BUTTON_WORKING ).html( `${ this.state?.module?.button?.text }` );
                this.isChecking = false;
            }
        }
    }

    /**
     *
     * @param {Partial<InputModule>} data
     * @return {object}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/05/21 08:53 pm
     * @see buildInputProps
     * @see buildInput
     * @see buildCheckbox
     * @see buildButton
     * @see buildFeedback
     */
    protected buildInputProps( data: Partial<InputModule> ): Record<string, unknown> {
        if( !data?.id ) {
            console.info( 'BASE CONTACT BUILD INPUT MODULE MISSING ID', { module: data } );
        }

        return {
            page              : this.state.page,
            module            : this.state.module,
            className         : data?.className ?? null,
            'data-id'         : data?.id ?? null,
            'data-prefix'     : this.state?.hash ?? null,
            'data-placeholder': data?.placeholder ?? null,
            'data-isBlank'    : data?.isBlank ?? false,
            'data-isError'    : data?.isError ?? false,
        };
    }

    /**
     *
     * @param {Partial<InputModule>} data
     * @param {string} className
     * @return {JSX.Element}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/25/21 07:35 pm
     * @see buildInputProps
     * @see buildInput
     * @see buildCheckbox
     * @see buildButton
     * @see buildFeedback
     */
    protected buildInput( data: Partial<InputModule>, className?: string ): JSX.Element {
        return (
            <div className={ className ?? 'cell small-12 medium-12' }>
                <Input { ...this.buildInputProps( data ?? null ) } />
            </div>
        );
    }

    /**
     *
     * @param {Partial<InputModule>} data
     * @param {string} className
     * @return {JSX.Element}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/25/21 07:35 pm
     * @see buildInputProps
     * @see buildInput
     * @see buildCheckbox
     * @see buildButton
     * @see buildFeedback
     */
    protected buildCheckbox( data: Partial<InputModule>, className?: string ): JSX.Element {
        return (
            <div className={ className ?? 'cell small-12 medium-12 section-with-checkbox' }>
                <Checkbox { ...this.buildInputProps( data ?? null ) } />
            </div>
        );
    }

    /**
     *
     * @return {JSX.Element}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/25/21 07:35 pm
     * @see buildInputProps
     * @see buildInput
     * @see buildCheckbox
     * @see buildButton
     * @see buildFeedback
     */
    protected buildButton(): JSX.Element {
        return (
            <div className="cell small-12 medium-12">
                <Button id={ BaseContact.getHashId( this.state?.hash, `${ BaseContact.ID_BUTTON }` ) } module={ this.props?.module?.button } />
            </div>
        );
    }

    /**
     *
     * @return {JSX.Element}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/25/21 07:35 pm
     * @see buildInputProps
     * @see buildInput
     * @see buildCheckbox
     * @see buildButton
     * @see buildFeedback
     */
    protected buildFeedback(): JSX.Element {
        return (
            <div id={ BaseContact.getHashId( this.state.hash ?? null, `${ BaseContact.ID_FEEDBACK }` ) } className={ `cell small-12 medium-12 ${ BaseContact.CLASS_FEEDBACK }` }>
                <div id={ BaseContact.getHashId( this.state.hash ?? null, `${ BaseContact.ID_FEEDBACK_TEXT }` ) } className={ BaseContact.CLASS_FEEDBACK_TEXT }>
                    { BaseContact.DEFAULT_CONTACT_STATUS }
                </div>
            </div>
        );
    }

    /**
     *
     * @param {jQuery<HTMLElement>} input
     * @param {InputModule} data
     * @return {InputModule}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/05/21 09:27 pm
     */
    protected checkInput( input: JQuery<HTMLElement>, data: InputModule ): InputModule {
        if( input?.val() === '' ) {
            return data.setIsBlank( true ).setIsError( false );
        }

        return data.setIsBlank( false ).setIsError( false );
    }

    /**
     *
     * @param {jQuery<HTMLElement>} input
     * @param {InputModule} data
     * @return {InputModule}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/05/21 09:27 pm
     */
    protected checkEmail( input: JQuery<HTMLElement>, data: InputModule ): InputModule {
        if( !input || input?.val() === '' ) {
            return data.setIsBlank( true ).setIsError( false );
        }
        if( !EmailValidator.validate( input?.val() as string ) ) {
            return data.setIsBlank( false ).setIsError( true );
        }

        return data.setIsBlank( false ).setIsError( false );
    }

    /**
     *
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 01/30/21 11:30 am
     */
    public componentDidMount(): void {
        this.state.jquery = new Map<string, JQuery<HTMLElement>>(
            [
                [ BaseContact.ID_BUTTON, jQuery( `#${ BaseContact.getHashId( this.state.hash, this.state.data[ BaseContact.ID_BUTTON ]?.id ?? BaseContact.ID_BUTTON ) }` ) ],
                [ BaseContact.ID_NAME, jQuery( `#${ BaseContact.getHashId( this.state.hash, this.state.data[ BaseContact.ID_NAME ]?.id ?? BaseContact.ID_NAME ) }` ) ],
                [ BaseContact.ID_EMAIL, jQuery( `#${ BaseContact.getHashId( this.state.hash, this.state.data[ BaseContact.ID_EMAIL ]?.id ?? BaseContact.ID_EMAIL ) }` ) ],
                [ BaseContact.ID_MESSAGE, jQuery( `#${ BaseContact.getHashId( this.state.hash, this.state.data[ BaseContact.ID_MESSAGE ]?.id ?? BaseContact.ID_MESSAGE ) }` ) ],
                [ BaseContact.ID_AFFILIATE, jQuery( `#${ BaseContact.getHashId( this.state.hash, this.state.data[ BaseContact.ID_AFFILIATE ]?.id ?? BaseContact.ID_AFFILIATE ) }` ) ],
                [ BaseContact.ID_SPONSOR, jQuery( `#${ BaseContact.getHashId( this.state.hash, this.state.data[ BaseContact.ID_SPONSOR ]?.id ?? BaseContact.ID_SPONSOR ) }` ) ],
                [ BaseContact.ID_FEEDBACK, jQuery( `#${ BaseContact.getHashId( this.state.hash, this.state.data[ BaseContact.ID_FEEDBACK ]?.id ?? BaseContact.ID_FEEDBACK ) }` ) ],
                [ BaseContact.ID_FEEDBACK_TEXT, jQuery( `#${ BaseContact.getHashId( this.state.hash, this.state.data[ BaseContact.ID_FEEDBACK_TEXT ]?.id ?? BaseContact.ID_FEEDBACK_TEXT ) }` ) ],
            ] );
    }
}