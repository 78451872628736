import React                  from 'react';
import { ParticleBase, Wave } from './ParticleBase';
import { Util }               from '../../../api/com/ewing/social/tool';

/**
 * @class Particle2
 * @extends ParticleBase
 * @author Isaac Ewing
 * @version 1.0.0 02/14/21 04:07 pm - documented
 */
export class Particle2 extends ParticleBase<any, any> {
     /**
      *
      * @type {number}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/14/21 04:07 pm - documented
      */
     protected static count: number = 0;

     public constructor( props: any ) {
          super( props );

          this.state = {
               type            : Particle2.PARTICLE.type2,
               hash            : Util.registerHash(),
               className       : [],
               action          : false,
               canvas          : null,
               container       : null,
               sprite          : null,
               app             : null,
               bunny           : null,
               interval        : null,
               isBrowser       : false,
               dimensions      : props.dimensions,
               particles       : {
                    max      : props.particleMax,
                    frequency: props.particleFrequency,
                    children : [],
               },
               visibilitySensor: {
                    active  : true,
                    onChange: ( visible: boolean ) => { this.registerOnChange( visible ); },
               },
          };
     }

     protected registerRender(): JSX.Element {
          return <div id={ this.state.hash } className="particle2-bg" style={ this.state.dimensions } />;
     }

     protected update(): void {
          const temp: any [] = this.state.particles.children.filter( ( p: Wave ) => p.isAnimationFinished() ? p.prepareForGC() : p.move() );

          this.state.particles = {
               ...this.state.particles,
               children: temp,
          };

          requestAnimationFrame( this.update.bind( this ) );
     }
}

export default Particle2;
