import { AComponent }                        from '../../../abstract';
import { IGalleryImageModule, IImageModule } from '../../../interface';
import { TitleModule }                       from '../../../module/api/title.module';
import { LinkModule }                        from '../../../module/api/link.module';
import { ImageModule }                       from '../image.module';

/**
 * @class GalleryImageModule
 * @extends AComponent
 * @implements IGalleryImageModule
 * @author Isaac Ewing
 * @version 1.0.0 10/03/20 04:10 pm
 * @version 1.1.0 02/22/21 04:32 pm - updated to extend AComponent
 * @classdesc This class handles the encapsulated data from the database when using the gallery layouts
 */
export class GalleryImageModule extends AComponent implements IGalleryImageModule {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 04:32 pm
      */
     protected static readonly API_ROOT_NODE: string  = process.env.REACT_APP_GRAPHQL_GET_GALLERY_IMAGE;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 04:32 pm
      */
     protected static readonly DEFAULT_JQUERY: string = 'image';

     /**
      *
      * @param module {GalleryImageModule} The object containing the data
      * @return {GalleryImageModule|null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 04:44 pm
      * @see Build
      * @see BuildForShortcode
      * @see image
      * @see title
      * @see link
      * @see setImage
      * @see setTitle
      * @see setLink
      */
     public static Build( module: GalleryImageModule ): GalleryImageModule;
     public static Build( obj: Partial<IGalleryImageModule> ): GalleryImageModule;
     public static Build( json: string ): GalleryImageModule;
     public static Build( image?: ImageModule, title?: TitleModule, link?: LinkModule, id?: number | string, hash?: string, className?: string, jquery?: string,
                          shortcode?: boolean ): GalleryImageModule;
     public static Build( image?: ImageModule, title?: TitleModule, link?: LinkModule, id?: number | string, hash?: string, classNames?: string[], jquery?: string,
                          shortcode?: boolean ): GalleryImageModule;
     public static Build( image?: ImageModule, title?: TitleModule, link?: LinkModule, id?: number | string, hash?: string, classNames?: Set<string>, jquery?: string,
                          shortcode?: boolean ): GalleryImageModule;
     public static Build( dataOrImage?: unknown, title?: TitleModule, link?: LinkModule, id?: number | string, hash?: string, classNames?: never, jquery?: string,
                          shortcode?: boolean ): GalleryImageModule {
          if( dataOrImage ) {
               if( dataOrImage instanceof GalleryImageModule ) {
                    return dataOrImage;
               }
               if( typeof dataOrImage === 'object' ) {
                    const localData: Record<string, never> = dataOrImage as Record<string, never>;

                    if( localData?.image ) {
                         return new GalleryImageModule( ImageModule.Build( localData?.image ) ?? null, TitleModule.Build( localData?.title ) ?? null,
                                                        LinkModule.Build( localData?.link ) ?? null, localData?.id ?? null, localData?.hash ?? null,
                                                        localData?.className ?? null, localData?.jquery ?? null, localData?.shortcode ?? null );
                    } else if( title ) {
                         return new GalleryImageModule( ImageModule.Build( dataOrImage as IImageModule ) ?? null, TitleModule.Build( title ) ?? null, LinkModule.Build( link ) ?? null,
                                                        id ?? null, hash ?? null, classNames ?? null, jquery ?? null, shortcode ?? null );
                    }
               }
               if( typeof dataOrImage === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrImage ) );
                    } catch( exception ) {
                         // not a valid json string
                         return new GalleryImageModule( ImageModule.Build( dataOrImage ) ?? null, TitleModule.Build( title ) ?? null, LinkModule.Build( link ) ?? null, id ?? null,
                                                        hash ?? null, classNames ?? null, jquery ?? null, shortcode ?? null );
                    }
               }
          }

          return null;
     }

     /**
      *
      * @param {ImageModule} image
      * @param {TitleModule} title
      * @param {LinkModule} link
      * @param {number | string} id
      * @param {string} hash
      * @param {string} className
      * @param {string} jquery
      * @param {boolean} shortcode
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 04:44 pm
      * @see image
      * @see title
      * @see link
      * @see setImage
      * @see setTitle
      * @see setLink
      */
     public constructor( image?: ImageModule, title?: TitleModule, link?: LinkModule, id?: number | string, hash?: string, className?: string, jquery?: string, shortcode?: boolean );
     public constructor( image?: ImageModule, title?: TitleModule, link?: LinkModule, id?: number | string, hash?: string, classNames?: string[], jquery?: string, shortcode?: boolean );
     public constructor( image?: ImageModule, title?: TitleModule, link?: LinkModule, id?: number | string, hash?: string, classNames?: Set<string>, jquery?: string, shortcode?: boolean );
     public constructor( image?: ImageModule, title?: TitleModule, link?: LinkModule, id?: number | string, hash?: string, classNames?: never, jquery?: string, shortcode?: boolean ) {
          super( id, hash, classNames, jquery ?? GalleryImageModule.DEFAULT_JQUERY, shortcode );

          this.image = image ?? null;
          this.title = title ?? null;
          this.link  = link ?? null;
     }

     /**
      *
      * @return {ImageModule} Returns the module for the image
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 04:44 pm
      * @see image
      * @see title
      * @see link
      * @see setImage
      * @see setTitle
      * @see setLink
      */
     public get image(): ImageModule {
          return this._data.get( 'image' );
     }

     /**
      *
      * @param {ImageModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 04:44 pm
      * @see image
      * @see title
      * @see link
      * @see setImage
      * @see setTitle
      * @see setLink
      */
     public set image( value: ImageModule ) {
          this._data.set( 'image', value );
     }

     /**
      *
      * @return {TitleModule} Returns the module for the image
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 04:44 pm
      * @see image
      * @see title
      * @see link
      * @see setImage
      * @see setTitle
      * @see setLink
      */
     public get title(): TitleModule {
          return this._data.get( 'title' );
     }

     /**
      *
      * @param {TitleModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 04:44 pm
      * @see image
      * @see title
      * @see link
      * @see setImage
      * @see setTitle
      * @see setLink
      */
     public set title( value: TitleModule ) {
          this._data.set( 'title', value );
     }

     /**
      *
      * @return {LinkModule} Returns the module for the image
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 04:44 pm
      * @see image
      * @see title
      * @see link
      * @see setImage
      * @see setTitle
      * @see setLink
      */
     public get link(): LinkModule {
          return this._data.get( 'link' );
     }

     /**
      *
      * @param {LinkModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 04:44 pm
      * @see image
      * @see title
      * @see link
      * @see setImage
      * @see setTitle
      * @see setLink
      */
     public set link( value: LinkModule ) {
          this._data.set( 'link', value );
     }

     /**
      *
      * @param {ImageModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 04:44 pm
      * @see image
      * @see title
      * @see link
      * @see setImage
      * @see setTitle
      * @see setLink
      */
     setImage( value: ImageModule ): this {
          this.image = value;

          return this;
     }

     /**
      *
      * @param {TitleModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 04:44 pm
      * @see image
      * @see title
      * @see link
      * @see setImage
      * @see setTitle
      * @see setLink
      */
     setTitle( value: TitleModule ): this {
          this.title = value;

          return this;
     }

     /**
      *
      * @param {LinkModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 04:44 pm
      * @see image
      * @see title
      * @see link
      * @see setImage
      * @see setTitle
      * @see setLink
      */
     setLink( value: LinkModule ): this {
          this.link = value;

          return this;
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 04:44 pm
      * @see image
      * @see title
      * @see link
      * @see setImage
      * @see setTitle
      * @see setLink
      */
     public toObject(): Record<string, unknown> {
          return {
               ...super.toObject(),
               image: this.image.toObject(),
               title: this.title?.toObject() ?? null,
               link : this.link?.toObject() ?? null,
          };
     }
}