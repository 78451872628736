import React             from 'react';
import { ErrorInfo }     from 'react';
import { ISectionProps } from '../../../api/com/ewing/social/interface';
import { BaseSection }   from '../../atom/BaseSection';
import { Divider }       from '../../atom/Divider';
import { Subtitle }      from '../../atom/Subtitle';
import { Title }         from '../../atom/Title';

/**
 * @class Layout6
 * @extends BaseSection
 * @author Isaac Ewing
 * @version 1.0.0 01/30/21 11:30 am - documented
 */
export class Layout6 extends BaseSection {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am - documented
      */
     protected static readonly COMPONENT_CLASS: string = 'layout-6';
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/30/21 12:15 pm
      */
     protected static readonly CLASS_PLATFORM: string  = 'platform-block';
     /**
      *
      * @type {ISectionProps}
      * @readonly
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/12/21 03:41 pm
      */
     public readonly props:ISectionProps;

     /**
      *
      * @param props {ISectionProps}
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am - documented
      */
     public constructor( props: ISectionProps ) {
          super( props );

          this.state = {
               className: new Set<string>( [ 'grid-x', Layout6.COMPONENT_CLASS ] ),
               children : new Set<JSX.Element>(),
          };

          this.registerStyle( props?.module );
          this.registerCurve( props?.module );
          this.registerSubmenus( props?.module );
          this.registerClasses( props?.module );
     }

     public componentDidCatch( error: Error, errorInfo: ErrorInfo ): void {
          console.error( 'LAYOUT 6', error, errorInfo );
     }

     /**
      *
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am
      * documented
      */
     public componentDidMount(): void {
          this.setState( {
                              children: new Set<JSX.Element>(
                                   [
                                        ...this.state?.children ?? null,
                                        ...this.buildClaimSocial( this.state.page.platforms ?? null ),
                                   ] ),
                         } );
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am
      * documented
      */
     public render(): JSX.Element {
          return (
               <section { ...this.registerSectionProps( this.props.module ) }>
                    <div className="grid-container content">
                         <div className="grid-x align-left">
                              <div className="cell small-12 medium-12">
                                   <Subtitle module={ this.props?.module?.subtitle } />
                                   <Divider />
                                   <Title module={ this.props?.module?.title } />
                              </div>
                              <div className={ `cell small-12 ${ Layout6.CLASS_PLATFORM }` }>
                                   { this.props.children }
                                   { this.state.children }
                              </div>
                         </div>
                    </div>
               </section>
          );
     }
}

export default Layout6;
