import { AModule }       from '../../abstract/module.abstract';
import { IAmountModule } from '../../interface';

/**
 * @class AmountModule
 * @extends AModule
 * @implements IAmountModule
 * @author Isaac Ewing
 * @version 1.0.0 02/07/21 12:32 pm
 * @version 1.1.0 02/23/21 08:00 pm - updated to extend AComponent
 * @classdesc This class will handle the storage for price amount data. This is the third class to
 * implement the interfaces in a similar way to laravel/lumen
 */
export class AmountModule extends AModule implements IAmountModule {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.1.0 02/23/21 08:00 pm
      */
     protected static readonly API_ROOT_NODE: string = process.env.REACT_APP_GRAPHQL_GET_SITE_PAGE;

     /**
      *
      * @param module {AmountModule} The object containing the data
      * @return {AmountModule|null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/23/21 08:00 pm
      * @see Build
      * @see id
      * @see amount
      * @see setId
      * @see setAmount
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public static Build( module: AmountModule ): AmountModule;
     public static Build( obj: Partial<IAmountModule> ): AmountModule;
     public static Build( json: string ): AmountModule;
     public static Build( amount?: number, id?: number | string ): AmountModule;
     public static Build( dataOrAmount?: unknown, id?: number | string ): AmountModule {
          if( dataOrAmount ) {
               dataOrAmount = this.mapAPIRootNode( dataOrAmount, this.API_ROOT_NODE );

               if( dataOrAmount instanceof AmountModule ) {
                    return dataOrAmount;
               }
               if( typeof dataOrAmount === 'object' ) {
                    const localData: Partial<IAmountModule> = dataOrAmount;

                    return new AmountModule( localData?.amount ?? null, localData?.id ?? null );
               }
               if( typeof dataOrAmount === 'number' ) {
                    return new AmountModule( +dataOrAmount ?? null, id ?? null );
               }
               if( typeof dataOrAmount === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrAmount ) );
                    } catch( exception ) {
                         // not a valid json string
                         return new AmountModule( +dataOrAmount, id ?? null );
                    }
               }
          }

          return null;
     }

     /**
      *
      * @param amount {number}
      * @param id {number | string} The object containing the data
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/11/20 02:58 pm
      * @version 1.1.0 02/23/21 08:00 pm
      * @see id
      * @see amount
      * @see setId
      * @see setAmount
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public constructor( amount?: number, id?: number | string ) {
          super( id );

          this.amount = +amount;
     }

     /**
      *
      * @return {number}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/11/20 02:58 pm
      * @version 1.1.0 02/23/21 08:00 pm
      * @public
      * @see id
      * @see amount
      * @see setId
      * @see setAmount
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public get amount(): number {
          return this._data.get( 'amount' );
     }

     /**
      *
      * @param {number} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/11/20 02:58 pm
      * @version 1.1.0 02/23/21 08:00 pm
      * @see id
      * @see amount
      * @see setId
      * @see setAmount
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public set amount( value: number ) {
          this._data.set( 'amount', value );
     }

     /**
      *
      * @param {number} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/11/20 02:58 pm
      * @version 1.1.0 02/23/21 08:00 pm
      * @see id
      * @see amount
      * @see setId
      * @see setAmount
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public setAmount( value: number ): this {
          this.amount = value;

          return this;
     }

     /**
      *
      * @return {Object}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/11/20 02:58 pm
      * @version 1.1.0 02/23/21 08:00 pm
      * @see id
      * @see amount
      * @see setId
      * @see setAmount
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public toObject(): Record<string, unknown> {
          return {
               id    : this.id,
               amount: this.amount,
          };
     }
}