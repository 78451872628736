import React                      from 'react';
import PropTypes                  from 'prop-types';
import { ColumnsEnum }            from '../../api/com/ewing/social/enum';
import { IBaseState, IMenuProps } from '../../api/com/ewing/social/interface';
import { LinkModule }             from '../../api/com/ewing/social/module';
import { MenuModule }             from '../../api/com/ewing/social/component';
import { Base }                   from '../atom/Base';
import { Button }                 from '../atom/Button';
import { Link }                   from '../atom/Link';

/**
 * @class Menu
 * @extends Base
 * @author Isaac Ewing
 * @version 1.0.0 - 10/04/20 12:05 pm
 */
export class Menu extends Base {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/22/21 08:36 pm
      */
     protected static readonly COMPONENT_CLASS: string  = 'menu-block';
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/22/21 08:36 pm
      */
     protected static readonly CONSOLE_ENABLED: boolean = false;
     protected static readonly itemClass: string        = 'item';
     protected static readonly socials: Set<string>     = new Set<string>(
          [
               'discord',
               'facebook',
               'instagram',
               'onlyfans',
               'patreon',
               'tiktok',
               'twitch',
               'twitter',
               'youtube',
          ] );
     public static propTypes                            = {
          ...Base.propTypes,
          module   : PropTypes.instanceOf( MenuModule ).isRequired,
          columns  : PropTypes.string,
          discord  : PropTypes.bool,
          facebook : PropTypes.bool,
          instagram: PropTypes.bool,
          onlyfans : PropTypes.bool,
          patreon  : PropTypes.bool,
          tiktok   : PropTypes.bool,
          twitch   : PropTypes.bool,
          twitter  : PropTypes.bool,
          youtube  : PropTypes.bool,
     };
     public static defaultProps                         = {
          columns: ColumnsEnum.Auto,
     };
     /**
      *
      * @type {Partial<IMenuProps>}
      * @readonly
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/29/21 07:23 pm
      */
     public readonly props: Partial<IMenuProps>;
     /**
      *
      * @type {Partial<IBaseState>}
      * @readonly
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/29/21 07:37 pm
      */
     public state: Partial<IBaseState>;

     /**
      *
      * @param props {MenuModule|null} The module with all the data
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 - 10/04/20 12:05 pm
      */
     protected registerSocials( props: MenuModule ): void {
          const socials: Set<string>             = new Set<string>();
          const modules: Record<string, unknown> = props.toObject();

          Menu.socials.forEach( ( social: string ): void => {
               if( modules[ social ] ) {
                    socials.add( social );
               }
          } );

          if( Menu.CONSOLE_ENABLED ) {
               console.info( '***** MENU TSX AFTER PROPS', { socials, props } );
          }

          for( const property in this.props ) {
               const tempProperty: string = property ?? null;

               if( Menu.socials.has( tempProperty ) ) {
                    socials.add( tempProperty );
               }
          }

          if( Menu.CONSOLE_ENABLED ) {
               console.info( '***** MENU TSX AFTER THIS.PROPS', { socials, props: this.props } );
          }

          socials.forEach( ( social: string ): void => {
               const module: LinkModule = LinkModule.Build( { path: social, text: social } ?? null );

               this.state.children.add( <Link module={ module } target="_blank" rel="noopener" /> );
          } );
     }

     /**
      *
      * @param {MenuModule} props
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/20/21 10:32 pm
      */
     protected registerLinks( props: MenuModule ): void {
          if( props?.links?.size ) {
               props.links.forEach( ( link: LinkModule ): void => {
                    if( Menu.CONSOLE_ENABLED ) {
                         console.log( '___MENU REGISTER LINK', { link } );
                    }

                    this.state.children.add( <Link module={ link } /> );
               } );
          }
     }

     /**
      *
      * @param props {MenuModule|null} The module with all the data
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 - 10/04/20 12:05 pm
      */
     protected registerColumns( props: MenuModule ): void {
          if( props?.columns ) {
               switch( props.columns ) {
                    case ColumnsEnum.Single:
                    case ColumnsEnum.Double:
                         this.state.columns = props.columns;
                         break;
                    default:
                         this.state.columns = Menu.defaultProps.columns;
                         break;
               }
          } else {
               this.state.columns = Menu.defaultProps.columns;
          }
          if( this.state.columns === ColumnsEnum.Auto && this.state.children.size <= 1 ) {
               this.state.columns = ColumnsEnum.Single;
          } else {
               this.state.columns = ColumnsEnum.Double;
          }
     }

     /**
      *
      * @param {JSX.Element} child
      * @return {JSX.Element}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 - 10/04/20 12:05 pm
      */
     protected renderItem( child: JSX.Element ): JSX.Element {
          return <li className={ Menu.itemClass } key={ this.registerHash() }>{ child }</li>;
     }

     /**
      *
      * @return {JSX.Element}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 - 10/04/20 12:05 pm
      */
     protected renderColumnsSingle(): JSX.Element {
          const children: Set<JSX.Element> = new Set<JSX.Element>();

          this.state.children.forEach( ( child: JSX.Element ): void => {
               children.add( this.renderItem( child ) );
          } );

          return (
               <div className="grid-x">
                    <div className="cell small-12">
                         <ul className="vertical menu">
                              { children }
                         </ul>
                    </div>
               </div>
          );
     }

     /**
      *
      * @return {JSX.Element}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 - 10/04/20 12:05 pm
      */
     protected renderColumnsDouble(): JSX.Element {
          let counter   = 0;
          const columns = {
               left : [],
               right: [],
               after: [],
          };

          this.state.children.forEach( ( child: JSX.Element ) => {
               if( child.type === Link ) {
                    const template = this.renderItem( child );

                    counter % 2 === 0 ? columns.left.push( template ) : columns.right.push( template );
               } else if( child.type === Button ) {
                    const temp = <Button key={ this.registerHash() } { ...child.props } style={ this.state.style } />;
                    columns.after.push( temp );
               } else if( child.type === 'span' ) {
                    columns.after.push( <span key={ this.registerHash() }>great, SPAN was detected...</span> );
               } else {
                    console.log( '>>> unknown child', child );
               }

               counter++;
          } );

          return (
               <div className="grid-x">
                    <div className="cell small-6 medium-5">
                         <ul className="vertical menu">
                              { columns.left }
                         </ul>
                    </div>
                    <div className="cell small-6 medium-7">
                         <ul className="vertical menu">
                              { columns.right }
                         </ul>
                    </div>
                    <div className="cell small-12">
                         { columns.after }
                    </div>
               </div>
          );
     }

     protected registerChildCount( props: MenuModule ): void {
          if( !this.state.children.size && !props.links.size ) {
               this.state.className.add( 'do-not-show' );
          }
     }

     /**
      *
      * @param props {MenuModule}
      * @return {void}
      * @throws Throws an exception if method is called without having an override
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/20/21 09:10 pm
      */
     protected registerPropsCall( props: MenuModule ): void {
          //registerStyle();
          this.registerClasses( props );
          this.registerChildren( props );
          this.registerSocials( props );
          this.registerLinks( props );
          this.registerColumns( props );
          this.registerChildCount( props );
     }

     /**
      *
      * @param props {any}
      * @return {void}
      * @constructor
      * @author Isaac Ewing
      * @version 1.0.0 - 10/04/20 12:05 pm
      */
     public constructor( props: IMenuProps ) {
          super( props );

          this.state = {
               module   : props.module,
               className: new Set<string>( [ Menu.COMPONENT_CLASS ] ),
               children : new Set<JSX.Element>(),
               columns  : null,
          };

          //console.error( '%%%%% MENU TSX', { module: props.module, props } );
          this.registerProps( props.module );

     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 - 10/04/20 12:05 pm
      */
     public render(): JSX.Element {
          return (
               <div className={ [ ...this.state.className ].join( ' ' ) }>
                    { this.state.columns === Menu.defaultProps.columns ? this.renderColumnsSingle() : this.renderColumnsDouble() }
               </div>
          );
     }
}