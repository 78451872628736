import React                           from 'react';
import jQuery                          from 'jquery';
import XMLViewer                       from 'react-xml-viewer';
import DownloadLink                    from 'react-download-link';
import { SectionModule, ViewerModule } from '../../../api/com/ewing/social/component';
import { Util }                        from '../../../api/com/ewing/social/tool';
import { URLManager }                  from '../../../api/com/ewing/social/manager';
import { BaseSection }                 from '../../atom/BaseSection';
import { Subtitle }                    from '../../atom/Subtitle';
import { Title }                       from '../../atom/Title';
import isNode                          from 'detect-node';

export class Viewer1 extends BaseSection<any, any> {
     protected static readonly COMPONENT_CLASS: string = 'viewer-1';
     protected static readonly BUTTON_ID: string       = 'download-button';

     /**
      *
      * @param props {SectionModule}
      * @return {void}
      * @throws Throws an exception if method is called without having an override
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am
      * documented
      */
     protected registerPropsCall( props: SectionModule ): void {
          this.registerStyle( props );
          this.registerCurve( props );
          this.registerSubmenus( props );
          this.registerClasses( props );
          this.registerXML();
     }

     /**
      *
      * @param props {any}
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am
      * documented
      */
     public constructor( props: any ) {
          super( props );

          console.info( '##### VIEWER 1 PROS', { props, module: props?.module, data: props?.module?.data } );

          this.state = {
               module   : props.module,
               page     : props.page,
               hash     : Util.registerHash(),
               viewer   : ViewerModule.Build( props.module?.data ?? null ),
               className: new Set<string>( [ 'grid-x', Viewer1.COMPONENT_CLASS ] ),
               children : new Set<JSX.Element>(),
          };

          this.registerProps( this.state.module ?? null );
     }

     /**
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am
      * documented
      */
     protected registerXML(): void {
          const onError   = ( exception ): void => {
               console.log( 'jQuery exception', exception );
          };
          const onSuccess = ( response ): void => {
               const xmlOld: string          = jQuery( response ).find( process.env.REACT_APP_VIEWER_TAG_HINT_XML ?? null )[ 0 ].outerHTML;
               const indentSize: number      = +process.env.REACT_APP_VIEWER_INDENT;
               const theme                   = {
                    tagColor           : process.env.REACT_APP_VIEWER_COLOR_TAG,
                    textColor          : process.env.REACT_APP_VIEWER_COLOR_TEXT,
                    cdataColor         : process.env.REACT_APP_VIEWER_COLOR_CDATA,
                    attributeKeyColor  : process.env.REACT_APP_VIEWER_COLOR_ATTR_KEY,
                    attributeValueColor: process.env.REACT_APP_VIEWER_COLOR_ATTR_VALUE,
               };
               const xml: string             = xmlOld.replaceAll( 'demo.ewingsocial.com', URLManager.Domain );
               const props                   = {
                    xml,
                    theme,
                    indentSize,
               };
               const children: JSX.Element[] = [ <XMLViewer key={ `${ this.state.hash }-viewer` } className="viewer" { ...props } /> ];

               if( this.state.viewer.options.download ) {
                    const properties = {
                         key       : `${ this.state.hash }-download`,
                         label     : 'download',
                         className : 'button-link',
                         filename  : this.state.viewer.file,
                         exportFile: () => xml,
                         style     : {},
                    };

                    children.push( <DownloadLink { ...properties } /> );
               }

               this.setState( { children: [ ...this.state?.children, ...children ] } );
          };
          const settings  = {
               type    : 'GET',
               url     : `./${ this.state.viewer.file }`,
               dataType: 'xml',
               error   : onError,
               success : onSuccess,
          };

          if( jQuery?.get ) {
               jQuery.get( settings );
          } else {
               console.log( 'XXX viewer 1 component...', 'could not called jquery get... it is not available' );
          }
     }

     /**
      *
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am
      * documented
      */
     public componentDidMount(): void {
          if( this.state.viewer.options.download ) {
               const button: JQuery<HTMLElement> = jQuery( `#${ Viewer1.BUTTON_ID }` );

               button.on( 'click', ( event ) => {
                    event.preventDefault();
               } );
          }
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am
      * documented
      */
     public render(): JSX.Element {
          let title: JSX.Element;
          let subtitle: JSX.Element;

          if( this.state?.module?.title ) {
               title = <Title key={ this.registerHash() } module={ this.state?.module?.title } />;
          }
          if( this.state?.module?.subtitle ) {
               subtitle = <Subtitle key={ this.registerHash() } module={ this.state?.module?.subtitle } />;
          }

          return (
               <section { ...this.registerSectionProps() }>
                    <div className="grid-container content">
                         <div className="grid-x align-left">
                              <div className="cell small-12 medium-10">
                                   { title }
                                   { subtitle }
                              </div>
                              <div className="cell small-12 medium-10 medium-offset-1 xml-viewer">
                                   { this.state.children }
                              </div>
                         </div>
                    </div>
               </section>
          );
     }
}
