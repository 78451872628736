import { IShortcodeQuery } from '../../interface';

/**
 * @class AShortcode
 * @abstract
 * @author Isaac Ewing
 * @version 1.0.0 02/12/21 12:59 pm
 */
export abstract class AShortcode {
    protected static readonly GET_LINK_QUERY: string  = `
        query getLink( $id: Int! ){
            getLink(
                where: {
                    id: $id
                }
            ){
                id
                path
                text
                icon
            }
        }
    `;
    protected static readonly GET_TITLE_QUERY: string = `
        query getTitle( $id: Int! ){
            getTitle(
                where: {
                    id: $id
                }
            ){
                id
                text
            }
        }
    `;

    /**
     *
     * @param id {number} The id number for the model
     * @return {IShortcodeQuery} Returns a correct query formatted document version
     * @author Isaac Ewing
     * @version 1.0.0 02/12/21 12:59 pm
     * @version 2.0.0 07/25/21 05:23 pm - changed return type, added support for variables, updated to support axios (removed apollo query component)
     * @see getLink
     * @see getTitle
     */
    protected static getTitle( id: number ): IShortcodeQuery {
        return {
            query    : this.GET_TITLE_QUERY,
            variables: {
                id,
            },
        };
    }

    /**
     *
     * @param id {number} The id number for the model
     * @return {IShortcodeQuery} Returns a correct query formatted document version
     * @author Isaac Ewing
     * @version 1.0.0 02/12/21 12:59 pm
     * @version 2.0.0 07/25/21 05:23 pm - changed return type, added support for variables, updated to support axios (removed apollo query component)
     * @see getLink
     * @see getTitle
     */
    protected static getLink( id: number ): IShortcodeQuery {
        return {
            query    : this.GET_LINK_QUERY,
            variables: {
                id,
            },
        };
    }
}