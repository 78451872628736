import { AModule }     from '../../abstract/module.abstract';
import { AddressUtil } from '../../tool';

/**
 * @class InfoModule
 * @extends AModule
 * @author Isaac Ewing
 * @version 1.0.0 12/09/20 01:41 pm
 * @version 2.0.0 05/30/21 08:11 pm - updated to extend AModule (was BaseModule)
 */
export class InfoModule extends AModule {
     /**
      * @param data {Object|null} The object containing the data
      * @return {InfoModule|null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 12:34 pm
      */
     public static Build( data: Record<string, unknown> ): InfoModule | null {
          if( data ) {
               return new InfoModule( data );
          }

          return null;
     }

     /**
      *
      * @param data
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 01:41 pm
      */
     public constructor( data: Record<string, unknown> ) {
          super( null );

          this.title   = ( data.title ?? process.env.REACT_APP_MAP_TITLE ) as string;
          this.address = AddressUtil.Build( data.address ?? null );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 01:41 pm
      * @see title
      * @see address
      */
     public get title(): string {
          return this._data.get( 'title' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/30/21 07:55 pm
      * @see title
      * @see address
      */
     public set title( value: string ) {
          this._data.set( 'title', value );
     }

     /**
      *
      * @return {AddressUtil}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 01:41 pm
      * @see title
      * @see address
      */
     public get address(): AddressUtil {
          return this._data.get( 'address' );
     }

     /**
      *
      * @param {AddressUtil} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/30/21 07:55 pm
      * @see title
      * @see address
      */
     public set address( value: AddressUtil ) {
          this._data.set( 'address', value );
     }

     public toObject(): Record<string, unknown> {
          return {
               id     : null,
               title  : this.title,
               address: this.address.toObject(),
          };
     }
}