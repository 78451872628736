import React                 from 'react';
import jQuery                from 'jquery';
import PropTypes             from 'prop-types';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { ICharacterProps }   from '../../../../api/com/ewing/social/interface';
import { ConsoleManager }    from '../../../../api/com/ewing/social/manager';
import { Base }              from '../../Base';

/**
 * @class Character
 * @extends Base
 * @author Isaac Ewing
 * @version 1.0.0 12/24/20 01:35 pm
 */
export class Character extends Base<Partial<ICharacterProps>> {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 06:23 pm
      */
     protected static readonly CONSOLE_PREFIX: string   = `${ process.env.REACT_APP_CONSOLE_PREFIX_COMPONENT } CHAR ${ process.env.REACT_APP_CONSOLE_SUFFIX_COMPONENT }`;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 06:23 pm
      */
     protected static readonly CONSOLE_ENABLED: boolean = true;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/24/20 01:35 pm
      */
     protected static readonly COMPONENT_CLASS: string  = 'character';
     public static propTypes                            = {
          ...Base.propTypes,
          caps    : PropTypes.bool,
          password: PropTypes.bool,
          disabled: PropTypes.bool,
          animate : PropTypes.bool,
          fadeIn  : PropTypes.bool,
          text    : PropTypes.string,
          tabIndex: PropTypes.number,
     };
     /**
      *
      * @type {Partial<ICharacterProps>}
      * @readonly
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/31/21 12:46 pm
      */
     public readonly props: Partial<ICharacterProps>;

     /**
      *
      * @param props {any}
      * @return {void}
      * @throws Throws an exception if method is called without having an override
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/24/20 01:24 pm
      */
     protected registerPropsCall( props: ICharacterProps ): void {
          this.registerStyle( props );
          this.registerClasses( props );
          this.registerCaps( props );
          this.registerPassword( props );
          this.registerDisabled( props );
          this.registerAnimate( props );
     }

     /**
      *
      * @param {boolean} visible
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/24/20 01:35 pm
      */
     protected registerOnChange( visible: boolean ): void {
          super.registerOnChange( visible );

          this.state.className.add( Character.CLASSNAME_FADE_IN );
     }

     /**
      *
      * @param {React.FormEvent<HTMLInputElement>} event
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 06:10 pm
      */
     protected registerOnInputChange( event: React.FormEvent<HTMLInputElement> ): void {
          const value: string = event.currentTarget.value;

          if( value !== '' ) {
               if( this.props?.id ) {
                    const index: number               = this.props.id.lastIndexOf( '-char' );
                    const slice: string               = this.props.id.slice( 0, index + 5 );
                    const name: string                = `${ slice }${ this.props.tabIndex }`;
                    const jquery: JQuery<HTMLElement> = jQuery( `#${ name }` );

                    if( jquery.length ) {
                         jquery.focus();
                    } else {
                         jQuery( `#${ slice }0` ).focus();
                    }

                    if( Character.CONSOLE_ENABLED ) {
                         ConsoleManager.Log( Character.CONSOLE_PREFIX ?? null, 'CHARACTER', 'GREAT... character changed...', { id: this.props.id, index, slice, name, jquery } );
                    }
               }
          }
     }

     /**
      *
      * @param props {any}
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/24/20 01:35 pm
      */
     public constructor( props: ICharacterProps ) {
          super( props );

          this.state = {
               initialized     : false,
               className       : new Set<string>( [ Character.COMPONENT_CLASS ] ),
               visibilitySensor: {
                    active  : true,
                    onChange: ( visible: boolean ): void => { this.registerOnChange( visible ); },
               },
          };

          this.registerProps( props );
          this.registerOnInputChange = this.registerOnInputChange.bind( this );
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/24/20 01:24 pm
      */
     public render(): JSX.Element {
          const props: Record<string, unknown> = {
               id          : this.props?.id ?? null,
               type        : 'text', //'tel',
               className   : '',
               maxLength   : 1,
               tabIndex    : this.props?.tabIndex ?? null,
               placeholder : '',
               'aria-label': `Enter Invite Code Digit ${ this.props?.tabIndex ?? null }`,
               'data-index': this.props?.tabIndex ?? null,
          };

          if( this.props.text ) {
               props.value = this.props.text;
          }
          if( this.props.password ) {
               props.type = 'password';
          }
          if( this.props.disabled ) {
               props.autoCorrect    = 'off';
               props.autoComplete   = 'off';
               props.autoCapitalize = 'off';
               props.readOnly       = true;
               props.disabled       = true;
               props.spellCheck     = false;
          }

          return (
               <ReactVisibilitySensor { ...this.state.visibilitySensor }>
                    <div className={ [ ...this.state.className ].join( ' ' ) }>
                         <input { ...props } onChange={ this.registerOnInputChange } />
                    </div>
               </ReactVisibilitySensor>
          );
     }
}