import fetch, { RequestInit, Response } from 'node-fetch';
import { ActionEnum }                   from '../../enum';
import { ProfileModule, SessionModule } from '../../module';
import { URLManager }                   from '../url.manager';

/**
 * @class OAuthManager
 * @author Isaac Ewing
 * @version 1.0.0 01/25/21 12:28 pm
 * @version 1.1.0 05/07/21 02:11 pm - changed base class to AModule
 * @version 1.2.0 05/07/21 04:53 pm - removed the base class
 */
export class OAuthManager {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/25/21 12:28 pm
      */
     protected static readonly PATH_INVITE: string = 'invite';

     /**
      *
      * @param {SessionModule} session
      * @param {ProfileModule} profile
      * @return {Promise<object>}
      * @async
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/25/21 09:16 pm
      */
     public static async inviteSubmit( session: SessionModule, profile: ProfileModule ): Promise<Record<string, unknown>> {
          session.action                      = ActionEnum.InviteSubmit;
          const path: string                  = process.env.REACT_APP_API_PATH_INVITE ?? this.PATH_INVITE;
          const init: RequestInit             = {
               method : 'post',
               headers: {
                    'user-agent': 'ewing-social-browser',
               },
               body   : new URLSearchParams( profile.toRequestParams() ),
          };
          const url                           = `${ URLManager.OAuth }/${ path }?${ session.toRequestParams() }`;
          const response: Response            = await fetch( `${ url }`, init ?? {} );
          const json: Record<string, unknown> = await response.json();

          console.log( '{"":""} OAUTH MANAGER JSON', json );

          return json;
     }
}