import React           from 'react';
import { FormEvent }   from 'react';
import { InputModule } from '../../../api/com/ewing/social/module';
import { Input }       from '../../molecule';
import { BaseContact } from '../../atom/contact/BaseContact';
import { Button }      from '../../atom/Button';
import { Description } from '../../atom/Description';
import { Image }       from '../../atom/Image';
import { Subtitle }    from '../../atom/Subtitle';
import { Title }       from '../../atom/Title';

/**
 * @class Newsletter1
 * @extends BaseContact
 * @author Isaac Ewing
 * @version 1.0.0 02/05/21 08:47 pm
 * @classdesc This class handles the default newsletter functionality for the site
 */
export class Newsletter1 extends BaseContact<any, any> {
     protected static COMPONENT_CLASS: string = 'newsletter-1';

     /**
      *
      * @param event {FormEvent}
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/05/21 08:23 pm
      */
     protected onSubmit( event: FormEvent<HTMLFormElement> ): void {
          this.onSubmitNewsletter( event );
     }

     /**
      *
      * @param props {any}
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am
      * documented
      */
     public constructor( props: any ) {
          super( props );

          this.state = {
               module   : props.module,
               page     : props.page,
               hash     : this.registerHash(),
               className: new Set<string>( [ 'grid-x', Newsletter1.COMPONENT_CLASS ] ),
               data     : {
                    name   : InputModule.BuildForName(),
                    email  : InputModule.BuildForEmail(),
                    message: InputModule.BuildForMessage(),
               },
          };

          this.state.module?.button?.enableSubmit();
          this.onResponseEmail = this.onResponseEmail.bind( this );
          this.onSubmit        = this.onSubmit.bind( this );
          this.registerProps( this.state.module ?? null );
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am
      * documented
      */
     public render(): JSX.Element {
          return (
               <section { ...this.registerSectionProps() }>
                    <div className="grid-container content">
                         <div className="grid-x align-center">
                              <div className="cell small-12 medium-12">
                                   <Title module={ this.state?.module?.title } />
                              </div>
                              <div className="cell small-12 medium-8">
                                   <Subtitle module={ this.state?.module?.subtitle } />
                                   <Description module={ this.state?.module?.description } />
                              </div>
                         </div>
                         <div className={ `grid-x align-left ${ Newsletter1.CLASS_FORM_SECTION }` }>
                              {
                                   this.state?.module?.image &&
                                   <div className="cell small-12 medium-5">
                                        <Image module={ this.state?.module?.image } rounded greyed shadow />
                                   </div>
                              }
                              <div className={ `cell small-12 medium-${ this.state?.module?.image ? '7' : '12' }` }>
                                   <form id={ Newsletter1.getHashId( this.state.hash, 'form' ) } className={ Newsletter1.CLASS_FORM } onSubmit={ this.onSubmit } data-abide={ true } noValidate>
                                        <div className="grid-x">
                                             <div className="cell small-12 medium-12">
                                                  <Input { ...this.buildInputProps( this.state.data.name ?? null ) } />
                                             </div>
                                             <div className="cell small-12 medium-12">
                                                  <Input { ...this.buildInputProps( this.state.data.email ?? null ) } />
                                             </div>
                                             <div className="cell small-12 medium-12">
                                                  <Input { ...this.buildInputProps( this.state.data.message ?? null ) } />
                                             </div>
                                             <div className="cell small-12 medium-12">
                                                  <Button id={ Newsletter1.getHashId( this.state.hash, `${ Newsletter1.ID_BUTTON }` ) } module={ this.state.module?.button } />
                                             </div>
                                             <div id={ Newsletter1.getHashId( this.state.hash ?? null, `${ Newsletter1.ID_FEEDBACK }` ) } className="cell small-12 medium-12 feedback-container">
                                                  <div id={ Newsletter1.getHashId( this.state.hash ?? null, `${ Newsletter1.ID_FEEDBACK_TEXT }` ) } className="feedback-text">working...</div>
                                             </div>
                                        </div>
                                   </form>
                              </div>
                         </div>
                    </div>
               </section>
          );
     }
}
