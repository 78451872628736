/**
 * @enum TypeEnum
 * @author Isaac Ewing
 * @version 1.0.0 06/01/21 12:19 pm - documented
 */
export enum TypeEnum {
    Ad         = 'ad',
    Contact    = 'contact',
    Donate     = 'donate',
    Event      = 'event',
    Hero       = 'hero',
    Invite     = 'invite',
    Layout     = 'layout',
    Legal      = 'legal',
    Map        = 'map',
    Newsletter = 'newsletter',
    Viewer     = 'viewer',
}

/**
 * @enum StyleEnum
 * @author Isaac Ewing
 * @version 1.0.0 06/01/21 12:19 pm - documented
 */
export enum StyleEnum {
    Light = 'light',
    Grey  = 'grey',
    Dark  = 'dark',
}

/**
 * @enum ColumnsEnum
 * @author Isaac Ewing
 * @version 1.0.0 06/01/21 12:19 pm - documented
 */
export enum ColumnsEnum {
    Auto   = 'auto',
    Single = 'single',
    Double = 'double',
}

/**
 * @enum VAlignEnum
 * @author Isaac Ewing
 * @version 1.0.0 06/01/21 12:19 pm - documented
 */
export enum VAlignEnum {
    Auto   = 'auto',
    Top    = 'top',
    Middle = 'middle',
    Bottom = 'bottom',
}

/**
 * @enum HAlignEnum
 * @author Isaac Ewing
 * @version 1.0.0 06/01/21 12:19 pm - documented
 */
export enum HAlignEnum {
    Auto   = 'auto',
    Left   = 'left',
    Center = 'center',
    Right  = 'right'
}

/**
 * @enum FavorImageEnum
 * @author Isaac Ewing
 * @version 1.1.0 03/31/21 12:39 pm
 */
export enum FavorImageEnum {
    Avif = 'avif',
    Webp = 'webp',
    Png  = 'png',
    Jpg  = 'jpg',
    Jpeg = 'jpeg',
    Gif  = 'gif',
    Svg  = 'svg',
    Jxr  = 'jxr',
    URL  = 'url',
}

/**
 * @enum ComponentEnum
 * @author Isaac Ewing
 * @version 1.0.0 06/01/21 12:19 pm - documented
 */
export enum ComponentEnum {
    Error        = 'error-help...',
    Button       = 'button',
    Description  = 'description',
    Gallery      = 'gallery',
    GalleryImage = 'gallery-image',
    Image        = 'image',
    Link         = 'link',
    Menu         = 'menu',
    Query        = 'query',
    Section      = 'section',
    Subtitle     = 'subtitle',
    Title        = 'title',
}

/**
 * @enum CurrencyEnum
 * @author Isaac Ewing
 * @version 1.0.0 06/10/21 09:43 pm
 */
export enum CurrencyEnum {
    Usd = 'usd',
}

/**
 * @enum PathEnum
 * @author Isaac Ewing
 * @version 1.0.0 06/01/21 12:19 pm - documented
 */
export enum PathEnum {
    About             = 'about',
    AboutWishlist     = 'about/wishlist',
    Download          = 'download',
    PartnerAffiliates = 'partner/affiliates',
    PartnerSponsors   = 'partner/sponsors',
    Store             = 'store',
}

/**
 * @enum PronounEnum
 * @author Isaac Ewing
 * @version 1.0.0 05/25/21 02:32 pm
 */
export enum PronounEnum {
    He   = 'he',
    She  = 'she',
    They = 'they'
}