import { IAbout, IProfile } from '../../interface';
import { TitleModule }      from './title.module';
import { AboutModule }      from './about.module';
import { StorageManager }   from '../../manager';

/**
 * @class ProfileModule
 * @extends TitleModule
 * @implements IProfile
 * @author Isaac Ewing
 * @version 1.0.0 01/17/21 11:19 am
 * @version 1.1.0 05/07/21 02:11 pm - changed base class to AModule
 * @version 1.0.0 05/24/21 11:47 am - added interface IProfile to the class
 * @version 2.0.0 05/27/21 03:50 pm - converted from AComponent to TitleModule
 */
export class ProfileModule extends TitleModule implements IProfile {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 11:47 am
      */
     protected static readonly CONSOLE_PREFIX: string   = `${ process.env.REACT_APP_CONSOLE_PREFIX_MODULE } PROF ${ process.env.REACT_APP_CONSOLE_SUFFIX_MODULE }`;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 11:47 am
      */
     protected static readonly CONSOLE_ENABLED: boolean = false;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 11:47 am
      */
     protected static readonly API_ROOT_NODE: string    = process.env.REACT_APP_GRAPHQL_GET_PROFILE;
     /**
      *
      * @type {ProfileModule}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 11:47 am
      */
     protected static fromStorage: ProfileModule;

     /**
      *
      * @param {ProfileModule} module
      * @return {ProfileModule}
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/17/21 11:19 am
      * @version 1.1.0 05/07/21 02:33 pm - added support for url
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      * @see FromStorage
      */
     public static Build( module: ProfileModule ): ProfileModule;
     public static Build( obj: Partial<IProfile> ): ProfileModule;
     public static Build( json: string ): ProfileModule;
     public static Build( id?: number, identity?: string, username?: string, email?: string, name?: AboutModule, location?: string, image?: string, url?: string, followers?: number,
                          verified?: boolean, started?: number, platform?: string, hash?: string, state?: string, step?: string ): ProfileModule;
     public static Build( dataOrId: unknown, identity?: string, username?: string, email?: string, name?: never, location?: string, image?: string, url?: string, followers?: number,
                          verified?: boolean, started?: number, platform?: string, hash?: string, state?: string, step?: string ): ProfileModule {
          if( dataOrId ) {
               if( dataOrId instanceof ProfileModule ) {
                    return dataOrId;
               }
               if( typeof dataOrId === 'object' ) {
                    const localData: Partial<IProfile> = dataOrId as Partial<IProfile>;

                    return new ProfileModule( localData?.id ?? null, localData?.identity ?? null, localData?.username ?? null, localData?.email ?? null,
                                              AboutModule.Build( localData?.name ) ?? null, localData?.location ?? null, localData?.image ?? null, localData?.url ?? null,
                                              localData?.followers ?? null, localData?.verified ?? null, localData?.started ?? null, localData?.platform ?? null,
                                              localData?.hash ?? null, localData?.state ?? null, localData?.step ?? null );
               }
               if( typeof dataOrId === 'number' ) {
                    return new ProfileModule( dataOrId, identity ?? null, username ?? null, email ?? null, AboutModule.Build( name ) ?? null, location ?? null,
                                              image ?? null, url ?? null, followers ?? null, verified ?? null, started ?? null, platform ?? null,
                                              hash ?? null, state ?? null, step ?? null );
               }
               if( typeof dataOrId === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrId ) );
                    } catch( exception ) {
                         // not a valid json string
                         return new ProfileModule( +dataOrId ?? null, identity ?? null, username ?? null, email ?? null, AboutModule.Build( name ) ?? null,
                                                   location ?? null, image ?? null, url ?? null, followers ?? null, verified ?? null, started ?? null,
                                                   platform ?? null, hash ?? null, state ?? null, step ?? null );
                    }
               }

               return null;
          }

          return null;
     }

     /**
      *
      * @return {ProfileModule | null}
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 11:40 am
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see url
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public static FromStorage(): ProfileModule | null {
          if( this.fromStorage ) {
               return this.fromStorage;
          }
          if( StorageManager.profileId && StorageManager.profileIdentity && StorageManager.profileUsername ) {
               this.fromStorage = new ProfileModule( StorageManager.profileId ?? null, StorageManager.profileIdentity ?? null, StorageManager.profileUsername ?? null,
                                                     StorageManager.profileEmail ?? null, AboutModule.Build( StorageManager.profileName ) ?? null,
                                                     StorageManager.profileLocation ?? null, StorageManager.profileImage ?? null, StorageManager.profileURL ?? null,
                                                     StorageManager.profileFollowers ?? null, StorageManager.profileVerified ?? null, StorageManager.profileStarted ?? null );

               return this.fromStorage;
          }

          return null;
     }

     /**
      *
      * @param {number} id
      * @param {string} identity
      * @param {string} username
      * @param {string} email
      * @param {AboutModule} name
      * @param {string} location
      * @param {string} image
      * @param {string} url
      * @param {number} followers
      * @param {number} verified
      * @param {string} started
      * @param {string} platform
      * @param {string} hash
      * @param {string} state
      * @param {string} step
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/17/21 11:19 am
      * @version 1.1.0 05/07/21 02:33 pm - added support for url
      * @version 1.1.1 05/24/21 1:28 am - changed name from string to AboutModule
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see url
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public constructor( id?: number, identity?: string, username?: string, email?: string, name?: AboutModule, location?: string, image?: string, url?: string,
                         followers?: number, verified?: boolean, started?: number, platform?: string, hash?: string, state?: string, step?: string ) {
          super( id ?? null );

          this.identity  = identity ?? null;
          this.username  = username ?? null;
          this.email     = email ?? null;
          this.name      = name ?? null;
          this.location  = location ?? null;
          this.image     = image ?? null;
          this.url       = url ?? null;
          this.followers = followers ?? 0;
          this.verified  = verified ?? false;
          this.started   = started ?? null;
          this.platform  = platform ?? null;
          this.hash      = hash ?? null;
          this.state     = state ?? null;
          this.step      = step ?? null;
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/17/21 11:10 am
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public get identity(): string {
          return this._data.get( 'identity' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/17/21 11:10 am
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public set identity( value: string ) {
          this._data.set( 'identity', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/17/21 11:10 am
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public get username(): string {
          return this._data.get( 'username' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/17/21 11:10 am
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public set username( value: string ) {
          this._data.set( 'username', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/17/21 11:10 am
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public get email(): string {
          return this._data.get( 'email' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/23/21 10:26 pm
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public set email( value: string ) {
          this._data.set( 'email', value );
     }

     /**
      *
      * @return {IAbout}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/17/21 11:10 am
      * @version 1.0.1 05/23/21 10:40 pm - changed from string to AboutModule
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public get name(): IAbout {
          return this._data.get( 'name' );
     }

     /**
      *
      * @param {IAbout} value
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/23/21 10:26 pm
      * @version 1.0.1 05/23/21 10:40 pm - changed from string to AboutModule
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public set name( value: IAbout ) {
          this._data.set( 'name', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/17/21 11:10 am
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public get location(): string {
          return this._data.get( 'location' );
     }

     /**
      *
      * @param {string} value
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/23/21 10:26 pm
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public set location( value: string ) {
          this._data.set( 'location', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/17/21 11:10 am
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public get image(): string {
          return this._data.get( 'image' );
     }

     /**
      *
      * @param {string} value
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/23/21 10:26 pm
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public set image( value: string ) {
          this._data.set( 'image', value );
     }

     /**
      *
      * @return {number}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/17/21 11:10 am
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public get followers(): number {
          return this._data.get( 'followers' );
     }

     /**
      *
      * @param {number} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/17/21 11:10 am
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public set followers( value: number ) {
          this._data.set( 'followers', value );
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/17/21 11:10 am
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public get verified(): boolean {
          return this._data.get( 'verified' );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 03:11 pm
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public set verified( value: boolean ) {
          this._data.set( 'verified', value );
     }

     /**
      *
      * @return {number}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/17/21 11:10 am
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public get started(): number {
          return this._data.get( 'started' );
     }

     /**
      *
      * @param {number} value
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/23/21 10:26 pm
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public set started( value: number ) {
          this._data.set( 'started', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/17/21 11:10 am
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public get platform(): string {
          return this._data.get( 'platform' );
     }

     /**
      *
      * @param {string} value
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/23/21 10:26 pm
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public set platform( value: string ) {
          this._data.set( 'platform', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/17/21 11:10 am
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public get hash(): string {
          return this._data.get( 'hash' );
     }

     /**
      *
      * @param {string} value
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/23/21 10:26 pm
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public set hash( value: string ) {
          this._data.set( 'hash', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/17/21 11:10 am
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public get state(): string {
          return this._data.get( 'state' );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/17/21 11:10 am
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public set state( value: string ) {
          this._data.set( 'state', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 03:11 pm
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public get step(): string {
          return this._data.get( 'step' );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 03:11 pm
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public set step( value: string ) {
          this._data.set( 'step', value );
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 03:11 pm
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public setIdentity( value: string ): this {
          this.identity = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 03:11 pm
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public setUsername( value: string ): this {
          this.username = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 03:11 pm
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public setEmail( value: string ): this {
          this.email = value;

          return this;
     }

     /**
      *
      * @param {AboutModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 03:11 pm
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public setName( value: AboutModule ): this {
          this.name = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 03:11 pm
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public setLocation( value: string ): this {
          this.location = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 03:11 pm
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public setImage( value: string ): this {
          this.image = value;

          return this;
     }

     /**
      *
      * @param {number} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 03:11 pm
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public setFollowers( value: number ): this {
          this.followers = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 03:11 pm
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public setVerified( value: boolean ): this {
          this.verified = value;

          return this;
     }

     /**
      *
      * @param {number} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 03:11 pm
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public setStarted( value: number ): this {
          this.started = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 03:11 pm
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public setPlatform( value: string ): this {
          this.platform = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 03:11 pm
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public setHash( value: string ): this {
          this.hash = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 03:11 pm
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public setState( value: string ): this {
          this.state = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 03:11 pm
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public setStep( value: string ): this {
          this.step = value;

          return this;
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 03:11 pm
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public store(): this {
          StorageManager.profileModel     = this;
          StorageManager.profileId        = this.id;
          StorageManager.profileIdentity  = this.identity;
          StorageManager.profileUsername  = this.username;
          StorageManager.profileEmail     = this.email;
          StorageManager.profileName      = this.name;
          StorageManager.profileLocation  = this.location;
          StorageManager.profileImage     = this.image;
          StorageManager.profileURL       = this.url;
          StorageManager.profileFollowers = this.followers;
          StorageManager.profileVerified  = this.verified;
          StorageManager.profileStarted   = this.started;

          return this;
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/25/21 02:18 pm
      * @see id
      * @see identity
      * @see username
      * @see email
      * @see name
      * @see location
      * @see image
      * @see followers
      * @see verified
      * @see started
      * @see platform
      * @see hash
      * @see state
      * @see step
      * @see setIdentity
      * @see setUsername
      * @see setEmail
      * @see setName
      * @see setLocation
      * @see setImage
      * @see setFollowers
      * @see setVerified
      * @see setStarted
      * @see setPlatform
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public toObject(): Record<string, unknown> {
          return {
               id       : this.id,
               identity : this.identity,
               username : this.username,
               email    : this.email,
               name     : this.name?.toObject(),
               location : this.location,
               image    : this.image,
               url      : this.url,
               followers: this.followers,
               verified : this.verified,
               started  : this.started,
               platform : this.platform,
               hash     : this.hash,
               state    : this.state,
               step     : this.step,
          };
     }
}