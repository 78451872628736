import { DocumentNode } from 'graphql';
import { QueryModule }  from '../../module/graphql/query.module';

/**
 * @class AGraphql
 * @abstract
 * @author Isaac Ewing
 * @version 1.0.0 02/08/21 02:59 pm
 */
export abstract class AGraphql {
     /**
      *
      * @return {string}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/19/20 09:27 pm
      * @see buildGetSitePage
      * @see buildDomain
      * @see buildPage
      * @see buildSetup
      * @see buildInvite
      * @see buildLayout
      * @see buildData
      * @see buildTitle
      * @see buildSubtitle
      * @see buildDescription
      * @see buildImage
      * @see buildImages
      * @see buildLink
      * @see buildGallery
      * @see buildMenu
      * @see buildLinks
      * @see buildButton
      * @see consoleQuery
      * @see Page
      */
     protected static buildGetSitePage( data: QueryModule ): string {
          return `
          ${ process.env.REACT_APP_GRAPHQL_GET_SITE_PAGE }(
               where: { 
                    domain: ${ data.where.page.domain }, 
                    domainName: "${ data.where.page.domainName }", 
                    page: ${ data.where.page.page }, 
                    pageName: "${ data.where.page.pageName }" 
               }
               options: { 
                    includes: { 
                         contact: ${ data.options.includes.contact },
                         invite: ${ data.options.includes.invite },
                    }
               }
          ) {`;
     }

     /**
      *
      * @return {string}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/19/20 09:27 pm
      * @see buildGetSitePage
      * @see buildDomain
      * @see buildPage
      * @see buildSetup
      * @see buildInvite
      * @see buildLayout
      * @see buildData
      * @see buildTitle
      * @see buildSubtitle
      * @see buildDescription
      * @see buildImage
      * @see buildImages
      * @see buildLink
      * @see buildGallery
      * @see buildMenu
      * @see buildLinks
      * @see buildButton
      * @see consoleQuery
      * @see Page
      */
     protected static buildDomain(): string {
          return `
          domain {
               id
               path
               text
          }`;
     }

     /**
      *
      * @return {string}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/19/20 09:27 pm
      * @see buildGetSitePage
      * @see buildDomain
      * @see buildPage
      * @see buildSetup
      * @see buildInvite
      * @see buildLayout
      * @see buildData
      * @see buildTitle
      * @see buildSubtitle
      * @see buildDescription
      * @see buildImage
      * @see buildImages
      * @see buildLink
      * @see buildGallery
      * @see buildMenu
      * @see buildLinks
      * @see buildButton
      * @see consoleQuery
      * @see Page
      */
     protected static buildPage(): string {
          return `
          page {
               id
               path
               text
          }`;
     }

     /**
      *
      * @return {string}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/19/20 09:27 pm
      * @see buildGetSitePage
      * @see buildDomain
      * @see buildPage
      * @see buildSetup
      * @see buildInvite
      * @see buildLayout
      * @see buildData
      * @see buildTitle
      * @see buildSubtitle
      * @see buildDescription
      * @see buildImage
      * @see buildImages
      * @see buildLink
      * @see buildGallery
      * @see buildMenu
      * @see buildLinks
      * @see buildButton
      * @see consoleQuery
      * @see Page
      */
     protected static buildSetup(): string {
          return `
          setup {
               id
               social
               claim
               login
               intro
          }`;
     }

     /**
      *
      * @return {string}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/19/20 09:27 pm
      * @see buildGetSitePage
      * @see buildDomain
      * @see buildPage
      * @see buildSetup
      * @see buildInvite
      * @see buildLayout
      * @see buildData
      * @see buildTitle
      * @see buildSubtitle
      * @see buildDescription
      * @see buildImage
      * @see buildImages
      * @see buildLink
      * @see buildGallery
      * @see buildMenu
      * @see buildLinks
      * @see buildButton
      * @see consoleQuery
      * @see Page
      */
     protected static buildInvite(): string {
          return `
          invite {
               id
               active
               nonce
               used
               code
          }`;
     }

     /**
      *
      * @return {string}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/19/20 09:27 pm
      * @see buildGetSitePage
      * @see buildDomain
      * @see buildPage
      * @see buildSetup
      * @see buildInvite
      * @see buildLayout
      * @see buildData
      * @see buildTitle
      * @see buildSubtitle
      * @see buildDescription
      * @see buildImage
      * @see buildImages
      * @see buildLink
      * @see buildGallery
      * @see buildMenu
      * @see buildLinks
      * @see buildButton
      * @see consoleQuery
      * @see Page
      */
     protected static buildLayout(): string {
          return `
          layout {
               id
               ${ this.buildTitle() }
               ${ this.buildDescription() }
          }`;
     }

     /**
      *
      * @return {string}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/16/20 06:27 pm
      * @see buildGetSitePage
      * @see buildDomain
      * @see buildPage
      * @see buildSetup
      * @see buildInvite
      * @see buildLayout
      * @see buildData
      * @see buildTitle
      * @see buildSubtitle
      * @see buildDescription
      * @see buildImage
      * @see buildImages
      * @see buildLink
      * @see buildGallery
      * @see buildMenu
      * @see buildLinks
      * @see buildButton
      * @see consoleQuery
      * @see Page
      */
     protected static buildData(): string {
          return `
          data {
               id
               text
          }`;
     }

     /**
      *
      * @return {string}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/19/20 09:27 pm
      * @see buildGetSitePage
      * @see buildDomain
      * @see buildPage
      * @see buildSetup
      * @see buildInvite
      * @see buildLayout
      * @see buildData
      * @see buildTitle
      * @see buildSubtitle
      * @see buildDescription
      * @see buildImage
      * @see buildImages
      * @see buildLink
      * @see buildGallery
      * @see buildMenu
      * @see buildLinks
      * @see buildButton
      * @see consoleQuery
      * @see Page
      */
     protected static buildTitle(): string {
          return `
          title {
               id
               text
          }`;
     }

     /**
      *
      * @return {string}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/19/20 09:27 pm
      * @see buildGetSitePage
      * @see buildDomain
      * @see buildPage
      * @see buildSetup
      * @see buildInvite
      * @see buildLayout
      * @see buildData
      * @see buildTitle
      * @see buildSubtitle
      * @see buildDescription
      * @see buildImage
      * @see buildImages
      * @see buildLink
      * @see buildGallery
      * @see buildMenu
      * @see buildLinks
      * @see buildButton
      * @see consoleQuery
      * @see Page
      */
     protected static buildSubtitle(): string {
          return `
          subtitle {
               id
               text
          }`;
     }

     /**
      *
      * @return {string}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/19/20 09:27 pm
      * @see buildGetSitePage
      * @see buildDomain
      * @see buildPage
      * @see buildSetup
      * @see buildInvite
      * @see buildLayout
      * @see buildData
      * @see buildTitle
      * @see buildSubtitle
      * @see buildDescription
      * @see buildImage
      * @see buildImages
      * @see buildLink
      * @see buildGallery
      * @see buildMenu
      * @see buildLinks
      * @see buildButton
      * @see consoleQuery
      * @see Page
      */
     protected static buildDescription(): string {
          return `
          description {
               id
               text
          }`;
     }

     /**
      *
      * @return {string}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/19/20 09:27 pm
      * @see buildGetSitePage
      * @see buildDomain
      * @see buildPage
      * @see buildSetup
      * @see buildInvite
      * @see buildLayout
      * @see buildData
      * @see buildTitle
      * @see buildSubtitle
      * @see buildDescription
      * @see buildImage
      * @see buildImages
      * @see buildLink
      * @see buildGallery
      * @see buildMenu
      * @see buildLinks
      * @see buildButton
      * @see consoleQuery
      * @see Page
      */
     protected static buildImage(): string {
          return `
          image {
               id
               domain {
                    id
               }
               source
               path
               alt
               favor
               valign
               halign
               logo
               rounded
               greyed
               shadow
               webp
               png
               jpg
               jpeg
               gif
               svg
               jxr
          }`;
     }

     /**
      *
      * @return {string}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/19/20 09:27 pm
      * @see buildGetSitePage
      * @see buildDomain
      * @see buildPage
      * @see buildSetup
      * @see buildInvite
      * @see buildLayout
      * @see buildData
      * @see buildTitle
      * @see buildSubtitle
      * @see buildDescription
      * @see buildImage
      * @see buildImages
      * @see buildLink
      * @see buildGallery
      * @see buildMenu
      * @see buildLinks
      * @see buildButton
      * @see consoleQuery
      * @see Page
      */
     protected static buildImages(): string {
          return `
          images {
               id
               ${ this.buildImage() }
               ${ this.buildTitle() }
               ${ this.buildLink() }
          }`;
     }

     /**
      *
      * @return {string}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/19/20 09:27 pm
      * @see buildGetSitePage
      * @see buildDomain
      * @see buildPage
      * @see buildSetup
      * @see buildInvite
      * @see buildLayout
      * @see buildData
      * @see buildTitle
      * @see buildSubtitle
      * @see buildDescription
      * @see buildImage
      * @see buildImages
      * @see buildLink
      * @see buildGallery
      * @see buildMenu
      * @see buildLinks
      * @see buildButton
      * @see consoleQuery
      * @see Page
      */
     protected static buildLink(): string {
          return `
          link {
               id
               path
               text
               icon
          }`;
     }

     /**
      *
      * @return {string}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/19/20 09:27 pm
      * @see buildGetSitePage
      * @see buildDomain
      * @see buildPage
      * @see buildSetup
      * @see buildInvite
      * @see buildLayout
      * @see buildData
      * @see buildTitle
      * @see buildSubtitle
      * @see buildDescription
      * @see buildImage
      * @see buildImages
      * @see buildLink
      * @see buildGallery
      * @see buildMenu
      * @see buildLinks
      * @see buildButton
      * @see consoleQuery
      * @see Page
      */
     protected static buildGallery(): string {
          return `
          gallery {
               id
               isGallery
               valign
               halign
               ${ this.buildImages() }
          }`;
     }

     /**
      *
      * @return {string}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/19/20 09:27 pm
      * @see buildGetSitePage
      * @see buildDomain
      * @see buildPage
      * @see buildSetup
      * @see buildInvite
      * @see buildLayout
      * @see buildData
      * @see buildTitle
      * @see buildSubtitle
      * @see buildDescription
      * @see buildImage
      * @see buildImages
      * @see buildLink
      * @see buildGallery
      * @see buildMenu
      * @see buildLinks
      * @see buildButton
      * @see consoleQuery
      * @see Page
      */
     protected static buildMenu(): string {
          return `
          menu {
               id
               submenu
               discord
               facebook
               instagram
               onlyfans
               patreon
               tiktok
               twitch
               twitter
               youtube
               columns
               ${ this.buildLinks() }
          }`;
     }

     /**
      *
      * @return {string}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/19/20 09:27 pm
      * @see buildGetSitePage
      * @see buildDomain
      * @see buildPage
      * @see buildSetup
      * @see buildInvite
      * @see buildLayout
      * @see buildData
      * @see buildTitle
      * @see buildSubtitle
      * @see buildDescription
      * @see buildImage
      * @see buildImages
      * @see buildLink
      * @see buildGallery
      * @see buildMenu
      * @see buildLinks
      * @see buildButton
      * @see consoleQuery
      * @see Page
      */
     protected static buildLinks(): string {
          return `
          links {
               id
               path
               text
               icon
          }`;
     }

     /**
      *
      * @return {string}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/19/20 09:27 pm
      * @see buildGetSitePage
      * @see buildDomain
      * @see buildPage
      * @see buildSetup
      * @see buildInvite
      * @see buildLayout
      * @see buildData
      * @see buildTitle
      * @see buildSubtitle
      * @see buildDescription
      * @see buildImage
      * @see buildImages
      * @see buildLink
      * @see buildGallery
      * @see buildMenu
      * @see buildLinks
      * @see buildButton
      * @see consoleQuery
      * @see Page
      */
     protected static buildButton(): string {
          return `
          button {
               id
               path
               text
               icon
          }`;
     }

     /**
      *
      * @return {string}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/09/21 01:21 pm
      * @see buildAbout
      * @see buildProfile
      * @see buildSocial
      * @see buildDomain
      * @see buildSetup
      * @see buildSetting
      * @see buildCompany
      * @see consoleQuery
      * @see Query
      */
     protected static buildAbout(): string {
          return `
          about {
               id
               first
               last
          }`;
     }

     /**
      *
      * @return {string}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/09/21 01:21 pm
      * @see buildAbout
      * @see buildProfile
      * @see buildSocial
      * @see buildDomain
      * @see buildSetup
      * @see buildSetting
      * @see buildCompany
      * @see consoleQuery
      * @see Query
      */
     protected static buildProfile( profile: string ): string {
          return `
          ${ profile } {
               id
               identity
               username
               email
               name
               location
               image
               url
               followers
               verified
               started
          }`;
     }

     /**
      *
      * @return {string}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/09/21 01:21 pm
      * @see buildAbout
      * @see buildProfile
      * @see buildSocial
      * @see buildDomain
      * @see buildSetup
      * @see buildSetting
      * @see buildCompany
      * @see consoleQuery
      * @see Query
      */
     protected static buildSocial(): string {
          return `
          social {
               id
               ${ this.buildProfile( 'discord' ) }
               ${ this.buildProfile( 'facebook' ) }
               ${ this.buildProfile( 'github' ) }
               ${ this.buildProfile( 'google' ) }
               ${ this.buildProfile( 'instagram' ) }
               ${ this.buildProfile( 'twitch' ) }
               ${ this.buildProfile( 'twitter' ) }
               ${ this.buildProfile( 'youtube' ) }
          }`;
     }

     /**
      *
      * @return {string}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/09/21 01:21 pm
      * @see buildAbout
      * @see buildProfile
      * @see buildSocial
      * @see buildDomain
      * @see buildSetup
      * @see buildSetting
      * @see buildCompany
      * @see consoleQuery
      * @see Query
      */
     protected static buildSetting(): string {
          return `
          setting {
               id
               pronouns
          }`;
     }

     /**
      *
      * @return {string}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/09/21 01:21 pm
      * @see buildAbout
      * @see buildProfile
      * @see buildSocial
      * @see buildDomain
      * @see buildSetup
      * @see buildSetting
      * @see buildCompany
      * @see consoleQuery
      * @see Query
      */
     protected static buildCompany(): string {
          return `
          company {
               id
               short
               name
          }`;
     }

     /**
      *
      * @param {string} page
      * @param {QueryModule} data
      * @return {void}
      * @protected
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/19/20 09:27 pm
      * @see buildGetSitePage
      * @see buildDomain
      * @see buildPage
      * @see buildSetup
      * @see buildInvite
      * @see buildLayout
      * @see buildData
      * @see buildTitle
      * @see buildSubtitle
      * @see buildDescription
      * @see buildImage
      * @see buildImages
      * @see buildLink
      * @see buildGallery
      * @see buildMenu
      * @see buildLinks
      * @see buildButton
      * @see consoleQuery
      * @see Page
      */
     protected static consoleQuery( page: string, data: QueryModule ): void {
          const temp: any = {
               domain    : data.where.page.domain,
               domainName: data.where.page.domainName,
               page      : data.where.page.page,
               pageName  : data.where.page.pageName,
          };
          console.log( `---> ${ page } page, graphql page module`, temp );
     }

     /**
      *
      * @param data {QueryModule}
      * @return {DocumentNode}
      * @constructor
      * @author Isaac Ewing
      * @version 1.0.0 10/26/20 08:37 pm
      * @see buildGetSitePage
      * @see buildDomain
      * @see buildPage
      * @see buildSetup
      * @see buildInvite
      * @see buildLayout
      * @see buildData
      * @see buildTitle
      * @see buildSubtitle
      * @see buildDescription
      * @see buildImage
      * @see buildImages
      * @see buildLink
      * @see buildGallery
      * @see buildMenu
      * @see buildLinks
      * @see buildButton
      * @see consoleQuery
      * @see Page
      */
     public static Page( data: QueryModule ): DocumentNode | null {
          return null;
     }

     /**
      *
      * @param data {QueryModule}
      * @return {DocumentNode}
      * @constructor
      * @author Isaac Ewing
      * @version 1.0.0 10/26/20 08:37 pm
      * @see buildGetSitePage
      * @see buildDomain
      * @see buildPage
      * @see buildSetup
      * @see buildInvite
      * @see buildLayout
      * @see buildData
      * @see buildTitle
      * @see buildSubtitle
      * @see buildDescription
      * @see buildImage
      * @see buildImages
      * @see buildLink
      * @see buildGallery
      * @see buildMenu
      * @see buildLinks
      * @see buildButton
      * @see consoleQuery
      * @see Page
      */
     public Page( data: QueryModule ): DocumentNode {
          return AGraphql.Page( data ) as DocumentNode;
     }
}