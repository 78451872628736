import React, { useEffect, useState } from 'react';
import PropTypes                      from 'prop-types';
import JsxParser                      from 'react-jsx-parser';
import { IBaseState, ISubtitleProps } from '../../api/com/ewing/social/interface';
import { ReportModule, TitleModule }  from '../../api/com/ewing/social/module';
import {
    ConsoleManager, EmailManager,
    ShortcodeManager,
}                                     from '../../api/com/ewing/social/manager';
import { RegisterTool, Util }         from '../../api/com/ewing/social/tool';
import { Shortcode }                  from './Shortcode';

/**
 *
 * @return {JSX.Element}
 * @constructor
 * @author Isaac Ewing
 * @version 1.0.0 06/06/21 04:22 pm
 */
export const Subtitle2 = ( props: Partial<ISubtitleProps> ): JSX.Element => {
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 06/06/21 04:22 pm
     */
    //const CONSOLE_PREFIX: string      = `${ process.env.REACT_APP_CONSOLE_PREFIX_COMPONENT } SUBT ${ process.env.REACT_APP_CONSOLE_SUFFIX_COMPONENT }`;
    /**
     *
     * @type {boolean}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 06/06/21 04:22 pm
     */
    //const CONSOLE_ENABLED: boolean    = true;
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 06/06/21 04:22 pm
     */
    const COMPONENT_CLASS: string     = 'subtitle';
    const components                  = {
        Shortcode,
    };
    const onError           = ( exception: Error ): void => {
        console.trace( '==X== SUBTITLE EXCEPTION', exception );
    };
    const [ hash ]          = useState( props[ 'data-hash' ] ?? Util.registerHash() );
    const [ text ]                  = useState( ShortcodeManager.parse( props.module.text ) );
    const [ className ]             = useState( new Set<string>( [ COMPONENT_CLASS ] ) );
    const [ children, setChildren ] = useState( new Set<JSX.Element>( props.children ?? [] ) );
    const registerRender              = () => {
        return (
            <h5 key={ Util.registerHash() } className={ [ ...className ].join( ' ' ) } data-hash={ hash }>
                <JsxParser renderInWrapper={ false } components={ components } jsx={ text } onError={ onError } autoCloseVoidElements />
            </h5>
        );
    };

    useEffect( (): void => {
        setChildren( new Set<JSX.Element>( [ registerRender() ] ) );
    } );

    if( props?.className ) {
        className.add( props.className as string );
    }

    return (
        <>
            { children }
        </>
    );
};

Subtitle2.propTypes = {
    module     : PropTypes.instanceOf( TitleModule ).isRequired,
    className  : PropTypes.string,
    children   : PropTypes.node,
    'data-hash': PropTypes.string,
};

/**
 * @class Subtitle
 * @extends Base
 * @author Isaac Ewing
 * @version 1.0.0 01/18/21 06:37 pm - documented
 */
export class Subtitle extends React.Component<Partial<ISubtitleProps>, Partial<IBaseState>> {
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 01/18/21 06:37 pm
     */
    protected static readonly COMPONENT_CLASS: string  = 'subtitle';
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 06/05/21 08:35 pm
     */
    protected static readonly CONSOLE_PREFIX: string   = `${ process.env.REACT_APP_CONSOLE_PREFIX_COMPONENT } SUBT ${ process.env.REACT_APP_CONSOLE_SUFFIX_COMPONENT }`;
    /**
     *
     * @type {boolean}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 06/05/21 08:35 pm
     */
    protected static readonly CONSOLE_ENABLED: boolean = true;
    /**
     *
     * @type {any}
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 06/06/21 11:26 am
     */
    public static propTypes: unknown;
    /**
     *
     * @type {Partial<ISubtitleProps>}
     * @readonly
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 06/06/21 11:26 am
     */
    public readonly props: Partial<ISubtitleProps>;
    /**
     *
     * @type {Partial<IPageState>}
     * @readonly
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 06/06/21 11:26 am
     */
    public readonly state: Partial<IBaseState>;

    /**
     *
     * @param exception
     * @return {void}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/22/20 06:25 pm
     */
    protected onError( exception: Error ): void {
        console.trace( '==X== SUBTITLE EXCEPTION', exception );
    }

    /**
     *
     * @return {JSX.Element}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 01/18/21 05:47 pm
     */
    protected registerRender(): JSX.Element {
        return (
            <h5 key={ Util.registerHash() } className={ [ ...this.state.className ].join( ' ' ) } data-hash={ this.state.hash }>
                <JsxParser renderInWrapper={ false } components={ this.state.components } jsx={ this.state.text } onError={ this.onError } autoCloseVoidElements />
            </h5>
        );
    }

    /**
     *
     * @param props {ISubtitleProps}
     * @constructor
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 01/18/21 06:37 pm - documented
     */
    public constructor( props: ISubtitleProps ) {
        super( props );

        try {
            this.state = {
                hash      : props[ 'data-hash' ] ?? Util.registerHash(),
                text      : ShortcodeManager.parse( props.module.text ),
                className : new Set<string>( [ Subtitle.COMPONENT_CLASS ] ),
                children  : new Set<JSX.Element>( props.children ?? [] ),
                components: {
                    Shortcode,
                },
            };
        } catch( exception: unknown ) {
            ConsoleManager.Warn( Subtitle.CONSOLE_ENABLED ?? null, Subtitle.CONSOLE_PREFIX ?? null, 'SUBTITLE Error', 'see all data', { props } );
        }

        RegisterTool.classes( props ?? null, this.state ?? null );
    }

    /**
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 - 10/04/20 11:59 am
     * @version 1.1.0 - 12/22/20 06:05 pm simplified and added static shortcode manager functionality
     * // TODO 05/29/21 05:38 pm
     * //  can this be simplified to use .clear() then call .add() while still knowing to make a component update
     */
    public componentDidMount(): void {
        this.setState( { children: new Set<JSX.Element>( [ this.registerRender() ] ) } );
    }

    /**
     *
     * @param {Readonly<any>} prevProps
     * @param {Readonly<any>} prevState
     * @param snapshot
     * @return {void}
     * @author Isaac Ewing
     * @version 1.0.0 01/17/21 04:26 pm
     */
    public componentDidUpdate( prevProps: Readonly<Record<string, unknown>>, prevState: Readonly<Record<string, unknown>>, snapshot?: unknown ): void {
        const data: unknown = { prevProps: prevProps.module, module: this.props.module, prevState, snapshot };

        if( prevProps.module !== this.props.module ) {
            if( prevProps.module instanceof TitleModule ) {
                if( prevProps.module?.text !== this.props.module.text ) {
                    if( Subtitle.CONSOLE_ENABLED ) {
                        ConsoleManager.Info( Subtitle.CONSOLE_PREFIX, 'SUBTITLE', 'UPDATING THE STATE FROM PROPS IN SUBTITLE', data ?? null );
                    }

                    this.setState( { children: new Set<JSX.Element>( [ this.registerRender() ] ) } );
                } else {
                    if( Subtitle.CONSOLE_ENABLED ) {
                        ConsoleManager.Log( Subtitle.CONSOLE_PREFIX, 'SUBTITLE', 'SKIPPING subtitle update, text is the same', data ?? null );
                    }
                }
            } else {
                if( Subtitle.CONSOLE_ENABLED ) {
                    ConsoleManager.Log( Subtitle.CONSOLE_PREFIX, 'SUBTITLE', 'SKIPPING subtitle update, text is not a module', data ?? null );
                }
            }
        } else {
            if( Subtitle.CONSOLE_ENABLED ) {
                ConsoleManager.Log( Subtitle.CONSOLE_PREFIX, 'SUBTITLE', 'SKIPPING subtitle update, module is the same', data ?? null );
            }
        }
    }

    /**
     *
     * @param {Error} error
     * @param {React.ErrorInfo} info
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 06/01/21 07:28 pm
     */
    public componentDidCatch( error: Error, info: React.ErrorInfo ): void {
        console.error( 'SUBTITLE JSX', { error, info } );

        EmailManager.Report( ReportModule.BuildComponent( error, info ) ).then();
    }

    /**
     *
     * @return {JSX.Element}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/20/20 11:36 am documented
     * @version 1.1.0 12/20/20 11:37 am added support for jsx parser
     */
    public render(): JSX.Element {
        return (
            <>
                { this.state.children }
            </>
        );
    }
}

Subtitle.propTypes = {
    module     : PropTypes.instanceOf( TitleModule ).isRequired,
    className  : PropTypes.string,
    children   : PropTypes.node,
    'data-hash': PropTypes.string,
};