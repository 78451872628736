import PropTypes                                   from 'prop-types';
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import { Link }                                    from 'gatsby';
import { IBaseState, IButtonProps, ILink }         from '../../api/com/ewing/social/interface';
import { LinkModule }                              from '../../api/com/ewing/social/module';
import { RegisterTool, Util }                      from '../../api/com/ewing/social/tool';
import { Base }                                    from './Base';

/**
 * @class Button
 * @extends Base
 * @author Isaac Ewing
 * @version 1.0.0 - 10/04/20 11:59 am
 */
export class Button extends Base<Partial<IButtonProps>, Partial<IBaseState>> {
    protected static readonly COMPONENT_CLASS: string  = 'button-link';
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 08/01/21 09:32 am
     */
    protected static readonly CONSOLE_PREFIX: string   = `${ process.env.REACT_APP_CONSOLE_PREFIX_COMPONENT } BUTT ${ process.env.REACT_APP_CONSOLE_SUFFIX_COMPONENT }`;
    /**
     *
     * @type {boolean}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 08/01/21 09:32 am
     */
    protected static readonly CONSOLE_ENABLED: boolean = true;
    protected static readonly DEFAULT_ID: string       = 'submit-button';
    public static propTypes                            = {
        ...Base.propTypes,
        module: PropTypes.instanceOf( LinkModule ).isRequired,
        to    : PropTypes.string,
        style : PropTypes.string,
    };
    /**
     *
     * @type {Partial<IButtonProps>}
     * @readonly
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 08/01/21 09:48 am
     */
    public readonly props: Partial<IButtonProps>;
    /**
     *
     * @type {Partial<IBaseState>}
     * @readonly
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 08/01/21 09:48 am
     */
    public readonly state: Partial<IBaseState>;

    /**
     *
     * @param props {LinkModule|null} The module with all the data
     * @author Isaac Ewing
     * @version 1.0.0 - 10/04/20 11:59 am
     */
    protected registerLink( props?: ILink ): JSX.Element {
        if( props ) {
            if( props.submit ) {
                const id: string                                                                            = `${ this.props.id ?? props?.id ?? Button.DEFAULT_ID }`;
                const params: DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> = {
                    key      : Util.registerHash(),
                    id       : id,
                    name     : id,
                    type     : 'submit',
                    className: [ ...this.state.className ].join( ' ' ),
                    ...this.state.other,
                };

                return <button { ...params }>{ props.text }{ this.state.children }</button>;
            }

            try {
                new URL( props.path );

                const params = {
                    key      : Util.registerHash(),
                    className: [ ...this.state.className ].join( ' ' ),
                    href     : props.path,
                    ...this.state.other,
                };

                return <a { ...params }>{ props.text }{ this.state.children }</a>;
            } catch( exception: unknown ) {
                const params = {
                    key      : Util.registerHash(),
                    className: [ ...this.state.className ].join( ' ' ),
                    to       : `/${ props.path }`,
                    ...this.state.other,
                };

                console.log( '[[ B ]] BUTTON', { module: props, to: `/${ props.path }` } );

                return <Link { ...params }>{ props.text }{ this.state.children }</Link>;
            }
        } else {
            console.log( 'skipping link, no props', props, this.state );
        }
    }

    /**
     *
     * @param {IButtonProps} props
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 - 10/04/20 11:59 am
     */
    public constructor( props: IButtonProps ) {
        super( props );

        const { module, to, ...other }: { module: ILink, to: string } = props;
        this.state                                                    = {
            module,
            to,
            other,
            className: new Set<string>( [ Button.COMPONENT_CLASS ] ),
            children : props?.children ?? new Set<JSX.Element>(),
        };

        //this.registerStyles(props);
        RegisterTool.classes( props ?? null, this.state ?? null );
    }

    /**
     *
     * @return {JSX.Element}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 - 10/04/20 11:59 am
     */
    public render(): JSX.Element {
        return (
            <>{ this.registerLink( this.props?.module ) }</>
        );
    }
}