import React                               from 'react';
import jQuery                              from 'jquery';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { IProfileState }                   from '../../../api/com/ewing/social/interface';
import { ActionEnum, RegisterStepEnum }    from '../../../api/com/ewing/social/enum';
import { ImageModule }                     from '../../../api/com/ewing/social/component';
import { OAuthManager }                    from '../../../api/com/ewing/social/manager';
import { SessionModule }                   from '../../../api/com/ewing/social/module/api/oauth/session.module';
import { ProfileModule }                   from '../../../api/com/ewing/social/module/api/profile.module';
import { OAuthObserver }                   from '../../../api/com/ewing/social/observer/oauth.observer';
import { Image }                           from '../../atom/Image';
import { InviteBase }                      from '../../atom/invite/InviteBase';

/**
 * @class Profile
 * @extends InviteBase
 * @author Isaac Ewing
 * @version 1.0.0 01/17/21 12:49 pm
 */
export class Profile extends InviteBase<unknown, unknown> {
     /**
      *
      * @type {string}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/17/21 12:49 pm
      */
     protected static readonly COMPONENT_CLASS: string = 'profile';
     /**
      *
      * @type {boolean}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/23/21 01:42 pm
      */
     protected isDefaultImage: boolean                 = true;
     /**
      *
      * @type {ProfileModule}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/23/21 10:14 pm
      */
     protected localProfile: ProfileModule;
     /**
      *
      * @type {IProfileState}
      * @readonly
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/17/21 12:49 pm
      */
     public readonly state: Partial<IProfileState>;

     /**
      *
      * @param value {number}
      * @return {string}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/31/21 04:42 pm
      */
     protected onStartedChange( value: number ): string {
          const options: Intl.DateTimeFormatOptions = {
               month: '2-digit',
               day  : '2-digit',
               year : 'numeric',
          };
          const result: string                      = new Intl.DateTimeFormat( 'en-US', options ?? null ).format( value * 1000 );

          console.log( '&&& profile started formatted', result );

          return result;
     }

     /**
      *
      * @param {ProfileModule} module
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/17/21 12:31 pm
      */
     protected onOAuthObserver( module: ProfileModule ): void {
          const update: Record<string, unknown> = {
               followers: 0,
          };

          if( module.image && this.isDefaultImage ) {
               const image: ImageModule = ImageModule.Build( { source: module.image, alt: 'profile image' } );

               this.isDefaultImage = false;
               this.localProfile   = module;
               update.image        = image;
          }
          if( module.name && !this.state.name ) {
               update.name = module.name;
          }
          if( module.platform && !this.state.platform ) {
               update.platform = module.platform;
          }
          if( this.state.followers ) {
               update.followers = +this.state.followers;
          }
          if( module.step ) {
               this.localProfile.step = module.step;
          }
          if( module.started ) {
               update.started            = this.onStartedChange( +module.started );
               this.localProfile.started = module.started;
          }

          this.localProfile.username ??= module.username ?? null;
          this.localProfile.email ??= module.email ?? null;
          this.localProfile.name ??= module.name ?? null;
          this.localProfile.location ??= module.location ?? null;
          console.log( '### PROFILE', 'STATE NAME', this.state.name, '!NAME', !this.state.name );
          console.log( '### PROFILE', 'STATE PLATFORM', this.state.platform, '!PLATFORM', !this.state.platform );
          console.log( '### PROFILE', 'STATE FOLLOWERS', this.state.followers, '!FOLLOWERS', !this.state.followers, 'UPDATE FOLLOWERS',
                       update.followers );
          console.log( '### PROFILE', 'STATE STARTED', this.state.started, '!STARTED', !this.state.started );

          update.followers = +update.followers + module.followers;
          console.log( '### PROFILE', 'MODULE', module, 'UPDATE', update );
          this.setState( update );

          if( module.step === RegisterStepEnum.Finish ) {
               const sessionData                               = {
                    action  : ActionEnum.InviteSubmit,
                    code    : this.state.page.invite.code,
                    provider: this.localProfile.platform,
                    domain  : window.location.hostname,
                    socket  : Profile.SOCKET.id,
                    hash    : this.localProfile.hash,
                    state   : this.localProfile.state,
               };
               const session: SessionModule                    = SessionModule.Build( sessionData );
               const promise: Promise<Record<string, unknown>> = OAuthManager.inviteSubmit( session, this.localProfile );

               promise.then( ( data: Record<string, unknown> ): void => {
                    console.log( '<<=>> COOL, PROFILE CALLED FINISH INVITE REGISTRATION', 'SESSION', sessionData, 'PROFILE', this.localProfile );
                    console.log( '<<=>> COOL, PROFILE CALLED FINISH INVITE REGISTRATION', 'RESULT', data );
               } );
          }
     }

     /**
      *
      * @param props {any}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/13/21 01:06 pm
      */
     public constructor( props: Record<string, never> ) {
          super( props );

          this.state = {
               page       : props.page,
               id         : props.id ?? '',
               hash       : this.registerHash(),
               initialized: false,
               className  : new Set<string>( [ Profile.COMPONENT_CLASS ] ),
               component  : null,
               children   : new Set<JSX.Element>(),
               image      : ImageModule.BuildDefaultGrey(),
               name       : null,
               platform   : null,
               followers  : null,
               started    : null,
          };

          //this.registerProps( props );
          this.onOAuthObserver = this.onOAuthObserver.bind( this );
          OAuthObserver.subscribe( Profile.COMPONENT_CLASS, this.onOAuthObserver );
     }

     /**
      *
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/13/21 01:06 pm
      */
     public componentDidMount(): void {
          const component: JQuery = jQuery( `[data-hash="${ this.state.hash }"]` );

          this.setState( { component } );
          Profile.REGISTER_MODULE.componentProfile = component;
     }

     /**
      *
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 03/26/21 05:23 pm
      */
     public componentWillUnmount(): void {
          OAuthObserver.unsubscribe( Profile.COMPONENT_CLASS );
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/13/21 01:06 pm
      */
     public render(): JSX.Element {
          const options: NumberFormatProps = {
               value      : this.state.started,
               format     : '##/##/####',
               placeholder: 'MM/DD/YYYY',
               displayType: 'text',
          };

          return (
               <div className={ [ ...this.state.className ].join( ' ' ) } data-hash={ this.state.hash }>
                    <div className="profile-image">
                         <Image module={ this.state.image } rounded greyed shadow />
                    </div>
                    <div className="grid-x">
                         <div className="cell small-12 medium-8 medium-offset-2 profile-text">
                              <p className="profile-label">name</p>
                              <h5 className={ 'subtitle profile-value profile-name' }>{ this.state.name }</h5>
                         </div>
                         <div className="cell small-12 medium-8 medium-offset-2 profile-text">
                              <p className="profile-label">platform</p>
                              <h5 className={ 'subtitle profile-value' }>{ this.state.platform }</h5>
                         </div>
                         <div className="cell small-12 medium-8 medium-offset-2 profile-text">
                              <p className="profile-label">followers</p>
                              <h5 className={ 'subtitle profile-value' }>
                                   <NumberFormat value={ this.state.followers } displayType={ 'text' } thousandSeparator={ true } />
                              </h5>
                         </div>
                         <div className="cell small-12 medium-8 medium-offset-2 profile-text">
                              <p className="profile-label">started</p>
                              <h5 className={ 'subtitle profile-value' }>
                                   <NumberFormat { ...options } />
                              </h5>
                         </div>
                    </div>
                    { this.state.children }
               </div>
          );
     }
}

export default Profile;