import { AComponent }        from '../../abstract';
import { IPartnerModule }    from '../../interface';
import { ContactActionEnum } from '../../enum';
import { URLManager }        from '../../manager';
import { Util }              from '../../tool';

/**
 * @class PartnerModule
 * @extends AComponent
 * @implements IPartnerModule
 * @author Isaac Ewing
 * @version 1.0.0 12/04/20 04:41 pm
 * @version 1.1.0 02/18/21 09:56 pm - updated to extend AComponent
 */
export class PartnerModule extends AComponent implements IPartnerModule {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/16/21 04:50 pm
      */
     protected static readonly DEFAULT_ACTION: string     = ContactActionEnum.Partner;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/16/21 04:58 pm
      */
     protected static readonly DEFAULT_AFFILIATE: boolean = false;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/16/21 04:58 pm
      */
     protected static readonly DEFAULT_SPONSOR: boolean   = false;

     /**
      *
      * @param {PartnerModule} module
      * @return {PartnerModule}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/16/21 05:00 pm
      */
     public static Build( module: PartnerModule ): PartnerModule;
     public static Build( obj: Partial<IPartnerModule> ): PartnerModule;
     public static Build( json: string ): PartnerModule;
     public static Build( action?: PartnerModule | string, domain?: string, page?: string, affiliate?: boolean, sponsor?: boolean, name?: string, email?: string, message?: string ): PartnerModule;
     public static Build( dataOrAction?: unknown, domain?: string, page?: string, affiliate?: boolean, sponsor?: boolean, name?: string, email?: string, message?: string ): PartnerModule {
          if( dataOrAction ) {
               if( dataOrAction instanceof PartnerModule ) {
                    return dataOrAction;
               }
               if( typeof dataOrAction === 'object' ) {
                    const localData: Partial<IPartnerModule> = dataOrAction;

                    return new PartnerModule( localData?.action ?? null, localData?.domain ?? null, localData?.page ?? null, localData?.affiliate ?? null,
                                              localData?.sponsor ?? null, localData?.name ?? null, localData?.email ?? null, localData?.message ?? null );
               }
               if( typeof dataOrAction === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrAction ) );
                    } catch( exception ) {
                         // not a valid json string
                         return new PartnerModule( dataOrAction ?? null, domain ?? null, page ?? null, affiliate ?? null, sponsor ?? null, name ?? null,
                                                   email ?? null, message ?? null );
                    }
               }
          }

          return null;
     }

     /**
      *
      * @param {PartnerModule | string} action
      * @param {string} domain
      * @param {string} page
      * @param {boolean} affiliate
      * @param {boolean} sponsor
      * @param {string} name
      * @param {string} email
      * @param {string} message
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/04/20 04:41 pm
      */
     public constructor( action?: string, domain?: string, page?: string, affiliate?: boolean, sponsor?: boolean, name?: string, email?: string, message?: string ) {
          super( null );

          this.action    = action ?? PartnerModule.DEFAULT_ACTION;
          this.domain    = Util.cleanPath( domain ?? URLManager.Domain, false );
          this.page      = Util.cleanPath( page ?? URLManager.Page, true );
          this.affiliate = Util.asBoolean( affiliate ?? PartnerModule.DEFAULT_AFFILIATE );
          this.sponsor   = Util.asBoolean( sponsor ?? PartnerModule.DEFAULT_SPONSOR );
          this.name      = name ?? PartnerModule.DEFAULT_VALUE;
          this.email     = email ?? PartnerModule.DEFAULT_VALUE;
          this.message   = message ?? PartnerModule.DEFAULT_VALUE;
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see campaign
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public get action(): string {
          return this._data.get( 'action' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see campaign
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public set action( value: string ) {
          this._data.set( 'action', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see campaign
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public get domain(): string {
          return this._data.get( 'domain' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see campaign
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public set domain( value: string ) {
          this._data.set( 'domain', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/16/21 03:17 pm
      * @see id
      * @see action
      * @see domain
      * @see page
      * @see name
      * @see email
      * @see message
      * @see setId
      * @see setAction
      * @see setDomain
      * @see setPage
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toArray
      * @see toString
      */
     public get page(): string {
          return this._data.get( 'page' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/16/21 03:17 pm
      * @see id
      * @see action
      * @see domain
      * @see page
      * @see name
      * @see email
      * @see message
      * @see setId
      * @see setAction
      * @see setDomain
      * @see setPage
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toArray
      * @see toString
      */
     public set page( value: string ) {
          this._data.set( 'page', value );
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see affiliate
      * @see sponsor
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public get affiliate(): boolean {
          return this._data.get( 'affiliate' );
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see affiliate
      * @see sponsor
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public set affiliate( value: boolean ) {
          this._data.set( 'affiliate', value );
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see affiliate
      * @see sponsor
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public get sponsor(): boolean {
          return this._data.get( 'sponsor' );
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see affiliate
      * @see sponsor
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public set sponsor( value: boolean ) {
          this._data.set( 'sponsor', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see page
      * @see affiliate
      * @see sponsor
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public get name(): string {
          return this._data.get( 'name' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see page
      * @see affiliate
      * @see sponsor
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public set name( value: string ) {
          this._data.set( 'name', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see page
      * @see affiliate
      * @see sponsor
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public get email(): string {
          return this._data.get( 'email' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see page
      * @see affiliate
      * @see sponsor
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public set email( value: string ) {
          this._data.set( 'email', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see page
      * @see affiliate
      * @see sponsor
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public get message(): string {
          return this._data.get( 'message' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see page
      * @see affiliate
      * @see sponsor
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public set message( value: string ) {
          this._data.set( 'message', value );
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see page
      * @see affiliate
      * @see sponsor
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public setAction( value: string ): this {
          this.action = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see page
      * @see affiliate
      * @see sponsor
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public setDomain( value: string ): this {
          this.domain = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/16/21 03:17 pm
      * @see id
      * @see action
      * @see domain
      * @see page
      * @see affiliate
      * @see sponsor
      * @see name
      * @see email
      * @see message
      * @see setId
      * @see setAction
      * @see setDomain
      * @see setPage
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toArray
      * @see toString
      */
     public setPage( value: string ): this {
          this.page = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/16/21 05:09 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see page
      * @see affiliate
      * @see sponsor
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setAffiliate
      * @see setSponsor
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public setAffiliate( value: boolean ): this {
          this.affiliate = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/16/21 05:09 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see page
      * @see affiliate
      * @see sponsor
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setAffiliate
      * @see setSponsor
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public setSponsor( value: boolean ): this {
          this.sponsor = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see page
      * @see affiliate
      * @see sponsor
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public setName( value: string ): this {
          this.name = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see page
      * @see affiliate
      * @see sponsor
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public setEmail( value: string ): this {
          this.email = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see page
      * @see affiliate
      * @see sponsor
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public setMessage( value: string ): this {
          this.message = value;

          return this;
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see page
      * @see affiliate
      * @see sponsor
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public toObject(): Record<string, unknown> {
          return {
               action   : this.action,
               domain   : this.domain,
               page     : this.page,
               affiliate: this.affiliate,
               sponsor  : this.sponsor,
               name     : this.name,
               email    : this.email,
               message  : this.message,
          };
     }
}