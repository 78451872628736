import { TitleModule }  from './title.module';
import { IEvent }       from '../../interface';
import { DomainModule } from './domain.module';

/**
 * @class EventModule
 * @extends TitleModule
 * @implements IEvent
 * @author Isaac Ewing
 * @version 1.0.0 01/30/21 04:02 pm
 * @version 1.1.0 03/27/21 12:40 pm - added interface support
 * @version 2.0.0 05/28/21 05:57 pm - converted from AComponent to TitleModule
 */
export class EventModule extends TitleModule implements IEvent {
     /**
      *
      * @param {object} module
      * @return {EventModule}
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 04:02 pm
      * @see id
      * @see domain
      * @see title
      * @see subtitle
      * @see description
      * @see image
      * @see allDay
      * @see start
      * @see end
      * @see setDomain
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setImage
      * @see setAllDay
      * @see setStart
      * @see setEnd
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public static Build( module: EventModule ): EventModule;
     public static Build( obj: Partial<IEvent> ): EventModule;
     public static Build( json: string ): EventModule;
     public static Build( id?: number, domain?: unknown, title?: string, subtitle?: string, description?: string, image?: string, allDay?: boolean, start?: number, end?: number ): EventModule;
     public static Build( id?: number, domainId?: number, title?: string, subtitle?: string, description?: string, image?: string, allDay?: boolean, start?: number, end?: number ): EventModule;
     public static Build( id?: number, domain?: DomainModule, title?: string, subtitle?: string, description?: string, image?: string, allDay?: boolean, start?: number, end?: number ): EventModule;
     public static Build( dataOrId: unknown, domain?: unknown, title?: string, subtitle?: string, description?: string, image?: string, allDay?: boolean, start?: number, end?: number ): EventModule {
          if( dataOrId ) {
               dataOrId = this.mapAPIRootNode( dataOrId, this.API_ROOT_NODE );

               if( dataOrId instanceof EventModule ) {
                    return dataOrId;
               }
               if( typeof dataOrId === 'object' ) {
                    const localData: Partial<IEvent> = dataOrId;

                    return new EventModule( localData?.id, DomainModule.Build( localData?.domain ), localData?.title, localData?.subtitle,
                                            localData?.description, localData?.image, !!localData?.allDay, localData?.start, localData?.end );
               } else if( typeof dataOrId === 'number' ) {
                    return new EventModule( +dataOrId, DomainModule.Build( domain ), title, subtitle, description, image, !!allDay, start, end );
               } else if( typeof dataOrId === 'string' ) {
                    if( dataOrId ) {
                         try {
                              return this.Build( JSON.parse( dataOrId ) );
                         } catch( exception ) {
                              // not a valid json string
                              return new EventModule( +dataOrId, DomainModule.Build( domain ), title, subtitle, description, image, !!allDay, +start, +end );
                         }
                    }
               } else {
                    return null;
               }
          }

          return null;
     }

     /**
      *
      * @param {number} id
      * @param {DomainModule} domain
      * @param {string} title
      * @param {string} subtitle
      * @param {string} description
      * @param {string} image
      * @param {boolean} allDay
      * @param {number} start
      * @param {number} end
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 04:02 pm
      * @see id
      * @see domain
      * @see title
      * @see subtitle
      * @see description
      * @see image
      * @see start
      * @see allDay
      * @see end
      * @see setDomain
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setImage
      * @see setAllDay
      * @see setStart
      * @see setEnd
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public constructor( id?: number, domain?: DomainModule, title?: string, subtitle?: string, description?: string, image?: string, allDay?: boolean, start?: number, end?: number ) {
          super( id ?? null );

          this.domain      = domain ?? null;
          this.title       = title ?? null;
          this.subtitle    = subtitle ?? null;
          this.description = description ?? null;
          this.image       = image ?? null;
          this.allDay      = allDay ?? null;
          this.start       = start ?? null;
          this.end         = end ?? null;
     }

     /**
      *
      * @return {DomainModule}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 04:02 pm
      * @see id
      * @see domain
      * @see title
      * @see subtitle
      * @see description
      * @see image
      * @see allDay
      * @see start
      * @see end
      * @see setDomain
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setImage
      * @see setAllDay
      * @see setStart
      * @see setEnd
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public get domain(): DomainModule {
          return this._data.get( 'domain' );
     }

     /**
      *
      * @param {DomainModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 04:02 pm
      * @see id
      * @see domain
      * @see title
      * @see subtitle
      * @see description
      * @see image
      * @see allDay
      * @see start
      * @see end
      * @see setDomain
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setImage
      * @see setAllDay
      * @see setStart
      * @see setEnd
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public set domain( value: DomainModule ) {
          this._data.set( 'domain', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 04:02 pm
      * @see id
      * @see title
      * @see subtitle
      * @see description
      * @see image
      * @see allDay
      * @see start
      * @see end
      * @see setDomain
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setImage
      * @see setAllDay
      * @see setStart
      * @see setEnd
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public get title(): string {
          return this._data.get( 'title' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 04:02 pm
      * @see id
      * @see domain
      * @see title
      * @see subtitle
      * @see description
      * @see image
      * @see allDay
      * @see start
      * @see end
      * @see setDomain
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setImage
      * @see setAllDay
      * @see setStart
      * @see setEnd
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public set title( value: string ) {
          this._data.set( 'title', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 04:02 pm
      * @see id
      * @see domain
      * @see title
      * @see subtitle
      * @see description
      * @see image
      * @see allDay
      * @see start
      * @see end
      * @see setDomain
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setImage
      * @see setAllDay
      * @see setStart
      * @see setEnd
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public get subtitle(): string {
          return this._data.get( 'subtitle' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 04:02 pm
      * @see id
      * @see domain
      * @see title
      * @see subtitle
      * @see description
      * @see image
      * @see allDay
      * @see start
      * @see end
      * @see setDomain
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setImage
      * @see setAllDay
      * @see setStart
      * @see setEnd
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public set subtitle( value: string ) {
          this._data.set( 'subtitle', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 04:02 pm
      * @see id
      * @see domain
      * @see title
      * @see subtitle
      * @see description
      * @see image
      * @see allDay
      * @see start
      * @see end
      * @see setDomain
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setImage
      * @see setAllDay
      * @see setStart
      * @see setEnd
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public get desc(): string {
          return this._data.get( 'description' );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 04:02 pm
      * @see id
      * @see domain
      * @see title
      * @see subtitle
      * @see description
      * @see image
      * @see allDay
      * @see start
      * @see end
      * @see setDomain
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setImage
      * @see setAllDay
      * @see setStart
      * @see setEnd
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public get description(): string {
          return this._data.get( 'description' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 04:02 pm
      * @see id
      * @see domain
      * @see title
      * @see subtitle
      * @see description
      * @see image
      * @see allDay
      * @see start
      * @see end
      * @see setDomain
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setImage
      * @see setAllDay
      * @see setStart
      * @see setEnd
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public set description( value: string ) {
          this._data.set( 'description', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 04:02 pm
      * @see id
      * @see domain
      * @see title
      * @see subtitle
      * @see description
      * @see image
      * @see allDay
      * @see start
      * @see end
      * @see setDomain
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setImage
      * @see setAllDay
      * @see setStart
      * @see setEnd
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public get image(): string {
          return this._data.get( 'image' );
     }

     /**
      *
      * @param {string} value
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 04:02 pm
      * @see id
      * @see domain
      * @see title
      * @see subtitle
      * @see description
      * @see image
      * @see allDay
      * @see start
      * @see end
      * @see setDomain
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setImage
      * @see setAllDay
      * @see setStart
      * @see setEnd
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public set image( value: string ) {
          this._data.set( 'image', value );
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 04:02 pm
      * @see id
      * @see domain
      * @see title
      * @see subtitle
      * @see description
      * @see image
      * @see allDay
      * @see start
      * @see end
      * @see setDomain
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setImage
      * @see setAllDay
      * @see setStart
      * @see setEnd
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public get allDay(): boolean {
          return this._data.get( 'allDay' );
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 04:02 pm
      * @see id
      * @see domain
      * @see title
      * @see subtitle
      * @see description
      * @see image
      * @see allDay
      * @see start
      * @see end
      * @see setDomain
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setImage
      * @see setAllDay
      * @see setStart
      * @see setEnd
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public set allDay( value: boolean ) {
          this._data.set( 'allDay', value );
     }

     /**
      *
      * @return {number}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 04:02 pm
      * @see id
      * @see domain
      * @see title
      * @see subtitle
      * @see description
      * @see image
      * @see allDay
      * @see start
      * @see end
      * @see setDomain
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setImage
      * @see setAllDay
      * @see setStart
      * @see setEnd
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public get start(): number {
          return this._data.get( 'start' );
     }

     /**
      *
      * @param {number} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 04:02 pm
      * @see id
      * @see domain
      * @see title
      * @see subtitle
      * @see description
      * @see image
      * @see allDay
      * @see start
      * @see end
      * @see setDomain
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setImage
      * @see setAllDay
      * @see setStart
      * @see setEnd
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public set start( value: number ) {
          this._data.set( 'start', value );
     }

     /**
      *
      * @return {number}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 04:02 pm
      * @see id
      * @see domain
      * @see title
      * @see subtitle
      * @see description
      * @see image
      * @see allDay
      * @see start
      * @see end
      * @see setDomain
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setImage
      * @see setAllDay
      * @see setStart
      * @see setEnd
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public get end(): number {
          return this._data.get( 'end' );
     }

     /**
      *
      * @return {number}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 04:02 pm
      * @see id
      * @see domain
      * @see title
      * @see subtitle
      * @see description
      * @see image
      * @see allDay
      * @see start
      * @see end
      * @see setDomain
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setImage
      * @see setAllDay
      * @see setStart
      * @see setEnd
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public set end( value: number ) {
          this._data.set( 'end', value );
     }

     /**
      *
      * @param {DomainModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 03/27/21 12:33 pm
      * @see id
      * @see domain
      * @see title
      * @see subtitle
      * @see description
      * @see image
      * @see start
      * @see allDay
      * @see end
      * @see setDomain
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setImage
      * @see setAllDay
      * @see setStart
      * @see setEnd
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     setDomain( value: DomainModule ): this {
          this.domain = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 03/27/21 12:33 pm
      * @see id
      * @see domain
      * @see title
      * @see subtitle
      * @see description
      * @see image
      * @see start
      * @see allDay
      * @see end
      * @see setDomain
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setImage
      * @see setAllDay
      * @see setStart
      * @see setEnd
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     setTitle( value: string ): this {
          this.title = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 03/27/21 12:33 pm
      * @see id
      * @see domain
      * @see title
      * @see subtitle
      * @see description
      * @see image
      * @see start
      * @see allDay
      * @see end
      * @see setDomain
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setImage
      * @see setAllDay
      * @see setStart
      * @see setEnd
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     setSubtitle( value: string ): this {
          this.subtitle = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 03/27/21 12:33 pm
      * @see id
      * @see domain
      * @see title
      * @see subtitle
      * @see description
      * @see image
      * @see start
      * @see allDay
      * @see end
      * @see setDomain
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setImage
      * @see setAllDay
      * @see setStart
      * @see setEnd
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     setDescription( value: string ): this {
          this.description = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 03/27/21 12:33 pm
      * @see id
      * @see domain
      * @see title
      * @see subtitle
      * @see description
      * @see image
      * @see start
      * @see allDay
      * @see end
      * @see setDomain
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setImage
      * @see setAllDay
      * @see setStart
      * @see setEnd
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     setImage( value: string ): this {
          this.image = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 03/27/21 12:33 pm
      * @see id
      * @see domain
      * @see title
      * @see subtitle
      * @see description
      * @see image
      * @see start
      * @see allDay
      * @see end
      * @see setDomain
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setImage
      * @see setAllDay
      * @see setStart
      * @see setEnd
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     setAllDay( value: boolean ): this {
          this.allDay = value;

          return this;
     }

     /**
      *
      * @param {number} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 03/27/21 12:33 pm
      * @see id
      * @see domain
      * @see title
      * @see subtitle
      * @see description
      * @see image
      * @see start
      * @see allDay
      * @see end
      * @see setDomain
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setImage
      * @see setAllDay
      * @see setStart
      * @see setEnd
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     setStart( value: number ): this {
          this.start = value;

          return this;
     }

     /**
      *
      * @param {number} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 03/27/21 12:33 pm
      * @see id
      * @see domain
      * @see title
      * @see subtitle
      * @see description
      * @see image
      * @see start
      * @see allDay
      * @see end
      * @see setDomain
      * @see setTitle
      * @see setSubtitle
      * @see setDescription
      * @see setImage
      * @see setAllDay
      * @see setStart
      * @see setEnd
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     setEnd( value: number ): this {
          this.end = value;

          return this;
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 04:02 pm
      * @see id
      * @see domain
      * @see title
      * @see subtitle
      * @see description
      * @see image
      * @see allDay
      * @see start
      * @see end
      * @see toObject
      * @see toMap
      * @see toString
      * @see toURLParams
      */
     public toObject(): Record<string, unknown> {
          return {
               id         : this.id,
               domain     : this.domain.toObject(),
               title      : this.title,
               subtitle   : this.subtitle,
               description: this.description,
               image      : this.image,
               allDay     : this.allDay,
               start      : this.start,
               end        : this.end,
          };
     }
}