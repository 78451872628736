import isNode                from 'detect-node';
import React                 from 'react';
import PropTypes             from 'prop-types';
import jQuery                from 'jquery';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { Util }              from '../../../api/com/ewing/social/tool';
import { ImageModule }       from '../../../api/com/ewing/social/component';

class Particle {
     private readonly random: number;
     private readonly lineWidth: number;
     private readonly speed: number;
     private readonly progress_inc: number;
     private readonly radius: number;
     private readonly type: string;
     private readonly color: string;
     private readonly w: any;
     private readonly h: any;
     private x: number;
     private y: number;
     private vx: number;
     private vy: number;
     private canvas: any;
     private progress: number;

     protected randomIntFromInterval( min, max ): number {
          return Math.floor( Math.random() * ( max - min + 1 ) + min );
     }

     protected createArcFill( radius, color ): void {
          this.canvas.beginPath();
          this.canvas.arc( this.x, this.y, radius / 1.5, 0, 2 * Math.PI );
          this.canvas.fillStyle = color;
          this.canvas.fill();
          this.canvas.closePath();
     }

     protected createArcEmpty( radius, lineWidth, color ): void {
          this.canvas.beginPath();
          this.canvas.arc( this.x, this.y, radius, 0, 2 * Math.PI );
          this.canvas.lineWidth   = lineWidth;
          this.canvas.strokeStyle = color;
          this.canvas.stroke();
          this.canvas.closePath();
     }

     protected drawRotatedRect( x, y, width, height, degrees, color ): void {
          this.canvas.save();
          this.canvas.beginPath();
          this.canvas.translate( x - this.radius + width / 2, y - this.radius + height / 2 );
          this.canvas.rotate( ( degrees * Math.PI ) / 180 );
          this.canvas.rect( -( width / 2 ), -( height / 2 ), width, height );
          this.canvas.fillStyle = color;
          this.canvas.fill();
          this.canvas.restore();
     }

     protected move(): boolean {
          this.progress += this.progress_inc;
          let diff = this.radius;
          switch( this.type ) {
               case 'full':
                    diff = this.radius + this.lineWidth * 2;
                    break;
               case 'square-in-circle':
                    diff = this.radius + this.lineWidth * 3;
                    break;
               case 'empty':
                    diff = this.radius + this.lineWidth;
                    break;
          }

          this.x = this.x + this.vx;
          this.y = this.y + this.vy;

          if( this.x < diff || this.x > this.w - diff ) {
               this.vx = -this.vx;
          }
          if( this.y < diff || this.y > this.h - diff ) {
               this.vy = -this.vy;
          }
          this.render();

          return true;
     }

     protected calculateDistance( v1, v2 ): number {
          let x = Math.abs( v1.x - v2.x );
          let y = Math.abs( v1.y - v2.y );

          return Math.sqrt( x * x + y * y );
     }

     public constructor( canvas ) {
          let speed         = 1;
          let colors        = [ '#E5493F', '#55C1FF', '#26B9A0', '#5A52FF' ];
          // let colors = ["#feea00","#a9df85","#5dc0ad", "#ff9a00","#fa3f20"]
          let types         = [ 'full', 'fill', 'empty', 'square', 'square-in-circle' ];
          this.random       = Math.random();
          this.canvas       = canvas;
          this.lineWidth    = 0.2 + 2.8 * this.random;
          this.speed        = speed + Math.random() * 3;
          this.progress     = 0;
          this.progress_inc = this.random > 0.5 ? this.random * ( this.speed * 1.5 ) : this.random * -( this.speed * 1.5 );
          this.vx           = Math.random() * this.speed - Math.random() * this.speed;
          this.vy           = Math.random() * this.speed - Math.random() * this.speed;
          this.radius       = 10 + Math.round( Math.random() * 50 );
          //this.w      = jQuery( window ).width();
          //this.h      = jQuery( window ).height();
          this.w = canvas.canvas.width;
          this.h = canvas.canvas.height;
          this.x = ( this.w - this.radius ) / 2;
          this.y = ( this.h - this.radius ) / 2;

          this.radius = 1 + 8 * this.random;
          this.type   = types[ this.randomIntFromInterval( 0, types.length - 1 ) ];
          this.color  = colors[ this.randomIntFromInterval( 0, colors.length - 1 ) ];
     }

     /**
      *
      * @return {{x: number, y: number}}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/25/21 07:02 pm - documented
      */
     public getCoordinates(): { x: number, y: number } {
          return {
               x: this.x,
               y: this.y,
          };
     }

     /**
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/25/21 07:02 pm - documented
      */
     public render(): void {
          // Create arc
          let color = this.color;
          switch( this.type ) {
               case 'square':
                    this.drawRotatedRect( this.x, this.y, this.radius * 2, this.radius * 2, this.progress, color );
                    break;
               case 'square-in-circle':
                    this.drawRotatedRect( this.x, this.y, this.radius * 2, this.radius * 2, this.progress, color );
                    this.createArcEmpty( this.radius + this.lineWidth * 3, this.lineWidth, color );
                    break;
               case 'full':
                    this.createArcFill( this.radius, color );
                    this.createArcEmpty( this.radius + this.lineWidth, this.lineWidth / 2, color );
                    break;
               case 'fill':
                    this.createArcFill( this.radius, color );
                    break;
               case 'empty':
                    this.createArcEmpty( this.radius, this.lineWidth, color );
                    break;
          }
     }
}

export class Wave extends React.PureComponent<any, any> {
     /**
      *
      * @type {number}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 04/09/21 08:29 pm
      */
     protected static readonly CANVAS_SMALL_HEIGHT: number = 200;
     /**
      *
      * @type {number}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 04/09/21 08:29 pm
      */
     protected static readonly CANVAS_LARGE_HEIGHT: number = 400;
     protected static media: any[]                         = [
          'plus.png',
          'circle.png',
          'dash.png',
          'triangle.png',
          'pentagon.png',
          'hexagon.png',
          'rhombus.png',
     ];
     protected static isInitialized: boolean;
     protected readonly isMobile: boolean;
     private readonly a: number;
     private readonly steps: number;
     private readonly rotation: string;
     private readonly scale: number;
     private readonly sine: number;
     private readonly speed: any;
     private readonly texture: any;
     private readonly mediaMaxX: number;
     private PIXI: any;
     private app: any;
     private container: any;
     private destroy: boolean;
     private progress: number;
     private bounding: { x: number; y: number };
     private bunny: any;

     public constructor( PIXI, app, container, options ) {
          super( null );

          if( !Wave.isInitialized ) {
               Wave.isInitialized = true;
               Wave.media         = Wave.media.map( ( m ) => PIXI.Texture.from( ImageModule.ForGetImageSocial( m ?? '', true ) ) );
          }

          this.PIXI      = PIXI;
          this.app       = app;
          this.container = container;
          this.destroy   = false;
          this.isMobile  = Util.isBreakpointSmall();
          this.a         = [ 0.5, 1, 2, 0.3, 3 ][ Math.floor( Math.random() * 4 ) ];
          this.steps     = app.screen.width / ( !this.isMobile ? 16 : 8 );
          this.rotation  = Math.random() > 0.5 ? '-' : '+';
          this.scale     = 0.5 * Math.random();
          this.sine      = ( !this.isMobile ? 100 : 20 ) * Math.random();
          this.speed     = !this.isMobile ? options.speed / 2.5 : options.speed / 4.5;
          this.progress  = 0;
          this.texture   = Wave.media[ Math.floor( Math.random() * Wave.media.length ) ];
          this.mediaMaxX = this.app.screen.width + +process.env.REACT_APP_PARTICLE_PIXI_WIDTH;
          this.bounding  = {
               x: app.screen.width,
               y: app.screen.height,
          };

          this.behaviours();
          this.render();
     }

     protected behaviours(): void {
          /*
           jQuery( window ).on( 'resize', () => {
           this.destroy = true;
           } ).bind( this );

           */
     }

     public static get mediaLength(): number {
          return Wave.media.length;
     }

     public move(): boolean {
          const x: number = this.progress;
          const y: number = this.app.renderer.height / 2 + this.sine + this.a * Math.sin( this.progress / this.steps ) * ( !this.isMobile ? 60 : 60 / 1.5 );

          this.bunny.x        = -this.texture?.orig?.width + x;
          this.bunny.y        = y;
          this.bunny.scale.x  = this.scale;
          this.bunny.scale.y  = this.scale;
          this.progress       = this.progress + this.speed;
          this.bunny.rotation = this.rotation + this.radians( this.progress );

          return this.isAnimationFinished() ? this.prepareForGC() : true;
     }

     public getMediaMaxX(): number {
          return this.mediaMaxX;
     }

     public isAnimationFinished(): boolean {
          return this.bunny.x > this.mediaMaxX || this.destroy === true;
     }

     public getMoveTest() {
          return {
               screenWidth  : this.app.screen.width,
               rendererWidth: this.app.renderer.width,
               textureWidth : this.texture?.orig?.width,
               x            : this.bunny.x,
          };
     }

     /**
      *
      * @return {{x: number, y: number, scaleX: number, scaleY: number}}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/11/21 11:06 am
      */
     public getCoordinates(): { x: number, y: number, scaleX: number, scaleY: number } {
          return {
               x     : +this.bunny.x,
               y     : +this.bunny.y,
               scaleX: +this.bunny.scale.x,
               scaleY: +this.bunny.scale.y,
          };
     }

     /**
      *
      * @return {number}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/11/21 11:06 am
      */
     public getProgress(): number {
          return this.progress;
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/11/21 12:08 pm
      */
     public enableDestroy(): this {
          this.destroy = true;

          return this;
     }

     /**
      *
      * @param {number} x
      * @param {number} y
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/15/21 11:05 pm
      */
     public moveXY( x?: number, y?: number ): this {
          this.bunny.x = x ?? 0;
          this.bunny.y = y ?? 0;

          return this;
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/15/21 11:05 pm
      */
     public prepareForGC(): boolean {
          this.moveXY( +( process.env.REACT_APP_PARTICLE_PIXI_WIDTH ?? 0 ), +( process.env.REACT_APP_PARTICLE_PIXI_HEIGHT ?? 0 ) );
          this.destroy = true;
          this.container.removeChild( this.bunny );

          return false;
     }

     protected radians( degrees ): number {
          return ( degrees * Math.PI ) / 180;
     }

     public render(): JSX.Element {
          let x      = this.progress;
          let y      = this.app.renderer.height / 2 + this.sine + this.a * Math.sin( this.progress / this.steps ) * 60;
          // Create a 5x5 grid of bunnies
          this.bunny = new this.PIXI.Sprite( this.texture );
          this.bunny.anchor.set( 0.5 );
          this.bunny.x = -( +process.env.REACT_APP_PARTICLE_PIXI_WIDTH ) + x;
          this.bunny.y = y;
          this.container.addChild( this.bunny );

          return null;
     }
}

export abstract class ParticleBase<P, S> extends React.PureComponent<any, any> {
     public static readonly PARTICLE = {
          type1: 'type-1',
          type2: 'type-2',
          type3: 'type-3',
     };
     public static LIBRARY           = {
          isReady: false,
          PIXI   : null,
     };
     public static propTypes         = {
          dimensions       : PropTypes.object.isRequired,
          particleMax      : PropTypes.number,
          particleFrequency: PropTypes.number,
     };
     public static defaultProps      = {
          particleMax      : 10,
          particleFrequency: 250,
     };
     public state: any;

     protected isBuildReady(): boolean {
          if( !isNode ) {
               if( !ParticleBase.LIBRARY.isReady ) {
                    try {
                         this.registerLibraries();
                         ParticleBase.LIBRARY.isReady = true;
                    } catch( exception ) {
                         console.log( '##### IS BUILD READY exception', exception );

                         return false;
                    }
               }

               return true;
          }

          console.log( '##### PARTICLE BASE IS NOT READY...' );
          return false;
     }

     protected registerLibraries(): boolean {
          try {
               let PIXI = require( 'pixi.js' );

               ParticleBase.LIBRARY.isReady = true;
               ParticleBase.LIBRARY.PIXI    = PIXI;
          } catch( e ) {
               return false;
          }

          return true;
     }

     protected registerOnEnter(): void {
          console.log( 'ON ENTER SCREEN', this, 'initialized', this.state.initialized );

          if( this.state.initialized ) {
               this.setState( { action: true } );
          } else {
               //this.build();
               this.setState( {
                                   initialized: true,
                                   action     : true,
                              } );
          }

          this.update();
     }

     protected registerRender(): JSX.Element {
          return (
               <canvas id={ this.state.hash } className={ this.state.className }{ ...this.state.dimensions } />
          );
     }

     protected populate(): number {
          const addParticle = () => {
               return () => {
                    this.state.particles.children.push( new Particle( this.state.canvas ) );
               };
          };

          for( let i = 0; i < this.state.particles.max; i++ ) {
               setTimeout( addParticle(), this.state.particles.frequency * i );
          }

          return this.state.particles.children.length;
     }

     protected clear(): void {
          try {
               this.state.canvas.fillStyle = '#' + process.env.REACT_APP_COLOR_LIGHT;
               this.state.canvas.fillRect( 0, 0, this.state.dimensions.width, this.state.dimensions.height );
          } catch( exception ) {
               console.log( 'particle exception', exception );
               this.build();
          }
     }

     protected connection(): void {
          let old_element: Particle = null;
          let canvas                = this.state.canvas;

          jQuery.each( this.state.particles.children, function( i: number, element: Particle ) {
               if( i > 0 && ( i % 3 === 0 || i % 7 === 0 ) ) {
                    let box1 = old_element.getCoordinates();
                    let box2 = element.getCoordinates();
                    canvas.beginPath();
                    canvas.moveTo( box1.x, box1.y );
                    canvas.lineTo( box2.x, box2.y );
                    canvas.lineWidth   = 0.8;
                    canvas.strokeStyle = '#32323C';

                    /*
                     if( i % 7 === 0 ) {
                     //canvas.strokeStyle="#3f47ff";
                     }
                     */

                    canvas.stroke();
                    canvas.closePath();
               }

               old_element = element;
          } );
     }

     protected waveInit(): void {
          clearInterval( this.state.interval );
          const PIXI     = ParticleBase.LIBRARY.PIXI;
          const appProps = {
               id         : this.state.hash,
               width      : this.state.dimensions.width,
               height     : this.state.dimensions.height,
               antialias  : Util.asBoolean( process.env.REACT_APP_PARTICLE_ANTIALIAS ),
               transparent: Util.asBoolean( process.env.REACT_APP_PARTICLE_TRANSPARENT ),
          };

          this.state.app = new PIXI.Application( appProps );

          let canvas    = jQuery( '#' + this.state.hash );
          let container = new PIXI.Container();
          let amount    = this.state.app.renderer instanceof PIXI.Renderer ? 100 : 5;

          if( amount === 5 ) {
               this.state.app.renderer.context.mozImageSmoothingEnabled    = false;
               this.state.app.renderer.context.webkitImageSmoothingEnabled = false;
          }
          this.state.container = container;
          this.state.app.stage.addChild( container );
          this.state.app.stage.addChild( this.state.container );
          this.state.app.view.style[ 'transform' ] = 'translatez(0)';
          this.state.app.view.key                  = Math.floor( Math.random() * Wave.mediaLength ) + 1;
          this.state.app.view.id                   = this.state.hash;

          //console.log( '--- container', container, this.state.container );
          //console.log( 'great... app view', this.state.app.view );
          canvas.before( this.state.app.view );

          const isaac               = () => {
               this.state.particles.children.push(
                    new Wave( PIXI ?? null, this.state.app ?? null, this.state.container ?? null, {
                         speed: 1 + Math.random() * Wave.mediaLength,
                    } ),
               );
          };
          const isNotSmall: boolean = !Util.isBreakpointSmall();
          const delay: number       = +process.env.REACT_APP_PARTICLE_PIXI_SPAWN_DELAY;
          const delayOffset: number = isNotSmall ? 10 : 50;

          console.log( '[[ P ]] INTERVAL', { isNotSmall, delay, offset: delayOffset } );

          try {
               this.state.interval = setInterval( isaac.bind( this ), delayOffset );
          } catch( exception ) {
               console.log( '&&& so the pixi package needs to be loaded into each each method...' );
          }
     }

     protected update(): void {
          if( this.state.action ) {
               this.clear();
               this.connection();
               this.state.particles.children.filter( ( p ) => {
                    return p.move();
               } );
               /*
                particles = this.state.particles.children.filter( function( p ) { return p.move(); } );
                this.setState( {
                particles: {
                children: particles,
                },
                } );
                //this.state.particles.children = this.state.particles.children.filter( function( p ) { return p.move(); } );
                */
               requestAnimationFrame( this.update.bind( this ) );
          }
     }

     protected build(): void {
          const type1 = () => {
               const canvas                        = jQuery( '#' + this.state.hash );
               const mainCanvas: HTMLCanvasElement = canvas[ 0 ] as HTMLCanvasElement;

               canvas.after( '<div class="canvas-bg"/>' );
               this.state.canvas = mainCanvas.getContext( '2d' );
               this.populate();
               this.update();
          };
          const type2 = () => {
               try {
                    this.waveInit();
                    this.update();
               } catch( exception ) {
                    console.log( '*** particle 2 EXCEPTION', exception );
               }
          };

          switch( this.state.type ) {
               case ParticleBase.PARTICLE.type1:
                    type1();
                    break;
               case ParticleBase.PARTICLE.type2:
                    type2();
                    break;
          }

     }

     protected debounce( func, wait, immediate ): () => void {
          let timeout;
          const contextThis = this;

          return function() {
               const context = contextThis,
                     args    = arguments;
               const later   = () => {
                    timeout = null;
                    if( !immediate ) {
                         func.apply( context, args );
                    }
               };
               const callNow = immediate && !timeout;
               clearTimeout( timeout );
               timeout = setTimeout( later, wait );

               if( callNow ) {
                    func.apply( context, args );
               }
          };
     }

     protected resize(): void {
          jQuery( '#' + this.state.hash ).remove();
          this.build();
     }

     /**
      *
      * @param {boolean} visible
      * @protected
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/04/21 03:50 pm
      */
     protected registerOnChange( visible: boolean ): void {
          if( visible && !this.state.initialized ) {
               this.setState( {
                                   action          : true,
                                   initialized     : true,
                                   visibilitySensor: {
                                        active  : false,
                                        onChange: null,
                                   },
                              } );
          }
     }

     protected registerDimensionsUpdated(): void {
          if( this.isBuildReady() ) {
               if( this.state.dimensions.width !== 0 && !this.state.initialized ) {
                    try {
                         this.build();
                    } catch( exception ) {
                         console.log( 'FUCK... mount exception', exception );
                    }
               }
          }
     }

     protected constructor( props ) {
          super( props );
     }

     public componentDidMount(): void {
          this.registerDimensionsUpdated();
     }

     public componentDidUpdate( prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any ): void {
          if( prevProps.dimensions !== this.props.dimensions ) {
               this.setState( { dimensions: this.props.dimensions } );
               this.registerDimensionsUpdated();
          }
          if( prevState.dimensions?.width !== this.state.dimensions?.width ) {
               this.registerDimensionsUpdated();
          }
     }

     public render(): JSX.Element {
          //<canvas id={ this.state.hash } className={ this.state.className }{ ...this.state.dimensions } />

          return (
               <ReactVisibilitySensor { ...this.state.visibilitySensor }>
                    { this.registerRender() }
               </ReactVisibilitySensor>
          );
     }
}
