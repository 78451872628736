import React   from 'react';
import Hash    from 'object-hash';
import * as d3 from 'd3-random';
import jQuery  from 'jquery';
import isNode  from 'detect-node';

export class Particle3 extends React.Component<any, any> {
     static propTypes    = {
          //
     };
     static defaultProps = {
          //
     };

     constructor( props ) {
          super( props );

          this.state = {
               PIXI      : null,
               hash      : this.registerHash(),
               className : [],
               action    : false,
               canvas    : null,
               container : null,
               sprite    : null,
               app       : null,
               bunny     : null,
               interval  : null,
               isBrowser : false,
               dimensions: props.dimensions,
               particles : {
                    max      : props.particleMax,
                    frequency: props.particleFrequency,
                    children : [],
               },
               onEnter   : () => { this.registerOnEnter(); },
               onLeave   : () => { this.registerOnLeave(); },
          };
     }

     isBuildReady() {
          try {
               if( typeof window !== 'undefined' ) {
                    this.registerJQuery();

                    return true;
               } else {
                    console.log( 'skipping jquery test, window is not available yet... ' );

                    return false;
               }
          } catch( exception ) {
               console.log( 'base component exception', exception );
          }
     }

     registerHash() {
          try {
               return Hash( { name: d3.randomNormal } );
          } catch( exception ) {
               console.log( '===> particle base hash exception', exception );
               return Math.random() * 5;
          }
     }

     registerJQuery() {
          if( typeof window !== 'undefined' ) {
               window[ '$' ] = window[ 'jQuery' ] = jQuery;
          }
     }

     registerClasses( props ) {
          if( props.className ) {
               this.state.className.push( props.className );
          }
     }

     registerOnEnter() {
          if( this.state.initialized ) {
               this.setState( { action: true } );
          } else {
               this.setState( {
                                   initialized: true,
                                   action     : true,
                              } );
          }

          this.update();
     }

     registerOnLeave() {
          this.setState( { action: false } );
     }

     registerRender() {
          return <div id={ this.state.hash } className="particle2-bg" style={ this.state.dimensions } />;
     }

     update() {
          if( this.state.action ) {
               this.state.particles.children.filter( ( p ) => {
                    return p.move();
               } );
               requestAnimationFrame( this.update.bind( this ) );
          }
     }

     build() {
          //
     }

     componentDidMount() {
          if( !isNode ) {
               this.state.children.push( <div>!isNode === true</div> );
          } else {
               this.state.children.push( <div>!isNode === false</div> );
          }
     }

     render() {
          return (
               <div>
                    { this.state.children }
               </div>
          );
     }
}

export default Particle3;
