import isNode                                from 'detect-node';
import React                                 from 'react';
import PropTypes                             from 'prop-types';
import store                                 from 'store2';
import { Socket, io }                        from 'socket.io-client';
import { HAlignEnum, StyleEnum, VAlignEnum } from '../../api/com/ewing/social/enum';
import {
    IBaseProps, IBaseState, ICharacterProps,
    IGalleryModule, IImageModule, ILinkProps,
    ISectionModule,
}                                            from '../../api/com/ewing/social/interface';
import { ClassError }                        from '../../api/com/ewing/social/error';
import { SiteModule }                        from '../../api/com/ewing/social/module/api';
import { URLManager }                        from '../../api/com/ewing/social/manager/url.manager';
import { SectionModule }                     from '../../api/com/ewing/social/component';
import { Util }                              from '../../api/com/ewing/social/tool/util';
import { OAuth }                             from './OAuth';

/**
 * @class Base
 * @extends React.Component
 * @author Isaac Ewing
 * @version 1.0.0 12/24/20 02:12 pm
 */
export abstract class Base<P = any, S = any> extends React.Component<Partial<IBaseProps>, Partial<IBaseState>> {
    protected static readonly CLASSNAME_CAPS: string      = process.env.REACT_APP_CSS_CLASSNAME_IS_CAPS ?? 'is-caps';
    protected static readonly CLASSNAME_PASSWORD: string  = process.env.REACT_APP_CSS_CLASSNAME_IS_PASSWORD ?? 'is-password';
    protected static readonly CLASSNAME_DISABLED: string  = process.env.REACT_APP_CSS_CLASSNAME_IS_DISABLED ?? 'is-disabled';
    protected static readonly CLASSNAME_ANIMATE: string   = process.env.REACT_APP_CSS_CLASSNAME_ANIMATE ?? 'animate';
    protected static readonly CLASSNAME_ANIMATION: string = process.env.REACT_APP_CSS_CLASSNAME_ANIMATION ?? 'animation';
    protected static readonly CLASSNAME_FADE_IN: string   = process.env.REACT_APP_CSS_CLASSNAME_FADE_IN ?? 'fade-in';
    protected static readonly SOCKET: Socket              = io( URLManager.Root );
    //protected static readonly SOCKET: Socket          = io( socketOptions ?? {} );
    protected static readonly trackHashes: boolean        = false;
    protected static readonly hashes: string[]            = [];
    protected static COMPONENT_CLASS: string              = 'page-base';
    protected static INITIALIZED: boolean                 = false;
    public static propTypes                               = {
        id       : PropTypes.string,
        light    : PropTypes.bool,
        grey     : PropTypes.bool,
        dark     : PropTypes.bool,
        left     : PropTypes.bool,
        center   : PropTypes.bool,
        right    : PropTypes.bool,
        top      : PropTypes.bool,
        middle   : PropTypes.bool,
        bottom   : PropTypes.bool,
        style    : PropTypes.string,
        align    : PropTypes.string,
        position : PropTypes.string,
        className: PropTypes.string,
    };
    /**
     *
     * @type {SiteModule}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 05/02/21 09:31 am
     */
    protected module: SiteModule;
    public readonly props: P;
    /**
     *
     * @type {Partial<IBaseState>}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 05/29/21 05:48 pm
     */
    public state: S;

    /**
     *
     * @return {Set<string>}
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 01/14/21 06:55 pm
     * @version 1.1.0 01/24/21 04:13 pm
     * converted the return type to a set (was string[])
     */
    protected static getSocials(): Set<string> {
        const socials: string     = process.env.REACT_APP_CONFIG_SOCIALS;
        const items: string[]     = socials.split( ',' );
        const result: Set<string> = new Set<string>();

        for( let i = 0, total: number = items.length; i < total; i++ ) {
            result.add( items[ i ] );
        }

        return result;
    }

    /**
     *
     * @return {string}
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 01/16/21 10:49 am documented
     */
    protected static getComponentClass(): string {
        return this.COMPONENT_CLASS;
    }

    /**
     *
     * @param {string} prefix
     * @param {string} id
     * @param {boolean} addBrackets
     * @return {string}
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 02/17/21 05:56 pm
     */
    public static getHashId( prefix: string, id: string, addBrackets?: boolean ): string {
        if( addBrackets ) {
            return `[${ prefix }]${ Util.capitalizeFirstLetter( id ?? Util.registerHash() ) }`;
        }

        return `${ prefix }${ Util.capitalizeFirstLetter( id ?? Util.registerHash() ) }`;
    }

    /**
     *
     * @return {string}
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/12/20 08:53 am
     */
    protected static get componentClass(): string {
        return this.COMPONENT_CLASS;
    }

    /**
     *
     * @param {Set<string>} platforms
     * @param {Socket} socket
     * @param options {object}
     * @return {JSX.Element[]}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 01/14/21 05:50 pm documented
     * @version 1.1.0 01/15/21 01:58 pm added support for options
     */
    protected buildClaimSocial( platforms?: Set<string>, socket?: Socket, options: Record<string, unknown> = {} ): JSX.Element[] {
        const buttons = ( providers: Set<string>, socket: Socket ): JSX.Element[] => {
            const button: Set<JSX.Element> = new Set<JSX.Element>();
            const props                    = ( provider: string ) => {
                return {
                    key: `oauth-${ this.registerHash() }`,
                    provider,
                    socket,
                    ...options,
                };
            };

            for( const provider of providers ) {
                button.add( <OAuth { ...props( provider ) } /> );
            }

            return [ ...button ];
        };

        return buttons( platforms ?? Base.getSocials(), socket ?? Base.SOCKET );
    }

    /**
     *
     * @return {boolean}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 01/30/21 04:22 pm
     * documented
     */
    protected isBuildReady(): boolean {
        try {
            //console.log( 'BBB base initialized', Base.INITIALIZED, 'session', store.session.get( 'base-initialized' ) );

            if( !isNode ) {
                if( !Base.INITIALIZED || !store.session.get( 'base-initialized' ) ) {
                    //this.registerShortcode();
                    this.registerStore();
                    Base.INITIALIZED = true;
                    store.session.set( 'base-initialized', true );
                }

                return store.session.get( 'base-initialized' );
            }

            return true;
        } catch( exception: unknown ) {
            console.log( 'base component exception', exception );
        }
    }

    /**
     *
     * @return {boolean}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 01/30/21 04:22 pm
     */
    protected isSiteADemo(): boolean {
        const defaultHostname: string = process.env.REACT_APP_SITE_DOMAIN;
        const sites: Set<string>      = new Set<string>( `${ process.env.REACT_APP_CONFIG_DEMO_PATH }`.split( ',' ) );
        const domain: string          = isNode ? this.module?.domain?.path ?? defaultHostname : window?.location?.hostname ?? this.module?.domain?.path ?? defaultHostname;

        console.log( '*** is site a demo', 'sites', [ ...sites ], 'has', sites.has( domain ) );

        return !URLManager.isLive || sites.has( domain );
    }

    /**
     *
     * @return {void}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 01/30/21 04:22 pm
     * documented
     */
    protected registerStore(): void {
        window[ 'store' ] = store;
    }

    /**
     *
     * @param {string[]} array
     * @return {string[]}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/09/20 06:18 pm
     * @see registerDuplicatesInArray
     * @see registerHash
     */
    protected registerDuplicatesInArray( array: string[] ): string[] {
        const duplicates: string[] = [];

        for( let i = 0, total: number = array.length; i < total; i++ ) {
            if( array.indexOf( array[ i ] ) != i && array.indexOf( array[ i ] ) != -1 ) {
                console.log( 'duplicate item ' + array[ i ] );
                duplicates.push( array[ i ] );
            }
        }

        return duplicates;
    }

    /**
     *
     * @param {number} length
     * @return {string}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/09/20 06:18 pm
     * @deprecated use <code>Util.registerHash</code>
     * @since 1.0.0 07/31/21 06:53 pm
     * @see registerDuplicatesInArray
     * @see registerHash
     */
    protected registerHash( length?: number ): string {
        return Util.registerHash( length );
    }

    /**
     *
     * @param base {Partial<IBaseProps>}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 01/30/21 04:22 pm - documented
     * @deprecated use <code>RegisterTool.style</code>
     * @since 1.0.0 06/06/21 10:56 am
     */
    protected registerStyle( base: Partial<IBaseProps> ): void;
    protected registerStyle( link: ILinkProps ): void;
    protected registerStyle( section: ISectionModule ): void;
    protected registerStyle( record: Record<string, never> ): void;
    protected registerStyle( data ): void {
        let style: string;

        if( data?.style ) {
            switch( data.style ) {
                case StyleEnum.Light:
                case StyleEnum.Grey:
                case StyleEnum.Dark:
                    style = data.style;
                    break;
                default:
                    style = StyleEnum.Light;
            }
        } else if( data?.light ) {
            style = StyleEnum.Light;
        } else if( data?.grey ) {
            style = StyleEnum.Grey;
        } else if( data?.dark ) {
            style = StyleEnum.Dark;
        } else {
            style = StyleEnum.Light;
        }

        this.state.className.add( style );
    }

    /**
     *
     * @param props {Partial<GalleryModule> | Partial<ImageModule>}
     * @author Isaac Ewing
     * @version 1.0.0 10/04/20 06:48 pm
     * @deprecated use <code>RegisterTool.align</code>
     * @since 1.0.0 07/31/21 07:56 pm
     */
    protected registerAlign( props: Partial<IGalleryModule> | Partial<IImageModule> ): void {
        let align: string;

        switch( props.halign ) {
            case HAlignEnum.Auto:
            case HAlignEnum.Left:
            case HAlignEnum.Center:
            case HAlignEnum.Right:
                align = props.halign;
                break;
            default:
                align = HAlignEnum.Center;
                break;
        }

        this.state.className.add( align );
    }

    /**
     *
     * @param props {Partial<GalleryModule> | Partial<ImageModule>}
     * @author Isaac Ewing
     * @version 1.0.0 10/04/20 06:48 pm
     * @deprecated use <code>RegisterTool.position</code>
     * @since 1.0.0 07/31/21 07:56 pm
     */
    protected registerPosition( props: Partial<IGalleryModule> | Partial<IImageModule> ): void {
        let align: string;

        switch( props.valign ) {
            case     VAlignEnum.Auto:
            case     VAlignEnum.Top:
            case     VAlignEnum.Middle:
            case     VAlignEnum.Bottom:
                align = props.valign;
                break;
            default:
                align = VAlignEnum.Top;
                break;
        }

        this.state.className.add( align );
    }

    /**
     *
     * @param {IBaseProps} base
     * @return {void}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/06/21 12:53 pm - documented
     * @deprecated use <code>RegisterTool.classes</code>
     * @since 1.0.0 06/06/21 10:56 am
     * @see registerClasses
     * @see registerSocial
     * @see registerChildren
     * @see registerPassword
     * @see registerCaps
     */
    protected registerClasses( base: Partial<IBaseProps> ): void;
    protected registerClasses( section: Partial<ISectionModule> ): void;
    protected registerClasses( record: Record<string, never> ): void;
    protected registerClasses( props: any ): void {
        if( props?.className ) {
            if( props.className instanceof Set || Array.isArray( props.className ) ) {
                props.className.forEach( ( name: string ): void => {
                    this.state.className.add( name );
                } );
            } else if( typeof props.className === 'string' ) {
                props.className.split( ' ' ).forEach( ( className: string ): void => {
                    this.state.className.add( className );
                } );
            } else {
                console.error( 'BASE TSX CLASSNAME IS UNKNOWN', { className: props.className, props } );
            }
        }
    }

    /**
     *
     * @param {IBaseProps} base
     * @return {void}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/06/21 12:53 pm - documented
     * @see registerClasses
     * @see registerSocial
     * @see registerChildren
     * @see registerPassword
     * @see registerCaps
     */
    protected registerSocial( base: Partial<IBaseProps> ): void;
    protected registerSocial( section: Partial<ISectionModule> ): void;
    protected registerSocial( record: Record<string, never> ): void;
    protected registerSocial( props: any ): void {
        if( props?.social ) {
            this.state.className.add( 'social' );
        }
    }

    /**
     *
     * @param props {any}
     * @return {void}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/06/21 12:53 pm - documented
     * @deprecated use <code>RegisterTool.children</code>
     * @since 1.0.0 07/31/21 08:18 pm
     * @see registerClasses
     * @see registerSocial
     * @see registerChildren
     * @see registerPassword
     * @see registerCaps
     */
    protected registerChildren( props: Partial<IBaseProps> ): void;
    protected registerChildren( section: Partial<ISectionModule> ): void;
    protected registerChildren( record: Record<string, never> ): void;
    protected registerChildren( props: any ): void {
        if( props?.children ) {
            props.children.forEach( ( child: JSX.Element ): void => {
                this.state.children.add( child );
            } );
        }
    }

    /**
     *
     * @param props {any}
     * @return {void}
     * @throws Throws an exception if method is called without having an override
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/24/20 01:24 pm
     * @deprecated use <code>RegisterTool.password</code>
     * @since 1.0.0 06/06/21 10:56 am
     * @see registerClasses
     * @see registerSocial
     * @see registerChildren
     * @see registerPassword
     * @see registerCaps
     * @see registerDisabled
     */
    protected registerPassword( props: ICharacterProps ): void {
        if( props?.password ) {
            this.state.className.add( Base.CLASSNAME_PASSWORD );
        }
    }

    /**
     *
     * @param props {any}
     * @return {void}
     * @throws Throws an exception if method is called without having an override
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/24/20 01:24 pm
     * @deprecated use <code>RegisterTool.caps</code>
     * @since 1.0.0 06/06/21 10:56 am
     * @see registerClasses
     * @see registerSocial
     * @see registerChildren
     * @see registerPassword
     * @see registerCaps
     * @see registerDisabled
     */
    protected registerCaps( props: ICharacterProps ): void {
        if( props?.caps ) {
            this.state.className.add( Base.CLASSNAME_CAPS );
        }
    }

    /**
     *
     * @param props {any}
     * @return {void}
     * @throws Throws an exception if method is called without having an override
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/24/20 02:12 pm
     * @deprecated use <code>RegisterTool.disabled</code>
     * @since 1.0.0 06/06/21 10:56 am
     * @see registerClasses
     * @see registerSocial
     * @see registerChildren
     * @see registerPassword
     * @see registerCaps
     * @see registerDisabled
     */
    protected registerDisabled( props: ICharacterProps ): void {
        if( props?.disabled ) {
            this.state.className.add( Base.CLASSNAME_DISABLED );
        }
    }

    /**
     *
     * @param props {any}
     * @return {void}
     * @throws Throws an exception if method is called without having an override
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/24/20 02:12 pm
     * @deprecated use <code>RegisterTool.animate</code>
     * @since 1.0.0 06/06/21 10:56 am
     * @see registerPassword
     * @see registerCaps
     * @see registerDisabled
     */
    protected registerAnimate( props: ICharacterProps ): void {
        if( props?.animate ) {
            this.state.className.add( Base.CLASSNAME_ANIMATE );
        }
    }

    /**
     *
     * @param props {any}
     * @return {void}
     * @throws Throws an exception if method is called without having an override
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/24/20 02:12 pm
     * @deprecated use <code>RegisterTool.fadeIn</code>
     * @since 1.0.0 06/06/21 10:56 am
     * @see registerPassword
     * @see registerCaps
     * @see registerDisabled
     */
    protected registerFadeIn( props: ICharacterProps ): void {
        if( props?.fadeIn ) {
            this.state.className.add( Base.CLASSNAME_FADE_IN );
        }
    }

    /**
     *
     * @param module {any}
     * @return {void}
     * @throws Throws an exception if method is called without having an override
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/24/20 01:24 pm
     */
    protected registerProps( module: SectionModule ): void;
    protected registerProps( modules: SectionModule[] ): void;
    protected registerProps( props: Partial<IBaseProps> ): void;
    protected registerProps( props: Partial<IBaseProps>[] ): void;
    protected registerProps( section: Partial<ISectionModule> ): void;
    protected registerProps( sections: Partial<ISectionModule>[] ): void;
    protected registerProps( record: Record<string, unknown> ): void;
    protected registerProps( records: Record<string, unknown>[] ): void;
    protected registerProps( propsOrModule: any ): void {
        if( propsOrModule ) {
            if( propsOrModule instanceof SectionModule ) {
                this.registerPropsCall( propsOrModule );
            } else if( propsOrModule?.module && propsOrModule?.page ) {
                this.registerPropsCall( propsOrModule.module );
            } else if( Array.isArray( propsOrModule ) || propsOrModule instanceof Set ) {
                propsOrModule.forEach( ( module: unknown ): void => {
                    this.registerPropsCall( module );
                } );
            } else if( typeof propsOrModule === 'object' ) {
                this.registerPropsCall( propsOrModule );
            }
        }
    }

    /**
     *
     * @param props {unknown}
     * @return {void}
     * @throws Throws an exception if method is called without having an override
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/24/20 01:31 pm
     */
    protected registerPropsCall( props?: Record<string, unknown> | unknown ): void {
        ClassError.overrideMethod();
        props ?? null;
    }

    /**
     * @return {void}
     * @deprecated since 1.1.0, use <code>registerOnChange</code>; waypoint was changed to ReactVisibilitySensor
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/10/21 05:36 pm
     * @version 1.1.0 02/23/21 02:20 pm -simplified and made this function obsolete
     */
    protected registerOnEnter(): void {
        if( !this.state.initialized ) {
            this.state.className.add( Base.CLASSNAME_ANIMATION );
            this.setState( { initialized: true } );
        }
    }

    /**
     * @return {void}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/10/21 05:36 pm
     */
    protected registerOnLeave(): void {
        this.setState( { action: false } );
    }

    /**
     *
     * @param {boolean} visible
     * @protected
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/10/21 05:36 pm
     */
    protected registerOnChange( visible: boolean ): void {
        if( visible && !this.state.initialized ) {
            this.state.className.add( Base.CLASSNAME_ANIMATION );
            this.setState( {
                               initialized     : true,
                               visibilitySensor: {
                                   active  : false,
                                   onChange: null,
                               },
                           } );
        }
    }

    /**
     *
     * @param exception
     * @return {void}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/10/21 05:36 pm
     */
    protected registerOnError( exception: Error ): void {
        console.trace( '==X== TITLE EXCEPTION', exception );
    }
}