import { AModule } from '../../abstract/module.abstract';
import { IModule } from '../../interface';

/**
 * @class ScriptModule
 * @extends AModule
 * @author Isaac Ewing
 * @version 1.0.0 12/09/20 01:41 pm
 * @version 2.0.0 05/30/21 08:11 pm - updated to extend AModule (was BaseModule)
 */
export class ScriptModule extends AModule implements IModule{
     /**
      * @param data {Object|null} The object containing the data
      * @return {ScriptModule|null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 02:09 pm
      */
     public static Build( data: unknown ): ScriptModule | null {
          if( data ) {
               return new ScriptModule( data );
          }

          return null;
     }

     /**
      *
      * @param data
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 02:09 pm
      */
     public constructor( data?: unknown ) {
          super( null );
     }

     /**
      *
      * @return {Record<string, unknown>}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/30/21 08:27 pm
      */
     public toObject(): Record<string, unknown> {
          return {
               id: null,
          };
     }
}