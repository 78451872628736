import { TitleModule }     from './title.module';
import { IAccount, IUser } from '../../interface';
import { AboutModule }     from './about.module';
import { AccountModule }   from './account.module';
import { CompanyModule }   from './company.module';
import { DomainModule }    from './domain.module';
import { SettingModule }   from './setting.module';
import { SetupModule }     from './setup.module';
import { StorageManager }  from '../../manager';

/**
 * @class UserModule
 * @extends TitleModule
 * @implements IUser
 * @author Isaac Ewing
 * @version 1.0.0 05/07/21 05:46 pm
 * @version 2.0.0 05/27/21 05:55 pm - converted from AComponent to TitleModule
 */
export class UserModule extends TitleModule implements IUser {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 06:23 pm
      */
     protected static readonly CONSOLE_PREFIX: string   = `${ process.env.REACT_APP_CONSOLE_PREFIX_MODULE } USER ${ process.env.REACT_APP_CONSOLE_SUFFIX_MODULE }`;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 06:23 pm
      */
     protected static readonly CONSOLE_ENABLED: boolean = false;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 06:23 pm
      */
     protected static readonly API_ROOT_NODE: string    = process.env.REACT_APP_GRAPHQL_GET_USER;
     /**
      *
      * @type {UserModule}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 06:23 pm
      */
     protected static fromStorage: UserModule;

     /**
      *
      * @param {AboutModule} account
      * @return {Set<AccountModule>}
      * @constructor
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/27/21 06:10 pm
      * @see id
      * @see username
      * @see email
      * @see token
      * @see setUsername
      * @see setEmail
      * @see setToken
      * @see toObject
      */
     protected static ParseAccounts( account: Partial<IAccount> ): Set<AccountModule>;
     protected static ParseAccounts( accounts: Partial<IAccount>[] ): Set<AccountModule>;
     protected static ParseAccounts( accounts: Set<Partial<IAccount>> ): Set<AccountModule>;
     protected static ParseAccounts( data?: unknown | Partial<IAccount> ): Set<AccountModule> {
          const localAccounts: Set<AccountModule> = new Set<AccountModule>();
          let tempAccount: AccountModule;

          if( data ) {
               if( Array.isArray( data ) || data instanceof Set ) {
                    data.forEach( ( account: Partial<IAccount> ): void => {
                         tempAccount = AccountModule.Build( account );

                         if( tempAccount ) {
                              localAccounts.add( tempAccount );
                         }
                    } );
               } else {
                    tempAccount = AccountModule.Build( data as Partial<IAccount> );

                    if( tempAccount ) {
                         localAccounts.add( tempAccount );
                    }
               }
          }

          return localAccounts;
     }

     /**
      *
      * @param module {UserModule} The object containing the data
      * @return {UserModule|null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/07/21 06:02 pm
      * @see id
      * @see username
      * @see email
      * @see token
      * @see setUsername
      * @see setEmail
      * @see setToken
      * @see toObject
      */
     public static Build( module: UserModule ): UserModule;
     public static Build( obj: Partial<IUser> ): UserModule;
     public static Build( json: string ): UserModule;
     public static Build( id?: number, username?: string, email?: string, about?: AboutModule, account?: AccountModule, domain?: DomainModule, setup?: SetupModule, setting?: SettingModule,
                          company?: CompanyModule, token?: string ): UserModule;
     public static Build( id?: number, username?: string, email?: string, about?: AboutModule, accounts?: AccountModule[], domain?: DomainModule, setup?: SetupModule, setting?: SettingModule,
                          company?: CompanyModule, token?: string ): UserModule;
     public static Build( id?: number, username?: string, email?: string, about?: AboutModule, accounts?: Set<AccountModule>, domain?: DomainModule, setup?: SetupModule, setting?: SettingModule,
                          company?: CompanyModule, token?: string ): UserModule;
     public static Build( dataOrId?: unknown, username?: string, email?: string, about?: AboutModule, accounts?: unknown, domain?: DomainModule, setup?: SetupModule, setting?: SettingModule,
                          company?: CompanyModule, token?: string ): UserModule {
          if( dataOrId ) {
               if( dataOrId instanceof UserModule ) {
                    return dataOrId;
               }
               if( typeof dataOrId === 'object' ) {
                    const localData: IUser = dataOrId as IUser;

                    return new UserModule( +localData?.id ?? null, localData?.username ?? null, localData?.email ?? null, AboutModule.Build( localData?.about ) ?? null,
                                           this.ParseAccounts( localData ) ?? null, DomainModule.Build( localData?.domain ) ?? null,
                                           SetupModule.Build( localData?.setup ) ?? null, SettingModule.Build( localData?.setting ) ?? null,
                                           CompanyModule.Build( localData?.company ) ?? null, localData?.token ?? null );
               }
               if( typeof dataOrId === 'number' ) {
                    return new UserModule( dataOrId ?? null, username ?? null, email ?? null, AboutModule.Build( about ) ?? null,
                                           this.ParseAccounts( accounts ) ?? null, DomainModule.Build( domain ) ?? null, SetupModule.Build( setup ) ?? null,
                                           SettingModule.Build( setting ) ?? null, CompanyModule.Build( company ) ?? null, token ?? null );
               }
               if( typeof dataOrId === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrId ) );
                    } catch( exception ) {
                         // not a valid json string
                         return new UserModule( +dataOrId ?? null, username ?? null, email ?? null, AboutModule.Build( about ) ?? null,
                                                this.ParseAccounts( accounts ) ?? null, DomainModule.Build( domain ) ?? null, SetupModule.Build( setup ) ?? null,
                                                SettingModule.Build( setting ) ?? null, CompanyModule.Build( company ) ?? null, token ?? null );
                    }
               }
          }

          return null;
     }

     /**
      *
      * @return {UserModule | null}
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 06:23 pm
      */
     public static FromStorage(): UserModule | null {
          if( this.fromStorage ) {
               return this.fromStorage;
          }
          if( StorageManager.userModel ) {
               this.fromStorage = UserModule.Build( StorageManager.userModel ?? null );
          } else if( StorageManager.userId && StorageManager.userUsername && StorageManager.userEmail && StorageManager.userToken ) {
               this.fromStorage = new UserModule( StorageManager.userId ?? null, StorageManager.userUsername ?? null, StorageManager.userEmail ?? null,
                                                  AboutModule.Build( StorageManager.userAbout ) ?? null, this.ParseAccounts( StorageManager.userAccounts ) ?? null,
                                                  DomainModule.Build( StorageManager.userDomain ) ?? null, SetupModule.Build( StorageManager.userSetup ) ?? null,
                                                  SettingModule.Build( StorageManager.userSetting ) ?? null, CompanyModule.Build( StorageManager.userCompany ) ?? null,
                                                  StorageManager.userToken ?? null );
          }

          return this.fromStorage ?? null;
     }

     /**
      *
      * @param {number} id
      * @param {string} username
      * @param {string} email
      * @param {AboutModule} about
      * @param {AccountModule} account
      * @param {DomainModule} domain
      * @param {SetupModule} setup
      * @param {SettingModule} setting
      * @param {CompanyModule} company
      * @param {string} token
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/07/21 05:46 pm
      * @version 1.1.0 05/25/21 02:13 pm - updated to reflect the user model in the database and passport version
      * @see id
      * @see username
      * @see email
      * @see token
      * @see setUsername
      * @see setEmail
      * @see setToken
      * @see store
      * @see toObject
      */
     public constructor( id?: number, username?: string, email?: string, about?: AboutModule, account?: AccountModule, domain?: DomainModule, setup?: SetupModule, setting?: SettingModule,
                         company?: CompanyModule, token?: string );
     public constructor( id?: number, username?: string, email?: string, about?: AboutModule, accounts?: AccountModule[], domain?: DomainModule, setup?: SetupModule, setting?: SettingModule,
                         company?: CompanyModule, token?: string ) ;
     public constructor( id?: number, username?: string, email?: string, about?: AboutModule, accounts?: Set<AccountModule>, domain?: DomainModule, setup?: SetupModule, setting?: SettingModule,
                         company?: CompanyModule, token?: string ) ;
     public constructor( id?: number, username?: string, email?: string, about?: AboutModule, accounts?: unknown, domain?: DomainModule, setup?: SetupModule, setting?: SettingModule,
                         company?: CompanyModule, token?: string ) {
          super( id ?? null );

          /*
           id: Int
           username: String
           email: String
           about:About
           accounts: [ Account ]
           domain: Domain
           setup: Setup
           setting: Setting
           company: Company
           jwt: String @isAuthUser
           */

          this.username = username ?? null;
          this.email    = email ?? null;
          this.about    = about ?? null;
          this.accounts = UserModule.ParseAccounts( accounts ?? null );
          this.domain   = domain ?? null;
          this.setup    = setup ?? null;
          this.setting  = setting ?? null;
          this.company  = company ?? null;
          this.token    = token ?? null;
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/07/21 06:02 pm
      * @see id
      * @see username
      * @see email
      * @see token
      * @see setUsername
      * @see setEmail
      * @see setToken
      * @see toObject
      */
     public get username(): string {
          return this._data.get( 'username' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/07/21 06:02 pm
      * @see id
      * @see username
      * @see email
      * @see token
      * @see setUsername
      * @see setEmail
      * @see setToken
      * @see toObject
      */
     public set username( value: string ) {
          this._data.set( 'username', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/07/21 06:02 pm
      * @see id
      * @see username
      * @see email
      * @see token
      * @see setUsername
      * @see setEmail
      * @see setToken
      * @see toObject
      */
     public get email(): string {
          return this._data.get( 'email' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/07/21 06:02 pm
      * @see id
      * @see username
      * @see email
      * @see token
      * @see setUsername
      * @see setEmail
      * @see setToken
      * @see toObject
      */
     public set email( value: string ) {
          this._data.set( 'email', value );
     }

     /**
      *
      * @return {AboutModule}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:17 pm
      * @see id
      * @see username
      * @see email
      * @see about
      * @see accounts
      * @see domain
      * @see setup
      * @see setting
      * @see company
      * @see token
      * @see setUsername
      * @see setEmail
      * @see setAbout
      * @see setAccounts
      * @see setDomain
      * @see setSetup
      * @see setSetting
      * @see setCompany
      * @see setToken
      * @see addAccount
      * @see store
      * @see toObject
      */
     public get about(): AboutModule {
          return this._data.get( 'about' );
     }

     /**
      *
      * @param {AboutModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:17 pm
      * @see id
      * @see username
      * @see email
      * @see about
      * @see accounts
      * @see domain
      * @see setup
      * @see setting
      * @see company
      * @see token
      * @see setUsername
      * @see setEmail
      * @see setAbout
      * @see setAccounts
      * @see setDomain
      * @see setSetup
      * @see setSetting
      * @see setCompany
      * @see setToken
      * @see addAccount
      * @see store
      * @see toObject
      */
     public set about( value: AboutModule ) {
          this._data.set( 'about', value );
     }

     /**
      *
      * @return {Set<AccountModule>}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:17 pm
      * @see id
      * @see username
      * @see email
      * @see about
      * @see accounts
      * @see domain
      * @see setup
      * @see setting
      * @see company
      * @see token
      * @see setUsername
      * @see setEmail
      * @see setAbout
      * @see setAccounts
      * @see setDomain
      * @see setSetup
      * @see setSetting
      * @see setCompany
      * @see setToken
      * @see addAccount
      * @see store
      * @see toObject
      */
     public get accounts(): Set<AccountModule> {
          return this._data.get( 'accounts' );
     }

     /**
      *
      * @param {Set<AccountModule>} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:17 pm
      * @see id
      * @see username
      * @see email
      * @see about
      * @see accounts
      * @see domain
      * @see setup
      * @see setting
      * @see company
      * @see token
      * @see setUsername
      * @see setEmail
      * @see setAbout
      * @see setAccounts
      * @see setDomain
      * @see setSetup
      * @see setSetting
      * @see setCompany
      * @see setToken
      * @see addAccount
      * @see store
      * @see toObject
      */
     public set accounts( value: Set<AccountModule> ) {
          this._data.set( 'accounts', value );
     }

     /**
      *
      * @return {DomainModule}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:17 pm
      * @see id
      * @see username
      * @see email
      * @see about
      * @see accounts
      * @see domain
      * @see setup
      * @see setting
      * @see company
      * @see token
      * @see setUsername
      * @see setEmail
      * @see setAbout
      * @see setAccounts
      * @see setDomain
      * @see setSetup
      * @see setSetting
      * @see setCompany
      * @see setToken
      * @see addAccount
      * @see store
      * @see toObject
      */
     public get domain(): DomainModule {
          return this._data.get( 'domain' );
     }

     /**
      *
      * @param {DomainModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:17 pm
      * @see id
      * @see username
      * @see email
      * @see about
      * @see accounts
      * @see domain
      * @see setup
      * @see setting
      * @see company
      * @see token
      * @see setUsername
      * @see setEmail
      * @see setAbout
      * @see setAccounts
      * @see setDomain
      * @see setSetup
      * @see setSetting
      * @see setCompany
      * @see setToken
      * @see addAccount
      * @see store
      * @see toObject
      */
     public set domain( value: DomainModule ) {
          this._data.set( 'domain', value );
     }

     /**
      *
      * @return {SetupModule}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:17 pm
      * @see id
      * @see username
      * @see email
      * @see about
      * @see accounts
      * @see domain
      * @see setup
      * @see setting
      * @see company
      * @see token
      * @see setUsername
      * @see setEmail
      * @see setAbout
      * @see setAccounts
      * @see setDomain
      * @see setSetup
      * @see setSetting
      * @see setCompany
      * @see setToken
      * @see addAccount
      * @see store
      * @see toObject
      */
     public get setup(): SetupModule {
          return this._data.get( 'setup' );
     }

     /**
      *
      * @param {SetupModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:17 pm
      * @see id
      * @see username
      * @see email
      * @see about
      * @see accounts
      * @see domain
      * @see setup
      * @see setting
      * @see company
      * @see token
      * @see setUsername
      * @see setEmail
      * @see setAbout
      * @see setAccounts
      * @see setDomain
      * @see setSetup
      * @see setSetting
      * @see setCompany
      * @see setToken
      * @see addAccount
      * @see store
      * @see toObject
      */
     public set setup( value: SetupModule ) {
          this._data.set( 'setup', value );
     }

     /**
      *
      * @return {SettingModule}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:17 pm
      * @see id
      * @see username
      * @see email
      * @see about
      * @see accounts
      * @see domain
      * @see setup
      * @see setting
      * @see company
      * @see token
      * @see setUsername
      * @see setEmail
      * @see setAbout
      * @see setAccounts
      * @see setDomain
      * @see setSetup
      * @see setSetting
      * @see setCompany
      * @see setToken
      * @see addAccount
      * @see store
      * @see toObject
      */
     public get setting(): SettingModule {
          return this._data.get( 'setting' );
     }

     /**
      *
      * @param {SettingModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:17 pm
      * @see id
      * @see username
      * @see email
      * @see about
      * @see accounts
      * @see domain
      * @see setup
      * @see setting
      * @see company
      * @see token
      * @see setUsername
      * @see setEmail
      * @see setAbout
      * @see setAccounts
      * @see setDomain
      * @see setSetup
      * @see setSetting
      * @see setCompany
      * @see setToken
      * @see addAccount
      * @see store
      * @see toObject
      */
     public set setting( value: SettingModule ) {
          this._data.set( 'setting', value );
     }

     /**
      *
      * @return {CompanyModule}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:17 pm
      * @see id
      * @see username
      * @see email
      * @see about
      * @see accounts
      * @see domain
      * @see setup
      * @see setting
      * @see company
      * @see token
      * @see setUsername
      * @see setEmail
      * @see setAbout
      * @see setAccounts
      * @see setDomain
      * @see setSetup
      * @see setSetting
      * @see setCompany
      * @see setToken
      * @see addAccount
      * @see store
      * @see toObject
      */
     public get company(): CompanyModule {
          return this._data.get( 'company' );
     }

     /**
      *
      * @param {CompanyModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:17 pm
      * @see id
      * @see username
      * @see email
      * @see about
      * @see accounts
      * @see domain
      * @see setup
      * @see setting
      * @see company
      * @see token
      * @see setUsername
      * @see setEmail
      * @see setAbout
      * @see setAccounts
      * @see setDomain
      * @see setSetup
      * @see setSetting
      * @see setCompany
      * @see setToken
      * @see addAccount
      * @see store
      * @see toObject
      */
     public set company( value: CompanyModule ) {
          this._data.set( 'company', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/07/21 06:02 pm
      * @see id
      * @see username
      * @see email
      * @see token
      * @see setUsername
      * @see setEmail
      * @see setToken
      * @see toObject
      */
     public get token(): string {
          return this._data.get( 'token' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/07/21 06:02 pm
      * @see id
      * @see username
      * @see email
      * @see token
      * @see setUsername
      * @see setEmail
      * @see setToken
      * @see toObject
      */
     public set token( value: string ) {
          this._data.set( 'token', value );
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/07/21 06:02 pm
      * @see id
      * @see username
      * @see email
      * @see token
      * @see setUsername
      * @see setEmail
      * @see setToken
      * @see toObject
      */
     public setUsername( value: string ): this {
          this.username = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/07/21 06:02 pm
      * @see id
      * @see username
      * @see email
      * @see token
      * @see setUsername
      * @see setEmail
      * @see setToken
      * @see toObject
      */
     public setEmail( value: string ): this {
          this.email = value;

          return this;
     }

     /**
      *
      * @param {AboutModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 03:43 pm
      * @see id
      * @see username
      * @see email
      * @see about
      * @see accounts
      * @see domain
      * @see setup
      * @see setting
      * @see company
      * @see token
      * @see setId
      * @see setUsername
      * @see setEmail
      * @see setAbout
      * @see setAccounts
      * @see setDomain
      * @see setSetup
      * @see setSetting
      * @see setCompany
      * @see setToken
      * @see addAccount
      * @see store
      * @see toObject
      */
     public setAbout( value: AboutModule ): this {
          this.about = value;

          return this;
     }

     /**
      *
      * @param {Set<AccountModule>} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 03:43 pm
      * @see id
      * @see username
      * @see email
      * @see about
      * @see accounts
      * @see domain
      * @see setup
      * @see setting
      * @see company
      * @see token
      * @see setId
      * @see setUsername
      * @see setEmail
      * @see setAbout
      * @see setAccounts
      * @see setDomain
      * @see setSetup
      * @see setSetting
      * @see setCompany
      * @see setToken
      * @see addAccount
      * @see store
      * @see toObject
      */
     public setAccounts( value: Set<AccountModule> ): this {
          this.accounts = value;

          return this;
     }

     /**
      *
      * @param {DomainModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 03:43 pm
      * @see id
      * @see username
      * @see email
      * @see about
      * @see accounts
      * @see domain
      * @see setup
      * @see setting
      * @see company
      * @see token
      * @see setId
      * @see setUsername
      * @see setEmail
      * @see setAbout
      * @see setAccounts
      * @see setDomain
      * @see setSetup
      * @see setSetting
      * @see setCompany
      * @see setToken
      * @see addAccount
      * @see store
      * @see toObject
      */
     public setDomain( value: DomainModule ): this {
          this.domain = value;

          return this;
     }

     /**
      *
      * @param {SetupModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 03:43 pm
      * @see id
      * @see username
      * @see email
      * @see about
      * @see accounts
      * @see domain
      * @see setup
      * @see setting
      * @see company
      * @see token
      * @see setId
      * @see setUsername
      * @see setEmail
      * @see setAbout
      * @see setAccounts
      * @see setDomain
      * @see setSetup
      * @see setSetting
      * @see setCompany
      * @see setToken
      * @see addAccount
      * @see store
      * @see toObject
      */
     public setSetup( value: SetupModule ): this {
          this.setup = value;

          return this;
     }

     /**
      *
      * @param {SettingModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 03:43 pm
      * @see id
      * @see username
      * @see email
      * @see about
      * @see accounts
      * @see domain
      * @see setup
      * @see setting
      * @see company
      * @see token
      * @see setId
      * @see setUsername
      * @see setEmail
      * @see setAbout
      * @see setAccounts
      * @see setDomain
      * @see setSetup
      * @see setSetting
      * @see setCompany
      * @see setToken
      * @see addAccount
      * @see store
      * @see toObject
      */
     public setSetting( value: SettingModule ): this {
          this.setting = value;

          return this;
     }

     /**
      *
      * @param {CompanyModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 03:43 pm
      * @see id
      * @see username
      * @see email
      * @see about
      * @see accounts
      * @see domain
      * @see setup
      * @see setting
      * @see company
      * @see token
      * @see setId
      * @see setUsername
      * @see setEmail
      * @see setAbout
      * @see setAccounts
      * @see setDomain
      * @see setSetup
      * @see setSetting
      * @see setCompany
      * @see setToken
      * @see addAccount
      * @see store
      * @see toObject
      */
     public setCompany( value: CompanyModule ): this {
          this.company = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/07/21 06:02 pm
      * @see id
      * @see username
      * @see email
      * @see token
      * @see setUsername
      * @see setEmail
      * @see setToken
      * @see toObject
      */
     public setToken( value: string ): this {
          this.token = value;

          return this;
     }

     /**
      *
      * @param {AccountModule} account
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 03:43 pm
      * @see id
      * @see username
      * @see email
      * @see about
      * @see accounts
      * @see domain
      * @see setup
      * @see setting
      * @see company
      * @see token
      * @see setId
      * @see setUsername
      * @see setEmail
      * @see setAbout
      * @see setAccounts
      * @see setDomain
      * @see setSetup
      * @see setSetting
      * @see setCompany
      * @see setToken
      * @see addAccount
      * @see store
      * @see toObject
      */
     public addAccount( account: AccountModule ): this {
          this.accounts.add( account );

          return this;
     }

     /**
      *
      * @param {boolean} primitive
      * @return {object[] | AccountModule[]}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 05:09 pm
      * @see id
      * @see username
      * @see email
      * @see token
      * @see setUsername
      * @see setEmail
      * @see setToken
      * @see toObject
      */
     protected accountsToArray( primitive?: boolean ): Record<string, unknown>[] | AccountModule[] {
          if( primitive ) {
               const temp: Record<string, unknown>[] = [];

               this.accounts.forEach( ( account: AccountModule ): number => temp.push( account.toObject( true ) ) );

               return temp;
          }

          return [ ...this.accounts ];
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/09/21 03:55 pm
      * @see id
      * @see username
      * @see email
      * @see token
      * @see setUsername
      * @see setEmail
      * @see setToken
      * @see toObject
      */
     public store(): this {
          StorageManager.userModel    = this;
          StorageManager.userId       = this.id;
          StorageManager.userUsername = this.username;
          StorageManager.userEmail    = this.email;
          StorageManager.userAbout    = this.about;
          StorageManager.userAccounts = this.accounts;
          StorageManager.userDomain   = this.domain;
          StorageManager.userSetup    = this.setup;
          StorageManager.userSetting  = this.setting;
          StorageManager.userCompany  = this.company;
          StorageManager.userToken    = this.token;

          return this;
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/07/21 05:46 pm
      * @see id
      * @see username
      * @see email
      * @see token
      * @see setUsername
      * @see setEmail
      * @see setToken
      * @see toObject
      */
     public toObject(): Record<string, unknown> {
          return {
               id      : this.id,
               username: this.username,
               email   : this.email,
               about   : this.about.toObject(),
               accounts: this.accountsToArray( true ),
               domain  : this.domain.toObject(),
               setup   : this.setup.toObject(),
               setting : this.setting.toObject(),
               company : this.company.toObject(),
               token   : this.token,
          };
     }
}