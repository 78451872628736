import { IDescription, ITitle } from '../../interface';
import { TitleModule }          from './title.module';

/**
 * @class DescriptionModule
 * @extends TitleModule
 * @implements IDescription
 * @author Isaac Ewing
 * @version 1.0.0 05/27/21 12:09 pm
 */
export class DescriptionModule extends TitleModule implements IDescription {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/27/21 12:09 pm
      */
     protected static readonly CONSOLE_PREFIX: string   = `${ process.env.REACT_APP_CONSOLE_PREFIX_MODULE } DESC ${ process.env.REACT_APP_CONSOLE_SUFFIX_MODULE }`;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/27/21 12:09 pm
      */
     protected static readonly CONSOLE_ENABLED: boolean = false;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/27/21 12:09 pm
      */
     protected static readonly API_ROOT_NODE: string    = process.env.REACT_APP_GRAPHQL_GET_DESCRIPTION;
     /**
      *
      * @type {DescriptionModule}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/27/21 12:09 pm
      */
     protected static fromStorage: DescriptionModule;

     /**
      *
      * @param module {DescriptionModule} The object containing the data
      * @return {DescriptionModule | null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/27/21 12:09 pm
      * @see Build
      * @see id
      * @see text
      * @see setText
      * @see store
      * @see toObject
      */
     public static Build( module: DescriptionModule ): DescriptionModule;
     public static Build( obj: ITitle ): DescriptionModule;
     public static Build( json: string ): DescriptionModule;
     public static Build( id?: number, text?: string ): DescriptionModule;
     public static Build( dataOrId?: unknown, text?: string ): DescriptionModule {
          if( dataOrId ) {
               dataOrId = this.mapAPIRootNode( dataOrId, this.API_ROOT_NODE );

               if( dataOrId instanceof DescriptionModule ) {
                    return dataOrId;
               }
               if( dataOrId instanceof TitleModule ) {
                    return new DescriptionModule( +dataOrId.id ?? null, dataOrId.text ?? null );
               }
               if( typeof dataOrId === 'object' ) {
                    const localData: ITitle = dataOrId as ITitle;

                    return new DescriptionModule( +localData?.id ?? null, localData?.text ?? null );
               }
               if( typeof dataOrId === 'number' ) {
                    return new DescriptionModule( dataOrId ?? null, text ?? null );
               }
               if( typeof dataOrId === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrId ) );
                    } catch( exception ) {
                         // not a valid json string
                         return new DescriptionModule( +dataOrId ?? null, text ?? null );
                    }
               }
          }

          return null;
     }
}