/**
 *
 * @enum RegisterStepEnum
 * @author Isaac Ewing
 * @version 1.0.0 01/19/21 09:29 am
 */
export enum RegisterStepEnum {
     Platform = 'platform',
     Expand   = 'expand',
     Start    = 'start',
     Finish   = 'finish'
}