import React                       from 'react';
import { SectionModule }           from '../../../api/com/ewing/social/component';
import { ISectionProps }           from '../../../api/com/ewing/social/interface';
import { ConsoleManager }          from '../../../api/com/ewing/social/manager';
import { SiteModule, TitleModule } from '../../../api/com/ewing/social/module';
import { BaseSection }             from '../../atom/BaseSection';
import { Subtitle }                from '../../atom/Subtitle';
import { Text }                    from '../../atom/invite';
import { Title }                   from '../../atom/Title';
import { Register }                from './Register';

/**
 * @class Invite1
 * @extends BaseSection
 * @author Isaac Ewing
 * @version 1.0.0 01/30/21 11:30 am - documented
 */
export class Invite1 extends BaseSection {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 05:37 pm
      */
     protected static readonly CONSOLE_PREFIX: string   = `${ process.env.REACT_APP_CONSOLE_PREFIX_COMPONENT } INVI ${ process.env.REACT_APP_CONSOLE_SUFFIX_COMPONENT }`;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 05:37 pm
      */
     protected static readonly CONSOLE_ENABLED: boolean = true;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am - documented
      */
     protected static readonly COMPONENT_CLASS: string  = 'invite-1';
     /**
      *
      * @type {ISectionProps}
      * @readonly
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 07:46 pm
      */
     public readonly props: ISectionProps;

     /**
      *
      * @param props {any}
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am - documented
      */
     public constructor( props: ISectionProps ) {
          super( props );

          this.state = {
               invite   : props.page?.invite ?? null,
               className: new Set<string>( [ 'grid-x', Invite1.COMPONENT_CLASS ] ),
          };

          if( Invite1.CONSOLE_ENABLED ) {
               ConsoleManager.Log( Invite1.CONSOLE_PREFIX ?? null, 'INVITE 1', 'finished setting state...', { props, module: props.module } );
          }

          this.registerProps( props.module ?? null );
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/24/20 01:24 pm
      */
     public render(): JSX.Element {
          let title: JSX.Element;
          let subtitle: JSX.Element;

          if( this.props.module?.title ) {
               title = <Title key={ this.registerHash() } module={ this.props.module.title as TitleModule }  />;
          }
          if( this.state.module?.subtitle ) {
               subtitle = <Subtitle key={ this.registerHash() } module={ this.props.module.subtitle as TitleModule } />;
          }

          return (
               <section { ...this.registerSectionProps( this.props.module as SectionModule ) }>
                    <div className="grid-container content">
                         <div className="grid-x align-left">
                              <div className="cell small-12 medium-10 medium-offset-1">
                                   { title }
                                   { subtitle }
                              </div>
                              <div className="cell small-12 medium-10 medium-offset-1">
                                   <Text text={ this.state?.invite?.code ?? null } length={ 6 } label="invitation code" caps animate fadeIn />
                              </div>
                              <div className="cell small-12 medium-12">
                                   <Register module={ this.props.module as SectionModule } page={ this.props.page as SiteModule } />
                              </div>
                         </div>
                    </div>
               </section>
          );
     }
}
