import { ColorEnum } from '../enum';

/**
 * @class ConsoleManager
 * @author Isaac Ewing
 * @version 1.0.0 06/02/21 02:12 pm
 */
export class ConsoleManager {
    /**
     *
     * @param {ColorEnum} color
     * @param {ColorEnum} background
     * @param {string} prefix
     * @param {string} title
     * @param {string} message
     * @param data
     * @constructor
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 06/02/21 02:32 pm
     */
    protected static TextCustomBackgroundCustom( color: ColorEnum, background: ColorEnum, prefix: string, title: string, message?: string, data?: unknown ): void {
        console.log( `%c ${ prefix } ${ title.toUpperCase() }`,
                     `color: ${ color }; background-color: ${ background }`,
                     `  ${ message ?? null }${ message ? '...' : '' }`, data ? { data: data ?? null } : '' );
    }

    /**
     *
     * @param {ColorEnum} background
     * @param {string} prefix
     * @param {string} title
     * @param {string} message
     * @param data
     * @constructor
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 06/02/21 02:32 pm
     * @see BrightGreen
     * @see BrightRed
     * @see BrightYellow
     * @see DarkBlue
     * @see DarkGrey
     * @see BrightYellow
     * @see DarkRed
     * @see DarkYellow
     * @see Gold
     * @see LightGrey
     * @see TealBlue
     */
    protected static TextWhiteBackgroundCustom( background: ColorEnum, prefix: string, title: string, message?: string, data?: unknown ): void {
        this.TextCustomBackgroundCustom( ColorEnum.White, background, prefix, title, message, data );
    }

    /**
     *
     * @param {ColorEnum} background
     * @param {string} prefix
     * @param {string} title
     * @param {string} message
     * @param data
     * @constructor
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 07/25/21 09:21 pm
     * @see BrightGreen
     * @see BrightRed
     * @see BrightYellow
     * @see DarkBlue
     * @see DarkGrey
     * @see BrightYellow
     * @see DarkRed
     * @see DarkYellow
     * @see Gold
     * @see LightGrey
     * @see TealBlue
     */
    protected static TextBlackBackgroundCustom( background: ColorEnum, prefix: string, title: string, message?: string, data?: unknown ): void {
        this.TextCustomBackgroundCustom( ColorEnum.Black, background, prefix, title, message, data );
    }

    /**
     *
     * @param prefix {string}
     * @param title {string}
     * @param message {string} [default=null]
     * @param data {any} [default=null]
     * @constructor
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 06/02/21 02:12 pm
     * @see BrightGreen
     * @see BrightRed
     * @see BrightYellow
     * @see DarkBlue
     * @see DarkGrey
     * @see BrightYellow
     * @see DarkRed
     * @see DarkYellow
     * @see Gold
     * @see LightGrey
     * @see TealBlue
     */
    public static BrightGreen( prefix: string, title: string, message?: string, data?: unknown ): void {
        this.TextWhiteBackgroundCustom( ColorEnum.BrightGreen, prefix, title, message, data );
    }

    /**
     *
     * @param prefix {string}
     * @param title {string}
     * @param message {string} [default=null]
     * @param data {any} [default=null]
     * @constructor
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 06/02/21 02:12 pm
     * @see BrightGreen
     * @see BrightRed
     * @see BrightYellow
     * @see DarkBlue
     * @see DarkGrey
     * @see BrightYellow
     * @see DarkRed
     * @see DarkYellow
     * @see Gold
     * @see LightGrey
     * @see TealBlue
     */
    public static BrightRed( prefix: string, title: string, message?: string, data?: unknown ): void {
        this.TextWhiteBackgroundCustom( ColorEnum.BrightRed, prefix, title, message, data );
    }

    /**
     *
     * @param prefix {string}
     * @param title {string}
     * @param message {string} [default=null]
     * @param data {any} [default=null]
     * @constructor
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 06/02/21 02:12 pm
     * @see BrightGreen
     * @see BrightRed
     * @see BrightYellow
     * @see DarkBlue
     * @see DarkGrey
     * @see BrightYellow
     * @see DarkRed
     * @see DarkYellow
     * @see Gold
     * @see LightGrey
     * @see TealBlue
     */
    public static BrightYellow( prefix: string, title: string, message?: string, data?: unknown ): void {
        this.TextBlackBackgroundCustom( ColorEnum.BrightYellow, prefix, title, message, data );
    }

    /**
     *
     * @param prefix {string}
     * @param title {string}
     * @param message {string} [default=null]
     * @param data {any} [default=null]
     * @constructor
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 06/02/21 02:12 pm
     * @see BrightGreen
     * @see BrightRed
     * @see BrightYellow
     * @see DarkBlue
     * @see DarkGrey
     * @see BrightYellow
     * @see DarkRed
     * @see DarkYellow
     * @see Gold
     * @see LightGrey
     * @see TealBlue
     */
    public static DarkBlue( prefix: string, title: string, message?: string, data?: unknown ): void {
        this.TextWhiteBackgroundCustom( ColorEnum.DarkBlue, prefix, title, message, data );
    }

    /**
     *
     * @param prefix {string}
     * @param title {string}
     * @param message {string} [default=null]
     * @param data {any} [default=null]
     * @constructor
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 06/02/21 02:12 pm
     * @see BrightGreen
     * @see BrightRed
     * @see BrightYellow
     * @see DarkBlue
     * @see DarkGrey
     * @see BrightYellow
     * @see DarkRed
     * @see DarkYellow
     * @see Gold
     * @see LightGrey
     * @see TealBlue
     */
    public static DarkGrey( prefix: string, title: string, message?: string, data?: unknown ): void {
        this.TextWhiteBackgroundCustom( ColorEnum.DarkGrey, prefix, title, message, data );
    }

    /**
     *
     * @param prefix {string}
     * @param title {string}
     * @param message {string} [default=null]
     * @param data {any} [default=null]
     * @constructor
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 06/02/21 02:12 pm
     * @see BrightGreen
     * @see BrightRed
     * @see BrightYellow
     * @see DarkBlue
     * @see DarkGrey
     * @see BrightYellow
     * @see DarkRed
     * @see DarkYellow
     * @see Gold
     * @see LightGrey
     * @see TealBlue
     */
    public static DarkRed( prefix: string, title: string, message?: string, data?: unknown ): void {
        this.TextWhiteBackgroundCustom( ColorEnum.DarkRed, prefix, title, message, data );
    }

    /**
     *
     * @param prefix {string}
     * @param title {string}
     * @param message {string} [default=null]
     * @param data {any} [default=null]
     * @constructor
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 06/02/21 02:12 pm
     * @see BrightGreen
     * @see BrightRed
     * @see BrightYellow
     * @see DarkBlue
     * @see DarkGrey
     * @see BrightYellow
     * @see DarkRed
     * @see DarkYellow
     * @see Gold
     * @see LightGrey
     * @see TealBlue
     */
    public static DarkYellow( prefix: string, title: string, message?: string, data?: unknown ): void {
        this.TextWhiteBackgroundCustom( ColorEnum.DarkYellow, prefix, title, message, data );
    }

    /**
     *
     * @param prefix {string}
     * @param title {string}
     * @param message {string} [default=null]
     * @param data {any} [default=null]
     * @constructor
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 06/02/21 05:57 pm
     * @see BrightGreen
     * @see BrightRed
     * @see BrightYellow
     * @see DarkBlue
     * @see DarkGrey
     * @see BrightYellow
     * @see DarkRed
     * @see DarkYellow
     * @see Gold
     * @see LightGrey
     * @see TealBlue
     */
    public static Gold( prefix: string, title: string, message?: string, data?: unknown ): void {
        this.TextWhiteBackgroundCustom( ColorEnum.Gold, prefix, title, message, data );
    }

    /**
     *
     * @param prefix {string}
     * @param title {string}
     * @param message {string} [default=null]
     * @param data {any} [default=null]
     * @constructor
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 06/02/21 05:57 pm
     * @version 1.1.0 08/03/21 06:50 pm - added support for overloaded functions for inline testing of enabled inline property setting
     * @see BrightGreen
     * @see BrightRed
     * @see BrightYellow
     * @see DarkBlue
     * @see DarkGrey
     * @see BrightYellow
     * @see DarkRed
     * @see DarkYellow
     * @see Gold
     * @see LightGrey
     * @see TealBlue
     */
    public static LightGrey( prefix: string, title: string, message?: string, data?: unknown ): void;
    public static LightGrey( enabled: boolean, prefix: string, title: string, message?: string, data?: unknown ): void;
    public static LightGrey( enabledOrPrefix: unknown, prefix: string, title: string, message?: string, data?: unknown ): void {
        if( enabledOrPrefix === true ) {
            this.TextWhiteBackgroundCustom( ColorEnum.LightGrey, prefix, title, message, data );
        } else if( typeof enabledOrPrefix === 'string' ) {
            this.TextWhiteBackgroundCustom( ColorEnum.LightGrey, enabledOrPrefix, prefix, title, message );
        }
    }

    /**
     *
     * @param prefix {string}
     * @param title {string}
     * @param message {string} [default=null]
     * @param data {any} [default=null]
     * @constructor
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 06/02/21 02:12 pm
     * @see BrightGreen
     * @see BrightRed
     * @see BrightYellow
     * @see DarkBlue
     * @see DarkGrey
     * @see BrightYellow
     * @see DarkRed
     * @see DarkYellow
     * @see Gold
     * @see LightGrey
     * @see TealBlue
     */
    public static TealBlue( prefix: string, title: string, message?: string, data?: unknown ): void {
        this.TextWhiteBackgroundCustom( ColorEnum.TealBlue, prefix, title, message, data );
    }

    /**
     *
     * @param prefix {string}
     * @param title {string}
     * @param message {string} [default=null]
     * @param data {any} [default=null]
     * @constructor
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 06/02/21 08:35 pm
     * @version 1.1.0 06/10/21 09:18 pm - added support for overloaded functions for inline testing of enabled inline property setting
     * @see BrightGreen
     * @see BrightRed
     * @see BrightYellow
     * @see DarkBlue
     * @see DarkGrey
     * @see BrightYellow
     * @see DarkRed
     * @see DarkYellow
     * @see Gold
     * @see LightGrey
     * @see TealBlue
     * @see Log
     * @see Info
     * @see Warn
     * @see Error
     */
    public static Log( prefix: string, title: string, message?: string, data?: unknown ): void;
    public static Log( enabled: boolean, prefix: string, title: string, message?: string, data?: unknown ): void;
    public static Log( enabledOrPrefix: unknown, prefix: string, title: string, message?: string, data?: unknown ): void {
        if( enabledOrPrefix === true ) {
            console.log( `${ prefix } ${ title.toUpperCase() }`, `  ${ message ?? null }${ message ? '...' : '' }`, { data: data ?? null } );
        } else if( typeof enabledOrPrefix === 'string' ) {
            console.log( `${ enabledOrPrefix } ${ prefix.toUpperCase() }`, `  ${ title ?? null }${ title ? '...' : '' }`, { data: message ?? null } );
        }
    }

    /**
     *
     * @param prefix {string}
     * @param title {string}
     * @param message {string} [default=null]
     * @param data {any} [default=null]
     * @constructor
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 06/02/21 08:35 pm
     * @version 1.1.0 06/10/21 09:18 pm - added support for overloaded functions for inline testing of enabled inline property setting
     * @see BrightGreen
     * @see BrightRed
     * @see BrightYellow
     * @see DarkBlue
     * @see DarkGrey
     * @see BrightYellow
     * @see DarkRed
     * @see DarkYellow
     * @see Gold
     * @see LightGrey
     * @see TealBlue
     * @see Log
     * @see Info
     * @see Warn
     * @see Error
     */
    public static Info( prefix: string, title: string, message?: string, data?: unknown ): void;
    public static Info( enabled: boolean, prefix: string, title: string, message?: string, data?: unknown ): void;
    public static Info( enabledOrPrefix: unknown, prefix: string, title: string, message?: string, data?: unknown ): void {
        if( enabledOrPrefix === true ) {
            console.info( `${ prefix } ${ title.toUpperCase() }`, `  ${ message ?? null }${ message ? '...' : '' }`, { data: data ?? null } );
        } else if( typeof enabledOrPrefix === 'string' ) {
            console.info( `${ enabledOrPrefix } ${ prefix.toUpperCase() }`, `  ${ title ?? null }${ title ? '...' : '' }`, { data: message ?? null } );
        }
    }

    /**
     *
     * @param prefix {string}
     * @param title {string}
     * @param message {string} [default=null]
     * @param data {any} [default=null]
     * @constructor
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 06/02/21 08:35 pm
     * @version 1.1.0 06/10/21 09:18 pm - added support for overloaded functions for inline testing of enabled inline property setting
     * @see BrightGreen
     * @see BrightRed
     * @see BrightYellow
     * @see DarkBlue
     * @see DarkGrey
     * @see BrightYellow
     * @see DarkRed
     * @see DarkYellow
     * @see Gold
     * @see LightGrey
     * @see TealBlue
     * @see Log
     * @see Info
     * @see Warn
     * @see Error
     */
    public static Warn( prefix: string, title: string, message?: string, data?: unknown ): void;
    public static Warn( enabled: boolean, prefix: string, title: string, message?: string, data?: unknown ): void;
    public static Warn( enabledOrPrefix: unknown, prefix: string, title: string, message?: string, data?: unknown ): void {
        if( enabledOrPrefix === true ) {
            console.warn( `${ prefix } ${ title.toUpperCase() }`, `  ${ message ?? null }${ message ? '...' : '' }`, { data: data ?? null } );
        } else if( typeof enabledOrPrefix === 'string' ) {
            console.warn( `${ enabledOrPrefix } ${ prefix.toUpperCase() }`, `  ${ title ?? null }${ title ? '...' : '' }`, { data: message ?? null } );
        }
    }

    /**
     *
     * @param prefix {string}
     * @param title {string}
     * @param message {string} [default=null]
     * @param data {any} [default=null]
     * @constructor
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 06/02/21 08:35 pm
     * @version 1.1.0 06/10/21 09:18 pm - added support for overloaded functions for inline testing of enabled inline property setting
     * @see BrightGreen
     * @see BrightRed
     * @see BrightYellow
     * @see DarkBlue
     * @see DarkGrey
     * @see BrightYellow
     * @see DarkRed
     * @see DarkYellow
     * @see Gold
     * @see LightGrey
     * @see TealBlue
     * @see Log
     * @see Info
     * @see Warn
     * @see Error
     */
    public static Error( prefix: string, title: string, message?: string, data?: unknown ): void;
    public static Error( enabled: boolean, prefix: string, title: string, message?: string, data?: unknown ): void;
    public static Error( enabledOrPrefix: unknown, prefix: string, title: string, message?: string, data?: unknown ): void {
        if( enabledOrPrefix === true ) {
            console.error( `${ prefix } ${ title.toUpperCase() }`, `  ${ message ?? null }${ message ? '...' : '' }`, { data: data ?? null } );
        } else if( typeof enabledOrPrefix === 'string' ) {
            console.error( `${ enabledOrPrefix } ${ prefix.toUpperCase() }`, `  ${ title ?? null }${ title ? '...' : '' }`, { data: message ?? null } );
        }
    }
}