import { AComponent }   from '../../abstract';
import { IInputModule } from '../../interface';

/**
 * @class InputModule
 * @extends AComponent
 * @implements IInputModule
 * @author Isaac Ewing
 * @version 1.0.0 02/14/21 12:04 pm
 * @version 1.1.0 02/18/21 09:37 pm - updated to extend AComponent
 * @classdesc This generic class handles the storage of input data from input fields.
 */
export class InputModule extends AComponent implements IInputModule {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 03/18/21 05:14 pm
      */
     protected static readonly DEFAULT_AFFILIATE_ID: string          = process.env.REACT_APP_INPUT_AFFILIATE_ID ?? 'affiliate';
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 03/18/21 05:14 pm
      */
     protected static readonly DEFAULT_AFFILIATE_PLACEHOLDER: string = process.env.REACT_APP_INPUT_AFFILIATE_PLACEHOLDER ?? '';
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 03/18/21 05:14 pm
      */
     protected static readonly DEFAULT_SPONSOR_ID: string            = process.env.REACT_APP_INPUT_SPONSOR_ID ?? 'sponsor';
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 03/18/21 05:14 pm
      */
     protected static readonly DEFAULT_SPONSOR_PLACEHOLDER: string   = process.env.REACT_APP_INPUT_SPONSOR_PLACEHOLDER ?? '';
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/14/21 12:25 pm
      */
     protected static readonly DEFAULT_IS_BLANK: boolean             = false;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/14/21 12:25 pm
      */
     protected static readonly DEFAULT_IS_ERROR: boolean             = false;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/26/21 02:44 pm
      */
     protected static readonly DEFAULT_CLASS_MESSAGE: string         = 'input-message';

     /**
      *
      * @param {Partial<IInputModule>} defaults
      * @param {object} obj
      * @return {InputModule}
      * @constructor
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/14/21 01:37 pm
      * @see BuildForName
      * @see BuildForEmail
      * @see BuildForMessage
      * @see BuildForAffiliate
      * @see BuildForSponsor
      * @see id
      * @see path
      * @see placeholder
      * @see isBlank
      * @see isError
      * @see doesPass
      * @see doesFail
      * @see setPath
      * @see setPlaceholder
      * @see setIsBlank
      * @see setIsError
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     protected static BuildForTemplate( defaults: Partial<IInputModule>, obj: Partial<IInputModule> ): InputModule;
     protected static BuildForTemplate( defaults: Partial<IInputModule>, id?: string, placeholder?: string ): InputModule;
     protected static BuildForTemplate( defaults: Partial<IInputModule>, dataOrId?: unknown, className?: string, placeholder?: string ): InputModule {
          if( dataOrId ) {
               if( typeof dataOrId === 'object' ) {
                    const localData: Partial<IInputModule> = dataOrId;
                    let localClassName: unknown            = localData?.className ?? defaults.className ?? null;

                    if( localClassName instanceof Set || Array.isArray( localClassName ) ) {
                         localClassName = [ ...localClassName ].join( ' ' );
                    }

                    return new InputModule( `${ localData?.id ?? defaults.id }`, localClassName as string ?? null, localData?.placeholder ?? defaults.placeholder ?? null,
                                            defaults?.isBlank ?? null, defaults?.isError ?? null );
               }
               if( typeof dataOrId === 'string' ) {
                    return new InputModule( dataOrId ?? null, className ?? null, placeholder ?? null, defaults?.isBlank ?? null, defaults?.isError ?? null );
               }
          }

          return InputModule.Build( defaults );
     }

     /**
      *
      * @param {InputModule} module
      * @return {InputModule}
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/14/21 12:04 pm
      * @see BuildForTemplate
      * @see BuildForName
      * @see BuildForEmail
      * @see BuildForMessage
      * @see BuildForAffiliate
      * @see BuildForSponsor
      * @see id
      * @see placeholder
      * @see isBlank
      * @see isError
      * @see setPlaceholder
      * @see setIsBlank
      * @see setIsError
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public static Build( module: InputModule ): InputModule;
     public static Build( obj: Partial<IInputModule> ): InputModule;
     public static Build( json: string ): InputModule;
     public static Build( id?: string, className?: string, placeholder?: string, isBlank?: boolean, isError?: boolean ): InputModule;
     public static Build( dataOrId?: unknown, className?: string, placeholder?: string, isBlank?: boolean, isError?: boolean ): InputModule {
          if( dataOrId ) {
               if( dataOrId instanceof InputModule ) {
                    return dataOrId;
               }
               if( typeof dataOrId === 'object' ) {
                    const localData: Partial<IInputModule> = dataOrId;

                    if( localData?.className instanceof Set || Array.isArray( localData?.className ) ) {
                         localData.className = [ ...localData?.className ].join( ' ' );
                    }

                    return new InputModule( `${ localData?.id ?? null }`, localData?.className ?? null, localData?.placeholder ?? null, localData?.isBlank ?? null,
                                            localData?.isError ?? null );
               }
               if( typeof dataOrId === 'string' || typeof dataOrId === 'number' ) {
                    try {
                         return this.Build( JSON.parse( `${ dataOrId ?? null }` ) );
                    } catch( exception ) {
                         // not a valid json string
                         return new InputModule( `${ dataOrId ?? null }`, className ?? null, placeholder ?? null, isBlank ?? null, isError ?? null );
                    }
               }

               return null;
          }

          return null;
     }

     /**
      *
      * @param {object} obj
      * @return {InputModule}
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/14/21 01:37 pm
      * @see BuildForTemplate
      * @see BuildForName
      * @see BuildForEmail
      * @see BuildForMessage
      * @see BuildForAffiliate
      * @see BuildForSponsor
      * @see id
      * @see path
      * @see placeholder
      * @see isBlank
      * @see isError
      * @see doesPass
      * @see doesFail
      * @see setPath
      * @see setPlaceholder
      * @see setIsBlank
      * @see setIsError
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public static BuildForName( obj: Partial<IInputModule> ): InputModule;
     public static BuildForName( id?: string, placeholder?: string ): InputModule;
     public static BuildForName( dataOrId?: unknown, placeholder?: string ): InputModule {
          const defaults: Partial<IInputModule> = {
               id         : process.env.REACT_APP_INPUT_NAME_ID ?? 'name',
               placeholder: process.env.REACT_APP_INPUT_NAME_PLACEHOLDER ?? 'john doe',
               isBlank    : this.DEFAULT_IS_BLANK,
               isError    : this.DEFAULT_IS_ERROR,
          };

          return InputModule.BuildForTemplate( defaults, dataOrId as string, placeholder );
     }

     /**
      *
      * @param {object} obj
      * @return {InputModule}
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/14/21 01:37 pm
      * @see BuildForTemplate
      * @see BuildForName
      * @see BuildForEmail
      * @see BuildForMessage
      * @see BuildForAffiliate
      * @see BuildForSponsor
      * @see id
      * @see path
      * @see placeholder
      * @see isBlank
      * @see isError
      * @see doesPass
      * @see doesFail
      * @see setPath
      * @see setPlaceholder
      * @see setIsBlank
      * @see setIsError
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public static BuildForEmail( obj: Partial<IInputModule> ): InputModule;
     public static BuildForEmail( id?: string, placeholder?: string ): InputModule;
     public static BuildForEmail( dataOrId?: unknown, placeholder?: string ): InputModule {
          const defaults: Partial<IInputModule> = {
               id         : process.env.REACT_APP_INPUT_EMAIL_ID ?? 'email',
               placeholder: process.env.REACT_APP_INPUT_EMAIL_PLACEHOLDER ?? 'example@website.com',
               isBlank    : this.DEFAULT_IS_BLANK,
               isError    : this.DEFAULT_IS_ERROR,
          };

          return InputModule.BuildForTemplate( defaults, dataOrId as string, placeholder );
     }

     /**
      *
      * @param {object} obj
      * @return {InputModule}
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/14/21 01:37 pm
      * @see BuildForName
      * @see BuildForEmail
      * @see BuildForMessage
      * @see BuildForAffiliate
      * @see BuildForSponsor
      * @see id
      * @see path
      * @see placeholder
      * @see isBlank
      * @see isError
      * @see doesPass
      * @see doesFail
      * @see setPath
      * @see setPlaceholder
      * @see setIsBlank
      * @see setIsError
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public static BuildForMessage( obj: Partial<IInputModule> ): InputModule;
     public static BuildForMessage( id?: string, placeholder?: string ): InputModule;
     public static BuildForMessage( dataOrId?: unknown, placeholder?: string ): InputModule {
          const defaults: Partial<IInputModule> = {
               id         : process.env.REACT_APP_INPUT_MESSAGE_ID ?? 'message',
               className  : process.env.REACT_APP_INPUT_MESSAGE_CLASSNAME ?? this.DEFAULT_CLASS_MESSAGE,
               placeholder: process.env.REACT_APP_INPUT_MESSAGE_PLACEHOLDER ?? 'Anything else?',
               isBlank    : this.DEFAULT_IS_BLANK,
               isError    : this.DEFAULT_IS_ERROR,
          };

          return InputModule.BuildForTemplate( defaults, dataOrId as string, placeholder );
     }

     /**
      *
      * @param {object} obj
      * @return {InputModule}
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/17/21 05:33 pm
      * @see BuildForName
      * @see BuildForEmail
      * @see BuildForMessage
      * @see BuildForAffiliate
      * @see BuildForSponsor
      * @see id
      * @see path
      * @see placeholder
      * @see isBlank
      * @see isError
      * @see doesPass
      * @see doesFail
      * @see setPath
      * @see setPlaceholder
      * @see setIsBlank
      * @see setIsError
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public static BuildForAffiliate( obj: Partial<IInputModule> ): InputModule;
     public static BuildForAffiliate( id?: string, placeholder?: string ): InputModule;
     public static BuildForAffiliate( dataOrId?: unknown, placeholder?: string ): InputModule {
          const defaults: Partial<IInputModule> = {
               id         : this.DEFAULT_AFFILIATE_ID,
               placeholder: this.DEFAULT_AFFILIATE_PLACEHOLDER,
               isBlank    : this.DEFAULT_IS_BLANK,
               isError    : this.DEFAULT_IS_ERROR,
          };

          return InputModule.BuildForTemplate( defaults, dataOrId as string ?? null, placeholder );
     }

     /**
      *
      * @param {object} obj
      * @return {InputModule}
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/17/21 05:33 pm
      * @see BuildForName
      * @see BuildForEmail
      * @see BuildForMessage
      * @see BuildForAffiliate
      * @see BuildForSponsor
      * @see id
      * @see path
      * @see placeholder
      * @see isBlank
      * @see isError
      * @see doesPass
      * @see doesFail
      * @see setPath
      * @see setPlaceholder
      * @see setIsBlank
      * @see setIsError
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public static BuildForSponsor( obj: Partial<IInputModule> ): InputModule;
     public static BuildForSponsor( id?: string, placeholder?: string ): InputModule;
     public static BuildForSponsor( dataOrId?: unknown, placeholder?: string ): InputModule {
          const defaults: Partial<IInputModule> = {
               id         : this.DEFAULT_SPONSOR_ID,
               placeholder: this.DEFAULT_SPONSOR_PLACEHOLDER,
               isBlank    : this.DEFAULT_IS_BLANK,
               isError    : this.DEFAULT_IS_ERROR,
          };

          return InputModule.BuildForTemplate( defaults, dataOrId as string, placeholder );
     }

     /**
      *
      * @param {string} id
      * @param {string}  className
      * @param {string} placeholder
      * @param {boolean} isBlank
      * @param {boolean} isError
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/14/21 12:04 pm
      * @see BuildForName
      * @see BuildForEmail
      * @see BuildForMessage
      * @see BuildForAffiliate
      * @see BuildForSponsor
      * @see id
      * @see placeholder
      * @see isBlank
      * @see isError
      * @see doesPass
      * @see doesFail
      * @see setPlaceholder
      * @see setIsBlank
      * @see setIsError
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public constructor( id?: string, className?: string, placeholder?: string, isBlank?: boolean, isError?: boolean ) {
          super( id ?? null, null, className );

          this.placeholder = placeholder ?? InputModule.DEFAULT_VALUE;
          this.isBlank     = isBlank ?? InputModule.DEFAULT_IS_BLANK;
          this.isError     = isError ?? InputModule.DEFAULT_IS_ERROR;
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/14/21 12:04 pm
      * @see BuildForName
      * @see BuildForEmail
      * @see BuildForMessage
      * @see BuildForAffiliate
      * @see BuildForSponsor
      * @see id
      * @see path
      * @see placeholder
      * @see isBlank
      * @see isError
      * @see doesPass
      * @see doesFail
      * @see setPath
      * @see setPlaceholder
      * @see setIsBlank
      * @see setIsError
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public get placeholder(): string {
          return this._data.get( 'placeholder' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/14/21 12:04 pm
      * @see BuildForName
      * @see BuildForEmail
      * @see BuildForMessage
      * @see BuildForAffiliate
      * @see BuildForSponsor
      * @see id
      * @see path
      * @see placeholder
      * @see isBlank
      * @see isError
      * @see doesPass
      * @see doesFail
      * @see setPath
      * @see setPlaceholder
      * @see setIsBlank
      * @see setIsError
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public set placeholder( value: string ) {
          this._data.set( 'placeholder', value );
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/14/21 12:04 pm
      * @see BuildForName
      * @see BuildForEmail
      * @see BuildForMessage
      * @see BuildForAffiliate
      * @see BuildForSponsor
      * @see id
      * @see path
      * @see placeholder
      * @see isBlank
      * @see isError
      * @see doesPass
      * @see doesFail
      * @see setPath
      * @see setPlaceholder
      * @see setIsBlank
      * @see setIsError
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public get isBlank(): boolean {
          return this._data.get( 'isBlank' );
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/14/21 12:04 pm
      * @see BuildForName
      * @see BuildForEmail
      * @see BuildForMessage
      * @see BuildForAffiliate
      * @see BuildForSponsor
      * @see id
      * @see path
      * @see placeholder
      * @see isBlank
      * @see isError
      * @see doesPass
      * @see doesFail
      * @see setPath
      * @see setPlaceholder
      * @see setIsBlank
      * @see setIsError
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public set isBlank( value: boolean ) {
          this._data.set( 'isBlank', value );
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/14/21 12:04 pm
      * @see BuildForName
      * @see BuildForEmail
      * @see BuildForMessage
      * @see BuildForAffiliate
      * @see BuildForSponsor
      * @see id
      * @see path
      * @see placeholder
      * @see isBlank
      * @see isError
      * @see doesPass
      * @see doesFail
      * @see setPath
      * @see setPlaceholder
      * @see setIsBlank
      * @see setIsError
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public get isError(): boolean {
          return this._data.get( 'isError' );
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/14/21 12:04 pm
      * @see BuildForName
      * @see BuildForEmail
      * @see BuildForMessage
      * @see BuildForAffiliate
      * @see BuildForSponsor
      * @see id
      * @see path
      * @see placeholder
      * @see isBlank
      * @see isError
      * @see doesPass
      * @see doesFail
      * @see setPath
      * @see setPlaceholder
      * @see setIsBlank
      * @see setIsError
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public set isError( value: boolean ) {
          this._data.set( 'isError', value );
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/14/21 12:04 pm
      * @see BuildForName
      * @see BuildForEmail
      * @see BuildForMessage
      * @see BuildForAffiliate
      * @see BuildForSponsor
      * @see id
      * @see path
      * @see placeholder
      * @see isBlank
      * @see isError
      * @see doesPass
      * @see doesFail
      * @see setPath
      * @see setPlaceholder
      * @see setIsBlank
      * @see setIsError
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public setPlaceholder( value: string ): this {
          this.placeholder = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/14/21 12:04 pm
      * @see BuildForName
      * @see BuildForEmail
      * @see BuildForMessage
      * @see BuildForAffiliate
      * @see BuildForSponsor
      * @see id
      * @see path
      * @see placeholder
      * @see isBlank
      * @see isError
      * @see doesPass
      * @see doesFail
      * @see setPath
      * @see setPlaceholder
      * @see setIsBlank
      * @see setIsError
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public setIsBlank( value: boolean ): this {
          this.isBlank = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/14/21 12:04 pm
      * @see BuildForName
      * @see BuildForEmail
      * @see BuildForMessage
      * @see BuildForAffiliate
      * @see BuildForSponsor
      * @see id
      * @see path
      * @see placeholder
      * @see isBlank
      * @see isError
      * @see doesPass
      * @see doesFail
      * @see setPath
      * @see setPlaceholder
      * @see setIsBlank
      * @see setIsError
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public setIsError( value: boolean ): this {
          this.isError = value;

          return this;
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/14/21 01:37 pm
      * @see BuildForName
      * @see BuildForEmail
      * @see BuildForMessage
      * @see BuildForAffiliate
      * @see BuildForSponsor
      * @see id
      * @see path
      * @see placeholder
      * @see isBlank
      * @see isError
      * @see doesPass
      * @see doesFail
      * @see setPath
      * @see setPlaceholder
      * @see setIsBlank
      * @see setIsError
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public doesPass(): boolean {
          return !this.doesFail();
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/14/21 01:37 pm
      * @see BuildForName
      * @see BuildForEmail
      * @see BuildForMessage
      * @see BuildForAffiliate
      * @see BuildForSponsor
      * @see id
      * @see path
      * @see placeholder
      * @see isBlank
      * @see isError
      * @see doesPass
      * @see doesFail
      * @see setPath
      * @see setPlaceholder
      * @see setIsBlank
      * @see setIsError
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public doesFail(): boolean {
          return this.isBlank || this.isError;
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/14/21 12:04 pm
      * @see BuildForName
      * @see BuildForEmail
      * @see BuildForMessage
      * @see BuildForAffiliate
      * @see BuildForSponsor
      * @see id
      * @see path
      * @see placeholder
      * @see isBlank
      * @see isError
      * @see doesPass
      * @see doesFail
      * @see setPath
      * @see setPlaceholder
      * @see setIsBlank
      * @see setIsError
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public toObject(): Record<string, unknown> {
          return {
               id         : this.id,
               className  : [ ...this.className ],
               placeholder: this.placeholder,
               isBlank    : this.isBlank,
               isError    : this.isError,
          };
     }
}