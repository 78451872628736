import { AComponent }                          from '../../abstract';
import { IGalleryImageModule, IGalleryModule } from '../../interface';
import { HAlignEnum, VAlignEnum }              from '../../enum';
import { LinkModule }                          from '../../module/api/link.module';
import { TitleModule }                         from '../../module/api/title.module';
import { Util }                                from '../../tool';
import { GalleryImageModule }                  from './gallery/gallery.image.module';
import { ImageModule }                         from './image.module';

/**
 * @class GalleryModule
 * @extends AComponent
 * @implements IGalleryModule
 * @author Isaac Ewing
 * @version 1.0.0 10/03/20 01:44 pm
 * @version 1.1.0 02/22/21 05:34 pm - updated to extend AComponent
 * @classdesc This class handles the encapsulated data from the database when using the gallery
 */
export class GalleryModule extends AComponent implements IGalleryModule {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 05:34 pm
      */
     protected static readonly API_ROOT_NODE: string      = process.env.REACT_APP_GRAPHQL_GET_GALLERY;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/22/21 08:36 pm
      */
     protected static readonly CONSOLE_ENABLED: boolean   = false;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 05:34 pm
      */
     protected static readonly DEFAULT_JQUERY: string     = 'gallery';
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 05:34 pm
      */
     protected static readonly DEFAULT_GALLERY: boolean   = false;
     /**
      *
      * @type {VAlignEnum}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 05:34 pm
      */
     protected static readonly DEFAULT_VALIGN: VAlignEnum = VAlignEnum.Auto;
     /**
      *
      * @type {HAlignEnum}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 05:34 pm
      */
     protected static readonly DEFAULT_HALIGN: HAlignEnum = HAlignEnum.Auto;

     /**
      *
      * @param {GalleryModule} module
      * @return {GalleryModule}
      * @constructor
      * @static
      * @public
      * @version 1.1.0 02/22/21 05:34 pm
      * @see Build
      * @see id
      * @see gallery
      * @see valign
      * @see halign
      * @see images
      * @see setGallery
      * @see setVAlign
      * @see setHAlign
      * @see setImages
      * @see addImage
      * @see imagesToArray
      * @see enableGallery
      * @see disableGallery
      */
     public static Build( module: GalleryModule ): GalleryModule;
     public static Build( obj: Partial<IGalleryModule> ): GalleryModule;
     public static Build( json: string ): GalleryModule;
     public static Build( gallery?: boolean, valign?: VAlignEnum | string, halign?: HAlignEnum | string, image?: GalleryImageModule, id?: number | string, hash?: string, className?: string,
                          jquery?: string, shortcode?: boolean ): GalleryModule;
     public static Build( gallery?: boolean, valign?: VAlignEnum | string, halign?: HAlignEnum | string, images?: GalleryImageModule[], id?: number | string, hash?: string, className?: string,
                          jquery?: string, shortcode?: boolean ): GalleryModule;
     public static Build( gallery?: boolean, valign?: VAlignEnum | string, halign?: HAlignEnum | string, images?: Set<GalleryImageModule>, id?: number | string, hash?: string, className?: string,
                          jquery?: string, shortcode?: boolean ): GalleryModule;
     public static Build( gallery?: boolean, valign?: VAlignEnum | string, halign?: HAlignEnum | string, image?: GalleryImageModule, id?: number | string, hash?: string, classNames?: string[],
                          jquery?: string, shortcode?: boolean ): GalleryModule;
     public static Build( gallery?: boolean, valign?: VAlignEnum | string, halign?: HAlignEnum | string, images?: GalleryImageModule[], id?: number | string, hash?: string, classNames?: string[],
                          jquery?: string, shortcode?: boolean ): GalleryModule;
     public static Build( gallery?: boolean, valign?: VAlignEnum | string, halign?: HAlignEnum | string, images?: Set<GalleryImageModule>, id?: number | string, hash?: string, classNames?: string[],
                          jquery?: string, shortcode?: boolean ): GalleryModule;
     public static Build( dataOrGallery?: unknown, valign?: VAlignEnum | string, halign?: HAlignEnum | string, images?: never, id?: number | string, hash?: string, classNames?: never, jquery?: string,
                          shortcode?: boolean ): GalleryModule {
          if( dataOrGallery ) {
               dataOrGallery = this.mapAPIRootNode( dataOrGallery, this.API_ROOT_NODE );

               if( dataOrGallery instanceof GalleryModule ) {
                    return dataOrGallery;
               }
               if( typeof dataOrGallery === 'boolean' ) {
                    return new GalleryModule( dataOrGallery ?? null, valign ?? null, halign ?? null, images ?? null, id ?? null, hash ?? null,
                                              classNames ?? null, jquery ?? null, shortcode ?? null );
               }
               if( typeof dataOrGallery === 'object' ) {
                    const localData: Partial<IGalleryModule> | any = dataOrGallery;
                    const localGallery                             = localData?.gallery ?? localData?.[ 'isGallery' ] ?? localData;
                    const localLinks                               = localData?.images ?? localData?.image ?? localData;

                    if( this.CONSOLE_ENABLED ) {
                         console.info( '[[ ]]GALLERY MODULE BUILD[[ ]]', { localData, localGallery, valign, halign, images, id, hash }, 'CHECK LOCAL GALLERY FOR BOOLEAN' );
                    }

                    return new GalleryModule( localGallery ?? null, localData?.valign ?? null, localData?.halign ?? null, localLinks ?? null, localData?.id ?? null,
                                              localData?.hash ?? null, localData?.className ?? null, localData?.jquery ?? null, localData?.shortcode ?? null );
               }
               if( typeof dataOrGallery === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrGallery ) );
                    } catch( exception ) {
                         // not a valid json string
                         return new GalleryModule( Util.asBoolean( dataOrGallery ) ?? null, valign ?? null, halign ?? null, images ?? null, id ?? null,
                                                   hash ?? null, classNames ?? null, jquery ?? null, shortcode ?? null );
                    }
               }
          }

          return null;
     }

     /**
      *
      * @param {boolean} gallery
      * @param {VAlignEnum | string} valign
      * @param {HAlignEnum | string} halign
      * @param {GalleryImageModule} image
      * @param {number | string} id
      * @param {string} hash
      * @param {string} className
      * @param {string} jquery
      * @param {boolean} shortcode
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 05:34 pm
      * @see id
      * @see gallery
      * @see valign
      * @see halign
      * @see images
      * @see setGallery
      * @see setVAlign
      * @see setHAlign
      * @see setImages
      * @see addImage
      * @see imagesToArray
      * @see enableGallery
      * @see disableGallery
      */
     public constructor( gallery?: boolean, valign?: VAlignEnum | string, halign?: HAlignEnum | string, image?: GalleryImageModule, id?: number | string, hash?: string, className?: string,
                         jquery?: string, shortcode?: boolean );
     public constructor( gallery?: boolean, valign?: VAlignEnum | string, halign?: HAlignEnum | string, images?: GalleryImageModule[], id?: number | string, hash?: string, className?: string,
                         jquery?: string, shortcode?: boolean );
     public constructor( gallery?: boolean, valign?: VAlignEnum | string, halign?: HAlignEnum | string, images?: Set<GalleryImageModule>, id?: number | string, hash?: string, className?: string,
                         jquery?: string, shortcode?: boolean );
     public constructor( gallery?: boolean, valign?: VAlignEnum | string, halign?: HAlignEnum | string, image?: GalleryImageModule, id?: number | string, hash?: string, classNames?: string[],
                         jquery?: string, shortcode?: boolean );
     public constructor( gallery?: boolean, valign?: VAlignEnum | string, halign?: HAlignEnum | string, images?: GalleryImageModule[], id?: number | string, hash?: string, classNames?: string[],
                         jquery?: string, shortcode?: boolean );
     public constructor( gallery?: boolean, valign?: VAlignEnum | string, halign?: HAlignEnum | string, images?: Set<GalleryImageModule>, id?: number | string, hash?: string, classNames?: string[],
                         jquery?: string, shortcode?: boolean );
     public constructor( gallery?: boolean, valign?: VAlignEnum | string, halign?: HAlignEnum | string, images?: never, id?: number | string, hash?: string, classNames?: never, jquery?: string,
                         shortcode?: boolean ) {
          super( id, hash, classNames, jquery ?? GalleryModule.DEFAULT_JQUERY, shortcode );

          this.gallery = Util.asBoolean( gallery ?? GalleryModule.DEFAULT_GALLERY );
          this.valign  = valign ?? GalleryModule.DEFAULT_VALIGN;
          this.halign  = halign ?? GalleryModule.DEFAULT_HALIGN;
          this._data.set( 'images', new Set<ImageModule>() );

          if( images ) {
               if( GalleryModule.CONSOLE_ENABLED ) {
                    console.info( '[[ ]]GALLERY MODULE[[ ]]', { gallery, valign, halign, images, id, hash, classNames, jquery, shortcode } );
               }
               this.addImage( images );
          }
     }

     /**
      *
      * @return {Set<GalleryImageModule>}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 05:34 pm
      * @see id
      * @see gallery
      * @see valign
      * @see halign
      * @see images
      * @see setGallery
      * @see setVAlign
      * @see setHAlign
      * @see setImages
      * @see addImage
      * @see imagesToArray
      * @see enableGallery
      * @see disableGallery
      */
     public get images(): Set<GalleryImageModule> {
          return this._data.get( 'images' );
     }

     /**
      *
      * @param {Set<GalleryImageModule>} value
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 05:34 pm
      * @see id
      * @see gallery
      * @see valign
      * @see halign
      * @see images
      * @see setGallery
      * @see setVAlign
      * @see setHAlign
      * @see setImages
      * @see addImage
      * @see imagesToArray
      * @see enableGallery
      * @see disableGallery
      */
     public set images( value: Set<GalleryImageModule> ) {
          this._data.set( 'images', value );
     }

     /**
      *
      * @param {ImageModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 05:34 pm
      * @see id
      * @see gallery
      * @see valign
      * @see halign
      * @see images
      * @see setGallery
      * @see setVAlign
      * @see setHAlign
      * @see setImages
      * @see addImage
      * @see imagesToArray
      * @see enableGallery
      * @see disableGallery
      */
     public setImages( value: ImageModule ): this;
     public setImages( values: ImageModule[] ): this;
     public setImages( values: Set<ImageModule> ): this;
     public setImages( values: unknown ): this {
          if( values ) {
               this._data.delete( 'images' );
               this._data.set( 'images', new Set<ImageModule>() );

               if( values instanceof Set || Array.isArray( values ) ) {
                    values.forEach( ( image ): void => {
                         this.addImage( image );
                    } );
               } else if( values instanceof ImageModule ) {
                    this.addImage( values );
               }
          }

          return this;
     }

     /**
      *
      * @return {boolean} Returns the value for the property
      * @deprecated since 1.1.0, use <code>gallery</code> instead
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 05:34 pm
      * @see id
      * @see gallery
      * @see valign
      * @see halign
      * @see images
      * @see setGallery
      * @see setVAlign
      * @see setHAlign
      * @see setImages
      * @see addImage
      * @see imagesToArray
      * @see enableGallery
      * @see disableGallery
      */
     public get isGallery(): boolean {
          return this.gallery;
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 06:27 pm
      * @see id
      * @see gallery
      * @see valign
      * @see halign
      * @see images
      * @see setGallery
      * @see setVAlign
      * @see setHAlign
      * @see setImages
      * @see addImage
      * @see imagesToArray
      * @see enableGallery
      * @see disableGallery
      */
     public enableGallery(): this {
          this.gallery = true;

          return this;
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 06:27 pm
      * @see id
      * @see gallery
      * @see valign
      * @see halign
      * @see images
      * @see setGallery
      * @see setVAlign
      * @see setHAlign
      * @see setImages
      * @see addImage
      * @see imagesToArray
      * @see enableGallery
      * @see disableGallery
      */
     public disableGallery(): this {
          this.gallery = false;

          return this;
     }

     /**
      *
      * @param module {GalleryImageModule[]|Object[]|GalleryImageModule|Object|number} An array or object or id with the data
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 06:27 pm
      * @see id
      * @see gallery
      * @see valign
      * @see halign
      * @see images
      * @see setGallery
      * @see setVAlign
      * @see setHAlign
      * @see setImages
      * @see addImage
      * @see imagesToArray
      * @see enableGallery
      * @see disableGallery
      */
     public addImage( module: ImageModule ): this
     public addImage( module: GalleryModule ): this
     public addImage( modules: GalleryModule[] ): this
     public addImage( modules: Set<GalleryModule> ): this
     public addImage( modules: IGalleryImageModule ): this
     public addImage( id: number, image: ImageModule, title: TitleModule, link: LinkModule ): this;
     public addImage( id: unknown, image?: ImageModule, title?: TitleModule, link?: LinkModule ): this {
          if( id instanceof GalleryImageModule ) {
               this.images.add( id );
          } else if( id instanceof Set || Array.isArray( id ) ) {
               id.forEach( ( element ): void => { this.addImage( element ); } );
          } else if( id instanceof GalleryModule ) {
               id.images.forEach( ( image: GalleryImageModule ): void => {
                    this.addImage( image ?? null );
               } );
          } else if( id instanceof ImageModule ) {
               this.images.add( new GalleryImageModule( id ?? null ) );
          } else if( typeof id === 'object' ) {
               const local: Record<string, never>       = id as Record<string, never>;
               const data: Partial<IGalleryImageModule> = {
                    id   : local?.id,
                    image: local?.image,
                    title: local?.title,
                    link : local?.link,
               };

               if( GalleryModule.CONSOLE_ENABLED ) {
                    console.info( '[[O]]GALLERY MODULE[[ ]]', { local, data } );
               }

               this.images.add( GalleryImageModule.Build( data ?? null ) );
          } else if( typeof id === 'number' && image instanceof ImageModule ) {
               this.images.add( GalleryImageModule.Build( { id, image, title, link } ) );
          } else {
               console.log( 'the link was invalid', { id, image, title, link } );
          }

          return this;
     }

     /**
      *
      * @param {boolean} [primitive=false] Indicates if the values should be transformed into their basic types
      * @return {GalleryImageModule[] | object[]}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 10:01 pm
      * @version 1.2.0 02/24/21 05:13 pm - added support for primitive transformation vs normal conversion to array
      * @see id
      * @see gallery
      * @see valign
      * @see halign
      * @see images
      * @see setGallery
      * @see setVAlign
      * @see setHAlign
      * @see setImages
      * @see addImage
      * @see imagesToArray
      * @see enableGallery
      * @see disableGallery
      */
     public imagesToArray( primitive?: boolean ): GalleryImageModule[] | Record<string, unknown>[] {
          if( this.images ) {
               if( primitive ) {
                    let test: Record<string, unknown>;
                    const images: Record<string, unknown>[] = [];

                    this.images.forEach( ( image: GalleryImageModule ): void => {
                         test = image.toObject();

                         if( test ) {
                              images.push( test );
                         }
                    } );

                    return images;
               } else {
                    return [ ...this.images ];
               }
          }

          return null;
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/22/21 06:27 pm
      * @see id
      * @see gallery
      * @see valign
      * @see halign
      * @see images
      * @see setGallery
      * @see setVAlign
      * @see setHAlign
      * @see setImages
      * @see addImage
      * @see imagesToArray
      * @see enableGallery
      * @see disableGallery
      */
     public toObject(): Record<string, unknown> {
          return {
               ...super.toObject(),
               gallery: this.gallery,
               valign : this.valign,
               halign : this.halign,
               images : this.imagesToArray( true ),
          };
     }
}
