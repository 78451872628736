import React                  from 'react';
import PropTypes              from 'prop-types';
import { GalleryImageModule } from '../../api/com/ewing/social/component';
import { Base }               from '../atom/Base';
import { Image }              from '../atom/Image';
import { Link }               from '../atom/Link';
import { Subtitle }           from '../atom/Subtitle';

export class Thumbnail extends Base<any, any> {
     protected static COMPONENT_CLASS: string = 'thumbnail';
     public static propTypes                  = {
          ...Base.propTypes,
          module  : PropTypes.instanceOf( GalleryImageModule ).isRequired,
          social  : PropTypes.bool,
          youtube : PropTypes.bool,
          equalize: PropTypes.bool,
     };

     public constructor( props: any ) {
          super( props );

          this.state = {
               module     : props.module,
               initialized: false,
               social     : !!props.social,
               youtube    : !!props.youtube,
               equalize   : !!props.equalize,
               className  : new Set<string>( [ Thumbnail.COMPONENT_CLASS ] ),
          };

          //this.registerStyle(props);
          this.registerClasses( this.state.module );
     }

     protected registerTitle(): JSX.Element {
          if( this.state.module?.title ) {
               return <Subtitle module={ this.state.module.title } />;
          }
     }

     /**
      *
      * @return {JSX.Element}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/05/20 06:39 pm
      */
     protected registerLink(): JSX.Element {
          if( this.state.module?.link ) {
               return (
                    <Link module={ this.state.module.link }>
                         <Image module={ this.state.module?.image } />
                    </Link>
               );
          }

          return <Image module={ this.state.module?.image } />;
     }

     /**
      *
      * @return {JSX.Element}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/05/20 06:39 pm
      */
     protected registerDefault(): JSX.Element {
          if( !this.state.youtube ) {
               if( this.state.equalize ) {
                    return (
                         <>
                              { !this.state.social ? this.registerTitle() : null }
                              { this.registerLink() }
                              { this.state.social ? this.registerTitle() : null }
                         </>
                    );
               } else {
                    return (
                         <>
                              { !this.state.social ? this.registerTitle() : null }
                              { this.registerLink() }
                              { this.state.social ? this.registerTitle() : null }
                         </>
                    );
               }
          }

          return null;
     }

     /**
      *
      * @return {JSX.Element}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/05/20 06:39 pm
      */
     protected registerYouTube(): JSX.Element {
          if( this.state.youtube ) {
               return (
                    <>
                         <div className="responsive-embed widescreen">
                              <iframe width="100%" height="auto" src={ this.state.module?.image?.source } frameBorder="0" allowFullScreen />
                         </div>
                    </>
               );
          }

          return null;
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/05/20 06:39 pm
      */
     public render(): JSX.Element {
          return (
               <>
                    { this.registerDefault() }
                    { this.registerYouTube() }
               </>
          );
     }
}