import { AModule }                  from '../../abstract/module.abstract';
import { INewsletterModule }        from '../../interface';
import { ActionEnum, CampaignEnum } from '../../enum/newsletter';
import { URLManager }               from '../../manager';
import { Util }                     from '../../tool';

/**
 * @class NewsletterModule
 * @extends AModule
 * @implements INewsletterModule
 * @author Isaac Ewing
 * @version 1.0.0 02/07/21 05:12 pm
 * @version 1.1.0 02/18/21 09:56 pm - updated to extend AComponent
 * @classdesc This class will handle the storage for address data. This is the fourth class to
 * implement the interfaces in a similar way to laravel/lumen
 */
export class NewsletterModule extends AModule implements INewsletterModule {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/06/20 03:50 pm
      */
     protected static readonly DEFAULT_ACTION: string   = ActionEnum.Subscribe;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/06/20 03:50 pm
      */
     protected static readonly DEFAULT_CAMPAIGN: string = CampaignEnum.General;

     /**
      *
      * @param {NewsletterModule} module
      * @return {NewsletterModule}
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/20 05:32 pm
      * @see id
      * @see action
      * @see domain
      * @see page
      * @see campaign
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public static Build( module: NewsletterModule ): NewsletterModule;
     public static Build( obj: Partial<INewsletterModule> ): NewsletterModule;
     public static Build( json: string ): NewsletterModule;
     public static Build( action?: ActionEnum | string, domain?: string, page?: string, campaign?: CampaignEnum | string, name?: string, email?: string, message?: string ): NewsletterModule;
     public static Build( dataOrAction?: unknown, domain?: string, page?: string, campaign?: CampaignEnum | string, name?: string, email?: string, message?: string ): NewsletterModule {
          if( dataOrAction ) {
               if( dataOrAction instanceof NewsletterModule ) {
                    return dataOrAction;
               }
               if( typeof dataOrAction === 'object' ) {
                    const localData: Partial<INewsletterModule> = dataOrAction;

                    return new NewsletterModule( localData?.action, localData?.domain, localData?.page, localData?.campaign, localData?.name, localData?.email, localData?.message );
               }
               if( typeof dataOrAction === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrAction ) );
                    } catch( exception ) {
                         // not a valid json string
                         return new NewsletterModule( dataOrAction, domain, page, campaign, name, email, message );
                    }
               }
          }

          return null;
     }

     /**
      *
      * @param {ActionEnum | string} action
      * @param {string} domain
      * @param {string} page
      * @param {CampaignEnum | string} campaign
      * @param {string} name
      * @param {string} email
      * @param {string} message
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/06/20 03:50 pm
      * @see id
      * @see action
      * @see domain
      * @see page
      * @see campaign
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public constructor( action?: ActionEnum | string, domain?: string, page?: string, campaign?: CampaignEnum | string, name?: string, email?: string, message?: string ) {
          super( null );

          this.action   = action ?? NewsletterModule.DEFAULT_ACTION;
          this.domain   = Util.cleanPath( domain ?? URLManager.Domain, false );
          this.page     = Util.cleanPath( page ?? URLManager.Page, true );
          this.campaign = campaign ?? NewsletterModule.DEFAULT_CAMPAIGN;
          this.name     = name ?? NewsletterModule.DEFAULT_VALUE;
          this.email    = email ?? NewsletterModule.DEFAULT_VALUE;
          this.message  = message ?? NewsletterModule.DEFAULT_VALUE;

     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see campaign
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public get action(): string {
          return this._data.get( 'action' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see campaign
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public set action( value: string ) {
          this._data.set( 'action', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see campaign
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public get domain(): string {
          return this._data.get( 'domain' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see campaign
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public set domain( value: string ) {
          this._data.set( 'domain', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/16/21 03:17 pm
      * @see id
      * @see action
      * @see domain
      * @see page
      * @see name
      * @see email
      * @see message
      * @see setId
      * @see setAction
      * @see setDomain
      * @see setPage
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toArray
      * @see toString
      */
     public get page(): string {
          return this._data.get( 'page' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/16/21 03:17 pm
      * @see id
      * @see action
      * @see domain
      * @see page
      * @see name
      * @see email
      * @see message
      * @see setId
      * @see setAction
      * @see setDomain
      * @see setPage
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toArray
      * @see toString
      */
     public set page( value: string ) {
          this._data.set( 'page', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see campaign
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public get campaign(): string {
          return this._data.get( 'campaign' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see campaign
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public set campaign( value: string ) {
          this._data.set( 'campaign', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see campaign
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public get name(): string {
          return this._data.get( 'name' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see campaign
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public set name( value: string ) {
          this._data.set( 'name', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see campaign
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public get email(): string {
          return this._data.get( 'email' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see campaign
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public set email( value: string ) {
          this._data.set( 'email', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see campaign
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public get message(): string {
          return this._data.get( 'message' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see campaign
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public set message( value: string ) {
          this._data.set( 'message', value );
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see campaign
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public setAction( value: string ): this {
          this.action = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see campaign
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public setDomain( value: string ): this {
          this.domain = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/16/21 03:17 pm
      * @see id
      * @see action
      * @see domain
      * @see page
      * @see name
      * @see email
      * @see message
      * @see setId
      * @see setAction
      * @see setDomain
      * @see setPage
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toArray
      * @see toString
      */
     public setPage( value: string ): this {
          this.page = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see campaign
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public setCampaign( value: string ): this {
          this.campaign = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see campaign
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public setName( value: string ): this {
          this.name = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see campaign
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public setEmail( value: string ): this {
          this.email = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see campaign
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public setMessage( value: string ): this {
          this.message = value;

          return this;
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/07/21 05:46 pm
      * @see id
      * @see path
      * @see action
      * @see domain
      * @see campaign
      * @see name
      * @see email
      * @see message
      * @see setPath
      * @see setAction
      * @see setDomain
      * @see setCampaign
      * @see setName
      * @see setEmail
      * @see setMessage
      * @see forComponent
      * @see toObject
      * @see toMap
      * @see toString
      */
     public toObject(): Record<string, unknown> {
          return {
               action  : this.action,
               domain  : this.domain,
               page    : this.page,
               campaign: this.campaign,
               name    : this.name,
               email   : this.email,
               message : this.message,
          };
     }
}