import {
     IDescription, IDomain, IMembership, ITitle,
}                            from '../../interface';
import { CurrencyEnum }      from '../../enum';
import { ConsoleManager }    from '../../manager';
import { DescriptionModule } from './description.module';
import { DomainModule }      from './domain.module';
import { TitleModule }       from './title.module';
import { ImageModule }       from '../../component';

/**
 *
 * @class MembershipModule
 * @extends TitleModule
 * @implements IMembership
 * @author Isaac Ewing
 * @version 1.0.0 06/10/21 10:03 pm
 */
export class MembershipModule extends TitleModule implements IMembership {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 07:32 pm
      */
     protected static readonly CONSOLE_PREFIX: string   = `${ process.env.REACT_APP_CONSOLE_PREFIX_MODULE } MEMb ${ process.env.REACT_APP_CONSOLE_SUFFIX_MODULE }`;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 07:32 pm
      */
     protected static readonly CONSOLE_ENABLED: boolean = false;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 07:32 pm
      */
     protected static readonly API_ROOT_NODE: string    = process.env.REACT_APP_GRAPHQL_GET_MEMBERSHIP;
     /**
      *
      * @type {MembershipModule}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 07:32 pm
      */
     protected static fromStorage: MembershipModule;

     /**
      *
      * @param module {MembershipModule} The object containing the data
      * @return {MembershipModule | Set<MembershipModule> | null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 08:44 pm
      * @version 1.0.1 06/12/21 11:07 am - added support for set creation and returning
      * @see Build
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public static Build( module: MembershipModule ): MembershipModule | null;
     public static Build( obj: Partial<IMembership> ): MembershipModule | null;
     public static Build( array: Partial<IMembership>[] ): MembershipModule | null;
     public static Build( set: Set<Partial<IMembership>> ): MembershipModule | null;
     public static Build( json: string ): MembershipModule | null;
     public static Build( id?: number, domain?: IDomain, title?: ITitle, description?: IDescription, image?: ImageModule, price?: number, currency?: CurrencyEnum,
                          enabled?: boolean ): MembershipModule | null;
     public static Build( id?: number, domain?: unknown, title?: TitleModule, description?: DescriptionModule, image?: ImageModule, price?: number, currency?: CurrencyEnum,
                          enabled?: boolean ): MembershipModule | null;
     public static Build( dataOrId?: unknown, domain?: DomainModule, title?: TitleModule, description?: DescriptionModule, image?: ImageModule, price?: number, currency?: CurrencyEnum,
                          enabled?: boolean ): MembershipModule | Set<MembershipModule> | null {
          if( dataOrId ) {
               dataOrId = this.mapAPIRootNode( dataOrId, this.API_ROOT_NODE );

               if( dataOrId instanceof MembershipModule ) {
                    return dataOrId;
               }
               if( dataOrId instanceof Set || Array.isArray( dataOrId ) ) {
                    const localData: Set<MembershipModule> = new Set<MembershipModule>();

                    dataOrId.forEach( data => {
                         const temp: MembershipModule | Set<MembershipModule> | null = MembershipModule.Build( data );

                         if( temp && temp instanceof MembershipModule ) {
                              localData.add( temp );
                         }
                    } );

                    return localData;
               }
               if( typeof dataOrId === 'object' ) {
                    const localData: IMembership = dataOrId as IMembership;

                    return new MembershipModule( +localData?.id ?? null, DomainModule.Build( localData?.domain ) ?? null, TitleModule.Build( localData?.title ) ?? null,
                                                 DescriptionModule.Build( localData?.description ) ?? null, ImageModule.Build( localData?.image ) ?? null,
                                                 localData?.price ?? null, localData?.currency ?? null, localData?.enabled ?? null );
               }
               if( typeof dataOrId === 'number' ) {
                    return new MembershipModule( dataOrId ?? null, DomainModule.Build( domain ) ?? null, TitleModule.Build( title ) ?? null,
                                                 DescriptionModule.Build( description as IDescription ) ?? null, ImageModule.Build( image ) ?? null, price ?? null,
                                                 currency ?? null, enabled ?? null );
               }
               if( typeof dataOrId === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrId ) );
                    } catch( exception: unknown ) {
                         // not a valid json string
                         return new MembershipModule( +dataOrId ?? null, DomainModule.Build( domain ) ?? null, TitleModule.Build( title ) ?? null,
                                                      DescriptionModule.Build( description as IDescription ) ?? null, ImageModule.Build( image ) ?? null, price ?? null,
                                                      currency ?? null, enabled ?? null );
                    }
               }
          }

          return null;
     }

     /**
      *
      * @param {number} id
      * @param {DomainModule} domain
      * @param {TitleModule} title
      * @param {DescriptionModule} description
      * @param {ImageModule} image
      * @param {number} price
      * @param {CurrencyEnum} currency
      * @param {boolean} enabled
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/10/21 10:03 pm
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public constructor( id?: number, domain?: DomainModule, title?: TitleModule, description?: DescriptionModule, image?: ImageModule, price?: number, currency?: CurrencyEnum, enabled?: boolean ) {
          super( id ?? null );

          this.domain      = domain ?? null;
          this.title       = title ?? null;
          this.description = description ?? null;
          this.image       = image ?? null;
          this.price       = price ?? null;
          this.currency    = currency ?? null;
          this.enabled     = enabled ?? null;
     }

     /**
      *
      * @return {DomainModule}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 07:59 pm
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public get domain(): DomainModule {
          return this._data.get( 'domain' );
     }

     /**
      *
      * @param {DomainModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 07:59 pm
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public set domain( value: DomainModule ) {
          this._data.set( 'domain', value );
     }

     /**
      *
      * @return {number}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 08:07 pm
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public get domainId(): number {
          return this.domain?.id ?? null;
     }

     /**
      *
      * @return {TitleModule}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 07:59 pm
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public get title(): TitleModule {
          return this._data.get( 'title' );
     }

     /**
      *
      * @param {TitleModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 07:59 pm
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public set title( value: TitleModule ) {
          this._data.set( 'title', value );
     }

     /**
      *
      * @return {number}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 08:07 pm
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public get titleId(): number {
          return this.title?.id ?? null;
     }

     /**
      *
      * @return {DescriptionModule}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 07:59 pm
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public get description(): DescriptionModule {
          return this._data.get( 'description' );
     }

     /**
      *
      * @param {DescriptionModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 07:59 pm
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public set description( value: DescriptionModule ) {
          this._data.set( 'description', value );
     }

     /**
      *
      * @return {number}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 08:07 pm
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public get descriptionId(): number {
          return this.description?.id ?? null;
     }

     /**
      *
      * @return {ImageModule}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 07:59 pm
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public get image(): ImageModule {
          return this._data.get( 'image' );
     }

     /**
      *
      * @param {ImageModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 07:59 pm
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public set image( value: ImageModule ) {
          this._data.set( 'image', value );
     }

     /**
      *
      * @return {number}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 08:07 pm
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public get imageId(): number {
          return +this.image?.id ?? null;
     }

     /**
      *
      * @return {number}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 07:59 pm
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public get price(): number {
          return this._data.get( 'price' );
     }

     /**
      *
      * @param {number} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 07:59 pm
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public set price( value: number ) {
          this._data.set( 'price', Math.round( value ) );
     }

     /**
      *
      * @return {CurrencyEnum}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 07:59 pm
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public get currency(): CurrencyEnum {
          return this._data.get( 'currency' );
     }

     /**
      *
      * @param {CurrencyEnum} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 07:59 pm
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public set currency( value: CurrencyEnum ) {
          this._data.set( 'currency', value );
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 07:59 pm
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public get enabled(): boolean {
          return this._data.get( 'enabled' );
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 07:59 pm
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public set enabled( value: boolean ) {
          this._data.set( 'enabled', value );
     }

     /**
      *
      * @param {DomainModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 07:59 pm
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public setDomain( value: DomainModule ): this {
          this.domain = value;

          return this;
     }

     /**
      *
      * @param {TitleModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 07:59 pm
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public setTitle( value: TitleModule ): this {
          this.title = value;

          return this;
     }

     /**
      *
      * @param {DescriptionModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 07:59 pm
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public setDescription( value: DescriptionModule ): this {
          this.description = value;

          return this;
     }

     /**
      *
      * @param {ImageModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 07:59 pm
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public setImage( value: ImageModule ): this {
          this.image = value;

          return this;
     }

     /**
      *
      * @param {number} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 07:59 pm
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public setPrice( value: number ): this {
          this.price = value;

          return this;
     }

     /**
      *
      * @param {CurrencyEnum} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 07:59 pm
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public setCurrency( value: CurrencyEnum ): this {
          this.currency = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 07:59 pm
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public setEnabled( value: boolean ): this {
          this.enabled = value;

          return this;
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 07:59 pm
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public enable(): this {
          this.enabled = true;

          return this;
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 07:59 pm
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public disable(): this {
          this.enabled = false;

          return this;
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 08:29 pm
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public store(): this {
          ConsoleManager.Warn( MembershipModule.CONSOLE_ENABLED ?? null, MembershipModule.CONSOLE_PREFIX ?? null, 'membership', 'module.store needs to be built' );

          return this;
     }

     /**
      *
      * @return {Record<string, unknown>}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/11/21 07:59 pm
      * @see id
      * @see domain
      * @see domainId
      * @see title
      * @see titleId
      * @see description
      * @see descriptionId
      * @see image
      * @see imageId
      * @see price
      * @see currency
      * @see enabled
      * @see setId
      * @see setDomain
      * @see setTitle
      * @see setDescription
      * @see setImage
      * @see setPrice
      * @see setCurrency
      * @see setEnabled
      * @see enable
      * @see disable
      * @see store
      * @see toObject
      */
     public toObject(): Record<string, unknown> {
          return {
               id         : this.id,
               domain     : this.domain?.toObject(),
               title      : this.title?.toObject(),
               description: this.description?.toObject(),
               image      : this.image?.toObject(),
               price      : this.price,
               currency   : this.currency,
               enabled    : this.enabled,
          };
     }
}