import PropTypes         from 'prop-types';
import React             from 'react';
import { FormEvent }     from 'react';
import { SectionModule } from '../../../api/com/ewing/social/component';
import { SiteModule }    from '../../../api/com/ewing/social/module';
import { BaseContact }   from '../../atom/contact/BaseContact';
import { Button }        from '../../atom/Button';
import { Description }   from '../../atom/Description';
import { Divider }       from '../../atom/Divider';
import { Subtitle }      from '../../atom/Subtitle';
import { Title }         from '../../atom/Title';

export class Contact12 extends BaseContact<any, any> {
     protected static COMPONENT_CLASS: string = 'contact';
     public static propTypes                  = {
          ...BaseContact.propTypes,
          module: PropTypes.instanceOf( SectionModule ).isRequired,
          page  : PropTypes.instanceOf( SiteModule ).isRequired,
     };

     /**
      *
      * @param event {FormEvent}
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/05/21 09:51 pm
      */
     protected onSubmit( event: FormEvent<HTMLFormElement> ): void {
          //
     }

     /**
      *
      * @param props {any}
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am
      * documented
      */
     public constructor( props: any ) {
          super( props );

          this.state = {
               module    : props.module,
               page      : props.page,
               className : new Set<string>( [ 'grid-x', Contact12.COMPONENT_CLASS ] ),
               dimensions: {
                    width : 0,
                    height: 0,
               },
          };

          this.registerProps( this.state.module ?? null );
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am
      * documented
      */
     public render(): JSX.Element {
          return (
               <section { ...this.registerSectionProps() }>
                    <div className="grid-container content">
                         <div className="grid-x align-center">
                              <div className="cell small-12">
                                   <Subtitle module={ this.state?.module?.subtitle } />
                                   <Divider />
                                   <Title module={ this.state?.module?.title } />
                              </div>
                              <div className="cell small-9 medium-8">
                                   <Description module={ this.state?.module?.description } />
                              </div>
                              <div className="cell small-11 medium-6 large-5">
                                   <form id="contactForm">
                                        <div className="grid-x grid-padding-x">
                                             <div className="cell small-6">
                                                  <label htmlFor="contactName">name</label>
                                                  <input type="text" name="[contact]Name" id="contactName" />
                                             </div>
                                             <div className="cell small-6">
                                                  <label htmlFor="contactNumber">number</label>
                                                  <input type="text" name="[contact]Number" id="contactNumber" />
                                             </div>
                                             <div className="cell small-6">
                                                  <label htmlFor="contactCompany">company</label>
                                                  <input type="text" name="[contact]Company" id="contactCompany" />
                                             </div>
                                             <div className="cell small-6">
                                                  <label htmlFor="contactEmail">email</label>
                                                  <input type="text" name="[contact]Email" id="contactEmail" />
                                             </div>
                                             <div className="cell small-12">
                                                  <label htmlFor="contactMessage">message</label>
                                                  <input type="text" name="[contact]Message" id="contactMessage" />
                                             </div>
                                             <div className="cell small-12">
                                                  <Button module={ this.state?.module?.button } />
                                             </div>
                                        </div>
                                   </form>
                              </div>
                         </div>
                    </div>
               </section>
          );
     }
}
