import { DomainModule, EventModule } from '../module';

/**
 * @class DemoManager
 * @author Isaac Ewing
 * @version 1.0.0 01/31/21 11:47 am
 */
export class DemoManager {
     /**
      *
      * @type {Date}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 12:57 pm
      */
     protected static readonly DEFAULT_DATE: Date               = new Date();
     /**
      *
      * @type {DomainModule}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 12:57 pm
      */
     protected static readonly DEFAULT_DOMAIN: DomainModule;
     /**
      *
      * @type {Set<EventModule>}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 12:57 pm
      */
     protected static readonly DEFAULT_EVENTS: Set<EventModule> = new Set<EventModule>(
          [
               EventModule.Build( {
                                       title : 'All Day Event with a super long title',
                                       allDay: true,
                                       start : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 0 ),
                                       end   : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 1 ),
                                  } ),
               EventModule.Build( {
                                       title: 'Long Event',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 7 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 10 ),
                                  } ),
               EventModule.Build( {
                                       title: 'DTS Starts',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 13 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 20 ),
                                  } ),
               EventModule.Build( {
                                       title: 'DTS Ends',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth() + 1, 6 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth() + 1, 13 ),
                                  } ),
               EventModule.Build( {
                                       title: 'Guest appearances',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 9 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 10 ),
                                  } ),
               EventModule.Build( {
                                       title      : 'conference',
                                       start      : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 11 ),
                                       end        : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 12 ),
                                       description: 'Big conference for important people',
                                  } ),
               EventModule.Build( {
                                       title      : 'meeting',
                                       start      : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 12, 10, 30 ),
                                       end        : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 12, 12, 30 ),
                                       description: 'Pre-meting meeting, to prepare for that awesome meeting',
                                  } ),
               EventModule.Build( {
                                       title      : 'lunch',
                                       start      : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 12, 12 ),
                                       end        : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 12, 13 ),
                                       description: 'Power Lunch',
                                  } ),
               EventModule.Build( {
                                       title: 'meeting',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 12, 14 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 12, 15 ),
                                  } ),
               EventModule.Build( {
                                       title: 'happy hour',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 12, 17 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 12, 19, 30 ),
                                  } ),
               EventModule.Build( {
                                       title: 'dinner',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 12, 20 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 12, 21 ),
                                  } ),
               EventModule.Build( {
                                       title: 'planning meeting',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 13, 8 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 13, 10, 30 ),
                                  } ),
               EventModule.Build( {
                                       title: 'inconvenient conference call',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 13, 9, 30 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 13, 12 ),
                                  } ),
               EventModule.Build( {
                                       title: 'project kickoff - randy\'s shoes',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 13, 11, 30 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 13, 14 ),
                                  } ),
               EventModule.Build( {
                                       title: 'quote follow-up - tea with michelle',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 13, 15, 30 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 13, 16 ),
                                  } ),
               EventModule.Build( {
                                       title: 'late night event',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 17, 19, 30 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 18, 2 ),
                                  } ),
               EventModule.Build( {
                                       title: 'same late night event',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 17, 19, 30 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 17, 23, 30 ),
                                  } ),
               EventModule.Build( {
                                       title: 'vacation with chris',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 20, 19, 30 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 22, 2 ),
                                  } ),
               EventModule.Build( {
                                       title: 'call birthday boy david',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 1, 9 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 1, 9, 30 ),
                                  } ),
               EventModule.Build( {
                                       title: 'binge lupin on netflix',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 13, 10 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 13, 22, 30 ),
                                  } ),
               EventModule.Build( {
                                       title: 'stream new game',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 14, 16, 30 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 14, 20 ),
                                  } ),
               EventModule.Build( {
                                       title      : 'lunch with meagan',
                                       start      : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 15, 12, 30 ),
                                       end        : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 15, 13, 30 ),
                                       description: 'Big conference for important people',
                                  } ),
               EventModule.Build( {
                                       title: 'collaborate with creators',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 19, 10, 30 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 19, 17, 30 ),
                                  } ),
               EventModule.Build( {
                                       title: 'online coding challenge',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 21, 10, 30 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 21, 13 ),
                                  } ),
               EventModule.Build( {
                                       title: 'phone interview',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 22, 14, 30 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 22, 15, 30 ),
                                  } ),
               EventModule.Build( {
                                       title: 'cooking class',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 22, 18 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 22, 19, 30 ),
                                  } ),
               EventModule.Build( {
                                       title: 'cooking class',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 23, 18 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 23, 19, 30 ),
                                  } ),
               EventModule.Build( {
                                       title: 'dinner with meagan',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 23, 19, 30 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 23, 21, 30 ),
                                  } ),
               EventModule.Build( {
                                       title: 'cooking class',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 24, 18 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 24, 19, 30 ),
                                  } ),
               EventModule.Build( {
                                       title: 'netflix meeting',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 25, 14, 30 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 25, 16, 30 ),
                                  } ),
               EventModule.Build( {
                                       title: 'investor meeting',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 25, 18 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 25, 19, 30 ),
                                  } ),
               EventModule.Build( {
                                       title: 'meet with celebrities',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 26, 10 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 26, 18 ),
                                  } ),
               EventModule.Build( {
                                       title: 'boring zoom conference call',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 27, 9, 30 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 27, 10, 30 ),
                                  } ),
               EventModule.Build( {
                                       title: 'pick up some gum',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 27, 11 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 27, 12, 30 ),
                                  } ),
               EventModule.Build( {
                                       title: 'go on a date',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 27, 20 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 27, 22 ),
                                  } ),
               EventModule.Build( {
                                       title: 'meeting at the gym',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 28, 8 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 28, 9 ),
                                  } ),
               EventModule.Build( {
                                       title: 'take a shower',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 28, 9, 30 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 28, 10 ),
                                  } ),
               EventModule.Build( {
                                       title: 'photo shoot',
                                       start: new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 28, 10, 30 ),
                                       end  : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 28, 14, 30 ),
                                  } ),
               EventModule.Build( {
                                       title      : 'conference',
                                       start      : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 29, 9 ),
                                       end        : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 29, 18 ),
                                       description: 'Big conference for important people',
                                  } ),
               EventModule.Build( {
                                       title      : 'meeting',
                                       start      : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 30, 10, 30 ),
                                       end        : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 30, 12, 30 ),
                                       description: 'Pre-meting meeting, to prepare for that awesome meeting',
                                  } ),
               EventModule.Build( {
                                       title      : 'lunch',
                                       start      : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 30, 13 ),
                                       end        : new Date( DemoManager.DEFAULT_DATE.getFullYear(), DemoManager.DEFAULT_DATE.getMonth(), 30, 14 ),
                                       description: 'Power Lunch',
                                  } ),
          ] );

     /**
      *
      * @return {Set<EventModule>}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/31/21 11:47 am
      */
     public static getDemoEvents(): Set<EventModule> {
          return DemoManager.DEFAULT_EVENTS;
     }
}