import { IOptionsModule, IOptionsIncludesModule } from '../../interface';

/**
 *
 * @class IncludesModule
 * @implements IOptionsIncludesModule
 * @author Isaac Ewing
 * @version 1.0.0 11/26/20 01:03 pm
 */
export class IncludesModule implements IOptionsIncludesModule {
     /**
      *
      * @type {IOptionsIncludesModule}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 11/26/20 01:03 pm
      */
     protected static defaultOptions: IOptionsIncludesModule = {
          contact: JSON.parse( process.env.REACT_APP_GRAPHQL_OPTIONS_INCLUDE_CONTACT ?? null ),
          invite : JSON.parse( process.env.REACT_APP_GRAPHQL_OPTIONS_INCLUDE_INVITE ?? null ),
     };
     /**
      *
      * @type {IOptionsIncludesModule}
      * @private
      * @author Isaac Ewing
      * @version 1.0.0 11/26/20 01:03 pm
      */
     private data: IOptionsIncludesModule;

     /**
      *
      * @param contact {boolean}
      * @param invite {boolean}
      * @return {IncludesModule}
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 11/26/20 01:03 pm
      * @version 1.0.1 12/19/20 11:12 pm added invite
      */
     public static Build( contact?: boolean, invite?: boolean ): IncludesModule {
          return new IncludesModule( contact, invite );
     }

     /**
      *
      * @param contact {boolean}
      * @param invite {boolean}
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 11/26/20 01:03 pm
      * @version 1.0.1 12/19/20 11:12 pm added invite
      */
     public constructor( contact?: boolean, invite?: boolean ) {
          this.data = {
               contact: contact ?? IncludesModule.defaultOptions.contact,
               invite : invite ?? IncludesModule.defaultOptions.invite,
          };
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 11/26/20 01:03 pm
      */
     public get contact(): boolean {
          return this.data.contact;
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.0.1 12/19/20 11:12 pm
      */
     public get invite(): boolean {
          return this.data.invite;
     }
}

/**
 *
 * @class OptionsModule
 * @implements IOptionsModule
 * @author Isaac Ewing
 * @version 1.0.0 11/26/20 01:11 pm
 */
export class OptionsModule implements IOptionsModule {
     /**
      *
      * @type {IOptionsModule}
      * @private
      * @author Isaac Ewing
      * @version 1.0.0 11/26/20 01:11 pm
      */
     private data: IOptionsModule;

     /**
      *
      * @param {IOptionsIncludesModule} includes
      * @return {OptionsModule}
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 11/26/20 01:11 pm
      */
     public static Build( includes?: IOptionsIncludesModule ): OptionsModule {
          return new OptionsModule( includes );
     }

     /**
      *
      * @param {IOptionsIncludesModule} includes
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 11/26/20 01:11 pm
      */
     public constructor( includes?: IOptionsIncludesModule ) {
          let tempIncludes: IOptionsIncludesModule;

          if( includes ) {
               tempIncludes = IncludesModule.Build( includes?.contact, includes?.invite );
          }

          this.data = {
               includes: tempIncludes ?? IncludesModule.Build(),
          };
     }

     /**
      *
      * @return {IOptionsIncludesModule}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 11/26/20 01:11 pm
      */
     public get includes(): IOptionsIncludesModule {
          return this.data.includes;
     }
}