/**
 * @enum ColorEnum
 * @author Isaac Ewing
 * @version 1.0.0 06/02/21 02:19 pm
 */
export enum ColorEnum {
    /**
     *
     * @type {ColorEnum}
     * @author Isaac Ewing
     * @version 1.0.0 06/02/21 02:19 pm
     */
    BrightGreen  = '#1abc27',
    /**
     *
     * @type {ColorEnum}
     * @author Isaac Ewing
     * @version 1.0.0 06/02/21 02:19 pm
     */
    BrightRed    = '#ff0000',
    /**
     *
     * @type {ColorEnum}
     * @author Isaac Ewing
     * @version 1.0.0 06/02/21 02:19 pm
     */
    BrightYellow = '#ffff00',
    /**
     *
     * @type {ColorEnum}
     * @author Isaac Ewing
     * @version 1.0.0 06/02/21 02:19 pm
     */
    DarkBlue     = '#336699',
    /**
     *
     * @type {ColorEnum}
     * @author Isaac Ewing
     * @version 1.0.0 06/02/21 02:19 pm
     */
    DarkGrey     = '#5a6469',
    /**
     *
     * @type {ColorEnum}
     * @author Isaac Ewing
     * @version 1.0.0 06/02/21 02:19 pm
     */
    DarkRed      = '#bd5d5d',
    /**
     *
     * @type {ColorEnum}
     * @author Isaac Ewing
     * @version 1.0.0 06/02/21 06:55 pm
     */
    DarkYellow   = '#a09b1e',
    /**
     *
     * @type {ColorEnum}
     * @author Isaac Ewing
     * @version 1.0.0 06/02/21 06:55 pm
     */
    Gold         = '#d4af37',
    /**
     *
     * @type {ColorEnum}
     * @author Isaac Ewing
     * @version 1.0.0 06/02/21 07:32 pm
     */
    LightGrey    = '#bababa',
    /**
     *
     * @type {ColorEnum}
     * @author Isaac Ewing
     * @version 1.0.0 06/02/21 02:19 pm
     */
    TealBlue     = '#61dbfb',
    /**
     *
     * @type {ColorEnum}
     * @author Isaac Ewing
     * @version 1.0.0 06/02/21 02:19 pm
     */
    White        = 'white',
    /**
     *
     * @type {ColorEnum}
     * @author Isaac Ewing
     * @version 1.0.0 07/25/21 09:20 pm
     */
    Black        = 'black',
}