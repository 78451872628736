import { ShortcodeModule } from '../../api/com/ewing/social/component';
import { ComponentEnum }   from '../../api/com/ewing/social/enum';
import { LinkModule }      from '../../api/com/ewing/social/module';
import { Link }            from './Link';

/**
 *
 * @class FactoryAtom
 * @author Isaac Ewing
 * @version 1.0.0 08/04/21 06:46 pm
 */
export class FactoryAtom {
    /**
     *
     * @param {ShortcodeModule} module
     * @return {JSX.Element | null}
     * @constructor
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 08/03/21 08:47 pm
     */
    public static FromShortcode( module: ShortcodeModule ): JSX.Element | null {
        switch( module.type ) {
            case ComponentEnum.Link:
                console.log( 'FACTORY ATOM LINK', { module } );
                return <Link key={ module.key } id={ module.hash } module={ module.module as LinkModule } { ...module.toComponentSpread() } />;
            default:
                return <span>type: { module.type }, id: { module.id }</span>;
        }
    }
}