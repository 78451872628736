import React     from 'react';
import PropTypes from 'prop-types';
import { Util }  from '../../../api/com/ewing/social/tool/util';

/**
 * @class Checkbox
 * @extends Component
 * @author Isaac Ewing
 * @version 1.0.0 02/17/21 05:27 pm
 * @classdesc This class builds a single checkbox component with functionality for the site
 */
export class Checkbox extends React.Component<any, any> {
     protected static readonly COMPONENT_CLASS: string  = 'contact-13';
     protected static readonly CLASS_SHOW_ERROR: string = 'show-error';
     public static propTypes                            = {
          className         : PropTypes.object,
          'data-id'         : PropTypes.string.isRequired,
          'data-prefix'     : PropTypes.string,
          'data-placeholder': PropTypes.string,
          'data-error'      : PropTypes.string,
          'data-required'   : PropTypes.bool,
          'data-isBlank'    : PropTypes.bool,
          'data-isError'    : PropTypes.bool,
     };

     /**
      *
      * @param {string} value
      * @param {boolean} addBrackets
      * @return {string}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/17/21 05:27 pm
      */
     protected autoPrefix( value: string, addBrackets?: boolean ): string {
          return Util.getHashId( this.state.data.prefix, value, addBrackets );
     }

     /**
      *
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/15/21 02:31 pm
      */
     protected onIsChange(): void {
          const data = {
               ...this.state.data,
               isBlankClassName: this.props[ 'data-isBlank' ] ? Checkbox.CLASS_SHOW_ERROR : null,
               isErrorClassName: this.props[ 'data-isError' ] ? Checkbox.CLASS_SHOW_ERROR : null,
          };

          this.setState( { data } );
     }

     /**
      *
      * @param props {any}
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/17/21 05:27 pm
      */
     public constructor( props: any ) {
          super( props );

          console.info( 'CHECKBOX CONSTRUCT', { props, module: props.module, page: props.page, className: props.className ?? null } );

          this.state = {
               module   : props.module,
               page     : props.page,
               className: props.className ?? null,
               children : null,
               data     : {
                    id              : this.props[ 'data-id' ] ?? Util.registerHash(),
                    prefix          : this.props[ 'data-prefix' ] ?? process.env.REACT_APP_CONTACT_ID_PREFIX,
                    placeholder     : this.props[ 'data-placeholder' ] ?? null,
                    error           : this.props[ 'data-error' ] ?? `The ${ this.props[ 'data-id' ] } field in invalid.`,
                    isBlankClassName: this.props[ 'data-isBlank' ] ? Checkbox.CLASS_SHOW_ERROR : null,
                    isErrorClassName: this.props[ 'data-isError' ] ? Checkbox.CLASS_SHOW_ERROR : null,
               },
          };
     }

     /**
      *
      * @param {Readonly<any>} prevProps
      * @param {Readonly<any>} prevState
      * @param snapshot
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/05/21 01:22 pm
      */
     public componentDidUpdate( prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any ): void {
          const changeBlank: boolean = prevProps[ 'data-isBlank' ] !== this.props[ 'data-isBlank' ];
          const changeError: boolean = prevProps[ 'data-isError' ] !== this.props[ 'data-isError' ];

          if( changeBlank || changeError ) {
               this.onIsChange();
          }
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/17/21 05:27 pm
      */
     public render(): JSX.Element {
          const propsInput = {
               type       : 'checkbox',
               name       : `${ this.autoPrefix( this.state.data.id, true ) }`,
               id         : `${ this.autoPrefix( this.state.data.id ) }`,
               className  : `switch-input`,
               placeholder: null,
          };

          return (
               <>
                    <label className={ `label-checkbox` } htmlFor={ `${ this.autoPrefix( this.state.data.id ) }` }>{ this.state.data.id }</label>
                    <div className={ `switch large` }>
                         <input { ...propsInput } />
                         <label className={ `switch-paddle` } htmlFor={ `${ this.autoPrefix( this.state.data.id ) }` }>
                              <span className={ 'show-for-sr' }>{ this.state.data.id }</span>
                              <span className={ 'switch-active' } aria-hidden="true">Yes</span>
                              <span className={ 'switch-inactive' } aria-hidden="true">No</span>
                         </label>
                    </div>
               </>
          );
     }
}
