import { AModule }                          from '../../abstract/module.abstract';
import { IParseModule, IViewerModule }      from '../../interface/';
import { ViewerOptionsModule, ParseModule } from './viewer';

/**
 * @class ViewerModule
 * @extends AModule
 * @implements IViewerModule
 * @author Isaac Ewing
 * @version 1.0.0 12/16/20 04:48 pm
 * @version 1.1.0 02/25/21 02:48 pm - updated to extend AComponent
 */
export class ViewerModule extends AModule implements IViewerModule {
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.1.0 02/25/21 02:48 pm
      */
     protected static readonly CONSOLE_ENABLED: boolean = false;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.1.0 02/25/21 02:48 pm
      */
     protected static readonly DEFAULT_FILE: string     = process.env.REACT_APP_VIEWER_FILE;

     /**
      *
      * @param module {ViewerModule} The object containing the data
      * @return {ViewerModule|null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/25/21 02:48 pm
      * @see id
      * @see file
      * @see options
      * @see setFile
      * @see setOptions
      * @see toObject
      */
     public static Build( module: ViewerModule ): ViewerModule;
     public static Build( obj: Partial<IViewerModule> ): ViewerModule;
     public static Build( json: string ): ViewerModule;
     public static Build( id?: number | string, file?: string, options?: ParseModule ): ViewerModule;
     public static Build( id?: number | string, file?: string, options?: Partial<IParseModule> ): ViewerModule;
     public static Build( dataOrId?: unknown, file?: string, options?: unknown ): ViewerModule {
          if( dataOrId ) {
               if( dataOrId instanceof ViewerModule ) {
                    return dataOrId;
               }
               if( typeof dataOrId === 'object' ) {
                    const local: Partial<IViewerModule> = dataOrId;

                    if( this.CONSOLE_ENABLED ) {
                         console.log( '{{ }} VIEWER MODULE', 'build object', { dataOrId } );
                    }

                    if( typeof local?.text === 'string' ) {
                         try {
                              const opts: Record<string, Record<string, unknown>> = JSON.parse( local.text );
                              const localOptions: Record<string, number | string> = { id: local?.id ?? null, ...opts?.options };

                              if( this.CONSOLE_ENABLED ) {
                                   console.log( '{{ }} VIEWER MODULE', 'build object', { local, opts, localOptions } );
                              }

                              return new ViewerModule( localOptions?.id ?? null, opts?.file as unknown as string ?? null, ViewerOptionsModule.Build( localOptions ) );
                         } catch( exception ) {
                              if( this.CONSOLE_ENABLED ) {
                                   console.log( '{{ }} VIEWER MODULE', 'build object EXCEPTION', exception );
                              }

                              return new ViewerModule( local?.id ?? null, local?.text ?? null, null );
                         }
                    }

                    if( this.CONSOLE_ENABLED ) {
                         console.log( '{{ }} VIEWER MODULE', 'build object does not have text property ' );
                    }

                    return new ViewerModule( local?.id ?? null, local?.file ?? null, ViewerOptionsModule.Build( local?.options ) );
               }
               if( typeof dataOrId === 'number' ) {
                    return new ViewerModule( +dataOrId ?? null, file ?? null, ViewerOptionsModule.Build( options ) ?? null );
               }
               if( typeof dataOrId === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrId ) );
                    } catch( exception ) {
                         // not a valid json string
                         return new ViewerModule( dataOrId ?? null, file ?? null, ViewerOptionsModule.Build( options ) ?? null );
                    }
               }
          }

          return null;
     }

     /**
      *
      * @param {number | string} id
      * @param {string} file
      * @param {ViewerOptionsModule} options
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.1.0 02/25/21 02:48 pm
      * @see id
      * @see file
      * @see options
      * @see setFile
      * @see setOptions
      * @see toObject
      */
     public constructor( id?: number | string, file?: string, options?: ViewerOptionsModule ) {
          super( id ?? null );

          this.file    = file ?? ViewerModule.DEFAULT_FILE;
          this.options = options ?? null;
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/16/20 04:48 pm
      * @see id
      * @see file
      * @see options
      * @see setFile
      * @see setOptions
      * @see toObject
      */
     public get file(): string {
          return this._data.get( 'file' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/16/20 04:48 pm
      * @see id
      * @see file
      * @see options
      * @see setFile
      * @see setOptions
      * @see toObject
      */
     public set file( value: string ) {
          this._data.set( 'file', value );
     }

     /**
      *
      * @return {ViewerOptionsModule}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/16/20 04:48 pm
      * @see id
      * @see file
      * @see options
      * @see setFile
      * @see setOptions
      * @see toObject
      */
     public get options(): ViewerOptionsModule {
          return this._data.get( 'options' );
     }

     /**
      *
      * @param {ViewerOptionsModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/16/20 04:48 pm
      * @see id
      * @see file
      * @see options
      * @see setFile
      * @see setOptions
      * @see toObject
      */
     public set options( value: ViewerOptionsModule ) {
          this._data.set( 'options', value );
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/16/20 04:48 pm
      * @see id
      * @see file
      * @see options
      * @see setFile
      * @see setOptions
      * @see toObject
      */
     public setFile( value: string ): this {
          this.file = value;

          return this;
     }

     /**
      *
      * @param {ViewerOptionsModule} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/16/20 04:48 pm
      * @see id
      * @see file
      * @see options
      * @see setFile
      * @see setOptions
      * @see toObject
      */
     public setOptions( value: ViewerOptionsModule ): this {
          this.options = value;

          return this;
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/16/20 04:48 pm
      * @see id
      * @see file
      * @see options
      * @see setFile
      * @see setOptions
      * @see toObject
      */
     public toObject(): Record<string, unknown> {
          return {
               id     : this.id,
               file   : this.file,
               options: this.options.toObject(),
          };
     }
}