import jQuery                                  from 'jquery';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import {
    IAmount, IItem, ISectionModule, ISectionProps,
    IShipping, IUnit,
}                                              from '../../../api/com/ewing/social/interface';
import { ItemCategoryEnum }                    from '../../../api/com/ewing/social/enum';
import { ConsoleManager }                      from '../../../api/com/ewing/social/manager';
import { AmountModule }                        from '../../../api/com/ewing/social/module';
import { DonateModule }                        from '../../../api/com/ewing/social/component';
import { RegisterTool, Util }                  from '../../../api/com/ewing/social/tool';
import { BaseSection }                         from '../../atom/BaseSection';
import { Subtitle }                            from '../../atom/Subtitle';
import { Title }                               from '../../atom/Title';
import { Amount }                              from '../../molecule';

/**
 * @class Donate1
 * @extends BaseSection
 * @author Isaac Ewing
 * @version 1.0.0 02/04/21 12:44 pm
 * @classdesc This class handles the donation section of the site using paypal
 */
export class Donate1 extends BaseSection {
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/04/21 12:44 pm
     */
    protected static readonly COMPONENT_CLASS: string      = 'donate-1';
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 07/31/21 06:37 pm
     */
    protected static readonly CONSOLE_PREFIX: string       = `${ process.env.REACT_APP_CONSOLE_PREFIX_COMPONENT } DONO ${ process.env.REACT_APP_CONSOLE_SUFFIX_COMPONENT }`;
    /**
     *
     * @type {boolean}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/23/21 08:52 pm
     */
    protected static readonly CONSOLE_ENABLED: boolean     = false;
    protected static readonly CLASS_IS_SELECTED: string    = 'is-selected';
    protected static readonly CLASS_IS_SHOW: string        = 'is-show';
    protected static readonly CLASS_AMOUNT_BUTTONS: string = 'amount-buttons';
    protected static readonly MAX_BUTTONS_LARGE: number    = 5;
    protected static readonly CLASS_FEEDBACK               = {
        container  : 'feedback-container',
        text       : 'feedback-text',
        transaction: 'feedback-transaction',
    };

    /**
     *
     * @param props {SectionModule}
     * @return {void}
     * @throws Throws an exception if method is called without having an override
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 02/04/21 11:01 am
     */
    protected registerPropsCall( props: ISectionModule ): void {
        RegisterTool.style( props, this.state );
        RegisterTool.curve( props, this.state );
        RegisterTool.submenus( props, this.state );
        RegisterTool.classes( props, this.state );
        this.registerAmounts( props );
    }

    /**
     *
     * @param {SectionModule} props
     * @return {void}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/12/20 01:25 pm
     */
    protected registerAmounts( props: ISectionModule ): void {
        const hash: string                 = Util.registerHash();
        const components: Set<JSX.Element> = new Set<JSX.Element>();
        const hashes: Set<string>          = new Set<string>();
        const donate: DonateModule         = DonateModule.Build( props?.description?.text );
        const index: number                = null;
        let count: number                  = null;
        let cheapest: number               = null;

        donate.amounts.forEach( ( amount: AmountModule ): void => {
            if( cheapest !== null ) {
                if( amount.amount < cheapest ) {
                    cheapest = amount.amount;
                }
            } else {
                count    = 0;
                cheapest = amount.amount;
            }
        } );
        donate.amounts.forEach( ( amount: AmountModule ): void => {
            const localHash: string = `${ hash }-${ count }`;
            const props             = {
                id       : localHash,
                key      : localHash,
                module   : amount,
                className: amount.amount !== cheapest ? null : Donate1.CLASS_IS_SELECTED,
            };

            if( count >= Donate1.MAX_BUTTONS_LARGE ) {
                if( props.className ) {
                    props.className += ' hide-for-medium';
                } else {
                    props.className = ' hide-for-medium';
                }
            }

            count++;
            hashes.add( localHash );
            components.add( <Amount { ...props } /> );
        } );

        if( Donate1.CONSOLE_ENABLED ) {
            console.log( 'AMOUNTS INDEX', index, 'AMOUNTS CHEAPEST', cheapest );
        }

        this.state.donation.index    = index;
        this.state.donation.amount   = cheapest;
        this.state.donation.cheapest = cheapest;
        this.state.donation.hashes   = hashes;
        this.state.children          = new Set<JSX.Element>( [ ...this.state.children, ...components ] );
    }

    /**
     *
     * @param {ClickEvent<HTMLElement>} event
     * @return {void}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/13/20 12:52 pm
     */
    protected registerClick( event: JQuery.ClickEvent<HTMLElement> ): void {
        event.preventDefault();
        console.log( 'JQUERY EVENT', event );
        const button: JQuery<HTMLElement> = jQuery( `#${ event.currentTarget.id }` );
        const amount: number              = button.data( 'amount' );

        console.log( 'EVENT', event, 'ID', event.currentTarget.id );
        console.log( 'state BEFORE', this.state.donation );

        this.state.donation.amounts.forEach( ( amount ) => amount.removeClass( Donate1.CLASS_IS_SELECTED ) );
        this.state.donation.amount = amount;
        button.addClass( Donate1.CLASS_IS_SELECTED ?? null );
        console.log( 'state AFTER', this.state.donation );
    }

    /**
     *
     * @param data {Object}
     * @param actions {{order:{},payment:{}}}
     * @return {string}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/12/20 01:25 pm
     */
    protected registerOrder( data, actions ): string {
        console.log( 'ORDER DATA', data );
        console.log( 'ORDER ACTIONS', actions );
        console.log( 'ORDER donation', this.state?.donation );

        const amount: number   = this.state?.donation?.amount ?? 0;
        const currency: string = String( process.env.REACT_APP_CURRENCY_AMERICA ).toUpperCase();
        const amounts: IAmount = {
            value        : amount,
            currency     : currency,
            currency_code: currency,
            breakdown    : {
                item_total: {
                    value        : amount,
                    currency_code: currency,
                },
                shipping  : {
                    value        : 0,
                    currency_code: currency,
                },
                tax_total : {
                    value        : 0,
                    currency_code: currency,
                },
            },

        };
        const item: IItem      = {
            name       : 'Donation',
            category   : ItemCategoryEnum.Digital,
            quantity   : 1,
            sku        : Util.registerHash(),
            description: `Donation for ${ amount } ${ currency }`,
            unit_amount: {
                value        : amount,
                currency_code: currency,
            },
        };
        const ship: IShipping  = {
            name   : {
                full_name: `Donation for ${ this.state.page.domain.text }`,
            },
            address: {
                address_line_1: `${ process.env.REACT_APP_MAP_ADDRESS_STREET }`,
                address_line_2: `${ process.env.REACT_APP_MAP_ADDRESS_SUITE }`,
                admin_area_1  : `${ process.env.REACT_APP_MAP_ADDRESS_CITY }`,
                admin_area_2  : 'DE',
                country_code  : 'US',
                postal_code   : `${ process.env.REACT_APP_MAP_ADDRESS_ZIP }`,
            },
        };
        const unit: IUnit      = {
            amount         : amounts,
            soft_descriptor: `Ewing Social`,
            description    : `Donation for ${ this.state.page.domain.text } for ${ amount } ${ currency }`,
            items          : [ item ],
            shipping       : ship,
        };

        if( Donate1.CONSOLE_ENABLED ) {
            console.log( 'ORDER ITEM', item );
            console.log( 'ORDER UNIT', unit );
            console.log( 'ORDER Description', `Donation for ${ this.state.page.domain.text } for ${ amount } ${ currency }` );
        }

        return actions.order
                      .create( {
                                   /*
                                    payer         : {
                                    name         : {
                                    given_name: 'test',
                                    surname   : 'react',
                                    },
                                    email_address: 'isaacewing.com@gmail.com',
                                    birth_date   : '1985-03-15',
                                    },

                                    */
                                   purchase_units: [ unit ],
                               } )
                      .then( ( orderID: string ) => {
                          console.log( 'ORDER ID', orderID );
                          this.state.donation.id = orderID;

                          return orderID;
                      } );
    }

    /**
     *
     * @param data {Object}
     * @param actions {{order:{},payment:{}}}
     * @return {void}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/13/20 01:01 pm
     */
    protected registerApprove( data, actions ): void {
        const amountButtons: JQuery<HTMLElement>       = jQuery( `.${ Donate1.CLASS_AMOUNT_BUTTONS }` );
        const feedbackContainer: JQuery<HTMLElement>   = jQuery( `.${ Donate1.CLASS_FEEDBACK.container }` );
        const feedbackText: JQuery<HTMLElement>        = jQuery( `.${ Donate1.CLASS_FEEDBACK.text }` );
        const feedbackTransaction: JQuery<HTMLElement> = jQuery( `.${ Donate1.CLASS_FEEDBACK.transaction }` );

        ConsoleManager.Log( Donate1.CONSOLE_ENABLED ?? null, Donate1.CONSOLE_PREFIX ?? null, 'Approve', 'calling register approve', { data, actions } );

        feedbackText.html( 'working...' );
        feedbackTransaction.html( '' );
        feedbackContainer.add( amountButtons ).addClass( Donate1.CLASS_IS_SHOW );

        return actions.order
                      .capture()
                      .then( ( details ) => {
                          if( Donate1.CONSOLE_ENABLED ) {
                              console.log( 'ORDER DETAILS', details );
                          }

                          feedbackText.html( `thank you so much!<br>your confirmation number is:` );
                          feedbackTransaction.html( `${ this.state?.donation?.id ?? details?.id ?? Util.registerHash() }` );
                      } );
    }

    /**
     *
     * @param props {any}
     * @return {void}
     * @constructor
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 01/30/21 11:30 am
     * documented
     */
    public constructor( props: ISectionProps ) {
        super( props );

        this.state = {
            data     : {
                paypal: {
                    options   : {
                        options: {
                            'client-id': Util.isLive( `${ process.env.REACT_APP_LIVE_PAYPAL_KEY }`, `${ process.env.REACT_APP_DEV_PAYPAL_KEY }` ),
                            //currency   : String( process.env.REACT_APP_CURRENCY_AMERICA ).toUpperCase(),
                        },
                    },
                    properties: {
                        style: {
                            layout : 'vertical',
                            shape  : 'rect',
                            label  : 'paypal',
                            tagline: false,
                        },
                        // createOrder: this.registerOrder,
                    },
                },
            },
            donation : {
                id      : null,
                index   : null,
                amount  : null,
                cheapest: null,
                hashes  : null,
                amounts : null,
            },
            className: new Set<string>( [ 'grid-x', Donate1.COMPONENT_CLASS ] ),
            children : new Set<JSX.Element>(),
        };

        if( Donate1.CONSOLE_ENABLED ) {
            console.log( ' @@@ CALLING CONSTRUCTOR DONATE - 1' );
        }

        this.registerClick   = this.registerClick.bind( this );
        this.registerOrder   = this.registerOrder.bind( this );
        this.registerApprove = this.registerApprove.bind( this );
        this.registerProps( props ?? null );
    }

    /**
     * @return {void}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/12/20 01:25 pm
     */
    public componentDidMount(): void {
        if( Donate1.CONSOLE_ENABLED ) {
            console.log( ' @@@ CALLING ON - MOUNT DONATE - 1' );
        }

        const hashes: Set<string>               = this.state.donation.hashes;
        const amounts: Set<JQuery<HTMLElement>> = new Set<JQuery<HTMLElement>>();

        hashes.forEach( ( hash: string ) => {
            const element: JQuery<HTMLElement> = jQuery( `#${ hash }` );

            amounts.add( element );
            element.on( 'click', ( event ) => {
                event.preventDefault();
                this.registerClick( event );

                if( Donate1.CONSOLE_ENABLED ) {
                    console.log( 'CLICK #' + event.currentTarget.id );
                }
            } );
        } );
        this.state.donation.amounts = amounts;
    }

    /**
     *
     * @return {JSX.Element}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/09/20 12:15 pm
     * TODO: 12/09/20 add support for action buttons on the right side in a column format
     */
    public render(): JSX.Element {
        console.log( ' @@@ CALLING RENDER DONATE - 1' );

        let title: JSX.Element;
        let subtitle: JSX.Element;

        if( this.state?.module?.title ) {
            title = <Title key={ Util.registerHash() } module={ this.state?.module?.title } />;
        }
        if( this.state?.module?.subtitle ) {
            subtitle = <Subtitle key={ Util.registerHash() } module={ this.state?.module?.subtitle } />;
        }

        return (
            <section { ...this.registerSectionProps() }>
                <div className="grid-container content">
                    <div className="grid-x align-left">
                        <div className="cell small-12 medium-12">
                            { title }
                            { subtitle }
                        </div>
                        <div className="cell small-12 medium-12 paypal amount-buttons">
                            { this.state.children }
                        </div>
                        <div className="cell small-12 medium-12 paypal">
                            <div className="feedback-container">
                                <p className="feedback-text" />
                                <p className="feedback-transaction" />
                            </div>
                            <PayPalScriptProvider { ...this.state?.data?.paypal?.options }>
                                <PayPalButtons createOrder={ this.registerOrder } onApprove={ this.registerApprove } { ...this.state?.data?.paypal?.properties } />
                            </PayPalScriptProvider>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

}
