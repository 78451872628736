import PropTypes         from 'prop-types';
import { RegisterTool }  from '../../api/com/ewing/social/tool';
import { Base }          from './Base';
import {
    IBaseProps, ISectionModule,
    ISectionProps, ISectionSectionProps,
    ISectionState,
}                        from '../../api/com/ewing/social/interface';
import { SectionModule } from '../../api/com/ewing/social/component/organism';
import { SiteModule }    from '../../api/com/ewing/social/module/api';

/**
 * @class BaseSection
 * @extends Base
 * @abstract
 * @author Isaac Ewing
 * @version 1.0.0 01/30/21 11:30 am - documented
 */
export abstract class BaseSection<P = ISectionProps, S = ISectionState> extends Base {
    protected static COMPONENT_CLASS: string            = null;
    protected static readonly CLASS_TOP_CURVE: string   = 'top-curve';
    protected static readonly CLASS_HAS_SUBMENU: string = 'has-sticky-submenu';
    protected static readonly CLASS_SHOW_ERROR: string  = 'show-error';
    public static propTypes                             = {
        ...Base.propTypes,
        module: PropTypes.instanceOf( SectionModule ).isRequired,
        page  : PropTypes.instanceOf( SiteModule ).isRequired,
        curve : PropTypes.bool,
    };
    /**
     *
     * @type {Partial<ISectionProps>}
     * @readonly
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 05/31/21 03:38 pm
     */
    public readonly props: Partial<P>;
    /**
     *
     * @type {Partial<ISectionState>}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 05/31/21 03:38 pm
     */
    public state: Partial<S>;

    /**
     *
     * @param props {SectionModule}
     * @return {void}
     * @throws Throws an exception if method is called without having an override
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 01/30/21 11:30 am
     * documented
     */
    protected registerPropsCall( props: ISectionModule ): void {
        RegisterTool.style( props ?? null, this.state ?? null );
        RegisterTool.curve( props ?? null, this.state ?? null );
        RegisterTool.submenus( props ?? null, this.state ?? null );
        RegisterTool.classes( props ?? null, this.state ?? null );
    }

    /**
     *
     * @param {IBaseProps} base
     * @return {void}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 01/30/21 11:30 am - documented
     * @deprecated use <code>RegisterTool.curve</code>
     * @since 1.0.0 07/31/21 06:24 pm
     */
    protected registerCurve( base: IBaseProps ): void;
    protected registerCurve( section: ISectionModule ): void;
    protected registerCurve( record: Record<string, never> ): void;
    protected registerCurve( data: any ): void {
        if( data.curve ) {
            this.state.className.add( BaseSection.CLASS_TOP_CURVE );
        }
    }

    /**
     *
     * @param {IBaseProps} base
     * @return {void}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 01/30/21 11:30 am - documented
     * @deprecated use <code>RegisterTool.curve</code>
     * @since 1.0.0 07/31/21 06:29 pm
     */
    protected registerSubmenus( base: IBaseProps ): void;
    protected registerSubmenus( section: ISectionModule ): void;
    protected registerSubmenus( record: Record<string, never> ): void;
    protected registerSubmenus( data: any ): void {
        if( data.submenu ) {
            this.state.className.add( BaseSection.CLASS_HAS_SUBMENU );
        }
    }

    /**
     *
     * @param {ISectionModule} section
     * @return {ISectionSectionProps}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 01/30/21 11:30 am - documented
     */
    protected registerSectionProps( section?: ISectionModule ): ISectionSectionProps {
        const hash: string = this.state?.hash ?? this.registerHash();
        const key: string  = `section-${ section?.id ?? this.props?.module?.id ?? hash }`;

        return {
            key,
            id       : key,
            className: [ ...this.state.className ].join( ' ' ),
        };
    }
}