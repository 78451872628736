import { IAccount, IUser } from '../../interface';
import { PlatformModule }  from './platform.module';
import { ProfileModule }  from './profile.module';
import { TitleModule }    from './title.module';
//import { UserModule }     from './user.module';
import { StorageManager } from '../../manager';

/**
 * @class AccountModule
 * @extends TitleModule
 * @implements IAccount
 * @author Isaac Ewing
 * @version 1.0.0 05/24/21 04:21 pm
 * @version 2.0.0 05/27/21 09:27 pm - converted from AComponent to TitleModule
 */
export class AccountModule extends TitleModule implements IAccount {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 04:21 pm
      */
     protected static readonly CONSOLE_PREFIX: string   = `${ process.env.REACT_APP_CONSOLE_PREFIX_MODULE } ACCO ${ process.env.REACT_APP_CONSOLE_SUFFIX_MODULE }`;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 04:21 pm
      */
     protected static readonly CONSOLE_ENABLED: boolean = false;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 04:21 pm
      */
     protected static readonly API_ROOT_NODE: string    = process.env.REACT_APP_GRAPHQL_GET_ACCOUNT;
     /**
      *
      * @type {AccountModule}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 04:21 pm
      */
     protected static fromStorage: AccountModule;

     /**
      *
      * @param module {AccountModule} The object containing the data
      * @return {AccountModule | null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 11:40 am
      * @see Build
      * @see id
      * @see user
      * @see platform
      * @see profile
      * @see setUser
      * @see setPlatform
      * @see setProfile
      * @see store
      * @see toObject
      * @see FromStorage
      */
     public static Build( module: AccountModule ): AccountModule;
     public static Build( obj: Partial<IAccount> ): AccountModule;
     public static Build( json: string ): AccountModule;
     public static Build( id?: number, user?: IUser, platform?: PlatformModule, profile?: ProfileModule ): AccountModule;
     public static Build( id?: number, user?: unknown, platform?: PlatformModule, profile?: ProfileModule ): AccountModule;
     public static Build( dataOrId?: unknown, user?: IUser, platform?: PlatformModule, profile?: ProfileModule ): AccountModule {
          if( dataOrId ) {
               dataOrId = this.mapAPIRootNode( dataOrId, this.API_ROOT_NODE );

               if( dataOrId instanceof AccountModule ) {
                    return dataOrId;
               }
               if( typeof dataOrId === 'object' ) {
                    const localData: IAccount = dataOrId as IAccount;

                    return new AccountModule( +localData?.id ?? null, null, PlatformModule.Build( localData?.platform ) ?? null,
                                              ProfileModule.Build( localData?.profile ) ?? null );
               }
               if( typeof dataOrId === 'number' ) {
                    return new AccountModule( dataOrId ?? null, null, PlatformModule.Build( platform ) ?? null,
                                              ProfileModule.Build( profile ) ?? null );
               }
               if( typeof dataOrId === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrId ) );
                    } catch( exception ) {
                         // not a valid json string
                         return new AccountModule( +dataOrId ?? null,  null, PlatformModule.Build( platform ) ?? null,
                                                   ProfileModule.Build( profile ) ?? null );
                    }
               }
          }

          return null;
     }

     /**
      *
      * @return {AccountModule | null}
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 11:40 am
      * @see id
      * @see user
      * @see platform
      * @see profile
      * @see setUser
      * @see setPlatform
      * @see setProfile
      * @see store
      * @see toObject
      */
     public static FromStorage(): AccountModule | null {
          if( this.fromStorage ) {
               return this.fromStorage;
          }
          if( StorageManager.accountModel ) {
               this.fromStorage = AccountModule.Build( StorageManager.accountModel ?? null );
          } else if( StorageManager.accountId && StorageManager.accountUser && StorageManager.accountPlatform && StorageManager.accountProfile ) {
               this.fromStorage = new AccountModule( StorageManager.accountId ?? null, null,
                                                     PlatformModule.Build( StorageManager.accountPlatform ) ?? null, ProfileModule.Build( StorageManager.accountProfile ) ?? null );
          }

          return this.fromStorage ?? null;
     }

     /**
      *
      * @param {number} id
      * @param {UserModule} user
      * @param {PlatformModule} platform
      * @param {ProfileModule} profile
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 04:21 pm
      * @see id
      * @see user
      * @see platform
      * @see profile
      * @see setUser
      * @see setPlatform
      * @see setProfile
      * @see store
      * @see toObject
      */
     public constructor( id?: number, user?: null, platform?: PlatformModule, profile?: ProfileModule ) {
          super( id ?? null );

          this.user     = user ?? null;
          this.platform = platform ?? null;
          this.profile  = profile ?? null;
     }

     /**
      *
      * @return {UserModule}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 04:21 pm
      * @see id
      * @see user
      * @see platform
      * @see profile
      * @see setUser
      * @see setPlatform
      * @see setProfile
      * @see store
      * @see toObject
      */
     public get user(): null {
          return this._data.get( 'user' );
     }

     /**
      *
      * @param {UserModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 04:21 pm
      * @see id
      * @see user
      * @see platform
      * @see profile
      * @see setUser
      * @see setPlatform
      * @see setProfile
      * @see store
      * @see toObject
      */
     public set user( value: null ) {
          this._data.set( 'user', value );
     }

     /**
      *
      * @return {PlatformModule}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 04:21 pm
      * @see id
      * @see user
      * @see platform
      * @see profile
      * @see setUser
      * @see setPlatform
      * @see setProfile
      * @see store
      * @see toObject
      */
     public get platform(): PlatformModule {
          return this._data.get( 'platform' );
     }

     /**
      *
      * @param {PlatformModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 04:21 pm
      * @see id
      * @see user
      * @see platform
      * @see profile
      * @see setUser
      * @see setPlatform
      * @see setProfile
      * @see store
      * @see toObject
      */
     public set platform( value: PlatformModule ) {
          this._data.set( 'platform', value );
     }

     /**
      *
      * @return {ProfileModule}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 04:21 pm
      * @see id
      * @see user
      * @see platform
      * @see profile
      * @see setUser
      * @see setPlatform
      * @see setProfile
      * @see store
      * @see toObject
      */
     public get profile(): ProfileModule {
          return this._data.get( 'profile' );
     }

     /**
      *
      * @param {ProfileModule} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 04:21 pm
      * @see id
      * @see user
      * @see platform
      * @see profile
      * @see setUser
      * @see setPlatform
      * @see setProfile
      * @see store
      * @see toObject
      */
     public set profile( value: ProfileModule ) {
          this._data.set( 'profile', value );
     }

     /**
      *
      * @param value {UserModule}
      * @return {this}
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 04:21 pm
      * @see id
      * @see user
      * @see platform
      * @see profile
      * @see setUser
      * @see setPlatform
      * @see setProfile
      * @see store
      * @see toObject
      */
     public setUser( value: null ): this {
          this.user = value;

          return this;
     }

     /**
      *
      * @param value {PlatformModule}
      * @return {this}
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 04:21 pm
      * @see id
      * @see user
      * @see platform
      * @see profile
      * @see setUser
      * @see setPlatform
      * @see setProfile
      * @see store
      * @see toObject
      */
     public setPlatform( value: PlatformModule ): this {
          this.platform = value;

          return this;
     }

     /**
      *
      * @param value {ProfileModule}
      * @return {this}
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 04:21 pm
      * @see id
      * @see user
      * @see platform
      * @see profile
      * @see setUser
      * @see setPlatform
      * @see setProfile
      * @see store
      * @see toObject
      */
     public setProfile( value: ProfileModule ): this {
          this.profile = value;

          return this;
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/23/21 06:39 pm
      */
     public store(): this {
          StorageManager.accountModel    = this;
          StorageManager.accountId       = this.id;
          //StorageManager.accountUser     = this.user;
          StorageManager.accountPlatform = this.platform;
          StorageManager.accountProfile  = this.profile;

          return this;
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/23/21 05:42 pm
      * @see first
      * @see last
      * @see setFirst
      * @see setLast
      */
     public toObject( forUser?: boolean ): Record<string, unknown> {
          if( forUser ) {
               return {
                    id      : this.id,
                    user    : null,
                    platform: this.platform?.toObject(),
                    profile : this.profile?.toObject(),
               };
          }

          return {
               id      : this.id,
               user    : null,
               platform: this.platform?.toObject(),
               profile : this.profile?.toObject(),
          };
     }
}