import { AModule }        from '../../abstract/module.abstract';
import { IPlatform }      from '../../interface';
import { StorageManager } from '../../manager';

/**
 * @class PlatformModule
 * @extends AModule
 * @implements IPlatform
 * @author Isaac Ewing
 * @version 1.0.0 05/24/21 12:50 pm
 */
export class PlatformModule extends AModule implements IPlatform {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 12:50 pm
      */
     protected static readonly CONSOLE_PREFIX: string   = `${ process.env.REACT_APP_CONSOLE_PREFIX_MODULE } PLAT ${ process.env.REACT_APP_CONSOLE_SUFFIX_MODULE }`;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 12:50 pm
      */
     protected static readonly CONSOLE_ENABLED: boolean = false;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 12:50 pm
      */
     protected static readonly API_ROOT_NODE: string    = process.env.REACT_APP_GRAPHQL_GET_PLATFORM;
     /**
      *
      * @type {PlatformModule}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 12:50 pm
      */
     protected static fromStorage: PlatformModule;

     /**
      *
      * @param module {PlatformModule} The object containing the data
      * @return {PlatformModule | null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:11 pm
      * @see Build
      * @see id
      * @see name
      * @see alias
      * @see text
      * @see url
      * @see enabled
      * @see standard
      * @see options
      * @see setId
      * @see setName
      * @see setAlias
      * @see setText
      * @see setURL
      * @see setEnabled
      * @see setStandard
      * @see setOptions
      * @see enable
      * @see disable
      * @see toObject
      * @see FromStorage
      */
     public static Build( module: PlatformModule ): PlatformModule;
     public static Build( obj: IPlatform ): PlatformModule;
     public static Build( json: string ): PlatformModule;
     public static Build( id?: number, name?: string, alias?: string, text?: string, url?: string, enabled?: boolean, standard?: boolean, options?: string ): PlatformModule;
     public static Build( id?: string, name?: string, alias?: string, text?: string, url?: string, enabled?: boolean, standard?: boolean, options?: string ): PlatformModule;
     public static Build( dataOrId?: unknown, name?: string, alias?: string, text?: string, url?: string, enabled?: boolean, standard?: boolean, options?: string ): PlatformModule {
          if( dataOrId ) {
               dataOrId = this.mapAPIRootNode( dataOrId, this.API_ROOT_NODE );

               if( dataOrId instanceof PlatformModule ) {
                    return dataOrId;
               }
               if( typeof dataOrId === 'object' ) {
                    const localData: Record<string, never> = dataOrId as Record<string, never>;

                    return new PlatformModule( +localData?.id ?? null, localData?.name ?? null, localData?.alias ?? null, localData?.text ?? null, localData?.url ?? null,
                                               localData?.enabled ?? null, localData?.standard ?? null, localData?.options ?? null );
               }
               if( typeof dataOrId === 'number' ) {
                    return new PlatformModule( dataOrId ?? null, name ?? null, alias ?? null, text ?? null, url ?? null, enabled ?? null, standard ?? null,
                                               options ?? null );
               }
               if( typeof dataOrId === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrId ) );
                    } catch( exception ) {
                         // not a valid json string
                         return new PlatformModule( +dataOrId ?? null, name ?? null, alias ?? null, text ?? null, url ?? null, enabled ?? null,
                                                    standard ?? null, options ?? null );
                    }
               }
          }

          return null;
     }

     /**
      *
      * @return {PlatformModule | null}
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 02:32 pm
      */
     public static FromStorage(): PlatformModule | null {
          if( this.fromStorage ) {
               return this.fromStorage;
          }
          if( StorageManager.domainId && StorageManager.domainPath && StorageManager.domainText ) {
               this.fromStorage = new PlatformModule( StorageManager.platformId ?? null, StorageManager.platformName ?? null, StorageManager.platformAlias ?? null,
                                                      StorageManager.platformText ?? null, StorageManager.platformURL ?? null, StorageManager.platformEnabled ?? null,
                                                      StorageManager.platformStandard ?? null, StorageManager.platformOptions ?? null );

               return this.fromStorage;
          }

          return null;
     }

     /**
      *
      * @param {number} id
      * @param {string} name
      * @param {string} alias
      * @param {string} text
      * @param {string} url
      * @param {boolean} enabled
      * @param {boolean} standard
      * @param {string} options
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 12:50 pm
      * @see id
      * @see name
      * @see alias
      * @see text
      * @see url
      * @see enabled
      * @see standard
      * @see options
      * @see setId
      * @see setName
      * @see setAlias
      * @see setText
      * @see setURL
      * @see setEnabled
      * @see setStandard
      * @see setOptions
      * @see enable
      * @see disable
      * @see toObject
      */
     public constructor( id?: number, name?: string, alias?: string, text?: string, url?: string, enabled?: boolean, standard?: boolean, options?: unknown ) {
          super( id ?? null );

          this.name     = name ?? null;
          this.alias    = alias ?? null;
          this.text     = text ?? null;
          this.url      = url ?? null;
          this.enabled  = enabled ?? null;
          this.standard = standard ?? null;
          this.options  = options ?? null;
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 12:50 pm
      * @see id
      * @see name
      * @see alias
      * @see text
      * @see url
      * @see enabled
      * @see standard
      * @see options
      * @see setId
      * @see setName
      * @see setAlias
      * @see setText
      * @see setURL
      * @see setEnabled
      * @see setStandard
      * @see setOptions
      * @see enable
      * @see disable
      * @see toObject
      */
     public get name(): string {
          return this._data.get( 'name' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 12:50 pm
      * @see id
      * @see name
      * @see alias
      * @see text
      * @see url
      * @see enabled
      * @see standard
      * @see options
      * @see setId
      * @see setName
      * @see setAlias
      * @see setText
      * @see setURL
      * @see setEnabled
      * @see setStandard
      * @see setOptions
      * @see enable
      * @see disable
      * @see toObject
      */
     public set name( value: string ) {
          this._data.set( 'name', value );
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 12:50 pm
      * @see id
      * @see name
      * @see alias
      * @see text
      * @see url
      * @see enabled
      * @see standard
      * @see options
      * @see setId
      * @see setName
      * @see setAlias
      * @see setText
      * @see setURL
      * @see setEnabled
      * @see setStandard
      * @see setOptions
      * @see enable
      * @see disable
      * @see toObject
      */
     public get alias(): string {
          return this._data.get( 'alias' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 12:50 pm
      * @see id
      * @see name
      * @see alias
      * @see text
      * @see url
      * @see enabled
      * @see standard
      * @see options
      * @see setId
      * @see setName
      * @see setAlias
      * @see setText
      * @see setURL
      * @see setEnabled
      * @see setStandard
      * @see setOptions
      * @see enable
      * @see disable
      * @see toObject
      */
     public set alias( value: string ) {
          this._data.set( 'alias', value );
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 12:50 pm
      * @see id
      * @see name
      * @see alias
      * @see text
      * @see url
      * @see enabled
      * @see standard
      * @see options
      * @see setId
      * @see setName
      * @see setAlias
      * @see setText
      * @see setURL
      * @see setEnabled
      * @see setStandard
      * @see setOptions
      * @see enable
      * @see disable
      * @see toObject
      */
     public get enabled(): boolean {
          return this._data.get( 'enabled' );
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 12:50 pm
      * @see id
      * @see name
      * @see alias
      * @see text
      * @see url
      * @see enabled
      * @see standard
      * @see options
      * @see setId
      * @see setName
      * @see setAlias
      * @see setText
      * @see setURL
      * @see setEnabled
      * @see setStandard
      * @see setOptions
      * @see enable
      * @see disable
      * @see toObject
      */
     public set enabled( value: boolean ) {
          this._data.set( 'enabled', value );
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 12:50 pm
      * @see id
      * @see name
      * @see alias
      * @see text
      * @see url
      * @see enabled
      * @see standard
      * @see options
      * @see setId
      * @see setName
      * @see setAlias
      * @see setText
      * @see setURL
      * @see setEnabled
      * @see setStandard
      * @see setOptions
      * @see enable
      * @see disable
      * @see toObject
      */
     public get standard(): boolean {
          return this._data.get( 'standard' );
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 12:50 pm
      * @see id
      * @see name
      * @see alias
      * @see text
      * @see url
      * @see enabled
      * @see standard
      * @see options
      * @see setId
      * @see setName
      * @see setAlias
      * @see setText
      * @see setURL
      * @see setEnabled
      * @see setStandard
      * @see setOptions
      * @see enable
      * @see disable
      * @see toObject
      */
     public set standard( value: boolean ) {
          this._data.set( 'standard', value );
     }

     /**
      *
      * @return {any}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 12:50 pm
      * @see id
      * @see name
      * @see alias
      * @see text
      * @see url
      * @see enabled
      * @see standard
      * @see options
      * @see setId
      * @see setName
      * @see setAlias
      * @see setText
      * @see setURL
      * @see setEnabled
      * @see setStandard
      * @see setOptions
      * @see enable
      * @see disable
      * @see toObject
      */
     public get options(): unknown {
          return this._data.get( 'options' );
     }

     /**
      *
      * @param {any} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 12:50 pm
      * @see id
      * @see name
      * @see alias
      * @see text
      * @see url
      * @see enabled
      * @see standard
      * @see options
      * @see setId
      * @see setName
      * @see setAlias
      * @see setText
      * @see setURL
      * @see setEnabled
      * @see setStandard
      * @see setOptions
      * @see enable
      * @see disable
      * @see toObject
      */
     public set options( value: unknown ) {
          this._data.set( 'options', value );
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 12:50 pm
      * @see id
      * @see name
      * @see alias
      * @see text
      * @see url
      * @see enabled
      * @see standard
      * @see options
      * @see setId
      * @see setName
      * @see setAlias
      * @see setText
      * @see setURL
      * @see setEnabled
      * @see setStandard
      * @see setOptions
      * @see enable
      * @see disable
      * @see toObject
      */
     public setName( value: string ): this {
          this.name = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 12:50 pm
      * @see id
      * @see name
      * @see alias
      * @see text
      * @see url
      * @see enabled
      * @see standard
      * @see options
      * @see setId
      * @see setName
      * @see setAlias
      * @see setText
      * @see setURL
      * @see setEnabled
      * @see setStandard
      * @see setOptions
      * @see enable
      * @see disable
      * @see toObject
      */
     public setAlias( value: string ): this {
          this.alias = value;

          return this;
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 12:50 pm
      * @see id
      * @see name
      * @see alias
      * @see text
      * @see url
      * @see enabled
      * @see standard
      * @see options
      * @see setId
      * @see setName
      * @see setAlias
      * @see setText
      * @see setURL
      * @see setEnabled
      * @see setStandard
      * @see setOptions
      * @see enable
      * @see disable
      * @see toObject
      */
     public setText( value: string ): this {
          this.text = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 12:50 pm
      * @see id
      * @see name
      * @see alias
      * @see text
      * @see url
      * @see enabled
      * @see standard
      * @see options
      * @see setId
      * @see setName
      * @see setAlias
      * @see setText
      * @see setURL
      * @see setEnabled
      * @see setStandard
      * @see setOptions
      * @see enable
      * @see disable
      * @see toObject
      */
     public setEnabled( value: boolean ): this {
          this.enabled = value;

          return this;
     }

     /**
      *
      * @param {boolean} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 12:50 pm
      * @see id
      * @see name
      * @see alias
      * @see text
      * @see url
      * @see enabled
      * @see standard
      * @see options
      * @see setId
      * @see setName
      * @see setAlias
      * @see setText
      * @see setURL
      * @see setEnabled
      * @see setStandard
      * @see setOptions
      * @see enable
      * @see disable
      * @see toObject
      */
     public setStandard( value: boolean ): this {
          this.standard = value;

          return this;
     }

     /**
      *
      * @param {any} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 12:50 pm
      * @see id
      * @see name
      * @see alias
      * @see text
      * @see url
      * @see enabled
      * @see standard
      * @see options
      * @see setId
      * @see setName
      * @see setAlias
      * @see setText
      * @see setURL
      * @see setEnabled
      * @see setStandard
      * @see setOptions
      * @see enable
      * @see disable
      * @see toObject
      */
     public setOptions( value: any ): this {
          this.options = value;

          return this;
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 12:50 pm
      * @see id
      * @see name
      * @see alias
      * @see text
      * @see url
      * @see enabled
      * @see standard
      * @see options
      * @see setId
      * @see setName
      * @see setAlias
      * @see setText
      * @see setURL
      * @see setEnabled
      * @see setStandard
      * @see setOptions
      * @see enable
      * @see disable
      * @see toObject
      */
     public enable(): this {
          this.enabled = true;

          return this;
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 12:50 pm
      * @see id
      * @see name
      * @see alias
      * @see text
      * @see url
      * @see enabled
      * @see standard
      * @see options
      * @see setId
      * @see setName
      * @see setAlias
      * @see setText
      * @see setURL
      * @see setEnabled
      * @see setStandard
      * @see setOptions
      * @see enable
      * @see disable
      * @see toObject
      */
     public disable(): this {
          this.enabled = false;

          return this;
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 12:50 pm
      * @see id
      * @see name
      * @see alias
      * @see text
      * @see url
      * @see enabled
      * @see standard
      * @see options
      * @see setId
      * @see setName
      * @see setAlias
      * @see setText
      * @see setURL
      * @see setEnabled
      * @see setStandard
      * @see setOptions
      * @see enable
      * @see disable
      * @see toObject
      */
     public store(): this {
          StorageManager.platformId       = +this.id;
          StorageManager.platformName     = this.name;
          StorageManager.platformAlias    = this.alias;
          StorageManager.platformText     = this.text;
          StorageManager.platformURL      = this.url;
          StorageManager.platformEnabled  = this.enabled;
          StorageManager.platformStandard = this.standard;
          StorageManager.platformOptions  = this.options;

          return this;
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/24/21 12:50 pm
      * @see id
      * @see name
      * @see alias
      * @see text
      * @see url
      * @see enabled
      * @see standard
      * @see options
      * @see setId
      * @see setName
      * @see setAlias
      * @see setText
      * @see setURL
      * @see setEnabled
      * @see setStandard
      * @see setOptions
      * @see enable
      * @see disable
      * @see toObject
      */
     public toObject(): Record<string, unknown> {
          return {
               id      : this.id,
               name    : this.name,
               alias   : this.alias,
               text    : this.text,
               url     : this.url,
               enabled : this.enabled,
               standard: this.standard,
               options : this.options,
          };
     }
}