import React             from 'react';
import PropTypes         from 'prop-types';
import jQuery            from 'jquery';
import Particles         from 'react-tsparticles';
import { SectionModule } from '../../../api/com/ewing/social/component';
import { SiteModule }    from '../../../api/com/ewing/social/module';
import { BaseSection }   from '../../atom/BaseSection';
import { Description }   from '../../atom/Description';
import { Image }         from '../../atom/Image';
import { Subtitle }      from '../../atom/Subtitle';
import { Util }          from '../../../api/com/ewing/social/tool';

export class Hero2 extends BaseSection<any, any> {
     protected static COMPONENT_CLASS: string = 'hero-2';
     public static propTypes                  = {
          ...BaseSection.propTypes,
          module: PropTypes.instanceOf( SectionModule ).isRequired,
          page  : PropTypes.instanceOf( SiteModule ).isRequired,
     };

     /**
      *
      * @param {SectionModule} props
      * @return {JSX.Element|null}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 12/05/20 05:56 pm
      */
     protected registerTitle( props: SectionModule ): JSX.Element | null {
          if( props?.title ) {
               return <h3 key={ this.registerHash() } className="title">{ this.state.module.title.text }</h3>;
          }
     }

     /**
      *
      * @param props {any}
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am
      * documented
      */
     public constructor( props: any ) {
          super( props );

          this.state = {
               module    : props.module,
               page      : props.page,
               site      : props.site,
               className : new Set<string>( [ 'grid-x', Hero2.COMPONENT_CLASS ] ),
               particles : null,
               dimensions: {
                    width : 0,
                    height: 0,
               },
          };
     }

     /**
      *
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am
      * documented
      */
     public componentDidMount(): void {
          const options: any                 = {
               'background'    : {
                    'position': '50% 50%',
                    'repeat'  : 'no-repeat',
                    'size'    : 'cover',
               },
               'backgroundMode': {
                    'zIndex': 1,
               },
               fpsLimit        : 60,
               'infection'     : {
                    'cure': true,
               },
               'interactivity' : {
                    'events': {
                         'onClick': {
                              'enable': true,
                              'mode'  : 'repulse',
                         },
                         'onHover': {
                              'enable': true,
                              'mode'  : '',
                         },
                    },
                    'modes' : {
                         'bubble': {
                              'distance': 400,
                              'duration': 0.3,
                              'opacity' : 1,
                              'size'    : 4,
                         },
                         'grab'  : {
                              'distance': 400,
                              'links'   : {
                                   'opacity': 0.5,
                              },
                         },
                    },
               },
               'particles'     : {
                    'collisions': {
                         'bounce': {
                              'horizontal': {
                                   'random': {
                                        'enable': true,
                                   },
                              },
                         },
                    },
                    'links'     : {
                         'color'   : {
                              'value': '#ffffff',
                         },
                         'distance': 500,
                         'opacity' : 0.4,
                         'width'   : 2,
                    },
                    'move'      : {
                         'attract'  : {
                              'rotate': {
                                   'x': 600,
                                   'y': 1200,
                              },
                         },
                         'direction': 'bottom',
                         'enable'   : true,
                         'outModes' : {
                              'default': process.env.REACT_APP_HERO_PARTICLE_OUT_MODE,
                              'bottom' : process.env.REACT_APP_HERO_PARTICLE_OUT_MODE,
                              'left'   : process.env.REACT_APP_HERO_PARTICLE_MOVE_MODE,
                              'right'  : process.env.REACT_APP_HERO_PARTICLE_MOVE_MODE,
                              'top'    : process.env.REACT_APP_HERO_PARTICLE_OUT_MODE,
                         },
                         'random'   : true,
                         'speed'    : 1,
                         'trail'    : {
                              'fillColor': {
                                   'value': '#00000047',
                              },
                         },
                    },
                    'number'    : {
                         'density': {
                              'enable': true,
                         },
                         'value'  : +process.env.REACT_APP_HERO_PARTICLE_COUNT,
                    },
                    'opacity'   : {
                         'random'   : {
                              'enable': true,
                         },
                         'value'    : +process.env.REACT_APP_HERO_PARTICLE_OPACITY,
                         'animation': {
                              'minimumValue': 0.1,
                              'speed'       : 1,
                         },
                    },
                    'shape'     : {
                         'options': {
                              'polygon': {
                                   'nb_sides': 5,
                              },
                              'star'   : {
                                   'nb_sides': 5,
                              },
                              'image'  : {
                                   'src'   : 'https://cdn.matteobruni.it/images/particles/github.svg',
                                   'width' : 100,
                                   'height': 100,
                              },
                              'images' : {
                                   'src'   : 'https://cdn.matteobruni.it/images/particles/github.svg',
                                   'width' : 100,
                                   'height': 100,
                              },
                         },
                    },
                    'size'      : {
                         'random'   : {
                              'enable': true,
                         },
                         'value'    : +process.env.REACT_APP_HERO_PARTICLE_SIZE,
                         'animation': {
                              'minimumValue': 0.1,
                              'speed'       : 40,
                         },
                    },
                    'stroke'    : {
                         'color': {
                              'value'    : '#000000',
                              'animation': {
                                   'enable': false,
                                   'speed' : 1,
                                   'sync'  : true,
                              },
                         },
                    },
               },
          };
          const hash: string                 = this.registerHash();
          const section: JQuery<HTMLElement> = jQuery( '[data-capture-resize]' );
          let oldHeight: number              = Math.round( section.height() );
          const dimensions: any              = {
               width : section.width(),
               height: oldHeight,
          };
          const props: any                   = {
               key      : `${ hash }`,
               id       : `${ hash }`,
               className: 'particles',
               options,
          };
          const intervalCheck                = (): void => {
               if( oldHeight !== Math.round( section.height() ) ) {
                    oldHeight = Math.round( section.height() );

                    const dimensions: any                   = {
                         width : section.width(),
                         height: oldHeight,
                    };
                    const canvas: JQuery<HTMLCanvasElement> = jQuery( `#${ hash }` ).find( 'canvas' );
                    //const particles: JSX.Element            = <Particles { ...props } width={ dimensions.width } height={ dimensions.height } />;

                    if( Util.isBreakpointSmall() ) {
                         console.log( 'OK, BUILDING FRESH PARTICLES...' );
                         const particles: JSX.Element = <Particles { ...props } width={ dimensions.width } height={ dimensions.height } />;

                         this.setState( { dimensions, particles } );

                         canvas.attr( 'width', dimensions.width );
                         canvas.attr( 'height', dimensions.height );
                         canvas.css( 'width', `100vw` );
                         canvas.css( 'height', `${ dimensions.height }px` );

                    }

                    clearTimeout( interval );
                    /*
                     canvas.attr( 'width', dimensions.width );
                     canvas.attr( 'height', dimensions.height );
                     canvas.css( 'width', `100vw` );
                     canvas.css( 'height', `${ dimensions.height }px` );

                     */
                    console.log( '*** [[ H2 ]] *** RESIZE', { dimensions, canvas, width: section.width(), height: section.height() } );
               }
          };
          const interval: NodeJS.Timeout     = setInterval( intervalCheck ?? null, 100 );
          const particles: JSX.Element       = <Particles { ...props } width={ dimensions.width } height={ dimensions.height } />;

          this.setState( { particles } );
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/30/21 11:30 am
      * documented
      */
     public render(): JSX.Element {
          return (
               <section { ...this.registerSectionProps() } data-capture-resize="">
                    <div className="grid-container content">
                         <div className="grid-x align-center">
                              <div className="cell small-12">
                                   <Subtitle module={ this.state.module?.subtitle } />
                              </div>
                              <div className="cell small-12">
                                   { this.registerTitle( this.state.module ) }
                              </div>
                              <div className="cell small-12">
                                   <Description module={ this.state.module?.description } />
                              </div>
                              <div className="cell small-12 image-footer">
                                   <Image module={ this.state.module?.image } />
                              </div>
                         </div>
                    </div>
                    { this.state.children }
                    { this.state.particles }
               </section>
          );
     }
}

export default Hero2;