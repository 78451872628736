import React             from 'react';
import { AModule }       from '../../abstract/module.abstract';
import { ReportEnum }    from '../../enum';
import { IReportModule } from '../../interface';

/**
 *
 * @class ReportModule
 * @extends AModule
 * @implements IReportModule
 * @author Isaac Ewing
 * @version 1.0.0 06/04/21 09:47 pm
 */
export class ReportModule extends AModule implements IReportModule {
     /**
      *
      * @return {ReportEnum}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 11:16 am
      * @see type
      * @see error
      * @see info
      * @see data
      * @see setType
      * @see setError
      * @see setInfo
      * @see setData
      * @see toObject
      */
     public get type(): ReportEnum {
          return this._data.get( 'type' );
     }

     /**
      *
      * @param {ReportEnum} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 11:16 am
      * @see type
      * @see error
      * @see info
      * @see data
      * @see setType
      * @see setError
      * @see setInfo
      * @see setData
      * @see toObject
      */
     public set type( value: ReportEnum ) {
          this._data.set( 'type', value );
     }

     /**
      *
      * @return {Error}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 11:16 am
      * @see type
      * @see error
      * @see info
      * @see data
      * @see setType
      * @see setError
      * @see setInfo
      * @see setData
      * @see toObject
      */
     public get report(): Error {
          return this._data.get( 'report' );
     }

     /**
      *
      * @param {Error} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 11:16 am
      * @see type
      * @see error
      * @see info
      * @see data
      * @see setType
      * @see setError
      * @see setInfo
      * @see setData
      * @see toObject
      */
     public set report( value: Error ) {
          this._data.set( 'report', value );
     }

     /**
      *
      * @return {ReportEnum}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 11:16 am
      * @see type
      * @see error
      * @see info
      * @see data
      * @see setType
      * @see setError
      * @see setInfo
      * @see setData
      * @see toObject
      */
     public get info(): React.ErrorInfo {
          return this._data.get( 'info' );
     }

     /**
      *
      * @param {React.ErrorInfo} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 11:16 am
      * @see type
      * @see error
      * @see info
      * @see data
      * @see setType
      * @see setError
      * @see setInfo
      * @see setData
      * @see toObject
      */
     public set info( value: React.ErrorInfo ) {
          this._data.set( 'info', value );
     }

     /**
      *
      * @return {unknown}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 12:54 pm
      * @see type
      * @see error
      * @see info
      * @see data
      * @see setType
      * @see setError
      * @see setInfo
      * @see setData
      * @see toObject
      */
     public get data(): unknown {
          return this._data.get( 'data' );
     }

     /**
      *
      * @param {unknown} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 12:54 pm
      * @see type
      * @see error
      * @see info
      * @see data
      * @see setType
      * @see setError
      * @see setInfo
      * @see setData
      * @see toObject
      */
     public set data( value: unknown ) {
          this._data.set( 'data', value );
     }

     /**
      *
      * @param report {Error}
      * @param info {React.ErrorInfo}
      * @param data {Object}
      * @return {ReportModule}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 01:09 pm
      * @see BuildComponent
      * @see BuildError
      * @see BuildException
      * @see BuildWarning
      * @see BuildUnknown
      * @see type
      * @see error
      * @see info
      * @see data
      * @see setType
      * @see setError
      * @see setInfo
      * @see setData
      * @see toObject
      */
     public static BuildComponent( report: unknown, info?: React.ErrorInfo, data?: unknown ): ReportModule {
          return new ReportModule( ReportEnum.Component ?? null, report ?? null, info ?? null, data ?? null );
     }

     /**
      *
      * @param report {Error}
      * @param info {React.ErrorInfo}
      * @param data {Object}
      * @return {ReportModule}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 11:16 am
      * @see BuildComponent
      * @see BuildError
      * @see BuildException
      * @see BuildWarning
      * @see BuildUnknown
      * @see type
      * @see error
      * @see info
      * @see data
      * @see setType
      * @see setError
      * @see setInfo
      * @see setData
      * @see toObject
      */
     public static BuildError( report: unknown, info?: React.ErrorInfo, data?: unknown ): ReportModule {
          return new ReportModule( ReportEnum.Error ?? null, report ?? null, info ?? null, data ?? null );
     }

     /**
      *
      * @param report {Error}
      * @param info {React.ErrorInfo}
      * @param data {Object}
      * @return {ReportModule}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 11:16 am
      * @see BuildComponent
      * @see BuildError
      * @see BuildException
      * @see BuildWarning
      * @see BuildUnknown
      * @see type
      * @see error
      * @see info
      * @see data
      * @see setType
      * @see setError
      * @see setInfo
      * @see setData
      * @see toObject
      */
     public static BuildException( report: unknown, info?: React.ErrorInfo, data?: unknown ): ReportModule {
          return new ReportModule( ReportEnum.Error ?? null, report ?? null, info ?? null, data ?? null );
     }

     /**
      *
      * @param report {Error}
      * @param info {React.ErrorInfo}
      * @param data {Object}
      * @return {ReportModule}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 11:16 am
      * @see BuildComponent
      * @see BuildError
      * @see BuildException
      * @see BuildWarning
      * @see BuildUnknown
      * @see type
      * @see error
      * @see info
      * @see data
      * @see setType
      * @see setError
      * @see setInfo
      * @see setData
      * @see toObject
      */
     public static BuildWarning( report: unknown, info?: React.ErrorInfo, data?: unknown ): ReportModule {
          return new ReportModule( ReportEnum.Error ?? null, report ?? null, info ?? null, data ?? null );
     }

     /**
      *
      * @param report {Error}
      * @param info {React.ErrorInfo}
      * @param data {Object}
      * @return {ReportModule}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 11:16 am
      * @see BuildComponent
      * @see BuildError
      * @see BuildException
      * @see BuildWarning
      * @see BuildUnknown
      * @see type
      * @see error
      * @see info
      * @see data
      * @see setType
      * @see setError
      * @see setInfo
      * @see setData
      * @see toObject
      */
     public static BuildUnknown( report: unknown, info?: React.ErrorInfo, data?: unknown ): ReportModule {
          return new ReportModule( ReportEnum.Error ?? null, report ?? null, info ?? null, data ?? null );
     }

     /**
      *
      * @return {Record<string, unknown>}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 01:20 pm
      * @see BuildComponent
      * @see BuildError
      * @see type
      * @see error
      * @see info
      * @see data
      * @see setType
      * @see setError
      * @see setInfo
      * @see setData
      * @see toObject
      */
     protected convertReportToObject(): Record<string, unknown> {
          const parts: Record<string, unknown> = {
               component: null,
               error    : null,
               exception: null,
               warning  : null,
               unknown  : null,
          };
          const errorToObject                  = ( error: Error ): Record<string, unknown> => {
               return {
                    name   : error.name,
                    message: error.message,
                    stack  : error.stack,
               };
          };

          switch( this.type ) {
               case ReportEnum.Component:
                    parts.component = errorToObject( this.report );
                    break;
               case ReportEnum.Error:
                    parts.error = errorToObject( this.report );
                    break;
               case ReportEnum.Exception:
                    parts.exception = errorToObject( this.report );
                    break;
               case ReportEnum.Warning:
                    parts.warning = errorToObject( this.report );
                    break;
               case ReportEnum.Unknown:
                    parts.unknown = errorToObject( this.report );
                    break;
          }

          return parts;
     }

     /**
      *
      * @param type {ReportEnum}
      * @param report {Error}
      * @param info {React.ErrorInfo}
      * @param data {Object}
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 11:16 am
      * @see type
      * @see error
      * @see info
      * @see data
      * @see setType
      * @see setError
      * @see setInfo
      * @see setData
      * @see toObject
      */
     public constructor( type?: ReportEnum, report?: unknown, info?: React.ErrorInfo, data?: unknown ) {
          super( null );

          this.type   = type ?? ReportEnum.Unknown;
          this.report = report as Error ?? null;
          this.info   = info ?? null;
          this.data   = data ?? null;
     }

     /**
      *
      * @param {ReportEnum} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 11:16 am
      * @see type
      * @see error
      * @see info
      * @see data
      * @see setType
      * @see setError
      * @see setInfo
      * @see setData
      * @see toObject
      */
     public setType( value: ReportEnum ): this {
          this.type = value;

          return this;
     }

     /**
      *
      * @param {Error} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 11:16 am
      * @see type
      * @see error
      * @see info
      * @see data
      * @see setType
      * @see setError
      * @see setInfo
      * @see setData
      * @see toObject
      */
     public setReport( value: Error ): this {
          this.report = value;

          return this;
     }

     /**
      *
      * @param {React.ErrorInfo} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 11:16 am
      * @see type
      * @see error
      * @see info
      * @see data
      * @see setType
      * @see setError
      * @see setInfo
      * @see setData
      * @see toObject
      */
     public setInfo( value: React.ErrorInfo ): this {
          this.info = value;

          return this;
     }

     /**
      *
      * @param {React.ErrorInfo} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 12:57 pm
      * @see type
      * @see error
      * @see info
      * @see data
      * @see setType
      * @see setError
      * @see setInfo
      * @see setData
      * @see toObject
      */
     public setData( value: React.ErrorInfo ): this {
          this.info = value;

          return this;
     }

     /**
      *
      * @return {Record<string, unknown>}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 11:16 am
      * @see type
      * @see error
      * @see info
      * @see data
      * @see setType
      * @see setError
      * @see setInfo
      * @see setData
      * @see toObject
      */
     public toObject(): Record<string, unknown> {
          return {
               type: this.type,
               info: this.info.componentStack ?? null,
               ...this.convertReportToObject(),
          };
     }
}