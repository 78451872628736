/**
 * @enum PartnerTypeEnum
 * @author Isaac Ewing
 * @version 1.0.0 02/07/21 04:41 pm
 */
export enum PartnerTypeEnum {
     Apply = 'apply'
}

/**
 * @enum ReportEnum
 * @author Isaac Ewing
 * @version 1.0.0 06/04/21 09:36 pm
 */
export enum ReportEnum {
     Component = 'component',
     Error     = 'error',
     Exception = 'exception',
     Unknown   = 'unknown',
     Warning   = 'warning',
}