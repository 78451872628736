import { TitleModule }    from './title.module';
import { ISetting }       from '../../interface';
import { PronounEnum }    from '../../enum';
import { StorageManager } from '../../manager';

/**
 * @class SettingModule
 * @extends TitleModule
 * @implements ISetting
 * @author Isaac Ewing
 * @version 1.0.0 05/25/21 02:24 pm
 * @version 2.0.0 05/28/21 12:33 pm - converted from AModule to TitleModule
 */
export class SettingModule extends TitleModule implements ISetting {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:24 pm
      */
     protected static readonly CONSOLE_PREFIX: string   = `${ process.env.REACT_APP_CONSOLE_PREFIX_MODULE } SETT ${ process.env.REACT_APP_CONSOLE_SUFFIX_MODULE }`;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:24 pm
      */
     protected static readonly CONSOLE_ENABLED: boolean = false;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:24 pm
      */
     protected static readonly API_ROOT_NODE: string    = process.env.REACT_APP_GRAPHQL_GET_USER;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:24 pm
      */
     protected static readonly DEFAULT_PRONOUNS: string = PronounEnum.They;
     /**
      *
      * @type {SettingModule}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:24 pm
      */
     protected static fromStorage: SettingModule;

     /**
      *
      * @param module {SettingModule} The object containing the data
      * @return {SettingModule | null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:24 pm
      * @see id
      * @see pronouns
      * @see setId
      * @see setPronouns
      * @see store
      * @see toObject
      */
     public static Build( module: SettingModule ): SettingModule;
     public static Build( obj: Partial<ISetting> ): SettingModule;
     public static Build( json: string ): SettingModule;
     public static Build( id?: number, pronouns?: string ): SettingModule;
     public static Build( dataOrId?: unknown, pronouns?: string ): SettingModule {
          if( dataOrId ) {
               if( dataOrId instanceof SettingModule ) {
                    return dataOrId;
               }
               if( typeof dataOrId === 'object' ) {
                    const localData: Partial<ISetting> = dataOrId;

                    return new SettingModule( localData?.id ?? null, localData?.pronouns ?? null );
               }
               if( typeof dataOrId === 'number' ) {
                    return new SettingModule( dataOrId ?? null, pronouns ?? null );
               }
               if( typeof dataOrId === 'string' ) {
                    try {
                         return this.Build( JSON.parse( dataOrId ) );
                    } catch( exception ) {
                         // not a valid json string
                         return new SettingModule( +dataOrId ?? null, pronouns ?? null );
                    }
               }
          }

          return null;
     }

     /**
      *
      * @return {SettingModule | null}
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:24 pm
      * @see id
      * @see pronouns
      * @see setId
      * @see setPronouns
      * @see store
      * @see toObject
      */
     public static FromStorage(): SettingModule | null {
          if( this.fromStorage ) {
               return this.fromStorage;
          }
          if( StorageManager.settingModel ) {
               this.fromStorage = SettingModule.Build( StorageManager.settingModel ?? null );
          } else if( StorageManager.settingId && StorageManager.settingPronouns ) {
               this.fromStorage = new SettingModule( StorageManager.settingId ?? null, StorageManager.settingPronouns ?? null );
          }

          return this?.fromStorage ?? null;
     }

     /**
      *
      * @param {number} id
      * @param {PronounEnum} pronouns
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:24 pm
      * @see id
      * @see pronouns
      * @see setId
      * @see setPronouns
      * @see store
      * @see toObject
      */
     public constructor( id?: number, pronouns?: string );
     public constructor( id?: number, pronouns?: PronounEnum ) {
          super( id ?? null );

          this.pronouns = pronouns ?? SettingModule.DEFAULT_PRONOUNS;
     }

     /**
      *
      * @return {string}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:24 pm
      * @see id
      * @see pronouns
      * @see setId
      * @see setPronouns
      * @see store
      * @see toObject
      */
     public get pronouns(): string {
          return this._data.get( 'pronouns' );
     }

     /**
      *
      * @param {string} value
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:24 pm
      * @see id
      * @see pronouns
      * @see setId
      * @see setPronouns
      * @see store
      * @see toObject
      */
     public set pronouns( value: string ) {
          this._data.set( 'pronouns', value );
     }

     /**
      *
      * @param {string} value
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:24 pm
      * @see id
      * @see pronouns
      * @see setId
      * @see setPronouns
      * @see store
      * @see toObject
      */
     public setPronouns( value: string ): this {
          this.pronouns = value;

          return this;
     }

     /**
      *
      * @return {this}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:24 pm
      * @see id
      * @see pronouns
      * @see setId
      * @see setPronouns
      * @see store
      * @see toObject
      */
     public store(): this {
          StorageManager.settingModel    = this;
          StorageManager.settingId       = this.id;
          StorageManager.settingPronouns = this.pronouns;

          return this;
     }

     /**
      *
      * @return {object}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/25/21 02:24 pm
      * @see id
      * @see username
      * @see email
      * @see token
      * @see setUsername
      * @see setEmail
      * @see setToken
      * @see toObject
      */
     public toObject(): Record<string, unknown> {
          return {
               id      : this.id,
               pronouns: this.pronouns,
          };
     }
}