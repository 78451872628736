/**
 * @class AError
 * @abstract
 * @author Isaac Ewing
 * @version 1.0.0 02/12/21 12:53 pm
 */
export abstract class AError {
     /**
      *
      * @param property {string} The property or properties that are missing
      * @return {string} Returns a formatted statement
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/08/21 02:59 pm
      */
     protected static buildInvalid( property: string ): string;
     protected static buildInvalid( properties: string[] ): string;
     protected static buildInvalid( values: string | string[] ): string {
          if( Array.isArray( values ) ) {
               return `Invalid: [ ${ values.join( ', ' ) } ], wrong values specified...`;
          } else {
               return `Invalid: ${ values }, wrong value specified...`;
          }
     }

     /**
      *
      * @param property {string} The property or properties that are missing
      * @return {string} Returns a formatted statement
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/08/21 02:59 pm
      */
     protected static buildMissing( property: string ): string;
     protected static buildMissing( properties: string[] ): string;
     protected static buildMissing( values: string | string[] ): string {
          if( Array.isArray( values ) ) {
               return `Missing: [ ${ values.join( ', ' ) } ], no values specified...`;
          } else {
               return `Missing: ${ values }, no value specified...`;
          }
     }

     /**
      *
      * @param property {string | string[]} The property or properties that are missing
      * @return {string} Returns a formatted statement
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/08/21 02:59 pm
      */
     protected static buildOverride( property: string ): string;
     protected static buildOverride( method: string, property: string ): string;
     protected static buildOverride( method: string, properties: string[] ): string;
     protected static buildOverride( propOrMethod: string, properties?: string | string[] ): string {
          if( Array.isArray( properties ) ) {
               return `Override: ${ propOrMethod }([ ${ properties.join( ', ' ) } ]}, method must have an override...`;
          } else if( properties ) {
               return `Override: ${ propOrMethod }([ ${ properties } ]}, method must have an override...`;
          } else {
               return `Override: ${ propOrMethod }, property must have an override...`;
          }
     }
}