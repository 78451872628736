import PropTypes         from 'prop-types';
import { SubmenuModule } from '../../api/com/ewing/social/component';
import { IBaseProps }    from '../../api/com/ewing/social/interface';
import { LinkModule }    from '../../api/com/ewing/social/module';
import { Util }          from '../../api/com/ewing/social/tool';
import { Base }          from '../atom/Base';
import { Link }          from '../atom/Link';

/**
 * @class Submenu
 * @extends Base
 * @author Isaac Ewing
 * @version 1.0.0 01/24/21 09:51 pm - documented
 */
export class Submenu extends Base {
    protected static COMPONENT_CLASS: string   = 'sticky-submenu';
    protected static CLASS_ACTIVE_PAGE: string = 'is-active';
    public static propTypes                    = {
        ...Base.propTypes,
        module: PropTypes.instanceOf( SubmenuModule ).isRequired,
    };

    /**
     *
     * @param {SubmenuModule} props
     * @return {void}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 01/13/21 01:06 pm
     */
    protected registerLinks( props?: SubmenuModule ): void {
        const children: JSX.Element[] = [];

        if( props ) {
            const page: string = Util.cleanPath( window.location.pathname, false );

            props.links.forEach( ( link: LinkModule ): void => {
                console.log( `<=${ page === link.path ? 'M' : '=' }=>`, `${ page } compared to ${ link.path }` );

                if( page === link.path ) {
                    link.addClass( Submenu.CLASS_ACTIVE_PAGE ?? null );
                } else {
                    link.removeClass( Submenu.CLASS_ACTIVE_PAGE ?? null );
                }

                children.push( <li key={ Util.registerHash() }><Link key={ Util.registerHash() } module={ link } /></li> );
            } );

            this.setState( {
                               children: new Set<JSX.Element>( [
                                                                   ...this.state.children,
                                                                   ...children,
                                                               ] ),
                           } );
        }
    }

    /**
     *
     * @param props {any}
     * @return {void}
     * @constructor
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 01/24/21 09:51 pm - documented
     */
    public constructor( props: IBaseProps ) {
        super( props );

        this.state = {
            className: new Set<string>( [ Submenu.COMPONENT_CLASS, 'grid-x', 'title-bar', 'is-anchored' ] ),
            children : new Set<JSX.Element>(),
        };
    }

    /**
     *
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 01/13/21 01:06 pm
     */
    public componentDidMount(): void {
        this.registerLinks( this.props?.module as SubmenuModule );
    }

    /**
     *
     * @return {JSX.Element}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/24/20 01:24 pm
     */
    public render(): JSX.Element {
        return (
            <nav data-sticky-container={ true }>
                <div className={ [ ...this.state.className ].join( ' ' ) } data-sticky={ true } data-options="marginTop:0;">
                    <div className="grid-container">
                        <div className="cell small-12 medium-12">
                            <ul className="dropdown menu" data-dropdown-menu="">
                                { this.state.children }
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

export default Submenu;