import React                     from 'react';
import PropTypes                 from 'prop-types';
import { TweenMax }              from 'gsap';
import { IInviteState, IJquery } from '../../../api/com/ewing/social/interface';
import { OAuthObserver }         from '../../../api/com/ewing/social/observer';
import {
     ProfileModule, RegisterModule, SiteModule,
}                                from '../../../api/com/ewing/social/module';
import { Base }                  from '../Base';

/**
 *
 * @class InviteBase
 * @extends Base
 * @author Isaac Ewing
 * @version 1.0.0 01/16/21 10:35 am
 */
export class InviteBase<P, S> extends Base {
     protected static COMPONENT_CLASS                         = 'invite';
     protected static REGISTER_MODULE: RegisterModule         = new RegisterModule();
     protected static TEXT_MAP: Map<string, string>           = new Map<string, string>( [
                                                                                              [ 'platform-heading', 'platform' ],
                                                                                              [ 'platform-description',
                                                                                                'Select the social platform that you use to stream or publish content the most. For example, if you stream content on Twitch, then you would select that platform. If you are a social media influencer on Twitter, then you would select that platform.' ],
                                                                                              [ 'expand-heading', 'expand' ],
                                                                                              [ 'expand-description',
                                                                                                'Select any number of additional platforms to connect to your account. The more you connect, the more it helps build a complete picture of you and your potential which helps determine your chances of getting additional affiliates, sponsors and more.' ],
                                                                                              [ 'start-heading', 'start' ],
                                                                                              [ 'start-description', 'this is waiting to be decided...' ],
                                                                                         ] );
     protected static readonly CLASS_SOCIALS: string          = 'social-buttons';
     protected static readonly CLASS_DATE_PICKER: string      = 'date-picker';
     protected static readonly COLOR_GREY: string             = '#8c969b';
     protected static readonly STATE_OPEN: string             = 'open';
     protected static readonly STATE_CLOSE: string            = 'close';
     protected static readonly STATUS_DONE: string            = 'done';
     protected static readonly STATUS_INCOMPLETE: string      = 'incomplete';
     protected static readonly ATTRIBUTE_DATA_CHANGED: string = 'attribute-data-changed';
     protected static readonly ANIMATION_TIME: number         = 0.5;
     /**
      *
      * @type {Object}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/24/21 04:06 pm
      */
     public static propTypes                                  = {
          ...Base.propTypes,
          page: PropTypes.instanceOf( SiteModule ).isRequired,
     };
     /**
      *
      * @type {boolean}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/24/21 06:50 pm
      */
     protected isDateChanged                                  = false;
     /**
      *
      * @type {Partial<IInviteState>}
      * @readonly
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/24/21 04:23 pm
      */
     public state: Partial<IInviteState>;

     /**
      *
      * @param props {any}
      * @return {void}
      * @throws Throws an exception if method is called without having an override
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/16/21 10:35 am
      */
     protected registerPropsCall( props: Record<string, never> ): void {
          // this.registerStyle( props );
          this.registerClasses( props );
          // this.registerCaps( props );
          // this.registerPassword( props );
     }

     /**
      *
      * @param event {JQuery.ClickEvent}
      * @param data {IJquery}
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/23/21 10:25 am
      */
     protected onAttributeDataChange( event: JQuery.ClickEvent, data: IJquery ): void {
          if( data.property === 'data-state' ) {
               const height: number = this.state?.title?.outerHeight();

               if( data.newValue === InviteBase.STATE_CLOSE ) {
                    const vars = {
                         duration: InviteBase.ANIMATION_TIME,
                         ease    : 'circ.out',
                         height  : height,
                    };
                    TweenMax.to( event.target, vars ?? {} );
                    console.log( '<<*>> UPDATING DATA CHANGE STATE', event.target, data, 'title', this.state.title, 'height', height );
               } else if( data.newValue === InviteBase.STATE_OPEN ) {
                    const vars = {
                         duration: InviteBase.ANIMATION_TIME,
                         ease    : 'circ.out',
                         height  : 'auto',
                    };
                    TweenMax.to( event.target, vars ?? {} );
                    console.log( '<<*>> UPDATING DATA CHANGE STATE', event.target, data, 'title', this.state.title, 'height', height );
               }
          }
     }

     /**
      *
      * @param {Date} value
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/23/21 10:25 am
      */
     protected onDateChanged( value: Date ): void {
          const newDate: number = new Date( value.getFullYear(), value.getMonth(), value.getDate() ).getTime() / 1000;

          this.isDateChanged = true;
          this.state.nextButton.removeClass( 'disabled' );
          this.setState( { started: value } );
          OAuthObserver.notify( ProfileModule.Build( { started: newDate } ) );
     }

     /**
      *
      * @param value {any}
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/23/21 10:25 am
      */
     protected onDateSelected( value: unknown ): void {
          console.info( '[[X]] ON DATE SELECTED', value );
     }

     /**
      *
      * @param {ProfileModule} module
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/21/21 09:24 pm
      */
     protected onOAuthObserver( module?: ProfileModule ): void {
          //
     }

     /**
      *
      * @param {ProfileModule} module
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/23/21 10:25 am
      */
     protected updateSocials( module: ProfileModule ): void {
          const socials: Set<string> = this.state.socials;
          const hasPlatform: boolean = socials.has( module.platform );

          if( hasPlatform ) {
               socials.delete( module.platform );
               this.setState( { socials } );
          }
     }

     /**
      *
      * @param element {JQuery<HTMLElement>}
      * @param data {object}
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/24/21 10:40 am
      */
     protected updateAttributeData( element: JQuery, data: Record<string, unknown> ): void {
          for( const property in data ) {
               if( data.hasOwnProperty( property ) ) {
                    const props = {
                         oldValue: element.data( property ),
                         newValue: data[ property ],
                         property,
                    };
                    element.attr( `${ property }`, `${ data[ property ] }` )
                         //.data( `${ property }`, `${ data[ property ] }` )
                           .trigger( InviteBase.ATTRIBUTE_DATA_CHANGED, props );
               }
          }
     }
}

export default InviteBase;