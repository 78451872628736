import isNode                                      from 'detect-node';
import React                                       from 'react';
import jQuery                                      from 'jquery';
import { Link }                                    from 'gatsby';
import { Img, ImgProps }                           from 'react-image';
import {
     IBaseStateStorage, IBaseStateStorageAction,
     IBaseStateStorageButton, IHeaderProps,
     IHeaderState, ILink,
}                                                  from '../../api/com/ewing/social/interface';
import { LinkModule }                              from '../../api/com/ewing/social/module/api/link.module';
import { SiteModule }                              from '../../api/com/ewing/social/module/api/site.module';
import { UserModule }                              from '../../api/com/ewing/social/module/api/user.module';
import { ImageModule }                             from '../../api/com/ewing/social/component';
import { PageObserver, UserObserver }              from '../../api/com/ewing/social/observer';
import { StorageManager, URLManager, UserManager } from '../../api/com/ewing/social/manager';
import { Util }                                    from '../../api/com/ewing/social/tool';
import { Base }                                    from '../atom/Base';
import { Link as LinkComponent }                   from '../atom/Link';

/**
 * @class Header
 * @extends Base
 * @author Isaac Ewing
 * @version 1.0.0 02/20/21 06:00 pm - documented
 */
export class Header extends Base<IHeaderProps, IHeaderState> {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/20/21 06:00 pm - documented
      */
     protected static readonly COMPONENT_CLASS: string       = 'root-header';
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/04/21 05:19 pm
      */
     protected static readonly CONSOLE_PREFIX: string        = `${ process.env.REACT_APP_CONSOLE_PREFIX_COMPONENT } HEAD ${ process.env.REACT_APP_CONSOLE_SUFFIX_COMPONENT }`;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 03/08/21 09:46 am
      */
     protected static readonly MOBILE_ID: string             = process.env.REACT_APP_HEADER_MOBILE_ID ?? 'data-root-header';
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 03/08/21 09:46 am
      */
     protected static readonly MOBILE_CLASS: string          = process.env.REACT_APP_HEADER_MOBILE_CLASS ?? 'curtain-menu-open';
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 03/08/21 12:15 pm
      */
     protected static readonly MENU_BUTTON_ID: string        = process.env.REACT_APP_HEADER_MENU_BUTTON_ID ?? 'data-menu-button';
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 04/14/21 01:25 pm
      */
     protected static readonly MENU_BUTTON_TOGGLE_ID: string = process.env.REACT_APP_HEADER_MENU_BUTTON_TOGGLE_ID ?? 'data-menu-button-toggle';
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 03/08/21 12:15 pm
      */
     protected static readonly CURTAIN_ID: string            = process.env.REACT_APP_HEADER_CURTAIN_ID ?? 'data-root-curtain';
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 03/08/21 07:25 pm
      */
     protected static readonly MOBILE_BUTTONS: string        = process.env.REACT_APP_HEADER_MOBILE_BUTTONS ?? 'data-mobile-buttons';
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 04/15/21 04:14 pm
      */
     protected static readonly DEFAULT_LOGO_COLOR: string    = process.env.REACT_APP_DEFAULT_IMAGE_LIGHT ?? 'light';
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 04/15/21 04:14 pm
      */
     protected static readonly CONFIG_LAZY_LOAD_ON           = 'lazy';
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 04/15/21 04:14 pm
      */
     protected static readonly CONFIG_LAZY_LOAD_OFF          = 'eager';
     /**
      *
      * @type {JQuery<HTMLElement>}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 03/08/21 12:15 pm
      */
     protected static HEADER: JQuery<HTMLElement>;
     /**
      *
      * @type {JQuery<HTMLElement>}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 03/08/21 12:15 pm
      */
     protected static CURTAIN: JQuery<HTMLElement>;
     /**
      *
      * @type {JQuery<HTMLElement>}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 03/08/21 12:15 pm
      */
     protected static MOBILE_JQUERY_BUTTONS: JQuery<HTMLElement>;
     /**
      *
      * @type {boolean}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 03/08/21 12:15 pm
      */
     protected static isCurtainEnabled: boolean;
     /**
      *
      * @type {SiteModule}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/07/21 10:12 pm
      */
     protected static cacheModule: SiteModule;
     /**
      *
      * @type {string}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/07/21 10:12 pm
      */
     protected static actionPage: string;
     /**
      *
      * @type {Partial<IHeaderProps>}
      * @readonly
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/31/21 01:14 pm
      */
     public readonly props: Partial<IHeaderProps>;
     /**
      *
      * @type {IHeaderState}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/26/21 11:18 am
      */
     public state: Partial<IHeaderState>;

     /**
      *
      * @return {Partial<IBaseStateStorageAction>}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 01:39 pm
      */
     protected static buildClaim(): Partial<IBaseStateStorageAction> {
          const action: Partial<ILink> = { path: 'claim', text: '< claim >' };
          const module: LinkModule     = LinkModule.Build( action ?? null );

          return {
               hash     : Util.registerHash(),
               module,
               data     : action,
               component: <li><LinkComponent module={ module } /></li>,
          };
     }

     /**
      *
      * @return {Partial<IBaseStateStorageAction>}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 01:39 pm
      */
     protected static buildLogin(): Partial<IBaseStateStorageAction> {
          const action: Partial<ILink> = { path: 'login', text: 'login' };
          const module: LinkModule     = LinkModule.Build( action ?? null );

          return {
               hash     : Util.registerHash(),
               module,
               data     : action,
               component: <li><LinkComponent module={ module } /></li>,
          };
     }

     /**
      *
      * @return {Partial<IBaseStateStorageAction>}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 01:39 pm
      */
     protected static buildLogout(): Partial<IBaseStateStorageAction> {
          const action: Partial<ILink> = { path: 'logout', text: 'logout' };
          const module: LinkModule     = LinkModule.Build( action ?? null );

          return {
               hash     : Util.registerHash(),
               module,
               data     : action,
               component: <li><LinkComponent module={ module } /></li>,
          };
     }

     /**
      *
      * @return {Partial<IBaseStateStorageAction>}
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 01:39 pm
      */
     protected static buildDashboard(): Partial<IBaseStateStorageAction> {
          const action: Partial<ILink> = { path: 'dashboard', text: 'dashboard' };
          const module: LinkModule     = LinkModule.Build( action ?? null );

          return {
               hash     : Util.registerHash(),
               module,
               data     : action,
               component: <li><LinkComponent module={ module } /></li>,
          };
     }

     /**
      *
      * @return {string}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/07/21 10:17 pm
      */
     public static getActionPage(): string {
          return Header.actionPage;
     }

     /**
      *
      * @param module {SiteModule}
      * @param skipState {boolean}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/02/21 04:52 pm - documented
      * @version 1.0.1 05/04/21 05:18 pm - added support for skip state
      */
     protected onPageObserver( module: SiteModule | unknown, skipState?: boolean ): void {
          const colorGreen = '#64FF00';
          const colorRed   = '#ff0000';
          console.log( '%c ' + `${ Header.CONSOLE_PREFIX } <<< com header name ${ Header.getComponentClass() } ${ module.constructor.name }`,
                       `color: white; background-color: ${ colorGreen }`,
                       module );

          if( module && !skipState ) {
               this.setState( { storage: this.buildStateButtons() } );
          } else {
               console.log( '%c ' + `${ Header.CONSOLE_PREFIX } <<< UNKNOWN module ${ Header.getComponentClass() } ${ module.constructor.name }`,
                            `color: white; background-color: ${ colorRed }`,
                            module );
          }
     }

     /**
      *
      * @return {IBaseStateStorage | null}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/08/21 01:56 pm
      */
     protected buildStateButtons(): IBaseStateStorage | null {
          let storage: IBaseStateStorage;
          let button: IBaseStateStorageButton;

          if( StorageManager.userToken ) {
               button = {
                    action   : Header.buildLogout(),
                    dashboard: Header.buildDashboard(),
               };
          }
          /*
           else if( StorageManager?.setupClaim ) {
           button= {
           action: Header.buildClaim(),
           }
           }

           */
          else if( StorageManager.setupLogin ) {
               button = {
                    action: Header.buildLogin(),
               };
          } else {
               const defaultDomain: boolean = Util.isDefaultDomain( URLManager.Domain );

               button = {
                    action: defaultDomain ? Header.buildLogin() : Header.buildClaim(),
               };
          }
          if( button ) {
               storage = {
                    button,
               };
          }

          Header.actionPage = storage.button.action.module.path;
          console.log( `${ Header.CONSOLE_PREFIX } Build state buttons result...`, { storage } );

          return storage ?? null;
     }

     /**
      *
      * @param {UserModule} module
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 04/15/21 04:14 pm
      */
     protected onUserObserver( module?: UserModule | unknown ): void {
          const user: UserModule = module as UserModule ?? UserManager.User();

          if( StorageManager.userToken ) {
               this.setState( { storage: this.buildStateButtons() } );
          } else {
               console.log( `${ Header.CONSOLE_PREFIX } USER observer is skipping, no token passed in from module`, { user, module } );
          }
     }

     /**
      *
      * @param data {any}
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 04/15/21 04:14 pm
      */
     protected onLogoLoad( data: unknown ): void {
          console.warn( `${ Header.CONSOLE_PREFIX } SVG LOGO LOADED...`, data ?? null );
     }

     /**
      *
      * @param {SiteModule} module
      * @return {JSX.Element}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/02/21 01:37 pm
      */
     protected registerLogo( module: SiteModule ): JSX.Element {
          if( module ) {
               const name: string                              = module.domain.text.toLowerCase();
               const image: string                             = Util.cleanPath( `${ name }-${ Header.DEFAULT_LOGO_COLOR }.svg`, false, true );
               const source: string                            = ImageModule.ForGetImageLogo( image ) as string;
               const params: ImgProps & Record<string, string> = {
                    key      : Util.registerHash(),
                    src      : source,
                    alt      : 'site logo',
                    className: `site-logo`,
                    //color     : '#fefefe',
                    'data-src'  : source,
                    unselectable: 'on',
                    loading     : Util.asBoolean( process.env.REACT_APP_CONFIG_LAZY_LOAD_IMAGE ) ? Header.CONFIG_LAZY_LOAD_ON : Header.CONFIG_LAZY_LOAD_OFF,
               };

               return <Img { ...params } />;
          }

          return null;
     }

     /**
      *
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 04/13/31 10:53 pm
      */
     protected registerJqueryButtons(): void {
          if( Header.MOBILE_JQUERY_BUTTONS ) {
               console.log( `${ Header.CONSOLE_PREFIX } ----->> HEADER a tags BEFORE CLEAR`, { buttons: Header.MOBILE_JQUERY_BUTTONS ?? null, total: Header.MOBILE_JQUERY_BUTTONS.length } );
               Header.MOBILE_JQUERY_BUTTONS.off( 'click' );
               console.log( `${ Header.CONSOLE_PREFIX } +++++>> HEADER a tags AFTER CLEAR`, { buttons: Header.MOBILE_JQUERY_BUTTONS ?? null, total: Header.MOBILE_JQUERY_BUTTONS.length } );
          }

          Header.MOBILE_JQUERY_BUTTONS = jQuery( `[${ Header.MOBILE_BUTTONS }]` ).find( 'a' );
          console.log( `${ Header.CONSOLE_PREFIX } =====>> HEADER a tags AFTER SETTING`, { buttons: Header.MOBILE_JQUERY_BUTTONS ?? null, total: Header.MOBILE_JQUERY_BUTTONS.length } );

          Header.MOBILE_JQUERY_BUTTONS.on( 'click', ( event: JQuery.ClickEvent<HTMLElement> ): void => {
               if( !event.currentTarget.hasAttribute( Header.MENU_BUTTON_TOGGLE_ID ) ) {
                    if( Header.HEADER.hasClass( Header.MOBILE_CLASS ) ) {
                         console.log( `${ Header.CONSOLE_PREFIX } [[ :-/ ]] Header curtain has been activated...` );

                         Header.HEADER.removeClass( Header.MOBILE_CLASS );

                         setTimeout( (): void => {
                              Header.CURTAIN.addClass( 'curtain-hide' ?? '' );
                              console.log( `${ Header.CONSOLE_PREFIX } [[ :-H ]] Header curtain has HIDE added...` );
                         }, 1000 );
                    }
               }
          } );
     }

     /**
      *
      * @param props {any}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/02/21 04:52 pm - documented
      */
     public constructor( props: IHeaderProps ) {
          super( props );

          const { module, ...other } = props;

          this.state = {
               module   : module ?? Header.cacheModule ?? null,
               other    : other ?? null,
               storage  : this.buildStateButtons(),
               className: new Set<string>( [ Header.COMPONENT_CLASS ] ),
          };

          if( module ) {
               Header.cacheModule = module as SiteModule;
          }

          Base.COMPONENT_CLASS = Header.COMPONENT_CLASS;
          this.onPageObserver  = this.onPageObserver.bind( this );
          this.onUserObserver  = this.onUserObserver.bind( this );
          PageObserver.subscribe( Header.COMPONENT_CLASS, this.onPageObserver );
          UserObserver.subscribe( Header.COMPONENT_CLASS, this.onUserObserver );
          console.info( `${ Header.CONSOLE_PREFIX } HEADER constructor just finished getting called...` );
          console.log( `${ Header.CONSOLE_PREFIX } HEADER constructor data`, { module: this.state?.module ?? 'NOT PASSED', other: this.state?.other ?? 'NOT PASSED' } );
     }

     /**
      *
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 03/22/21 08:19 pm
      */
     public componentDidMount(): void {
          if( this.state?.module ) {
               console.log( `${ Header.CONSOLE_PREFIX } MODULE DATA`, { module: this.state.module } );
               this.onPageObserver( this.state.module, true );
          }
          if( !isNode ) {
               let prev                          = 0;
               const win: JQuery<Window>         = jQuery( window );
               const nav: JQuery<HTMLElement>    = jQuery( '.scroll-hide-nav' );
               const button: JQuery<HTMLElement> = jQuery( `[${ Header.MENU_BUTTON_ID }]` );
               const toggleNav                   = (): void => {
                    if( win.scrollTop() === 0 ) {
                         nav.addClass( 'is-anchored' ).removeClass( [ 'is-stuck', 'is-at-top' ] );
                    } else {
                         nav.addClass( [ 'is-stuck', 'is-at-top' ] ).removeClass( 'is-anchored' );
                    }
               };

               toggleNav();
               Header.HEADER           = jQuery( `[${ Header.MOBILE_ID }]` );
               Header.CURTAIN          = jQuery( `[${ Header.CURTAIN_ID }]` );
               Header.isCurtainEnabled = false;
               jQuery( document ).foundation();

               this.registerJqueryButtons();

               button.on( 'click', ( event: JQuery.ClickEvent<HTMLElement> ): void => {
                    event.preventDefault();

                    if( Header.HEADER.hasClass( Header.MOBILE_CLASS ) ) {
                         console.log( `${ Header.CONSOLE_PREFIX } [[ /-: ]] Header curtain has been activated...` );
                         setTimeout( (): void => {
                              Header.CURTAIN.addClass( 'curtain-hide' ?? '' );
                              console.log( `${ Header.CONSOLE_PREFIX } [[ H-: ]] Header curtain has HIDE added...` );
                         }, 1000 );
                    } else {
                         console.log( `${ Header.CONSOLE_PREFIX } [[ /-: ]] Header curtain has NOT been activated...` );
                         setTimeout( (): void => {
                              Header.CURTAIN.removeClass( 'curtain-hide' ?? '' );
                              console.log( `${ Header.CONSOLE_PREFIX } [[ S-: ]] Header curtain has SHOW added...` );
                         }, 0 );
                    }

                    Header.HEADER.toggleClass( `${ Header.MOBILE_CLASS }` );
               } );

               win.on( 'scroll', (): void => {
                    const scrollTop: number = win.scrollTop();

                    nav.toggleClass( 'hidden', scrollTop > prev );
                    toggleNav();
                    prev = scrollTop;
               } );
          } else {
               console.log( `${ Header.CONSOLE_PREFIX } @@@ the window error was in app component...` );
          }
     }

     /**
      *
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/02/21 04:52 pm - documented
      */
     public componentWillUnmount(): void {
          PageObserver.unsubscribe( Header.COMPONENT_CLASS );
          UserObserver.unsubscribe( Header.COMPONENT_CLASS );
     }

     /**
      *
      * @param {Readonly<Record<string, unknown>>} prevProps
      * @param {Readonly<Record<string, unknown>>} prevState
      * @param snapshot {unknown}
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 04/15/21 04:14 pm
      */
     public componentDidUpdate( prevProps: Readonly<Record<string, unknown>>, prevState: Readonly<Record<string, unknown>>, snapshot?: unknown ): void {
          if( prevState?.storage !== this.state.storage ) {
               console.log( `${ Header.CONSOLE_PREFIX } ^^ + ^^ header state storage CHANGED`, { storage: this.state.storage } );
               this.registerJqueryButtons();
          } else {
               console.log( `${ Header.CONSOLE_PREFIX } ^^ + ^^ header state storage is the SAME, what are the action buttons`, { storage: this.state.storage, snapshot } );
               //this.onPageObserver( this.state.module, true );
          }
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 02/02/21 04:52 pm - documented
      */
     public render(): JSX.Element {
          const name: string                              = `${ this.state?.module?.domain?.text ?? 'loading' }`.toLowerCase();
          const topBarAttributes: Record<string, unknown> = {
               id            : process.env.REACT_APP_HEADER_ID,
               className     : 'top-bar scroll-hide-nav sticky grid-x show-for-medium is-anchored',
               role          : 'navigation',
               'data-sticky' : '',
               'data-options': 'marginTop:0;',
               'data-animate': 'slide-in-down slide-out-up',
          };

          return (
               <header className={ [ ...this.state.className ].join( ' ' ) } data-root-header="" data-sticky-container="">
                    <div className="curtain-menu curtain-hide" data-root-curtain="" data-mobile-buttons="">
                         <div className="curtain-left" />
                         <div className="curtain-right" />
                         <div className="curtain-menu-wrapper">
                              <ul className="curtain-menu-list menu vertical">
                                   <li><Link to="/social">social</Link></li>
                                   <li><Link to="/partner">partner</Link></li>
                                   <li><Link to="/about">about</Link></li>
                                   <li><Link to="/store">store</Link></li>
                                   <li><Link to="/blog">blog</Link></li>
                                   <li><Link to="/download">download</Link></li>
                                   { this.state.children }
                                   { this.state?.storage?.button?.dashboard?.component }
                                   { this.state?.storage?.button?.action?.component }
                              </ul>
                         </div>
                    </div>
                    <div className="title-bar scroll-hide-nav sticky grid-x hide-for-medium" data-responsive-toggle={ process.env.REACT_APP_HEADER_ID } data-hide-for="medium" data-mobile-buttons="">
                         <div className="cell small-2 top-bar-left">
                              <Link to="/" className="top-bar-logo">{ this.registerLogo( this.props.module as SiteModule ) }</Link>
                         </div>
                         <div className="cell small-8 top-bar-center">
                              <Link to="/" className="top-bar-logo"><span className={ `site-name` }>{ name }</span></Link>
                         </div>
                         <div className="cell small-2 top-bar-right">
                              <ul className="menu" data-dropdown-menu="">
                                   <li data-menu-button=""><LinkComponent module={ LinkModule.Build( { path: '#', icon: 'fas plus' } ) } data-menu-button-toggle="" /></li>
                              </ul>
                         </div>
                    </div>
                    <div { ...topBarAttributes }>
                         <div className="cell small-3 medium-2 top-bar-left">
                              <Link to="/" className="top-bar-logo">
                                   { this.registerLogo( this.props.module as SiteModule ) }
                                   <span className={ `site-name` }>{ name }</span></Link>
                         </div>
                         <div className="cell small-6 medium-8 top-bar-center">
                              <ul className="dropdown menu" data-dropdown-menu="">
                                   <li><Link to="/social">social</Link></li>
                                   <li><Link to="/partner">partner</Link></li>
                                   <li><Link to="/about">about</Link></li>
                                   <li><Link to="/store">store</Link></li>
                                   <li><Link to="/blog">blog</Link></li>
                                   <li><Link to="/download">download</Link></li>
                                   { this.state.children }
                                   { this.state?.storage?.button?.dashboard?.component }
                                   { this.state?.storage?.button?.action?.component }
                              </ul>
                         </div>
                         <div className="cell small-3 medium-2 top-bar-right">
                              <ul className="menu">
                                   <li><Link to="/contact">contact</Link></li>
                              </ul>
                         </div>
                    </div>
               </header>
          );
     }
}