import React               from 'react';
import PropTypes           from 'prop-types';
import { navigate }        from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionEnum }      from '../../api/com/ewing/social/enum';
import { ISessionProps }   from '../../api/com/ewing/social/interface';
import { ProfileModule }   from '../../api/com/ewing/social/module/api/profile.module';
import { SessionModule }   from '../../api/com/ewing/social/module/api/oauth/session.module';
import { UserModule }      from '../../api/com/ewing/social/module/api/user.module';
import { OAuthObserver }   from '../../api/com/ewing/social/observer';
import { ConsoleManager }  from '../../api/com/ewing/social/manager/console.manager';
import { UserManager }     from '../../api/com/ewing/social/manager/user.manager';
import { URLManager }      from '../../api/com/ewing/social/manager/url.manager';
import { Util }            from '../../api/com/ewing/social/tool';

/**
 * @class OAuth
 * @extends React.Component
 * @author Isaac Ewing
 * @version 1.0.0 01/19/21 12:45 pm
 */
export class OAuth extends React.Component<any, any> {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/07/21 02:48 pm
      */
     protected static readonly CONSOLE_PREFIX: string     = `${ process.env.REACT_APP_CONSOLE_PREFIX_COMPONENT } OAUT ${ process.env.REACT_APP_CONSOLE_SUFFIX_COMPONENT }`;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 02/23/21 03:21 pm
      */
     protected static readonly CONSOLE_ENABLED: boolean   = true;
     /**
      *
      * @type {ActionEnum}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/28/21 04:40 pm
      */
     protected static readonly DEFAULT_ACTION: ActionEnum = ActionEnum.Login;
     /**
      *
      * @type {Set<string>}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/16/21 09:39 pm
      */
     protected static readonly DEFAULT_PAGES: Set<string> = new Set<string>( [ 'login', 'claim' ] );
     /**
      *
      * @type {number}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/07/21 08:13 pm
      */
     protected static readonly DEFAULT_WIDTH: number      = 600;
     /**
      *
      * @type {number}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/07/21 08:13 pm
      */
     protected static readonly DEFAULT_HEIGHT: number     = 600;
     /**
      *
      * @type {number}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/28/21 04:40 pm
      */
     protected static readonly DEFAULT_RECHECK: number    = 1000;
     /**
      *
      * @type {any}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/19/21 12:45 pm
      */
     public static propTypes;
     /**
      *
      * @type {Window}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/28/21 04:40 pm
      */
     protected popup: Window;
     /**
      *
      * @type {NodeJS.Timeout}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/28/21 04:40 pm
      */
     protected check: NodeJS.Timeout;

     /**
      *
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/28/21 04:40 pm
      */
     protected closePopup(): void {
          try {
               if( this.popup ) {
                    this.popup.close();
                    this.popup = null;
               }
          } catch( exception: unknown ) {
               ConsoleManager.Info( OAuth.CONSOLE_ENABLED ?? null, OAuth.CONSOLE_PREFIX ?? null, 'oauth', 'close popup exception', { exception } );
          }
     }

     /**
      *
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/19/21 12:47 pm
      * documented
      */
     protected checkPopup(): void {
          this.check = setInterval( (): void => {
               const { popup } = this;

               if( !popup || popup.closed || popup.closed === undefined ) {
                    clearInterval( this.check );
                    this.setState( { disabled: null } );
               }
          }, OAuth.DEFAULT_RECHECK );
     }

     /**
      *
      * @return {Window}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/19/21 12:47 pm
      * documented
      */
     protected openPopup(): Window {
          const { provider, socket }        = this.props;
          const currentPage: string         = Util.cleanPath( URLManager.Page, false, true, false );
          const defaultAction: string       = OAuth.DEFAULT_PAGES.has( currentPage ) ? currentPage : OAuth.DEFAULT_ACTION;
          const action: string              = this.props?.action ?? defaultAction;
          const domain: string              = window.location.hostname;
          const code: string                = this.props?.code ?? null;
          const hash: string                = this.state.hash ?? null;
          const state: string               = this.state.state ?? null;
          const width: number               = OAuth.DEFAULT_WIDTH;
          const height: number              = OAuth.DEFAULT_HEIGHT;
          const left: number                = ( window.innerWidth >> 1 ) - ( width >> 1 );
          const top: number                 = ( window.innerHeight >> 1 ) - ( height >> 1 );
          const obj: Partial<ISessionProps> = {
               action,
               code,
               provider,
               domain,
               socket,
               hash,
               state,
          };
          const params: SessionModule       = SessionModule.Build( obj ?? null );
          const url: string                 = `${ URLManager.OAuth }/${ provider }?${ params.toRequestParams() }`;
          const features: string[]          = [
               'toolbar=no',
               'location=no',
               'directories=no',
               'status=no',
               'menubar=no',
               'scrollbars=no',
               'resizable=no',
               'copyhistory=no',
               `width=${ width }`,
               `height=${ height }`,
               `top=${ top }`,
               `left=${ left }`,
          ];

          ConsoleManager.Log( OAuth.CONSOLE_ENABLED ?? null, OAuth.CONSOLE_PREFIX ?? null, 'oauth', 'socket id', { socket: socket.id } );
          ConsoleManager.Log( OAuth.CONSOLE_ENABLED ?? null, OAuth.CONSOLE_PREFIX ?? null, 'oauth', 'oauth url', { url } );

          return window.open( url ?? '', '', features.join( ' ' ) ?? '' );
     }

     /**
      *
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/19/21 12:47 pm
      * documented
      */
     protected onStartAuth(): void {
          if( !this.state.disabled ) {
               this.popup = this.openPopup();
               this.checkPopup();
               this.setState( { disabled: 'disabled' } );
          }
     };

     /**
      *
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/19/21 12:47 pm
      * documented
      */
     protected onCloseCard(): void {
          this.setState( { user: null } );
     };

     /**
      *
      * @param data {any}
      * @return {void}
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/07/21 08:08 pm
      */
     protected handleSocketData( data: unknown ): void {
          const page: string = Util.cleanPath( URLManager.Page, false );
          let localData: UserModule | ProfileModule;

          ConsoleManager.Log( OAuth.CONSOLE_ENABLED ?? null, OAuth.CONSOLE_PREFIX ?? null, 'oauth', 'detected current page', { page } );

          switch( page ) {
               case 'claim':
               case 'login':
                    localData = UserModule.Build( data );
                    ConsoleManager.Log( OAuth.CONSOLE_ENABLED ?? null, OAuth.CONSOLE_PREFIX ?? null, 'oauth', 'returned data', { raw: data, user: localData } );
                    UserManager.Login( localData );
                    navigate( `/dashboard` ).then();
                    break;
               default:
                    localData = ProfileModule.Build( data );
                    ConsoleManager.Log( OAuth.CONSOLE_ENABLED ?? null, OAuth.CONSOLE_PREFIX ?? null, 'oauth', 'returned data', { raw: data, profile: localData } );
                    OAuthObserver.notify( localData );
          }
     }

     /**
      *
      * @param props {any}
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/19/21 12:45 pm
      */
     public constructor( props: any ) {
          super( props );

          this.state = {
               user    : null,
               disabled: null,
               hash    : Util.registerHash(),
               state   : props?.state ?? null,
          };

          this.onStartAuth = this.onStartAuth.bind( this );
          this.onCloseCard = this.onCloseCard.bind( this );
     }

     /**
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/17/21 11:31 am
      * documented
      */
     public componentDidMount(): void {
          const { socket, provider } = this.props;

          socket.on( provider, user => {
               if( this.state.hash === ( user?.session?.hash ?? user?.hash ) ) {
                    try {
                         ConsoleManager.Log( OAuth.CONSOLE_ENABLED ?? null, OAuth.CONSOLE_PREFIX ?? null, 'oauth', 'setting up socket with data', { socket, provider, hash: this.state.hash, user } );
                    } catch( exception: unknown ) {
                         ConsoleManager.Log( OAuth.CONSOLE_ENABLED ?? null, OAuth.CONSOLE_PREFIX ?? null, 'oauth', 'component did mount error', { exception } );
                    }

                    this.closePopup();

                    if( typeof user === 'object' && user?.error ) {
                         ConsoleManager.Log( OAuth.CONSOLE_ENABLED ?? null, OAuth.CONSOLE_PREFIX ?? null, 'oauth', 'component did mount error', { exception: user?.error } );
                    } else {
                         this.handleSocketData( user );
                    }
               } else {
                    this.closePopup();

                    ConsoleManager.Log( OAuth.CONSOLE_ENABLED ?? null, OAuth.CONSOLE_PREFIX ?? null, 'oauth', 'skipping oauth, hashes do not math', { oauth: this.state.hash, socket: user?.hash } );
               }
          } );
     }

     /**
      *
      * @return {JSX.Element}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/19/21 12:45 pm
      */
     public render(): JSX.Element {
          const { provider } = this.props;
          const properties   = {
               onClick     : this.onStartAuth,
               className   : `${ provider } ${ this.state?.disabled } button`,
               'data-hash' : this.state.hash,
               'data-state': this.state.state,
          };

          return (
               <div className="button-wrapper fadein-fast">
                    <button { ...properties } >
                         <FontAwesomeIcon icon={ [ 'fab', provider ] } color={ this.props?.color ?? null } />
                    </button>
               </div>
          );
     }
}

OAuth.propTypes = {
     provider: PropTypes.string.isRequired,
     socket  : PropTypes.object.isRequired,
     action  : PropTypes.string,
     code    : PropTypes.string,
     step    : PropTypes.string,
     state   : PropTypes.string,
};
