import { AModule }  from '../../abstract/module.abstract';
import { IOverlay } from '../../interface';
import { Util }     from '../../tool';

/**
 * @class OverlayModule
 * @extends AModule
 * @implements IOverlay
 * @author Isaac Ewing
 * @version 1.0.0 12/09/20 01:41 pm
 * @version 2.0.0 05/30/21 08:11 pm - updated to extend AModule (was BaseModule)
 */
export class OverlayModule extends AModule implements IOverlay {
     /**
      * @param data {Object|null} The object containing the data
      * @return {OverlayModule|null} Returns a new instance of the module or null
      * @constructor
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 01:42 pm
      */
     public static Build( data: Record<string, unknown> ): OverlayModule | null {
          if( data ) {
               return new OverlayModule( data );
          }

          return null;
     }

     /**
      *
      * @param data
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 01:42 pm
      */
     public constructor( data: Record<string, unknown> ) {
          super( null );

          this.enabled  = Util.asBoolean( data?.enabled ?? process.env.REACT_APP_MAP_OVERLAY_ENABLED );
          this.onLoad   = Util.asBoolean( data?.onLoad ?? process.env.REACT_APP_MAP_OVERLAY_ON_LOAD );
          this.onUnload = Util.asBoolean( data?.onUnload ?? process.env.REACT_APP_MAP_OVERLAY_ON_UNLOAD );
          this.onClick  = Util.asBoolean( data?.onClick ?? process.env.REACT_APP_MAP_OVERLAY_ON_CLICK );
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 01:42 pm
      * @see enabled
      * @see onLoad
      * @see onUnload
      * @see onClick
      * @see enabled
      * @see disable
      */
     public get enabled(): boolean {
          return this._data.get( 'enabled' );
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @author Isaac Ewing
      * @version 1.0.0 05/30/21 08:24 pm
      * @see enabled
      * @see onLoad
      * @see onUnload
      * @see onClick
      * @see enabled
      * @see disable
      */
     public set enabled( value: boolean ) {
          this._data.set( 'enabled', value );
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 01:42 pm
      * @see enabled
      * @see onLoad
      * @see onUnload
      * @see onClick
      * @see enabled
      * @see disable
      */
     public get onLoad(): boolean {
          return this._data.get( 'onLoad' );
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @author Isaac Ewing
      * @version 1.0.0 05/30/21 08:24 pm
      * @see enabled
      * @see onLoad
      * @see onUnload
      * @see onClick
      * @see enabled
      * @see disable
      */
     public set onLoad( value: boolean ) {
          this._data.set( 'onLoad', value );
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 01:42 pm
      * @see enabled
      * @see onLoad
      * @see onUnload
      * @see onClick
      * @see enabled
      * @see disable
      */
     public get onUnload(): boolean {
          return this._data.get( 'onUnload' );
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @author Isaac Ewing
      * @version 1.0.0 05/30/21 08:24 pm
      * @see enabled
      * @see onLoad
      * @see onUnload
      * @see onClick
      * @see enabled
      * @see disable
      */
     public set onUnload( value: boolean ) {
          this._data.set( 'onUnload', value );
     }

     /**
      *
      * @return {boolean}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 01:42 pm
      * @see enabled
      * @see onLoad
      * @see onUnload
      * @see onClick
      * @see enabled
      * @see disable
      */
     public get onClick(): boolean {
          return this._data.get( 'onClick' );
     }

     /**
      *
      * @param {boolean} value
      * @return {void}
      * @author Isaac Ewing
      * @version 1.0.0 05/30/21 08:24 pm
      * @see enabled
      * @see onLoad
      * @see onUnload
      * @see onClick
      * @see enabled
      * @see disable
      */
     public set onClick( value: boolean ) {
          this._data.set( 'onClick', value );
     }

     /**
      *
      * @return {OverlayModule}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 01:42 pm
      * @see enabled
      * @see onLoad
      * @see onUnload
      * @see onClick
      * @see enabled
      * @see disable
      */
     public enable(): this {
          this.enabled = true;

          return this;
     }

     /**
      *
      * @return {OverlayModule}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 12/09/20 01:42 pm
      * @see enabled
      * @see onLoad
      * @see onUnload
      * @see onClick
      * @see enabled
      * @see disable
      */
     public disable(): this {
          this.enabled = false;

          return this;
     }

     /**
      *
      * @return {Record<string, boolean>}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/30/21 08:24 pm
      * @see enabled
      * @see onLoad
      * @see onUnload
      * @see onClick
      * @see enabled
      * @see disable
      */
     public toObject(): Record<string, boolean> {
          return {
               id      : null,
               enabled : this.enabled,
               onLoad  : this.onLoad,
               onUnload: this.onUnload,
               onClick : this.onClick,
          };
     }
}